import { getUserId } from '../user-session/user-session-use-cases/getUserId'
import { userFilesDatabase } from '../../databaseworker/databases'
import { textToBlob } from '../../databaseworker/fileUtils'
import { lowToleranceAuthFetcher } from '../utils/low-tolerance-auth-fetcher'
import { metadataDeserializer, metadataSerializer } from '../../databaseworker/jsonUtils'

class UserFileGateway {
  async getFromCloud(filename, initialization) {
    const resp = await lowToleranceAuthFetcher(
      `getFile/${encodeURIComponent(userFilesDatabase(getUserId()).name)}/${filename}`, {}, [404], initialization
    )

    if (resp.status === 404) {
      return { generateDefaultFile: true, fileContents: '' }
    }

    return { generateDefaultFile: false, fileContents: JSON.parse(await resp.text(), metadataDeserializer) }
  }

  async saveInCloud(filename, content, initialization) {
    const form = new FormData()
    form.append('file', textToBlob(JSON.stringify(content, metadataSerializer)), filename)
    return await lowToleranceAuthFetcher(`postFile/${encodeURIComponent(userFilesDatabase(getUserId()).name)}`, {
      method: 'POST',
      body: form,
    }, [], initialization)
  }
}

const userFileGateway = new UserFileGateway()
export { userFileGateway }