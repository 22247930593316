// Toolbar for user control/interaction with simulation environment
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import { environmentList } from './entities/Environments'
import { cameraList } from './entities/Cameras'
import HelpIcon from '@material-ui/icons/Help'
import { useMission } from './contexts/MissionContext'
import { useScene, SceneActions } from './contexts/SceneContext'
import FilterHdrIcon from '@material-ui/icons/FilterHdr'
import VideocamIcon from '@material-ui/icons/Videocam'
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong'
import { Button } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@material-ui/core/CircularProgress'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'

function EnvironmentSelect(props) {
  if (!props.doShow) {
    return null
  }

  return (
    <FormControl className={props.classes.formControl}>
      <Select
        SelectDisplayProps={{ style: { paddingLeft: 35 } }}
        value={props.selectedScene}
        onChange={props.onSceneChange}
        disableUnderline
      >
        {
          props.isExplore ?
            // Reduce list to all available environments in Explore mode
            environmentList.reduce((accum, env, index) => {
              if (props.availableEnvironments?.includes(env.name) ||
                  index === props.selectedScene) {  // Shouldn't happen, but allow current env even if not in list.
                accum.push(
                  (<MenuItem key={index} value={index}>{env.name}</MenuItem>)
                )
              }
              return accum
            }, []) :
            // Just the current selected scene in "Mission Mode"
            [(<MenuItem key={props.selectedScene} value={props.selectedScene}>{environmentList[props.selectedScene].name}</MenuItem>)]
        }
      </Select>
    </FormControl>
  )
}

const useStyles = makeStyles(theme => ({
  toolBar: {
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    marginLeft: 5,
    minWidth: 100,
  },
  formControlNav: {
    marginLeft: 'auto',
    marginRight: '2em',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  openDrawerButton: {
  },
  iconButton: {
    borderRadius: 5,
    height: 30,
    width: 30,
    marginRight: 5,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    marginLeft: 45,
  },
  identificationIcon: {
    position: 'relative',
    right: -35,
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

function SceneResetButton() {
  const classes = useStyles()
  const [missionState] = useMission()
  const [sceneState, sceneDispatch] = useScene()

  return (
    <div style={{ marginLeft: 5, position: 'relative' }}>
      <Tooltip title='Reset Environment' placement='top' arrow>
        <span> {/* Prevents a MUI error message */}
          <Button
            size='small'
            disabled={!missionState.lessonContentReady}
            onClick={() => sceneDispatch({ type: SceneActions.ENVIRONMENT_RESET })}
          >
          Reset
          </Button>
        </span>
      </Tooltip>
      {!sceneState.ready && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  )
}

export default function SceneToolbar(props) {
  const classes = useStyles()
  const [missionState] = useMission()
  const [sceneState, sceneDispatch] = useScene()

  if (!missionState.requiresSim) {
    return (
      <div className={classes.toolBar}>
        <SceneResetButton />
      </div>
    )
  }

  return (
    <div className={classes.toolBar}>
      <SceneResetButton />
      <div className={classes.buttonGroup}>
        <FilterHdrIcon className={classes.identificationIcon} />
        <EnvironmentSelect
          doShow={true}
          isExplore={missionState.ui.isExploreMode}
          selectedScene={sceneState.envIndex}
          availableEnvironments={missionState.activeMissionPack.environments}
          onSceneChange={(event) => {
            const selection = event.target.value
            sceneDispatch({ type: SceneActions.ENVIRONMENT_SET, envIndex: selection, params: null })
          }
          }
          classes={classes}
        />
      </div>
      <div className={classes.buttonGroup}>
        <VideocamIcon className={classes.identificationIcon} />
        <FormControl className={classes.formControl}>
          <Select
            SelectDisplayProps={{ style: { paddingLeft: 35 } }}
            disableUnderline
            value={sceneState.camIndex}
            onChange={(event) => {
              const selection = event.target.value
              if (selection === -2) {
                sceneDispatch({ type: SceneActions.CAMERA_HELP, shouldShow: true })
              } else {
                sceneDispatch({ type: SceneActions.CAMERA_SET, camIndex: selection })
              }
            }
            }
          >
            <MenuItem value={-2} divider={true} style={{ marginTop: -5 }} >
              <em style={{ marginRight: 5 }}>Help</em>
              <HelpIcon />
            </MenuItem>
            {cameraList.map((cam, index) => (
              <MenuItem key={index} value={index}>{cam.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Tooltip title='Center Camera' placement='top' arrow>
          <IconButton
            className={classes.iconButton}
            onClick={() => sceneDispatch({ type: SceneActions.CAMERA_RESET })}
          >
            <CenterFocusStrongIcon style={{ fontSize: 20 }} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Scene Volume' placement='top' arrow>
          <IconButton
            className={classes.iconButton}
            style={{marginLeft: 45}}
            onClick = {() => sceneDispatch({ type: SceneActions.VOLUME_SHOW, shouldShow: !sceneState.volumeShow })}
          >
            <VolumeUpIcon
              style={{ fontSize: 20, border: sceneState.volumeShow ? '1px solid' : 'none', borderRadius:'3px'}}
            />
          </IconButton>
        </Tooltip>
      </div>
    </div >
  )
}
