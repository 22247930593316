import React from 'react'

var setContentDirectorState = () => {}
const ContentDirectorStateContext = React.createContext()
const ContentDirectorProvider = ({ children }) => {
  const [contentDirector, setContentDirector] = React.useState({
    contentSequence: [],
    contentSequenceIndex: 0,
  })
  setContentDirectorState = setContentDirector

  const formatContentSequenceItem = () => {
    if (contentDirector.contentSequenceIndex < 0) {
      return
    }
    return contentDirector.contentSequence[contentDirector.contentSequenceIndex]
  }

  const formatAssignment = () => {
    const contentSequenceItem = formatContentSequenceItem() ?? {}
    return contentSequenceItem?.assignment
  }

  const contentSequenceItem = formatContentSequenceItem()
  const contentSequenceItemIsLast = contentDirector.contentSequenceIndex === contentDirector.contentSequence.length - 1
  const nextContentSequenceItem = contentSequenceItemIsLast || contentDirector.contentSequence.length === 0 ? null : contentDirector.contentSequence[contentDirector.contentSequenceIndex + 1]
  return (
    <ContentDirectorStateContext.Provider
      value={{
        ...contentDirector,
        contentSequenceItemIsLast,
        nextContentSequenceItem,
        contentSequenceItem,
        contentSequenceItemScored: contentSequenceItem?.flow?.scored,
        assignment: formatAssignment(),
      }}
    >
      {children}
    </ContentDirectorStateContext.Provider>
  )
}

const useContentDirector = () => {
  return React.useContext(ContentDirectorStateContext)
}

export { ContentDirectorProvider, useContentDirector, setContentDirectorState }