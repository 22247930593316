// This file has a twin!
// The botsim file lives at src/content-manager/groups/group-use-cases/lesson-access-code-operations.js
// The class-dashboard file lives at class-dashboard/src/pages/lesson-access-control
// IF YOU MAKE CHANGES TO THIS FILE, MAKE SURE TO MAKE THE SAME CHANGES TO THE TWIN FILE!!!

function generateDefaultMissionPackIdArray(missionPack) {
  const arr = []

  missionPack.missions.forEach((mission) => {
    arr.push(mission.id)
    mission.flow.forEach((flow) => {
      arr.push(flow.id)
    })
  })
  return arr
}

function decodeLessonAccessCode(pack, str) {
  const orderedMissionPackIds = generateDefaultMissionPackIdArray(pack)
  if (str === '*') {
    return orderedMissionPackIds
  }

  if (str === '') {
    return []
  }

  const arr = []
  const sections = str.split('/')
  for (const run of sections) {
    const parts = run.split('-')
    if (parts.length === 1) {
      arr.push(parts[0])
    } else {
      const idxStart = orderedMissionPackIds.indexOf(parts[0])
      const idxEnd = orderedMissionPackIds.indexOf(parts[1])
      arr.push(...orderedMissionPackIds.slice(idxStart, idxEnd))
    }
  }

  return arr
}

function encodeLessonAccessCode(pack, selectedMissionPackIds) {
  const orderedMissionPackIds = generateDefaultMissionPackIdArray(pack)
  if (selectedMissionPackIds.length === orderedMissionPackIds.length) {
    return '*'
  }

  if (selectedMissionPackIds.length === 0) {
    return ''
  }

  var str = ''
  var midRun = false
  var startRunId = null
  for (const id of orderedMissionPackIds) {
    if (selectedMissionPackIds.includes(id)) {
      if (!midRun) {
        midRun = true
        startRunId = id
        str += `${id}-`
      }
    } else {
      if (midRun) {
        midRun = false
        if (startRunId === id) {
          str += '/'
        } else {
          str += `${id}/`
        }
      }
    }
  }

  if (midRun) {
    const id = orderedMissionPackIds[orderedMissionPackIds.length-1]
    if (startRunId === id) {
      str += '/'
    } else {
      str += `${id}/`
    }
  }

  return str.slice(0, -1)
}


export { encodeLessonAccessCode, decodeLessonAccessCode, generateDefaultMissionPackIdArray }