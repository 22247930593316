import { eraseUserSessionIdCookie } from './cookieOperations'
import { setUserSessionInfo } from './userAuthStore'
import authFetchHandler from './authFetchHandler'
import identifyUser from '../tracking/identifyUser'
import handleLoginPortalRedirect from './handleLoginPortalRedirect'

const handleSessionIdLogin = async () => {
  try {
    const resp = await authFetchHandler('session', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (resp.status === 200) {
      const message = (await resp.json())
      identifyUser({
        id: message?.uid,
        username: message?.displayName,
        email: message?.email,
      })
      setUserSessionInfo(message)
      handleLoginPortalRedirect()
    } else {
      // Bad token?
      eraseUserSessionIdCookie()
    }
  } catch (err) {
    console.log(err)
    eraseUserSessionIdCookie()
  }
}


export default handleSessionIdLogin