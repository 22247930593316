import { notificationsStateInterface } from '../../contexts/NotificationsContext'
import { RESPONSE_STATUS } from '../fetch-responses'
import { readNotificationsFromStore } from './notification-store'

async function presentNotifications() {
  notificationsStateInterface.setNotificationsState(readNotificationsFromStore())
}

function presentFetchNotificationsResponseStarted() {
  notificationsStateInterface.setFetchNotificationsResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentFetchNotificationsResponseFailed(reason) {
  notificationsStateInterface.setFetchNotificationsResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentFetchNotificationsResponseSucceeded() {
  notificationsStateInterface.setFetchNotificationsResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
}





export { presentNotifications, presentFetchNotificationsResponseStarted, presentFetchNotificationsResponseFailed, presentFetchNotificationsResponseSucceeded }