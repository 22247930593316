// Coding Panel
import React, { useRef } from 'react'
import { useMissionEditor, MissionEditorActions, genDefault } from './contexts/MissionEditorContext'
import { ValidatorEditorNotes } from './Validators'
import { ValidatorContextKeys } from './ValidatorControl'
import { useCodePanel, CodePanelActions } from './contexts/CodePanelContext'
import Editor from '@monaco-editor/react'
import { useUserConfig } from './contexts/UserConfigContext'
import { loader } from '@monaco-editor/react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { IconButton, TextField, Tooltip, Select, InputLabel, MenuItem, Divider, Switch, Button, DialogContent, DialogActions } from '@material-ui/core'
import { Delete, Add, Check, Close, FormatListNumbered, Room, ContactSupport, ArrowUpward } from '@material-ui/icons'
import dark_vs from './dark_vs.json'
import FiriaMarkdown from './FiriaMarkdown'
import { missionEditorController } from './MissionEditorControl'
import { cameraList } from './entities/Cameras'
import { environmentList } from './entities/Environments'
import { Paper, CircularProgress }  from '@material-ui/core'
import { TargetStates, TargetModes } from './TargetInterface'
import { useDebugger, DebuggerActions } from './contexts/DebuggerContext'
import { SceneActions } from './contexts/SceneContext'
import { MissionActions } from './contexts/MissionContext'
import { UserConfigActions } from './contexts/UserConfigContext'
import DebugButtons from './DebugButtons'
import { useSnackbar } from 'notistack'
import { isEqual, isEmpty } from 'lodash'
import { CompletionPanelDialogContainer } from './CompletionPanel'
import { goalCodeParseValidatorWorks, getParsedCodeMap } from './content-manager/lesson-editor/lesson-editor-use-cases'
import { RESPONSE_STATUS } from './content-manager/fetch-responses'

import StopIcon from '@material-ui/icons/Stop'
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined'
import { TargetNames } from './Players'
import { FileManagementActions } from './contexts/FileManagementContext'

const AVATAR_SOURCE = '/pub/'

const sceneActions = Object.keys(SceneActions).map(key => ValidatorContextKeys.SCENE + '.' + key)
const userConfigActions = Object.keys(UserConfigActions).map(key => ValidatorContextKeys.USER_CONFIG + '.' + key)
const missionActions = Object.keys(MissionActions).map(key => ValidatorContextKeys.MISSION + '.' + key)
const codePanelActions = Object.keys(CodePanelActions).map(key => ValidatorContextKeys.CODE_PANEL + '.' + key)
const debuggerActions = Object.keys(DebuggerActions).map(key => ValidatorContextKeys.DEBUGGER + '.' + key)
const fileManagementActions = Object.keys(FileManagementActions).map(key => ValidatorContextKeys.FILE_MANAGEMENT + '.' + key)

const ValidatorEditorContextActions = [...sceneActions, ...userConfigActions, ...missionActions, ...codePanelActions, ...debuggerActions, ...fileManagementActions]

loader.config({ paths: { vs: 'monaco-editor/min/vs' } })
export let editorInstance = null

const useStyles = makeStyles((theme) => {
  return ({
    tab: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      // width:80,
      height:29,
      padding:2,
      paddingLeft: 6,
      paddingRight: 6,
    },
    tabFocusedLight: {
      backgroundColor: '#ffffff',
    },
    tabFocusedDark: {
      backgroundColor: dark_vs.colors['editor.background'],
    },
    tabUnfocused: {
      color: 'gray',
    },
    errorTextColor: {
      color: 'red',
    },
    tabContainer: {
      height:29,
      float: 'left',
      cursor: 'pointer',
    },
    fileNameText:{
      fontSize: 14,
    },
    noFileMessage:{
      fontSize: 14,
      display: 'block',
      width: '100%',
      paddingTop: 30,
      textAlign: 'center',
    },
    small: {
      width: 6,
      height: 6,
      marginTop: 12,
    },
    right: {
      float: 'right',
      marginRight: 10,
    },
    left: {
      float: 'left',
      marginLeft: 8,
    },
    textInputField: {
      display: 'block',
      marginBottom: 10,
    },
    fullHeight: {
      height: '100vh',
      overflow: 'auto',
    },
    speechBubble: {
      // backgroundColor: '#f8f8f8',
      border: '1px solid #c8c8c8',
      borderRadius: '5px',
      position: 'relative',
      width: '400px',
      padding: '5px',
      marginTop: '8px',
    },
    arrow: {
      borderStyle: 'solid',
      borderColor: '#c8c8c8 transparent transparent transparent',
      borderWidth: '8px 8px 0px 8px',
      transform: 'rotate(180deg)',
      position: 'absolute',
      top: '-8px',
      left: '10px',
    },
    codestepMarkdownContainer: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
    },
    iconButton: {
      borderRadius: 5,
      height:25,
      width:25,
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        color: 'rgb(0, 0, 0)',
        opacity: '.3',
      },
    },
  })
})

const DebuggerEditorValidatorIndicator = ({ goalCode }) => {
  const [editorState] = useMissionEditor()
  const [status, setStatus] = React.useState(null)
  const isMounted = useRef(false)

  React.useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const goal = editorState?.tabs[editorState?.tabSelected]
  const validatorType = goal?.validatorType
  const validatorIsCodeParse = validatorType === 'CODE_PARSE'

  const debounce = React.useRef(null)
  const oldGoalCode = React.useRef('')
  React.useEffect(() => {
    if (goalCode === '' || !validatorIsCodeParse) {
      return
    }

    const checkValidator = async () => {
      if (!isMounted.current) {
        return
      }

      const validatorWorks = await goalCodeParseValidatorWorks({ validator: goal.validator, validatorType }, goalCode)
      if (validatorWorks) {
        setStatus(RESPONSE_STATUS.SUCCEEDED)
      } else {
        setStatus(RESPONSE_STATUS.FAILED)
      }
    }

    setStatus(RESPONSE_STATUS.IN_PROGRESS)
    clearTimeout(debounce.current)

    if (oldGoalCode.current !== goalCode) {
      oldGoalCode.current = goalCode
      debounce.current = setTimeout(checkValidator, 200)
      return
    }
    debounce.current = setTimeout(checkValidator, 500)
  }, [goal.validator, goalCode, validatorIsCodeParse, validatorType])

  const getCircleColor = () => {
    switch (status) {
      case RESPONSE_STATUS.IN_PROGRESS:
        return 'yellow'
      case RESPONSE_STATUS.FAILED:
        return 'red'
      case RESPONSE_STATUS.SUCCEEDED:
        return 'green'
      default:
        return 'gray'
    }
  }

  if (!validatorIsCodeParse) {
    return null
  }

  return (
    <Tooltip title={'Indicates whether the code in "Debugger" panel validates the code in the "Validator Code" panel.'} placement='bottom' arrow>
      <div style={{paddingLeft: 10, paddingRight: 10, height: '100%'}}>
        <div style={{width: 8, height: 8, borderRadius: 50, backgroundColor: getCircleColor(), border: '1px solid rgba(0,0,0,0.3)'}}></div>
      </div>
    </Tooltip>
  )
}

const DebuggerEditor = (props) => {
  const [solutionCode, setSolutionCode] = React.useState('')
  const [goalCode, setGoalCode] = React.useState('')

  React.useEffect(() => {
    if (props.editorState.editorSelected === 'objective'){
      setSolutionCode(props.editorState.objective.solution)
      setGoalCode(props.editorState.objective.solution)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.editorState.editorSelected])

  const saveSolutionCode = (code) => {
    setSolutionCode(code)
    props.handleEditorUpdate(code)
  }

  const saveGoalCode = (code) => {
    setGoalCode(code)
    props.codePanelDispatch({ type: CodePanelActions.EDITOR_CONTENT_CHANGED, code })
  }

  const goalEditor = props.editorState.toolSelected === 'Goals'
  return (
    <div style={{display: props.open ? 'block' : 'none'}}>
      <Divider />
      <div style={{height:30, width:'100%', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
        <div className={`${props.classes.tab} ${props.userConfigState.theme === 'dark' ? props.classes.tabFocusedDark : props.classes.tabFocusedLight}`}>
          {goalEditor ? 'Debugger' :
            props.editorState.toolSelected === 'Solution' ? 'Solution' : null}
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
          {goalEditor ?
            <DebuggerEditorValidatorIndicator {...{goalCode}}/>:
            null}
          <DebugButtons />
        </div>
      </div>
      <Editor
        height={goalEditor ? '31vh' :
          props.editorState.toolSelected === 'Solution' ? '88vh' : 0}
        onMount={props.handleEditorDidMount}
        theme={'vs-'+props.userConfigState.theme}
        language={'python'}
        path={props.editorState.mission.id + '/' + props.editorState.objective.id + '/' + props.editorState.toolSelected}
        options={{
          automaticLayout: true,
          glyphMargin: true,
          lineNumbersMinChars: 3,
        }}
        value={goalEditor ? goalCode :
          props.editorState.toolSelected === 'Solution' ? solutionCode : null}
        onChange={goalEditor ? saveGoalCode :
          props.editorState.toolSelected === 'Solution' ? saveSolutionCode : null}
      />
    </div>
  )
}

const GenericPicker = ({classes, stateObj, values, onChange, fieldObj}) => {
  const [field, setField] = React.useState('')
  const [idType, setIdType] = React.useState('objective')
  const [fetchedContent, setfetchedContent] = React.useState('')
  const [, editorDispatch] = useMissionEditor()

  const finishCheck = () => {
    setfetchedContent('success')
    var newVals = [...values]
    var newField = stateObj.editorSelected === 'mission' ? {id: field, type:idType} : {id:field}
    newVals.push(newField)
    onChange(newVals)
    setField('')
  }

  const saveComplete = (res) => {
    if (res.success) {
      console.log(res.msg)
      finishCheck()
    } else {
      alert(res.msg)
    }
  }

  const fetchComplete = (res) => {
    const folder = stateObj.editorSelected === 'mission' ? idType : 'question'

    let id = ''
    let title = ''

    if (res.success) {
      if (stateObj.editorSelected === 'mission') {
        id = res.data.id
        title = res.data.title || ''
      }
      finishCheck()
    } else {
      const json = genDefault[folder]()
      json.id = field
      missionEditorController.saveJson(json, folder, saveComplete)
      if (stateObj.editorSelected === 'mission') {
        id = json.id
        title = json.title
      }
    }

    if (stateObj.editorSelected === 'mission') {
      editorDispatch({type: MissionEditorActions.ADD_FLOW_TITLE, id, title})
    }
  }

  const getJsonData = () => {
    const folder = stateObj.editorSelected === 'mission' ? idType : 'question'
    setfetchedContent('fetching')
    missionEditorController.loadJson(field, folder, fetchComplete)
  }

  const interimIdChange = (oldId, newId) => {
    var newVals = [...values]
    const vRef = newVals.find(o => o.id === oldId)
    vRef.id = newId
    onChange(newVals)
  }

  const updateItemId = async (oldId, newId, type) => {
    if (newId === '' || newId === null) {
      return
    }
    const success = await missionEditorController.renameJson(oldId, newId, type)
    if (success) {
      interimIdChange(oldId, newId)
    }
  }

  return (
    <Paper style={{padding:10, margin:'10px 10px 10px 0'}}>
      <div>
        <div>
          {fieldObj.title}
        </div>
      </div>
      <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-start', gap:10}}>
        <TextField
          className={classes.textInputField}
          label={fieldObj.label}
          value={field}
          onChange={e => setField(e.target.value)}
        />
        {stateObj.editorSelected === 'mission' ?
          <div>
            <InputLabel shrink id='demo-simple-select-label'>
              Id Type
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              value={idType}
              onChange={e => setIdType(e.target.value)}
              style={{position:'relative', top:-5}}
            >
              <MenuItem key={0} value={'objective'}>{'Objective'}</MenuItem>
              <MenuItem key={1} value={'quiz'}>{'Quiz'}</MenuItem>
            </Select>
          </div>:
          null
        }
        <IconButton
          size='small'
          aria-label='feedback'
          disableRipple
          onClick={() => getJsonData()}
        >
          <Add />
        </IconButton>
        {fetchedContent === 'success' ? <Check style={{color:'green'}}/> :
          fetchedContent === 'fetching' ? <CircularProgress size={13} style={{ position: 'absolute', padding:0, margin:0}}/> :
            fetchedContent === '' ? null :
              <Close style={{color:'red'}}/>
        }
      </div>
      <div style={{overflowY:'scroll'}}>
        { values.map((obj, index)=> {
          return (
            <div key={obj.id} style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', gap:'0.3em', borderBottom: '1px solid', padding:'0 3px 0 3px'}}>
              <Tooltip title={'Go to Item'} placement='right' arrow>
                <IconButton
                  size='small'
                  aria-label='feedback'
                  disableRipple
                  onClick={() => {
                    var valueIndex = values.findIndex(v => v.id === obj.id)
                    if (valueIndex !== null){
                      missionEditorController.loadJson(values[valueIndex].id, obj.type || 'question', (res) => {
                        if (res.success) {
                          editorDispatch({type: MissionEditorActions.JSON_LOADED, json: res.data, editor: obj.type || 'question'})
                        } else {
                          alert(res.msg)
                        }
                      })
                    }
                  }}
                >
                  {obj.type === 'objective' ? <Room /> : obj.type === 'quiz' ? <FormatListNumbered /> : <ContactSupport />}
                </IconButton>
              </Tooltip>
              {stateObj.editorSelected === 'mission' ?
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <p style={{padding: 0, margin: 0, fontSize: '0.7em'}}>{stateObj.flowTitles[obj.id]}</p>
                  <TextField
                    style={{padding: 0, margin: 0}}
                    className={classes.textInputField}
                    InputProps={{ disableUnderline: true }}
                    value={obj.id}
                    onChange={(e) => {
                      editorDispatch({type: MissionEditorActions.ADD_FLOW_TITLE, id: e.target.value, title: stateObj.flowTitles[obj.id]})
                      updateItemId(obj.id, e.target.value, obj.type || 'question')
                    }}
                  />
                </div>:
                <TextField
                  style={{padding: 0, margin: 0}}
                  className={classes.textInputField}
                  InputProps={{ disableUnderline: true }}
                  value={obj.id}
                  onChange={e => updateItemId(obj.id, e.target.value, obj.type || 'question')}
                />
              }
              <Tooltip title={'Remove and Delete from Server'} placement='right' arrow>
                <IconButton
                  size='small'
                  aria-label='feedback'
                  disableRipple
                  onClick={() => {
                    var valueIndex = values.findIndex(v => v.id === obj.id)
                    if (valueIndex !== null){
                      missionEditorController.deleteJson(values[valueIndex].id, obj.type || 'question', (res) => {
                        console.log(res.msg)
                      })
                      let newVals = [...values]
                      newVals.splice(valueIndex, 1)
                      onChange(newVals)
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Only Remove Item (Do Not Delete from Server)'} placement='right' arrow>
                <IconButton
                  size='small'
                  aria-label='feedback'
                  disableRipple
                  onClick={() => {
                    var valueIndex = values.findIndex(v => v.id === obj.id)
                    if (valueIndex !== null){
                      let newVals = [...values]
                      newVals.splice(valueIndex, 1)
                      onChange(newVals)
                    }
                  }}
                >
                  <Close />
                </IconButton>
              </Tooltip>
              <Tooltip title={'Change Item Position'} placement='right' arrow>
                <IconButton
                  size='small'
                  aria-label='feedback'
                  disableRipple
                  onClick={() => {
                    let valueIndex = values.findIndex(v => v.id === obj.id)
                    if (valueIndex !== null && valueIndex !== 0){
                      let newVals = [...values]; // need this semi-colon to prevent creeping onto next line
                      [newVals[valueIndex],newVals[valueIndex - 1]] = [newVals[valueIndex - 1],newVals[valueIndex]]
                      onChange(newVals)
                    }
                  }}
                >
                  <ArrowUpward />
                </IconButton>
              </Tooltip>
            </div>
          )
        })}
      </div>
      <div style={{paddingTop:10}}>
        {fetchedContent !== 'success' && fetchedContent !== 'fetching' ? fetchedContent : null}
      </div>
    </Paper>
  )
}

const JsonField = ({ onChange, value, fieldObj }) => {
  const [fieldValue, setFieldValue] = React.useState(null)
  const [editorState, editorDispatch] = useMissionEditor()
  const snacks = useSnackbar()

  const defaultParams = environmentList[editorState.objective.scene]?.defaultParams
  const parsedValue = isEmpty(value) ? defaultParams ? defaultParams : {} : value

  React.useEffect(() => {
    setFieldValue(null)
  }, [editorState.objective.scene])

  const onSave = () => {
    try {
      const paramJson = JSON.parse(fieldValue)
      if (isEqual(paramJson, defaultParams)){
        onChange({})
        setFieldValue(null)
      } else {
        onChange(paramJson)
        setFieldValue(null)
      }
    } catch (error) {
      snacks.enqueueSnackbar('Error parsing json! Please reformat and try again.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      })
    }
  }

  return (
    <div style={{padding: '2px 4px'}}>
      <TextField
        disabled={!fieldValue}
        style={{spellCheck:'false'}}
        fullWidth
        type={'string'}
        multiline
        InputProps={{spellCheck :false}}
        label={fieldObj.label}
        value={
          fieldValue ? fieldValue :
            JSON.stringify(
              parsedValue,
              undefined,
              4
            )
        }
        onChange={e =>setFieldValue(e.target.value)}
        variant='filled'
      />
      <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div>
          <Button onClick={()=>{
            editorDispatch({type: MissionEditorActions.SET_MANIFEST_VAL, value: editorState.objective.scene, key: 'scene'})
            setFieldValue(null)
          }}
          >
            Set Scene Defaults
          </Button>
        </div>
        <div>
          {fieldValue ?
            <div>
              <Button onClick={()=>
                setFieldValue(null)
              }
              >
                Undo Changes
              </Button>
              <Button onClick={onSave}>
                Save
              </Button>
            </div>:
            <Button onClick={()=>
              setFieldValue(JSON.stringify(
                parsedValue,
                undefined,
                4
              ))
            }
            >
              Edit
            </Button>
          }
        </div>
      </div>
    </div>
  )
}

const GenericField = ({classes, stateObj, onChange, fieldObj}) => {
  const [tempFilename, setTempFilename] = React.useState('')

  const getField = () => {
    switch (fieldObj.type) {
      case 'string':
        return (
          <div style={{display:'flex', flexDirection:'row', alignItems:'center', gap:10}}>
            <TextField
              type={'string'}
              className={classes.textInputField}
              label={fieldObj.label}
              value={stateObj[stateObj.editorSelected][fieldObj.key]}
              onChange={e => onChange(e.target.value)}
            />
            {fieldObj.key === 'avatar'?
              <img style={{alignSelf: 'center', width:32, height:32}} alt={''} src={AVATAR_SOURCE+stateObj[stateObj.editorSelected][fieldObj.key]}/>:null
            }
          </div>
        )
      case 'number':
        return (
          <TextField
            type={'number'}
            className={classes.textInputField}
            label={fieldObj.label}
            value={stateObj[stateObj.editorSelected][fieldObj.key]}
            onChange={e => onChange(e.target.value)}
          />
        )
      case 'select':
        return (
          <>
            <InputLabel shrink id='demo-simple-select-label'>
              {fieldObj.label}
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              value={stateObj[stateObj.editorSelected][fieldObj.key]}
              onChange={e => onChange(e.target.value)}
            >
              { fieldObj.options.map(menuObj => <MenuItem key={menuObj.key} value={menuObj.key}>{menuObj.name}</MenuItem>) }
            </Select>
          </>
        )
      case 'picker':
        return (
          <GenericPicker
            classes={classes}
            values={stateObj[stateObj.editorSelected][fieldObj.key]}
            fieldObj={fieldObj}
            stateObj={stateObj}
            onChange={onChange}
          />
        )
      case 'json':
        return <JsonField onChange={onChange} value={stateObj[stateObj.editorSelected][fieldObj.key]} fieldObj={fieldObj} />
      case 'list':
        return (
          <div>
            <div>
              <InputLabel shrink id='demo-simple-select-label'>
                {fieldObj.label}
              </InputLabel>
            </div>
            <div style={{display:'flex', flexDirection:'row', alignItems:'center', marginLeft: 30, gap:10}}>
              <TextField
                type={'string'}
                className={classes.textInputField}
                value={tempFilename}
                onChange={e => setTempFilename(e.target.value)}
              />
              <IconButton
                size='small'
                aria-label='feedback'
                disableRipple
                onClick={() => {
                  var newList = [...stateObj[stateObj.editorSelected][fieldObj.key]]
                  newList.push(tempFilename)
                  onChange(newList)
                  setTempFilename('')
                }}
              >
                <Add />
              </IconButton>
            </div>
            {stateObj[stateObj.editorSelected][fieldObj.key].map((fname, idx) =>
              <div key={idx} style={{display:'flex', flexDirection:'row', alignItems:'center', marginLeft: 30, gap:10}}>
                <TextField
                  type={'string'}
                  className={classes.textInputField}
                  value={fname}
                  onChange={(e) => {
                    var newList = [...stateObj[stateObj.editorSelected][fieldObj.key]]
                    newList[idx] = e.target.value
                    onChange(newList)
                  }}
                />
                <IconButton
                  size='small'
                  aria-label='feedback'
                  disableRipple
                  onClick={() => {
                    var newList = [...stateObj[stateObj.editorSelected][fieldObj.key]]
                    newList.splice(idx, 1)
                    onChange(newList)
                  }}
                >
                  <Delete />
                </IconButton>
              </div>)
            }
          </div>
        )
      default:
        throw new Error(`Unhandled action type: ${fieldObj.type.toString()}`)
    }
  }
  return (
    <div style={{marginBottom: 3}}>
      {getField()}
    </div>
  )
}

const GenericManifest = ({stateObj, editorDispatch, manifestObj, setEditorSaveStatus}) => {
  const classes = useStyles()

  const onChange = (value, key) => {
    if (key !== 'questions' && key !== 'flow'){
      var formattedValue = null
      if (key === 'xp'){
        let val = value
        if (value < 0) {
          val = -value
        } else if (!value) {
          val = 0
        }
        formattedValue = Number.parseFloat(val).toFixed(0)
      } else {
        formattedValue = value
      }
      editorDispatch({type: MissionEditorActions.SET_MANIFEST_VAL, value: formattedValue, key: key})
    } else {
      editorDispatch({type: MissionEditorActions.SET_MANIFEST_VAL, value: value, key: key})
    }
    setEditorSaveStatus('waiting')
  }

  return (
    manifestObj.map((fieldObj) => {
      return (
        <GenericField
          key={fieldObj.key}
          classes={classes}
          stateObj={stateObj}
          onChange={e => onChange(e, fieldObj.key)}
          fieldObj={fieldObj}
        />
      )
    })
  )
}

function CompleteToolFloatingPanel({content}) {
  return (
    <CompletionPanelDialogContainer
      open={true}
      onClose={() => {}}
    >
      <div style={{overflow:'hidden'}}>
        <DialogContent style={{maxHeight: 600, overflowY: 'auto'}}>
          <FiriaMarkdown>
            {content}
          </FiriaMarkdown>
        </DialogContent>
        <DialogActions><Button disabled>Next</Button></DialogActions>
      </div>
    </CompletionPanelDialogContainer>)
}

function CodeParseConsoleLogButton() {
  const [editorState] = useMissionEditor()
  const goal = editorState?.tabs[editorState?.tabSelected]
  const validatorType = goal?.validatorType
  const validatorIsCodeParse = validatorType === 'CODE_PARSE'

  async function onClick() {
    console.log(getParsedCodeMap(editorState.objective.solution))
  }

  if (!validatorIsCodeParse) {
    return null
  }

  return (
    <Button style={{margin: 5}} variant='outlined' onClick={onClick}>
      {'Print Map'}
    </Button>
  )
}

export default function EditMissionPanel(props) {
  const editorRef = useRef(null)
  const panelElement = useRef(null)
  const testRef = useRef(null)
  // const [editorState, editorUpdateState] = useEditorPanel()
  const [userConfigState] = useUserConfig()
  const altNavSkipped = useRef(null)
  const monacoKeyListener = useRef(null)
  const saveTimeout = useRef(0)

  const classes = useStyles()
  const [codePanelState, codePanelDispatch] = useCodePanel()
  const [editorState, editorDispatch] = useMissionEditor()
  const [debuggerState, debuggerDispatch] = useDebugger()

  missionEditorController.setSaveSuccessCb(()=>props.setEditorSaveStatus('success'))

  const handleValidatorUpdate = (editorContent, event) => {
    // .25 second timeout before saving to file, save code was working with no timeout, but
    // adding one doesn't effect the user experience and reduces filesystem call considerably
    clearTimeout(saveTimeout.current)
    saveTimeout.current = setTimeout(function() {
      editorDispatch({ type: MissionEditorActions.UPDATE_VALIDATOR, value: editorContent })
    }, 250)
  }

  const handleValidatorDidMount = (editor, monaco) => {}

  const handleEditorUpdate = (editorContent, event) => {
    // .25 second timeout before saving to file, save code was working with no timeout, but
    // adding one doesn't effect the user experience and reduces filesystem calls considerably
    clearTimeout(saveTimeout.current)
    props.setEditorSaveStatus('waiting')
    saveTimeout.current = setTimeout(function() {
      editorDispatch({ type: MissionEditorActions.UPDATE_CODE, code: editorContent })
      if (editorState.toolSelected === 'Walkthrough' && editorState.tabSelected !== null  && testRef.current) {
        editorDispatch({ type: MissionEditorActions.UPDATE_STEP_HEIGHT, value: testRef.current.scrollHeight })
      }
    }, 250)
  }

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor
    editorInstance = editor

    // this allows code to be run from the editor
    codePanelDispatch({
      type: CodePanelActions.EDITOR_INSTANCE_SET,
      editorInstance: editor,
    })

    if (monacoKeyListener.current !== null){
      monacoKeyListener.current.dispose()
    }

    monacoKeyListener.current = editor.onKeyDown(function (e) {
      if (e.altKey && e.code !== 'AltLeft' && e.code !== 'AltRight' ) {
        altNavSkipped.current = true
      }
    })

    // TODO: Need to send the opposite of this when switching screens
    codePanelDispatch({ type: CodePanelActions.EDITOR_READY, ready: true })
  }

  const QUESTION_MANIFEST_OBJ = [
    // {key:'id', label:'Question Unique ID', type:'string'},
    {key:'xp', label:'Xp For Completion', type:'number'},
    {
      key:'type',
      label:'Question Type',
      type:'select',
      options: [{key:'mc', name: 'Multiple Choice'}],
    },
  ]

  const QUIZ_MANIFEST_OBJ = [
    // {key:'id', label:'Quiz Unique ID', type:'string'},
    {key:'title', label:'Quiz Title', type:'string'},
    {
      key:'questions',
      title:'Assigned Questions',
      label:'Question Id',
      type:'picker',
    },
  ]

  const OBJECTIVE_MANIFEST_OBJ = [
    // {key:'id', label:'Objective Unique ID', type:'string'},
    {key:'title', label:'Objective Display Name', type:'string'},
    {key:'xp', label:'Xp for Completion', type:'number'},
    {
      key:'language',
      label:'Code Language',
      type:'select',
      options: [
        {key:'python', name:'Python'},
        {key:'javascript', name:'Javascript'},
      ],
    },
    {
      key:'camera',
      label:'Starting Camera',
      type:'select',
      options: cameraList.map((cam, camId) => {
        return {key:camId, name:cam.name}
      }),
    },
    {
      key:'scene',
      label:'Simulator Scene',
      type:'select',
      options: environmentList.map((env, envId) => {
        return {key:envId, name:env.name}
      }),
    },
    {
      key:'target',
      label:'Target Interface',
      type:'select',
      options: Object.keys(TargetNames).map((tgtName) => {
        return {key:tgtName, name:TargetNames[tgtName]}
      }),
    },
    {
      key:'files',
      label:'Injected Files',
      type:'list',
    },
    {
      key:'sceneParams',
      label:'Scene Params',
      type:'json',
    },
  ]

  const MISSION_MANIFEST_OBJ = [
    {key:'id', label:'Mission Unique ID', type:'string'},
    {key:'title', label:'Mission Display Name', type:'string'},
    {key:'avatar', label:'Mission Avatar Name', type:'string'},
    {
      key:'flow',
      title:'Assigned Objectives and Quizes',
      label:'Id',
      type:'picker',
    },
  ]

  // TODO
  // There is probably a way to reuse this object, which would avoid importing the icons from DebugButtons
  const runStateIcon = {
    [TargetStates.STOPPED]: (<PlayArrowOutlinedIcon style={{fontSize: 24, color: '#89d185'}}/>),
    [TargetStates.STOPPING]: (<PlayArrowOutlinedIcon style={{fontSize: 24, color: '#89d185'}}/>),
    [TargetStates.LOADING]: (<CircularProgress size={13} style={{ position: 'absolute', padding:0, margin:0, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null}}/>),
    [TargetStates.RUNNING]: (<StopIcon style={{fontSize: 22, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null}}/>),
    // You must account for ALL possible state to prevent the button from disappearing
    [TargetStates.AWAITING_INPUT]: (<PlayArrowOutlinedIcon style={{fontSize: 24, color: '#89d185'}}/>),
  }

  function EditorTab(props) {
    var title = props.title
    if (editorState.multipleTabs) {
      title = props.title + ' ' + (props.index + 1)
    }

    return (
      <div
        className={classes.tabContainer}
        onClick={() => {
          editorDispatch({type: MissionEditorActions.SELECT_TAB, idx: props.index})
        }}
      >
        <div
          key={props.index}
          className={`${classes.tab} ${props.focused ? userConfigState.theme === 'dark' ? classes.tabFocusedDark : classes.tabFocusedLight : classes.tabUnfocused}`}
        >
          <Typography
            className={classes.fileNameText}
            noWrap
          >
            {title}
          </Typography>
        </div>
      </div>
    )
  }

  function NoTabsMessage(props) {
    return (
      <>
        <div className={classes.noFileMessage} >
          <Typography
            className={classes.noFileMessage}
          >
            {'There are no ' + editorState.tabName + 's yet.'}
          </Typography>
          <Typography
            className={classes.noFileMessage}
          >
            {'If you want to add one press the \'+\' button!'}
          </Typography>
        </div>
      </>
    )
  }

  function EditorValidatorTab(props) {
    return (
      <div
        className={classes.tabContainer}
      >
        <div
          className={`${classes.tab} ${userConfigState.theme === 'dark' ? classes.tabFocusedDark : classes.tabFocusedLight}`}
        >
          <Typography
            className={`${classes.fileNameText}`}
            noWrap
          >
            {'Validator Code'}
          </Typography>
        </div>
      </div>
    )
  }

  function EditorCodePanelTabs(props) {
    if (!editorState.showCode) {
      return null
    }

    return (
      <>
        {
          editorState.tabs.map((tab, index) => {
            return (
              <EditorTab
                key={index}
                title={tab.title}
                index={index}
                focused={editorState.tabSelected === index}
              />
            )
          })
        }
        {
          editorState.multipleTabs ?
            <Tooltip title={'Add ' + editorState.tabName} placement='right' arrow>
              <IconButton
                size='small'
                className={`${classes.small} ${classes.left}`}
                aria-label='feedback'
                disableRipple
                onClick={() => editorDispatch({type: MissionEditorActions.ADD_TAB})}
              >
                <Add />
              </IconButton>
            </Tooltip> : null
        }
        {
          editorState.multipleTabs && editorState.tabSelected !== null ?
            <Tooltip title={'Delete ' + editorState.tabName} placement='right' arrow>
              <IconButton
                size='small'
                className={`${classes.small} ${classes.right}`}
                aria-label='feedback'
                disableRipple
                onClick={() => editorDispatch({type: MissionEditorActions.DELETE_TAB})}
              >
                <Delete />
              </IconButton>
            </Tooltip> : null
        }
        {
          editorState.editorSelected === 'objective' && editorState.toolSelected === 'Solution' ?
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div></div>
              <div style={{marginTop: 2}}>
                <IconButton
                  onClick={() => debuggerDispatch({ type:DebuggerActions.RUN })}
                  disabled={!codePanelState.editorReady || (!codePanelState.tabs.focused && debuggerState.targetState !== TargetStates.RUNNING) }
                  className={classes.iconButton}
                  style={{marginRight:3}}
                >
                  {debuggerState.mode === TargetModes.RUN ? runStateIcon[debuggerState.targetState]:runStateIcon[TargetStates.STOPPED]}
                </IconButton>
              </div>
            </div> :
            null
        }
      </>
    )
  }

  var valNotes = null
  if (editorState.toolSelected === 'Goals' && editorState.tabSelected !== null) {
    valNotes = ValidatorEditorNotes.find(el => el.id === editorState.tabs[editorState.tabSelected].validatorType)
  }

  const currentTitle = editorState.editorSelected === 'question' ? editorState.question.type + ' Question' : editorState[editorState.editorSelected].title

  return (
    <div
      ref={panelElement}
      id='coding-panel'
      className={classes.fullHeight}
      onDragOver={(e) => {
        e.preventDefault()
      }}
      onDrop={async (e) => {
        e.preventDefault()
        if (editorState.toolSelected === 'Instructions' && codePanelState.editorReady) {
          missionEditorController.uploadImage(e.dataTransfer.files[0], codePanelState.editorInstance)
        }
      }}
    >
      <p style={{margin: '0.2em', fontSize:'0.9em'}}>{editorState[editorState.editorSelected].id + ': ' + currentTitle}</p>
      <Divider />
      {editorState.toolSelected === 'Manifest' ?
        <GenericManifest
          editorDispatch={editorDispatch}
          stateObj={editorState}
          manifestObj={
            editorState.editorSelected === 'objective' ? OBJECTIVE_MANIFEST_OBJ :
              editorState.editorSelected === 'question' ? QUESTION_MANIFEST_OBJ :
                editorState.editorSelected === 'quiz' ? QUIZ_MANIFEST_OBJ :
                  editorState.editorSelected === 'mission' ? MISSION_MANIFEST_OBJ:
                    []
          }
          setEditorSaveStatus={props.setEditorSaveStatus}
        /> :
        null
      }
      {editorState.toolSelected === 'Walkthrough' && editorState.tabSelected !== null ?
        <TextField
          type={'number'}
          className={classes.textInputField}
          label='Code Walkthrough Marker (Integer)'
          value={editorState.tabs[editorState.tabSelected].walkthroughMarker}
          onChange={(e) => {
            let val = e.target.value
            if (e.target.value < 0) {
              val = -e.target.value
            } else if (!e.target.value) {
              val = 0
            }

            editorDispatch({type: MissionEditorActions.CHANGE_STEP_MARKER, marker: Number.parseFloat(val).toFixed(0)})
          }}
        /> : null
      }
      {editorState.toolSelected === 'Answers' && editorState.tabSelected !== null ?
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
          <div>
            <InputLabel shrink id='demo-simple-select-label'>Answer Correct</InputLabel>
            <Switch
            // className={classes.textInputField}
              checked={editorState.tabs[editorState.tabSelected].correct ? true : false}
              onChange={e =>
                editorDispatch({type: MissionEditorActions.CHANGE_ANSWER_CORRECTNESS, correct: e.target.checked})
              }
              name='checkedA'
            />
          </div>
          <TextField
            className={classes.textInputField}
            label='Answer Unique ID'
            value={editorState.tabs[editorState.tabSelected].key}
            onChange={e =>
              editorDispatch({type: MissionEditorActions.CHANGE_ANSWER_KEY, key: e.target.value})
            }
          />
        </div>: null
      }
      <EditorCodePanelTabs />
      {editorState.tabSelected !== null && editorState.showCode ?
        <Editor
          height={editorState.toolSelected === 'Goals'? '8vh': '70vh'}
          onMount={() => {}}
          theme={'vs-'+userConfigState.theme} // "dark or "light"
          language={editorState.tabs[editorState.tabSelected].language}
          value={editorState.tabs[editorState.tabSelected].code}
          options={{automaticLayout: true}}
          onChange={handleEditorUpdate}
        /> : null
      }
      {editorState.toolSelected === 'Goals' && editorState.tabSelected !== null ?
        <>
          <InputLabel shrink id='validator-type-select-label'>Validator Type</InputLabel>
          <Select
            labelId='validator-type-select-label'
            value={editorState.tabs[editorState.tabSelected].validatorType}
            onChange={(e) => {
              editorDispatch({type: MissionEditorActions.SET_VALIDATOR_TYPE, value: e.target.value})
            }}
          >
            {
              ValidatorEditorNotes.map(val => <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>)
            }
          </Select>
          <Divider />
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <div>
              <div style={{fontSize:'0.9em'}}>
                <p>{'Validator Code type below: ' + valNotes.input}</p>
                {valNotes.input === 'func' ?
                  <>
                    <p>{'Function params: ' + valNotes.params.join(', ')}</p>
                    <p>{'Return required: ' + valNotes.ret}</p>
                  </> : null
                }
              </div>
              {valNotes.id === 'CONTEXT_EV' || valNotes.id === 'CONTEXT_SINGLE' ?
                <>
                  <InputLabel shrink id='context-event-select-label'>Context Event</InputLabel>
                  <Select
                    labelId='context-event-select-label'
                    value={editorState.tabs[editorState.tabSelected].other}
                    onChange={(e) => {
                      editorDispatch({type: MissionEditorActions.SET_VALIDATOR_OTHER, value: e.target.value})
                    }}
                  >
                    {
                      ValidatorEditorContextActions.map(val => <MenuItem key={val} value={val}>{val}</MenuItem>)
                    }
                  </Select>
                </>: null
              }
              {valNotes.id === 'MODEL_CHANGE' || valNotes.id === 'POLL_BACKEND' ?
                <TextField
                  className={classes.textInputField}
                  label={valNotes.id === 'MODEL_CHANGE' ? 'Model Name' : 'Poll Interval (ms)'}
                  value={editorState.tabs[editorState.tabSelected].other}
                  onChange={(e) => {
                    editorDispatch({type: MissionEditorActions.SET_VALIDATOR_OTHER, value: e.target.value})
                  }}
                /> : null
              }
            </div>
            <CodeParseConsoleLogButton />
          </div>
          {valNotes.input !== 'none' ?
            <>
              <Divider />
              <EditorValidatorTab />
              <Editor
                height='30vh'
                onMount={handleValidatorDidMount}
                theme={'vs-'+userConfigState.theme} // "dark or "light"
                language={'javascript'}
                value={editorState.tabs[editorState.tabSelected].validator}
                options={{automaticLayout: true}}
                onChange={handleValidatorUpdate}
              />
            </> : null
          }
        </> : null
      }
      {editorState.showCode && editorState.tabs.length === 0 ? <NoTabsMessage /> : null}
      {editorState.showCode && editorState.multipleTabs && editorState.tabSelected !== null && editorState.toolSelected === 'Walkthrough' ?
        <div className={classes.speechBubble} >
          <div className={classes.arrow} />
          <div className={classes.codestepMarkdownContainer} >
            <div ref={testRef} >
              <FiriaMarkdown>{editorState.tabs[editorState.tabSelected].code}</FiriaMarkdown>
            </div>
          </div>
        </div> : null
      }
      {editorState.showCode && editorState.multipleTabs && editorState.tabSelected !== null && editorState.toolSelected !== 'Walkthrough' && editorState.toolSelected !== 'Goals' ?
        <div ref={testRef} >
          <FiriaMarkdown>{editorState.tabs[editorState.tabSelected].code}</FiriaMarkdown>
        </div> : null
      }
      {editorState.toolSelected === 'Complete' ?
        <CompleteToolFloatingPanel content={editorState.tabs[editorState.tabSelected].code}/> : null}
      <DebuggerEditor
        open={editorState.toolSelected === 'Goals' || editorState.toolSelected === 'Solution'}
        editorState={editorState}
        classes={classes}
        userConfigState={userConfigState}
        handleEditorUpdate={handleEditorUpdate}
        codePanelDispatch={codePanelDispatch}
        handleEditorDidMount={handleEditorDidMount}
      />
    </div>
  )
}
