import Cookies from 'js-cookie'
import { setUserSessionId } from './userAuthStore'

const runningLocally = (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '')
const staging = window.location.hostname.startsWith('dev-')

const cookieName = staging ? 'dev-FiriaSimSessionCookie' : 'FiriaSimSessionCookie'

const cookieAttributes = Object.freeze({
  path: '/',
  sameSite: 'Lax',
  secure: true,
  domain: (runningLocally ? 'localhost' : 'firialabs.com'),
})

const setUserSessionIdCookie = async (token, days) => {
  setUserSessionId(token)
  Cookies.set(cookieName, token || '', {
    ...cookieAttributes,
    ...(days && { expires: days }),
  })
}
const getUserSessionIdFromCookie = () => {
  return Cookies.get(cookieName) ?? null
}
const eraseUserSessionIdCookie = () => {
  setUserSessionId(null)
  Cookies.remove(cookieName, cookieAttributes)
}

export { setUserSessionIdCookie, getUserSessionIdFromCookie, eraseUserSessionIdCookie }