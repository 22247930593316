import regexReplaceAll from './regexReplaceAll'

// Want to make a markdown a bullet item? formatBullets goes through all bullet points
// and sequence items (1. 2. 3.)... and adds 4 spaces before them.
const insertBullets = ({ md }) => {
  var bulletList = md.split('\r\n* ')
  // console.log('pre', bulletList)
  bulletList = bulletList.map((bulletItem) => {
    // If there isn't a double line end before the bullet point
    if (bulletItem.slice(-2) !== '\r\n'){
      bulletItem += '\r\n'
    }

    // If theres a line end without space following, it'll put the line in a code block
    bulletItem = bulletItem.split('\r\n').join('\r\n ')

    // If theres a numbered list, put the appropriate spacing before the list.
    bulletItem = regexReplaceAll({regex: /[^\S\r\n]*[0-9]\.\s/, length: 2, str: bulletItem})

    return bulletItem
  })
  // console.log('post', bulletList)
  const fin = bulletList.join('\r\n    * ')
  return fin
}

export default insertBullets