import UserFilenames from '../../databaseworker/userFilenames'
import { appDefaults } from '../../BotSimDefaults'
import { userFileUseCases } from './user-file-use-cases'
import { userFilePresenter } from './user-file-presenter'
import { userFileStore } from './user-file-store'

class UserFileController {
  async initializeUserFiles() {
    return await Promise.allSettled([
      userFileUseCases.initializeUserFile(UserFilenames.EDITOR_STATE, appDefaults.codePanel),
      userFileUseCases.initializeUserFile(UserFilenames.USER_CONFIG, appDefaults.userConfig),
      userFileUseCases.initializeUserFile(UserFilenames.USER_PROGRESS, appDefaults.userProgress),
    ])
  }

  async handleUserProgressFetchErrorState() {
    userFileStore.setSavingDisabled(true)
    userFilePresenter.presentFileError()
  }
}

const userFileController = new UserFileController()
export { userFileController }