// Front Porch
import { Entity } from '../../SimScene'
import * as BABYLON from '@babylonjs/core'
import {
  assetPath, makeImpostor, disposePromise, loadGLB, hitCache,
  loadGLBWithPhysics, loadSoundCachedAsync, setPhysicsNoContactCollision,
} from '../../utils/BabylonUtils'
import FrontPorchModel from '../../assets/HauntedHouse/FrontPorch.glb'
import LeafModel from '../../assets/HauntedHouse/Leaf.glb'
import SpiderModel from '../../assets/HauntedHouse/Spider.glb'
import RatModel from '../../assets/HauntedHouse/Rat.glb'
import PbrLightingTextureEnv from '../../assets/MistyBrightSky.env'
import SpiderDieSound from '../../assets/HauntedHouse/sounds/SpiderDeath.mp3'
import FrontPorchTheme from '../../assets/HauntedHouse/sounds/Porch.mp3'
import SpiderRustleSound from '../../assets/HauntedHouse/sounds/SpiderRustle.mp3'
import RatTranceSound from '../../assets/HauntedHouse/sounds/RatTrance.mp3'
import RatRedemptionSound from '../../assets/HauntedHouse/sounds/RatRedemption.mp3'
import RatBlastSound from '../../assets/HauntedHouse/sounds/RatBlast.mp3'
import LevelEndSound from '../../assets/HauntedHouse/sounds/LevelEnd.mp3'

const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7
const OBSTACLE_FRICTION = 5.9
const nonPbrLights = false

class SimLights extends Entity {
  load = async (scene) => {
    this.scene = scene

    // Ambient lighting provided by HDR environment
    // this.envTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(PbrLightingTextureEnv, scene)
    this.envTexture = await hitCache('Env_MistyBrightSky', () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(PbrLightingTextureEnv, scene)
    })

    scene.environmentTexture = this.envTexture
    // Adjust scene exposure level for PBR materials
    scene.environmentIntensity = 0.15

    // Directional shadow-casting light. For crisp shadows, position light so raycast from it in specified
    // direction will be occluded by shadow-casters of interest (e.g. CodeBot)
    this.light = null
    this.shadowGenerator = null
    if (nonPbrLights) {
      this.light = new BABYLON.DirectionalLight('dir01', new BABYLON.Vector3(-1, -1, 0), scene)
      this.light.position = new BABYLON.Vector3(18.2, 9.4, 10.4)
      this.light.intensity = 0.5

      this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light)
      this.shadowGenerator.useExponentialShadowMap = true
    }

    // Fog - Makes some of the crisp graphic details less "pretty" to show off. BUT it does add spooky feel...
    scene.fogMode = BABYLON.Scene.FOGMODE_EXP2
    scene.fogColor = new BABYLON.Color3(0.9, 0.9, 0.85)
    scene.fogDensity = 0.003
  }

  getShadowGenerators = () => {
    return nonPbrLights ? [this.shadowGenerator] : []
  }

  unload = async () => {
    const disposeList = [this.shadowGenerator, this.light]
    await Promise.all(disposeList.map(disposePromise))
    this.scene.environmentTexture = null
    this.scene.fogMode = BABYLON.Scene.FOGMODE_NONE
  }
}

class SimGround extends Entity {
  makeCheckpoint = (mesh) => {
    if (mesh.name.includes('Checkpoint')) {
      mesh.visibility = false
      // Use physics engine to detect collisions but pass-through (a "trigger volume")
      mesh.setParent(null)
      mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: GROUND_FRICTION, group: 0, mask: 0 })
      setPhysicsNoContactCollision(mesh.physicsImpostor)
      BABYLON.Tags.AddTagsTo(mesh, 'BotCollider')
    }
  }

  load = async (scene, params) => {
    // Create the invisible ground parent mesh
    this.ground = BABYLON.Mesh.CreateGround('ground', 200, 200, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions
    this.ground.isVisible = false

    let path, name
    [path, name] = assetPath(FrontPorchModel)

    // Import the environment
    this.frontPorch = await hitCache('FrontPorchModels', async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync (path, name, scene)
    })
    // Currently NOT cloning environment model, instead trying to keep makeImpostor() and other
    // operations that modify the mesh idempotent. That means we can't dispose this, and instead
    // must add/remove it from scene.
    this.frontPorch.addAllToScene()
    const meshes = this.frontPorch.meshes
    this.rootMesh = meshes[0]

    // Set the ground mesh as the root node's parent
    this.rootMesh.parent = this.ground

    // Recurse through all meshes in this scene
    await Promise.all(meshes.map(async (mesh) => {
      makeImpostor(mesh, this.ground, { mass: 0, friction: OBSTACLE_FRICTION })
      this.makeCheckpoint(mesh)

      if (mesh.name === 'InteriorVeil') {
        this.interiorVeil = mesh
        mesh.material.emissiveColor.g = 0.0  // Reset glow
    
      } else if (mesh.name === 'Envelope') {
        mesh.isVisible = params?.showEnvelope
      }

      mesh.freezeWorldMatrix()
    }))

    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
    this.ground.freezeWorldMatrix()
  }

  unload = async () => {
    // Don't dispose environment model, just remove from scene
    this.rootMesh.parent = null
    this.frontPorch.removeAllFromScene()

    await disposePromise(this.ground)
  }
}

class SimObstacles extends Entity {
  constructor(mainEnv) {
    super()
    this.env = mainEnv
    this.ground = mainEnv.ground
    this.musicVolume = 0.5
    this.sfxVolume = 0.5
  }

  setVolume = (volumeParams) => {
    // console.log('New Volume is: ', volumeParams)
    this.musicVolume = volumeParams[0]
    this.sfxVolume = volumeParams[1]
    this?.music && this.music.setVolume(this.musicVolume)
    this?.sfxTrack && this.sfxTrack.setVolume(this.sfxVolume)
  }

  loadSounds = async (scene, params) => {
    // Load sounds and return list of them
    this.sounds = []

    // Ambient sound can start later when it's fully loaded... don't await it.
    this.music = await hitCache('FrontPorchTheme', () => {
      return new BABYLON.Sound('frontPorchTheme', FrontPorchTheme, scene, null, {
        loop: true,
        autoplay: true,
        volume: this.musicVolume,
      })
    })
    this.music.play()

    // Create a Soundtrack to manage volume for all sound effects
    this.sfxTrack = new BABYLON.SoundTrack(scene, { volume: this.sfxVolume })
    this.sounds.push(this.sfxTrack)

    // Settings for SFx sounds attached to mesh objects
    const spatialSoundOptions = {
      loop: false,
      spatialSound: true,
      distanceModel: 'exponential',
      rolloffFactor: 3,
      refDistance: 3,
    }

    // Note some SFx are spatial (cloned later and attached to meshes) and some are "global"
    // The SFx are cached, not disposed or added to SoundTrack. Objects will clone them for use as needed.
    const soundPromises = []
    soundPromises.push(loadSoundCachedAsync('spiderDieSound', SpiderDieSound, scene, { ...spatialSoundOptions, volume: 1.0 }))
    soundPromises.push(loadSoundCachedAsync('spiderRustleSound', SpiderRustleSound, scene, { ...spatialSoundOptions, volume: 0.3, loop: true }))
    soundPromises.push(loadSoundCachedAsync('ratRedemptionSound', RatRedemptionSound, scene, { volume: 1.0 }))
    soundPromises.push(loadSoundCachedAsync('ratBlastSound', RatBlastSound, scene, { volume: 1.0 }))
    soundPromises.push(loadSoundCachedAsync('ratTranceSound', RatTranceSound, scene, { ...spatialSoundOptions, volume: 0.7, loop: true }))
    soundPromises.push(loadSoundCachedAsync('levelEndSound', LevelEndSound, scene, { volume: 1.0 }))
    this.sfxSounds = await Promise.all(soundPromises)

    this.sfxSounds.forEach((sfx) => {
      this[sfx.name] = sfx  // convenience access via object properties
    })
    this.sfxTrack.setVolume(this.sfxVolume)
  }

  playLevelEndSound = () => {
    this.levelEndSound.play()
  }

  windGust = () => {
    // Leaf blower! Check for gust at intervals (e.g. 1sec)
    const gustProbability = 0.5
    const leafMoveProbability = 0.5
    if (Math.random() < gustProbability) {
      // Sometimes it gusts
      const gustStrength = Math.random()
      const leafWindSpot = BABYLON.Vector3.Zero()
      const windDirection = new BABYLON.Vector3(0, 0.007 * gustStrength, -0.002 * gustStrength)
      for (const lf of this.leaves) {
        // Only some leaves are affected
        if (Math.random() < leafMoveProbability) {
          // console.log('blow:', lf)
          if (!lf.physicsImpostor) {
            console.log(`Error: leaf ${lf.name} has no impostor:`, lf, this.leaves)
          } else {
            lf.physicsImpostor.applyImpulse(windDirection, leafWindSpot)
          }
        }
      }
    }
  }

  loadLeaf = async (index, position, scaling) => {
    const leaf = await loadGLBWithPhysics(LeafModel, `leaf${index}`, { mass: 0.1, friction: 0.1, restitution: 0.8 }, scaling, position)
    return leaf
  }

  loadLeaves = async (scene, params) => {
    this.leaves = []
    this.timers.push(setInterval(this.windGust, 1000))

    // Scatter leaves
    const leafZoneOrg = [-35, -12]
    const leafZoneRng = [67, 42]
    const numLeaves = 40
    const leafLoadPromises = []
    for (let i = 0; i < numLeaves; ++i) {
      const posX = leafZoneOrg[0] + leafZoneRng[0] * Math.random()
      const posZ = leafZoneOrg[1] + leafZoneRng[1] * Math.random()
      const pos = new BABYLON.Vector3(posX, 0.3, posZ)
      const scale = 0.5 + Math.random()
      leafLoadPromises.push(this.loadLeaf(i, pos, scale))
    }
    this.leaves = await Promise.all(leafLoadPromises)

    // Attach callbacks so leaves vanish when off porch
    this.leaves.forEach((leaf) => {
      leaf.physicsImpostor.registerOnPhysicsCollide(this.ground.ground.physicsImpostor, (self, collider) => {
        const lfX = self.object.position.x
        const lfZ = self.object.position.z
        if (lfX < leafZoneOrg[0] || lfX > leafZoneOrg[0] + leafZoneRng[0] ||
          lfZ < leafZoneOrg[1] || lfZ > leafZoneOrg[1] + leafZoneRng[1]) {
          // console.log(`${self.object.name} outside zone`)
          self.object.dispose()
          const leafIdx = this.leaves.indexOf(self.object)
          this.leaves.splice(leafIdx, 1)  // remove from list
        }
      })
    })
  }

  loadSpider = async (index, position, scaling, rotation, scene) => {
    const spider = await loadGLB(SpiderModel, `spider${index}`, scaling, position, rotation, scene)
    return spider
  }

  loadSpiders = async (scene, params) => {
    const spiderLoadPromises = params.spiders.map((spider, index) => {
      const spiderPos = new BABYLON.Vector3(...spider.pos)
      const spiderRot = new BABYLON.Vector3(0, spider.rot * Math.PI / 180, 0)
      return this.loadSpider(index, spiderPos, spider.scale, spiderRot, scene)
    })
    this.spiders = await Promise.all(spiderLoadPromises)
  }

  configureSpiders = () => {
    // Configure spiders
    this.spiders.forEach((spider) => {
      // Tag spider colliders
      // Spider is non-physics mesh, so tag colliding body as BotCollider
      const spiderBody = spider.getChildMeshes(false, m => m.name === 'Spider')?.[0]
      spiderBody.name = spider.name + '_body'
      BABYLON.Tags.AddTagsTo(spiderBody, 'BotCollider')

      // Init animations (Firia added data members animXXX)
      spider.animAttack = spider.animationGroups.find(ag => ag.name === 'Attack')
      spider.animDie = spider.animationGroups.find(ag => ag.name === 'die')
      // First animation (Attack) will loop automatically. Set each spider to a random
      // frame within the animation, so they're not all perfectly in sync.
      const randFrame = Math.random() * (spider.animAttack.to - spider.animAttack.from)
      spider.animAttack.goToFrame(randFrame)
      spider.animAttack.speedRatio = 0.5 + Math.random() * 0.5
      spider.animCurrent = spider.animAttack
      spider.animCurrent.play(true)

      // Handle collisions
      spiderBody.botCollision = () => {
        // Play death animation and sound, then dispose
        if (spider?.dying) {
          return  // Ignore duplicate collision events
        }
        spider.dying = true
        spider.animCurrent.stop()
        spider.animCurrent = spider.animDie
        spider.animCurrent.play(false)
        spider.animCurrent.onAnimationGroupEndObservable.addOnce(() => {
          this.timers.push(setTimeout(() => {
            spider.dispose()
          }, 1000))
        })
        spider.soundRustle.stop()
        spider.soundDie.play()
      }

      // Add spider sounds (Firia added data members soundXXX)
      spider.soundDie = this.spiderDieSound.clone()
      spider.soundDie.attachToMesh(spider)
      this.sfxTrack.addSound(spider.soundDie)
      this.sounds.push(spider.soundDie)

      spider.soundRustle = this.spiderRustleSound.clone()
      spider.soundRustle.attachToMesh(spider)
      this.sfxTrack.addSound(spider.soundRustle)
      this.sounds.push(spider.soundRustle)
      spider.soundRustle.play()
    })
  }

  loadRat = async (index, position, scaling, rotation, scene) => {
    const rat = await loadGLB(RatModel, `rat${index}`, scaling, position, rotation, scene)
    return rat
  }

  findRat = (firstName) => {
    const index = this.params.rats.findIndex(r => r.name === firstName)
    return index < 0 ? null : this.rats[index]
  }

  redeemRat = (firstName) => {
    const rat = this.findRat(firstName)
    if (!rat) {
      console.log(`Warning: redeemRat(${firstName}) not found`)
      return
    }

    if (rat?.redeeming) {
      return
    }
    rat.redeeming = true

    // Not sure I love how this jerks the camera...
    this.scene.activeCamera.setTarget(rat.position)

    // No redeem animation, but a janky substitute...
    // rat.animCurrent.stop()
    const {ratBody, ratEyes} = rat.animRedeemProps
    ratBody.material.emissiveIntensity = 0.8
    ratEyes.material.emissiveIntensity = 0.0  // Go black

    rat.soundCurrent.stop()
    rat.soundCurrent = this.ratRedemptionSound
    rat.soundCurrent.play()

    this.timers.push(setTimeout(() => {
      // TODO: Groovy exit animation!
      rat.dispose()
    }, 3000))
  }

  loadRats = async (scene, params) => {
    const ratLoadPromises = params.rats.map((rat, index) => {
      const ratPos = new BABYLON.Vector3(...rat.pos)
      const ratRot = new BABYLON.Vector3(0, rat.rot * Math.PI / 180, 0)
      return this.loadRat(index, ratPos, rat.scale, ratRot, scene)
    })
    this.rats = await Promise.all(ratLoadPromises)
  }

  configureRats = () => {
    this.rats.forEach((rat) => {
      // Tag rat colliders
      // Rat is non-physics mesh, so tag colliding body as BotCollider
      const ratBody = rat.getChildMeshes(false, m => m.name === 'Plane007_primitive0')?.[0]
      BABYLON.Tags.AddTagsTo(ratBody, 'BotCollider')

      const ratEyes = rat.getChildMeshes(false, m => m.name === 'Plane007_primitive1')?.[0]
      // Make rat body non-emissive, with blue emissivity
      ratBody.material.emissiveColor = BABYLON.Color3.Blue().toGammaSpace()
      ratBody.material.emissiveIntensity = 0

      // Had no luck with creating material animation commented-out below. Tried attaching
      // animation to mesh as well as directly to PBRMaterial. Neither worked.
      // Dropping back to squirreling away the meshes to directly tweak in the redeem callback.
      rat.animRedeemProps = {'ratBody': ratBody, 'ratEyes': ratEyes}

      // // Create animation for eyes to fade to black and body to cycle emmissive glow
      // const animEyeFade = new BABYLON.Animation('eyefade', 'emmissiveIntensity', 30,
      //   BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT)
      // animEyeFade.setKeys([
      //   { frame: 0, value: 1 },
      //   { frame: 240, value: 0 },
      // ])
      // const animBodyGlow = new BABYLON.Animation('bodyglow', 'emmissiveIntensity', 30,
      //   BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)
      // animBodyGlow.setKeys([
      //   { frame: 0, value: 0 },
      //   { frame: 15, value: 1 },
      //   { frame: 30, value: 0 },
      //   { frame: 45, value: 1 },
      //   { frame: 60, value: 0 },
      // ])
      // rat.animRedeem = new BABYLON.AnimationGroup('Redeem')
      // rat.animationGroups.push(rat.animRedeem)  // So new group will get disposed (TODO: confirm this)
      // rat.animRedeem.addTargetedAnimation(animEyeFade, ratEyes.material)
      // rat.animRedeem.addTargetedAnimation(animBodyGlow, ratBody.material)

      // Init animations (Firia added data members animXXX)
      rat.animMain = rat.animationGroups[0]
      // Main animation will loop automatically. Set each rat to a random
      // frame within the animation, so they're not all perfectly in sync.
      const randFrame = Math.random() * (rat.animMain.to - rat.animMain.from)
      rat.animMain.goToFrame(randFrame)
      rat.animMain.speedRatio = 0.5 + Math.random() * 0.5
      rat.animCurrent = rat.animMain
      rat.animCurrent.play(true)

      // Handle collisions
      ratBody.botCollision = (cbImpostor, cbController) => {
        // Collision is deadly for CodeBot!
        // Apply hard vertical impulse to blast bot away
        // Play animation and sound, then dispose
        if (rat?.blasting || rat?.redeeming) {
          return
        }
        rat.blasting = true
        rat.soundCurrent.stop()
        rat.soundCurrent = this.ratBlastSound
        rat.soundCurrent.onEndedObservable.addOnce((ev) => {
          rat.blasting = false
          rat.soundCurrent = rat.soundTrance
          rat.soundCurrent.play()
        })
        rat.soundCurrent.play()
        // Blast Bot!
        const bot = this.env.playerEntity.cbBody
        const blastSpot = BABYLON.Vector3.Zero()
        const xvec = Math.sign(bot.position.x - rat.position.x)
        const zvec = Math.sign(bot.position.z - rat.position.z)
        const blastDirection = new BABYLON.Vector3(4 * xvec, 12, 4 * zvec)  // strong y-component
        bot.applyImpulse(blastDirection, blastSpot)
      }

      // Add rat sounds (Firia added data members soundXXX)
      rat.soundTrance = this.ratTranceSound.clone()
      rat.soundTrance.attachToMesh(rat)
      this.sfxTrack.addSound(rat.soundTrance)
      this.sounds.push(rat.soundTrance)
      rat.soundCurrent = rat.soundTrance
      rat.soundCurrent.play()

      // Rats should turn to face CodeBot
      ratBody.onBeforeRenderObservable.add(() => {
        if (this.env?.playerEntity) {
          const targetMesh = this.env.playerEntity.cbBody
          // Ensure rats only rotate horizontally (around y-axis)
          let projectedTarget = new BABYLON.Vector3(targetMesh.position.x, rat.position.y, targetMesh.position.z)
          rat.lookAt(projectedTarget, -Math.PI / 2)  // rat is offset -90 degrees (lookAt assumes facing toward camera)
        }
      })
    })
  }

  load = async (scene, params) => {
    this.scene = scene
    this.params = params
    this.timers = []  // timer IDs for cleanup
    this.sounds = []
    this.leaves = []
    this.spiders = []
    this.rats = []

    const obstaclePromises = []

    obstaclePromises.push(this.loadSounds(scene, params))
    obstaclePromises.push(this.loadLeaves(scene, params))

    // Add spiders
    if (params.spiders) {
      obstaclePromises.push(this.loadSpiders(scene, params))
    }

    // Add rats
    if (params.rats) {
      obstaclePromises.push(this.loadRats(scene, params))
    }

    await Promise.all(obstaclePromises)

    // Finalize objects after assets loaded
    this.configureSpiders()
    this.configureRats()
  }

  unload = async () => {
    // Stop any active timers
    this.timers.forEach(id => clearTimeout(id))

    // Stop the music... or rather, pause it. Nicer to continue music after each reset while coding.
    this.music.pause()

    const disposeList = [...this.leaves, ...this.spiders, ...this.rats, ...this.sounds]
    await Promise.all(disposeList.map(disposePromise))
  }
}

class FrontPorch extends Entity {
  constructor() {
    super()
    this.name = 'Front Porch'
    this.lights = new SimLights()
    this.ground = new SimGround()
    this.obstacles = new SimObstacles(this)
    this.defaultParams = {
      'bot': [
        {
          'playerPosition': [25, 0, 0],
          'playerRotation': 90,
        },
      ],
      'showEnvelope': false,
      'spiders': [
        // {
        //   'pos': [
        //     13,
        //     0,
        //     -8,
        //   ],
        //   'rot': -90,  // Facing directly toward CB. rot=0 faces toward front yard.
        //   'scale': 0.7,
        // },
      ],
    }
  }
  finalObjectiveHit = () => {
    // Light interior veil
    const veil = this.ground.interiorVeil
    veil.material.emissiveColor.g = 1.0  // Glow Green!
  }
  missionCompleteMusic = () => {
    this.obstacles.playLevelEndSound()
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false

    // Place ground first, so objects don't fall through.
    await this.ground.load(scene, this.params)

    await Promise.all([
      this.lights.load(scene),
      this.obstacles.load(scene, this.params),
    ])
  }
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.ground.unload(),
      this.obstacles.unload(),
    ])
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
  setVolume = (volumeParams) => {
    this.obstacles.setVolume(volumeParams)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController

    // Disallow keyboard CB buttons for this challenge (no driving!)
    if (this.playerController) {
      this.playerController.allowKeyboardButtons = false

      // Not using prox or ls, save CPU
      this.playerController.sensorOverrides.prox = (num) => {}
      this.playerController.sensorOverrides.ls = (num) => {}
    }
  }
}

export default FrontPorch
