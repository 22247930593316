/* Embedded Data Viewer - Show data received from connected device via USB "side channel"
   Initial use-case is images from CodeAIR. Text messages are also supported.

   Hope to add strip-chart object in the future. For that, rather than always creating a new chart, a ChartID
   could be specified. If the ChartID already exists, the new plot data will be appended.
*/
import React, { Fragment, useEffect, useRef } from 'react'
import { crc32_le } from './utils/crc'
import FileDownloadIcon from '@material-ui/icons/GetApp'

function ImageRenderer({ buf, width, height, style }) {
  const canvasRef = useRef(null)
  const imageDataRef = useRef(null)

  useEffect(() => {
    if (buf && width && height && canvasRef.current) {
      const canvas = canvasRef.current
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')

      // Create a Uint8ClampedArray to hold RGBA values
      const imageDataArray = new Uint8ClampedArray(width * height * 4)

      let bufIndex = 0
      for (let i = 0; i < width * height; i++) {
        // Combine two bytes into one 16-bit value
        const highByte = buf[bufIndex++]
        const lowByte = buf[bufIndex++]
        const rgb565 = (highByte << 8) | lowByte

        // Convert RGB565 to RGB888
        const r = ((rgb565 >> 11) & 0x1F) * 0xFF / 0x1F
        const g = ((rgb565 >> 5) & 0x3F) * 0xFF / 0x3F
        const b = (rgb565 & 0x1F) * 0xFF / 0x1F

        // Set RGBA values
        const imageDataIndex = i * 4
        imageDataArray[imageDataIndex] = r
        imageDataArray[imageDataIndex + 1] = g
        imageDataArray[imageDataIndex + 2] = b
        imageDataArray[imageDataIndex + 3] = 255 // Fully opaque
      }

      // Create ImageData from the array
      const imageData = new ImageData(imageDataArray, width, height)
      imageDataRef.current = imageData

      // Render the image on the canvas
      ctx.putImageData(imageData, 0, 0)
    }
  }, [buf, width, height])

  useEffect(() => {
    // Hook visibility change to ensure image is restored if context has been
    // cleared (sometimes happens after idle periods, leaving images blank).
    const handleVisibilityChange = () => {
      if (!document.hidden && canvasRef.current && imageDataRef.current) {
        const ctx = canvasRef.current.getContext('2d')
        ctx.putImageData(imageDataRef.current, 0, 0)
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('pageshow', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('pageshow', handleVisibilityChange)
    }
  }, [])


  const handleDownload = () => {
    const canvas = canvasRef.current
    if (canvas) {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            const link = document.createElement('a')
            link.download = 'image.png'
            link.href = URL.createObjectURL(blob)
            link.click()
            URL.revokeObjectURL(link.href)
          } else {
            console.error('Failed to create Blob from canvas')
          }
        },
        'image/png',
        1.0 // Image quality (for image/jpeg), ignored for PNG
      )
    }
  }

  return (
    <div style={{ position: 'relative', display: 'inline-block', ...style }}>
      <canvas ref={canvasRef} style={{ border: '1px solid black' }}></canvas>
      <FileDownloadIcon
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '50%',
          padding: '4px',
          cursor: 'pointer',
        }}
        onClick={() => handleDownload()}
      />
    </div>
  )
}

export const EdataViewer = ({ edata }) => {
  const [contentList, setContentList] = React.useState([])
  const [lastEdataSeq, setLastEdataSeq] = React.useState(-1)

  if (edata && edata?.seq > lastEdataSeq) {
    setLastEdataSeq(edata.seq)
    const data = edata.data
    let content

    // First 16 bytes of edata is the type descriptor.
    const td = data.substring(0, 16)
    if (td.startsWith('text')) {
      // Nothing else in descriptor. Just grab text from the data payload.
      // Force text to wrap flexbox
      content = (<span style={{flexBasis:'100%'}}>{data.substring(16)}</span>)
    } else if (td.startsWith('image')) {
      // descriptor="imageBWWHH" | B=bits per pixel, WW=width16, HH=height16
      // const bpp = td.charCodeAt(5)
      const width = (td.charCodeAt(6) << 8) | td.charCodeAt(7)
      const height = (td.charCodeAt(8) << 8) | td.charCodeAt(9)
      const imgBuf = Uint8Array.from(data.substring(16), c => c.charCodeAt(0))

      const crc = crc32_le(imgBuf)
      console.log(`Image ${width}x${height} received, crc=${crc.toString(16).padStart(8, '0')}`)

      content = (
        <ImageRenderer buf={imgBuf} width={width} height={height} style={{margin:'0.25em'}}/>
      )
    } else {
      content = (<span>Unknown embedded data type</span>)
    }

    // Append content
    setContentList([...contentList, content])
  }

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'flex-start',
      height: '100%',
      border: 'thin solid gray',
      padding: '0.5em',
      overflow: 'auto',
    }}>
      {contentList.map((item, index) => (
        <Fragment key={index}>
          {item}
        </Fragment>
      ))}
    </div>
  )
}
