import { formatAndSetUserProgress } from './user-progress-use-cases/formatAndSetUserProgress'
import { mergeActiveGroupAndLessonContentSequence } from '../content-director/content-director-use-cases/mergeActiveGroupAndLessonContentSequence'
import { presentContentDirector } from '../content-director/content-director-presenter'
import { userProgressController } from '../../UserProgressControl'
import { presentUserUI } from '../content-director/content-director-presenter'
import { userFileUseCases } from '../user-files/user-file-use-cases'
import { loggedOutLocalStorageController } from '../../databaseworker/database.worker.controller'
import UserFilenames from '../../databaseworker/userFilenames'
import { userIsLoggedIn } from '../user-session/user-session-use-cases/userIsLoggedIn'

// This is being called by the UserProgressControl, since setting/getting progress is so tightly controlled
// by mission context, content-manager's userProgress is just along for the ride.
async function handleUserProgressChanged(userProgress, initialization){
  formatAndSetUserProgress(userProgress, initialization)
  mergeActiveGroupAndLessonContentSequence()
  presentContentDirector()
}

async function initializeUserProgress() {
  return await userProgressController.init(userFileUseCases.saveUserProgress, userFileUseCases.getUserProgress, presentUserUI, !userIsLoggedIn())
}

async function migrateUserProgressOnFirstTimeLogin() {
  const userProgress = await loggedOutLocalStorageController.readObject(UserFilenames.USER_PROGRESS)
  if (!!userProgress) {
    await userFileUseCases.saveUserProgress(userProgress)
  }
}


export {
  handleUserProgressChanged,
  initializeUserProgress,
  migrateUserProgressOnFirstTimeLogin,
}