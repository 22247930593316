// Base classes subscribed to the HTML builder
const baseCSS = `
.MuiSvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  vertical-align: middle;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(MaterialIcons-Regular.ttf);
  src: local('Material Icons'),
  local('MaterialIcons-Regular.ttf'),
  url(MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';
}
`

const formatHTML = (css, md) => {
  return (
    `
<!DOCTYPE html>
<html>
<head>
<meta charset="utf-8">
<style>
${baseCSS}
${css.join('\n')}
</style>
</head>
<body>
${md}
</body>
</html>
  `
  )
}

export default formatHTML