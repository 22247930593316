// Mission and Objective Panels
import React, { useRef, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { PositionedPanel } from './PositionedPanels'
import Markdown from './FiriaMarkdown'
import { IconButton, Button, Tooltip, Divider, MobileStepper } from '@material-ui/core'
import hexToRGBA from './hexToRGBA'
import {
  FilterNone, Place, CheckBoxOutlineBlank, DirectionsWalk, Close,
  EmojiObjects, KeyboardArrowLeft, KeyboardArrowRight, Done,
  Assignment as AssignmentIcon, SkipNext as SkipNextIcon } from '@material-ui/icons'
import PropTypes from 'prop-types'
import RegionalDialog from './RegionalDialog'
import Editor, { loader } from '@monaco-editor/react'
import { useUserConfig } from './contexts/UserConfigContext'
import { useMission, MissionActions } from './contexts/MissionContext'
import { AssignmentSubmissionPanel } from './AssignmentSubmissionPanels/AssignmentSubmissionPanel'
import Checkbox from '@material-ui/core/Checkbox'
import { codeMarkerController } from './CodeMarkerControl'
import { useContentDirector } from './contexts/ContentDirectorContext'
import { codeTrekPath } from './contexts/CodePanelContext'
import { iterateContentSequenceIndex } from './content-manager/content-director/content-director-use-cases/iterateContentSequenceIndex'

loader.config({ paths: { vs: 'monaco-editor/min/vs' } })

const walkStepOutlineColor = '#c8c8c8'

export const useStyles = makeStyles((theme) => {
  return ({
    missionPanel: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    panelHeader: {
      display: 'flex',
      marginBottom: '0.2em',
      justifyContent: 'space-between',
    },
    panelName: {
      marginLeft: '0.5em',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
    },
    panelXp: {
      marginTop: 'auto',
      marginBottom: 'auto',
      marginRight: '0.5em',
      fontSize: '0.75rem',
    },
    collapseButton: {
      marginLeft: 'auto',
      width: 30,
      height: 30,
    },
    noMargin: {
      margin: 0,
    },
    tinyLeftMargin: {
      marginLeft: '2px',
    },
    body: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
      padding: 5,
      overflowX: 'hidden',
    },
    panelFooter: {
      display: 'flex',
      marginTop: 5,
    },
    continueButton: {
      marginLeft: 'auto',
    },
    minimizeIcon: {
      fontSize: 17,
      transform: 'scale(-1)',
    },
    maximizeIcon: {
      fontSize: 18,
    },
    speechBubble: {
      border: '1px solid ' + walkStepOutlineColor,
      borderRadius: '5px',
      position: 'relative',
      padding: '5px',
      marginTop: '12px',
      minWidth: '200px',
      backgroundColor: theme.palette.background.paper,
      pointerEvents: 'auto', /* causes odd behavior allowing user to horizontally scroll the code window when hovering over this
        (separate from scrollbar), but also allows users to still click toolbox links inside CodeTrek, such as Unit 5, Lesson 1 */
    },
    arrow: {
      borderStyle: 'solid',
      borderColor: walkStepOutlineColor + ' transparent transparent transparent',
      borderWidth: '12px 12px 0px 12px',
      transform: 'rotate(180deg)',
      position: 'absolute',
      top: '-12px',
      left: '10px',
    },
    codestepMarkdownContainer: {
      margin: 0,
      padding: 0,
      width: '100%',
      height: '100%',
    },
    smallIconList: {
      margin: 'auto 0.3em auto 0.3em',
      width: '0.7em',
      height: '0.7em',
    },
    codeStepRegionalDialog: {
      position: 'absolute',
      bottom: 'calc(5% + 30px)',
      top: '5%',
      left: '5%',
      right: 'calc(5% + 60px)',
      padding: '8px',
      marginBottom: 39,
    },
    hintDialog: {
      position: 'absolute',
      bottom: 'calc(15% + 30px)',
      top: '15%',
      left: '10%',
      right: 'calc(10% + 60px)',
      padding: '8px',
    },
    regionalDialog: {
      position: 'absolute',
      width: '80%',
      minHeight: '300px',
      height: '40%',
      marginRight: 91,
    },
    checkboxIcon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
    },
    checkboxSelectedIcon: {
      stroke: 'white',
      strokeWidth: '2',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
      },
    },
    correctIcon: {
      backgroundColor: '#5DB460',
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    goalBody: {
      display: 'flex',
      flexDirection: 'column',
      margin: '14px 0px',
      padding: '0px 7px',
      border: '2px groove',
      borderRadius: '5px',
      backgroundColor: theme.palette.type === 'dark' ? '#4a4a4a' : '#ffffff',
    },
    disabledCheckbox: {
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    trekIndex: {
      zIndex: '2 !important',
    },
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 5,
      fontSize: '0.875rem',
      paddingRight: '1em',
    },
    checkbox: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: '0.875rem',
      margin: '1em',
      padding: '1px 0px 0px',
    },
    objGoalInnerContainer: {
      margin: '3px 0px',
    },
    codeTrekScrollbarGapDark: {
      '&::-webkit-scrollbar': {
        backgroundColor: 'rgb(30, 30, 30)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgb(55, 55, 55)',
      },
      '&::-webkit-scrollbar-corner': {
        backgroundColor: 'rgb(30, 30, 30)',
      },
    },
  })
})

function ObjPanelHeader(props) {
  const [missionState] = useMission()
  let label = `Objective ${props.objectiveNumber}:`

  return (
    <div className={props.classes.panelHeader}>
      <div>
        <Place />
        <div className={props.classes.panelName}>
          <p className={props.classes.noMargin}>
            <em>{label}</em> <span className={props.classes.tinyLeftMargin} >{props.json.title || ''}</span>
          </p>
        </div>
      </div>
      <div style={{ marginLeft: 'auto', display: 'flex' }}>
        <p className={props.classes.panelXp}>
          <em>XP:</em> <span className={props.classes.tinyLeftMargin} >+{props.json.xp}</span>
        </p>

        {missionState?.requiresSim ?
          <>
            <IconButton
              className={props.classes.collapseButton}
              onClick={() => (props.setMaximizeWindow(!props.maximizeWindow))}
            >
              {props.maximizeWindow ?
                <FilterNone className={props.classes.minimizeIcon} /> :
                <CheckBoxOutlineBlank className={props.classes.maximizeIcon} />
              }
            </IconButton>
            <IconButton
              className={props.classes.collapseButton}
              onClick={props.onCollapseButton}
              size='small'
            >
              <Close />
            </IconButton>
          </>:null}
      </div>
    </div>
  )
}

export function LessonPanelSkipButton({ onContinueClick, onCollapseClick }) {
  const classes = useStyles()
  const [missionState] = useMission()
  const { nextContentSequenceItem, contentSequenceItem, contentSequenceItemIsLast }  = useContentDirector()

  if (contentSequenceItemIsLast) {
    return <div style={{height: 36}}></div>
  }

  if (contentSequenceItem?.flow?.completed) {
    return (
      <Button
        style={{ marginLeft: 'auto' }}
        className={classes.continueButton}
        disabled={!missionState.lessonContentReady}
        onClick={onContinueClick}
      >
        {'Next'}
      </Button>
    )
  }

  if (!nextContentSequenceItem?.locked) {
    return (
      <Button
        style={{ marginLeft: 'auto' }}
        disabled={!missionState.lessonContentReady}
        onClick={iterateContentSequenceIndex}
      >
        <SkipNextIcon style={{paddingBottom: 1}}/>
      </Button>
    )
  }

  if (missionState.requiresSim) {
    return (
      <Button className={classes.continueButton} onClick={onCollapseClick} variant='text' >
      Close
      </Button>
    )
  }

  return <div style={{height: 36}}></div>
}

function ObjPanelFooter(props) {
  const { contentSequenceItem }  = useContentDirector()
  const flowAssignment = contentSequenceItem?.assignment

  return (
    <div className={props.classes.panelFooter} >
      <div >
        {props.json.code && props.json.code.length > 0 ?
          <Tooltip title={'CodeTrek'} placement='top-start' arrow >
            <IconButton
              onClick={props.toggleCodeDialog}
              size='small'
            >
              <DirectionsWalk />
            </IconButton>
          </Tooltip> : null
        }
        {props.json.hints && props.json.hints.length > 0 ?
          <Tooltip title={'Hints'} placement='top-start' arrow >
            <IconButton
              onClick={props.toggleHintDialog}
              size='small'
            >
              <EmojiObjects />
            </IconButton>
          </Tooltip> : null
        }
        {flowAssignment ?
          <Tooltip title={'Assignment Submission'} placement='top-start' arrow >
            <IconButton
              onClick={props.toggleSubmissionDialog}
              size='small'
            >
              <AssignmentIcon />
            </IconButton>
          </Tooltip>:
          null}
      </div>
      <LessonPanelSkipButton
        onContinueClick={props.onContinueButton}
        onCollapseClick={props.onCollapseButton}
      />
    </div>
  )
}

function ObjCodeStepOverlay(props) {
  return (
    <div className={props.classes.speechBubble} style={{ display: props.display, width:'fit-content' }}>
      <div className={props.classes.arrow} />
      <IconButton size='small' style={{float:'right'}}>
        {/* Small "close" button provides hint to user that bubble can be closed... */}
        <Close style={{width:'0.7em', height:'0.7em'}}/>
      </IconButton>
      <div className={props.classes.codestepMarkdownContainer} >
        <Markdown>
          {props.walkMarkdown}
        </Markdown>
      </div>
    </div>
  )
}

function ObjCodeEditor(props) {
  const [userConfigState] = useUserConfig()
  const [containerHeight, setContainerHeight] = useState(0)
  const [missionState] = useMission()
  const editorDivRef = useRef()

  const handleOverlayClick = React.useCallback(() => {
    if (!missionState.ui.toolboxOpen) {
      codeMarkerController.hideOverlay()
    }
  }, [missionState.ui.toolboxOpen])

  const handleEditorClick = React.useCallback(() => {
    if (codeMarkerController.overlayHidden) {
      codeMarkerController.restoreOverlay(props.walkStep)
      if (props.overlayRef.current) {
        props.overlayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
      }
    } else {
      props.walkNextBtnPressed()
    }
  }, [props])

  const handleEditorUpdate = (editorContent, event) => { }

  const theme = React.useMemo(() => userConfigState.theme === 'dark' ? 'vs-' + userConfigState.theme : userConfigState.theme, [userConfigState.theme])
  const language = React.useMemo(() => props.json.language, [props.json.language])
  const code = React.useMemo(() => codeMarkerController.stripMarkers(props.json.code), [props.json.code])

  const handleEditorDidMount = React.useCallback((editor, monaco) => {
    props.setMonacoRdy(true)

    codeMarkerController.setEditor(editor, monaco)
    codeMarkerController.setStepOverlayDiv(props.overlayRef.current)

    editor.onDidContentSizeChange(() => {
      updateEditorHeight() // typing
      requestAnimationFrame(updateEditorHeight) // folding
      const editorWidth = editorDivRef.current.offsetWidth
      props.overlayRef.current.style.maxWidth = `${editorWidth}px`

      // scroll capability
      let viewLines = document.getElementsByClassName('view-lines')
      let viewLine = null
      for (let i = 0; i < viewLines.length; i++) {
        // Selects an element with the class 'view-lines' that is contained within codetrek-editor
        // to use as a reference for the width of the content
        if (editorDivRef.current.contains(viewLines[i])) {
          viewLine = viewLines[i]
          break
        }
      }
      if (viewLine === null) {
        console.log('Unable to find a width reference for CodeTrek')
      } else {
        let rawWidth = viewLine.style.width
        let newWidth = parseInt(rawWidth.substring(0, rawWidth.length - 2))
        const newMinWidth = 200

        if (newWidth < newMinWidth) {
          newWidth = newMinWidth
        }
        newWidth = newWidth.toString() + 'px'
        editorDivRef.current.children[0].children[0].style.width = newWidth
        editorDivRef.current.children[0].children[0].style.minWidth = '100%'
        editorDivRef.current.children[0].style.display = 'block'
      }
    })

    let prevHeight = 0

    const updateEditorHeight = () => {
      const editorElement = editor.getDomNode()

      if (!editorElement) {
        return
      }

      const contentHeight = editor.getContentHeight()

      if (prevHeight !== contentHeight) {
        prevHeight = contentHeight
        editorElement.style.minHeight = `${contentHeight}px`

        editor.layout()
        setContainerHeight(contentHeight)
      }
    }

    const messageContribution = editor.getContribution('editor.contrib.messageController')
    editor.onDidAttemptReadOnlyEdit(() => {
      messageContribution.dispose()
    })

    updateEditorHeight()

    editor.getModel().setValue(code)
  }, [code, props])

  return React.useMemo(() => (
    <>
      <div
        className={theme === 'vs-dark' ? props.classes.codeTrekScrollbarGapDark : null}
        style={{ height: '100%', overflow: 'auto', backgroundColor: theme === 'vs-dark' ? '#1E1E1E' : null }}
        onClick={handleEditorClick}
      >
        <div ref={editorDivRef} style={{ height: containerHeight + 4, overflow: 'visible', pointerEvents: 'none', display: 'absolute', userSelect: 'none' }}>
          <Editor
            onMount={handleEditorDidMount}
            theme={theme} // "dark or "light"
            language={language}
            path={codeTrekPath}
            options={{
              readOnly: true,
              cursorWidth: 0,
              selectionHighlight: true,
              automaticLayout: false,
              codeLens: false,
              scrollBeyondLastLine: false,
              scrollbar: {
                alwaysConsumeMouseWheel: false,
                vertical: 'hidden',
                horizontal: 'hidden',
                horizontalScrollbarSize: 0,
                verticalScrollbarSize: 0,
              },
              minimap: { enabled: false },
            }}
            onChange={handleEditorUpdate}
            wrappingStrategy={'advanced'}
            overviewRulerLanes={0}
          />
        </div>
      </div>
      <div
        style={{ width: props.walkDisplay === 'none' ? '0' : '100%', margin: 0, padding: 0 }}
        ref={props.overlayRef}
        onClick={handleOverlayClick}
      >
        <ObjCodeStepOverlay {...props} walkStep={props.walkStep} display={props.walkDisplay} />
      </div>
    </>
  ), [containerHeight, handleEditorClick, handleEditorDidMount, handleOverlayClick, language, props, theme])
}

export const GoalCheckbox = ({ validated, className }) => {
  const classes = useStyles()
  return (
    <Checkbox
      checked={validated ? true : false}
      disableRipple
      color='default'
      disabled={true}
      checkedIcon={
        <Done className={`${classes.checkboxIcon} ${classes.checkboxSelectedIcon} ${classes.correctIcon}`} />
      }
      icon={
        <span className={`${classes.checkboxIcon} ${classes.disabledCheckbox}`} />
      }
      className={className}
    />
  )
}

export const ObjGoals = (props) => {
  const classes = useStyles()
  return (
    <>
      {props.goals.map((goal, idx) => {
        var goalContainerStyle = {
          backgroundColor: goal.validated ? '#5DB46066' : null,
        }
        if ((idx === 0 && !props.hud) || props.goals[idx - 1]?.validated) {
          goalContainerStyle.borderTopLeftRadius = 0
          goalContainerStyle.borderTopRightRadius = 0
          goalContainerStyle.borderBottomLeftRadius = 5
          goalContainerStyle.borderBottomRightRadius = 5
        } else if ((idx === props.goals.length - 1 && !props.hud) || props.goals[idx + 1]?.validated) {
          goalContainerStyle.borderTopLeftRadius = 5
          goalContainerStyle.borderTopRightRadius = 5
          goalContainerStyle.borderBottomLeftRadius = 0
          goalContainerStyle.borderBottomRightRadius = 0
        } else {
          goalContainerStyle.borderRadius = 5
        }

        return (
          <div key={idx} style={goalContainerStyle} className={props.outerContainerClass}>
            <div className={props.innerContainerClass}>
              <div className={classes.checkboxContainer}>
                <GoalCheckbox
                  validated={goal.validated}
                  className={classes.checkbox}
                />
                <Markdown>
                  {goal.md}
                </Markdown>
              </div>
            </div>
            {idx === props.goals.length - 1 ||
              (goal.validated && !props.goals[idx + 1]?.validated) ||
              (!goal.validated && props.goals[idx + 1]?.validated) ?
              null :
              <Divider className={props.dividerClass} />
            }
          </div>
        )
      })}
    </>
  )
}

function ObjLessonPanel(props) {
  const id = props?.json?.id
  const activeId = useRef(id)
  const bodyRef = useRef(null)
  // Manually scroll to top in fullscreen mode when the id changes
  if (id !== activeId.current) {
    activeId.current = id
    if (props.displayFullscreen) {
      bodyRef.current.scrollTo(0, 0)
    }
  }

  return (
    <div className={props.classes.missionPanel}>
      <ObjPanelHeader {...props} />
      <Divider />

      <div ref={bodyRef} className={props.classes.body}>
        <Markdown>
          {props.json.instructions || ''}
        </Markdown>
        {props.json.goals ?
          <div className={props.classes.goalBody}>
            <ObjGoals
              goals={props.json.goals}
              innerContainerClass={props.classes.objGoalInnerContainer}
            />
          </div> :
          null
        }
      </div>
      <Divider />
      <ObjPanelFooter {...props} />
    </div>
  )
}

export function PanelHeader({ onClose, title, icon }) {
  const classes = useStyles()

  return (
    <div className={classes.panelHeader}>
      <div>
        {icon}
        <div className={classes.panelName}>
          <p className={classes.noMargin}>
            <span>{title || ''}</span> <em>Submission</em>
          </p>
        </div>
      </div>
      <div style={{ marginLeft: 'auto', display: 'flex' }}>
        <IconButton
          onClick={onClose}
          className={classes.collapseButton}
        >
          <Close />
        </IconButton>
      </div>
    </div>
  )
}

function ObjHintPanel(props) {
  const [hintSelectedState, setHintSelectedState] = useState(0)
  const hintRef = useRef(null)

  const closeHintPanel = () => {
    props.toggleHintDialog()
  }

  const hintNextBtnPressed = () => {
    setHintSelectedState(hintSelectedState + 1)
    if (hintRef) {
      hintRef.current.scrollTop = 0
    }
  }

  const hintPrevBtnPressed = () => {
    setHintSelectedState(hintSelectedState - 1)
    if (hintRef) {
      hintRef.current.scrollTop = 0
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0, padding: 5, height: '100%', width: '100%' }}>
      <div className={props.classes.panelHeader}>
        <div>
          <EmojiObjects />
          <div className={props.classes.panelName}>
            <p className={props.classes.noMargin}>
              <em>Hint</em>
            </p>
          </div>
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <IconButton
            onClick={closeHintPanel}
            className={props.classes.collapseButton}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <div ref={hintRef} style={{ padding: '1em', flex: 'auto', overflow: 'auto' }}>
        <Markdown>{props.json.hints && props.json.hints.length > 0 ? props.json.hints[hintSelectedState] : ''}</Markdown>
      </div>
      <Divider />
      {props.json.hints ?
        <MobileStepper
          steps={props.json.hints.length}
          position='static'
          variant='dots'
          activeStep={hintSelectedState}
          nextButton={
            hintSelectedState === props.json.hints.length - 1 ?
              <Button size='small' onClick={closeHintPanel}>Close</Button> :
              <Button size='small' onClick={hintNextBtnPressed}>Next<KeyboardArrowRight /></Button>
          }
          backButton={
            <Button size='small' onClick={hintPrevBtnPressed} disabled={hintSelectedState === 0}>
              <KeyboardArrowLeft />
              Back
            </Button>
          }
        /> : null
      }
    </div>
  )
}

function ObjCodePanel(props) {
  const [walkStepState, setWalkStepState] = useState(0)
  const [walkDisplayState, setWalkDisplayState] = useState('none')
  const [trekReady, setTrekReady] = useState(false)
  const [monacoRdyState, setMonacoRdyState] = useState(false)
  const [walkMarkdown, setWalkMarkdown] = useState('')
  const overlayRef = React.useRef(null)
  const scrollTimeout = React.useRef(null)

  const walkCloseBtnPressed = () => {
    codeMarkerController.restartWalk()
    props.toggleCodeDialog()
  }

  const scrollToOverlay = () => {
    clearTimeout(scrollTimeout.current)
    // Theres no callback to tell when the overlay is finished loading and editor size adjusted,
    // therefore heres short timeout to allow the editor to adjust it's size before scrolling
    scrollTimeout.current = setTimeout(() => {
      if (overlayRef.current) {
        overlayRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' })
      }
    }, 100)
  }

  const walkNextBtnPressed = () => {
    if (!monacoRdyState || walkStepState >= props.trexsteps.length || !trekReady) {
      return
    }

    codeMarkerController.nextWalkStep()
    if (codeMarkerController.isWalkDone()) {
      walkCloseBtnPressed()
    } else {
      setWalkStepState(codeMarkerController.getWalkStep())
      setWalkMarkdown(codeMarkerController.walk[codeMarkerController.getWalkStep()].md)
    }
    scrollToOverlay()
  }

  const walkPrevBtnPressed = () => {
    codeMarkerController.prevWalkStep()
    setWalkStepState(codeMarkerController.getWalkStep())
    setWalkMarkdown(codeMarkerController.walk[codeMarkerController.getWalkStep()].md)
    scrollToOverlay()
  }

  React.useEffect(() => {
    if (monacoRdyState && props.trexsteps.length > 0) {
      codeMarkerController.startWalk(props.trexsteps)
      if (props.startMarker !== null) {
        while (codeMarkerController.walk[codeMarkerController.getWalkStep()].marker !== props.startMarker) {
          codeMarkerController.nextWalkStep()
        }
      }

      setWalkStepState(codeMarkerController.getWalkStep())
      setWalkMarkdown(codeMarkerController.walk[codeMarkerController.getWalkStep()].md)
      setWalkDisplayState('block')
      scrollToOverlay()
      setTrekReady(true)
    }
  }, [props.trexsteps, monacoRdyState, props.startMarker])

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'space-between' }}>
      <div className={props.classes.panelHeader} style={{ height: 30 }}>
        <div>
          <DirectionsWalk />
          <div className={props.classes.panelName}>
            <p className={props.classes.noMargin}>
              <span>{props.json.title || ''}</span> <em>CodeTrek</em>
            </p>
          </div>
        </div>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <IconButton
            onClick={walkCloseBtnPressed}
            className={props.classes.collapseButton}
          >
            <Close />
          </IconButton>
        </div>
      </div>
      <div style={{ height: '100%', overflow: 'auto' }}>
        <ObjCodeEditor
          {...props}
          theme={props.theme}
          walkMarkdown={walkMarkdown}
          walkStep={walkStepState}
          walkDisplay={walkDisplayState}
          setMonacoRdy={setMonacoRdyState}
          overlayRef={overlayRef}
          walkNextBtnPressed={walkNextBtnPressed}
        />
      </div>
      <div style={{ height: 48 }}>
        {!props.trexsteps || !props.trexsteps.length || props.trexsteps.length === 0 || (props.startMarker && props.trexsteps.length === 1) ?
          <MobileStepper
            steps={0}
            position='static'
            variant='dots'
            activeStep={0}
            nextButton={
              <Button size='small' onClick={walkCloseBtnPressed}>Close</Button>
            }
          /> :
          <MobileStepper
            steps={props.trexsteps.length}
            position='static'
            variant='dots'
            activeStep={walkStepState}
            nextButton={
              <Button size='small' onClick={walkNextBtnPressed}>
                {
                  walkStepState === props.trexsteps.length - 1 ?
                    'Close' :
                    <>Next <KeyboardArrowRight /></>
                }
              </Button>
            }
            backButton={
              <Button size='small' onClick={walkPrevBtnPressed} disabled={walkStepState === 0}>
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />

        }
      </div>
    </div>
  )
}

// Base panel used for both Missions and Objectives
export default function ObjectivePanel(props) {
  const classes = useStyles()
  const [userConfigState] = useUserConfig()
  const [missionState, dispatchMissionState] = useMission()

  const transparency = 0.95
  const theme = useTheme()
  const backgroundColor = hexToRGBA(theme.palette.background.paper, transparency)

  const openTrexDialog = () => {
    dispatchMissionState({ type: MissionActions.TREX_SHOW, isOpen: true, startMarker: null, steps: props.json.codesteps.map(step => step.marker) })
  }

  const closeTrexDialog = () => {
    codeMarkerController.overlayHidden = false
    dispatchMissionState({ type: MissionActions.TREX_SHOW, isOpen: false })
  }

  const toggleHintDialog = () => {
    dispatchMissionState({ type: MissionActions.TOGGLE_HINT_DIALOG })
  }

  const toggleSubmissionDialog = () => {
    dispatchMissionState({ type: MissionActions.TOGGLE_SUBMISSION_DIALOG })
  }

  React.useEffect(() => {
    if (props.json.codesteps) {
      codeMarkerController.loadSteps(props.json.codesteps)
    }
  }, [props.objectiveNumber, props.json.codesteps])

  let panelStyle = { left: '15%', right: '15%', top: '10%', maxHeight: '80%', display: 'flex', backgroundColor: backgroundColor }
  if (props.maximizeWindow) {
    panelStyle = { left: '0%', right: '0%', top: '0%', maxHeight: '100%', minHeight: '100%', display: 'flex', backgroundColor: backgroundColor }
  }
  if (props.displayFullscreen) {
    panelStyle.backgroundColor = theme.palette.background.paper
  }

  return (
    <>
      <PositionedPanel
        style={{ ...panelStyle }}
        direction={'left'}
        anchorEl={props.missionViewElt}
        open={props.open}
        timeout={{ enter: 200, exit: 200 }}
        onExited={props.onPanelExit}
        onEntering={props.onPanelEnter}
        noSlide={props.panelSlidingDisabled}
      >
        <ObjLessonPanel
          {...props}
          theme={userConfigState.theme}
          classes={classes}
          toggleCodeDialog={openTrexDialog}
          toggleHintDialog={toggleHintDialog}
          toggleSubmissionDialog={toggleSubmissionDialog}
        />
      </PositionedPanel>
      <RegionalDialog
        open={missionState.trex.open && props.open}
        onClose={closeTrexDialog}
        PaperProps={{ classes: { root: classes.codeStepRegionalDialog }, square: true }}
        containerId={'3d'}
        classes={{ root: classes.trekIndex }}
        maxWidth='lg'
      >
        <ObjCodePanel {...props} open={missionState.trex.open && props.open} theme={userConfigState.theme} trexsteps={missionState.trex.steps} startMarker={missionState.trex.startMarker} classes={classes} toggleCodeDialog={closeTrexDialog} />
      </RegionalDialog>
      <RegionalDialog
        open={missionState.hintDialogOpen && props.open}
        onClose={toggleHintDialog}
        PaperProps={{ classes: { root: classes.hintDialog }, square: true }}
        containerId={'3d'}
        maxWidth='lg'
      >
        <ObjHintPanel {...props} classes={classes} toggleHintDialog={toggleHintDialog} />
      </RegionalDialog>
      <RegionalDialog
        open={missionState.submissionDialogOpen && props.open}
        onClose={toggleSubmissionDialog}
        PaperProps={{ classes: { root: classes.regionalDialog }, square: true }}
        containerId={'3d'}
      >
        <AssignmentSubmissionPanel {...props} classes={classes} toggleSubmissionDialog={toggleSubmissionDialog} flowId={props.json.id}/>
      </RegionalDialog>
    </>
  )
}
ObjectivePanel.propTypes = {
  open: PropTypes.bool.isRequired,
  missionViewElt: PropTypes.any,
  json: PropTypes.any,
  isCompleted: PropTypes.bool.isRequired,
  onCollapseButton: PropTypes.func.isRequired,
  onContinueButton: PropTypes.func.isRequired,
  maximizeWindow: PropTypes.bool.isRequired,
  setMaximizeWindow: PropTypes.func.isRequired,
}
