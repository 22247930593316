import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, DialogContent, DialogTitle, Divider, Typography } from '@material-ui/core'
import { useMission } from './contexts/MissionContext'
import RegionalDialog from './RegionalDialog'
import hexToRGBA from './hexToRGBA'
import dark_vs from './dark_vs.json'
import { funQuotes } from './FunQuotes'

const randomIndex = maxCount => Math.floor(Math.random() * maxCount)

const useStyles = makeStyles((theme) => {
  return ({
    title: {
      padding: 2,
      paddingLeft: '10px',
      fontStyle: 'oblique',
      fontVariant: 'small-caps',
      fontSize: 15,
      filter: `drop-shadow( 0.1rem 0.1rem 0 ${theme.palette.type === 'dark' ? '#000' : 'white'} )`,
    },
    content: {
      display:'flex',
      flexDirection:'row',
      alignItems: 'center',
      height: '90px',
      fontSize: 6,
      fontStyle: 'italic',
      padding: 5,
      paddingLeft: '15px',
    },
    loadingDialog: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      zIndex: 100,
      bottom: '1%',
      right: '1%',
      margin: '0px !important',
      overflow: 'hidden',
      padding: '0px',
      width: '300px',
      height: '115px',
      backgroundColor: hexToRGBA(theme.palette.background.paper, 0.75),
    },
    spinner: {
      color: theme.palette.type === 'dark' ? dark_vs.colors['menu.foreground'] : null,
      size: 1,
      flex: '0 0 auto',
    },
  })
})

export default function LoadingPanel() {
  const classes = useStyles()
  const [quote, setQuote] = React.useState('')
  const [missionState] = useMission()

  const shuffle = React.useCallback(() => {
    const index = randomIndex(funQuotes.length)
    setQuote(funQuotes[index])
  }, [])

  React.useEffect(() => {
    shuffle()
    const intervalId = setInterval(shuffle, 2500)
    return () => clearInterval(intervalId)
  }, [shuffle])

  return (
    <RegionalDialog
      id={'loading-mission'}
      containerId={'3d'}
      open={!missionState.initialLoadDone}
      PaperProps={{
        square: true,
        classes: { root: classes.loadingDialog },
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle className={classes.title}>Mission Loader</DialogTitle>
      <Divider />
      <DialogContent
        classes={{ root: classes.content }}
      >
        <CircularProgress
          className={ classes.spinner }
        />
        <Typography style={{paddingLeft: '10px'}}>{quote}...</Typography>
      </DialogContent>
    </RegionalDialog>
  )
}
