/* Mission toolbar
   When user is in "mission" mode rather than Explore, the Mission Toolbar is up.
*/

import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import {
  Assignment as AssignmentIcon,
  Lock as LockIcon,
  Done as DoneIcon,
  Block as BlockIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@material-ui/icons'
import { useMission, MissionActions, LessonContentViews } from './contexts/MissionContext'
import Avatar from '@material-ui/core/Avatar'
import { useContentDirector } from './contexts/ContentDirectorContext'
import { changeContentSequenceIndex } from './content-manager/content-director/content-director-use-cases/changeContentSequenceIndex'
import { ContentSequenceItemTypes } from './content-manager/content-director/ContentSequenceItemTypes'

const ObjectiveTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightLight,
  },
}))(Tooltip)


const useStyles = makeStyles((theme) => {
  return ({
    objBar: {
      overflowY: 'auto',
      overflowX: 'hidden',
      background: `
      linear-gradient(${theme.palette.background.default} 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), ${theme.palette.background.default} 70%) 0 100%,
      linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)) 0 100%`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.background.default,
      backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
      backgroundAttachment: 'local, local, scroll, scroll',
    },
    objectiveButtons: {
      minWidth: 0,
      width: 51,
      height: 32,
    },
    doneIcon: {
      color: 'green',
      padding: 0,
    },
    missionButton: {
      borderRadius: 5,
      height: 51,
      width: 51,
      marginBottom: 2,
    },
    avatar: {
      height: 35,
      width: 35,
      borderRadius: 5,
    },
  })
})

function SequenceItemContainer({
  tooltipContent,
  title,
  children,
  seqIndex,
  lastItem,
  locked,
  onClick,
  selected,
}) {
  const classes = useStyles()
  const [missionState] = useMission()

  return (
    <ObjectiveTooltip
      title={(
        <div style={{ display: 'flex' }}>
          {locked && <LockIcon style={{ marginRight: '0.2em', width: '0.8em' }} />}
          <span>{title}</span>
        </div>
      )}
      key={seqIndex}
      placement='left' arrow
    >
      <span id={seqIndex}>
        <>
          <Button
            size='small'
            disabled={locked || !missionState.lessonContentReady}
            onClick={onClick}
            className={classes.objectiveButtons}
            style={{ borderRadius: 0, backgroundColor: selected ? 'rgba(255,255,255,0.1)' : null, border: '1px solid ' + selected ? 'rgba(255,255,255,0.1)':'', padding: 0}}
          >
            <div style={{width: '100%'}}>
              {children}
            </div>
          </Button>
          <div style={{borderBottom: !lastItem ? '1px solid rgba(255,255,255,0.05)' : null }}></div>
        </>
      </span>
    </ObjectiveTooltip>
  )
}

function SequenceItem({ seqItemData, seqIndex, firstItem, lastItem, handleSeqItemClick }) {
  const classes = useStyles()
  const { contentSequenceIndex }  = useContentDirector()
  const [missionState] = useMission()
  const selected = seqIndex === contentSequenceIndex
  const visible = missionState?.ui.focusedContent === ContentSequenceItemTypes.FLOW
  const flowItem = seqItemData.contentSequenceType === ContentSequenceItemTypes.FLOW
  const itemHasAssignment = 'assignment' in seqItemData

  const handleItemClick = async () => {
    await handleSeqItemClick(visible, selected, seqIndex)
  }

  return (
    <SequenceItemContainer
      lastItem={lastItem}
      key={seqIndex}
      seqIndex={seqIndex}
      locked={seqItemData.locked && !missionState.testMode}
      onClick={handleItemClick}
      selected={selected}
      title={
        flowItem ? seqItemData.flow.title : 'Progression Gate'
      }
    >
      {
        flowItem ?
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-around', padding: 2, paddingRight: '1em'}}>
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
              {seqItemData.flow.completed ? <DoneIcon className={classes.doneIcon} style={{fontSize: itemHasAssignment ? 15 : 22}}/>:null}
            </div>
            <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center'}}>
              {itemHasAssignment ? <AssignmentIcon style={{fontSize: 15}}/>:null}
            </div>
            <div style={{fontSize: 14, flex: 1}}>
              {seqItemData.flow.flowType === 'objective' ? seqItemData.flow.flowTypeIndex + 1 : 'Q'}
            </div>
          </div>:
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {seqItemData.progressionGate.locked ?
              <BlockIcon style={{ width: '0.8em' }} />:<RadioButtonUncheckedIcon style={{ width: '0.8em' }} />
            }
          </div>
      }
    </SequenceItemContainer>
  )
}

function ContentSequencePanel({ fsMode, handleTransitioningFromFsToNonFs }) {
  const classes = useStyles()
  const [missionState, missionDispatch] = useMission()
  const { contentSequence, contentSequenceIndex }  = useContentDirector()

  const missionOverviewVisible = missionState.ui.focusedContent === LessonContentViews.MISSION
  function handleMissionOverviewClick() {
    if (contentSequenceIndex === -1) {
      return
    }

    if (missionOverviewVisible && fsMode) {
      missionDispatch({ type: MissionActions.CHANGE_VIEW, view: LessonContentViews.FLOW })
    } else if (missionOverviewVisible) {
      missionDispatch({ type: MissionActions.VIEW_NOTHING })
    } else {
      missionDispatch({ type: MissionActions.CHANGE_VIEW, view: LessonContentViews.MISSION })
    }
  }

  async function handleSeqItemClick(visible, selected, seqIdx) {
    if (visible && selected && missionState.requiresSim) {
      await missionDispatch({ type: MissionActions.VIEW_NOTHING })
      return
    }

    handleTransitioningFromFsToNonFs(seqIdx, missionState)
    changeContentSequenceIndex(seqIdx)
  }

  return (
    !missionState.ui.isExploreMode ?
      <>
        <Tooltip
          title={missionState.activeMission.title ? missionState.activeMission.title : ''}
          placement='left'
          arrow>
          <span>
            <IconButton
              id='mission-select-btn'
              onClick={handleMissionOverviewClick}
              className={classes.missionButton}
              disabled={!missionState.lessonContentReady}
              style={{ backgroundColor: missionOverviewVisible ? 'rgba(255,255,255,0.03)' : null }}
            >
              <>
                <Avatar
                  className={classes.avatar}
                  variant='square'
                  src={missionState.activeMission.avatarBlob ? URL.createObjectURL(missionState.activeMission.avatarBlob) : ''}
                />
                <div className={classes.avatar} style={{position: 'absolute', backgroundColor: !missionState.lessonContentReady ? 'rgba(0,0,0,0.5' : null}}></div>
              </>
            </IconButton>
          </span>
        </Tooltip>
        <div className={classes.objBar}>
          <div style={{ border: '1px solid rgba(255,255,255,0.05)', borderRadius: 3, backgroundColor: 'rgba(255,255,255,0.02)' }}>
            {contentSequence.map((seqItemData, seqIndex) =>
              <SequenceItem
                seqItemData={seqItemData}
                seqIndex={seqIndex}
                key={seqIndex}
                firstItem={seqIndex === 0}
                lastItem={seqIndex === contentSequence.length - 1}
                handleSeqItemClick={handleSeqItemClick}
              />
            )}
          </div>
        </div>
      </> : null
  )
}

export { ContentSequencePanel }