// Positioned Panels
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Portal from '@material-ui/core/Portal'
import PropTypes from 'prop-types'
import Slide from '@material-ui/core/Slide'
import hexToRGBA from './hexToRGBA'

const useStyles = makeStyles((theme) => {
  const alphaBackground = 0.85  // add a little transparency
  return ({
    panel: {
      padding: theme.spacing(1),
      backgroundColor: hexToRGBA(theme.palette.background.paper, alphaBackground),
      position: 'absolute',
      // overflow: 'auto'
    },
  })
})

export function PositionedPanel(props) {
  const classes = useStyles()

  if (!props.anchorEl) {
    return null
  }

  return (
  // Portal'ing our content over to be a child of the anchor element, which should be position:relative,
  // so we can absolute position ourself inside it.
    <Portal container={props.anchorEl}>
      {props.noSlide && props.open ?
        <div
          onClick={props.onClick}
          className={props.className ? props.className : classes.panel}
          style={props.style}
        >
          <>{props.children}</>
        </div> : null
      }
      <Slide direction={props.direction} in={props.open} onExited={props.onExited} onEntering={props.onEntering} onEntered={props.onEntered} timeout={props.timeout} mountOnEnter unmountOnExit>
        {!props.noSlide ?
          <div
            onClick={props.onClick}
            className={props.className ? props.className : classes.panel}
            style={props.style}
          >
            <>{props.children}</>
          </div>:
          <div></div>}
      </Slide>
    </Portal>
  )
}
PositionedPanel.propTypes = {
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  direction: PropTypes.string,
}

