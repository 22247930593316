import React from 'react'

import { userProgressController } from '../UserProgressControl'
import { useFileManagement } from './FileManagementContext'
import { validatorController, ValidatorContextKeys } from '../ValidatorControl'
import { simFsController } from '../SimFileSystemControl'
import { appDefaults } from '../BotSimDefaults'
import { useScene, SceneActions } from './SceneContext'
import { Targets } from '../Players'
import { setMissionContextDispatch } from '../content-manager/content-director/content-director-presenter'
import { completeMissionPackProgress, completeMissionProgress } from '../content-manager/user-progress/user-progress-use-cases/completeMissionPackProgress'
import { loadActiveMissionPack } from '../content-manager/content-director/content-director-use-cases/loadActiveMissionPack'
import { openAssignmentSubmissionValidationDialog } from '../AssignmentSubmissionPanels/ValidatedAssignmentSubmissionDialog'
import { scoreInterface } from '../content-manager/content-director/content-director-use-cases/score-actions'
import { getActiveMission } from '../content-manager/content-director/content-director-use-cases/getActiveMission'
import { editorMode } from '../content-manager/lesson-editor/lesson-editor-controllers'

export const MissionActions = Object.freeze({
  CHANGE_VIEW: Symbol('Change content view'),
  VIEW_NOTHING: Symbol('view nothing'),
  VIEW_PROGRESSION_GATE: Symbol('view progression gate'),
  SET_CURR_FLOW: Symbol('set current objective'),
  SET_MSN: Symbol('set active mission by id'),
  SET_NEXT_MSN: Symbol('set active mission to the next one'),
  LOAD_MSN: Symbol('load selected mission'),
  LOAD_MSN_PACK: Symbol('load selected mission pack'),
  UPDATE_GOALS: Symbol('update goals validation'),
  RESIZE_LSN_WINDOW: Symbol('maximize the lesson content window'),
  SHOW_MSN_SEL: Symbol('show the mission selector panel'),
  MISSION_CONTINUE: Symbol('continue function on mission panel'),
  TOOLBOX_SHOW: Symbol('view toolbox'),
  TOOLBOX_MAX: Symbol('maximize toolbox window'),
  TOOL_SELECT: Symbol('select a toolbox tool'),
  SET_QUIZ_ANSWER: Symbol('set quiz answer'),
  ENTER_EXPLORE_MODE: Symbol('enter sandbox / explore mode'),
  EXIT_EXPLORE_MODE: Symbol('exit sandbox / explore mode'),
  CONSOLE_SHOW: Symbol('toggle the debug console'),
  TREX_SHOW: Symbol('toggle the debug console'),
  SYNC_FROM_EDITOR: Symbol('take a mission sync from the editor'),
  SWITCH_MSN_PACK: Symbol('Switch the mission pack from the selector'),
  SET_TEST_MODE: Symbol('Unlock all flow items'),
  SET_GOAL_HUD: Symbol('Set goal hud expanded/collapsed'),
  HANDLE_DEBUGGER_RUN: Symbol('Handle lesson content on debugger run'),
  TOGGLE_HINT_DIALOG: Symbol('Toggle hint dialog'),
  TOGGLE_SUBMISSION_DIALOG: Symbol('Toggle submission dialog'),
  CLOSE_SUBMISSION_DIALOG: Symbol('close submission dialog'),
  SET_SCORE: Symbol('set score'),
  SET_INITIAL_LOAD_DONE: Symbol('initial load done'),
})

validatorController.addContextActions(ValidatorContextKeys.MISSION, MissionActions)

export const LessonContentViews = Object.freeze({
  MISSION: 'mission',
  FLOW: 'flow',
  TOOLBOX: 'toolbox',
  COMPLETION_MESSAGE: 'completion_message',
  NOTHING: 'nothing',
  PROGRESSION_GATE: 'progression gate',
  LOADING: 'loading',
})

export function targetRequiresSim(target) {
  return !target?.includes('USB') && target !== 'UNCONNECTED' && !!target
}

const MissionContext = React.createContext()

const activeMissionDefaults = {
  id: '',
  title: '',
  brief: '',
  overview: '',
  complete: '',
  avatar: '',
}

let setMissionState = () => {}
export const MissionProvider = ({ children }) => {
  const [sceneState, sceneDispatch] = useScene()
  const [fileManagementState, fileManagementDispatch] = useFileManagement()
  const hiddenContentRef = React.useRef(null)
  const [score, setScore] = React.useState(0)
  const [requiresSim, setRequiresSim] = React.useState(false)
  const [loadingMission, setLoadingMission] = React.useState(false)
  const [loadingFlow, setLoadingFlow] = React.useState(false)
  const [fileInjecting, setFileInjecting] = React.useState(false)
  const [state, setState] = React.useState({
    testMode: editorMode,
    initialLoadDone: false,
    editorMode: editorMode,
    hiddenContent: null,
    // Hint dialog state currently not saved with ui, but required
    // as state to render toolbox over hint dialog
    hintDialogOpen: false,
    submissionDialogOpen: false,
    ui: { ...appDefaults.userProgress.ui },
    activeMissionPack: {id: '', missions: []},
    activeMission: {
      ...activeMissionDefaults,
      flow: [],
    },
    trex: {
      steps: [],
      open: false,
      startMarker: null,
    },
  })
  const stateCache = React.useRef(state)
  setMissionState = setState

  // Grab the latest 'fileManagementDispatch' and place it in a ref.
  // This is only to provide the UserProgressControl class with an updated reference
  // to the codePanelDispatch function (containing latest state variables within its scope).
  const fileManagementDispatchRef = React.useRef()
  React.useEffect(() => {
    fileManagementDispatchRef.current = fileManagementDispatch
  }, [fileManagementDispatch])

  React.useEffect(() => {
    // this happens when the user logs in or logs out
    if (!fileManagementState.fileSystemReady) {
      return
    }
    validatorController.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileManagementState.fileSystemReady, state.editorMode])


  const markMissionCompleted = (copyOfState) => {
    if (copyOfState.editorMode && copyOfState.ui.curFlowIndex + 1 === copyOfState.activeMission.flow.length) {
      return true
    }

    if (copyOfState.activeMission.progress.completed === ''){
      for (let i=0; i<copyOfState.activeMission.flow.length; i++){
        if (copyOfState.activeMission.flow[i].progress.completed === ''){
          return false
        }
      }
      copyOfState.activeMission.progress.completed = new Date()
    }
    return true
  }

  const checkQuestionCompleted = (answers, selected) => {
    for (let answer in answers){
      if (answers[answer].correct && selected.find(a => a === answers[answer].key) === undefined){
        return false
      }
    }
    return true
  }

  const markQuizCompleted = (copyOfState) => {
    var quiz = copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex]
    var quizCompleted = true
    var msnCompleted = false
    for (let index in quiz.progress.questions){
      if (!quiz.progress.questions[index].completed){
        quizCompleted = false
      }
    }
    if (quizCompleted) {
      if (quiz.progress.completed === ''){
        quiz.progress.completed = new Date()
      }
      msnCompleted = markMissionCompleted(copyOfState)
    }
    return [quizCompleted, msnCompleted]
  }

  const mergeMissionAndProgress = (mission, progress) => {
    mission.progress = (({ flow, ...o }) => o)(progress)

    mission.flow.forEach((missionFlowItem, index) => {
      // Find index of this objective/quiz in the user's progress array
      let progressFlowItem = progress.flow.find(item => item.id === missionFlowItem.id)
      if (!progressFlowItem) {
        console.error('User progress does not contain a flow item with id: ' + missionFlowItem.id + ', which is required by the mission.')
      }
      // Use the interesting bits!
      mission.flow[index].progress = (({ id, type, questions, ...o }) => o)(progressFlowItem)

      if (missionFlowItem.type === 'quiz') {
        const newQuestions = []
        // Handle embedded quiz questions
        progressFlowItem.questions.forEach((qProgress, idx) => {
          const flowQuestion = mission.flow[index].obj.questions.find(q => q.id === qProgress.id)
          if (!!flowQuestion) {
            newQuestions[idx] = {
              id: qProgress.id,
              selected: [...qProgress.selected],
              answers: [...qProgress.answers],
              completed: checkQuestionCompleted(flowQuestion.obj.answers, qProgress.selected),
            }
          } else {
            // This should never happen, as the outer 'else' would have created the questions object on the user progress
            console.error('User progress contains a question with id: ' + qProgress.id + ', which is required by the mission.')
          }
        })
        mission.flow[index].progress.questions = newQuestions
      }
    })
    return mission
  }

  const switchMission = (copyOfState, newMissionId) => {
    if (copyOfState.ui.curFlowIndex !== -1){
      // Clear all goals
      for (let index in copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.goals){
        copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.goals[index].validated = false
      }
    }
    copyOfState.hiddenContent = null
    copyOfState.ui.isExploreMode = false
    // this triggers a useEffect to load the new mission
    copyOfState.ui.activeMissionId = newMissionId
  }

  const loadFlowIndex = async (copyOfState, flowIndex) => {
    // TODO: this should never happen but WAS happening in CX-288
    if (flowIndex < copyOfState.activeMission.flow.length) {
      if (copyOfState.ui.curFlowIndex !== -1 &&
                copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex]?.type === 'objective' &&
                copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex]?.obj?.goals &&
                copyOfState.ui.curFlowIndex !== flowIndex){
        // Clear all goals
        for (let index in copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.goals){
          copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.goals[index].validated = false
        }
      }

      copyOfState.ui.curFlowIndex = flowIndex
      if (flowIndex === -1) {
        copyOfState.ui.focusedContent = LessonContentViews.MISSION
        setRequiresSim(false)
        return
      }

      if (copyOfState.activeMission.flow[flowIndex]?.progress?.started === ''){
        copyOfState.activeMission.flow[flowIndex].progress.started = new Date()
        if (copyOfState.activeMission.flow[flowIndex].type === 'objective'){
          await userProgressController.setObjectiveStarted(copyOfState.activeMission.flow[flowIndex].id)
        } else {
          await userProgressController.setQuizStarted(copyOfState.activeMission.flow[flowIndex].id)
        }
      }

      if (copyOfState.activeMission.flow[flowIndex].type === 'objective'){
        const target = copyOfState.activeMission.flow[flowIndex]?.obj?.target
        setRequiresSim(targetRequiresSim(target))
      } else {
        setRequiresSim(false)
      }
      copyOfState.ui.focusedContent = LessonContentViews.FLOW
    }
  }

  const loadMission = async (copyOfState, mission) => {
    copyOfState.activeMission = mergeMissionAndProgress(
      mission,
      await userProgressController.activateMission(mission)
    )
    copyOfState.ui.activeMissionId = copyOfState.activeMission.id

    // this checks the case where the user had one objective left in the mission
    // and that objective was removed from the flow by the lesson developer
    // we need to come back behind and check if the mission is complete even if it wasnt
    // the last time the user logged in
    const isMissionComplete = markMissionCompleted(copyOfState)
    if (isMissionComplete) {
      await userProgressController.setMissionComplete()
    }
    // this catches a case where if an an objective was removed from a mission and
    // pushed user into a locked objective as the active flow item on the initial load
    if (copyOfState.ui.curFlowIndex >= copyOfState.activeMission.flow.length || (
      copyOfState.ui.curFlowIndex !== -1 &&
        copyOfState.ui.curFlowIndex !== 0 &&
        copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex - 1].progress.completed === ''
    )) {
      copyOfState.ui.curFlowIndex = -1
      copyOfState.ui.focusedContent = LessonContentViews.MISSION
    }

    if (copyOfState.ui.curFlowIndex === -1 && copyOfState.ui.focusedContent === LessonContentViews.FLOW) {
      copyOfState.ui.focusedContent = LessonContentViews.MISSION
    }

    copyOfState.ui.isExploreMode = false
  }

  const resetValidatedGoals = () => {
    const copyOfState = {...state}
    const goals = copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.goals
    for (let index in goals){
      goals[index].validated = false
    }
    setState(copyOfState)
  }

  const dispatch = async (action) => {
    const copyOfState = {...stateCache.current}
    // const copyOfState = {...state}
    let setScene = false
    let saveUiState = false
    switch (action.type) {
      case MissionActions.SET_SCORE:
        setScore(action.score)
        break
      case MissionActions.CHANGE_VIEW:
        copyOfState.ui.focusedContent = action.view
        await userProgressController.saveUiState(copyOfState.ui)
        break
      case MissionActions.TOOLBOX_SHOW:
        copyOfState.ui.toolboxOpen = action.isOpen
        if (!action.isOpen) {
          copyOfState.ui.toolSelected = ''
        }
        break
      case MissionActions.TREX_SHOW:
        copyOfState.trex.open = action.isOpen
        if (action.isOpen) {
          copyOfState.trex.startMarker = action.startMarker
          copyOfState.trex.steps = action.steps
        }
        break
      case MissionActions.CONSOLE_SHOW:
        copyOfState.ui.consoleOpen = action.isOpen
        break
      case MissionActions.TOOLBOX_MAX:
        copyOfState.ui.toolboxMaximized = action.isMax
        break
      case MissionActions.TOOL_SELECT:
        copyOfState.ui.toolboxOpen = true
        copyOfState.ui.toolSelected = action.tool
        await userProgressController.addToolFound(action.tool)
        break
      case MissionActions.VIEW_NOTHING:
        copyOfState.ui.focusedContent = LessonContentViews.NOTHING
        break
      case MissionActions.ENTER_EXPLORE_MODE:
        copyOfState.ui.isExploreMode = copyOfState.ui.focusedContent
        copyOfState.ui.focusedContent = LessonContentViews.NOTHING
        break
      case MissionActions.EXIT_EXPLORE_MODE:
        if (state.ui.curFlowIndex === -1) {
          setRequiresSim(targetRequiresSim(null))
        } else {
          const target = state.activeMission.flow[state.ui.curFlowIndex]?.obj?.target
          setRequiresSim(targetRequiresSim(target))
        }

        copyOfState.ui.focusedContent = copyOfState.ui.isExploreMode
        copyOfState.ui.isExploreMode = false
        break
      case MissionActions.VIEW_PROGRESSION_GATE:
        copyOfState.ui.focusedContent = LessonContentViews.PROGRESSION_GATE
        break
      case MissionActions.SET_MSN:
        switchMission(copyOfState, action.id)
        break
      case MissionActions.SET_NEXT_MSN:
        const curMsnIdx = copyOfState.activeMissionPack.missions.findIndex(msn => msn.id === copyOfState.ui.activeMissionId)
        if (curMsnIdx + 1 < copyOfState.activeMissionPack.missions.length) {
          switchMission(copyOfState, copyOfState.activeMissionPack.missions[curMsnIdx + 1].id)
        }
        break
      case MissionActions.SET_CURR_FLOW:
        setLoadingFlow(true)
        await loadFlowIndex(copyOfState, action.index)
        setLoadingFlow(false)
        saveUiState = true
        break
      case MissionActions.LOAD_MSN:
        setLoadingMission(true)
        await loadMission(copyOfState, action.mission)
        await loadFlowIndex(copyOfState, action.flowIndex)
        if (!(copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex]?.obj?.scene)) {
          setScene = true
        }
        saveUiState = true
        setLoadingMission(false)
        break
      case MissionActions.LOAD_MSN_PACK:
        copyOfState.ui.activeMissionPackId = action.pack.id
        copyOfState.ui.isExploreMode = false
        copyOfState.activeMissionPack = action.pack
        copyOfState.missionPackReady = true
        userProgressController.activateMissionPack(action.pack)
        await loadMission(copyOfState, action.mission)
        await loadFlowIndex(copyOfState, action.flowIndex)
        if (!(copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex]?.obj?.scene)) {
          setScene = true
        }
        saveUiState = true
        copyOfState.initialLoadDone = true
        break
      case MissionActions.MISSION_CONTINUE:
        copyOfState.ui.curFlowIndex = copyOfState.activeMission.flow.findIndex(mission => mission.progress.completed === '')
        if (copyOfState.ui.curFlowIndex === -1){
          copyOfState.ui.curFlowIndex = 0
        }
        copyOfState.ui.focusedContent = LessonContentViews.FLOW
        await userProgressController.saveUiState(copyOfState.ui)
        break
      case MissionActions.UPDATE_GOALS:
        var objectiveComplete = true
        for (let index in action.validators){
          if  (action.validators[index].validated === false){
            objectiveComplete = false
          }
          copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.goals[index].validated = action.validators[index].validated
        }
        if (objectiveComplete){
          openAssignmentSubmissionValidationDialog()
          // TODO: Calculate how much xp the objective/quiz is worth based on performance
          copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].progress.completed = new Date()
          copyOfState.trex.open = false
          // copyOfState.ui.focusedContent = LessonContentViews.COMPLETION_MESSAGE
          const isMsnComplete = markMissionCompleted(copyOfState)
          await userProgressController.setObjectiveCompleted(
            copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].id,
            copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex].obj.xp,
            isMsnComplete
          )

          if (isMsnComplete) {
            sceneDispatch({ type: SceneActions.PLAY_MISSION_COMPLETION_MUSIC })
          }
        }
        break
      case MissionActions.RESIZE_LSN_WINDOW:
        copyOfState.ui.windowMaximized = action.isMax
        break
      case MissionActions.SHOW_MSN_SEL:
        copyOfState.ui.missionSelOpen = action.isOpen
        break
      case MissionActions.SYNC_FROM_EDITOR:
        copyOfState.ui.focusedContent = LessonContentViews.MISSION
        copyOfState.ui.curFlowIndex = -1
        copyOfState.activeMissionPack = action.pack
        copyOfState.activeMission = mergeMissionAndProgress(
          action.mission,
          await userProgressController.activateMission(action.mission)
        )
        break
      case MissionActions.SET_QUIZ_ANSWER:
        const quiz = copyOfState.activeMission.flow[copyOfState.ui.curFlowIndex]
        const qProgress = quiz.progress.questions.find(q => q.id === action.questionId)
        const question = quiz.obj.questions.find(q => q.id === action.questionId)
        const qData = question.obj
        qProgress.selected.push(action.answerKey)
        const isQuestionComplete = checkQuestionCompleted(qData.answers, qProgress.selected)
        quiz.progress.questions.find(q => q.id === action.questionId).completed = isQuestionComplete
        const [isQuizComplete, isMsnComplete] = markQuizCompleted(copyOfState)

        await userProgressController.setAnswerSelected(
          quiz.id,
          action.questionId,
          action.answerKey,
          qData.xp,
          isQuestionComplete,
          isQuizComplete,
          isMsnComplete
        )
        if (isMsnComplete) {
          sceneDispatch({ type: SceneActions.PLAY_MISSION_COMPLETION_MUSIC })
        }
        break
      case MissionActions.SWITCH_MSN_PACK:
        copyOfState.ui.activeMissionPackId = action.packId
        loadActiveMissionPack(action.packId)
        await userProgressController.saveUiState(copyOfState.ui)
        break
      case MissionActions.SET_TEST_MODE:
        // this will trigger the load user progress useEffect
        copyOfState.testMode = action.isTestMode
        break
      case MissionActions.SET_GOAL_HUD:
        copyOfState.ui.goalHUDOpen = action.goalHUDOpen
        await userProgressController.saveUiState(copyOfState.ui)
        break
      case MissionActions.HANDLE_DEBUGGER_RUN:
        const hiddenContent = hiddenContentRef.current
        if (action.runAction === 'start' &&
        copyOfState.ui.focusedContent !== LessonContentViews.NOTHING &&
        copyOfState.ui.focusedContent !== LessonContentViews.COMPLETION_MESSAGE){
          hiddenContentRef.current = copyOfState.ui.focusedContent
          copyOfState.ui.focusedContent = LessonContentViews.NOTHING
        } else if (action.runAction === 'stop' && hiddenContent) {
          if (copyOfState.ui.focusedContent === LessonContentViews.NOTHING){
            copyOfState.ui.focusedContent = hiddenContent
          }
          hiddenContentRef.current = null
        }
        break
      case MissionActions.TOGGLE_HINT_DIALOG:
        copyOfState.hintDialogOpen = !copyOfState.hintDialogOpen
        break
      case MissionActions.TOGGLE_SUBMISSION_DIALOG:
        copyOfState.submissionDialogOpen = !copyOfState.submissionDialogOpen
        break
      case MissionActions.CLOSE_SUBMISSION_DIALOG:
        copyOfState.submissionDialogOpen = false
        break
      case MissionActions.SET_INITIAL_LOAD_DONE:
        copyOfState.initialLoadDone = action.initialLoadDone
        break
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
    stateCache.current = copyOfState

    validatorController.handleContextEvent(action)

    setState(copyOfState)

    if (setScene && requiresSim) {
      let newEnvIdx = appDefaults.sceneConfig.envIndex
      const activeMission = getActiveMission()
      if (!!activeMission) {
        try {
          newEnvIdx = activeMission.flow[state.ui.curFlowIndex + 1].obj.scene
        } catch {
          //
        }
      }
      sceneDispatch({ type: SceneActions.ENVIRONMENT_SET, envIndex: newEnvIdx })
    }
    if (saveUiState) {
      await userProgressController.saveUiState(copyOfState.ui)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  // this allows you to type: toggleTestMode in the chrome console to unlock all the objectives
  React.useEffect(() => {
    Object.defineProperty(window, 'toggleTestMode', {
      get: () => {
        dispatch({type: MissionActions.SET_TEST_MODE, isTestMode: !state.testMode})
        const retVal = state.testMode ? 'You have left test mode!' : 'You have entered test mode!'
        return retVal
      },
      configurable: true,
    })
    Object.defineProperty(window, 'completePack', {
      get: () => {
        completeMissionPackProgress()
        return 'Pack Completed.'
      },
      configurable: true,
    })
    Object.defineProperty(window, 'completeFlow', {
      get: () => {
        // completeMissionPackProgress()
        const flow = state.activeMission?.flow[state.ui.curFlowIndex]
        if (flow.type === 'quiz') {
          for (const q of flow?.obj?.questions) {
            for (const answer of q?.obj?.answers) {
              if (answer.correct) {
                dispatch({type: MissionActions.SET_QUIZ_ANSWER, questionId: q.id, answerKey: answer.key})
              }
            }
          }
          return 'Quiz Completed.'
        }

        for (let val of validatorController.validators){
          val.validated = true
        }

        dispatch({type: MissionActions.UPDATE_GOALS, validators: validatorController.validators})
        return 'Objective Completed.'
      },
      configurable: true,
    })
    Object.defineProperty(window, 'completeMission', {
      get: () => {
        completeMissionProgress()
        return 'Mission Completed.'
      },
      configurable: true,
    })
    Object.defineProperty(window, 'toggleEditorMode', {
      get: () => {
        if (editorMode) {
          window.location.href = '/'
        } else {
          window.location.href = '/editorMode'
        }
        return 'Toggled Editor Mode.'
      },
      configurable: true,
    })
  })

  React.useEffect(() => {
    const injectFiles = async (flowObj) => {
      if (!flowObj.fileBlobs || fileInjecting) {
        return
      }
      setFileInjecting(true)
      for (const fileIndex in flowObj.fileBlobs){
        const file = flowObj.fileBlobs[fileIndex]
        // console.log(file)
        await simFsController.fileWriteByteArray(file.id, file.obj)
      }
      setFileInjecting(false)
    }

    if (((sceneState.ready && state.activeMission.flow[state.ui.curFlowIndex]?.obj?.scene === sceneState.envIndex) ||
    !requiresSim)  &&
      state.ui.curFlowIndex !== -1 &&
      state.activeMission.flow.length > state.ui.curFlowIndex &&
      !state.ui.isExploreMode &&
      state.activeMission.flow[state.ui.curFlowIndex]?.type === 'objective') {
      resetValidatedGoals()
      injectFiles(state.activeMission.flow[state.ui.curFlowIndex].obj)
      validatorController.clear()
      validatorController.subscribe(state.activeMission.flow[state.ui.curFlowIndex].obj.goals, scoreInterface)
    } else {
      validatorController.clear()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneState.ready, requiresSim])

  const PROGRESSION_GATE_PANEL_OPENED = state.ui.focusedContent === LessonContentViews.PROGRESSION_GATE

  // Why use this rather than requiresSim? There's a timing issue where requiresSim is not updated in time due to the dispatch.
  const curFlowRequiresSim = targetRequiresSim(state?.activeMission?.flow[state.ui.curFlowIndex]?.obj?.target)
  React.useEffect(() => {
    if (state.activeMission.id === '') {
      return
    }

    if (state.ui.curFlowIndex !== -1 &&
      !state.ui.isExploreMode &&
      state.activeMission.flow[state.ui.curFlowIndex]?.type === 'objective' &&
      !PROGRESSION_GATE_PANEL_OPENED){
      const validatorUpdated = () => {
        scoreInterface.save()
        dispatch({ type: MissionActions.UPDATE_GOALS, validators: validatorController.validators })
      }
      // this will trigger a useEffect that watches for sceneState.ready and then subscribes to validators
      let tgt = state.activeMission.flow[state.ui.curFlowIndex].obj.target !== undefined ? state.activeMission.flow[state.ui.curFlowIndex].obj.target : Targets.SIM_CB2
      sceneDispatch({
        type: SceneActions.TARGET_AND_ENVIRONMENT_SET,
        requiresSim:curFlowRequiresSim,
        target: tgt,
        camIndex: state.activeMission.flow[state.ui.curFlowIndex].obj.camera,
        envIndex: state.activeMission.flow[state.ui.curFlowIndex].obj.scene,
        params: state.activeMission.flow[state.ui.curFlowIndex].obj?.sceneParams,
      })


      for (var goal in state.activeMission.flow[state.ui.curFlowIndex].obj.goals){
        state.activeMission.flow[state.ui.curFlowIndex].obj.goals[goal].success = validatorUpdated
        state.activeMission.flow[state.ui.curFlowIndex].obj.goals[goal].fail = validatorUpdated
        state.activeMission.flow[state.ui.curFlowIndex].obj.goals[goal].update = validatorUpdated
      }
      if (!curFlowRequiresSim || (sceneState.ready && state.activeMission.flow[state.ui.curFlowIndex].obj.scene === sceneState.envIndex)) {
        // Why was injectFiles called here too?
        validatorController.clear()
        validatorController.subscribe(state.activeMission.flow[state.ui.curFlowIndex].obj.goals, scoreInterface)
      }
    } else {
      validatorController.clear()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.ui.curFlowIndex, state.ui.activeMissionId, state.ui.activeMissionPackId, state.ui.isExploreMode, PROGRESSION_GATE_PANEL_OPENED, curFlowRequiresSim])

  React.useEffect(() => {
    if (state.ui.isExploreMode) {
      setRequiresSim(targetRequiresSim(sceneState.target))
    }
  }, [state.ui.isExploreMode, sceneState.target])

  const lessonContentReady = sceneState.ready && !loadingMission && !loadingFlow && state.initialLoadDone

  setMissionContextDispatch(dispatch)
  return (
    <MissionContext.Provider value={{state: {
      ...state,
      score,
      requiresSim,
      lessonContentReady,
      loadingMission,
    }, dispatch}}>
      {children}
    </MissionContext.Provider>
  )
}

const useMission = () => {
  const {state, dispatch} = React.useContext(MissionContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useMission must be used within a child of a MissionProvider'
    )
  }
  return [state, dispatch]
}

export { useMission, setMissionState }
