const sharedFilesKey = 'shared-files'

const store = {}
function setFileStoreDefaults() {
  store[sharedFilesKey] = {}
}
setFileStoreDefaults()

function setSharedFileInRepo(sharedFile) {
  store[sharedFilesKey][sharedFile.id] = sharedFile
}

function readSharedFileFromRepo(sharedFileId) {
  return store[sharedFilesKey][sharedFileId]
}

function readAllSharedFilesFromRepo() {
  return store[sharedFilesKey]
}

function deleteSharedFileFromRepo(sharedFileId) {
  delete store[sharedFilesKey][sharedFileId]
}

function deleteAllSharedFilesFromRepo() {
  store[sharedFilesKey] = {}
}

export {
  setSharedFileInRepo,
  readSharedFileFromRepo,
  readAllSharedFilesFromRepo,
  deleteSharedFileFromRepo,
  deleteAllSharedFilesFromRepo,
}