import React from 'react'
import { Button, Tooltip, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { genShadeColor, genContrastColor } from '../utils/shade-highlight-tools'
import { ROW_HEIGHT } from './ClassesDialogContent'

function getTitle(defaultGroup) {
  if (defaultGroup) {
    return (
      <Typography>
        {'Free classes cannot be left.'}
      </Typography>
    )
  }

  return (
    <div  style={{ display: 'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column' }}>
      <Typography>
        {'To leave a class you own,'}
      </Typography>
      <Typography>
        {'delete it on the class dashboard.'}
      </Typography>
      <Typography style={{fontStyle: 'italic'}}>
        {'dashboard.firialabs.com'}
      </Typography>
    </div>
  )
}

function LeaveClassButton({ onLeaveClick, group }) {
  // const classes = useStyles()

  const defaultGroup = !!group.default
  const userOwnsGroup = group?.owner
  const leaveDisabled = defaultGroup || userOwnsGroup

  if (leaveDisabled) {
    return (
      <div style={{width: '100%', height: '100%'}}>
        <Tooltip title={getTitle(defaultGroup)} placement='top' arrow style={{height: '100%'}}>
          <div style={{width: '100%', height: ROW_HEIGHT, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <CloseIcon
              stroke={genShadeColor(0.5)}
              strokeWidth={1}
              style={{color: leaveDisabled ? genContrastColor(0.3) : '#ff3333', fontSize: 29}}
            />
          </div>
        </Tooltip>
      </div>
    )
  }

  return (
    <Button
      style={{
        borderRadius: 0,
        width: '100%',
        height: ROW_HEIGHT,
      }}
      onClick={onLeaveClick}
      disabled={leaveDisabled}
    >
      <CloseIcon
        stroke={genShadeColor(0.5)}
        stroke-width={1}
        style={{color: leaveDisabled ? genContrastColor(0.3) : '#ff3333', fontSize: 29}}

      />
    </Button>
  )
}

export { LeaveClassButton }