import React from 'react'
import { appDefaults } from '../BotSimDefaults'
import { editorController } from '../EditorControl'
import { FileManagementActions, useFileManagement } from './FileManagementContext'
import { useLogin } from './LoginContext'
import { useUserConfig } from './UserConfigContext'
import { validatorController, ValidatorContextKeys } from '../ValidatorControl'
import { simFsController } from '../SimFileSystemControl'
import { useSnackbar } from 'notistack'
import { userSessionStore } from '../content-manager/user-session/user-session-store'
import { deleteAllSharedFilesFromRepo, deleteSharedFileFromRepo } from '../content-manager/code-files/code-file-store'
import { getSharedFilesArray } from '../content-manager/code-files/code-file-use-cases/getSharedFilesArray'
import { isFileShared } from '../content-manager/code-files/code-file-use-cases/isFileShared'
import { codeFileUseCases } from '../content-manager/code-files/code-file-use-cases'
import { useNotifications } from './NotificationsContext'
import { LOGGED_OUT_FILE_ID } from '../content-manager/code-files/code-file-gateways'
import { firstTimeLogin } from '../content-manager/user-session/user-session-use-cases/firstTimeLogin'
import { loggedOutLocalStorageController } from '../databaseworker/database.worker.controller'

// This prevents the code panel context from overwriting the CodeTrek editor. Search for codeTrekPath in the CodePanelContext.js
// to see it's implementation.
export const codeTrekPath = 'kdfbhavuinkjalwefuyahdfkl;asnvlknznxbcvhjabdfklwhefasdfzxcvtykokjn98754kj'
export const CodePanelActions = Object.freeze({
  EDITOR_INSTANCE_SET: Symbol('set editor instance'),
  EDITOR_READY: Symbol('set editor ready'),
  EDITOR_CONTENT_CHANGED: Symbol('editor content changed'),
  OPEN_TAB: Symbol('open tab'),
  OPEN_TAB_BY_FILENAME: Symbol('open tab by filename'), // instead of by uid
  CLOSE_TABS: Symbol('close tab'),
  MOVE_TAB: Symbol('move tab'),
  DOWNLOAD_TAB: Symbol('download tab'),
  DELETE_TAB: Symbol('delete tab'),
  UPDATE_SHARED_FILES_TABS: Symbol('update shared files tabs'),
})
export var codePanelDispatch = () => {}

validatorController.addContextActions(ValidatorContextKeys.CODE_PANEL, CodePanelActions)

export const decodeModelPath = (modelPath) => {
  const uid = modelPath.substring(1, 37)
  const filePath = modelPath.substring(37, modelPath.length)
  return {uid: uid, filePath: filePath}
}

const CodePanelContext = React.createContext()
export const CodePanelProvider = ({ children }) => {
  const snacks = useSnackbar()
  const [loginState] = useLogin()
  const { notificationsStateInterface } = useNotifications()
  const [userConfigState] = useUserConfig()
  const [fileManagementState, fileManagementDispatch] = useFileManagement()
  const editorInstance = React.useRef()
  const monacoInstance = React.useRef()
  const [editorReady, setEditorReady] = React.useState(false)
  const [editorInstanceAvailable, setEditorInstanceAvailable] = React.useState(false)
  const [tabs, setTabs] = React.useState(appDefaults.codePanel.tabs)
  const [sharedFileMode, setSharedFileMode] = React.useState(false)

  const tabFocused = tabs?.focused
  React.useEffect(() => {
    if (isFileShared(tabFocused)) {
      setSharedFileMode(true)
    } else {
      setSharedFileMode(false)
    }
  }, [tabFocused])

  const saveTimersRef = React.useRef({})
  const codeRef = React.useRef({})
  const initializedFileIds = React.useRef({})
  // Save code file after some amount of time of no typing activity
  const debounceSave = (focusedTab, code) => {
    if (!focusedTab) {
      return
    }

    if (!initializedFileIds.current[focusedTab]) {
      initializedFileIds.current[focusedTab] = true
      return
    }

    codeRef.current[focusedTab] = code
    function save() {
      if (codeRef.current[focusedTab] === undefined) {
        throw new Error('CodeRef was unexpectedly undefined')
      }
      const fileContent = codeRef.current[focusedTab]
      delete codeRef.current[focusedTab]
      delete saveTimersRef.current[focusedTab]
      if (!loginState?.user) {
        codeFileUseCases.saveFile(null, fileContent)
      } else {
        fileManagementDispatch({
          type: FileManagementActions.SAVE_FILE,
          uid: focusedTab,
          code: fileContent,
        })
      }
    }

    if (!saveTimersRef.current[focusedTab]) {
      saveTimersRef.current[focusedTab] = { to:setTimeout(save, 2000), func:save }
    }
  }

  React.useEffect(() => {
    return () => {
      Object.values(saveTimersRef.current).forEach(({func}) => func())
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(saveTimersRef.current).forEach(({to}) => clearTimeout(to))
    }
  }, [])

  React.useEffect(() => {
    initializedFileIds.current = {}
  }, [loginState])

  const writeTabDataToFile = async (tabs) => {
    try {
      await fileManagementDispatch({ type: FileManagementActions.SAVE_EDITOR_STATE, editorState: {tabs} })
    } catch (err) {
      console.error(err)
    }
  }

  const stripSharedTabs = (tabs) => {
    if (tabs.opened.size === 0) {
      return appDefaults.codePanel.tabs
    }

    const filteredOpenedTabs = [...tabs.opened].filter(tabId => !isFileShared(tabId))
    if (filteredOpenedTabs.length === 0) {
      return appDefaults.codePanel.tabs
    }

    let focused = tabs.focused
    if (isFileShared(tabs.focused)) {
      focused = filteredOpenedTabs[0]
    }

    return {
      focused,
      opened: new Set(filteredOpenedTabs),
    }
  }
  // Save editor state to file everytime the tabs change
  React.useEffect(() => {
    if (!fileManagementState.fileSystemReady || !editorReady) {
      return
    }

    writeTabDataToFile(stripSharedTabs(tabs))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs])
  //

  const getFileContents = React.useCallback(async (editorModel, path) => {
    const decodedPath = decodeModelPath(path)
    let code
    try {
      code = await codeFileUseCases.getFile(decodedPath.uid)
      if (code === undefined) {
        code = !fileManagementState.tree?.size ? appDefaults.initialCodeSnippet[userConfigState.codeLanguage] : ''
      }
    } catch (err) {
      code = !fileManagementState.tree?.size ? appDefaults.initialCodeSnippet[userConfigState.codeLanguage] : ''
    }
    editorModel.setValue(code ?? '')
  }, [fileManagementState.tree, userConfigState.codeLanguage])
  /**
   * Sets the code contents of each open file's unique Monaco model. The Editor component
   * (in CodePanel.js) will load the model based on a provided `path` option (currently
   * gets set to the focused tab's file UID).
   *
   * This will happen only on the first time the file is loaded. Meaning, this is the only time
   * a code file gets 'pulled down' from the cloud. Closing the tab and reopening will not re-pull,
   * as Monaco caches the model once it has been created (until the Editor component unmounts).
   */
  React.useEffect(() => {
    if (!!monacoInstance.current) {
      const disposable = monacoInstance.current.editor.onDidCreateModel(async (model) => {
        var path = model.uri.path
        if (path === `/${codeTrekPath}`) {
          return
        }
        // Monaco stores the URI path with a leading '/', so we remove it to end up with
        // a simple file UID
        if (path.startsWith('/root') || path.startsWith('/nologin')){
          path = tabs.focused
        }
        await getFileContents(model, path)
      })
      return () => disposable.dispose()
    }
  })

  /**
   * Open a tab, BY FILENAME rather than UID
   * @param {string} filename associated with the tab to be focused on
   * @returns {Promise<void>}
   */
  const openTabByFilename = async (filename) => {
    return new Promise(async (resolve, reject) => {
      const matchingUids = [...(fileManagementState.tree?.entries() ?? [])]
        .flatMap(([fileUid, metadata]) => metadata.name === filename ? [fileUid] : [])

      if (matchingUids.length > 0) {
        const uidToOpen = matchingUids[0]
        await openTab(uidToOpen)
        resolve()
      } else {
        // If we get here, the file no longer exists. I supposed this could happen if a
        // user deletes the file during a debug session. For now, pop up a snackbar, and then
        // reject this promise. The calling function can catch the rejection and perform other
        // tasks (such as terminating a running debug session).
        // TODO: We should 'lock' the file (but do not persist lock flag to cloud storage).
        // That way, both editing and deleting could be disallowed while debugging is active.
        snacks.enqueueSnackbar('Error: file "'+filename+'" not found. Did you delete it?', {
          variant: 'error',
        })
        reject()
      }
    })
  }

  /**
   * Open a tab
   * @param {string} fileUid ID of file to open & focus on
   */
  const openTab = async (fileUid) => {
    setTabs((tabs) => {
      tabs.opened.add(fileUid)
      return {
        focused: fileUid,
        opened: new Set(tabs.opened),
      }
    })
  }

  /**
   * Close one or more tabs
   * @param {string[]} fileUids Array of file IDs to close
   */
  const closeTabs = async (fileUids) => {
    if (!tabs?.opened) {
      return
    }
    let focusedTabIndex = [...tabs.opened].indexOf(tabs.focused)
    fileUids.forEach(tab => tabs.opened.delete(tab))
    focusedTabIndex = Math.max(Math.min(Math.max(0, focusedTabIndex - 1), tabs.opened.size - 1), 0)
    tabs.focused = [...tabs.opened][focusedTabIndex]

    setTabs({
      focused: tabs.focused,
      opened: new Set(tabs.opened),
    })
  }

  // Use File System Access API to save where user prefers
  const downloadFile = async () => {
    const suggestedName = fileManagementState.tree?.get(tabs.focused)?.name ?? 'Untitled Program'

    const editorModel = await getModelFromUid(tabs.focused)
    const path = editorModel.uri.path

    const decodedPath = decodeModelPath(path)
    let contents
    if (!!fileManagementState.tree?.get(decodedPath.uid)) {
      contents = await codeFileUseCases.getFile(decodedPath.uid)
    } else {
      contents = !fileManagementState.tree?.size ? appDefaults.initialCodeSnippet[userConfigState.codeLanguage] : ''
    }

    const opts = {
      suggestedName: suggestedName,   // Supported as of Chrome 91
      types: [{
        description: 'Text File',
        accept: { 'text/plain': ['.txt'] },
      },{
        description: 'Python Script',
        accept: { 'text/x-python': ['.py'] },
      }],
      // types: [{
      //   description: 'JSON file',
      //   accept: { 'application/json': ['.json'] },
      // }],
    }
    const fileHandle = await window.showSaveFilePicker(opts)
    const writable = await fileHandle.createWritable()
    await writable.write(contents)
    await writable.close()
  }

  /**
   * Get editor model from uid
   * @param {string} fileUid ID of file to open & focus on
   */
  const getModelFromUid = async (fileUid) => {
    const path = await fileManagementDispatch({ type: FileManagementActions.GET_ABSOLUTE_FILE_PATH, fileUid: fileUid })
    if (!path){
      return
    }

    const editorModel = monacoInstance.current.editor.getModels().find((model) => {
      const decodedPath = decodeModelPath(model.uri.path)
      return decodedPath.uid === fileUid && decodedPath.filePath === '/'+path
    })
    if (!editorModel){
      return
    }

    return editorModel
  }

  const mixInSharedFiles = (tabs) => {
    const sharedFiles = getSharedFilesArray()
    if (!sharedFiles || sharedFiles.length === 0) {
      return tabs
    }

    return {
      focused: sharedFiles[0].id,
      opened: new Set([...sharedFiles.map(file => file.id), ...tabs.opened]),
    }
  }


  // Close all tabs for this session and open either the default
  // code snippet, or the user's last opened & focused tab(s)
  React.useEffect(() => {
    if (!loginState) {
      return
    }

    // Close any open tabs upon sign in/out
    closeTabs([...tabs.opened])
    if (!loginState?.user) {
      // User has not yet logged in, or has just logged out...

      // Start a new tab. The code panel will decide the contents (likely appDefault's initialCodeSnippet)

      if (userSessionStore.readUserHasLoggedInDuringSession()) {
        deleteAllSharedFilesFromRepo()
      }

      openTab(LOGGED_OUT_FILE_ID)
    } else {
      // User is now logged in...
      if (fileManagementState.fileSystemReady) {
        const readTabDataFromFile = async () => {
          setEditorReady(false)
          // If user has no files yet, go ahead and create, bootstrap, & open a new [default] file
          if (!fileManagementState.tree?.get(fileManagementState.root)?.tree?.length) {
            let code = appDefaults.initialCodeSnippet.python
            if (firstTimeLogin()) {
              code = (await loggedOutLocalStorageController.readFile(LOGGED_OUT_FILE_ID)) ?? code
            }
            const newFileUid = await fileManagementDispatch({
              type: FileManagementActions.NEW_FILE,
              parentFolder: fileManagementState.root,
              name: 'Default Program',
              code,
            })
            const tabs = mixInSharedFiles({
              focused: newFileUid,
              opened: new Set([newFileUid]),
            })
            writeTabDataToFile(tabs)
            setTabs(tabs)
          } else {
            // Otherwise, restore the user's previous state
            const editorState = await fileManagementDispatch({ type: FileManagementActions.READ_EDITOR_STATE })
            setTabs(mixInSharedFiles(editorState.tabs))
          }
          setEditorReady(true)
        }
        readTabDataFromFile()
        // If the pre-login code is not blank, and has been modified from the default code snippet,
        // prompt the user to name & save it somewhere
        // if (!!tabs.opened.get(tabs.focused) && tabs.opened.get(tabs.focused) !== defaultCodeSnippet) {
        //   // TODO: Prompt user to save, and merge this tab (keeping it as the 'focused' tab)
        //   // with the user's other opened tabs
        // }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileManagementState.fileSystemReady])

  // Compute the current file's human-readable name
  const filename = React.useMemo(() => {
    if (!!tabs.focused) {
      return fileManagementState.tree?.get(tabs.focused)?.name
    }
  }, [fileManagementState.tree, tabs.focused])

  const dispatch = async (action) => {
    validatorController.handleContextEvent(action)

    switch (action.type) {
    // { type: ..., editorInstance: monaco.editor.IStandaloneCodeEditor, monacoInstance: Monaco }
      case CodePanelActions.EDITOR_INSTANCE_SET: {
        const { editorInstance: editor, monacoInstance: monaco } = action

        editorController.setEditorInstance(editor)

        editor.onMouseDown((ev) => {
          notificationsStateInterface.displayNotificationReminder()
          editorController.bpMouseDown(ev)
        })
        editor.onMouseMove((ev) => {
          editorController.bpMouseMove(ev)
        })
        editor.onMouseLeave((ev) => {
          editorController.bpMouseLeave(ev)
        })
        editor.onDidChangeModelContent((ev) => {
          editorController.bpChangeContent(ev)
        })

        // Mission Editor doesn't provide 'monaco' instance, so dodge that issue here.
        // TODO: update EditMissionPanel to supply monaco instance (currently doing so breaks EditMissionPanel)
        if (monaco) {
          editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S, function() {
            // Prevent browser save dialog when ^s is pressed reflexively
            console.log('Saved!')  // Satisfying message
          })
        }

        editorInstance.current = editor
        monacoInstance.current = monaco

        // If the editor has already created a model at this point,
        // set the contents on it.
        // A useEffect above will ensure that onDidCreateModel is up to date with
        // the latest state (to retrieve code files from the file tree)
        let model = editor.getModel()
        if (!!model) {
          await getFileContents(model, model.uri.path)
        }

        setEditorInstanceAvailable(true)
        setEditorReady(true)
        break
      }

      // { type: ..., ready: Bool }
      case CodePanelActions.EDITOR_READY: {
        setEditorReady(action.ready)
        break
      }

      // { type: ..., code: String }
      case CodePanelActions.EDITOR_CONTENT_CHANGED: {
        debounceSave(tabs.focused, action.code)
        break
      }

      // { type: ..., fileUid: UUID, focused: BOOL }
      case CodePanelActions.OPEN_TAB: {
        await openTab(action.fileUid)
        break
      }

      // { type: ..., string: filename }
      case CodePanelActions.OPEN_TAB_BY_FILENAME: {
        try {
          await openTabByFilename(action.filename)
        } catch (err) {
          throw err
        }
        break
      }

      // { type: ..., fileUids: [UUID] }
      case CodePanelActions.CLOSE_TABS: {
        const sharedTabs = action.fileUids.filter(fileUid => isFileShared(fileUid))
        sharedTabs.map(deleteSharedFileFromRepo)
        await closeTabs(action.fileUids)
        break
      }

      case CodePanelActions.MOVE_TAB: {
        // To be implemented with tabs update
        break
      }

      // { type: ..., fileUid: string }
      case CodePanelActions.UPDATE_TAB: {
        const editorModel = await getModelFromUid(action.fileUid)
        if (!editorModel){
          break
        }

        let code
        if (!loginState?.user) {
          code = await codeFileUseCases.getFile()
        } else {
          code = await fileManagementDispatch({ type: FileManagementActions.READ_FILE, uid: action.fileUid })
        }
        if (!code){
          break
        }

        editorModel.setValue(code ?? '')
        break
      }

      // { type: ... }
      case CodePanelActions.DOWNLOAD_TAB: {
        downloadFile(tabs)
        break
      }

      // { type: ..., fileUid: string }
      case CodePanelActions.DELETE_TAB: {
        if (tabs.focused !== action.fileUid){
          tabs.opened.delete(action.fileUid)
          setTabs({
            focused: tabs.focused,
            opened: new Set(tabs.opened),
          })
        } else {
          await closeTabs([action.fileUid])
        }

        if (isFileShared(action.fileUid)) {
          deleteSharedFileFromRepo(action.fileUid)
        }

        const editorModel = await getModelFromUid(action.fileUid)
        if (!editorModel){
          break
        }

        editorModel.dispose()
        break
      }

      case CodePanelActions.UPDATE_SHARED_FILES_TABS: {
        setTabs(existingTabs => mixInSharedFiles(existingTabs))
        break
      }

      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }

  codePanelDispatch = dispatch
  simFsController.setTabCbs(
    fileUid => dispatch({ type: CodePanelActions.UPDATE_TAB, fileUid: fileUid }),
    fileUid => dispatch({ type: CodePanelActions.DELETE_TAB, fileUid: fileUid })
  )

  return (
    <CodePanelContext.Provider
      value={{
        state: {
          editorInstance: editorInstance.current,
          editorInstanceAvailable,
          editorReady,
          tabs,
          filename,
          sharedFileMode,
        },
        dispatch,
      }}>
      {children}
    </CodePanelContext.Provider>
  )
}

export const useCodePanel = () => {
  const {state, dispatch} = React.useContext(CodePanelContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useCodePanel must be used within a child of a CodePanelProvider'
    )
  }
  return [state, dispatch]
}
