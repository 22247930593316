import { readContentSequence, readContentSequenceIndex, readContentSequenceItem } from '../content-director-store'
import { changeContentSequenceIndex } from './changeContentSequenceIndex'
import { ContentSequenceItemTypes } from '../ContentSequenceItemTypes'
import { findNextUncompletedFlowItemIndexInActiveMission } from '../../user-progress/user-progress-use-cases/findNextUncompletedFlowItemIndexInActiveMission'

async function loadNextBestContentSequenceIndex() {
  const oldContentSequenceIndex = readContentSequenceIndex()
  const newContentSequenceIndex = oldContentSequenceIndex + 1
  const contentSequence = readContentSequence()

  if (contentSequence.length === newContentSequenceIndex) {
    changeContentSequenceIndex(0)
    return
  }

  const contentSequenceItem = readContentSequenceItem(newContentSequenceIndex)
  if (contentSequenceItem.contentSequenceType === ContentSequenceItemTypes.PROGRESSION_GATE && !contentSequenceItem.progressionGate.locked) {
    changeContentSequenceIndex(newContentSequenceIndex + 1)
    return
  }

  const nextUncompletedFlowItemIndex = await findNextUncompletedFlowItemIndexInActiveMission()
  // If the next flow index isn't unlocked yet, set the user to the nearest unlocked flow index.
  if (nextUncompletedFlowItemIndex === null || nextUncompletedFlowItemIndex >= contentSequenceItem?.flow.flowIndex) {
    changeContentSequenceIndex(newContentSequenceIndex)
    return
  }

  changeContentSequenceIndex(nextUncompletedFlowItemIndex)
}

export { loadNextBestContentSequenceIndex }