// Mission and Objective Panels
import React from 'react'
import { Typography, Accordion,AccordionSummary, AccordionDetails  } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'

function AssignmentSubmissionInfoPanel() {
  return (
    <div>
      <Accordion style={{border: '1px solid rgba(0,0,0,0.2)'}}>
        <AccordionSummary
          expandIcon={<InfoIcon />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <Typography style={{fontWeight: 500}}>{'How do I submit my assignment?'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <Typography>
              {'There are two different ways to submit your assignment, manually or validated. A manual submission can be done at any time by hitting the assignment icon in the bottom left hand corner of any objective panel with an assignment. A validated submission can only be done directly after completing an objective. Any objective with an assignment will prompt the user to submit their work on completion.'}
            </Typography>
            <div style={{height: 40}}></div>
            <Typography style={{fontWeight: 500}}>
              {'What\'s the difference between a manual and validated submission?'}
            </Typography>
            <div style={{height: 20}}></div>
            <Typography>
              {'Your instructor will know whether your submission was manual or validated. A validated submission proves that the code you submitted completes the objective. It\'s recommended to always use a validated submission unless you\'re stuck on an objective.'}
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>

    </div>
  )
}

export { AssignmentSubmissionInfoPanel }
