import { getActiveMission } from '../../content-director/content-director-use-cases/getActiveMission'
import { findNextUncompletedFlowItem } from './findNextUncompletedFlowItem'
import { getFlowItemIdFlowItemsIndex } from '../../lesson-content/lesson-content-use-cases'

async function findNextUncompletedFlowItemIndexInActiveMission() {
  const mission = getActiveMission()

  const nextUncompletedFlowItem = await findNextUncompletedFlowItem(mission.id)
  if (nextUncompletedFlowItem === null) {
    return nextUncompletedFlowItem
  }
  return getFlowItemIdFlowItemsIndex(mission.id, nextUncompletedFlowItem)
}

export { findNextUncompletedFlowItemIndexInActiveMission }