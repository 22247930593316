import React from 'react'
import { Dialog, DialogContent, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGroups } from '../contexts/GroupsContext'
import { AssignmentDetail } from './AssignmentSubmissionPanel'
import { ValidatedAssignmentSubmissionButton } from './SubmissionButtons'
import { useContentDirector } from '../contexts/ContentDirectorContext'

var openAssignmentSubmissionValidationDialog = () => {}
function ValidatedAssignmentSubmissionDialog({ completionBannerVisible, handleClose, onBannerContinue }) {
  const { submitAssignmentSucceeded } = useGroups()
  const { assignment } = useContentDirector()
  const [open, setOpen] = React.useState(false)

  openAssignmentSubmissionValidationDialog = () => {
    if (!assignment?.submissionsRemaining) {
      return
    }
    setOpen(true)
  }

  React.useEffect(() => {
    if (submitAssignmentSucceeded) {
      setOpen(false)
    }
  }, [submitAssignmentSucceeded])

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <div style={{overflow:'hidden'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px'}}>
          <Typography variant='h6'>{'You\'ve got an assignment for this objective!'}</Typography>
          <IconButton onClick={() => setOpen(false)} size='small'>
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent style={{height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <AssignmentDetail />
          <ValidatedAssignmentSubmissionButton />
        </DialogContent>
        <div style={{height: 10}}></div>
      </div>
    </Dialog>
  )
}

export { ValidatedAssignmentSubmissionDialog, openAssignmentSubmissionValidationDialog }