import { getActiveMission } from './getActiveMission' // for gtag reporting purposes
import { setContentSequenceIndex } from '../content-director-store'
import { getActiveContentSequenceItem } from './getActiveContentSequenceItem'
import {
  presentFlowIndex,
  presentProgressionGate,
  presentContentDirector,
}
from '../content-director-presenter'
import { ContentSequenceItemTypes } from '../ContentSequenceItemTypes'
import { resetScore } from './score-actions'

function changeContentSequenceIndex(newContentSequenceIndex) {
  // The lowest impact logging would be the following, but the objective numbers 0-n are not very helpful by themselves
  //window.gtag('event', 'select_content', {'content_type':'objective','content_id':String(newContentSequenceIndex)})
  // Go ahead and prepend the mission to the objective number so we get something useful
  const mission = getActiveMission()
  //console.log('mission=')
  //console.log(mission)
  window.gtag('event', 'select_content', {'content_type':'mission_objective','content_id':String(mission.id)+'_'+String(newContentSequenceIndex)})
  resetScore()
  setContentSequenceIndex(newContentSequenceIndex)
  if (newContentSequenceIndex === -1) {
    // presentMissionInfo()
    return
  }

  const activeContentSequenceItem = getActiveContentSequenceItem()
  if (activeContentSequenceItem.contentSequenceType === ContentSequenceItemTypes.FLOW) {
    presentFlowIndex(activeContentSequenceItem.flow.flowIndex)
  } else {
    presentProgressionGate()
  }
  presentContentDirector()
}

export { changeContentSequenceIndex }
