import { userSessionGateway } from '../user-session-gateway'
import { pollerController } from '../../utils/poller-controller'

var poller = null

function stopPoller() {
  clearInterval(poller)
  poller = null
}

function startPoller() {
  stopPoller()
  poller = setInterval(userSessionGateway.updateActivity, 60 * 1000)
}

const userSessionActivityPoller = {
  start: startPoller,
  stop: stopPoller,
}
pollerController.addPoller('userSessionActivityPoller', userSessionActivityPoller.stop)
export { userSessionActivityPoller }