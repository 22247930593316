
const tagFinder = ({ string, tag, startIdx = 0 }) => {
  const a = string.slice(0, startIdx)
  const b = string.slice(startIdx)

  var idxAStart = -1
  var idxAEnd = 0
  while (!a.slice(idxAStart).includes(`<${tag}`)) {
    idxAStart -= 1
  }

  while (string[startIdx + idxAStart + idxAEnd] !== '>') {
    idxAEnd += 1
  }
  idxAEnd += 1

  var idxB = 1
  while (!b.slice(0, idxB).includes(`</${tag}>`)) {
    idxB += 1
  }

  const aStartIdx = startIdx + idxAStart
  const aEndIdx = aStartIdx + idxAEnd

  const bEndIdx = startIdx + idxB
  const bStartIdx = bEndIdx - 3 - tag.length

  const content = string.slice(aEndIdx, bStartIdx)
  return {
    aStartIdx,
    aEndIdx,
    bStartIdx,
    bEndIdx,
    content,
  }
}

const postProcessCodeTrekHTML = ({ html, findSubstringIndexes, idxFind, pushSplice }) => {
  const trekStart = findSubstringIndexes(html, '<div id="ripperCodeTrekStart')[0]
  const trekEnd = findSubstringIndexes(html, '<div id="ripperCodeTrekEnd"')[0]

  if (trekStart && trekEnd){
    const stepsStart = findSubstringIndexes(html, '<div id="ripperStepStart"></div>')
    const stepsEnd = findSubstringIndexes(html, '<div id="ripperStepEnd"></div>')
    const stepIdxs = [...stepsStart, ...stepsEnd].sort((a, b) => parseInt(a) - parseInt(b))

    const markdowns = {}
    for (var i=0; stepIdxs.length > i; i+=2) {
      const startIdx = stepIdxs[i]
      const endIdx = stepIdxs[i+1]
      const markdown = html.slice(startIdx, endIdx).slice(32)
      const index = markdown.split('stepMarkdown;')[1].split('"')[0]
      pushSplice([startIdx, endIdx-startIdx, ''])
      markdowns[index] = markdown
    }

    findSubstringIndexes(html, /#@[0-9]<\/span>/).forEach((idx) => {
      const tagInfo = tagFinder({ string: html, tag:'span', startIdx: idx })
      const markdownIdx = parseInt(tagInfo.content.split('#@')[1])
      pushSplice([tagInfo.aStartIdx, tagInfo.bEndIdx - tagInfo.aStartIdx, markdowns[markdownIdx]])
    })
  }
}

export default postProcessCodeTrekHTML