import { getActiveMissionPack } from './getActiveMissionPack'
import { readActiveMissionId } from '../content-director-store'
import { changeActiveMission } from './changeActiveMission'

function iterateActiveMissionIndex() {
  const activeMissionPack = getActiveMissionPack()
  const activeMissionId = readActiveMissionId()
  const activeMissionIdx = activeMissionPack.missions.findIndex(mission => mission.id === activeMissionId)
  if (activeMissionPack.missions.length === activeMissionIdx + 1) {
    return
  }

  changeActiveMission(activeMissionPack.missions[activeMissionIdx + 1].id, -1)
}

export { iterateActiveMissionIndex }