import { userFileGateway } from './user-file-gateway'
import { getActiveUserFilesLocalStorageController, loggedOutLocalStorageController } from '../../databaseworker/database.worker.controller'
import { userIsLoggedIn } from '../user-session/user-session-use-cases/userIsLoggedIn'
import UserFilenames from '../../databaseworker/userFilenames'

class UserFileUseCases {
  async initializeUserFile(filename, defaultFileContents) {
    let { generateDefaultFile, fileContents } = await userFileGateway.getFromCloud(filename, true)

    if (generateDefaultFile) {
      fileContents = defaultFileContents
      await userFileGateway.saveInCloud(filename, defaultFileContents, true)
    }

    await getActiveUserFilesLocalStorageController().saveObject(filename, fileContents)
  }

  async getUserProgress() {
    if (!userIsLoggedIn()) {
      return await loggedOutLocalStorageController.readObject(UserFilenames.USER_PROGRESS)
    }

    return await getActiveUserFilesLocalStorageController().readObject(UserFilenames.USER_PROGRESS)
  }

  async saveUserProgress(userProgress) {
    if (!userIsLoggedIn()) {
      return await loggedOutLocalStorageController.saveObject(UserFilenames.USER_PROGRESS, userProgress)
    }

    return await Promise.allSettled([
      getActiveUserFilesLocalStorageController().saveObject(UserFilenames.USER_PROGRESS, userProgress),
      userFileGateway.saveInCloud(UserFilenames.USER_PROGRESS, userProgress),
    ])
  }

  async getUserConfig() {
    return await getActiveUserFilesLocalStorageController().readObject(UserFilenames.USER_CONFIG)
  }

  async saveUserConfig(userConfig) {
    return await Promise.allSettled([
      getActiveUserFilesLocalStorageController().saveObject(UserFilenames.USER_CONFIG, userConfig),
      userFileGateway.saveInCloud(UserFilenames.USER_CONFIG, userConfig),
    ])
  }

  async getEditorState() {
    return await getActiveUserFilesLocalStorageController().readObject(UserFilenames.EDITOR_STATE)
  }

  async saveEditorState(editorState) {
    return await Promise.allSettled([
      getActiveUserFilesLocalStorageController().saveObject(UserFilenames.EDITOR_STATE, editorState),
      userFileGateway.saveInCloud(UserFilenames.EDITOR_STATE, editorState),
    ])
  }
}

const userFileUseCases = new UserFileUseCases()
export { userFileUseCases }