// Provide for data-driven creation of new peripheral objects

import SevenSegment from './SevenSegment.js'
import BreadBoard from './BreadBoard.js'
import JumperWire, {JumperWireSet4} from './JumperWire.js'
import Neopixel16 from './Neopixel16.js'

function createPeripheral(name, params) {
  let p = null

  switch (name) {
    case 'SevenSegment':
      p = new SevenSegment(params.rxPin, params.txPin)
      break

    case 'Breadboard':
      p = new BreadBoard()
      break

    case 'JumperWire':
      p = new JumperWire(params.color, params.pos, params.rot)
      break

    case 'JumperWireSet4':
      p = new JumperWireSet4()
      break

    case 'Neopixel16':
      p = new Neopixel16(params.pos, params.rot, params.dataPin)
      break

    default:
      console.log(`Error: unknown peripheral "${name}"`)
      break
  }
  return p
}

export default createPeripheral