import React from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined'
import FiriaMarkdown from '../../FiriaMarkdown'
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined'
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined'
import DescriptionIcon from '@material-ui/icons/Description'
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined'
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { Typography } from '@material-ui/core'
import { useMission } from '../../contexts/MissionContext'
import { useUserConfig } from '../../contexts/UserConfigContext'
import { GoalCheckbox } from '../../ObjectivePanel'
import ToysIcon from '@material-ui/icons/Toys'
import {
  DirectionsWalk as DirectionsWalkIcon,
  TouchApp as TouchAppIcon,
} from '@material-ui/icons'
import peripheralsData from '../../Toolbox/PeripheralsData'

const useStyles = makeStyles(theme => ({
  md: {
    width: '100%',
  },
  sectionPanelContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.type === 'dark' ? 'rgba(255,255,255,0.05)':'rgba(0,0,0,0.15)'}`,
    padding: '5px 10px',
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0)',
    boxShadow: 'rgba(0, 0, 0, 0.6) 5px 5px 10px 0px',
    width: '100%',
  },
  flipX: {
    transform:[{ scaleX: '-1' }],
  },
  codexCanvasContainer: {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    position: 'relative',
    float: 'left',
    alignItems: 'center',
  },
  codexCanvas: {
    position: 'relative',
    zIndex: 2,
    display: 'block',
    left: 0,
    right: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  codexImage: {
    position: 'absolute',
    zIndex: 1,
    display: 'block',
    left: 0,
    right: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  peripherals: {
    width: '350px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'row',
  },
}))

function IconPanelContainer({ Icon, color, lightColor, children }) {
  const [userConfigState] = useUserConfig()

  let iconColor = color
  if (userConfigState.lightTheme) {
    iconColor = lightColor ?? color
  }
  return (
    <span style={{display:'flex', justifyContent: 'flex-start', padding: 10,
      paddingLeft: 0,
      paddingRight:8}}>
      {<Icon style={{
        color: iconColor, fontSize: 30, marginTop: 5, marginLeft: -6, marginRight: 4,
        background: 'radial-gradient(ellipse at center, rgba(0,0,0,0.2) 0%,rgba(255,255,255,0) 65%,rgba(255,255,255,0) 100%)',
      }}/>}
      {children}
    </span>
  )
}

function SectionPanelContainer({ children }) {
  const classes = useStyles()
  return (
    <span className={classes.sectionPanelContainer}>
      {children}
    </span>
  )
}

function LessonContentPanelContainer({ Icon, color, lightColor, children }) {
  return (
    <IconPanelContainer {...{Icon, color, lightColor}}>
      <SectionPanelContainer>
        {children}
      </SectionPanelContainer>
    </IconPanelContainer>
  )
}

function makeLessonSectionPanel(Icon, titlePrefix, color, lightColor) {
  return ({ title, content, error }) => {
    const classes = useStyles()
    if (!!error) {
      return (
        <Typography>{`!!! --- ${error} --- !!!`}</Typography>
      )
    }
    return (
      <LessonContentPanelContainer {...{ Icon, color, lightColor}}>
        <div style={{display:'flex', justifyContent: 'flex-start'}}>
          <span style={{fontSize: 20}}>
            {`${titlePrefix}${!!title ? ':' : ''}`}
          </span>
          {!!title ?
            <span style={{fontStyle: 'italic', fontSize: 20, paddingLeft: 5}}>
              {title}
            </span>:null
          }
        </div>
        <div style={{width:'100%'}}>
          <FiriaMarkdown  className={classes.md}>
            {content}
          </FiriaMarkdown>
        </div>
      </LessonContentPanelContainer>
    )
  }
}

function FilePanel({ content, title }) {
  let prompt = 'Create a New File!'
  let menu = 'New File'
  if (title.endsWith(',SaveAs')) {
    prompt = 'Save to a New File!'
    menu = 'Save As'
    title = title.slice(0, -7)
  }
  return (
    <LessonContentPanelContainer {...{ Icon: DescriptionIcon, color: 'white', lightColor:'black'}}>
      <span style={{fontSize: 20}}>
        {prompt}
      </span>
      <FiriaMarkdown>
        {`Use the **File → ${menu}** menu to create a new file called ***${title}***.`}
        {content}
      </FiriaMarkdown>
    </LessonContentPanelContainer>
  )
}

function RunItPanel({ content, goalIndex  }) {
  const [missionState] = useMission()

  function handleRunItGoalCheckbox() {
    const goals = missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.obj?.goals
    const parsedIdx = parseInt(goalIndex)
    if (goalIndex === undefined || !goals || goals.length === 0 || goals.length < parsedIdx) {
      return null
    }

    return <GoalCheckbox validated={goals[parsedIdx]?.validated} />
  }

  return (
    <LessonContentPanelContainer {...{Icon: PlayArrowOutlinedIcon, color: '#89d185', lightColor: '#00802b'}}>
      <div style={{display: 'flex', alignItems:'center'}}>
        <span style={{fontSize: 20}}>
          {'Run It!'}
        </span>
        {handleRunItGoalCheckbox()}
      </div>
      <div style={{width:'100%'}}>
        <FiriaMarkdown>
          {content}
        </FiriaMarkdown>
      </div>
    </LessonContentPanelContainer>
  )
}

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    width: '100%',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    width: '100%',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

function PeekPanel({ title, content }) {
  const classes = useStyles()
  return (
    <IconPanelContainer {...{Icon: HelpOutlineIcon, color: '#ff80ff'}}>
      <Accordion style={{
        backgroundColor: 'rgba(255,255,255,0.05)',
        boxShadow: 'rgba(0, 0, 0, 0.6) 5px 5px 10px 0px',
        borderRadius: 0,
        width: '100%',
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <span style={{fontSize: 20, fontWeight: 'bold'}}>
            {title}
          </span>
        </AccordionSummary>
        <AccordionDetails>
          <FiriaMarkdown className={classes.md}>
            {content}
          </FiriaMarkdown>
        </AccordionDetails>
      </Accordion>
    </IconPanelContainer>
  )
}

function ConnectPeripheralPanel({ title, content, pin, peripheral, hideWire, useDivider, glowColor }) {
  const classes = useStyles()

  const canvasRef = React.useRef(null)

  // Draw 'light' coming from the NeoPixel next to the port
  React.useEffect(() => {
    if (!glowColor) {
      // Only if color is not set externally
      // eslint-disable-next-line react-hooks/exhaustive-deps
      glowColor = 'rgb(0, 255, 0)'
    }

    // from https://codepen.io/chanthy/pen/WxQoVG
    function drawArrow(ctx, fromx, fromy, tox, toy, arrowWidth, color) {
      // variables to be used when creating the arrow
      const headlen = 10
      const angle = Math.atan2(toy-fromy,tox-fromx)

      ctx.save()
      ctx.strokeStyle = color

      // starting path of the arrow from the start square to the end square
      // and drawing the stroke
      ctx.beginPath()
      ctx.moveTo(fromx, fromy)
      ctx.lineTo(tox, toy)
      ctx.lineWidth = arrowWidth
      ctx.stroke()

      // starting a new path from the head of the arrow to one of the sides of
      // the point
      ctx.beginPath()
      ctx.moveTo(tox, toy)
      ctx.lineTo(tox-headlen*Math.cos(angle-Math.PI/7),
        toy-headlen*Math.sin(angle-Math.PI/7))

      // path from the side point of the arrow, to the other side point
      ctx.lineTo(tox-headlen*Math.cos(angle+Math.PI/7),
        toy-headlen*Math.sin(angle+Math.PI/7))

      // path from the side point back to the tip of the arrow, and then
      // again to the opposite side point
      ctx.lineTo(tox, toy)
      ctx.lineTo(tox-headlen*Math.cos(angle-Math.PI/7),
        toy-headlen*Math.sin(angle-Math.PI/7))

      // draws the paths created above
      ctx.stroke()
      ctx.restore()
    }

    const ctx = canvasRef.current.getContext('2d')
    ctx.save()

    // Figure out light coordinates
    let neo_x = 0
    let neo_y = 0
    let arrow_start_x = 0
    let arrow_start_y = 0
    let arrow_end_x = 0
    let arrow_end_y = 0
    let cable_x = 0
    let cable_y = 0
    const halfWidth = ctx.canvas.width / 2
    let rotation = 0
    switch (pin) {
      case '0':
        neo_x = halfWidth - 132
        neo_y = 96
        arrow_start_x = neo_x-20
        arrow_start_y = neo_y-20
        arrow_end_x = neo_x-15
        arrow_end_y = neo_y-15
        cable_x = arrow_start_x-58.5
        cable_y = arrow_start_y-93
        rotation = 45 * Math.PI / 180
        break
      case '1':
        neo_x = halfWidth - 107
        neo_y = 83
        arrow_start_x = neo_x-20
        arrow_start_y = neo_y-20
        arrow_end_x = neo_x-15
        arrow_end_y = neo_y-15
        cable_x = arrow_start_x-58.5
        cable_y = arrow_start_y-93
        rotation = 45 * Math.PI / 180
        break
      case '2':
        neo_x = halfWidth + 113
        neo_y = 83
        arrow_start_x = neo_x+20
        arrow_start_y = neo_y-20
        arrow_end_x = neo_x+15
        arrow_end_y = neo_y-15
        cable_x = arrow_start_x+95
        cable_y = arrow_start_y-60
        rotation = 135 * Math.PI / 180
        break
      case '3':
        neo_x = halfWidth + 138
        neo_y = 96
        arrow_start_x = neo_x+20
        arrow_start_y = neo_y-20
        arrow_end_x = neo_x+15
        arrow_end_y = neo_y-15
        cable_x = arrow_start_x+95
        cable_y = arrow_start_y-60
        rotation = 135 * Math.PI / 180
        break
      default:
        // console.error('Unknown CodeX port!')
        return
    }

    // Draw light
    const gradient = ctx.createRadialGradient(neo_x, neo_y, 10, neo_x, neo_y, 30)
    gradient.addColorStop(0, glowColor)
    gradient.addColorStop(1, 'rgba(255, 255, 255, 0.0)')
    ctx.fillStyle = gradient
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)

    // Draw port number
    ctx.fillStyle = 'black'
    ctx.font = '20px roboto'
    ctx.textAlign = 'center'
    ctx.fillText(pin, neo_x, neo_y+6.5)
    ctx.restore()

    drawArrow(ctx, arrow_start_x, arrow_start_y, arrow_end_x, arrow_end_y, 4, 'red')

    if (hideWire) {
      return
    }
    let cable_image = new Image()
    cable_image.src = 'pub/pk_cable_cropped_sm.png'
    cable_image.onload = () => {
      Promise.all([
        createImageBitmap(cable_image),
      ]).then((images) => {
        ctx.translate(cable_x, cable_y)
        ctx.rotate(rotation)
        ctx.translate(-(cable_x), -(cable_y))
        ctx.drawImage(images[0], cable_x, cable_y)
      })
    }
  }, [pin])

  return (
    <LessonContentPanelContainer {...{ Icon: SettingsInputComponentIcon, color: '#66ccff', lightColor: '#0088cc'}}>
      <div style={{display:'flex', justifyContent: 'flex-start'}}>
        <span style={{fontSize: 20}}>
          {`Connect Peripheral${!!title ? ':' : ''}`}
        </span>
        {!!title ?
          <span style={{fontStyle: 'italic', fontSize: 20, paddingLeft: 5}}>
            {title}
          </span>:null
        }
      </div>
      <div style={{width:'100%'}}>
        <FiriaMarkdown  className={classes.md}>
          {content}
        </FiriaMarkdown>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          {peripheralsData[peripheral] ? <>
            <div className={classes.peripherals}>
              <span style={{ float: 'left', width: '160px' }} >
                <img src={ peripheralsData[peripheral].image } alt={ peripheralsData[peripheral].title } style={{ width: '160px'}} />
              </span>
              { hideWire || useDivider ? null :
                <span style={{ float: 'left', width: '190px', marginTop: '34px' }} >
                  <img src='pub/cable_sml.png' alt='peripheral cable' />
                </span>
              }
              { !useDivider ? null :
                <span style={{ float: 'left', width: '190px', marginTop: '34px' }} >
                  <img src='pub/divider_sml.png' alt='peripheral divider' />
                </span>
              }
            </div>
          </> : null }
          <div className={classes.codexCanvasContainer}>
            <img src='pub/codex_periph_sml.png' alt={ 'CodeX Peripheral Header' } className={classes.codexImage} />
            <canvas ref={canvasRef} className={classes.codexCanvas} width='400' height='150' />
          </div>
        </div>
      </div>
    </LessonContentPanelContainer>
  )
}

const ConceptPanel = makeLessonSectionPanel(EmojiObjectsOutlinedIcon, 'Concept', '#bb99ff')
const CautionPanel = makeLessonSectionPanel(WarningOutlinedIcon, 'Caution', '#ff4d4d')
const DebugPanel = makeLessonSectionPanel(BugReportOutlinedIcon, 'Debug', 'orange')
const TouchPanel = makeLessonSectionPanel(TouchAppIcon, 'Physical Interaction', '#ff661a', '#e67300')
const TypeInTheCodePanel = makeLessonSectionPanel(KeyboardIcon, 'Type in the Code', '#b0b0b0', '#8c8c8c')
const RemixPanel = makeLessonSectionPanel(ToysIcon, 'Try Your Skills', 'rgb(3, 169, 244)')
const CheckTheTrekPanel = makeLessonSectionPanel(DirectionsWalkIcon, 'Check the \'Trek!', 'white', 'black')

export {
  ConceptPanel,
  CautionPanel,
  RunItPanel,
  DebugPanel,
  FilePanel,
  TypeInTheCodePanel,
  PeekPanel,
  RemixPanel,
  CheckTheTrekPanel,
  TouchPanel,
  ConnectPeripheralPanel,
}