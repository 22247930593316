import {
  setGroupsState,
  setActiveGroupIdState,
  setFetchGroupsResponseState,
  setFetchFloatingLicenseSeatsResponseState,
  setTakeSeatResponseState,
  setJoinGroupResponseState,
  setLeaveGroupResponseState,
  setSubmitAssignmentResponseState,
  setAuthorizationRequiredState,
  enqueueSnack,
  setGroupsDialogOpenState,
  setFloatingLicenseSeatsState,
} from '../../contexts/GroupsContext'
import { getActiveGroup } from './group-use-cases/getActiveGroup'
import { userSessionStore } from '../user-session/user-session-store'
import { closeSubmissionDialog } from '../content-director/content-director-presenter'
import { mergeActiveGroupAndLessonContentSequence } from '../content-director/content-director-use-cases/mergeActiveGroupAndLessonContentSequence'
import { readActiveGroupIdFromStore, readGroupsFromStore, readFloatingLicenseSeatsFromStore } from './group-store'
import { RESPONSE_STATUS } from '../fetch-responses'
import { takeSeat } from './group-use-cases/takeSeat'

function presentFetchGroupsResponseStarted() {
  setFetchGroupsResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentFetchGroupsResponseFailed(reason) {
  setFetchGroupsResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
  setGroupsDialogOpenState(true)
}

function presentFetchGroupsResponseSucceeded() {
  setFetchGroupsResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
  presentGroups()
}

function presentFetchFloatingLicenseSeatsResponseStarted() {
  setFetchFloatingLicenseSeatsResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentFetchFloatingLicenseSeatsResponseFailed(reason) {
  setFetchFloatingLicenseSeatsResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentFetchFloatingLicenseSeatsResponseSucceeded() {
  setFetchFloatingLicenseSeatsResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
}

function presentFloatingLicenseSeats() {
  setFloatingLicenseSeatsState(readFloatingLicenseSeatsFromStore())
}

function presentTakeSeatResponseStarted(groupId) {
  setTakeSeatResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
    groupId,
  })
}

function presentTakeSeatResponseFailed(groupId, reason) {
  setTakeSeatResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
    groupId,
  })
}

function presentTakeSeatResponseSucceeded() {
  setTakeSeatResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
}

function presentJoinGroupResponseStarted() {
  setJoinGroupResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentJoinGroupResponseFailed(reason) {
  setJoinGroupResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentJoinGroupResponseSucceeded() {
  setJoinGroupResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
  if (userSessionStore.readUserHasLoggedInDuringSession()) {
    const group = getActiveGroup()
    enqueueSnack(`Activated class "${group.groupName}".`)
  }
  presentGroups()
}

function presentLeaveGroupResponseStarted() {
  setLeaveGroupResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentLeaveGroupResponseFailed(reason) {
  setLeaveGroupResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentLeaveGroupResponseSucceeded(leftGroupId) {
  setLeaveGroupResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
  if (userSessionStore.readUserHasLoggedInDuringSession()) {
    enqueueSnack(`Left class "${leftGroupId}".`)
  }
  presentGroups()
}

function presentSubmitAssignmentResponseStarted() {
  setSubmitAssignmentResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentSubmitAssignmentResponseFailed(reason){
  enqueueSnack('Assignment submission failed.', 'error')
  setSubmitAssignmentResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentSubmitAssignmentResponseSucceeded() {
  closeSubmissionDialog()
  enqueueSnack('Assignment submitted.')
  setSubmitAssignmentResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
  mergeActiveGroupAndLessonContentSequence()
}

function presentGroups() {
  const groups = readGroupsFromStore()
  setGroupsState(Object.values(groups))
  presentActiveGroupId()
}

function presentActiveGroupId() {
  const activeGroupId = readActiveGroupIdFromStore()
  setActiveGroupIdState(activeGroupId)
}

function presentAuthorizationRequired(groupId, postSeat) {
  const authObj = { groupId }
  if (postSeat) {
    authObj.cb = () => takeSeat(groupId)
  }
  setAuthorizationRequiredState(authObj)
}

function presentAuthorizationNotRequired() {
  setAuthorizationRequiredState(false)
}

const presentCloseGroupsDialog = () => setGroupsDialogOpenState(false)
const presentOpenGroupsDialog = () => setGroupsDialogOpenState(true)

export {
  RESPONSE_STATUS,
  presentGroups,
  presentFetchGroupsResponseFailed,
  presentFetchGroupsResponseStarted,
  presentFetchGroupsResponseSucceeded,
  presentFetchFloatingLicenseSeatsResponseStarted,
  presentFetchFloatingLicenseSeatsResponseFailed,
  presentFetchFloatingLicenseSeatsResponseSucceeded,
  presentFloatingLicenseSeats,
  presentTakeSeatResponseFailed,
  presentTakeSeatResponseStarted,
  presentTakeSeatResponseSucceeded,
  presentJoinGroupResponseFailed,
  presentJoinGroupResponseStarted,
  presentJoinGroupResponseSucceeded,
  presentLeaveGroupResponseStarted,
  presentLeaveGroupResponseFailed,
  presentLeaveGroupResponseSucceeded,
  presentSubmitAssignmentResponseStarted,
  presentSubmitAssignmentResponseFailed,
  presentSubmitAssignmentResponseSucceeded,
  presentAuthorizationRequired,
  presentAuthorizationNotRequired,
  presentActiveGroupId,
  presentCloseGroupsDialog,
  presentOpenGroupsDialog,
}