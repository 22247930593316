// Mission and Objective Panels
import React from 'react'
import {useTheme } from '@material-ui/core/styles'
import {
  Block as BlockIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@material-ui/icons'
import { Button, Divider, Typography } from '@material-ui/core'

import { useContentDirector } from './contexts/ContentDirectorContext'
import { loadNextBestContentSequenceIndex } from './content-manager/content-director/content-director-use-cases/loadNextBestContentSequenceIndex'
import { PositionedPanel } from './PositionedPanels'
import { useStyles } from './ObjectivePanel'
import hexToRGBA from './hexToRGBA'

function Field({ title, children }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
      <Typography variant='caption' color='textSecondary' style={{fontSize: 14}}>{title}</Typography>
      {typeof children === 'string' || typeof children === 'number' ?
        <Typography variant='subtitle1' style={{fontWeight: 500, fontSize: 22}}>{children}</Typography>:
        children}
    </div>
  )
}

function ProgressionGateHeader({ locked }) {
  const classes = useStyles()
  return (
    <div className={classes.panelHeader}>
      <div>
        {locked ? <BlockIcon /> : <RadioButtonUncheckedIcon />}
        <div className={classes.panelName}>
          <p className={classes.noMargin}>
            <em>{'Progression Gate'}</em>
          </p>
        </div>
      </div>
    </div>
  )
}

function UnlockedPanelFooter({ locked }) {
  const classes = useStyles()
  return (
    <div className={classes.panelFooter} style={{display: 'flex', alignItems: 'center'}} >
      <div></div>
      <Button disabled={locked} className={classes.continueButton} onClick={loadNextBestContentSequenceIndex} variant='text' >
        {'Next'}
      </Button>
    </div>
  )
}


function ProgressionGateBody({ locked, lockedUntilTs, progressionLockerSource }) {
  if (progressionLockerSource) {
    return (
      <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', flexDirection: 'column', height: '100%', gap: 20}}>
        <BlockIcon style={{fontSize: 80}}/>
        <Typography variant='subtitle2' style={{fontWeight: 500, fontSize: 22, paddingBottom: 40}}>
          {'The following lesson content has been manually locked by your instructor.'}
        </Typography>
      </div>
    )
  }

  return (
    <div style={{flex: 1, display: 'flex', alignItems:'center', justifyContent:'center', height: '100%'}}>
      {locked ?
        <Field title='Locked Until'>
          {new Date(lockedUntilTs).toLocaleString()}
        </Field>:
        <Field title='Unlocked At'>
          <Typography variant='subtitle1' color='textSecondary' style={{fontWeight: 500, fontSize: 22}}>
            {new Date(lockedUntilTs).toLocaleString()}
          </Typography>
        </Field>}
    </div>
  )
}

function ProgressionGatePositionedPanelContent() {
  const { contentSequenceItem } = useContentDirector()
  const progressionGate = contentSequenceItem?.progressionGate
  const locked = progressionGate?.progressionLockerSource || progressionGate?.lockedUntilTs > Date.now()

  return (
    <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
      <ProgressionGateHeader locked={locked}/>
      <Divider/>
      <ProgressionGateBody locked={locked} lockedUntilTs={progressionGate?.lockedUntilTs} progressionLockerSource={progressionGate?.progressionLockerSource} />
      <Divider/>
      <UnlockedPanelFooter locked={locked}/>
    </div>
  )
}


// Base panel used for both Missions and Objectives
function ProgressionGatePanel({
  missionViewElt,
  onCollapseButton,
  maximizeWindow,
  setMaximizeWindow,
  onPanelExit,
  onPanelEnter,
  open,
  panelSlidingDisabled,
}) {
  const transparency = 0.95
  const theme = useTheme()
  const backgroundColor = hexToRGBA(theme.palette.background.paper, transparency)

  const panelStyle = maximizeWindow ?
    { left: '0%', right: '0%', top: '0%', maxHeight: '100%', minHeight: '100%', display: 'flex', backgroundColor: theme.palette.background.paper } :
    { left: '15%', right: '15%', top: '10%', maxHeight: '80%', display: 'flex', backgroundColor: backgroundColor }

  return (
    <PositionedPanel
      style={{...panelStyle}}
      direction={'left'}
      anchorEl={missionViewElt}
      open={open}
      timeout={{enter: 200, exit: 200}}
      onExited={onPanelExit}
      onEntering={onPanelEnter}
      noSlide={panelSlidingDisabled}
    >
      <ProgressionGatePositionedPanelContent />
    </PositionedPanel>
  )
}

export { ProgressionGatePanel }