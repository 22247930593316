import React from 'react'
import { validatorController } from './ValidatorControl'
import { makeStyles } from '@material-ui/core/styles'
import { useMission, MissionActions, LessonContentViews } from './contexts/MissionContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Portal from '@material-ui/core/Portal'
import { GoalCheckbox, ObjGoals } from './ObjectivePanel'
import { Divider, Tooltip, Fade, IconButton } from '@material-ui/core'
import Markdown from './FiriaMarkdown'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import MenuIcon from '@material-ui/icons/Menu'
import { useContentDirector } from './contexts/ContentDirectorContext'
import { useContests } from './contexts/ContestContext'
import { readFlowProgressFromUserProgressStore } from './content-manager/user-progress/user-progress-store'

const useStyles = makeStyles(theme => ({
  toolBar: {
    display: 'flex',
    alignItems: 'center',
  },
  timerTypography: {
    '-webkit-text-stroke': '2px black',
    fontSize: 40,
    textShadow: '1.5px 1.5px 0 #000',
    fontWeight: 400,
    userSelect: 'none',
  },
  normalColor: {
    color: 'white',
  },
  lateColor: {
    color: 'red',
  },
  progressSvg: {
    '-webkit-filter': 'drop-shadow( -0.05rem 0.1rem 0 #000 )',
    strokeWidth: '2px',
    strokeLinecap: 'round',
  },
  shadowSvg: {
    stroke: 'black',
    strokeWidth: '3px',
    strokeLinecap: 'round',
  },
  timerContainer: {
    position: 'absolute',
    top:5,
    left: 5,
    pointerEvents:'none',
  },
  scoreContainer: {
    position: 'absolute',
    top:5,
    right: 5,
    pointerEvents:'none',
  },
  scoreContent: {
    backgroundColor: '#3030305C',
    borderRadius: '5px',
    border: '1px solid #ffffff40',
  },
  goalTypography: {
    alignItems:'center',
    '-webkit-filter': 'drop-shadow( 0.075rem 0.075rem 0 #000 )',
    fontWeight: 425,
    userSelect: 'none',
    // color:'white',
    // width:'100%',
    margin: 0,
  },
  validatorContainer: {
    position: 'absolute',
    bottom:3,
    left: 4,
    width:'100%',
    pointerEvents:'none',
  },
  goalContainer: {
    display:'inline-block',
    flexDirection: 'column',
    maxWidth: '50%',
    backgroundColor: '#3030305C',
    borderRadius: '5px',
  },
  objectiveHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px 7px 7px 7px',
    marginTop: '5px',
  },
  titleContainer: {
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'space-between',
    marginTop: '4px',
  },
  expandedHeader: {
    marginBottom: '5px',
  },
  minimizedHeader: {
    display: 'flex',
    alignItems:'center',
    justifyContent:'center',
    marginTop: '5px',
  },
  minimizedGoals: {
    display:'flex',
    padding: '7px 7px 0px 7px',
  },
  headerDivider: {
    width:'100%',
    backgroundColor: '#ffffff40',
  },
  lightDivider: {
    backgroundColor: '#ffffff40',
    '-webkit-filter': 'drop-shadow( -0.05rem 0.1rem 0 #000 )',
  },
  tooltip: {
    maxWidth: 'none',
    fontSize: 14,
    margin:0,
    marginBottom: 10,
    width:'100%',
    backgroundColor: '#3030305C',
  },
  completedTooltip: {
    backgroundColor: '#40804299',
  },
  arrow: {
    '-webkit-filter': 'drop-shadow( 0.075rem 0.075rem 0 #000 )',
  },
  completedTooltipArrow: {
    color: '#408042',
  },
  popper: {
    maxWidth: '60%',
  },
  goalsExpandedButton: {
    borderRadius: 4,
    width: 18,
    height: 18,
    pointerEvents:'auto',

  },
  hoverContainer: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    pointerEvents:'auto',
  },
  titleTypography: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    paddingTop: 2,
    color: 'white',
    fontWeight: 425,
  },
  checkbox: {
    padding: 0,
  },
  hudIcon: {
    color: 'white',
  },
  goalsDivider: {
    backgroundColor: '#ffffff40',
    margin: '0px 14px',
  },
}))

function CircularProgressWithLabel({ value, timeRemaining }) {
  const classes = useStyles()
  const roundedTime = Math.floor(timeRemaining)

  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        size={90}
        thickness={6}
        variant='determinate'
        value={value*-1}
        classes={{
          root:`${roundedTime <= 5 ? classes.lateColor : classes.normalColor}`,
          circle:classes.shadowSvg,
        }}
      />
      <CircularProgress
        size={90}
        thickness={6}
        variant='determinate'
        value={value*-1}
        classes={{
          root:`${roundedTime <= 5 ? classes.lateColor : classes.normalColor}`,
          circle:classes.progressSvg,
        }}
        style={{position: 'absolute'}}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography className={`${classes.timerTypography} ${roundedTime <= 5 ? classes.lateColor : classes.normalColor}`}>
          {`${roundedTime}`}
        </Typography>
      </Box>
    </Box>
  )
}

function ValidatorTimer() {
  const classes = useStyles()
  const [missionState] = useMission()
  const validatorMonitor = React.useRef(null)
  const componentMounted = React.useRef(null)
  const floatTimerValue = React.useRef(0)
  const timerStartValue = React.useRef(0)
  const timerValidator = React.useRef(null)
  const [timerVisible, setTimerVisible] = React.useState(false)
  const [timerValue, setTimerValue] = React.useState(0)

  // Monitors the timer validator and sets the timer based on it's status
  React.useEffect(() => {
    const timerPoller = () => {
      // If the timer validator is still active
      if (timerValidator.current && !timerValidator.current.paused){
        validatorMonitor.current = setTimeout(() => {
          const validatorTimerSecondsRemaining = timerValidator.current.getTimeRemaining()/1000
          if (validatorTimerSecondsRemaining > 0){
            if (validatorTimerSecondsRemaining+1 < floatTimerValue.current){
              floatTimerValue.current = Math.round(validatorTimerSecondsRemaining)
            }
          } else {
            floatTimerValue.current = 0
          }
          setTimerValue(floatTimerValue.current)
          timerPoller()
        }, 100)
      }
    }

    const timerMonitor = (timerRunning) => {
      clearTimeout(validatorMonitor.current)
      // Ref required here to reference the timerValue in a timeout
      if (timerRunning){
        timerStartValue.current = timerValidator.current ? timerValidator.current.seconds : 0
        floatTimerValue.current = timerStartValue.current
        setTimerValue(timerStartValue.current)
        timerPoller()
      } else {
        clearTimeout(validatorMonitor.current)
      }
    }

    const setTimer = (timer) => {
      timerValidator.current = timer
      if (timer){
        setTimerVisible(true)
        timerStartValue.current = timer.seconds
        floatTimerValue.current = timerStartValue.current
        setTimerValue(timerStartValue.current)
      } else {
        clearTimeout(validatorMonitor.current)
        setTimerVisible(false)
      }
    }

    validatorController.setTimerCallbacks(timerMonitor, setTimer)
    componentMounted.current = true
    return () => {
      clearTimeout(validatorMonitor.current)
      componentMounted.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.timerContainer}>
      {timerVisible && !missionState.ui.isExploreMode ?
        <CircularProgressWithLabel value={timerValue/timerStartValue.current*100} timeRemaining={timerValue}/>:
        null
      }
    </div>
  )
}

function ValidatorGoals() {
  const [missionState, missionDispatch] = useMission()
  const classes = useStyles()
  // If you've got the objective window closed, and you complete and objective, you should be able to see
  // the goals from the completion menu
  const [showDuringCompletion, setShowDuringCompletion] = React.useState(false)
  const [hoveredGoal, setHoveredGoal] = React.useState(null)
  const [offset, setOffset] = React.useState('0, 0')
  const lastFocusedContent =  React.useRef(LessonContentViews.NOTHING)
  const goalsRef = React.useRef(null)

  React.useEffect(() => {
    if (
      lastFocusedContent.current === LessonContentViews.NOTHING &&
      missionState.ui.focusedContent === LessonContentViews.COMPLETION_MESSAGE
    ){
      setShowDuringCompletion(true)
    } else {
      setShowDuringCompletion(false)
    }
    lastFocusedContent.current = missionState.ui.focusedContent
  }, [missionState.ui.focusedContent])

  React.useEffect(() => {
    if (hoveredGoal !== null && goalsRef.current){
      setTimeout(()=>{
        const hoverRect = document.getElementById('goals_hud_hover_'+hoveredGoal)?.getBoundingClientRect()
        const goalsRect = goalsRef.current.getBoundingClientRect()
        const popperRect = document.getElementById('goals_hud_tooltip_'+hoveredGoal)?.getBoundingClientRect()

        if (popperRect && hoverRect){
          const arrowCenter = ((hoverRect.width/2) + 7) + ((hoverRect.width+10) * hoveredGoal)
          const pxOverflowLeft = (popperRect.width/2) - arrowCenter
          const pxOverflowRight = (goalsRect.width - arrowCenter) - (popperRect.width/2)

          var shiftBy = 0
          // Measure whether the popper is longer than the goals container on the left or right,
          // if it overflows the left or if it's not overflowing, but super close to the left,
          // just shift it until it's flush with the container. If neither of these occur,
          // try it with the right side. Otherwise, just leave it sitting in the middle
          if (pxOverflowLeft > 0 || (pxOverflowLeft < 0 && ((popperRect.width/2) - Math.abs(pxOverflowLeft)) > 15)){
            shiftBy =  Math.ceil(pxOverflowLeft)
          } else if (pxOverflowRight > 0 || (pxOverflowRight < 0 && ((popperRect.width/2) - Math.abs(pxOverflowRight)) > 15)) {
            shiftBy =  Math.ceil(pxOverflowRight)
          }

          setOffset(String(shiftBy)+', 0')
        }
      }, 1)
    }
  }, [hoveredGoal])

  const objectiveIndex = (index) => {
    var formattedIndex = index
    for (let i=0;i<index;i++){
      if (missionState.activeMission.flow[i].type === 'quiz'){
        formattedIndex -= 1
      }
    }
    return (formattedIndex + 1).toString()
  }

  const ObjectiveHeader = () => {
    return (
      <div className={`${classes.goalTypography} ${classes.objectiveHeader}`}>
        <Divider light className={classes.headerDivider}/>
        <div style={{gap:3}} className={`${missionState.ui.goalHUDOpen ? classes.expandedTitle : classes.minimizedTitle} ${classes.titleContainer}`}>
          <IconButton
            onClick={async ()=>(await missionDispatch({ type: MissionActions.SET_GOAL_HUD, goalHUDOpen: !missionState.ui.goalHUDOpen }))}
            className={classes.goalsExpandedButton}
          >
            {missionState.ui.goalHUDOpen ?
              <ExpandMoreIcon className={classes.hudIcon}/> :
              <MenuIcon className={classes.hudIcon}/>
            }
          </IconButton>
          <div className={classes.titleTypography} style={{gap:4}}>
            <em>{`Objective ${objectiveIndex(missionState.ui.curFlowIndex)}:`}</em>
            {`${missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.obj?.title}`}
            <div></div>
          </div>
        </div>
      </div>
    )
  }

  if (!missionState.requiresSim) {
    return null
  }

  const goals = missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.obj?.goals
  return (
    <>
      {
        (missionState.ui.focusedContent !== LessonContentViews.COMPLETION_MESSAGE || showDuringCompletion) &&
        missionState.ui.focusedContent !== LessonContentViews.FLOW &&
        missionState.ui.focusedContent !== LessonContentViews.PROGRESSION_GATE &&
        !missionState.ui.isExploreMode &&
        missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.obj?.goals &&
        missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.type !== 'Quiz' ?
          <div className={classes.validatorContainer}>
            <div className={classes.goalContainer} ref={goalsRef}>
              {missionState.ui.goalHUDOpen ?
                <ObjGoals
                  goals={goals}
                  outerContainerClass={classes.goalTypography}
                  dividerClass={classes.goalsDivider}
                  hud={true}
                />:
                <>
                  <div className={classes.minimizedGoals} style={{gap:10}}>
                    {goals.map((goal, idx) => {
                      return (
                        <Tooltip
                          PopperProps={{
                            popperOptions:{
                              modifiers: {
                                offset: {
                                  offset: offset,
                                },
                              },
                            },
                            disablePortal: true,
                          }}
                          open={hoveredGoal === idx}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          classes={{
                            arrow: goal.validated ? classes.completedTooltipArrow : null,
                            tooltip: `${classes.tooltip} ${goal.validated ? classes.completedTooltip : null}`,
                            popper: classes.popper,
                          }}
                          TransitionComponent={Fade}
                          title={
                            <div className={classes.goalTypography}>
                              <Markdown>
                                {'<div style="color: white; marginTop: 2px; padding-right:10px; width:100%; margin-top: 0px;">\n\n'+goal.md+'\n\n</div>'}
                              </Markdown>
                            </div>
                          }
                          id={`goals_hud_tooltip_${idx}`}
                          arrow
                          placement='top'
                          key={idx}
                        >
                          <div
                            onMouseOver={()=>setHoveredGoal(idx)}
                            onMouseOut={()=>setHoveredGoal(null)}
                            key={idx}
                            id={`goals_hud_hover_${idx}`}
                            className={`${classes.goalTypography} ${classes.hoverContainer}`}
                          >
                            <GoalCheckbox
                              validated={goal.validated}
                              className={!missionState.ui.goalHUDOpen ? classes.checkbox : null}
                            />
                          </div>
                        </Tooltip>
                      )
                    })}
                  </div>
                </>}
              <ObjectiveHeader />
            </div>
          </div>:
          null
      }
    </>
  )
}

function ScoreTypography(props) {
  const classes = useStyles()
  return (
    <Typography {...props} className={classes.goalTypography}>
      {props.children}
    </Typography>
  )
}

function SmallScorePanel({ icon, children: val, hoverText }) {
  const [tooltipOpen, setTooltipOpen] = React.useState(false)

  const onMouseEnter = () => {
    setTooltipOpen(true)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
  }

  return (
    <Tooltip
      // leaveDelay={500}
      arrow
      placement={'left'}
      open={tooltipOpen}
      title={hoverText}
      // classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
    >
      <div
        style={{
          pointerEvents:'auto',
          backgroundColor: tooltipOpen ? 'rgba(255,255,255,0.2)' : null,
          width: '100%',
          height: '100%',
          padding: '0px 5px',
        }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}>
          <ScoreTypography>
            {icon}
          </ScoreTypography>
          <div style={{width: 10}}></div>
          <ScoreTypography>
            {val}
          </ScoreTypography>
        </div>
      </div>

    </Tooltip>
  )
}

function ObjectiveScore() {
  const [missionState] = useMission()
  const classes = useStyles()
  const { contentSequenceItemScored, contentSequenceItem } = useContentDirector()
  const { objectiveHighScore } = useContests()
  // console.log(missionState)

  if (missionState.ui.isExploreMode) {
    return null
  }

  if (!contentSequenceItemScored) {
    return null
  }

  const bestScore = readFlowProgressFromUserProgressStore(contentSequenceItem.flow.id)?.bestScore ?? 0
  const score = missionState.score
  // console.log('SCORED: ', scored, bestScore, score)

  return (
    <div className={classes.scoreContainer}>
      <div className={classes.scoreContent}>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 84, width: 230}}>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0px 30px', width: '100%'}}>
            <ScoreTypography style={{paddingTop: 5}}>
              {'Score'}
            </ScoreTypography>
            <ScoreTypography  style={{fontSize: 40}}>
              {score}
            </ScoreTypography>
          </div>
          <Divider light className={classes.lightDivider} style={{height: '100%'}} orientation='vertical'/>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%'}}>
            <SmallScorePanel
              icon={'🎉'}
              hoverText={'Your personal best!'}
            >
              {bestScore}
            </SmallScorePanel>
            <Divider light className={classes.lightDivider} style={{width: '100%'}} />
            <SmallScorePanel
              icon={'👑'}
              hoverText={'The best score in your contest band!'}
            >
              {objectiveHighScore ?? 'N/A'}
            </SmallScorePanel>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function ValidatorUI({ anchorEl }) {
  return (
    <Portal container={anchorEl}>
      <ObjectiveScore />
      <ValidatorTimer />
      <ValidatorGoals />
    </Portal>
  )
}


