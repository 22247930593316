import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CodePanelActions, useCodePanel } from './contexts/CodePanelContext'
import { useUserConfig } from './contexts/UserConfigContext'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import dark_vs from './dark_vs.json'
import CloseIcon from '@material-ui/icons/Close'
import { useFileManagement } from './contexts/FileManagementContext'
import { useLogin } from './contexts/LoginContext'
import DescriptionIcon from '@material-ui/icons/Description'
import Divider from '@material-ui/core/Divider'
import ShareIcon from '@material-ui/icons/Share'
import { isFileShared } from './content-manager/code-files/code-file-use-cases/isFileShared'
import { getSharedFileName } from './content-manager/code-files/code-file-use-cases/getSharedFileName'

const useStyles = makeStyles((theme) => {
  return ({
    innerTab: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      maxWidth:200,
      minWidth:120,
      // justifyContent: 'space-between',
      width:'100%',
    },
    outerTab: {
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      height:34,
    },
    fileNameText:{
      fontSize: 14,
      width:'100%',
      userSelect: 'none',
    },
    iconButton: {
      height:30,
      width:30,
    },
    tabsHeader: {
      height:34,
      display:'flex',
      flexDirection: 'row',
      justifyContent:'space-between',
      alignItems:'center',
    },
    closeButton: {
      borderRadius: 5,
      height:20,
      width:20,
      marginRight:3,
    },
    iconTypographyContainer: {
      display:'flex',
      flexDirection:'row',
      // alignItems:'center',
      justifyContent:'space-between',
      maxWidth:142,
      width:'100%',
    },
    bottomDivider: {
      backgroundColor: '#34B757D9',
      height: 1,
    },
    fileIcon: {
      width:27,
      fontSize: 15,
      display:'flex',
      alignItems:'center',
    },
    tabBarContainer: {
      display:'flex',
      height:35,
      overflowX: 'scroll',
      overflowY: 'hidden',
      /* width */
      '&::-webkit-scrollbar': {
        height: 3,
        display:'none',
      },
      '&:hover::-webkit-scrollbar': {
        height: 3,
        display:'flex',
        backgroundColor: '#00000000',
      },
      '&::-webkit-scrollbar-track': {
        display:'none !important',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 0,
        backgroundColor: '#ffffff4D',
      },
      '&::-webkit-scrollbar-corner': {
        display:'none !important',
      },
      '&::-webkit-scrollbar-track-piece': {
        display:'none !important',
      },
    },
  })
})

function getTabName(fileId, fileTreeName) {
  if (fileTreeName) {
    return fileTreeName
  }

  const sharedFileName = getSharedFileName(fileId)
  if (sharedFileName) {
    return sharedFileName
  }

  return 'Untitled Program'
}

export default function CodingTabs({ setDirectoryOpen }) {
  const [codePanelState, codePanelDispatch] = useCodePanel()
  const [userConfigState] = useUserConfig()
  const [loginState] = useLogin()
  const [fileManagementState] = useFileManagement()
  const classes = useStyles()

  const renderTabs = React.useCallback(() => [...(codePanelState?.tabs?.opened ?? new Set())].map(fileUid => (
    <React.Fragment key={fileUid}>
      <div
        key={fileUid}
        id={'tab_'+fileUid}
        onClick={async () => {
          if (fileUid !== codePanelState.tabs.focused) {
            await codePanelDispatch({ type: CodePanelActions.OPEN_TAB, fileUid })
          }
        }}
        className={classes.outerTab}
        style={{
          cursor: !!loginState.user ? 'pointer' : 'default',
          backgroundColor: fileUid === codePanelState.tabs.focused ?
            userConfigState.theme === 'dark' ?
              dark_vs.colors['editor.background'] : '#ffffff' : userConfigState.theme === 'dark' ?
              '#2D2D2D' : '#f2f2f2',
        }}
      >
        <div style={{height:1}}></div>
        <div
          className={classes.innerTab}
          style={{gap:2}}
        >
          {isFileShared(fileUid) ?
            <ShareIcon className={classes.fileIcon}/>:
            <DescriptionIcon className={classes.fileIcon}/>
          }
          <div className={classes.iconTypographyContainer}>
            <Typography
              className={classes.fileNameText}
              style={{
                color: fileUid === codePanelState.tabs.focused ?
                  (userConfigState.theme === 'dark' ? '#ffffff' : null) :
                  (userConfigState.theme === 'dark' ? '#969696' : '#454545'),
              }}
              noWrap
            >
              {getTabName(fileUid, fileManagementState.tree?.get(fileUid)?.name)}
            </Typography>
            <div></div>
          </div>
          <div style={{width:27}}>
            {!!loginState?.user &&
          <IconButton
            className={classes.closeButton}
            onClick={(e) => {
              e.stopPropagation()
              codePanelDispatch({ type: CodePanelActions.CLOSE_TABS, fileUids: [fileUid] })
            }}
          >
            <CloseIcon style={{width: 15}} />
          </IconButton>}
          </div>
        </div>
        <div style={{height:1}}>
          {fileUid === codePanelState.tabs.focused && userConfigState.theme === 'dark' ?
            <Divider className={classes.bottomDivider}/>:
            null
          }
        </div>
      </div>
      <Divider orientation={'vertical'} style={{backgroundColor:userConfigState.theme === 'dark' ? dark_vs.colors['menu.background']: '#f2f2f2'}}/>
    </React.Fragment>
  )
  ), [loginState, classes, codePanelDispatch, codePanelState.tabs.focused, codePanelState.tabs.opened, fileManagementState.tree, userConfigState.theme])

  React.useEffect(() => {
    // Using element scroll into view would be nice here, but the file menu was getting hidden
    // from vertical scrolling. Doing this manually gives us more control over how the scroll
    // behaves. Uf the tab is totally out of view, it gets centered, otherwise it gets shifted into view.

    const tabElement = document.getElementById('tab_'+codePanelState.tabs.focused)
    const containerElement = document.getElementById('tabContainer')
    if (tabElement && containerElement){
      const containerSize = containerElement.getBoundingClientRect()
      const tabSize = tabElement.getBoundingClientRect()

      if (tabSize.left >= containerSize.left && tabSize.right <= containerSize.right){
        // The tab is already entirely in view, do nothing
      } else if (containerSize.left > tabSize.left && containerSize.left < tabSize.right) {
        // If the tab is partially in view on the left side
        containerElement.scrollLeft += (tabSize.left - containerSize.left)
      } else if (containerSize.right > tabSize.left && containerSize.right < tabSize.right) {
        // If the tab is partially in view on the right side
        containerElement.scrollLeft += (tabSize.right - containerSize.right)
      } else {
        // The tab is entirely out of view
        containerElement.scrollLeft += (tabSize.left - containerSize.left - (containerSize.width/2) + (tabSize.width/2))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codePanelState.tabs.focused])

  //
  const onWheel = React.useCallback((e) => {
    const containerElement = document.getElementById('tabContainer')
    const containerScrollPosition = containerElement.scrollLeft
    containerElement.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY,
      behaviour: 'smooth',
    })
  }, [])

  return (
    <>
      <div id='tabContainer' onWheel={onWheel} className={classes.tabBarContainer} style={{backgroundColor: userConfigState.theme === 'dark' ? dark_vs.colors['menu.background'] : '#f5f5f5'}}>
        {renderTabs()}
      </div>
      <div style={{height:1, backgroundColor: userConfigState.theme === 'dark' ? dark_vs.colors['editor.background'] : '#FFFFFE'}}></div>
    </>
  )
}
