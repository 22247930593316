import { getAuthorizationURLFromCloud } from '../group-gateways'

async function authorizeGroupLMS(lmsId) {
  const url = await getAuthorizationURLFromCloud(lmsId)
  if (!url) {
    return
  }
  window.open(url, '_blank')
  return url
}

export { authorizeGroupLMS }