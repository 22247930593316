import { postSeatInCloud } from '../group-gateways'
import {
  presentTakeSeatResponseFailed,
  presentTakeSeatResponseStarted,
  presentTakeSeatResponseSucceeded,
  presentCloseGroupsDialog,
  presentActiveGroupId,
} from '../group-presenter'
import { setActiveGroupIdInStore, readGroupFromStore } from '../group-store'
import { setAssignments } from './fetchAssignments'
import { changeActiveMissionPack } from '../../content-director/content-director-use-cases/changeActiveMissionPack'
import { formatAssignmentsFromCloud } from '../group-gateways'
import { userIsLoggedIn } from '../../user-session/user-session-use-cases/userIsLoggedIn'

async function takeSeat(groupId, leaveDialogOpen = false){
  presentTakeSeatResponseStarted(groupId)
  if (userIsLoggedIn()) {
    const { authorize, error, assignments } = await postSeatInCloud(groupId)
    if (authorize) {
      presentTakeSeatResponseFailed(groupId)
      return authorize
    }
    if (!!error) {
      presentTakeSeatResponseFailed(groupId, error)
      return error
    }
    setAssignments(groupId, formatAssignmentsFromCloud(assignments))
  }

  setActiveGroupIdInStore(groupId)

  const group = readGroupFromStore(groupId)
  await changeActiveMissionPack(group.missionPackId)
  presentTakeSeatResponseSucceeded()

  if (!leaveDialogOpen) {
    presentCloseGroupsDialog()
  }
  presentActiveGroupId()
}

export {
  takeSeat,
}