const userSessionKey = 'user-session'
const initializedUserSessionUiKey = 'initialized-user-session-ui'
const userSessionUIKey = 'user-session-ui'
const store = {}

class UserSessionStore {
  constructor() {
    this.setDefaults()
  }

  setDefaults() {
    store[userSessionKey] = {}
    store[userSessionUIKey] = {}
    store.userHasLoggedInDuringSession = false
    store.userLastSessionErrorMessage = ''
    store.isOnline = navigator.onLine
  }

  readIsOnline() {
    return store.isOnline
  }

  setIsOnline(isOnline) {
    store.isOnline = isOnline
  }

  setUserSession(userSession) {
    store[userSessionKey] = userSession
  }

  readUserSession(){
    return store[userSessionKey]
  }

  setUserSessionUI(userSessionUI){
    store[userSessionUIKey] = userSessionUI
  }

  readUserSessionUI(){
    return store[userSessionUIKey]
  }

  setInitializedUserSessionUI(userSessionUI){
    store[initializedUserSessionUiKey] = userSessionUI
  }

  readInitializedUserSession(){
    return store[initializedUserSessionUiKey]
  }

  setUserHasLoggedInDuringSession() {
    store.userHasLoggedInDuringSession = true
  }

  readUserHasLoggedInDuringSession() {
    return store?.userHasLoggedInDuringSession
  }

  setUserSessionLastErrorMessage(errObj) {
    store.userLastSessionErrorMessage = JSON.stringify(errObj)
  }

  readUserSessionLastErrorMessage(){
    return store.userLastSessionErrorMessage
  }

  clearUserSessionLastErrorMessage() {
    store.userLastSessionErrorMessage = ''
  }
}

const userSessionStore = new UserSessionStore()
export {
  userSessionStore,
}