import { hintCategory, genHelpObjs } from '../../../Toolbox/GeneralHelpHints'

const getToolsByCategory = () => {
  const toolsByCategory = {}
  Object.values(hintCategory).forEach((category) => {
    toolsByCategory[category] = []
  })

  genHelpObjs.forEach((toolboxItem) => {
    toolsByCategory[toolboxItem.category].push(toolboxItem)
  })

  return toolsByCategory
}
const toolsByCategory = getToolsByCategory()

const getToolIdsByMatches = () => {
  const toolIdsByMatches = {}
  genHelpObjs.forEach((toolboxItem) => {
    toolboxItem.matches.forEach((match) => {
      toolIdsByMatches[match] = toolboxItem.name
    })
  })

  return toolIdsByMatches
}

const toolboxItemsFromString = ({ string }) => {
  return [...string.matchAll(/==[^=\r\n]*==/g)].map(match => match[0].slice(2, -2))
}

const toolboxItemsFromFlowItem = ({ flowItem }) => {
  const toolboxItems = []
  const obj = flowItem.obj

  const pushStrings = ({ string }) => {
    toolboxItems.push(...toolboxItemsFromString({ string }))
  }

  if (obj?.instructions) {
    pushStrings({ string: obj?.instructions })
  }

  if (obj?.complete) {
    pushStrings({ string: obj?.complete })
  }

  if (obj?.goals && obj?.goals.length > 0) {
    for (const i in obj?.goals){
      const goal = obj?.goals[i]
      pushStrings({ string: goal.md })
    }
  }

  if (obj?.codesteps && obj?.codesteps.length > 0) {
    for (const i in obj?.codesteps){
      const step = obj?.codesteps[i]
      pushStrings({ string: step.md })
    }
  }

  if (obj?.questions) {
    for (const i in obj?.questions) {
      const q = obj?.questions[i].obj
      pushStrings({ string: q.q })
      for (const n in q.answers) {
        const answer = q.answers[n]
        pushStrings({ string: answer.a })
      }
    }
  }

  return [...new Set(toolboxItems)]
}

const getContentAppearenceByTool = ({ packs, toolIdsByMatches }) => {
  const contentAppearenceByTool = {}
  const updateObj = ({ pack, mission, flowItem, toolItem }) => {
    const toolItemId = toolIdsByMatches[toolItem]
    if (!(toolItemId in contentAppearenceByTool)){
      contentAppearenceByTool[toolItemId] = {}
    }

    if (!(pack in contentAppearenceByTool[toolItemId])){
      contentAppearenceByTool[toolItemId][pack] = {}
    }

    if (!(mission in contentAppearenceByTool[toolItemId][pack])){
      contentAppearenceByTool[toolItemId][pack][mission] = []
    }

    contentAppearenceByTool[toolItemId][pack][mission].push(flowItem)
    contentAppearenceByTool[toolItemId][pack][mission] = [...new Set(contentAppearenceByTool[toolItemId][pack][mission])]
  }

  packs.forEach((pack, packIdx) => {
    pack.missions.forEach((mission, missionIdx) => {
      var objectiveIdx = 0
      var quizIdx = 0
      mission.flow.forEach((flowItem, flowIdx) => {
        var flowTitle
        if (flowItem.type === 'quiz'){
          quizIdx += 1
          flowTitle = `Quiz ${quizIdx} - ${flowItem.obj.title}`
        } else {
          objectiveIdx += 1
          flowTitle = `Objective ${objectiveIdx} - ${flowItem.obj.title}`
        }

        toolboxItemsFromFlowItem({ flowItem })
          .map(toolItem => updateObj({
            pack: pack.title,
            mission: `Mission ${missionIdx+1} - ${mission.title}`,
            flowItem: flowTitle,
            toolItem,
          }))
      })
    })
  })

  return contentAppearenceByTool
}

const ripToolboxContent = ({ addTask, addSpacer, packs }) => {
  addSpacer(4)
  addTask('# Toolbox Content')

  const toolIdsByMatches = getToolIdsByMatches()
  getContentAppearenceByTool({ packs, toolIdsByMatches })
  // const contentAppearenceByToolName = getContentAppearenceByTool({ packs, toolIdsByMatches })

  Object.values(hintCategory).forEach((category) => {
    const toolboxItems = toolsByCategory[category]
    addTask(`# <u>***${category}***</u>`)
    toolboxItems
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .forEach((item) => {
        addTask(`# ***${item.name}***`)
        addTask(`## ***${item.desc}***`)
        addSpacer(4)
        const appearances = {} // contentAppearenceByToolName[item.name] ?? {}
        addTask({ hint: item.hint, appearances }, 'toolboxItem')
      })
  })
}

export { getToolIdsByMatches, toolboxItemsFromFlowItem, toolsByCategory }
export default ripToolboxContent