import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogTitle, DialogContent, Typography } from '@material-ui/core'
import { useMissionEditor } from '../contexts/MissionEditorContext'
import { readMissionInfoByTestFlowIdFromStore } from '../content-manager/lesson-editor/lesson-editor-repos'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: 700,
  },
  footer: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 5,
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid rgba(0,0,0,0.2)',
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'none',
    borderRadius: 0,
    height: 50,
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    padding: 3,
    paddingLeft: 10,
    paddingRight: 10,
    // width: 550,
  },
  tabContainer: {
    border: '1px solid rgba(0,0,0,0.3)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 5,
    height: 500,
    overflow: 'auto',
  },
}))

const TestTabField = ({ label, value, subValue }) => {
  return (
    <div>
      <Typography variant='subtitle2'>
        {value}
      </Typography>
      <Typography variant='body2'>
        {subValue}
      </Typography>
    </div>
  )
}

const TestTab = ({ id, idx }) => {
  const classes = useStyles()
  const { flowTitle, missionId } = readMissionInfoByTestFlowIdFromStore(id)

  return (
    <div className={classes.tab}>
      <TestTabField value={missionId} subValue={''}/>
      <TestTabField value={id} subValue={flowTitle}/>
      <TestTabField value={idx} subValue={''}/>
    </div>
  )
}

const ValidatorTestsDialog = ({ open, onClose }) => {
  const classes = useStyles()
  const [{ validatorTestState }] = useMissionEditor()

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth='xl'
      >
        <div className={classes.dialogContainer}>
          <DialogTitle style={{paddingBottom: 0}}>
            {'Code Parse Validator Tests'}
          </DialogTitle>
          <DialogContent>
            <div style={{paddingBottom: 8, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <Typography>
                  {`Total Tests: ${validatorTestState.totalTests}`}
                </Typography>
                <Typography>
                  {`Failed Tests: ${validatorTestState.tests.length}`}
                </Typography>
              </div>
              <div>
                <Typography variant='body2'>
                  {'Each test represents a CODE_PARSE'}
                </Typography>
                <Typography variant='body2'>
                  {'goal\'s response to being run with the'}
                </Typography>
                <Typography variant='body2'>
                  {'objective\'s solution code.'}
                </Typography>
              </div>
            </div>
            <div className={classes.tab} style={{border: 'none'}}>
              <Typography>
                {'Mission'}
              </Typography>
              <Typography>
                {'Flow'}
              </Typography>
              <Typography>
                {'Goal Idx'}
              </Typography>
            </div>
            <div className={classes.tabContainer}>
              {validatorTestState.tests.map(test => <TestTab key={test.id} {...test}/>)}
            </div>
          </DialogContent>
          <div className={classes.footer}>
            <div></div>
            <Button
              onClick={onClose}
              variant='outlined'
              style={{margin: 3}}
            >
              {'Close'}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default ValidatorTestsDialog
