// Players - Manage the "actors" in our simulation.
import { CodeBotCB2Model } from './entities/CodeBotModels'
import { CodeXModel } from './entities/CodeXModels'
import { CodeAIRModel } from './entities/CodeAIRModels'
import UsbDebugBackend from './UsbDebugBackend'
import { WebTerminal } from './WebTerminal'
import { CodeRunner } from './CodeRunner'
import SimDebugBackend from './SimDebugBackend'
import CodeBotModelController from './entities/CodeBotModelController'
import CodeXModelController from './entities/CodeXModelController'
import CodeAIRModelController from './entities/CodeAIRModelController'
import { validatorController } from './ValidatorControl'
import { editorController } from './EditorControl'

export const Targets = Object.freeze({
  UNCONNECTED: 'UNCONNECTED',
  SIM_CB2: 'SIM_CB2',
  USB_CODEX: 'USB_CODEX',
  SIM_CODEX: 'SIM_CODEX',
  USB_CODEAIR: 'USB_CODEAIR',
  SIM_CODEAIR: 'SIM_CODEAIR',
  USB_CODEBOT: 'USB_CODEBOT',
  USB_CB2: 'USB_CB2',
  USB_CB3: 'USB_CB3',
})

export const TargetNames = {
  UNCONNECTED: 'No Device',
  SIM_CB2: 'Sim CodeBot',
  USB_CODEX: 'USB CodeX',
  SIM_CODEX: 'Sim CodeX',
  USB_CODEAIR: 'USB CodeAIR',
  SIM_CODEAIR: 'SIM CodeAIR',
  USB_CODEBOT: 'USB CodeBot',  // Any CodeBot
  USB_CB2: 'USB CodeBot (CB2)',
  USB_CB3: 'USB CodeBot (CB3)',
}


// Singleton (for now) actors controlled by user code in our simulation.

// Creation
const player_num = 1
export const codeBotCB2ModelOne = new CodeBotCB2Model(player_num)
export const codeXModelOne = new CodeXModel(player_num)
export const codeAIRModelOne = new CodeAIRModel(player_num)
export const codeRunner = new CodeRunner()
export const simDebugBackend = new SimDebugBackend(codeRunner)
export const usbDebugBackend = new UsbDebugBackend()
export const webTerminal = new WebTerminal()
export const codeBotCtrl = new CodeBotModelController(codeBotCB2ModelOne)
export const codeXCtrl = new CodeXModelController(codeXModelOne)
export const codeAIRCtrl = new CodeAIRModelController(codeAIRModelOne)

codeRunner.setModelController(codeBotCtrl)
editorController.breakpointObservable.add(simDebugBackend.breakpointChanged)
validatorController.setModelController(codeBotCtrl)
simDebugBackend.connectWebTerminal(webTerminal)
simDebugBackend.addCallbacks(validatorController.handleRunStateChange, undefined, undefined, validatorController.handleCodeError)
simDebugBackend.addCallbacks(undefined, undefined, undefined, editorController.addError)
validatorController.setBackend(simDebugBackend)
