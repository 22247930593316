import PeripheralBase from './PeripheralBase'
import * as BABYLON from '@babylonjs/core'
import { loadGLBWithPhysics, disposePromise } from '../../utils/BabylonUtils'
import SevenSegmentPcb from '../../assets/SevenSeg.glb'

const BODY_HEIGHT = 0.005
const LED_BASE_HEIGHT = 0.07
const BB_HEIGHT = 0.0001
const START_LEFT = -0.1600
const DIGIT_SPACING = 0.1016

const ledVals = {
  0x00: 0b00111111, // 0
  0x01: 0b00000110, // 1
  0x02: 0b01011011, // 2
  0x03: 0b01001111, // 3
  0x04: 0b01100110, // 4
  0x05: 0b01101101, // 5
  0x06: 0b01111101, // 6
  0x07: 0b00000111, // 7
  0x08: 0b01111111, // 8
  0x09: 0b01100111, // 9
  0x0A: 0b01110111, // A
  0x0B: 0b01111100, // b
  0x0C: 0b00111001, // C
  0x0D: 0b01011110, // d
  0x0E: 0b01111001, // E
  0x0F: 0b01110001, // F
  0x22: 0b00100010, // "
  0x27: 0b00000010, // '
  0x28: 0b00111001, // (
  0x29: 0b00001111, // )
  0x2C: 0b00010000, // ,
  0x2D: 0b01000000, // -
  0x30: 0b00111111, // 0
  0x31: 0b00000110, // 1
  0x32: 0b01011011, // 2
  0x33: 0b01001111, // 3
  0x34: 0b01100110, // 4
  0x35: 0b01101101, // 5
  0x36: 0b01111101, // 6
  0x37: 0b00000111, // 7
  0x38: 0b01111111, // 8
  0x39: 0b01100111, // 9
  0x41: 0b01110111, // A
  0x42: 0b01111100, // b
  0x43: 0b00111001, // C
  0x44: 0b01011110, // d
  0x45: 0b01111001, // E
  0x46: 0b01110001, // F
  0x47: 0b00111101, // G
  0x48: 0b01110110, // H
  0x49: 0b00000110, // I
  0x4A: 0b00001110, // J
  0x4C: 0b00111000, // L
  0x4E: 0b01010100, // n
  0x4F: 0b00111111, // O
  0x50: 0b01110011, // P
  0x51: 0b01100111, // q
  0x52: 0b01010000, // r
  0x53: 0b01101101, // S
  0x54: 0b01111000, // t
  0x55: 0b00111110, // U
  0x59: 0b01101110, // y
  0x5B: 0b00111001, // [
  0x5D: 0b00001111, // ]
  0x5F: 0b00001000, // _
  0x60: 0b00100000, // `
  0x61: 0b01110111, // A
  0x62: 0b01111100, // b
  0x63: 0b01011000, // c
  0x64: 0b01011110, // d
  0x65: 0b01111011, // e
  0x66: 0b01110001, // f
  0x67: 0b00111101, // G
  0x68: 0b01110100, // h
  0x69: 0b00000100, // i
  0x6A: 0b00001110, // J
  0x6C: 0b00000110, // l
  0x6E: 0b01010100, // n
  0x6F: 0b01011100, // o
  0x70: 0b01110011, // p
  0x71: 0b01100111, // q
  0x72: 0b01010000, // r
  0x73: 0b01101101, // S
  0x74: 0b01111000, // t
  0x75: 0b00011100, // u
  0x78: 0b00000000, // blank
}

const baudrates = {
  0x00: 2400,
  0x01: 4800,
  0x02: 9600,
  0x03: 14400,
  0x04: 19200,
  0x05: 38400,
  0x06: 57600,
  0x07: 76800,
  0x08: 115200,
  0x09: 250000,
  0x0A: 500000,
  0x0B: 1000000,
}

const twoByteCmds = [0x77, 0x79, 0x7A, 0x7B, 0x7C, 0x7D, 0x7E, 0x7F, 0x80]

const DECIMAL_START = -0.1160

const decimalLocs = [
  {x: DECIMAL_START, y: -0.045},
  {x: DECIMAL_START + DIGIT_SPACING, y: -0.045},
  {x: DECIMAL_START + DIGIT_SPACING * 2, y: -0.045},
  {x: DECIMAL_START + DIGIT_SPACING * 3, y: -0.045},
  {x: -0.11 + DIGIT_SPACING, y: 0.021},
  {x: -0.11 + DIGIT_SPACING * 2, y: 0.045},
  {x: -0.11 + DIGIT_SPACING, y: -0.021},
]

const DIGIT_OFFSETS = [START_LEFT, START_LEFT + DIGIT_SPACING, START_LEFT + DIGIT_SPACING * 2, START_LEFT + DIGIT_SPACING * 3]

const LED_COLOR = new BABYLON.Color3(0.26, 0, 0)  // Emissive color level for 100% brightness.

class SevenSegment extends PeripheralBase {
  constructor(rxPin, txPin) {
    super()
    this.digits = []
    this.decimals = []
    this.cursor = 0
    this.factoryReset()
    this.waitDataCmd = null
    this.rxPin = rxPin
    this.txPin = txPin
  }

  factoryReset = () => {
    this.baudrate = 9600
    this.onColor = new BABYLON.Color3()
    this.offColor = BABYLON.Color3.Black()
    this.setBrightness(100)
    this.i2cAddr = 0x71
  }

  setBrightness = (pcnt) => {
    this.brightness = pcnt
    // Adjust our current 'onColor' based on brightness pct.
    LED_COLOR.scaleToRef(pcnt / 100, this.onColor)
  }

  handleWaitData = (value) => {
    switch (this.waitDataCmd) {
      case 0x77: // decimal
        this.powerDecimals(value)
        break
      case 0x79: // cursor
        this.cursor = value < 4 ? value : this.cursor
        break
      case 0x7A: // brightness
        this.setBrightness(value > 100 ? 100 : value)
        break
      case 0x7B: // digit1
      case 0x7C: // digit2
      case 0x7D: // digit3
      case 0x7E: // digit4
        this.powerDigit(this.waitDataCmd - 0x7B, value)
        break
      case 0x7F: // baudrate
        if (value < 0x0C) {
          this.baudrate = baudrates[value]
        }
        break
      case 0x80: // i2caddr
        if (value >= 0x01 && value <= 0x7E) {
          this.i2cAddr = value
        }
        break
      default:
        break
    }
  }

  hasUart = () => {
    return true
  }

  // override for PeripheralBase
  uartWrite = (pin, baudrate, bits, parity, stopBits, bytesArr) => {
    if (pin !== this.rxPin || baudrate !== this.baudrate || bits !== 8 || stopBits !== 1 || parity !== null) {
      return
    }

    for (var i = 0; i < bytesArr.length; i++) {
      var cmd = bytesArr[i]
      // console.log(cmd, ledVals[cmd])
      if (this.waitDataCmd !== null) {
        this.handleWaitData(cmd)
        this.waitDataCmd = null
      } else if (twoByteCmds.includes(cmd)) {
        this.waitDataCmd = cmd
      } else if (cmd === 0x76) { // clear: all LEDs off, reset cursor to 0
        this.cursor = 0
        this.digits.forEach((digit, idx) => this.powerDigit(idx, 0))
      } else if (cmd === 0x81) { // factory reset, bright 100, i2c 0x71, 9600 baud
        this.factoryReset()
      } else if (ledVals.hasOwnProperty(cmd)) {
        this.powerDigit(this.cursor, ledVals[cmd])
        this.cursor = this.cursor >= 3 ? 0 : this.cursor + 1
      }
    }
  }

  powerDigit = (digit, val) => {
    for (let i = 0; i < 7; i++) {
      this.powerSegment(digit, i, ((val >> i) & 0x01) === 1)
    }
  }

  powerLed = (led, isOn) => {
    led.material.emissiveColor = isOn ? this.onColor : this.offColor
  }

  powerSegment = (digit, segment, isOn) => {
    this.powerLed(this.digits[digit][segment], isOn)
  }

  powerDecimals = (val) => {
    const decimalBits = [0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x10]
    decimalBits.map((loc, idx) => this.powerDecimal(idx, (val & loc) !== 0))
  }

  powerDecimal = (idx, isOn) => {
    this.powerLed(this.decimals[idx], isOn)
  }

  loadBBox = async (scene, name, w, h, d, x, y, z, c0, c1, c2) => {
    const box = BABYLON.MeshBuilder.CreateBox(name, { width: w, height: h, depth: d }, scene)
    const material = new BABYLON.StandardMaterial('', scene)
    material.diffuseColor = new BABYLON.Color3(c0, c1, c2)
    material.specularColor = new BABYLON.Color3(c0, c1, c2)
    material.ambientColor = new BABYLON.Color3(c0, c1, c2)
    box.material = material
    box.parent = this.peripheralTransform
    box.position = new BABYLON.Vector3(x, y, z)
    return box
  }

  loadBody = async (scene) => {
    this.displayBase = this.loadBBox(scene, 'SevenSegBase', 0.4018, LED_BASE_HEIGHT, 0.128, 0, BODY_HEIGHT + LED_BASE_HEIGHT / 2, 0, 1, 1, 1)
    this.blackBackground = this.loadBBox(scene, 'SevenSegBlack', 0.4018, BB_HEIGHT, 0.128, 0, BODY_HEIGHT + LED_BASE_HEIGHT + BB_HEIGHT / 2, 0, 0, 0, 0)
  }

  loadLed = (name, scene, x, y, w, d) => {
    const led = BABYLON.MeshBuilder.CreateBox(name, { width: w, height: BB_HEIGHT, depth: d }, scene)
    led.material = new BABYLON.StandardMaterial('', scene)
    led.material.diffuseColor = new BABYLON.Color3(0.2, 0.2, 0.2)  // "off" segments are gray
    led.material.emissiveColor = this.offColor
    led.parent = this.peripheralTransform
    led.position = new BABYLON.Vector3(x, BODY_HEIGHT + LED_BASE_HEIGHT + BB_HEIGHT + BB_HEIGHT / 2, y)
    return led
  }

  loadSegment = (params, idx, scene) => {
    const w = params.h ? 0.042 : 0.009
    const d = params.h ? 0.009 : 0.042
    return this.loadLed('Segment' + idx.toString(), scene, params.x, params.y, w, d)
  }

  loadDigit = (scene, x) => {
    const segments = [
      {h: true, x: x, y: 0.0455},
      {h: false, x: 0.0255 + x, y: 0.0255},
      {h: false, x: 0.0255 + x, y: -0.0255},
      {h: true, x: x, y: -0.0455},
      {h: false, x: -0.0255 + x, y: -0.0255},
      {h: false, x: -0.0255 + x, y: 0.0255},
      {h: true, x: x, y: 0},

    ]
    return segments.map((p, idx) => this.loadSegment(p, idx, scene))
  }

  loadDigits = (scene) => {
    this.digits = DIGIT_OFFSETS.map(x => this.loadDigit(scene, x))
  }

  loadDecimal = (scene, loc, idx) => {
    return this.loadLed('Decimal' + idx.toString(), scene, loc.x, loc.y, 0.011, 0.011)
  }

  loadDecimals = (scene) => {
    this.decimals = decimalLocs.map((loc, idx) => this.loadDecimal(scene, loc, idx))
  }

  loadPcbWithPhysics = async (scene, parent) => {
    // Load PCB model and bind physics "compound collider" to its mesh
    const pcbPos = new BABYLON.Vector3(0, 0, 0)
    const pcbRot = new BABYLON.Vector3(0, Math.PI, 0)
    pcbPos.addInPlace(this.peripheralTransform.position)
    pcbRot.addInPlace(this.peripheralTransform.rotation)
    const pcb = await loadGLBWithPhysics(SevenSegmentPcb, 'SevenSegPcb', {mass: 0}, 1.0, pcbPos, pcbRot, scene)

    // Must add physics mesh directly to the physics-enabled parent. (i.e. physics doesn't know about child meshes)
    pcb.parent = parent
    return pcb
  }

  load = async (scene, parent, posVec3, rotVec3) => {
    this.peripheralTransform = new BABYLON.TransformNode('Peripheral-SevenSegment')
    this.peripheralTransform.rotation = rotVec3
    this.peripheralTransform.position = posVec3
    this.loadBody(scene)
    this.loadDigits(scene)
    this.loadDecimals(scene)
    this.pcb = await this.loadPcbWithPhysics(scene, parent)

    this.peripheralTransform.parent = parent
  }

  unload = async () => {
    this.peripheralTransform.dispose()
    await disposePromise(this.pcb)
  }
}

export default SevenSegment