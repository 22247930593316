import React from 'react'
import { Button, Chip, Link, Divider, Dialog, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { useLogin } from './contexts/LoginContext'
import { FiriaGreenButton } from './FiriaGreenButton'
import { editorMode } from './content-manager/lesson-editor/lesson-editor-controllers'

const getLoginSite = () => {
  if (process.env.NODE_ENV === 'development') {
    return '//localhost:3006'
  } else if (window.location.host.includes('dev')) {
    return '//dev-login.firialabs.com'
  } else {
    return '//login.firialabs.com'
  }
}

export const loginHref = getLoginSite() + '?redirect=y&returnTo=' + window.location.host

const useStyles = makeStyles(theme => ({
  dialog: {
    height: '70%',
  },
  dialogContentContainer: {
    padding: 25,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    width: 650,
  },
  contentSection: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    width: '49%',
    // padding: '0px 10px 0px 10px',
  },
  topHalfContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    padding: 15,
    paddingBottom: 20,
  },
  loginTextBlock: {
    fontSize: 15,
    paddingBottom: 3,
  },
  freeAccountTextBlock: {
    fontSize: 15,
    paddingBottom: 15,
  },
  dividerContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  bottomButtonContainer: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    padding: 15,
    paddingTop: 20,
    flexDirection: 'column',
  },
  bottomButton: {
    width: 260,
    borderRadius: 3,
  },
  divider: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  warningContainer: {
    border: '1px solid rgb(0,0,0,0.3)',
    borderRadius: 3,
    backgroundColor: 'rgba(255,208,56)',
    '&:hover': {
      background: 'rgba(255,208,56, 0.9)',
    },
    textTransform: 'none',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'black',
  },
}))

function VerticalLoginButtonsContainer(props) {
  const classes = useStyles()

  return (
    <div style={{ width: '100%'}}>
      <div className={classes.topHalfContainer}>
        {props.children[0]}
      </div>
      <div className={classes.dividerContainer}>
        <Divider className={classes.divider}/>
        <Chip label='OR' />
        <Divider className={classes.divider}/>
      </div>
      <div className={classes.bottomButtonContainer}>
        {props.children[1]}
      </div>
    </div>
  )
}

function LoginButton(props) {
  return (
    <Link href={loginHref} style={{ color: 'inherit', textDecoration: 'inherit'}}>
      <FiriaGreenButton variant='contained' style={{ width: 260 }} {...props} >
        {'Log In'}
      </FiriaGreenButton>
    </Link>
  )
}

function MicroBitWarningMessage() {
  const classes = useStyles()
  return (
    <Button
      variant='outlined'
      href='https://makebit.firialabs.com'
      className={classes.warningContainer}
    >
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center'}}>
        <Typography variant='subtitle1'>
          {'JumpStart and LiftOff for micro:bit have moved to'}
        </Typography>

        <Typography variant='subtitle1' style={{textDecoration: 'underline'}}>
          {'makebit.firialabs.com!'}
        </Typography>
      </div>
    </Button>
  )
}

const LandingDialog = ({ ready }) => {
  const classes = useStyles()
  const [loginState] = useLogin()
  const [dialogDismissed, setDialogDismissed] = React.useState(false)

  const dismissDialog = () => setDialogDismissed(true)

  React.useEffect(() => {
    if (loginState?.user) {
      setDialogDismissed(true)
    } else {
      setDialogDismissed(false)
    }
  }, [loginState])

  const open = !loginState?.user && ready && !dialogDismissed && !window.location.pathname.includes('hourofcode') && !editorMode
  return (
    <Dialog
      open={open}
      onClose={(ev, reason) => {
        if (reason === 'backdropClick' ){
          return
        }
        dismissDialog()
      }}
      className={classes.dialog}
      maxWidth={'md'}
    >
      <div className={classes.dialogContentContainer}>
        <img alt={''} src={'pub/logo-min.svg'} />
        <Divider />
        <VerticalLoginButtonsContainer>
          <>
            <div className={classes.loginTextBlock}>Log In or Create an Account!</div>
            <div className={classes.freeAccountTextBlock}><i>A free account lets you save files and access premium Missions!</i></div>
            <LoginButton />
          </>
          <>
            <Button variant='outlined' className={classes.bottomButton} onClick={dismissDialog}>
              {'Continue Without logging in'}
            </Button>
            <div style={{height: 20}}/>
            <MicroBitWarningMessage />
          </>
        </VerticalLoginButtonsContainer>
      </div>
    </Dialog>
  )
}

export { VerticalLoginButtonsContainer, LoginButton }
export default LandingDialog