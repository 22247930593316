const logStore = []
var totalLogs = 0
const maxLogs = 2000

const formatStoreLog = ({ ts, level, messages, bindings = [] }) => {
  let email = null
  bindings.forEach((binding) => {
    if ('user' in binding) {
      email = binding.user.email
    }
  })

  return `${new Date(ts).toISOString()} - ${level.label} - ${
    email ? `${email}`:'Logged Out'
  } - ${messages[0]}`
}

const storeLog = (log) => {
  if (totalLogs > maxLogs) {
    logStore.shift()
  }

  logStore.push(formatStoreLog(log))
  totalLogs += 1
}

const downloadLogStore = () => {
  const element = document.createElement('a')
  const file = new Blob([...JSON.stringify(logStore,null,4)], {type: 'json'})
  element.href = URL.createObjectURL(file)
  element.download = `${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}-${document.location.href}-logs.json`
  document.body.appendChild(element)
  element.click()
}


export {
  storeLog,
  downloadLogStore,
}