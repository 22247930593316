import {
  setMissionProgressInUserProgressStore,
  setFlowProgressInUserProgressStore,
} from '../user-progress-store'
import {
  userSessionStore,
} from '../../user-session/user-session-store'


// This is being called by the UserProgressControl, since setting/getting progress is so tightly controlled
// by mission context, content-manager's userProgress is just along for the ride.
function formatAndSetUserProgress(userProgress, initialization){
  // We can't afford for this function to fail, otherwise progress doesn't get saved
  try {
    Object.entries(userProgress.missions).forEach(([missionId, mission]) => {
      setMissionProgressInUserProgressStore(missionId, mission)
      mission.flow.forEach((flowItem) => {
        setFlowProgressInUserProgressStore(flowItem)
      })
    })

    userSessionStore.setUserSessionUI(userProgress.ui)
    if (initialization) {
      userSessionStore.setInitializedUserSessionUI(userProgress.ui)
    }
  } catch (err) {
    console.log('ERROR!', err)
  }
}

export { formatAndSetUserProgress }