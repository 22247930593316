// Hallway Environment
import { Entity } from '../../SimScene'
import * as BABYLON from '@babylonjs/core'
import { TemplateLights, TemplateGround, TemplateSounds } from '../EnvTemplate'
import {
  disposePromise, loadGLB,
} from '../../utils/BabylonUtils'
import PbrLightingTextureEnv from '../../assets/MistyBrightSky.env'
import EnvModel from '../../assets/HauntedHouse/Hallway.glb'
import EnvThemeMusic from '../../assets/HauntedHouse/sounds/Hall.mp3'
import SnackCrunchSound from '../../assets/HauntedHouse/sounds/SnackCrunch.mp3'
import CatTrinket from '../../assets/HauntedHouse/cat.glb'
import GhostTrinket from '../../assets/HauntedHouse/ghost.glb'
import PumpkinTrinket from '../../assets/HauntedHouse/pumpkin.glb'
import BloodStain from '../../assets/HauntedHouse/BloodStain.glb'
import LevelEndSound from '../../assets/HauntedHouse/sounds/LevelEnd.mp3'

const environmentName = 'Hallway'

// Environment template options
const lightOptions = {
  envLightingName: 'MistyBrightSky',
  envLightingSource: PbrLightingTextureEnv,
  envIntensity: 0.2,
  fogMode: BABYLON.Scene.FOGMODE_EXP2,
  fogDensity: 0.003,
  fogColor: new BABYLON.Color3(0.9, 0.9, 0.85),
}
const soundOptions = {
  envMusicName: 'HallwayThemeMusic',
  envMusicSource: EnvThemeMusic,
  envSoundFx: [
    {name: 'snackCrunchSound', source: SnackCrunchSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'levelEndSound', source: LevelEndSound, options: { spatialSound: false, volume: 1.0 }},
  ],
}
const groundOptions = {
  envModelName: 'HallwayModel',
  envModelSource: EnvModel,
}

// Default environment params which the Mission Editor may override
const defaultEnvParams = {
  'bot': [
    {
      'playerPosition': [56.0, 0, 0.61],  // Lined up for easy blood trail
      'playerRotation': 90,
    },
  ],
  'stain': false,  // If true, stain will appear at random distance in front of bot (range x=45-52, z=0)
  'trails': false,  // Show blood trails (short + long) to one door (currently Door1 or Door5)
  'trinkets': [
    {
      pos: [5, 0, 5],
      rot: 0,
      scale: 1.0,
      scoreValue: 10,
      shape: null,   // null for random choice. Choices are: 'cat', 'ghost', 'pumpkin'
    },
  ],
}

// Interactives - things to interact with in the environment:
//  * Movable objects, treats, NPCs, etc.
class Interactives extends Entity {
  constructor(mainEnv) {
    super()
    this.env = mainEnv
    this.trinketMap = new Map([
      ['cat', CatTrinket],
      ['ghost', GhostTrinket],
      ['pumpkin', PumpkinTrinket],
    ])
  }

  loadTrinket = async (index, shape, scoreValue, position, scaling, rotation) => {
    const model = this.trinketMap.get(shape) || [...this.trinketMap.values()][Math.trunc(this.trinketMap.size * Math.random())]
    return await loadGLB(model, `trinket${index}_+${scoreValue}`, scaling, position, rotation)
  }

  loadStain = async () => {
    // Place stain between (45, 0, 0) and (52, 0, 0)
    const xPos = 45 + 7 * Math.random()
    return await loadGLB(BloodStain, 'BloodStain', 1.0, [xPos, 0, 0], 0)
  }

  load = async (scene, params) => {
    this.scene = scene
    this.params = params
    this.timers = []  // timer IDs for cleanup
    this.disposeList = []

    let trinkets = []
    let stain = []

    if (params.trinkets) {
      trinkets = params.trinkets.map((t, index) => {
        return this.loadTrinket(index, t.shape, t.scoreValue, t.pos, t.scale, t.rot)
      })
    }

    if (params.stain) {
      stain.push(this.loadStain())
    }

    const interactivesPromises = [...trinkets, ...stain]
    this.meshes = await Promise.all(interactivesPromises)
    this.disposeList.push(...this.meshes)
  }

  unload = async () => {
    // Stop any active timers
    this.timers.forEach(id => clearTimeout(id))

    await Promise.all(this.disposeList.map(disposePromise))
  }

  configure = (params) => {
    // Finalize interactives AFTER loading all assets
    this.configureTrinkets()
  }

  configureTrinkets = () => {
    const crunchSfx = this.env.sounds.cloneSfx('snackCrunchSound')

    this.meshes.forEach((m) => {
      if (m.name.startsWith('trinket')) {
        // Trinket is non-physics mesh, so tag colliding body as BotCollider
        const trinket = m.getChildMeshes(false, m => m.name === 'Circle' || m.name === 'Cat_primitive1' || m.name === 'Cube_primitive0')?.[0]
        trinket.name = m.name
        try {
          trinket.points = parseInt(m.name.split('_+')[1])
        } catch (err) {
          trinket.points = 1
        }

        BABYLON.Tags.AddTagsTo(trinket, 'BotCollider')
        trinket.botCollision = () => {
          // Play "crunch" sound and disappear
          crunchSfx.play()
          m.dispose()
        }
      }
    })
  }
}

class MainEnvironment extends Entity {
  constructor() {
    super()

    groundOptions.meshVisitor = this.meshVisitor

    this.name = environmentName
    this.lights = new TemplateLights(lightOptions)
    this.sounds = new TemplateSounds(soundOptions)
    this.ground = new TemplateGround(groundOptions)
    this.interactives = new Interactives(this)
    this.defaultParams = defaultEnvParams

    this.selectedDoor = null  // On load will be randomly set to 1 or 5
    this.doorMesh = null
  }
  meshVisitor = (mesh) => {
    // Look for DoorN and PathN meshes
    if (mesh.name === `Door${this.selectedDoor}`) {
      BABYLON.Tags.AddTagsTo(mesh, 'BotCollider')
      this.doorMesh = mesh
      this.doorMesh.material.emissiveColor.g = 0.0  // No green glow. Hell no!
    } else if (mesh.name.startsWith('Door')) {
      BABYLON.Tags.RemoveTagsFrom(mesh, 'BotCollider')
    } else if (mesh.name.startsWith('Path')) {
      const activePath = this.params.trails && parseInt(mesh.name[4]) === this.selectedDoor
      mesh.isVisible = activePath
      mesh.isPickable = activePath
    } else if (mesh.name.startsWith('Spot')) {
      const randomStains = !this.params?.stain    // No random stains when we're in "find the stain" mode
      mesh.isVisible = randomStains
      mesh.isPickable = randomStains
    }
  }
  finalObjectiveHit = () => {
    // Light door
    if (this.doorMesh) {
      // In current model this lights up ALL doors, but hey, why not?
      this.doorMesh.material.emissiveColor.g = 1.0  // Glow Green!
      this.playerController.suspendActivity(true)
    }
  }
  missionCompleteMusic = () => {
    if (this.levelEndSfx) {
      this.levelEndSfx.play()
    }
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false

    this.selectedDoor = Math.random() > 0.5 ? 5 : 1
    this.doorMesh = null

    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.sounds.load(scene),
      this.interactives.load(scene, this.params),
    ])

    // Configuration after async loading
    this.interactives.configure()
    this.levelEndSfx = this.sounds.cloneSfx('levelEndSound')

    // TESTING
    // this.doorMesh.botCollision = (cbImpostor, cbController) => {
    //   console.log('Hit door!')
    //   this.finalObjectiveHit()
    // }
    // window.simEnv = this
  }
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.sounds.unload(),
      this.ground.unload(),
      this.interactives.unload(),
    ])
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
  setVolume = (volumeParams) => {
    this.sounds.setVolume(volumeParams)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController

    // Disallow keyboard CB buttons for this challenge (no driving!)
    if (this.playerController) {
      this.playerController.allowKeyboardButtons = false

      // Not using prox, save CPU
      this.playerController.sensorOverrides.prox = (num) => {}
    }
  }
}

export default MainEnvironment
