import React from 'react'
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, TextField, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FiriaGreenButton, FiriaGreenButtonBase, FIRIA_GREEN_RGB, FIRIA_GREEN } from './FiriaGreenButton'
import { getTotalXPEarned } from './content-manager/user-progress/user-progress-use-cases/getTotalXPEarned'
import { getTotalScoreEarnedByMissionPack } from './content-manager/user-progress/user-progress-use-cases/getTotalScoreEarnedByMissionPack'
import { loginHref } from './LandingDialog'
import { useLogin } from './contexts/LoginContext'
import { useContests } from './contexts/ContestContext'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import { GatewayResponseIndicator } from './MyClassesDialog/GatewayResponseIndicator'
import { registerIndyUser } from './content-manager/contests/contest-use-cases/registerIndyUser'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const useStyles = makeStyles((theme) => {
  const type = theme.palette.type
  return {
    dialog: {
      height: '100%',
      minWidth: 600,
    },
    progressItemContainer: {
      border: `1px solid ${type === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)' }`,
      backgroundColor: type === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.03)',
      borderRadius: 3,
      padding: '5px 10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems:'center',
    },
    defaultLeaderboardRow: {
      border: `1px solid ${type === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)' }`,
      backgroundColor: type === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.03)',
      display: 'flex',
      padding: '5px 10px',
      userSelect: 'none',
      borderRadius: 4,
    },
    userScoredLeaderboardRow: {
      border: `1px solid rgba(${type === 'dark' ? '244, 210, 56' : '138, 101, 0'}, 1)`,
      backgroundColor: 'rgba(244, 210, 56, 0.8)',
      display: 'flex',
      padding: '5px 10px',
      color: 'black',
      userSelect: 'none',
      borderRadius: 4,
    },
    headerScoredLeaderboardRow: {
      border: `1px solid ${type === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.5)' }`,
      backgroundColor: type === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.03)',
      display: 'flex',
      alignItems:'center',
      padding: '5px 10px',
      userSelect: 'none',
      borderRadius: 2,
      height: 30,
    },
    leaderboardContainer: {
      border: `1px solid ${type === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.2)' }`,
      // backgroundColor: type === 'dark' ? 'rgba(255,255,255,0.003)' : 'rgba(0,0,0,0.003)',
      borderRadius: 3,
      padding: '5px 10px',
      width: '100%',
    },
    crown: {
      textShadow: '0.075em -0.075em 0.075em #000, 0.075em -0.075em 0.075em #000, -0.075em 0.075em 0.075em #000, 0.075em 0.075em 0.075em #000',
    },
    textField: {
      '& .MuiInput-underline:after': {
        borderBottomColor: FIRIA_GREEN,
      },
      width: '100%',
    },
    registeredUnstartedPanelContainer: {
      display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent: 'center', gap: 25, padding: '20px 20px', width: '100%',
      borderTop: `1px solid ${type === 'dark' ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.5)' }`,
    },
  }
})

function HalloweenRegisteredPanel({halloweenContest}) {
  const classes = useStyles()
  const { registered, started, bandName } = halloweenContest

  if (registered && !started) {
    return (
      <div className={classes.registeredUnstartedPanelContainer}>
        <Typography variant='h5'>
          {'You\'re registered!'}
        </Typography>
        <ProgressContainer title={'Grade Band'}>
          {bandName}
        </ProgressContainer>
        <Typography>
          {'Check back in on October 1st when the competition begins!'}
        </Typography>
      </div>
    )
  }

  return (
    <LeaderboardsPanel leaderboards={halloweenContest.leaderboards}/>
  )
}

function HalloweenUnregisteredLoggedOutPanel() {
  return (
    <>
      <Button variant='outlined' href={loginHref}>
        Log In to enable registration
      </Button>
    </>
  )
}

function ContestQuestion({ question, required=false, children }) {
  return (
    <div>
      <div style={{display: 'flex', alignItems: 'center', gap: 5}}>
        <Typography style={{fontWeight: 'bold'}}>{question}</Typography>
        {required && <Typography style={{fontWeight: 'bold', color: 'red'}}>{'*'}</Typography>}
      </div>
      {children}
    </div>
  )
}

const OutlinedCheckBox = ({ checked=false, onClick, disabled, label, style }) => {
  return (
    <Button
      variant='outlined'
      onClick={onClick}
      disabled={disabled}
      style={{
        textTransform:'none',
        width: '100%',
        justifyContent: 'flex-start',
        backgroundColor: checked ? `rgba(${FIRIA_GREEN_RGB}, 0.2)` : null,
        padding: 5,
        ...style,
      }}
    >
      <span style={{display: 'flex', gap: 5, alignItems:'center', justifyContent: 'flex-start'}}>
        <Checkbox
          style={{borderRadius: 3, backgroundColor: 'rgba(0,0,0,0)', marginBottom: 1}}
          checked={checked}
          disableRipple={true}
          checkedIcon={<CheckBoxIcon style={{color: FIRIA_GREEN}}/>}
        />
        {label}
      </span>
    </Button>
  )
  // return (
  //   <FormControl
  //     variant='outlined'
  //     className={`${classes.textField} ${props.className}`}
  //     style={{width: '100%'}}
  //   >
  //     <InputLabel
  //       shrink
  //     >
  //       {props.label}
  //     </InputLabel>
  //     <OutlinedInput
  //       disabled={props.disabled}
  //       notched
  //       id='component-disabled'
  //       label={props.label}
  //       inputComponent={getCheck}
  //     />
  //   </FormControl>
  // )
}

function IndyRegistrationButton({ disabled, onClick }) {
  const {
    putIndyContestRegistrationResponseState,
    indyRegistrationPanelOpen,
  } = useContests()

  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
      <div style={{width: 24}}>
        <GatewayResponseIndicator
          fetchState={putIndyContestRegistrationResponseState}
          open={indyRegistrationPanelOpen}
        />
      </div>
      <div style={{width: 10}}></div>
      <FiriaGreenButtonBase
        variant='contained'
        disabled={disabled}
        onClick={onClick}
        style={{width: 160}}
      >
        {'Submit Registration'}
      </FiriaGreenButtonBase>
      <div style={{width: 24}}/>
    </div>
  )
}

function makeIndyRegistrationFields({
  firstName='',
  lastName='',
  over13=true,
  memberOfEducationalInstitution=true,
  educationalInstitution='',
  terms=false,
}) {
  return Object.freeze({
    firstName,
    lastName,
    memberOfEducationalInstitution,
    educationalInstitution,
    over13,
    terms,
  })
}

function IndyRegistrationPanel() {
  const classes = useStyles()
  const {
    contestsStateInterface,
  } = useContests()

  const [registrationFields, setRegistrationFields] = React.useState(makeIndyRegistrationFields({}))

  function updateRegistrationField(key, value) {
    setRegistrationFields({
      ...registrationFields,
      [key]: value,
    })
  }

  async function onIndyRegistrationButtonClicked() {
    const { firstName, lastName, ...registrationDetails } = registrationFields
    const error = await registerIndyUser('hween_2024', firstName, lastName, registrationDetails)
    if (!error) {
      contestsStateInterface.toggleIndyRegistrationPanelOpen()
    }
  }

  const registrationButtonDisabled = !registrationFields.firstName || !registrationFields.lastName || !registrationFields.over13 || !registrationFields.terms || !registrationFields.educationalInstitution
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 25}}>
      <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
        <Button variant='outlined' onClick={contestsStateInterface.toggleIndyRegistrationPanelOpen}>
          <div style={{display: 'flex', alignItems:'center'}}>
            <KeyboardBackspaceIcon style={{marginRight: 5, fontSize: 20}}/>
            {'Back'}
          </div>
        </Button>
        <Typography variant='h6'>
          {'Haunted Code Chronicles 2024'}
        </Typography>
        <div style={{width: 95}}/>
      </div>
      <Typography variant='h4' style={{paddingBottom: 10, margin: 'auto', fontWeight: 'bold'}}>
        {'Independent Registration'}
      </Typography>
      <ContestQuestion question={'First Name'}>
        <TextField
          className={classes.textField}
          placeholder='First Name'
          value={registrationFields.firstName}
          onChange={e => updateRegistrationField('firstName', e.target.value)}
        />
      </ContestQuestion>
      <ContestQuestion question={'Last Name'}>
        <TextField
          className={classes.textField}
          placeholder='Last Name'
          value={registrationFields.lastName}
          onChange={e => updateRegistrationField('lastName', e.target.value)}
        />
      </ContestQuestion>
      <ContestQuestion question={'Are you over 13 years old?'}>
        <OutlinedCheckBox
          style={{width: '100%', marginTop: 5}}
          checked={registrationFields.over13}
          // onClick={e => updateRegistrationField('terms', !terms)}
          onClick={e => updateRegistrationField('over13', !registrationFields.over13)}
          label={'I am older than 13'}
        />
      </ContestQuestion>
      {!registrationFields.over13 &&
        <div style={{backgroundColor: 'rgba(255, 51, 51, 0.7)', border: '1px solid rgba(0,0,0,0.3)', borderRadius: 4, padding: '5px 10px', display: 'flex', alignItems:'center', gap: 20, width: '100%'}}>
          <ErrorOutlineIcon />
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
            <Typography>
              {'You must be older than 13 to register for this competition.'}
            </Typography>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Typography>
                {'Please refer to our '}
                <a
                  style={{color:'white'}}
                  onClick={(e) => {
                    window.open('https://firialabs.com/pages/privacy-policy', '_blank')
                    e.preventDefault()
                  }}
                  href='https://firialabs.com/pages/privacy-policy'
                >
                  {'privacy policy'}
                </a>
                {' for more information.'}
              </Typography>
              {/* <Typography style={{paddingLeft: 1}}>
                <a
                style={{color:'white'}}
                href='https://firialabs.com/pages/privacy-policy#:~:text=Your%20personal%20information%20is%20held,to%20keep%20the%20information%20confidential'>
                  {' privacy policy '}
                </a>
              </Typography>
              <Typography>
                {' for more information.'}
              </Typography> */}
            </div>
          </div>
        </div>
      }
      <ContestQuestion question={'School/Organization Name'}>
        <TextField
          className={classes.textField}
          placeholder='School/Organization Name'
          value={registrationFields.educationalInstitution}
          onChange={e => updateRegistrationField('educationalInstitution', e.target.value)}
        />
      </ContestQuestion>
      <ContestQuestion question={'Terms and Conditions'}>
        <OutlinedCheckBox
          style={{width: '100%', marginTop: 5}}
          checked={registrationFields.terms}
          onClick={e => updateRegistrationField('terms', !registrationFields.terms)}
          label={'I\'ve read and agree to the terms and conditions'}
        />
      </ContestQuestion>
      <IndyRegistrationButton
        onClick={onIndyRegistrationButtonClicked}
        disabled={registrationButtonDisabled}
      >
        {'Register'}
      </IndyRegistrationButton>
    </div>
  )
}

function HalloweenUnregisteredLoggedInPanel() {
  const {
    contestsStateInterface,
  } = useContests()

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10, alignItems:'center', width: '100%'}}>
        <Typography>Register as a:</Typography>
        <Button
          variant='outlined'
          style={{width: '45%'}}
          onClick={() => window.open('https://learn.firialabs.com/compete/haunted-code-chronicles/hh24-instructions', '_blank')}
        >
          {'Student'}
        </Button>
        <Button
          variant='outlined'
          style={{width: '45%'}}
          onClick={() => window.open('https://learn.firialabs.com/compete/haunted-code-chronicles/hh24-instructions', '_blank')}
        >
          {'Teacher'}
        </Button>
        <Button
          variant='outlined'
          style={{width: '45%'}}
          onClick={contestsStateInterface.toggleIndyRegistrationPanelOpen}
        >
          {'Individual'}
        </Button>
      </div>
    </>
  )
}

function HalloweenUnregisteredPanel() {
  const [loginState] = useLogin()
  return (
    <>
      <Typography variant='h6'>
        {'You\'re not registered for this competition!'}
      </Typography>
      {loginState?.user ? <HalloweenUnregisteredLoggedInPanel />:<HalloweenUnregisteredLoggedOutPanel />}
    </>
  )
}

// function generateMenuItems(scoredContent, level=0) {
//   const menuItems = []

//   menuItems.push(
//     <MenuItem value={scoredContent.id} style={{width: 500}}>
//       <div style={{ display: 'flex', alignItems: 'center', gap: 15}}>
//         <Avatar variant='square' src={scoredContent.avatarBlob ? URL.createObjectURL(scoredContent.avatarBlob) : ''} />
//         <Typography>
//           {`${level === 0 ? 'Overall - ' : ''}${scoredContent.name}`}
//         </Typography>
//       </div>
//     </MenuItem>
//   )

//   if ('children' in scoredContent) {
//     menuItems.push(scoredContent.children.map(child => generateMenuItems(child, level + 1)))
//   }
//   return menuItems.flat()
// }

// function LeaderboardSelector({ selectedLeaderboardId, setSelectedLeaderboardId, scoredContent }) {
//   return (
//     <Select
//       style={{width: 500}}
//       autoWidth
//       variant='outlined'
//       value={selectedLeaderboardId}
//       onChange={ev => setSelectedLeaderboardId(ev.target.value)}
//     >
//       {generateMenuItems(scoredContent)}
//     </Select>
//   )
// }

function LeaderboardTableRow({ score='-', place, name='-', submissionId=null, header }) {
  const [loginState] = useLogin()
  const classes = useStyles()
  const userId = loginState?.user?.uid
  const usersScore = userId === submissionId

  return (
    <div className={header ? classes.headerScoredLeaderboardRow : usersScore ? classes.userScoredLeaderboardRow : classes.defaultLeaderboardRow}>
      {place === 1 ?
        <Typography className={classes.crown} style={{width: 120}}>
          {'👑'}
        </Typography>:
        <Typography style={{width: 120}}>
          {place}
        </Typography>
      }
      <Typography style={{width: 230}}>
        {usersScore ? 'You!' : ''}
      </Typography>
      <Typography align='right' style={{width: 140}}>
        {score}
      </Typography>
    </div>
  )
}

const defaultNames = [
  'The Spooky Scary Skeletons',
  'The Ghosts',
  'The Ghouls',
  'The Goblins',
  'The Witches',
]

function generateLeaderboardRowData(submissionIds, submissionScores, userId, userScore) {
  const rows = new Array(5).fill(null).map((_, idx) => <LeaderboardTableRow key={idx} place={idx + 1}/>)
  if (!submissionIds) {
    return rows
  }
  submissionIds.forEach((submissionId, idx) => {
    rows[idx] = <LeaderboardTableRow name={defaultNames[idx]} score={submissionScores[idx]} key={idx} place={idx + 1} submissionId={submissionId}/>
  })

  if (!submissionIds.includes(userId)) {
    rows.push(<LeaderboardTableRow name={'You!'} score={userScore} place={'-'} key={userId} submissionId={userId}/>)
  }

  return rows
}

function LeaderboardTable() {
  const [loginState] = useLogin()
  const { selectedLeaderboardData } = useContests()
  const userId = loginState?.user?.uid

  const sortedIds = selectedLeaderboardData?.sortedSubmissionIds ?? []
  const sortedScores = selectedLeaderboardData?.sortedSubmissionScores ?? []
  const userScore = selectedLeaderboardData?.userScore ?? 0
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 5}}>
      <LeaderboardTableRow name={''} score={'Score'} place={'Rank'} header={true} key={'header'}/>
      {generateLeaderboardRowData(sortedIds, sortedScores, userId, userScore)}
    </div>
  )
}

function LeaderboardPanel({ leaderboard={}, bandName }) {
  const classes = useStyles()
  const { contestsStateInterface } = useContests()

  React.useEffect(() => {
    try {
      contestsStateInterface.setSelectedLeaderboardScoreIdState(leaderboard.id)
    } catch {
      //
    }
  }, [contestsStateInterface, leaderboard.id])

  return (
    <div className={classes.leaderboardContainer}>
      <Typography variant={'h5'} style={{paddingBottom: 3}}>
        {bandName}
      </Typography>
      {/* <LeaderboardSelector
        selectedLeaderboardId={selectedLeaderboardId}
        setSelectedLeaderboardId={setSelectedLeaderboardId}
        scoredContent={leaderboard}
      /> */}
      <LeaderboardTable leaderboard={leaderboard}/>
    </div>
  )
}

function LeaderboardsPanel({ leaderboards }) {
  const { contests } = useContests()

  const halloweenContest = contests.find(({id}) => id === 'hween_2024')
  // For now we're just going to show a single leaderboard, this code will be simpler when we have multiple
  let leaderboard = {}

  try {
    const nonDefaultLeaderboardsObj = leaderboards.find(leaderboard => leaderboard.bandName !== 'Default')
    leaderboard = nonDefaultLeaderboardsObj.HWN
  } catch {
    //
  }

  return (
    <div>
      <div>
        {[leaderboard].map(leaderboard => <LeaderboardPanel key={halloweenContest.bandName} leaderboard={leaderboard} bandName={halloweenContest.bandName}/>)}
      </div>
    </div>
  )
}

function HalloweenPanel() {
  const { contests } = useContests()

  const halloweenContest = contests.find(({id}) => id === 'hween_2024')
  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, padding: '0px 20px'}}>
      <Typography style={{fontSize: 22}}>
        {'Haunted Code Chronicles 2024'}
      </Typography>
      <FiriaGreenButton onClick={() => window.open('https://learn.firialabs.com/compete/haunted-code-chronicles', '_blank')}>
        {'View Competition Info'}
      </FiriaGreenButton>
      <div style={{height: 10}}></div>
      {halloweenContest.registered ?
        <HalloweenRegisteredPanel halloweenContest={halloweenContest}/>:
        <HalloweenUnregisteredPanel />}
    </div>
  )
}


function contestComponentMapper(contestId) {
  if (contestId === 'hween_2024') {
    return <HalloweenPanel key={contestId}/>
  }
}

function ContestPanel() {
  const { contests } = useContests()

  return (
    <div>
      {contests.map(({id}) => contestComponentMapper(id))}
    </div>
  )
}

function ProgressContainer({ title, children }) {
  const classes = useStyles()

  return (
    <div className={classes.progressItemContainer}>
      <Typography style={{fontSize: 14}}>
        {title}
      </Typography>
      <Typography style={{fontSize: 24}}>
        {children}
      </Typography>
    </div>
  )
}

function ProgressPanel() {
  const {
    contestVisible,
  } = useContests()
  const [totalXP, setTotalXP] = React.useState(0)
  const [totalScore, setTotalScore] = React.useState(0)

  React.useEffect(() => {
    setTotalXP(getTotalXPEarned())
    setTotalScore(getTotalScoreEarnedByMissionPack())
  }, [])

  return (
    <div style={{display: 'flex', gap: 10}}>
      <ProgressContainer title={'Earned XP'}>
        {totalXP}
      </ProgressContainer>
      {!contestVisible &&
      <ProgressContainer title={'Total Score'}>
        {totalScore}
      </ProgressContainer>
      }
      <div style={{flex: 1}}></div>
    </div>
  )
}

function SearchingForAvailableContestsPanel() {
  const { fetchContestsFailed } = useContests()

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems:'center', height: '80%'}}>
      {fetchContestsFailed && <>
        <Typography>Initialization failed unexpectedly!</Typography>
        <Typography> Please refresh your browser to try again.</Typography>
      </>}
      {!fetchContestsFailed && <>
        <DialogTitle>{'Fetching contests...'}</DialogTitle>
        <div style={{paddingTop: 0}}>
          <CircularProgress style={{color: 'white'}}/>
        </div>
      </>}
    </div>
  )
}

function ContestHeader() {
  const { fetchContestsInProgress } = useContests()
  return (
    <div style={{display: 'flex', alignItems:'center', gap: 8}}>
      <Typography variant='h6'>
        {'Contests'}
      </Typography>
      {fetchContestsInProgress ? <CircularProgress style={{color: 'white'}} size={13}/>:null}
    </div>
  )
}

function UserProgressDialog() {
  const classes = useStyles()
  const {
    contestsDialogOpen,
    contestsStateInterface,
    contestVisible,
    contestsInitialized,
    indyRegistrationPanelOpen,
  } = useContests()

  return (
    <Dialog
      open={contestsDialogOpen}
      onClose={contestsStateInterface.closeDialog}
      className={classes.dialog}
      // maxWidth={contestVisible ? 'md' : 'sm'}
      maxWidth={'xl'}
      // fullWidth
    >
      <DialogContent style={{minWidth: 600}}>
        {!indyRegistrationPanelOpen ?
          <div>
            <Typography
              variant='h6'
            >
              {'Progress'}
            </Typography>
            <div style={{height: 20}}/>
            <ProgressPanel />
            {contestVisible || !contestsInitialized ?
              <>
                <div style={{height: 30}}/>
                <ContestHeader />
                <div style={{height: 20}}/>
                {!contestsInitialized ?
                  <SearchingForAvailableContestsPanel /> :
                  <ContestPanel />}
              </>:null}
          </div>:
          <>
            <IndyRegistrationPanel />
          </>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={contestsStateInterface.closeDialog}
        >
          {'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { UserProgressDialog }