import { readActiveMissionId, readActiveMissionPackId, setActiveMissionId, setActiveMissionPackId } from '../content-director-store'
import { formatAndSaveMissionPack } from '../../lesson-content/lesson-content-use-cases'
import { setActiveGroupIdInStore } from '../../groups/group-store'
import { genFauxLicense } from '../../groups/group-use-cases/generateDefaultGroups'
import { changeActiveMissionPack } from './changeActiveMissionPack'
import { missionEditorController } from '../../../MissionEditorControl'

async function formatEditorPack({ mission, objective, quiz, question}) {
  const { pack } = await missionEditorController.formatMissionFromEditor(
    mission
  )
  console.log(pack)
  pack.missions[0].flow.forEach((flowItem, idx) => {
    pack.missions[0].flow[idx] = {...flowItem, ...flowItem.obj}
  })
  // newPack.missions = [pack]
  return pack
}

async function syncMissionEditor(editorState) {
  if (!editorState?.pack?.id) {
    return
  }

  const activeMissionPackId = readActiveMissionPackId()
  const activeMissionId = readActiveMissionId()
  const pack = await formatEditorPack(editorState)
  formatAndSaveMissionPack(pack)
  setActiveMissionPackId(pack.id)
  setActiveMissionId(editorState.mission.id)

  if (activeMissionPackId !== pack.id || activeMissionId !== editorState.mission.id){
    const group = genFauxLicense(pack.id, pack.id)
    setActiveGroupIdInStore(group.id)
    changeActiveMissionPack(pack.id)
  }
}

export { syncMissionEditor }