import PeripheralBase from './PeripheralBase'
import { loadGLBWithPhysics, disposePromise } from '../../utils/BabylonUtils'
import BreadboardModel from '../../assets/BreadBoard.glb'

class Breadboard extends PeripheralBase {
  load = async (scene, parent, posVec3, rotVec3) => {
    this.bb = await loadGLBWithPhysics(BreadboardModel, 'Breadboard', {mass: 0, friction: 6.0}, 1.0, posVec3, rotVec3, scene)

    // Must add physics mesh directly to the physics-enabled parent. (i.e. physics doesn't know about child meshes)
    this.bb.parent = parent
  }

  unload = async () => {
    await disposePromise(this.bb)
  }
}

export default Breadboard
