// The Environments we play in, which comprise Lights, Ground, and Obstacles
import { Entity } from '../SimScene'
import { GridMaterial } from '@babylonjs/materials'
import * as BABYLON from '@babylonjs/core'
import { disposePromise } from '../utils/BabylonUtils'
import SumoGarden from './SumoGarden'
import { Classroom, LineSenseArena } from './Classroom'
import Auditorium from './Auditorium'
import { Cafeteria } from './Cafeteria'
import Gymnasium from './Gymnasium'
import { Showroom } from './Showroom'
import Attic from './HauntedHouse/Attic'
import Kitchen from './HauntedHouse/Kitchen'
import Library from './HauntedHouse/Library'
import LivingRoom from './HauntedHouse/LivingRoom'
import FrontPorch from './HauntedHouse/FrontPorch'
import Hallway from './HauntedHouse/Hallway'
// import LineSenseVoid from './LineSenseVoid'

const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7
const OBSTACLE_FRICTION = 0.9

const IMPOSTOR_TYPE = {
  NONE: 0,
  BOX: 1,
  CYLINDER: 2,
  SPHERE: 3,
  PLANE: 4,
  CAPSULE: 5,
}

// Make this enum immutable
Object.freeze(IMPOSTOR_TYPE)

class SimLights extends Entity {
  load = async (scene) => {
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    await new Promise((resolve, reject) => {
      try {
        this.light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(0, 1, 0), scene)
        this.light.intensity = 0.7
        // TODO: how to hook light.onReady callback? Never seems to be called.
        resolve()
      } catch (err) {
        return reject(err)
      }
    })
  }

  unload = async () => {
    await disposePromise(this.light)
  }
}

class SimGround extends Entity {
  initBorder = (border, x, z, rot) => {
    border.position.y = .125
    border.position.x = x
    border.position.z = z
    border.rotation.y = rot
    border.physicsImpostor = new BABYLON.PhysicsImpostor(border, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: OBSTACLE_FRICTION })
    border.parent = this.ground
    // border.checkCollisions = true  // For mesh, not physics collisions
  }

  load = (scene) => {
    this.ground = BABYLON.Mesh.CreateGround('ground', 10, 10, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions

    this.ground.material = new GridMaterial('groundMaterial', scene)
    // localAxes(3, scene).parent = this.ground

    const borderXpos = BABYLON.MeshBuilder.CreateBox('', { width: 0.1, height: 0.25, depth: 10 }, scene)
    const borderMaterial = new BABYLON.StandardMaterial('BorderMaterial', scene)
    borderMaterial.diffuseColor = new BABYLON.Color3(0.5, 0.5, 0.5)
    borderXpos.material = borderMaterial

    const borderXneg = borderXpos.instantiateHierarchy(null)
    const borderZpos = borderXpos.instantiateHierarchy(null)
    const borderZneg = borderXpos.instantiateHierarchy(null)
    this.initBorder(borderXpos, 4.95, 0, 0)
    this.initBorder(borderXneg, -4.95, 0, 0)
    this.initBorder(borderZpos, 0, 5, Math.PI / 2)
    this.initBorder(borderZneg, 0, -5, Math.PI / 2)
    this.ground.freezeWorldMatrix()
    borderXpos.freezeWorldMatrix()
    borderXneg.freezeWorldMatrix()
    borderZpos.freezeWorldMatrix()
    borderZneg.freezeWorldMatrix()
    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
  }

  unload = async () => {
    await disposePromise(this.ground)
  }
}

class SimObstacles extends Entity {
  load = (scene) => {
    // Create obstacles
    this.ob1 = BABYLON.MeshBuilder.CreateBox('', { width: 2.5, height: 0.1, depth: 1.5 }, scene)
    this.ob1.position.z = 3
    this.ob1.rotation.set(0, 0, Math.PI / 9)
    this.ob1.physicsImpostor = new BABYLON.PhysicsImpostor(this.ob1, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: OBSTACLE_FRICTION })
    this.ob1.freezeWorldMatrix()
  }

  unload = async () => {
    await disposePromise(this.ob1)
  }
}

class VoidMatrix extends Entity {
  constructor() {
    super()
    this.name = 'Void Matrix'
    this.lights = new SimLights()
    this.ground = new SimGround()
    this.obstacles = new SimObstacles()
  }
  load = scene => Promise.all([
    scene.autoClear = true, // Required for transparent background
    scene.autoClearDepthAndStencil = true,
    this.lights.load(scene),
    this.ground.load(scene),
    this.obstacles.load(scene),
  ])
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.ground.unload(),
      this.obstacles.unload(),
    ])
  }
}

// Note: Curriculum content depends on the order of this list. DO NOT CHANGE ORDER.
export const environmentList = [
  new VoidMatrix(),
  new SumoGarden(),
  new Classroom(),
  new Auditorium(),
  new Cafeteria(),
  new Gymnasium(),
  new LineSenseArena(),
  new Showroom(),
  new FrontPorch(),
  new LivingRoom(),
  new Kitchen(),
  new Hallway(),
  new Library(),
  new Attic(),
  // new LineSenseVoid(),
]
