const store = {}

function setContestInStore(contest) {
  store[contest.id] = contest
}

function getContestByIdFromStore(contestId) {
  return store[contestId]
}

function getContestsFromStore() {
  return Object.values(store)
}

export { setContestInStore, getContestByIdFromStore, getContestsFromStore }