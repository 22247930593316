/* CodeXModelController: In main thread, uses shared memory to let a worker thread interface to a CodeBot model.
   * The worker is started/terminated for each run.
*/

/* eslint-disable no-undef */
import * as BABYLON from '@babylonjs/core'
import CodeXHWschema from './CodeXHWschema'
// import { getPickedColor, PerformanceTicker, radAdd, radDiff } from '../utils/BabylonUtils'
import { PerformanceTicker } from '../utils/BabylonUtils'
import { simScene } from '../SimScene'
import { UartCmd, UARTBuffer } from '../Busio'
import { validatorController } from '../ValidatorControl'

export default class CodeXModelController {
  // Control a CodeBotModel via SharedArrayBuffer representing HW state
  // An instance of this class persists while the CodeBot model exists.
  // With just a single 'bot in the first version of botsim, there's a singleton instance of this.
  constructor(model) {
    this.model = model
    this.scene = null
    this.createSharedArrayBuffer()
    this.userCodeIsRunning = false
    this.modelEventObservable = new Set()   // add/delete callbacks here: observer(changes, current)
    this.modelTrackChanges = new Map()      // collects changed values each tick, used to notify observers and update cached values.
    this.modelCollisionObservable = new Set()
    this.modelReady = false

    this.model.loadedObservable.add(this.modelLoadedObserver)
    simScene.pointerObservable.add(this.simPointerObserver)
    simScene.keyboardObservable.add(this.simKeyboardObserver)

    this.ctrKeyDown = false

    this.lastAudioUpdateMs = 0

    this.resetCachedValues()
    this.perf = new PerformanceTicker()
    this.uartBuffers = {}
  }

  // At program startup we want a fresh clean sharedArry.
  // See also recreateSharedArrayBuffer()
  createSharedArrayBuffer = () => {
    this.sharedArray = new SharedArrayBuffer(1024)
    this.useSharedArrayBuffer()
  }

  // We don't want to risk our sharedArray getting written to by a PREVIOUS worker thread
  // who is somehow still running (zombie?), BUT we also need to maintain CodeBot state
  // BETWEEN program runs. So, we switch to a COPY of the previous sharedArray
  // Assumption - That the docs are telling the truth:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer
  recreateSharedArrayBuffer = () => {
    this.sharedArray = this.sharedArray.slice()
    this.useSharedArrayBuffer()
  }

  useSharedArrayBuffer = () => {
    // initArrayView requires the sharedArray instance, meaning we need to re-init codebot schema
    this.hw = new CodeXHWschema(this.sharedArray)
  }

  modelLoadedObserver = (scene) => {
    if (scene) {
      this.scene = scene

      // In case model is re-loaded while code is active, invalidate cache so it gets full update of state.
      this.resetCachedValues()

      if (this.model.doPhysics) {
        const body = this.model.cxBody.physicsImpostor

        // Hook Collisions with designated objects in current scene
        // Physics colliders require list of candidate objects to collide with
        // Assume scene colliders already loaded.
        const meshes = scene.getMeshesByTags('BotCollider')

        if (meshes.length > 0) {
          const impostorCandidateList = meshes.map(mesh => mesh.physicsImpostor)
          body.registerOnPhysicsCollide(impostorCandidateList, (main, collided) => {
            this.onCollide(collided)
          })
        }

        // Hook functions to check sensors after before and after physics calculations
        body.registerAfterPhysicsStep(this.onAfterPhysicsStep)
        body.registerBeforePhysicsStep(this.onBeforePhysicsStep)


        // Explicitly unregistering these physics callbacks since we have seen errors where they're being called
        // apparently after unload (cbBody is null)  https://rollbar.com/firia/BotSim/items/629/
        this.model.cxBody.onDisposeObservable.add((cxBody) => {
          const imp = cxBody.physicsImpostor
          if (imp) {
            console.warn('Caught a case where physicsImposter was not disposed.')
            imp.unregisterAfterPhysicsStep(this.onAfterPhysicsStep)
            imp.unregisterBeforePhysicsStep(this.onBeforePhysicsStep)
          }
        })
      }

      // Model is loaded and ready
      this.modelReady = true
    } else {
      // Model is unloaded from scene
      this.modelReady = false

      // Collisions are registered to model objects that already get disposed on unload.
    }
  }

  onCollide = (imposter) => {
    this.notifyCollisionObservers(imposter.object)

    // A callback can be attached to a mesh for mutual notification.
    // This allows objects to react to bot collision independent of being hooked into the controller.
    if (imposter.object.botCollision) {
      imposter.object.botCollision()
    }
  }

  uartCmd = (cmd, id, data) => {
    validatorController.handleUARTCmd(cmd, id, data)
    switch (cmd) {
      case UartCmd.INIT: // obj {tx, rx, baudrate, bits, parity, stopBits, timeout, rxBufSize}
        this.uartBuffers[id] = new UARTBuffer(data, this.model.peripherals, this.hw)
        break
      case UartCmd.DEINIT: // null
        delete this.uartBuffers[id]
        break
      default:
        this.uartBuffers[id].cmd(cmd, data)
        break
    }
  }

  // callback overridden by CodeRunner
  onReboot = () => {}

  // Pointer down event handler
  pointerDown = (pointerInfo) => {
    // Handle CodeBot button down events
    // First check to see if we have a hit
    if (pointerInfo.pickInfo.hit) {
      // If pointer is down on a button then set the button's on state
      // Set both is_pressed and was_pressed bits
      if (pointerInfo.pickInfo.pickedMesh.name === 'Cylinder.006') {
        // Atomics.store(this.hw.button, LEFT, 3)
      } else if (pointerInfo.pickInfo.pickedMesh.name === 'Cylinder.004') {
        // Atomics.store(this.hw.button, RIGHT, 3)
      }
      // TODO: hook up the reboot button to onReboot here
    }
  }

  // Pointer up event handler
  pointerUp = (pointerInfo) => {
    // Handle CodeBot button up events
    // Button bitmask:
    //   bit 0 = is_pressed
    //   bit 1 = was_pressed

    // Use the Control key to simulate pressing both buttons at the same time
    if (this.ctrKeyDown === false) {
      // Clear is_pressed bit
      // Atomics.and(this.hw.button, LEFT, 2)
      // Atomics.and(this.hw.button, RIGHT, 2)
    }
  }

  // Pointer move event handler
  pointerMove = (pointerInfo) => {
  }

  // Pointer wheel event handler
  pointerWheel = (pointerInfo) => {
  }

  // Pointer pick event handler
  pointerPick = (pointerInfo) => {
  }

  // Pointer tap event handler
  pointerTap = (pointerInfo) => {
  }

  // Pointer double tap event handler
  pointerDoubleTap = (pointerInfo) => {
  }

  // SimScene pointer event observer handler
  simPointerObserver = (pointerInfo) => {
    // Switch on the pointer event type (DOWN, UP, MOVE etc.)
    switch (pointerInfo.type) {
      case BABYLON.PointerEventTypes.POINTERDOWN:
        // console.log("POINTER DOWN");
        this.pointerDown(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERUP:
        // console.log("POINTER UP");
        this.pointerUp(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERMOVE:
        // console.log("POINTER MOVE");
        this.pointerMove(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERWHEEL:
        // console.log("POINTER WHEEL");
        this.pointerWheel(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERPICK:
        // console.log("POINTER PICK");
        this.pointerPick(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERTAP:
        // console.log("POINTER TAP");
        this.pointerTap(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
        // console.log("POINTER DOUBLE-TAP");
        this.pointerDoubleTap(pointerInfo)
        break
      default:
        // this.console.log("Unknown pointer event type")
        break
    }
  }

  // Keyboard key down event handler
  keyboardKeyDown = (kbInfo) => {
    if (kbInfo.event.key === 'Control') {
      this.ctrKeyDown = true
    } else if (kbInfo.event.key === '0') {
      // Atomics.store(this.hw.button, LEFT, 3)
    } else if (kbInfo.event.key === '1') {
      // Atomics.store(this.hw.button, RIGHT, 3)
    }
  }

  // Keyboard key up event handler
  keyboardKeyUp = (kbInfo) => {
    if (kbInfo.event.key === 'Control') {
      this.ctrKeyDown = false
    } else if (kbInfo.event.key === '0') {
      // Atomics.and(this.hw.button, LEFT, 2)
    } else if (kbInfo.event.key === '1') {
      // Atomics.and(this.hw.button, RIGHT, 2)
    }
  }

  // SimScene keyboard event observer handler
  simKeyboardObserver = (kbInfo) => {
    switch (kbInfo.type) {
      // Switch on the keyboard event type (DOWN and UP)
      case BABYLON.KeyboardEventTypes.KEYDOWN:
        // console.log("KEY DOWN: ", kbInfo.event.key);
        this.keyboardKeyDown(kbInfo)
        break
      case BABYLON.KeyboardEventTypes.KEYUP:
        // console.log("KEY UP: ", kbInfo.event.keyCode);
        this.keyboardKeyUp(kbInfo)
        break
      default:
        // console.log("Unknown keyboard event type")
        break
    }
  }

  onBeforePhysicsStep = (imposter) => {
    // Physics are about to be calculated, so we need to get the latest changes from worker that would
    // affect physics.
    // This gives the worker the max amount of time to respond to sensor changes (updated AfterPhysicsStep).
    // Any earlier wouldn't matter from the physics standpoint. However, if physics ticks are slow e.g. 33ms (30fps)
    // then we're limiting stuff like LEDs to a bit slower than needed. But if physics is running that slowly, then
    // probably you're on an underpowered machine, and thankful not to burn cycles on LEDs.
    this.updateFromWorker()
  }

  onAfterPhysicsStep = (imposter) => {
    // Called after each physics engine step. Check sensors.

    /* Currently checking all sensors every tick...
      * Considered Changing as Follows:
          Only check a sensor when user code has an outstanding read() operation on it.
          E.g. we only do raycast AFTER user invokes ls.read(), so a user call to ls.read()
          will yield until the next physics update.
          Benefits of this approach:
            1. Avoids performance hit of proactively checking all sensors every physics update.
            2. More realistic that Python code incurs delay in reading sensors, versus instantaneous return.
            3. Python code is awakened in sync with physics update, so can act immediately when new position
                is known rather than acting asynchronously on stale data.

          The above could be implemented by using the sleep mutex and adding a call 'requestUpdate' which the worker
          thread would invoke to register interest in a sensor value prior to taking the mutex. Then onPhysicsStep() would
          just have to read the sensors which had been requested, and give the mutex.

          Drawback of this approach:
          1. Physics update rate on a slow machine might be 30 Hz at best, so 33ms between updates. That's
              much larger a delay than sensor reads, and would unduly slow code execution.
    */

    // DEBUG: Track physics update rate
    // Note: With default engine options (see SimScene.js) this is 30/s. It can be increased to, say, 100/s
    //       even on a Chromebook (independent of graphics fps). Chromebooks struggle at that rate though.
    // if (this.perf.tick()) {
    //   console.log(`PhysicsStep: ${Math.round(this.perf.hz)}Hz`)
    // }

    // Update atomics so worker thread sees physics changes ASAP
    this.updatePhysicsToWorker()
  }

  updateAudioPosition = (updateTimeMs) =>{
    if (updateTimeMs - this.lastAudioUpdateMs > 100) {
      this.lastAudioUpdateMs = updateTimeMs

      // Work around audio attachToMesh() problem in Babylon.js
      // https://forum.babylonjs.com/t/attaching-audio-to-a-mesh-seems-to-be-broken-in-latest/25469
      // https://playground.babylonjs.com/#EDVU95#37
      /*
      if (this.model.speakerSound) {
        const speakerSound = this.model.speakerSound
        speakerSound._soundPanner.setPosition(this.model.cxBody.position.x, this.model.cxBody.position.y, this.model.cxBody.position.z)
      }
      */
    }
  }

  notifyCollisionObservers = (mesh) => {
    this.modelCollisionObservable.forEach(observer => observer(mesh))
  }

  notifyModelEventObservers = () => {
    // Observers receive change map AND 'this' prior to updating with changed values.
    this.modelEventObservable.forEach(observer => observer(this.modelTrackChanges, this))
  }

  resetCachedValues = () => {
    // Cached values
    // this.speakerDuty = null
    // this.speakerFreq = null
  }

  startupActions = (resetHardware=true) => {
    // Conditionally initialize stuff when bot program starts
    // Turn off LEDs, motors, speaker
    if (resetHardware) {
      // Atomics.store(this.hw.speaker, 0, 0)
      // Atomics.store(this.hw.speaker, 1, 0)
    }
    this.userCodeIsRunning = true
    this.resetCachedValues()
  }

  terminalActions = () => {
    // Shut down stuff when bot program terminates (motors, speaker)
    this.userCodeIsRunning = false
    if (this.model.isLoaded) {
      // this.model.setSpeaker(0, 0)
    }

    // Ensure the HWSchema gets updated too - the normal datapaths
    // won't work because we have shutdown the simulated program
    // Atomics.store(this.hw.speaker, 0, 0)
    // Atomics.store(this.hw.speaker, 1, 0)
  }

  updatePhysicsToWorker = async () => {
    // Update worker thread based on physics-driven changes detected in main thread.
    // Non physics-driven changes such as button-presses are updated in other event-handlers as appropriate.

    // If BabylonJS physics is configured with a nonzero SubTimeStep (see SimScene.js) then what we really want is to
    // be notified when all the substeps are done. Unfortunately Babylon doesn't provide begin/end callbacks bracketing
    // the whole "subtime" loop. (it's in scene's advancePhysicsEngineStep() function BTW)
    // So here we peek at the _physicsTimeAccumulator to only perform updates when the final SubTimeStep has been run.
    const subTime = this.scene._physicsEngine.getSubTimeStep()
    if (subTime !== 0 && this.scene._physicsTimeAccumulator > 2 * subTime) {
      // We've completed a SubTimeStep, but not the last one.
      return
    }

    // Store the physics timestamp (microseconds) before all other atomic storage
    //   - Would like to use high-resolution timer (performance.now) but due to security issues it is de-synchronized between
    //     main and worker threads. For now use plain-old Date.now() to interpolate between samples.
    // const updateTime = Date.now()
    // Note: defer updating Atomic physicsTimestamp until after fast-sensor values are stored (see below)

    // Also grab Babylon's deltaTime, which seems to be a more accurate measure of elapsed time from physics engine's perspective.
    // const deltaTime = this.scene.getEngine().getDeltaTime()
    // Atomics.store(this.hw.physicsDeltaTime, 0,  BigInt(Math.round(deltaTime * 1000)))

    // const encVal = this.readEncoder()
    // Check encoder sensors [leftRot, leftAngularVel, rightRot, rightAngularVel]
    /*
    for (let i = 0; i < 4; ++i) {
      Atomics.store(this.hw.encSense, i, encVal[i])
    }
    */

    // Check accelerometer [x, y, z]
    // const accVal = this.readAccel()
    // for (let i = 0; i < 3; ++i) {
    //   Atomics.store(this.hw.accel, i, accVal[i])
    // }

    // Store the physicsTimestamp update after non-promise-based sensor data, so we can key off timestamp change to validate new data values.
    // Atomics.store(this.hw.physicsTimestamp, 0,  BigInt(Math.round(updateTime * 1000)))

    // Workaround for Babylon.js audio bug
    // this.updateAudioPosition(updateTime)
  }

  updateFromWorker = () => {
    // Update cxModel in main thread with any changes made by worker thread to CodebotHWschema

    if (!this.userCodeIsRunning || !this.model.isLoaded || !this.model.doPhysics) {
      return
    }

    const changes = this.modelTrackChanges
    changes.clear()

    /* const curSpeakerFreq = Atomics.load(this.hw.speaker, 0)
    if (curSpeakerFreq !== this.speakerFreq) {
      changes.set('speakerFreq', curSpeakerFreq)
    }

    const curSpeakerDuty = Atomics.load(this.hw.speaker, 1)
    if (curSpeakerDuty !== this.speakerDuty) {
      changes.set('speakerDuty', curSpeakerDuty)
    }

    if (changes.has('speakerFreq') || changes.has('speakerDuty')) {
      this.model.setSpeaker(curSpeakerFreq, curSpeakerDuty)
    }
    */

    // Track accumulated changes
    if (changes.size > 0) {
      this.notifyModelEventObservers()
      const changeObj = Object.fromEntries(changes)
      Object.assign(this, changeObj)
    }
  }
}