import { getContestsFromStore } from '../contest-store'
import { putContestSubmissionInCloud } from '../contest-gateways'
import { getActiveMissionPack } from '../../content-director/content-director-use-cases/getActiveMissionPack'
import { getActiveMission } from '../../content-director/content-director-use-cases/getActiveMission'
import { getActiveContentSequenceItem } from '../../content-director/content-director-use-cases/getActiveContentSequenceItem'
import { contestIsActive } from './contestIsActive'
import { fetchContests } from './fetchContests'

async function submitScores() {
  const activeMissionPack = getActiveMissionPack()
  const activeMission = getActiveMission()
  const activeContentSequenceItem = getActiveContentSequenceItem()

  const contests = getContestsFromStore()
  const activeContests = contests.filter(contest => contestIsActive(contest.id))
  const submittableContests = activeContests.filter(contest => contest.missionPackId === activeMissionPack.id)
  if (submittableContests.length === 0) {
    return
  }

  const newHighScore = await putContestSubmissionInCloud(activeMissionPack.id, activeMission.id, activeContentSequenceItem.flow.id)
  if (newHighScore) {
    fetchContests()
  }
}

export { submitScores }