const store = {}

class UserFileStore {
  setSavingDisabled(savingDisabled) {
    store.setSavingDisabled = savingDisabled
  }

  savingIsDisabled() {
    return store?.setSavingDisabled
  }
}

const userFileStore = new UserFileStore()
export { userFileStore }