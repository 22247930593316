import { readSharedFileFromRepo } from '../code-file-store'

function isFileShared(fileId) {
  try {
    return !!readSharedFileFromRepo(fileId)
  } catch (err) {
    //
  }
}

export { isFileShared }