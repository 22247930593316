import { readMissionProgressFromUserProgressStore } from '../../user-progress/user-progress-store'
import { getContestsFromStore } from '../contest-store'
import { getActiveGroup } from '../../groups/group-use-cases/getActiveGroup'

function getActiveContestStartTs() {
  const contests = getContestsFromStore()
  const activeGroup = getActiveGroup()
  if (!activeGroup) {
    return
  }
  const { missionPackId } = activeGroup

  for (const contest of contests) {
    if (!contest.active || contest.missionPackId !== missionPackId) {
      continue
    }

    return new Date(contest.start).getTime()
  }
}

function missionWasStartedBeforeContestStarted(missionId) {
  const contestStartTs = getActiveContestStartTs()
  if (!contestStartTs) {
    return
  }

  const missionProgress = readMissionProgressFromUserProgressStore(missionId)
  if (!missionProgress) {
    return
  }

  return new Date(missionProgress.started).getTime() < contestStartTs
}

// function flowWasStartedBeforeContestStarted(flowId) {
//   const contestStartTs = getActiveContestStartTs()
//   const flowProgress = readFlowProgressFromUserProgressStore(flowId)
//   return contestStartTs && new Date(flowProgress.started).getTime() < contestStartTs
// }

// function missionCompletionIsErasedByContest(missionId) {

// }

// function flowCompletionIsErasedByContest(flowId) {

// }

export { missionWasStartedBeforeContestStarted }