import { Entity } from '../../SimScene'

class PeripheralBase extends Entity {
  hasUart = () => {
    return false
  }
  uartWrite = (pin, baudrate, bits, parity, stopBits, data) => {}
  load = async (scene, parent, posVec3, rotVec3) => {} // YOU MUST OVERRIDE THIS
  unload = async () => {} // YOU MUST OVERRIDE THIS

  name = () => {
    // Can override, but ID defaults to derived class name
    return this.constructor.name
  }
}

export default PeripheralBase