import React from 'react'
import { Entity /* simScene*/ } from './SimScene'

class StatsProbe extends Entity {
  // Consider also using BABYLON.SceneInstrumentation
  constructor() {
    super()
    this.fps = 0
  }
    render = (scene) => {
      this.fps = scene.getEngine().getFps()
    }
}
const statsProbe = new StatsProbe()
// simScene.addEntity(statsProbe)

export function FpsDisplay() {
  const [fpsState, setFps] = React.useState(0)

  React.useEffect(() => {
    const interval = setInterval(() => {
      setFps(statsProbe.fps.toFixed())
    }, 100)
    return () => clearInterval(interval)
  }, [])

  return (
    <div style={{ position: 'absolute', fontSize: 16, color: 'red', top: 15, right: 10, zIndex:1000 }}>{fpsState}</div>
  )
}