import { toolboxItemsFromFlowItem } from './ripToolboxContent'
import { getTool } from '../../../Toolbox/ToolboxUtil'

const ripLessonContent = ({ addTask, addSpacer,  packs }) => {
  function addClear() {
    addTask('\n <div style="clear:both"/> \n')
  }
  function addBreakAfter() {
    addTask('\n <div style="break-after: page"/> \n')
  }
  packs.forEach((pack, packIdx) => {
    addTask(`\n# <u>${pack.title}</u>`)
    addTask(pack.brief)
    addSpacer(3)
    pack.missions.forEach((mission, missionIdx) => {
      if (missionIdx !== 0) {
        addBreakAfter()
      }
      addTask(`\n## <u>***Mission ${missionIdx+1}*** - ${mission.title}</u>`)
      addTask(mission.overview)
      addClear()
      addSpacer()
      var quizCount = 0
      mission.flow.forEach((flowItem, flowIdx) => {
        addSpacer(1)
        const formatTitle = (index) => {
          addTask(`\n### <u>***${flowItem.type.charAt(0).toUpperCase() + flowItem.type.slice(1)} ${index}*** - ${flowItem.obj.title}</u>`)
        }

        // HANDLE QUIZES
        if (flowItem.type === 'quiz'){
          quizCount += 1
          formatTitle(quizCount)
          if (flowItem.obj.overview) {
            addTask(flowItem.obj.overview)
          }
          addTask(flowItem.obj, 'quiz')
          addSpacer(1)
        // HANDLE OBJECTIVES
        } else {
          formatTitle(flowIdx + 1 - quizCount)
          addTask(flowItem.obj.instructions)

          // HANDLE CODETREK
          if (flowItem.obj?.code) {
            addTask({code: flowItem.obj.code, codesteps: flowItem.obj?.codesteps}, 'codeTrek')
          }

          // HANDLE HINTS
          if (flowItem.obj?.hints && flowItem.obj?.hints.length !== 0){
            addTask(flowItem.obj.hints, 'hints')
          }

          // HANDLE GOALS
          addTask(flowItem.obj.goals, 'goals')

          // HANDLE TOOLS FOUND
          const toolboxItems = toolboxItemsFromFlowItem({ flowItem })
          if (toolboxItems.length > 0) {
            addTask([...new Set(toolboxItems.map(tool => getTool(tool)?.name))], 'toolsFound')
          }

          // HANDLE SOLUTIONS
          if (flowItem.obj.solution){
            addTask(flowItem.obj.solution, 'solution')
          } else {
            addTask('### Solution: \n*N/A*\n')
          }
        }
        addSpacer(1)
        addClear()
      })

      addTask(`\n### <u>***Mission ${missionIdx+1} Complete*** </u>`)
      addTask(mission.complete)
    })
  })
}

export default ripLessonContent