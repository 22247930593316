import { getNotificationsFromCloud, postNotificationStatusToCloud } from './notification-gateway'
import { setNotificationsInStore, setNotificationInStore, readNotificationFromStore, setNotificationsStoreDefaults, readNotificationsFromStore } from './notification-store'
import {
  presentNotifications,
  presentFetchNotificationsResponseFailed,
  presentFetchNotificationsResponseStarted,
  presentFetchNotificationsResponseSucceeded,
} from './notification-presenter'
import { pollerController } from '../utils/poller-controller'

async function fetchNotifications() {
  presentFetchNotificationsResponseStarted()
  var notifications = await getNotificationsFromCloud()
  if (!notifications) {
    presentFetchNotificationsResponseFailed()
  } else {
    presentFetchNotificationsResponseSucceeded()
    notifications = notifications.filter(notif => !!notif.startVisibilityTs)
    notifications.forEach(notif => notif.startVisibilityTs = new Date(notif.startVisibilityTs))
    notifications.forEach(notif => notif.endVisibilityTs = notif.endVisibilityTs ? new Date(notif.endVisibilityTs) : null)

    const now = new Date()
    notifications = notifications.filter((notif) => {
      if (!notif.endVisibilityTs) {
        return true
      }
      return notif.startVisibilityTs <= now && notif.endVisibilityTs >= now
    })
    setNotificationsInStore(notifications.sort((a, b) => b.startVisibilityTs - a.startVisibilityTs))
  }

  presentNotifications()
}
var poller = null
function stopPoller() {
  clearInterval(poller)
  poller = null
}

function startPoller() {
  setTimeout(fetchNotifications, 100)
  stopPoller()
  poller = setInterval(fetchNotifications, 60 * 1000 * 5)
}

const notificationsPoller = {
  start: startPoller,
  stop: stopPoller,
}

pollerController.addPoller('notificationsPoller', notificationsPoller.stop)

function clearNotifications() {
  notificationsPoller.stop()
  setNotificationsStoreDefaults()
  presentNotifications()
}

async function markNotificationsRead(notificationIds) {
  const notifications = notificationIds.map(id => readNotificationFromStore(id))
  const filteredNotifications = notifications.filter(notif => !notif.read)
  if (filteredNotifications.length === 0) {
    return
  }

  await postNotificationStatusToCloud(filteredNotifications.map(notif => notif.id))
  filteredNotifications.forEach(notif => setNotificationInStore({ ...notif, read: true }))
  presentNotifications()

  await fetchNotifications()
}

async function markNotificationRead(notificationId) {
  return await markNotificationsRead([notificationId])
}

async function markAllNotificationsRead() {
  const notifications = readNotificationsFromStore()
  await markNotificationsRead(notifications.map(notif => notif.id))
}

export {
  markAllNotificationsRead,
  fetchNotifications,
  markNotificationRead,
  clearNotifications,
  notificationsPoller,
}