import { initializeGroups } from './groups/group-use-cases/initializeGroups'
import { setLessonContentStoreDefaults } from './lesson-content/lesson-content-store'
import { setGroupsStoreDefaults } from './groups/group-store'
import { setContentDirectorStoreDefaults } from './content-director/content-director-store'
import { setUserProgressStoreDefaults } from './user-progress/user-progress-store'
import { userSessionStore } from './user-session/user-session-store'
import { userSessionActivityPoller } from './user-session/user-session-use-cases/user-activity-poller'
import { userProgressController } from '../UserProgressControl'
import { presentContentDirector, presentUserUI } from './content-director/content-director-presenter'
import { contestsPoller } from './contests/contest-use-cases/fetchContests'
import { notificationsPoller, clearNotifications } from './notifications/notification-use-cases'
import { initializeValidatorRegressionTests } from './lesson-editor/lesson-editor-controllers'
import { fetchLessonContent } from './lesson-content/lesson-content-use-cases'
import { editorMode } from './lesson-editor/lesson-editor-controllers'
import pollSessionIdCookie from '../tools/auth/pollSessionIdCookie'
import { initializeUserProgress } from './user-progress/user-progress-controllers'
import { firstTimeLogin } from './user-session/user-session-use-cases/firstTimeLogin'
import { migrateUserProgressOnFirstTimeLogin } from './user-progress/user-progress-controllers'
import { codeFileController } from './code-files/code-file-controllers'

function handleUserSessionChanged() {
  setLessonContentStoreDefaults()
  setGroupsStoreDefaults()
  setContentDirectorStoreDefaults()
  userSessionStore.setDefaults()
}

async function initializeEditorModeContentManager() {
  initializeValidatorRegressionTests()
  await userProgressController.init(() => {}, () => {}, presentUserUI)
  handleUserSessionChanged()
  clearNotifications()
}

async function initializeContentManager() {
  if (editorMode) {
    initializeEditorModeContentManager()
    return
  }
  pollSessionIdCookie()
}

async function initializeContentManagerUserSession() {
  if (firstTimeLogin()) {
    await migrateUserProgressOnFirstTimeLogin()
  }
  initializeUserProgress()
  notificationsPoller.start()
  userSessionStore.setUserHasLoggedInDuringSession()
  userSessionActivityPoller.start()
  initializeGroups().then(async res => await res())  // Perform in background
  presentContentDirector()
}

async function handleContentManagerOnUserSessionStarted(userSession){
  contestsPoller.start()
  setUserProgressStoreDefaults()
  handleUserSessionChanged()
  userSessionStore.setUserSession(userSession)
  if (userSession?.lastActiveGroup) {
    fetchLessonContent(userSession.lastActiveGroup)
  }
}

async function handleContentManagerOnUserSessionEnded(){
  contestsPoller.start()
  clearNotifications()
  codeFileController.handleUserLoggedOut()
  setUserProgressStoreDefaults()
  await initializeUserProgress()
  userSessionActivityPoller.stop()
  handleUserSessionChanged()
  initializeGroups().then(async res => await res())  // Perform in background
  presentContentDirector()
}

export {
  handleUserSessionChanged,
  initializeContentManager,
  initializeContentManagerUserSession,
  handleContentManagerOnUserSessionStarted,
  handleContentManagerOnUserSessionEnded,
}