import { readActiveMissionPackId } from '../content-director-store'
import { readFetchMissionPackPromiseFromPackStore, readMissionPackStoreByPackId } from '../../lesson-content/lesson-content-store'
import { fetchLessonContent } from '../../lesson-content/lesson-content-use-cases'
import { presentMissionPack } from '../content-director-presenter'
import { setActiveMissionId } from '../content-director-store'

async function loadActiveMissionPack(missionPackId = readActiveMissionPackId()) {
  if (!missionPackId) {
    return
  }

  setActiveMissionId(missionPackId)
  const pack = readMissionPackStoreByPackId(missionPackId)
  // If the pack doesn't exist yet, check for a pending fetch, otherwise start a pull
  if (!pack) {
    const promise = readFetchMissionPackPromiseFromPackStore(missionPackId)
    if (promise) {
      await promise
    } else {
      await fetchLessonContent([missionPackId])
    }
  }

  presentMissionPack(missionPackId)
}

export { loadActiveMissionPack }