// Mission Select Dialog
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {Button,Paper} from '@material-ui/core'

const FIRIA_GREEN_RGB = '76, 175, 80'
const FIRIA_GREEN = 'rgb(' + FIRIA_GREEN_RGB + ')'
const FiriaGreenButtonBase = withStyles(theme => ({
  root: {
    borderRadius: 2,
    color: theme.palette.getContrastText(FIRIA_GREEN),
    backgroundColor: FIRIA_GREEN,
    '&:hover': {
      backgroundColor: '#3d8a40',
    },
  },
}))(Button)

const FiriaGreenButton = (props) => {
  const { paperProps, ...buttonProps  } = props
  return (
    <Paper elevation={5} {...paperProps}>
      <FiriaGreenButtonBase {...buttonProps}>
        {props.children}
      </FiriaGreenButtonBase>
    </Paper>
  )
}

export { FiriaGreenButton, FiriaGreenButtonBase, FIRIA_GREEN_RGB, FIRIA_GREEN }