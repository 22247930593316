const pollers = {}

// Add a poller anywhere in the project, it'll get gracefully disposed of when the app is unmounted
class PollerController {
  addPoller(id, stopPoller){
    if (!pollers[id]) {
      pollers[id] = stopPoller
    }
  }

  stopPollers() {
    Object.values(pollers).forEach(stopPoller => stopPoller())
  }
}


const pollerController = new PollerController()
export {
  pollerController,
}