import authFetchHandler from '../../tools/auth/authFetchHandler'

async function getNotificationsFromCloud() {
  try {
    const resp = await authFetchHandler('notifications',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    const data = await resp.json()
    if (data && resp.status === 200) {
      return data
    }
  } catch (err){
    //
  }
}

async function postNotificationStatusToCloud(notificationIds) {
  try {
    const resp = await authFetchHandler('notifications/status',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notificationIds }),
      })
    return resp.status === 200
  } catch (err){
    //
  }
}

export { getNotificationsFromCloud, postNotificationStatusToCloud }