// Interface to Mission Context
import { MissionActions } from '../../contexts/MissionContext'
import {
  readActiveMissionPackId,
  readActiveMissionId,
  readContentSequence,
  readContentSequenceIndex,
} from './content-director-store'
import { getActiveMissionPack } from './content-director-use-cases/getActiveMissionPack'
import { getActiveMission } from './content-director-use-cases/getActiveMission'
import { getFlowIndexFromContentSequenceIndex } from './content-director-use-cases/getFlowIndexFromContentSequenceIndex'
import { setContentDirectorState } from '../../contexts/ContentDirectorContext'
import { LessonContentViews } from '../../contexts/MissionContext'
import { setMissionState } from '../../contexts/MissionContext'

var missionDispatch = () => {}
function setMissionContextDispatch(missionContextDispatch){
  missionDispatch = missionContextDispatch
}

async function presentMissionInfo() {
  await missionDispatch({ type: MissionActions.CHANGE_VIEW, view: LessonContentViews.MISSION })
}

async function presentProgressionGate(){
  await missionDispatch({ type: MissionActions.VIEW_PROGRESSION_GATE })
}

async function presentActiveMissionPackId() {
  await missionDispatch({ type: MissionActions.SWITCH_MSN_PACK, packId: readActiveMissionPackId() })
}

async function presentActiveMissionId() {
  await missionDispatch({ type: MissionActions.SET_MSN, id: readActiveMissionId() })
}

async function presentMissionPack() {
  await missionDispatch({ type: MissionActions.LOAD_MSN_PACK, pack: getActiveMissionPack(), mission: getActiveMission(), flowIndex: getFlowIndexFromContentSequenceIndex()})
}

async function presentMission() {
  await missionDispatch({ type: MissionActions.LOAD_MSN, mission: getActiveMission(), flowIndex: getFlowIndexFromContentSequenceIndex() })
}

async function presentFlowIndex() {
  await missionDispatch({ type: MissionActions.SET_CURR_FLOW, index: getFlowIndexFromContentSequenceIndex()})
}

async function presentScore(score) {
  await missionDispatch({ type: MissionActions.SET_SCORE, score })
}

async function toggleSubmissionDialog(){
  await missionDispatch({ type: MissionActions.TOGGLE_SUBMISSION_DIALOG })
}

async function closeSubmissionDialog() {
  await missionDispatch({ type: MissionActions.CLOSE_SUBMISSION_DIALOG })
}

async function presentUserUI(userUi) {
  setMissionState((s) => {
    return {
      ...s,
      ui: { ...s.ui, ...userUi.ui },
    }
  })
}

function presentContentDirector() {
  const contentSequence = readContentSequence()
  const contentSequenceIndex = readContentSequenceIndex()
  setContentDirectorState({
    contentSequence,
    contentSequenceIndex,
  })
}

function presentCloseMissionSelect() {
  missionDispatch({ type: MissionActions.SHOW_MSN_SEL, isOpen: false })
}

export {
  setMissionContextDispatch,
  presentFlowIndex,
  presentProgressionGate,
  presentActiveMissionPackId,
  presentActiveMissionId,
  presentContentDirector,
  presentMissionPack,
  presentMission,
  presentMissionInfo,
  toggleSubmissionDialog,
  presentUserUI,
  closeSubmissionDialog,
  presentScore,
  presentCloseMissionSelect,
}