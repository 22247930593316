import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { missionEditorController } from '../MissionEditorControl'
import fetchHandler from '../tools/tracking/fetchHandler'
import { FetchResp } from '../MyClassesDialog/FetchResp'

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  moduleContainer: {
    width: 600,
  },
  footer: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 5,
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid rgba(0,0,0,0.2)',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'none',
    borderRadius: 0,
    height: 50,
    borderBottom: '1px solid rgba(0,0,0,0.3)',
    width: 550,
  },
  buttonGroupContainer: {
    minHeight: 200,
    border: '1px solid rgba(0,0,0,0.3)',
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRadius: 5,
  },
  progressContainer: {
    height: 180,
    width: 550,
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-between',
  },
}))

const CompressPacksButton = ({ pack, packs, open, idx }) => {
  const classes = useStyles()
  const [postStatus, setPostStatus] = React.useState(null)

  const upload = async (pack) => {
    setPostStatus('waiting')
    for (const mission of pack.missions) {
      for (let flow of mission.flow) {
        delete flow.obj.fileBlobs
      }
    }
    const resp = await fetchHandler(`http://localhost:3001/packed/${pack.id}`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(pack),
      }
    )
    if (resp.status === 200) {
      setPostStatus('success')
      return
    }

    setPostStatus('failed')
  }

  function getBorderRadius(idx) {
    if (idx === 0) {
      return {borderTopLeftRadius: 5, borderTopRightRadius: 5}
    }

    if (idx === packs.current.length - 1) {
      return {borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderBottom: 'none'}
    }

    return {borderRadius: 0}
  }


  return (
    <Button
      packs={packs}
      pack={pack}
      variant='text'
      onClick={() => upload(pack)}
      className={classes.button}
      style={getBorderRadius(idx)}
    >
      <div style={{width: 25}}></div>
      {pack.title}
      <div style={{width: 25}}>
        <FetchResp
          fetchStatus={postStatus}
          errorMsg={''}
          open={open}
          setErrorMsg={() => {}}
          setFetchStatus={setPostStatus}
        />
      </div>
    </Button>
  )
}

const CompressPacksDialog = (props) => {
  const classes = useStyles()
  const packs = React.useRef([])
  const [fetchingPacks, setFetchingPacks] = React.useState(false)

  React.useEffect(() => {
    const getPackData = async () => {
      if (fetchingPacks || packs.current.length !== 0){
        return
      }
      setFetchingPacks(true)

      const packIds = (await missionEditorController.getMissionPacks()).data
      const packPromises = packIds.map(packId => missionEditorController.formatEditorMissionPack(packId))
      packs.current = await Promise.all(packPromises)
      setFetchingPacks(false)
    }
    getPackData()
  })

  /*
  const download = (pack) => {
    const filename = `${pack.id}.json`
    const file = new File([JSON.stringify(pack)], filename, {type: 'application/json'})
    const element = document.createElement('a')
    const url = URL.createObjectURL(file)
    element.href = url
    element.download = filename
    document.body.appendChild(element)
    element.click()
    URL.revokeObjectURL(url)
  }
  */

  return (
    <>
      <Dialog
        onClose={props.onClose}
        open={props.open}
      >
        <div className={classes.dialogContainer}>
          <DialogTitle>
            {'Download Compressed Mission Packs'}
          </DialogTitle>
          <DialogContent>
            <div className={classes.buttonGroupContainer}>
              {fetchingPacks ?
                <div className={classes.progressContainer}>
                  <div></div>
                  <CircularProgress style={{color: 'rgba(255,255,255,0.8'}} />
                  <div></div>
                </div> :
                <>
                  {packs.current.map((pack, idx) =>
                    <CompressPacksButton key={pack.id} {...{pack, idx, packs, open: props.open}}/>
                  )}
                </>
              }
            </div>
          </DialogContent>
          <div className={classes.footer}>
            <div></div>
            <Button
              onClick={props.onClose}
              variant='outlined'
              style={{margin: 3}}
            >
              {'Close'}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default CompressPacksDialog
