import React from 'react'
import { Dialog, DialogContent, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { AssignmentDetail } from './AssignmentSubmissionPanel'
import { ValidatedAssignmentSubmissionButton } from './SubmissionButtons'

var handleReminderAssignmentSubmissionDialogVisibility = () => {}
function ReminderAssignmentSubmissionDialog({ completionBannerVisible, handleClose, onBannerContinue }) {
  const [open, setOpen] = React.useState(false)

  handleReminderAssignmentSubmissionDialogVisibility = () => setOpen(true)
  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <div style={{overflow:'hidden'}}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px'}}>
          <Typography variant='h6'>{'You\'ve got an assignment for this objective!'}</Typography>
          <IconButton onClick={() => setOpen(false)} size='small'>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent style={{height: 300, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          <AssignmentDetail />
          <ValidatedAssignmentSubmissionButton />
        </DialogContent>
        <div style={{height: 10}}></div>
      </div>
    </Dialog>
  )
}

export { ReminderAssignmentSubmissionDialog, handleReminderAssignmentSubmissionDialogVisibility }