import React from 'react'
import { PositionedPanel } from './PositionedPanels'
import { useScene, SceneActions } from './contexts/SceneContext'
import hexToRGBA from './hexToRGBA'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles(theme => ({
  closeIcon: {
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  transparent: {
    backgroundColor: hexToRGBA(theme.palette.background.paper, 0.6), // add a little transparency
  },
  slider: {
    marginRight: '1em',
    marginLeft: '0.5em',
    width: '6em',
  },
}))

export function SceneVolume(props) {
  const classes = useStyles()

  const [sceneState, dispatch] = useScene()

  return (
    <PositionedPanel
      style={{ right: 50, bottom: 25, zIndex: 10, maxHeight: '50%', maxWidth: '50%', display: 'flex', flexDirection: 'column', borderRadius:10 }}
      direction={'up'}
      open={sceneState.volumeShow}
      onBackdropClick={() => {
        dispatch({ type: SceneActions.VOLUME_SHOW, shouldShow: false })
      }}
      {...props}
    >
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{margin:'0.3em', width:'3em'}}>Music</span>
          <Slider
            className={classes.slider}
            value={sceneState.volumeParams[0]}
            valueLabelDisplay='auto'
            valueLabelFormat={val => parseInt(val) + '%'}
            onChange={(ev, value) => dispatch({ type: SceneActions.VOLUME_SET, newVolumeParams: [value, sceneState.volumeParams[1]] }) }
          />
        </div>

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{margin:'0.3em', width:'3em'}}>Fx</span>
          <Slider
            className={classes.slider}
            value={sceneState.volumeParams[1]}
            valueLabelDisplay='auto'
            valueLabelFormat={val => parseInt(val) + '%'}
            onChange={(ev, value) => dispatch({ type: SceneActions.VOLUME_SET, newVolumeParams: [sceneState.volumeParams[0], value] }) }
          />
        </div>

      </div>
    </PositionedPanel>
  )
}
