var store = {}

function setNotificationsStoreDefaults() {
  store = {}
}
setNotificationsStoreDefaults()

function readNotificationFromStore(notificationId){
  return store[notificationId]
}

function readNotificationsFromStore() {
  return Object.values(store)
}

function setNotificationsInStore(notifications){
  notifications.forEach((notification) => {
    store[notification.id] = notification
  })
}

function setNotificationInStore(notification){
  store[notification.id] = notification
}

export {
  readNotificationFromStore,
  readNotificationsFromStore,
  setNotificationsInStore,
  setNotificationInStore,
  setNotificationsStoreDefaults,
}