// User Settings Panel

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { FormControlLabel } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import { setVolume } from './AudioControls'
import { set3dSound } from './AudioControls'
import Grid from '@material-ui/core/Grid'
import Slider from '@material-ui/core/Slider'
import VolumeDown from '@material-ui/icons/VolumeDown'
import VolumeUp from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import FormHelperText from '@material-ui/core/FormHelperText'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Button from '@material-ui/core/Button'
import MessageDialog from './Dialogs'
import { useUserConfig, UserConfigActions } from './contexts/UserConfigContext'
import { useScene, SceneActions } from './contexts/SceneContext'

const useStyles = makeStyles(theme => ({
  settingsPanel: {
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '90%',
    // width: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  settingBox: {
  },
  button: {
    maxWidth: 100,
  },
}))

export default function UserSettings() {
  const classes = useStyles()
  const [userConfigState, userConfigDispatch] = useUserConfig()
  const [soundVolume, setSoundVolume] = React.useState(
    userConfigState.soundVolume * 100
  )
  const [spatialSound, setSpatialSound] = React.useState(
    userConfigState.spatialSound
  )
  const [confirmDeleteAll, setConfirmDeleteAll] = React.useState(false)
  const [, sceneDispatch] = useScene()

  const handleSoundVolumeChange = (event, newValue, saveNow) => {
    setSoundVolume(newValue)
    const newVolume = newValue / 100
    setVolume(newVolume)
    if (saveNow) {
      userConfigDispatch({ type: UserConfigActions.VOLUME_SET, soundVolume: newVolume })
    }
  }

  const handleConfirmedDeleteAll = async () => {
    setConfirmDeleteAll(false)
    await userConfigDispatch({ type: UserConfigActions.DELETE_ALL })

    // Reload browser window
    window.location.reload(true)
  }

  const handle3dSoundToggle = (event, newValue) => {
    sceneDispatch({ type: SceneActions.ENVIRONMENT_RESET })
    setSpatialSound(newValue)
    set3dSound(newValue)
    userConfigDispatch({ type: UserConfigActions.SPATIAL_SOUND_SET, spatialSound: newValue })
  }

  return (
    <div className={classes.settingsPanel}>
      <p><strong>NOTE</strong>: Changes may result in 3D environment reset</p>
      <Grid container wrap='wrap' spacing={3}>
        <Grid item xs className={classes.settingBox}>
          <FormControl className={classes.formControl}>
            <InputLabel>Language</InputLabel>
            <Select
              value={userConfigState.codeLanguage}
              onChange={(event) => {
                const lang = event.target.value
                userConfigDispatch({ type: UserConfigActions.LANGUAGE_SET, codeLanguage: lang })
                // Probably not the proper way to change Monaco editor language... works for now.
                window.monaco.editor.setModelLanguage(window.monaco.editor.getModels()[0], lang)
              }}
            >
              <MenuItem value={'python'}>
                Python
              </MenuItem>
              {/* <MenuItem value={'javascript'}>
                                Javascript
              </MenuItem> */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs className={classes.settingBox}>
          <FormControl className={classes.formControl}>
            <InputLabel>Theme</InputLabel>
            <Select
              value={userConfigState.theme}
              onChange={(event) => {
                userConfigDispatch({ type: UserConfigActions.THEME_SET, theme: event.target.value })
              }}
            >
              <MenuItem value={'dark'}>
                Dark
              </MenuItem>
              <MenuItem value={'light'}>
                Light
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs className={classes.settingBox}>
          <FormControl className={classes.formControl}>
            <FormHelperText>Sound Effects</FormHelperText>
            <Grid container spacing={2}>
              <Grid item>
                {soundVolume ?
                  <VolumeDown onClick={() => handleSoundVolumeChange(0, 0, true)} /> :
                  <VolumeOffIcon />
                }
              </Grid>
              <Grid item xs>
                <Slider
                  value={soundVolume}
                  onChange={handleSoundVolumeChange}
                  onChangeCommitted={(_, newValue) => {
                    userConfigDispatch({ type: UserConfigActions.VOLUME_SET, soundVolume: newValue / 100 })
                  }}
                />
              </Grid>
              <Grid item>
                <VolumeUp onClick={() => handleSoundVolumeChange(0, 100, true)} />
              </Grid>
            </Grid>
            <FormControlLabel className={classes.formControl} onChange={handle3dSoundToggle} control={<Checkbox color='default' checked={spatialSound} />} label='3D Sound' />
          </FormControl>
        </Grid>
        <Grid item xs className={classes.settingBox}>
          <FormControl className={classes.formControl}>
            <FormHelperText>Delete Saved Progress</FormHelperText>
            <Button
              onClick={() => setConfirmDeleteAll(true)}
              startIcon={<DeleteForeverIcon />}
              size='small'
              className={classes.button}
            >
              Delete
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <MessageDialog
        open={confirmDeleteAll}
        title='REMOVE ALL PROGRESS?'
        onClose={async () => await setConfirmDeleteAll(false)}
      >
        <p>This will remove all your saved progress.</p>
        <p><em>Are you sure you want to delete it and start over?</em></p>
        <div style={{ display: 'flex' }}>
          <Button
            onClick={handleConfirmedDeleteAll}
            variant='contained'
            style={{ marginLeft: 'auto' }}
          >
            DELETE
          </Button>
          <Button
            onClick={() => setConfirmDeleteAll(false)}
            variant='outlined'
            style={{ marginLeft: '1em' }}
          >
            CANCEL
          </Button>
        </div>
      </MessageDialog>

    </div>
  )
}
