import authFetchHandler from '../../tools/auth/authFetchHandler'
import { readMissionPackIdsFromMissionPackStore, readMissionPackStoreByPackId, readMissionStoreByMissionId } from '../lesson-content/lesson-content-store'

function decodeLeaderboardName(leaderboardName) {
  if (leaderboardName === 'default_band-2511-42b5-9a00-ca066cbd01d7') {
    return 'Default'
  }

  if (leaderboardName === 'firia') {
    return 'Firia'
  }

  const girlsWhoCodeTag = '_girlswhocode'
  if (leaderboardName.endsWith(girlsWhoCodeTag)) {
    return 'Girls Who Code ' + decodeLeaderboardName(leaderboardName.split(girlsWhoCodeTag)[0])
  }

  if (leaderboardName === 'indy') {
    return 'Indy'
  }

  if (leaderboardName === 'K-5') {
    return 'Elementary (K-5)'
  }

  if (leaderboardName === '6-8') {
    return 'Middle School (6-8)'
  }

  if (leaderboardName === '9-12') {
    return 'High School (9-12)'
  }

  return leaderboardName
}

// function formatContestFlowLeaderboard(flowId, leaderboard) {
//   const flow = readFlowStoreByFlowId(flowId)
//   if (!flow) {
//     return {}
//   }

//   return { id: flowId, name: flow.obj.title, leaderboard: leaderboard[flowId], flow}
// }

function formatContestMissionLeaderboard(missionId, leaderboard) {
  const mission = readMissionStoreByMissionId(missionId)
  if (!mission) {
    return {}
  }

  return { id: missionId, name: mission.title, avatarBlob: mission.avatarBlob, leaderboard: leaderboard[missionId]} // , children: mission.flow.filter(flowItem => !!leaderboard[flowItem.id]).map(flowItem => formatContestFlowLeaderboard(flowItem.id, leaderboard))}
}

function formatContestPackLeaderboard(packId, leaderboard, packsFetched=true) {
  if (!packsFetched) {
    // We need to wait for ALL the packs to be fetched before we can display the titles and blobs.
    // However, this feature is really just for the scoreboard picker, which lets the user check the rankings by
    // mission/pack/objective. Currently only pack level viewing is implemented. So this is an adequate solution for now.
    return { id: packId, packId, leaderboard: leaderboard[packId] }
  }

  const pack = readMissionPackStoreByPackId(packId)
  if (!pack) {
    return {}
  }

  return { id: packId, packId, name: pack.title, avatarBlob: pack.avatarBlob, leaderboard: leaderboard[packId], children: pack.missions.filter(msn => !!leaderboard[msn.id]).map(msn => formatContestMissionLeaderboard(msn.id, leaderboard)) }
}

function formatContestBandLeaderboard(leaderboard) {
  const leaderboardFields = {}
  const packIds = readMissionPackIdsFromMissionPackStore()
  Object.keys(leaderboard).forEach((leaderboardId) => {
    if (packIds.includes(leaderboardId)) {
      leaderboardFields[leaderboardId] = formatContestPackLeaderboard(leaderboardId, leaderboard)
    } else {
      leaderboardFields[leaderboardId] = formatContestPackLeaderboard(leaderboardId, leaderboard, false)
    }
  })
  leaderboardFields.bandName = decodeLeaderboardName(leaderboard.id)
  return leaderboardFields
}

function formatContestFromCloud(contest) {
  const { leaderboards, ...contestInfo } = contest
  const packIds = readMissionPackIdsFromMissionPackStore()

  let bandName = ''
  Object.keys(leaderboards ?? []).forEach((leaderboardId) => {
    if (!leaderboardId.startsWith('default_band')) {
      bandName = decodeLeaderboardName(leaderboardId)
    }
  })


  let formattedLeaderboards = {}
  if (!!leaderboards) {
    formattedLeaderboards = Object.entries(leaderboards).map(([key, value]) => {
      if (!value) {
        return value
      }
      return formatContestBandLeaderboard({ ...value, id: key })
    }).filter(x => !!x)
  }

  return {...contestInfo, leaderboards: formattedLeaderboards, leaderboardsByScoreId: leaderboards, packsFormatted: packIds.length > 0, bandName }
}

function formatContestsFromCloud(contests) {
  return contests.map(formatContestFromCloud)
}

async function getContestsFromCloud() {
  try {
    const resp = await authFetchHandler('contests',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const data = await resp.json()
    if (data && resp.status === 200) {
      return formatContestsFromCloud(data)
    }
  } catch (err){
    console.log(err)
  //
  }
}

async function putContestSubmissionInCloud(missionPackId, missionId, flowId) {
  try {
    const resp = await authFetchHandler('contests/submission',
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ missionPackId, missionId, flowId }),
      }
    )

    return resp.status === 200
  } catch (err){
    console.log(err)
  //
  }
}

async function putIndyUserContestRegistrationInCloud(contestId, bandId, name, registrationDetails) {
  try {
    const resp = await authFetchHandler('contests/registration',
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contestId, bandId, name, registrationDetails }),
      }
    )

    if (resp.status !== 200) {
      return (await resp.json())?.message
    }
  } catch (err){
    console.log(err)
    return err
  }
}


export { getContestsFromCloud, putContestSubmissionInCloud, putIndyUserContestRegistrationInCloud }