import {
  setAssignmentIdsByGroupId,
  setGroupEventsByDueDate,
  setAssignmentInStore,
  readActiveGroupIdFromStore,
} from '../group-store'
import { fetchAssignmentsFromCloud } from '../group-gateways'
import {
  presentFetchGroupsResponseFailed,
  presentFetchGroupsResponseStarted,
  presentFetchGroupsResponseSucceeded,
} from '../group-presenter'
import { groupIsDefault } from './groupIsDefault'

async function setAssignments(groupId, assignments) {
  // When progression gates are implemented, they need to be formatted in
  // 'fetchAssignmentsFromCloud' and stored here
  setGroupEventsByDueDate(assignments)
  assignments.forEach(setAssignmentInStore)
  setAssignmentIdsByGroupId(groupId, assignments.map(assignment => assignment.id))
}

async function fetchAssignments(groupId=readActiveGroupIdFromStore()){
  if (groupIsDefault(groupId)) {
    return
  }

  presentFetchGroupsResponseStarted()
  const assignmentDataFromCloud = await fetchAssignmentsFromCloud(groupId)
  if (!assignmentDataFromCloud) {
    presentFetchGroupsResponseFailed()
    return
  }

  setAssignments(groupId, assignmentDataFromCloud)
  presentFetchGroupsResponseSucceeded()
  return assignmentDataFromCloud
}

export {
  fetchAssignments,
  setAssignments,
}