import { readMissionPackStoreByPackId } from '../../lesson-content/lesson-content-store'
import { readMissionProgressFromUserProgressStore } from '../user-progress-store'
import { isMissionManuallyLocked } from '../../content-director/content-director-use-cases/isMissionManuallyLocked'
import { isMissionGated } from '../../groups/group-use-cases/isMissionGated'
import { missionWasStartedBeforeContestStarted } from '../../contests/contest-use-cases/contest-erases-progress'

async function findNextUncompletedMission(missionPackId) {
  const pack = readMissionPackStoreByPackId(missionPackId)

  let nextMissionId = null
  for (const i in pack.missions) {
    const { id: missionId } = pack.missions[i]
    if ((i === 0 && isMissionGated(missionId)) || missionWasStartedBeforeContestStarted(missionId)) {
      nextMissionId = missionId
      break
    }

    if (i > 0 && isMissionGated(missionId) && !isMissionGated(pack.missions[i - 1].id)) {
      nextMissionId = pack.missions[i - 1].id
      break
    }

    const missionProgress = readMissionProgressFromUserProgressStore(missionId)
    if ((missionProgress?.started && !missionProgress.completed && !isMissionGated(missionId)) || !missionProgress || missionWasStartedBeforeContestStarted(missionId)) {
      nextMissionId = missionId
      break
    }
  }

  if (isMissionManuallyLocked(nextMissionId)) {
    const idx = pack.missions.findIndex(msn => msn.id === nextMissionId)
    if (idx === -1 || idx === 0) {
      return pack.missions[0].id
    }
    return pack.missions[idx - 1].id
  }

  return nextMissionId
}

export { findNextUncompletedMission }