import React from 'react'
import './App.css'
import { makeStyles } from '@material-ui/core/styles'
import FiriaLabsBanner from './assets/firialabsbanner.png'
import { Typography, Button } from '@material-ui/core'
import { VerticalLoginButtonsContainer, LoginButton } from './LandingDialog'
import { genContrastColor } from './utils/shade-highlight-tools'
import { useLogin } from './contexts/LoginContext'
import { USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE, FAILED_TO_AUTHENTICATE_USER } from './content-manager/user-session/user-session-use-cases/user-session-error-codes'

const useStyles = makeStyles(theme => ({
  logoutPageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  firiaBanner: {
    display: 'flex',
    alignItems:'center',
  },
  sessionContainer: {
    width: 800,
    padding: '30px 60px',
    borderRadius: 10,
    backgroundColor: genContrastColor(0.025),
    border: `1px solid ${genContrastColor(0.15)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

function SessionEndingErrorContainer({ children }) {
  const classes = useStyles()

  function refresh() {
    window.location.href = '/'
  }

  const BUTTON_WIDTH = 260
  return (
    <div className={classes.logoutPageContainer}>
      <img alt={''} className={classes.firiaBanner} src={FiriaLabsBanner}/>
      <div className={classes.sessionContainer} >
        {children}
        <VerticalLoginButtonsContainer>
          <LoginButton style={{ width: BUTTON_WIDTH }} paperProps={{ elevation: 0 }}/>
          <Button style={{ width: BUTTON_WIDTH }} variant='outlined' onClick={refresh}>
            {'Continue without logging in'}
          </Button>
        </VerticalLoginButtonsContainer>
      </div>
    </div>
  )
}

function UserSessionOverwrittenPage() {
  const [loginState] = useLogin()

  return (
    <SessionEndingErrorContainer>
      {
        loginState?.displayUserLoggedOutMessage === USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE ?
          <>
            <Typography variant={'h5'}>{'Your account has been logged into on another device.'}</Typography>
            <Typography style={{paddingTop: 10, paddingBottom: 20}}>{'You\'ve been logged out on this device!'}</Typography>
          </>: loginState?.displayUserLoggedOutMessage === FAILED_TO_AUTHENTICATE_USER ?
            <>
              <Typography variant={'h5'}>{'Your session has expired.'}</Typography>
              <Typography style={{paddingTop: 10, paddingBottom: 20}}>{'Please log back in to start a new session!'}</Typography>
            </>:
            null
      }
    </SessionEndingErrorContainer>
  )
}

function LTILogoutPage() {
  const classes = useStyles()

  return (
    <div className={classes.logoutPageContainer}>
      <img alt={''} className={classes.firiaBanner} src={FiriaLabsBanner}/>
      <Typography variant={'h5'}>{'Successfully logged out.'}</Typography>
      <Typography style={{paddingTop: 10}} variant={'body2'}>{'Please close this tab at your leisure.'}</Typography>
    </div>
  )
}

export { LTILogoutPage, UserSessionOverwrittenPage }
