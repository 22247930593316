import smartlookClient from 'smartlook-client'

const SMARTLOOK_ENABLED = process.env.NODE_ENV !== 'development' && (process.env.REACT_APP_PRODUCTION_USE_SMARTLOOK === 'y' || process.env.REACT_APP_STAGING_USE_SMARTLOOK === 'y')

var initialized = false
var trackQueue = []

const isWindowAvailable = () => {
  try {
    initialized = smartlookClient.initialized()

    if (initialized) {
      clearInterval(initializedInterval)
      while (trackQueue.length > 0) {
        trackQueue.pop()()
      }
      return
    }
  } catch (err) {
    console.log('err: ', err)
    return
  }
}

var initializedInterval = null
const pollWindowAvailability = () => {
  initializedInterval = setInterval(isWindowAvailable, 200)
}


const smartlookInit = () => {
  if (initialized) {
    return
  }

  if (SMARTLOOK_ENABLED) {
    smartlookClient.init(process.env.REACT_APP_SMARTLOOK_ACCESS_TOKEN)
    pollWindowAvailability()
    return
  }
}

const disabledWrapper = (func) => {
  return (...args) => {
    if (!SMARTLOOK_ENABLED){
      return
    }

    // The track queue catches messages sent before smartlook has a chance to initialize.
    // Smartlook requires the window, but some of our web calls occur before the app component loads.
    if (!initialized) {
      trackQueue.push(() => func(...args))
      return
    }

    func(...args)
  }
}

const identifySmartlookUser = disabledWrapper(({ id, name, email }) => {
  smartlookClient.identify(id, { id, name, email })
})

const clearSmartlookUser = disabledWrapper(() => {
  smartlookClient.anonymize()
})

// Track smartlook message is currently not being used due to hitting the data cap really fast.
// If we do enable it in the future, we'll need to change how the fetch handler functions in the webworker.
// Currently the window is always considered unavailable from the webworker, so we need an exception in the logger
// where logs from the webworker don't trigger anything from smartlook.
const trackSmartlookMessage = disabledWrapper((eventName, props) => {
  smartlookClient.track(eventName, props)
})

export {
  smartlookInit,
  trackSmartlookMessage,
  identifySmartlookUser,
  clearSmartlookUser,
}