import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog } from '@material-ui/core'

import { missionEditorController } from '../../MissionEditorControl'
import RipperInstructions from './RipperInstructions'
import RipperModule from './RipperModule'

const useStyles = makeStyles(theme => ({
  instructionsContainer: {
    position: 'absolute',
    zIndex: 5000,
    userSelect: 'text',
  },
  instructionsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 15,
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  fetchingPacksContainer: {
    fontSize: 22,
    padding: 50,
  },
  moduleContainer: {
    width: 600,
  },
  footer: {
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid rgba(0,0,0,0.2)',
  },
}))

const TeachersManualRipperDialog = (props) => {
  const classes = useStyles()
  const packs = React.useRef([])
  const absImagePath = React.useRef(null)
  const [fetchingPacks, setFetchingPacks] = React.useState(false)
  const [running, setRunning] = React.useState(null)

  React.useEffect(() => {
    const getPackData = async () => {
      if (fetchingPacks || packs.current.length !== 0){
        return
      }
      setFetchingPacks(true)
      absImagePath.current = (await missionEditorController.getTeachersManualAbsoluteImagePaths()).msg

      const packIds = (await missionEditorController.getMissionPacks()).data
      const packPromises = packIds.map(packId => missionEditorController.formatMissionPack(packId))
      packs.current = await Promise.all(packPromises)
      setFetchingPacks(false)
    }
    getPackData()
  })

  const toggleRunning = (packId, running) => {
    if (running) {
      setRunning(packId)
    } else {
      setRunning(null)
    }
  }

  function handleClose() {
    if (running) {
      return
    }
    props.onClose()
  }

  return (
    <>
      <RipperInstructions open={props.open} onClose={props.onClose} running={running} />
      <Dialog
        onClose={handleClose}
        open={props.open}
        PaperProps={{
          classes: { root: classes.dialogRoot },
        }}
      >
        <div className={classes.dialogContainer}>
          {fetchingPacks ?
            <div className={classes.fetchingPacksContainer}>
              Fetching Packs...
            </div>:
            <div className={classes.moduleContainer}>
              <RipperModule
                packs={packs}
                pack={{id: 'toc', title: 'Table of Contents'}}
                absImagePath={absImagePath}
                runningId={running}
                setRunning={running => toggleRunning('toc', running)}
              />
              <RipperModule
                packs={packs}
                pack={{id: 'toolbox', title: 'Toolbox'}}
                absImagePath={absImagePath}
                runningId={running}
                setRunning={running => toggleRunning('toolbox', running)}
              />
              {packs.current.map((pack, idx) =>
                <RipperModule
                  key={pack.id}
                  packs={packs}
                  pack={pack}
                  absImagePath={absImagePath}
                  runningId={running}
                  setRunning={running => toggleRunning(pack.id, running)}
                />
              )}
            </div>
          }
          <div className={classes.footer}>
            <div></div>
            <Button
              onClick={props.onClose}
              disabled={running}
              variant='outlined'
            >
              Close
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default TeachersManualRipperDialog