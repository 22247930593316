import React from 'react'
import { Button, Typography, Dialog, DialogTitle, DialogActions, CircularProgress, IconButton, DialogContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useNotifications } from './contexts/NotificationsContext'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Markdown from './FiriaMarkdown'
import { genContrastColor, genShadeColor, genHighlightColor } from './utils/shade-highlight-tools'
import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons'
import { markAllNotificationsRead } from './content-manager/notifications/notification-use-cases'

const useStyles = makeStyles((theme) => {
  // const type = theme.palette.type
  return {
    dialog: {
      height: '90%',
      // overflowY: 'visible',
    },
    dialogPaper: {
      display: 'flex',
      flexDirection: 'column',
      height: 600,
      width: 650,
      // overflowY: 'hidden',
    },
    readNotificationRow: {
      backgroundColor: genHighlightColor(0.04, 0.7),
    },
    notificationRowBase: {
      '&:hover': {
        backgroundColor: genContrastColor(0.05),
      },
      width: '100%',
      display: 'flex',
      alignItems:'flex-start',
      padding: '6px 10px',
      borderBottom: `1px ${genContrastColor(0.2)} solid`,
    },
    readRowFont: {
      fontWeight: 200,
    },
    unreadRowFont: {
      fontWeight: 600,
    },
    notificationsContent: {
      backgroundColor: 'rgba(0,0,0,0.08)',
      border: '1px solid rgba(0,0,0,0.15)',
      margin: '0px 10px',
      padding: 0,
    },
    listPanelContainer: {
      overflowY: 'scroll',
    },
    detailPanelContainer: {
      height: 'calc(100% - 117px)',
    },
  }
})

// Ripped from https://dev.to/sh20raj/converting-dates-into-human-readable-time-ago-format-in-javascript-595g
function timeAgo(date) {
  const seconds = Math.floor((new Date() - date) / 1000)

  const days = Math.floor(seconds / 86400)
  if (days >= 1) {
    return date.toISOString().substring(0, 10)
  }

  const hours = Math.floor(seconds / 3600)
  if (hours > 1) {
    return hours + ' hours ago'
  }
  if (hours === 1) {
    return hours + ' hour ago'
  }

  const minutes = Math.floor(seconds / 60)
  if (minutes > 1) {
    return minutes + ' minutes ago'
  }
  if (minutes === 1) {
    return minutes + ' minute ago'
  }

  return 'just now'
}


function NotificationRow({ title, brief, read, id, startVisibilityTs }) {
  const classes = useStyles()
  const { notificationsStateInterface } = useNotifications()

  return (
    <div
      onClick={() => notificationsStateInterface.selectNotificationIdState(id)}
      className={`${!read && classes.readNotificationRow} ${classes.notificationRowBase}`}>
      <div style={{paddingRight: 9, paddingTop: 5, width: 20}}>
        {!read && <FiberManualRecordIcon style={{fontSize: 9, color: genContrastColor(1)}}/>}
      </div>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography className={read ? classes.readRowFont:classes.unreadRowFont} variant='h6'>{title}</Typography>
          <Typography className={read ? classes.readRowFont:classes.unreadRowFont} style={{fontSize: 15}} variant='body1'>{timeAgo(startVisibilityTs)}</Typography>
        </div>
        <Typography className={read ? classes.readRowFont:classes.unreadRowFont} variant='body2'>{brief}</Typography>
      </div>
    </div>
  )
}

function NotificationListPanel() {
  const { notifications } = useNotifications()
  const classes = useStyles()

  if (notifications.length === 0) {
    return (
      <DialogContent className={classes.notificationsContent} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Typography>You've got no notifications!</Typography>
      </DialogContent>
    )
  }

  return (
    <DialogContent className={classes.notificationsContent} style={{display: 'flex', flexDirection: 'column'}}>
      {notifications.map(notification => (
        <NotificationRow
          key={notification?.id}
          {...notification}
        />
      ))}
    </DialogContent>
  )
}

function NotificationDetailSubheader() {
  const classes = useStyles()
  const { selectedNotification, notificationsStateInterface } = useNotifications()

  return (
    <div style={{height: 65, width: '100%', backgroundColor: genContrastColor(0.02), borderBottom: `1px solid ${genShadeColor(0.2)}`}}>
      <div style={{display: 'flex', alignItems:'center'}}>
        <IconButton
          style={{borderRadius: 0, height: 65}}
          sx={{height: '100%'}}
          onClick={() => notificationsStateInterface.selectNotificationIdState(null)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <div style={{width: '100%', padding: '6px 10px'}}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant='h6'>{selectedNotification?.title}</Typography>
            <Typography className={classes.readRowFont} style={{fontSize: 15}} variant='body1'>{timeAgo(selectedNotification.startVisibilityTs)}</Typography>
          </div>
          <Typography variant='body2'>{selectedNotification?.brief}</Typography>
        </div>
      </div>
    </div>
  )
}

function NotificationDetailPanel() {
  const { selectedNotification } = useNotifications()
  const classes = useStyles()

  let content = selectedNotification?.content
  try {
    content = selectedNotification?.content.split('\\n').join('\n')
  } catch {
    //
  }

  return (
    <>
      <div className={classes.notificationsContent} style={{borderBottom: '0px solid black'}}>
        <NotificationDetailSubheader />
      </div>
      <DialogContent className={classes.notificationsContent} style={{padding: '0px 20px'}}>
        <Markdown>{content}</Markdown>
      </DialogContent>
    </>
  )
}

function NotificationsHeader() {
  const { fetchNotificationsInProgress } = useNotifications()
  return (
    <div style={{display: 'flex', alignItems:'center', gap: 8, height: 64}}>
      <DialogTitle style={{paddingRight: 0}}>
        {'Notifications'}
      </DialogTitle>
      {fetchNotificationsInProgress ? <CircularProgress style={{color: 'white'}} size={13}/>:null}
    </div>
  )
}

function SearchingForNotificationsPanel() {
  const classes = useStyles()
  const { fetchNotificationsFailed } = useNotifications()

  return (
    <DialogContent className={classes.notificationsContent} style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems:'center', height: '50%'}}>
        {fetchNotificationsFailed && <>
          <Typography>Initialization failed unexpectedly!</Typography>
          <Typography> Please refresh your browser to try again.</Typography>
        </>}
        {!fetchNotificationsFailed && <>
          <DialogTitle>{'Fetching notifications...'}</DialogTitle>
          <div style={{paddingTop: 0}}>
            <CircularProgress style={{color: 'white'}}/>
          </div>
        </>}
      </div>
    </DialogContent>
  )
}

function DismissAllButton() {
  const { allNotificationsRead } = useNotifications()
  const [posting, setPosting] = React.useState(false)
  async function markNotificationsRead() {
    setPosting(true)
    await markAllNotificationsRead()
    setPosting(false)
  }

  return (
    <Button
      variant='outlined'
      style={{borderRadius: 0}}
      onClick={markNotificationsRead}
      disabled={posting || allNotificationsRead}
    >
      {'Dismiss All'}
    </Button>
  )
}

function NotificationsDialog() {
  const classes = useStyles()
  const { notificationsDialogOpen, notificationsStateInterface, selectedNotificationId, notificationsInitialized } = useNotifications()

  var searchingPanelOpen = false
  var detailPanelOpen = false
  var listPanelOpen = false

  if (!notificationsInitialized) {
    searchingPanelOpen = true
  } else if (!!selectedNotificationId) {
    detailPanelOpen = true
  } else {
    listPanelOpen = true
  }

  return (
    <Dialog
      open={notificationsDialogOpen}
      onClose={notificationsStateInterface.closeDialog}
      className={classes.dialog}
      fullWidth
      maxWidth='lg'
      PaperProps={{
        classes: { root: classes.dialogPaper },
      }}
    >
      <>
        <NotificationsHeader />
        {searchingPanelOpen && <SearchingForNotificationsPanel />}
        {detailPanelOpen && <NotificationDetailPanel />}
        {listPanelOpen && <NotificationListPanel />}
        <DialogActions style={{height: 53, justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10}}>
          {listPanelOpen ?
            <DismissAllButton/>:
            <div/>}
          <Button
            style={{borderRadius: 0}}
            onClick={notificationsStateInterface.closeDialog}
          >
            {'Close'}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  )
}

export { NotificationsDialog }