// Library Environment
import { Entity } from '../../SimScene'
import * as BABYLON from '@babylonjs/core'
// import * as BabylonDebug from '@babylonjs/core/Debug'
import { TemplateLights, TemplateGround, TemplateSounds } from '../EnvTemplate'
import {
  disposePromise, loadGLB, loadGLBWithPhysics,
} from '../../utils/BabylonUtils'
import PbrLightingTextureEnv from '../../assets/MistyBrightSky.env'
import EnvModel from '../../assets/HauntedHouse/Library.glb'
import HopperModel from '../../assets/HauntedHouse/hopper.glb'
import CardboardBoxModel from '../../assets/HauntedHouse/CardboardBox.glb'
import PetrifiedPaperModel from '../../assets/HauntedHouse/PetrifiedPaper.glb'
import EnvThemeMusic from '../../assets/HauntedHouse/sounds/Library.mp3'
import SpiritReleaseSound from '../../assets/HauntedHouse/sounds/SpiritRelease.mp3'
import LevelEndSound from '../../assets/HauntedHouse/sounds/LevelEnd.mp3'
import { Trinkets } from './Trinkets'
import { Waypoints } from '../CommonProps'

const environmentName = 'Library'

// Environment template options
const lightOptions = {
  envLightingName: 'MistyBrightSky',
  envLightingSource: PbrLightingTextureEnv,
  envIntensity: 0.2,
  fogMode: BABYLON.Scene.FOGMODE_NONE,  // BABYLON.Scene.FOGMODE_EXP2,
  fogDensity: 0.003,
  fogColor: new BABYLON.Color3(0.9, 0.9, 0.85),
}
const soundOptions = {
  envMusicName: 'LibraryThemeMusic',
  envMusicSource: EnvThemeMusic,
  envSoundFx: [
    {name: 'spiritReleaseSound', source: SpiritReleaseSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'levelEndSound', source: LevelEndSound, options: { spatialSound: false, volume: 1.0 }},
  ],
}
const groundOptions = {
  envModelName: 'LibraryModel',
  envModelSource: EnvModel,
}

// Default environment params which the Mission Editor may override
const defaultEnvParams = {
  'bot': [
    {
      'playerPosition': [0, 0, -25],
      'playerRotation': 180,
    },
  ],
  'rotateCam': null,  // Angles for rotate camera. Usual default is {alpha:2.5, beta:1.3, radius:4}
  'trinkets': [
    {
      pos: [5, 0, 5],
      rot: 0,
      scale: 1.0,
      scoreValue: 10,
      shape: null,   // null for random choice. Choices are: 'cat', 'ghost', 'pumpkin'
    },
  ],
  'hopperGhost': true,
  'ghostMessage': {
    pos: [0, 0, -20],
  },
  'boxOnBook': {
    pos: [-2, 1, -14],
    rot: 0,
  },
  'paperRamp': true,
}

// Interactives - things to interact with in the environment:
//  * Movable objects, treats, NPCs, etc.
class Interactives extends Entity {
  constructor(mainEnv) {
    super()
    this.env = mainEnv
    this.waypoints = new Waypoints()
    this.lampMeshes = Array(5)
    this.lampLights = Array(5)

    // Map lamp light names from model file
    this.lampIndex = new Map([
      ['Area.004', 0],
      ['Area.003', 1],
      ['Area.002', 2],
      ['Area',     3],
      ['Area.001', 4],
    ])
  }

  loadMessageCard = (message, pos, rot) => {
    // Creates and returns a new message card. Will dispose on unload.
    const rotation = [0, rot, -90]
    const rotationRadians = rotation.map(BABYLON.Tools.ToRadians)
    const pad = this.waypoints.createPad(message, pos, rotationRadians)
    this.disposeList.push(pad)
    pad.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_Y  // Face the camera!
    return pad
  }

  loadGhost = async () => {
    const pos = [0, -20, 0]   // Spawn outside of room
    const rot = 0
    this.ghost = await loadGLB(HopperModel, 'HopperGhost', 1.0, pos, rot)
    return this.ghost
  }

  loadBox = async (position, rotation) => {
    // Cardboard box, gets pushed off of book
    this.box = await loadGLBWithPhysics(CardboardBoxModel, 'box', { mass: 10, friction: 10, restitution: 0.8 }, 1.0, position, rotation)
    return this.box
  }

  loadPaperRamp = async () => {
    // Petrified wallpaper ramp that rotates atop toppled bookshelf
    const rampMesh = await loadGLB(PetrifiedPaperModel, 'ramp')

    // Create simple (not compound) physics impostor, which works better for hinge joint
    // Manually matching the sized of GLB asset.
    this.paperRamp = BABYLON.MeshBuilder.CreateBox('rampBox', { height: .005, width: 15.3, depth: 6.7})
    BABYLON.Tags.AddTagsTo(this.paperRamp, 'BotCollider')

    // Position close to where hinge joint is, so physics doesn't lose its sh**
    // The position and rotation below were snooped from placement and motor rotation via debugger
    this.paperRamp.position = new BABYLON.Vector3(-1.68, 12.15, 20.9)
    this.paperRamp.rotationQuaternion = new BABYLON.Quaternion(-0.25135868787765503, -0.5234841108322144, 0.16505388915538788, 0.7972078919410706)

    this.paperRamp.physicsImpostor = new BABYLON.PhysicsImpostor(this.paperRamp, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 30, friction: 5.9 })
    this.paperRamp.isVisible = false  // Hide impostor mesh
    rampMesh.parent = this.paperRamp  // Parent the pretty model to our shiny new Impostor mesh

    return this.paperRamp
  }

  load = async (scene, params) => {
    this.scene = scene
    this.params = params
    this.timers = []  // timer IDs for cleanup
    this.disposeList = []
    this.box = null

    const interactivesPromises = []

    if (params.hopperGhost) {
      interactivesPromises.push(this.loadGhost())
    }

    if (params.boxOnBook) {
      interactivesPromises.push(this.loadBox(params.boxOnBook.pos, params.boxOnBook.rot))
    }

    if (params.paperRamp) {
      interactivesPromises.push(this.loadPaperRamp())
    }

    this.meshes = await Promise.all(interactivesPromises)
    this.disposeList.push(...this.meshes)
  }

  unload = async () => {
    // Stop any active timers
    this.timers.forEach(id => clearTimeout(id))
    await Promise.all(this.disposeList.map(disposePromise))
  }

  lampControl = (num, isOn) => {
    // Turn lamp on/off
    this.lampLights[num].setEnabled(isOn)
  }

  lampCheck = (num) => {
    // Check if lamp is on/off
    return this.lampLights[num].isEnabled()
  }

  configure = () => {
    // Setup lamps
    const lights = this.env.ground.envModel.lights
    lights.forEach(light => this.lampLights[this.lampIndex.get(light.name)] = light)
    this.initLamps()

    if (this.params.hopperGhost) {
      const anim = this.ghost.animationGroups[0]  // Just one animation
      anim.speedRatio = 0.2
      anim.play(true)

      this.initGhostController()
    }

    if (this.params.paperRamp) {
      this.createAxle()
      this.axleJoint.setMotor(0, 5)
      this.initPaperController()
    }
  }

  handleModelEvent = (eventMap) => {
    // Hook into the model LS LEDs to control the lamps!
    if (!this.env.isLoaded) {
      return
    }
    const lsLeds = eventMap.get('lsLeds')
    if (lsLeds !== undefined) {
      // console.log('lsLEDs=', this.env?.playerController.lsLeds)
      let whichLamp = null
      for (let i = 0; i < 5; ++i) {
        if (lsLeds & (1 << i)) {
          if (whichLamp === null) {
            whichLamp = i
          } else {
            // More than one LS LED is set - effect is all off.
            whichLamp = null
            break
          }
        }
        // this.lampControl(i, lsLeds & (1 << i))
      }
      if (whichLamp !== this.curLamp) {
        if (this.curLamp !== undefined) {
          this.lampControl(this.curLamp, false)
          this.curLamp = undefined
        }
        if (whichLamp !== null) {
          this.curLamp = whichLamp
          this.lampControl(this.curLamp, true)
        }
      }
    }
  }

  hopperSpiritReleased = () => {
    // For validators to check status
    return this?.hopperEnergy === 100
  }

  initGhostController = () => {
    // Move ghost among lamps in a sequence, searching for energy.
    // Every 100ms sample where ghost touches light increases spectral energy by 1%,
    // while darkness decreases energy. Show spectral energy on messageCard.
    // nLamp = (int(time.time()) * 3) % 5
    this.hopperEnergy = 0  // 0-100%
    const spiritReleaseSfx = this.env.sounds.cloneSfx('spiritReleaseSound')

    // Hopper's position near each lamp
    const positions = [
      [-24, 0, -17],
      [-23, 0, 28],
      [24, 0, 28],
      [24, 0, -7],
      [24, 0, -26],
    ]

    const hopperBody = this.ghost.getChildMeshes(false, m => m.name === 'Cylinder.001')[0]

    // Init energy message card
    if (this.params.ghostMessage) {
      this.ghostMessage = this.loadMessageCard('Spectral Energy', this.params.ghostMessage.pos, 90)
      this.ghostMessage.material.specularColor = BABYLON.Color3.Black()  // Avoid light glare
    }

    const timer = setInterval(() => {
      // Calc sequence
      const hopSequence = (Math.trunc(Date.now() / 1000) * 3) % 5
      // Move ghost
      const pos = positions[hopSequence]
      this.ghost.position.copyFromFloats(...pos)

      // Check lamp and update energy accordingly
      if (this.lampCheck(hopSequence)) {
        this.hopperEnergy += 1
      } else {
        if (this.hopperEnergy > 0) {
          this.hopperEnergy -= 1
        }
      }

      // Hopper shows more energy
      hopperBody.material.alpha = 0.1 + 0.7 * (this.hopperEnergy / 100)
      hopperBody.material.emissiveIntensity = 1 + 5 * (this.hopperEnergy / 100)

      // Display energy message
      if (this.params.ghostMessage) {
        // console.log('Hopper energy=', this.hopperEnergy)
        const line1 = 'Spectral Energy'
        const line2 = `${this.hopperEnergy}%`
        this.ghostMessage._dynamicTexture.drawText(line1, 30, 40, '50px monospace', 'white', 'blue', true, true)
        this.ghostMessage._dynamicTexture.drawText(line2, 110, 180, '140px monospace', 'white', null, true, true)
      }

      if (this.hopperEnergy === 100) {
        clearInterval(timer)
        // Hopper spirit release!
        // Move ghost to center of room
        const center = [0, 0, -6]
        this.ghost.position.copyFromFloats(...center)
        hopperBody.material.emissiveIntensity = 10

        // Look at Hopper!
        const hopFace = this.ghost.position.clone()
        hopFace.y = 8
        this.scene.activeCamera.setTarget(hopFace)

        // Play release sound, and dispose ghost
        spiritReleaseSfx.onEndedObservable.addOnce((ev) => {
          this.ghost.dispose()
        })
        spiritReleaseSfx.play()
      }
    }, 100)

    this.timers.push(timer)
  }

  initLamps = () => {
    for (let i = 0; i < 5; ++i) {
      // Curently using PointLights imported from model (Blender GLTF export "Punctual lights" option)
      const light = this.lampLights[i]
      light.setEnabled(false)
      // light.diffuse.copyFromFloats(0, 1.0, 0)  // Green

      // Currently in model meshes all share same material, so we're keeping emissivity zero
      const mesh = this.lampMeshes[i]
      // mesh.material.emissiveColor.copyFromFloats(0, 1.0, 0)
      mesh.material.emissiveIntensity = 0
    }
  }

  isBoxOffBook = () => {
    // Validators can call this to see if player successfully moved box of of book
    if (!(this.box && this.params.boxOnBook)) {
      return false  // Why are you even asking!?
    }
    const boxPos = this.box.position
    const bookPos = new BABYLON.Vector3(...this.params.boxOnBook.pos)
    const dist = BABYLON.Vector3.Distance(boxPos, bookPos)
    // console.log('Dist from box to book = ', dist)
    return dist > 4  // Achievable distance apart
  }

  initPaperController = () => {
    // Rotate wallpaper ramp back and forth when bot is near
    const moveTicks = 100
    const thresholdDistance = 6
    const minBotSpeed = 15
    const maxBotSpeed = 50
    let motionTicks = moveTicks
    let speed = (0.2 + 0.1 * Math.random()) * ((Math.random() > 0.5) ? 1 : -1)

    const timer = setInterval(() => {
      if (!this.env.playerEntity) {
        return
      }

      const dist = BABYLON.Vector3.Distance(this.hub.position, this.env.playerEntity.cbBody.position)
      if (dist < thresholdDistance) {
        // Bot has moved, no slowing down or gunning it, or we speed up!
        const spdL = Math.abs(this.env.playerController.motorL)
        const spdR = Math.abs(this.env.playerController.motorR)
        if ((spdL < minBotSpeed && spdR < minBotSpeed) ||
            (spdL > maxBotSpeed && spdR > maxBotSpeed)) {
          speed = (1 + Math.random()) * -1 // * Math.sign(speed)  // FAST!
        }

        // Cap max speed
        let overrideSpeed = false
        if (spdL > maxBotSpeed) {
          this.env.playerController.motorL = maxBotSpeed * Math.sign(this.env.playerController.motorL)
          overrideSpeed = true
        }
        if (spdR > maxBotSpeed) {
          this.env.playerController.motorR = maxBotSpeed * Math.sign(this.env.playerController.motorR)
          overrideSpeed = true
        }
        if (overrideSpeed) {
          this.env.playerController.overrideMotors(this.env.playerController.motorL, this.env.playerController.motorR)
          overrideSpeed = false
        }

        // Change direction occasionally
        if (motionTicks > 0) {
          motionTicks--
          this.axleJoint.setMotor(speed, 15)
        } else {
          motionTicks = Math.trunc(moveTicks / 10 + Math.random() * moveTicks)
          speed *= -1
        }
      } else {
        this.axleJoint.setMotor(0, 15)
        motionTicks = moveTicks
      }
    }, 100)

    this.timers.push(timer)
  }

  createAxle = () => {
    // Create hub on sloping bookshelf to rotate petrified wallpaper ramp.
    // Constructing this manually due to physics issues when dealing with how our utilities load/config stuff.
    this.hub = BABYLON.MeshBuilder.CreateBox('hub', { height: 1, width: 1, depth: 1})
    this.hub.isVisible = false
    // Position our invisible hub near center of shelf, at 55 degree slant
    this.hub.position.x = -1.68
    this.hub.position.y = 11.05
    this.hub.position.z = 21.157
    this.hub.rotation.set(55 * Math.PI / 180, 0, 0)
    this.hub.physicsImpostor = new BABYLON.PhysicsImpostor(this.hub, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 5.9 })
    this.disposeList.push(this.hub)

    let shelfAxlePos = new BABYLON.Vector3(0, 0, -0.57)   // Small offset along axle

    this.axleJoint = new BABYLON.MotorEnabledJoint(BABYLON.PhysicsJoint.HingeJoint, {
      mainPivot: shelfAxlePos,
      connectedPivot: new BABYLON.Vector3(0, 0, 0), // Center of paperRamp
      mainAxis: new BABYLON.Vector3(0, 0, -1),  // Axle of hub is -Z
      connectedAxis: new BABYLON.Vector3(0, 1, 0),  // paperRamp rotates about its Y-axis
      nativeParams: {
      },
    })
    this.hub.physicsImpostor.addJoint(this.paperRamp.physicsImpostor, this.axleJoint)
  }
}

class MainEnvironment extends Entity {
  constructor() {
    super()
    groundOptions.meshVisitor = this.meshVisitor
    this.name = environmentName
    this.lights = new TemplateLights(lightOptions)
    this.sounds = new TemplateSounds(soundOptions)
    this.ground = new TemplateGround(groundOptions)
    this.interactives = new Interactives(this)
    this.trinkets = new Trinkets(this.sounds)
    this.defaultParams = defaultEnvParams
    this.isLoaded = false  // Set true when loaded
  }
  meshVisitor = (mesh) => {
    if (mesh.name === 'Checkpoint1') {
      this.doorCheckpointMesh = mesh
    } else if (mesh.name.startsWith('Lamp')) {
      const n = parseInt(mesh.name[4]) - 1
      this.interactives.lampMeshes[n] = mesh
    } else if (mesh.name === 'Cube') {
      if (this.params.groundCollider) {
        BABYLON.Tags.AddTagsTo(mesh, 'BotCollider')
      }
    }
  }
  finalObjectiveHit = () => {
    // Called by validator after it detects "Checkpoint1" hit on the last Objective
    // Light doorway and stop bot
    if (this.doorCheckpointMesh) {
      this.doorCheckpointMesh.isVisible = true
      this.doorCheckpointMesh.material.emissiveColor.g = 1.0  // Glow Green!
      this.playerController.suspendActivity(true)  // Stop bot from moving through door
    }
  }
  missionCompleteMusic = () => {
    if (this.levelEndSfx) {
      this.levelEndSfx.play()
    }
  }
  load = async (scene) => {
    this.scene = scene
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false

    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.sounds.load(scene),
      this.interactives.load(scene, this.params),
      this.trinkets.load(this.params),
    ])

    // Configuration after async loading
    this.isLoaded = true
    this.interactives.configure()
    this.trinkets.configure()
    this.levelEndSfx = this.sounds.cloneSfx('levelEndSound')

    // --- DEBUG ---
    // this.sounds.setVolume([0, 0.5])
    // window.simEnv = this
  }
  unload = async () => {
    this.isLoaded = false
    await Promise.all([
      this.lights.unload(),
      this.sounds.unload(),
      this.ground.unload(),
      this.interactives.unload(),
      this.trinkets.unload(),
    ])
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
  setVolume = (volumeParams) => {
    this.sounds.setVolume(volumeParams)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController

    // Disallow keyboard CB buttons for this challenge (no driving!)
    if (this.playerController) {
      this.playerController.allowKeyboardButtons = false

      // Track LS LEDs
      this.playerController.modelEventObservable.add(this.interactives.handleModelEvent)

      // Not using prox or ls, save CPU
      this.playerController.sensorOverrides.prox = (num) => {}
      this.playerController.sensorOverrides.ls = (num) => {}
    }
  }
}

export default MainEnvironment
