// Mission and Objective Panels
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, CircularProgress, Button } from '@material-ui/core'
import { useCodePanel } from '../contexts/CodePanelContext'
import { useGroups } from '../contexts/GroupsContext'
import { useContentDirector } from '../contexts/ContentDirectorContext'
import { submitAssignment } from '../content-manager/groups/group-use-cases/submitAssignment'
import { RESPONSE_STATUS } from '../content-manager/groups/group-presenter'
import { useFileManagement } from '../contexts/FileManagementContext'
import { FiriaGreenButtonBase } from '../FiriaGreenButton'

function ValidatedAssignmentSubmissionButton() {
  const [{ tabs }] = useCodePanel()
  const [fileManagementState] = useFileManagement()
  const { submitAssignmentResponse } = useGroups()
  const [codePanelState] = useCodePanel()
  const { assignment } = useContentDirector()
  if (!assignment) {
    return null
  }

  const submissionRequestInProgress = submitAssignmentResponse?.status === RESPONSE_STATUS.IN_PROGRESS
  const disabled = submissionRequestInProgress || !assignment.submissionsRemaining

  return (
    <FiriaGreenButtonBase
      variant='contained'
      disabled={disabled}
      onClick={() => submitAssignment(tabs.focused, codePanelState.editorInstance.getValue(), true)}
      style={{width: '100%', height: 61}}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
        {!assignment.submissionsRemaining ?
          <Typography style={{textTransform: 'none', fontWeight: 500}}>
            {'No Submissions Remaining'}
          </Typography> :
          submissionRequestInProgress ?
            <CircularProgress style={{color: 'white'}}/>:
            <>
              {'Submit File'}
              <Typography style={{textTransform: 'none', fontWeight: 500}}>
                {`"${fileManagementState.tree?.get(tabs.focused)?.name ?? 'Untitled Program'}"`}
              </Typography>
            </>
        }
      </div>
    </FiriaGreenButtonBase>
  )
}

const GoldButton = withStyles(theme => ({
  root: {
    borderRadius: 2,
    color: theme.palette.getContrastText('#F4D038'),
    backgroundColor: '#F4D038',
    '&:hover': {
      backgroundColor: '#b0972a',
    },
  },
}))(Button)


function ManualAssignmentSubmissionButton({ handleClose = () => {} }) {
  const [fileManagementState] = useFileManagement()
  const { submitAssignmentResponse } = useGroups()
  const [codePanelState] = useCodePanel()
  const { assignment } = useContentDirector()
  if (!assignment) {
    return null
  }

  const submissionRequestInProgress = submitAssignmentResponse?.status === RESPONSE_STATUS.IN_PROGRESS
  const disabled = submissionRequestInProgress || !assignment.submissionsRemaining

  return (
    <GoldButton
      variant='contained'
      disabled={disabled}
      onClick={() => submitAssignment(codePanelState.tabs.focused, codePanelState.editorInstance.getValue())}
      style={{width: '100%', height: 61}}
    >
      <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
        {!assignment.submissionsRemaining ?
          <Typography style={{textTransform: 'none', fontWeight: 500}}>
            {'No Submissions Remaining'}
          </Typography> :
          submissionRequestInProgress ?
            <CircularProgress style={{color: 'white'}}/>:
            <>
              {'MANUALLY Submit File'}
              <Typography style={{textTransform: 'none', fontWeight: 500}}>
                {`"${fileManagementState.tree?.get(codePanelState.tabs.focused)?.name ?? 'Untitled Program'}"`}
              </Typography>
            </>
        }
      </div>
    </GoldButton>
  )
}

export { ManualAssignmentSubmissionButton, ValidatedAssignmentSubmissionButton }