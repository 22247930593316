import { eraseUserSessionIdCookie } from './cookieOperations'
import { setUserSessionInfo } from './userAuthStore'
import identifyUser from '../tracking/identifyUser'

const handleLogout = () => {
  eraseUserSessionIdCookie(null)
  setUserSessionInfo(null)
  identifyUser(null)
}

export default handleLogout