import { MissionEditorActions, optionalFields, genDefault } from './contexts/MissionEditorContext'
import { isEqual, cloneDeep } from 'lodash'
import { cacheBust } from './firebase/domain'
import fetchHandler from './tools/tracking/fetchHandler'

class MissionEditorControl {
  constructor() {
    this.saveDebounce = null
    this.saveSuccessCb = () => {}
  }

  importJson = async (rawFile, dataFormat, dispatch) => {
    const fileReader = new FileReader()

    fileReader.onload = async (ev) => {
      try {
        const jsonData = this.pruneLoadedFields('mission', JSON.parse(ev.target.result))
        try {
          if (!jsonData.flow) {
            alert('Tried to import a file that was not a mission!')
          } else {
            const titlesDict = await this.getFlowTitles(jsonData)
            // TODO: default any values that are not in the new object
            dispatch({type: MissionEditorActions.IMPORT_MSN_JSON, value: jsonData, titles: titlesDict})
          }
        } catch (e) {
          console.error('Error loading mission', jsonData)
          alert(e)
        }
      } catch (e) {
        alert('Could Not Parse JSON File')
      }
    }

    fileReader.readAsText(rawFile)
  }

  setSaveSuccessCb = (cb) => {
    this.saveSuccessCb = cb
  }

  stripAndPutJson = (jsonData, type, cb) => {
    var lessonJson = cloneDeep(jsonData)
    for (let key in optionalFields[type]){
      const field = optionalFields[type][key]
      const defaults = genDefault[type]()
      if (isEqual(defaults[field], lessonJson[field])){
        delete lessonJson[field]
      }
    }
    fetchHandler(`http://localhost:3001/${type}/${lessonJson.id}`,
      {
        method: 'PUT',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(lessonJson),
      }
    )
      .then(response => response.json())
      .then((res) => {
        if (!res.success) {
          alert('Error saving file!')
        }
        cb(res)
        this.saveSuccessCb()
      })
      .catch(err => alert('There is a problem with the server!'))
  }

  saveJson = async (jsonData, type, cb, debounce=false) => {
    // console.log(debounce, jsonData)
    if (debounce){
      clearTimeout(this.saveDebounce)
      this.saveDebounce = setTimeout(() => {
        this.stripAndPutJson(jsonData, type, cb)
      }, 1000)
    } else {
      this.stripAndPutJson(jsonData, type, cb)
    }
  }

  renameJson = async (oldId, newId, type) => {
    let retVal = true
    await this.loadJson(oldId, type, async (res) => {
      if (res.success) {
        const json = res.data
        json.id = newId
        this.saveJson(json, type, async (res2) => {
          if (res2.success) {
            // Only delete if save was successful
            this.deleteJson(oldId, type, async (res3) => {
              if (!res3.success) {
                retVal = false
              }
            })
          } else {
            retVal = false
          }
        })
      } else {
        retVal = false
      }
    })

    return retVal
  }

  deleteJson = async (id, type, cb) => {
    fetchHandler(`http://localhost:3001/${type}/${id}`,
      {
        method: 'DELETE',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
      }
    )
      .then(response => response.json())
      .then((res) => {
        cb(res)
        this.saveSuccessCb()
      })
      .catch(err => alert('There is a problem with the server!'))
  }

  pruneLoadedFields = (type, data) => {
    var defaultFields = genDefault[type]()
    if (data){
      for (let field in defaultFields){
        if (field in data){
          defaultFields[field] = data[field]
        }
      }
    }
    return defaultFields
  }

  loadJson = async (id, type, cb) => {
    fetchHandler(`http://localhost:3001/${type}/${id}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
      }
    )
      .then(response => response.json())
      .then((res) => {
        res.data = this.pruneLoadedFields(type, res.data)
        cb(res)
      })
      .catch(err => alert('There is a problem with the server!'))
  }

  uploadImage = async (file, editor) => {
    /*
    const formData = new FormData()
    formData.append('myFile', file)

    fetchHandler('http://localhost:3001/uploadimage',
      {
        method: 'POST',
        mode: 'cors',
        body: formData,
      }
    )
    .then(response => response.json())
    .then(data => {
      if(data.success || data.msg==="") {
        editor.setValue(editor.getValue() + '\n<img src="\\pub\\' + file.name + '" style="height:50px;" />')
      } else if (data.msg === "That image already exists!") {
        editor.setValue(editor.getValue() + '\n<img src="\\pub\\' + file.name + '" style="height:50px;" />')
        alert("Could not upload new file it already exists!")
      } else {
        alert("Error: " + data.msg)
      }
    })
    .catch(err => alert("There is a problem with the server!"))
    */

    editor.setValue(editor.getValue() + '\n<img src="\\pub\\' + file.name + '" style="height:50px;" />')
  }

  pullObj = async (folder, id, blob=false) => {
    if (!blob){
      const response = await fetchHandler(`lesson-content/${folder}/${id}.json${cacheBust}`
        ,{
          headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
        }
      )
      if (response.status === 200) {
        return await response.json()
      } else {
        console.log(`Error ${response.status} fetching lesson-content/${folder}/${id}`)
        return null
      }
    } else {
      const response = await fetchHandler(`lesson-content/${folder}/${id}${cacheBust}`
        ,{
          headers : {
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/octet-stream',
          },
        }
      )
      if (response.status === 200) {
        return response
      } else {
        console.log(`Error ${response.status} fetching lesson-content/${folder}/${id}`)
        return null
      }
    }
  }

  fetchAvatar = async (obj, pullFunc) => {
    const resp = await pullFunc('images', obj.avatar, 'blob')
    return await resp.blob()
  }


  getFlowTitles = async (mission) => {
    const flowPromises = mission.flow.map((flowObj) => {
      return this.pullEditorObj(flowObj.type, flowObj.id)
    })
    const flowResults = await Promise.all(flowPromises)

    const titleDict = {}
    flowResults.forEach((flowObj, index) => {
      if (!flowObj) {
        const msg = `Missing file: ${JSON.stringify(mission.flow[index])}`
        console.error(msg)
        throw Error(msg)
      }
      titleDict[flowObj.id] = flowObj.title
    })

    return titleDict
  }

  getFileBlobs = async (flowObj, pullFunc) => {
    const files = []
    if (!flowObj.files){
      return files
    }
    const filePromises = flowObj.files.map((fileName, index) =>
      new Promise(async (resolve, reject) => {
        const data = await pullFunc('objective-files', fileName, 'blob')
        if (!flowObj.fileBlobs){
          flowObj.fileBlobs = []
        }
        files.push({id: fileName, obj: new Uint8Array(await data.arrayBuffer())})
        resolve()
      })
    )
    await Promise.all(filePromises)
    return files
  }

  getTeachersManualAbsoluteImagePaths = async () => {
    const response = await fetchHandler('http://localhost:3001/absImagePath',
      {
        method: 'GET',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
      }
    )
    return await response.json()
  }

  sendTeachersManualImages = async (id, imgData) => {
    const response = await fetchHandler('http://localhost:3001/resizeTeachersManualImages',
      {
        method: 'PUT',
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ id, imgData }),
      }
    )
    return await response.json()
  }

  getMissionPacks = async () => {
    const response = await fetchHandler('http://localhost:3001/missionPackList'
      ,{
        method: 'GET',
        mode: 'cors',
        headers : {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    )
    return await response.json()
  }

  formatMissionFlow = async (mission, pullFunc) => {
    // TODO: This is inefficient pulling and awaiting one at a time
    const flowPromises = mission.flow.map((flowObj) => {
      return pullFunc(flowObj.type, flowObj.id)
    })
    const flowResults = await Promise.all(flowPromises)

    const questionPromises = []
    var filePromises = []
    flowResults.forEach(async (flowObj) => {
      if (!flowObj) {
        console.error('Missing mission assets!')
        return
      }
      if (flowObj.questions) {
        flowObj.questions.forEach((q) => {
          questionPromises.push(pullFunc('question', q.id))
        })
      }

      if (flowObj.files){
        flowObj.fileBlobs = await this.getFileBlobs(flowObj, pullFunc)
      }
    })

    const questionResults = await Promise.all(questionPromises)

    flowResults.forEach((flowObj) => {
      const flowRef = mission.flow.find(f => f.id === flowObj.id)
      flowRef.obj = flowObj

      if (flowRef.type === 'quiz') {
        flowObj.questions.forEach((q) => {
          const qRef = flowRef.obj.questions.find(qu => qu.id === q.id)
          qRef.obj = questionResults.find(ques => ques.id === q.id)
        })
      }
    })

    Promise.all(filePromises)
    return mission
  }

  formatFlowTypeIndex = (mission) => {
    let totalObjectives = 0
    let totalQuizzes = 0
    mission.flow.forEach((flowItem, flowIndex) => {
      flowItem.flowIndex = flowIndex
      if (flowItem.type === 'objective') {
        flowItem.typeIndex = totalObjectives
        totalObjectives += 1
      } else {
        flowItem.typeIndex = totalQuizzes
        totalQuizzes += 1
      }
    })
    return mission
  }

  formatMission = async (missionData) => {
    try {
      var mission = missionData
      if (!('obj' in missionData)){
        mission = await this.pullEditorObj('mission', missionData.id)
      }
      const fullMission = await this.formatMissionFlow(mission, this.pullEditorObj)
      fullMission.avatarBlob = await this.fetchAvatar(fullMission, this.pullEditorObj)

      return this.formatFlowTypeIndex(fullMission)
    } catch (error) {
      console.log(`formatMission(mission=${missionData?.id}) error: `, error)
      return null
    }
  }

  formatMissionPack = async(newPackId) => {
    const pack = await this.pullEditorObj('pack', newPackId)
    const msnPromises = pack.missions.map((msn) => {
      return this.formatMission(msn)
    })
    const results = await Promise.all(msnPromises)
    pack.missions = results
    pack.avatarBlob = await this.fetchAvatar(pack, this.pullEditorObj)
    return pack
  }

  formatEditorMissionPack = async(newPackId) => {
    const pack = await this.pullEditorObj('pack', newPackId)
    const msnPromises = pack.missions.map((msn) => {
      return this.formatMission(msn)
    })
    const results = await Promise.all(msnPromises)
    pack.missions = results
    pack.avatarBlob = await this.fetchAvatar(pack, this.pullEditorObj)
    return pack
  }

  pullEditorObj = async (type, id, blob=false) => {
    if (!blob){
      const response = await fetchHandler(`http://localhost:3001/${type}/${id}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {'Content-Type': 'application/json'},
        }
      )
      const dataObj = await response.json()
      return dataObj.data
    } else {
      const response = await fetchHandler(`http://localhost:3001/${type}/${id}`
        ,{
          headers : {
            method: 'GET',
            mode: 'cors',
            'Content-Type': 'application/octet-stream',
            'Accept': 'application/octet-stream',
          },
        }
      )
      return response
    }
  }

  formatMissionFromEditor = async (missionData, loadMissionCb) => {
    const msn = JSON.parse(JSON.stringify(missionData))
    const fullMsn = await this.formatMissionFlow(msn, this.pullEditorObj)
    fullMsn.id = Math.floor(Math.random() * 10000000000).toString()

    fullMsn.avatarBlob = await this.fetchAvatar(fullMsn, this.pullEditorObj)
    msn.avatarBlob = await this.fetchAvatar(msn, this.pullEditorObj)

    const pack = {
      id: Math.floor(Math.random() * 10000000000).toString(),
      title: 'Default Pack',
      brief: 'Editing Mission in Editor',
      overview: 'Editing Mission in Editor',
      complete: 'Editing Mission in Editor',
      avatar: 'codebot_head_sm.png',
      missions: [this.formatFlowTypeIndex(fullMsn)],
    }
    pack.avatarBlob = await this.fetchAvatar(pack, this.pullEditorObj)
    return { pack, mission: fullMsn}
  }

  formatSaveJson = (obj) => {
    // This function creates a deep copy of state and strips all the objects from mission flow and
    // quiz questions and saves it to the lesson blob, that way we don't need to re-pull mission content
    // from file every time a value changes in any editor.
    var stateBlob = JSON.parse(JSON.stringify(obj))
    if (stateBlob.editorSelected === 'mission'){
      for (var flowObj of stateBlob.mission.flow) {
        if (flowObj.obj) {
          delete flowObj.obj
        }
      }
    } else if (stateBlob.editorSelected === 'quiz'){
      for (var quizQuestionsObj of stateBlob['quiz'].questions) {
        if (quizQuestionsObj.obj){
          delete quizQuestionsObj.obj
        }
      }
    }
    // console.log(stateBlob[stateBlob.editorSelected])
    return new Blob([JSON.stringify(stateBlob[stateBlob.editorSelected])])
  }
}

export const missionEditorController = new MissionEditorControl()