import { userSessionGateway } from '../user-session-gateway'
import handleLogout from '../../../tools/auth/handleLogout'
import { presentCloseGroupsDialog } from '../../groups/group-presenter'
import { handleUserSessionChanged } from '../../content-manager-use-cases'

async function closeUserSession() {
  handleUserSessionChanged()
  handleLogout()
  presentCloseGroupsDialog()
}

async function endUserSession() {
  await userSessionGateway.deleteUserSession()
  closeUserSession()
}

export { endUserSession, closeUserSession }