// Mission Select Dialog
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { Tooltip, Fade, Typography, CircularProgress } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { genContrastColor } from '../utils/shade-highlight-tools'

const alertColor = '#ff3333'
const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: alertColor,
  },
  arrow: {
    color: alertColor,
  },
}))


function ErrorTooltip(props) {
  const classes = useStyles()
  const tooltipTimeout = React.useRef(null)
  const [tooltipOpen, setTooltipOpen] = React.useState(false)
  const open = props.open

  React.useEffect(() => {
    if (open){
      setTooltipOpen(true)
      clearTimeout(tooltipTimeout.current)
      tooltipTimeout.current = setTimeout(()=>{
        setTooltipOpen(false)
      }, 10000)
    }

    return () => {
      clearTimeout(tooltipTimeout.current)
      setTooltipOpen(false)
    }
  }, [open])

  const parseProps = () => {
    const {
      open,
      ...formattedProps
    } = props
    return formattedProps
  }

  const onMouseEnter = () => {
    setTooltipOpen(true)
    clearTimeout(tooltipTimeout.current)
  }

  const onMouseLeave = () => {
    setTooltipOpen(false)
    clearTimeout(tooltipTimeout.current)
  }


  return (
    <Tooltip
      leaveDelay={500}
      arrow
      placement={'top'}
      open={tooltipOpen && props.open}
      classes={{tooltip: classes.tooltip, arrow: classes.arrow}}
      {...parseProps()}
    >
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {props.children}
      </div>
    </Tooltip>
  )
}

function GatewayResponseIndicator({ fetchState, open, checkVisibleDuration = 1500, placement, size, noCheck, progressColor='white', themedSpinner }) {
  const [fetchStatus, setFetchStatus] = React.useState(false)
  const [checkVisible, setCheckVisible] = React.useState(false)
  const isMounted = React.useRef(false)
  const checkTimeout = React.useRef(null)

  React.useEffect(() => {
    setFetchStatus(fetchState?.status)
  }, [fetchState])

  React.useEffect(() => {
    if (fetchState?.status !== 'in progress') {
      setFetchStatus(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  React.useEffect(()=>{
    isMounted.current = true
    return ()=>{
      clearTimeout(checkTimeout.current)
      isMounted.current = false
    }
  }, [])

  React.useEffect(()=>{
    if (fetchStatus === 'succeeded' && checkVisibleDuration){
      setCheckVisible(true)
      clearTimeout(checkTimeout.current)
      checkTimeout.current = setTimeout(()=>{
        if (isMounted.current){
          setCheckVisible(false)
        }
      }, checkVisibleDuration)
    }
  }, [fetchStatus, checkVisibleDuration])

  return (
    <div style={{display:'flex', alignItems:'center', justifyContent: 'flex-end'}}>
      {fetchStatus === 'in progress' ?
        <CircularProgress style={{color:themedSpinner ? genContrastColor(1) : progressColor}} size={size ? size - 5 : 20}/>:
        null}
      {fetchStatus === 'succeeded' && !noCheck ?
        <Fade
          in={checkVisibleDuration ? checkVisible : true}
          timeout={{enter: 0, exit: 800}}
        >
          <CheckIcon style={{color: 'green', fontSize: size }}/>
        </Fade>:
        null}
      {fetchStatus === 'failed' ?
        <ErrorTooltip
          placement={placement ?? 'top'}
          open={Boolean(fetchStatus === 'failed' && open && fetchState?.reason)}
          title={
            <>
              <Typography
                style={{width: 230}}
                variant='body2'
              >
                {fetchState?.reason}
              </Typography>
            </>
          }
        >
          <ErrorOutlineIcon style={{color: alertColor, display:'flex', alignItems: 'center', justifyContent: 'center', fontSize: size}}/>
        </ErrorTooltip>:null
      }
    </div>
  )
}

export { GatewayResponseIndicator }