import React from 'react'
import { Targets } from '../Players'
import { FIRMWARE_VERSIONS } from './device-firmware-versions'

const codebotSteps = [
  [
    'Set the ',
    <b>"POWER"</b>,
    ' switch to ',
    <b>"USB"</b>,
  ],
  'Switch to USB Power',
  'Connect a USB cable between this computer and the CodeBot',
  [
    'Press and hold button ',
    <b>"BTN-0"</b>,
  ],
  [
    'Press and release button ',
    <b>"REBOOT"</b>,
  ],
  [
    'Release button ',
    <b>"BTN-0"</b>,
  ],
]

const FLASH_STEPS = {
  cb2: codebotSteps,
  codex: [
    'Connect a USB cable between this computer and the CodeX',
    [
      'Press and hold button ',
      <b>"U"</b>,
    ],
    [
      'Press and release button ',
      <b>"RESET"</b>,
    ],
    [
      'Release button ',
      <b>"U"</b>,
    ],
  ],
  cb3: codebotSteps,
  codeair: [
    'Connect a USB cable between this computer and the CodeAIR',
    [
      'Press and hold button ',
      <b>"B0"</b>,
    ],
    [
      'Press and release button ',
      <b>"RST"</b>,
    ],
    [
      'Release button ',
      <b>"B0"</b>,
    ],
  ],
}

const LABELLED_IMAGE = {
  cb2: {},
  cb3: {
    imgSrc: 'cb3_head_on.png',
    imgStyle: {height: 600, margin:'auto'},
    labels: [
      { x: 157, y: 87, text: 'POWER' },
      { x: 173, y: 188, text: 'BTN-0' },
      { x: 13, y: 117, text: 'REBOOT', orientation: 'top' },
    ],
  },
  codex: {
    imgSrc:'CodexLarge.png',
    imgStyle: {height: 420, paddingLeft: 150, paddingBottom: 25, margin:'auto'},
    labels: [
      { x: 73, y: 95, text: 'U' },
      { x: 118, y: 284, text: 'RESET', orientation: 'top' },
    ],
  },
  codeair: {
    imgSrc: 'AIR Overhead_3_CLEAR.png',
    imgStyle: {paddingLeft: 23, height: 750, margin:'auto'},
    labels: [
      { x: 154, y: 103, text: 'B0' },
      { x: 46, y: 247, text: 'RST' },
    ],
  },
}


const DEVICES = {
  cb2: {
    title: 'CodeBot CB2',
    img: '/pub/cb2-transparent.png',
    // bg: 'white',
    imgStyle: {height: 135, paddingTop: 10},
    pid: 0xdf11,
    vid: 0x0483,
    resetButtonName: 'RESET',
    name: 'ESP32-S2',
    target: Targets.USB_CB2,
    labeledImage: LABELLED_IMAGE.cb2,
    steps: FLASH_STEPS.cb2,
    firmware: FIRMWARE_VERSIONS.cb2,
  },
  cb3: {
    title: 'CodeBot CB3',
    img: '/pub/CB3-Top.png',
    imgStyle: {},
    pid: 0x0002,
    vid: 0x303A,
    resetButtonName: 'REBOOT',
    name: 'ESP32-S2',
    target: Targets.USB_CB3,
    labeledImage: LABELLED_IMAGE.cb3,
    steps: FLASH_STEPS.cb3,
    firmware: FIRMWARE_VERSIONS.cb3,
  },
  codex: {
    title: 'CodeX',
    img: '/pub/CodexLarge.png',
    imgStyle: {height: 165},
    pid: 0x0002,
    vid: 0x303A,
    name: 'ESP32-S2',
    resetButtonName: 'RESET',
    target: Targets.USB_CODEX,
    labeledImage: LABELLED_IMAGE.codex,
    steps: FLASH_STEPS.codex,
    firmware: FIRMWARE_VERSIONS.codex,
  },
  codeair: {
    title: 'CodeAIR',
    img: '/pub/CodeAIR_Large.png',
    imgStyle: {height: 125},
    pid: 0x1001,
    vid: 0x303A,
    name: 'USB-JTAG',
    resetButtonName: 'RST',
    target: Targets.USB_CODEAIR,
    labeledImage: LABELLED_IMAGE.codeair,
    steps: FLASH_STEPS.codeair,
    firmware: FIRMWARE_VERSIONS.codeair,
  },
}

function getDeviceIdByTarget(target) {
  for (const [key, device] of Object.entries(DEVICES)) {
    if (device.target === target) {
      return key
    }
  }
}

export { DEVICES, getDeviceIdByTarget }