const storeDefault = {
  validatorTestData: [],
  totalTests: 0,
  missionInfoByFlowId: {},
}

var store
function setLessonEditorStoreDefaults() {
  store = storeDefault
}
setLessonEditorStoreDefaults()

const IDENTIFIER = '89u32rnfjakd'
function encodeValidatorTestData(id, idx) {
  return `${id}${IDENTIFIER}${idx}`
}

function decodeValidatorTestData(encodedTestData) {
  const [id, idx] = encodedTestData.split(IDENTIFIER)
  return { id, idx }
}

function readValidatorTestDataFromStore() {
  return store.validatorTestData.map(x => decodeValidatorTestData(x))
}

function setValidatorTestInStore(id, idx) {
  store.validatorTestData.push(encodeValidatorTestData(id, idx))
}

function removeValidatorTestFromStore(id, idx) {
  const test = encodeValidatorTestData(id, idx)
  store.validatorTestData = store.validatorTestData.filter(x => x !== test)
}

function iterateTotalTests() {
  store.totalTests += 1
}

function resetTotalTests() {
  store.totalTests = 0
}

function decrementTotalTests() {
  store.totalTests -= 1
}

function getTotalTests() {
  return store.totalTests
}

function setMissionInfoByTestFlowIdInStore(mission) {
  for (const flow of mission.flow) {
    store.missionInfoByFlowId[flow.id] = { missionTitle: mission.title, missionId: mission.id }
  }
}

function setFlowInfoByTestFlowIdInStore(flowItem) {
  store.missionInfoByFlowId[flowItem.id] = { ...store.missionInfoByFlowId[flowItem.id], flowTitle: flowItem.title }
}

function readMissionInfoByTestFlowIdFromStore(flowId) {
  return store.missionInfoByFlowId[flowId] ?? {}
}

export {
  setValidatorTestInStore,
  readValidatorTestDataFromStore,
  removeValidatorTestFromStore,
  encodeValidatorTestData,
  decodeValidatorTestData,
  iterateTotalTests,
  resetTotalTests,
  decrementTotalTests,
  getTotalTests,
  setMissionInfoByTestFlowIdInStore,
  readMissionInfoByTestFlowIdFromStore,
  setFlowInfoByTestFlowIdInStore,
}