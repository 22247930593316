import { readAllMissionProgressFromUserProgressStore } from '../user-progress-store'
import { missionPackContainsScoredMission } from '../../lesson-content/lesson-content-use-cases'
import { getActiveMissionPack } from '../../content-director/content-director-use-cases/getActiveMissionPack'
import { readMissionPackStoreByPackId } from '../../lesson-content/lesson-content-store'
import { getLessonContentItemByScoreId } from '../../contests/contest-use-cases/getLessonContentItemByScoreId'
// TODO: Implement this when we support flow level leaderboards.
function calculateScoreOverFlowItem(flowId) {
  // const flowItem = readFlowStoreByFlowId(flowId)
  return 0
}

function calculateScoreOverMission(missionId) {
  let score = 0
  const progress = readAllMissionProgressFromUserProgressStore()
  if (progress[missionId] && 'flow' in progress[missionId]) {
    progress[missionId].flow.forEach((flowItem) => {
      if ('bestScore' in flowItem) {
        score += flowItem.bestScore
      }
    })
  }
  return score
}

function calculateScoreOverMissionPack(missionPackId) {
  let score = 0
  const pack = readMissionPackStoreByPackId(missionPackId)
  pack.missions.forEach(({ id }) => {
    score += calculateScoreOverMission(id)
  })
  return score
}

const placeholder = 'N/A'
function formatTotalScoreEarnedByMissionPack() {
  const activeMissionPack = getActiveMissionPack()
  if (!missionPackContainsScoredMission(activeMissionPack.id)) {
    return placeholder
  }

  return calculateScoreOverMissionPack(activeMissionPack.id)
}

function getTotalScoreEarnedByMissionPack() {
  try {
    return formatTotalScoreEarnedByMissionPack()
  } catch {
    return placeholder
  }
}

// Score id can be packId, missionId, or flowId
function formateTotalScoreEarnedByScoreId(scoreId) {
  const { type } = getLessonContentItemByScoreId(scoreId)
  if (type === 'pack') {
    return calculateScoreOverMissionPack(scoreId)
  }

  if (type === 'mission') {
    return calculateScoreOverMission(scoreId)
  }

  if (type === 'flowItem') {
    return calculateScoreOverFlowItem(scoreId)
  }

  return 0
}

function getTotalScoreEarnedByScoreId(scoreId) {
  try {
    return formateTotalScoreEarnedByScoreId(scoreId)
  } catch {
    return 0
  }
}

export { getTotalScoreEarnedByMissionPack, getTotalScoreEarnedByScoreId }