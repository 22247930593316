import { readAllMissionProgressFromUserProgressStore } from '../user-progress-store'

function formatTotalXPEarned() {
  let totalXp = 0
  const progress = readAllMissionProgressFromUserProgressStore()

  Object.values(progress).forEach(({ flow }) => {
    flow.forEach((flowItem) => {
      if (flowItem.type === 'objective') {
        if (flowItem.xp) {
          totalXp += parseInt(flowItem.xp)
        }
      } else {
        flowItem.questions.forEach(({ xp }) => {
          if (xp) {
            totalXp += parseInt(xp)
          }
        })
      }
    })
  })

  return totalXp
}

function getTotalXPEarned() {
  try {
    return formatTotalXPEarned()
  } catch {
    return 0
  }
}

export { getTotalXPEarned }