import { FileOperation, FileOpStatus, FILE_DATA_BUFFER_SIZE } from './utils/file-operation-utils'

// DO NOT MODIFY NUMBERS THAT ALREADY EXIST!!!
export const UartCmd = {
  INIT: 0,
  DEINIT: 1,
  READ: 2,
  READLINE: 3,
  WRITE: 4,
  RST_READ_BUF: 5,
}

// main Thread Side
export class UARTBuffer {
  constructor(data, peripherals, hw) {
    // {tx, rx, baudrate, bits, parity, stopBits, timeout, rxBufSize}
    this.tx = data.tx
    this.rx = data.rx
    this.baudrate = data.baudrate
    this.bits = data.bits
    this.parity = data.parity
    this.stopBits = data.stopBits
    this.timeout = data.timeout
    this.rxBufSize = data.rxBufSize
    this.peripherals = peripherals
    this.hw = hw
    this.readBuffer = []
  }

  read = (nbytes) => {
    // copy the read buffer and send back using the hw interface
    const byteArray = []
    if (byteArray.length > FILE_DATA_BUFFER_SIZE) {
      // TODO: start sending back FileOpStatus.PARTIAL_FILE_READ
    }
    this.hw.sendFileData(FileOpStatus.COMPLETE_FILE_READ, byteArray)
  }

  readline = () => { // only read up until the first '\n' character
    // copy the read buffer and send back using the hw interface
    const byteArray = []
    // TODO: copy the read buffer and send back using the hw interface
    if (byteArray.length > FILE_DATA_BUFFER_SIZE) {
      // TODO: start sending back FileOpStatus.PARTIAL_FILE_READ
    }
    this.hw.sendFileData(FileOpStatus.COMPLETE_FILE_READ, byteArray)
  }

  write = (buf) => {
    const uartPeriphs = this.peripherals.filter(p => p.hasUart())
    for (let i = 0; i < uartPeriphs.length; i++) {
      uartPeriphs[i].uartWrite(this.tx, this.baudrate, this.bits, this.parity, this.stopBits, buf)
    }
  }

  resetReadBuf = () => {
    this.readBuffer = []
  }

  cmd = (cmd, data) => {
    switch (cmd) {
      case UartCmd.READ: // optional nbytes
        this.read(data)
        break
      case UartCmd.READLINE: // null
        this.readline()
        break
      case UartCmd.WRITE: // array of ints (0 - 255)
        this.write(data)
        break
      case UartCmd.RST_READ_BUF: // null
        this.resetReadBuf()
        break
      default:
        break
    }
  }
}

// Code Worker Thread Side
class UART {
  constructor(id, tx, rx, baudrate, bits, parity, stopBits, timeout, rxBufSize, busio) {
    this.tx = tx
    this.rx = rx
    this.baudrate = baudrate
    this.bits = bits
    this.parity = parity
    this.stopBits = stopBits
    this.timeout = timeout
    this.rxBufSize = rxBufSize
    this.busio = busio
    this.id = id
    this.busio.postRPC('uartCmd', [UartCmd.INIT, this.id, {tx, rx, baudrate, bits, parity, stopBits, timeout, rxBufSize}])
    this.initialized = true
  }

  deinit = () => {
    this.busio.postRPC('uartCmd', [UartCmd.DEINIT, this.id, null])
    this.initialized = false
    return 0
  }

  read = (nbytes) => {
    if (!this.initialized) {
      return [1, []]
    }
    this.busio.hw.preFileOp()
    this.busio.postRPC('uartCmd', [UartCmd.READ, this.id, nbytes])
    const buf = this.busio.hw.uartReadRequest(this.busio.postRPC, FileOperation.READ_BINARY)
    return [0, buf]
  }

  readline = () => {
    if (!this.initialized) {
      return [1, []]
    }
    this.busio.hw.preFileOp()
    this.busio.postRPC('uartCmd', [UartCmd.READLINE, this.id, null])
    const buf = this.busio.hw.fileReadRequest(this.busio.postRPC, FileOperation.READ_BINARY)
    return [0, buf]
  }

  write = (buf) => {
    if (!this.initialized) {
      return 1
    }
    this.busio.postRPC('uartCmd', [UartCmd.WRITE, this.id, buf.source])
    return 0
  }

  resetInputBuffer = () => {
    if (!this.initialized) {
      return 1
    }
    this.busio.postRPC('uartCmd', [UartCmd.RST_READ_BUF, this.id, null])
    return 0
  }
}

export default class BusIO {
  constructor(postRPC, hw) {
    this.busInstances = []
    this.postRPC = postRPC
    this.hw = hw
  }

  uartInit = (tx, rx, baudrate, bits, parity, stop, timeout, receiver_buffer_size) => {
    const uart = new UART(this.busInstances.length, tx, rx, baudrate, bits, parity, stop, timeout, receiver_buffer_size, this)
    this.busInstances.push(uart)
    return [0, uart]
  }

  cleanup = () => {
    this.busInstances = []
  }
}
