// Auditorium
import { Entity, simScene } from '../SimScene'
import * as BABYLON from '@babylonjs/core'
import { assetPath, makeImpostor, getRootMesh, disposePromise, hitCache } from '../utils/BabylonUtils'
import Auditorium1 from '../assets/auditorium.glb'
import StudioSoftboxEnv from '../assets/StudioSoftbox.env'
// import { localAxes } from '../utils/BabylonUtils'
import Balloon from '../assets/Balloon.glb'

const GROUND_FRICTION = 50
const GROUND_RESTITUTION = 0.7

class SimLights extends Entity {
  load = async (scene) => {
    this.scene = scene

    // Ambient lighting provided by HDR environment
    // this.envTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    this.envTexture = await hitCache('Env_StudioSoftbox', () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    })
    scene.environmentTexture = this.envTexture

    // Directional shadow-casting light. For crisp shadows, position light so raycast from it in specified
    // direction will be occluded by shadow-casters of interest (e.g. CodeBot)
    this.light = new BABYLON.DirectionalLight('dir01', new BABYLON.Vector3(-1, -1, -1), scene)
    this.light.position = new BABYLON.Vector3(40, 14, 10)
    this.light.intensity = 2.0

    // Visualize directional light source (beautiful yellow sun!)
    // const lightSphere = BABYLON.Mesh.CreateSphere('sphere', 10, 2, scene)
    // lightSphere.position = this.light.position
    // lightSphere.material = new BABYLON.StandardMaterial('light', scene)
    // lightSphere.material.emissiveColor = new BABYLON.Color3(1, 1, 0)
    // localAxes(3, scene).parent = lightSphere

    this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light)
    this.shadowGenerator.useExponentialShadowMap = true

    // Note: A potential performance improvement (if shadows are found to be too burdensome) is
    //       to switch to using imposters as the shadow casters. The following line allows transparent
    //       meshes to cast shadows. This will require changing imposters to use "visibility=0" rather
    //       than "isVisible=false", since the former sets 100% transparent while the latter removes
    //       from render altogether. Would need to test to see if this helps performance!!
    // this.shadowGenerator.setTransparencyShadow(true)
  }

  getShadowGenerators = () => {
    return [this.shadowGenerator]
  }

  unload = async () => {
    const disposeList = [this.shadowGenerator, this.light]
    await Promise.all(disposeList.map(disposePromise))
    this.scene.environmentTexture = null
  }
}

class SimGround extends Entity {
  load = async (scene) => {
    // Create the invisible ground parent mesh
    this.ground = BABYLON.Mesh.CreateGround('ground', 200, 200, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions
    this.ground.isVisible = false

    let path, name
    [path, name] = assetPath(Auditorium1)

    // Import the Auditorium environment
    this.auditoriumMesh = await hitCache('AuditoriumModels', async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync (path, name, scene)
    })
    // Currently NOT cloning environment model, instead trying to keep makeImpostor() and other
    // operations that modify the mesh idempotent. That means we can't dispose this, and instead
    // must add/remove it from scene.
    this.auditoriumMesh.addAllToScene()
    const meshes = this.auditoriumMesh.meshes
    this.rootMesh = meshes[0]

    // Set the ground mesh as the root node's parent
    this.rootMesh.parent = this.ground

    // Recurse through all meshes in this scene
    await Promise.all(meshes.map(async (mesh) => {
      // Enable shadows for only the "Plane" meshes
      if (mesh.name === 'Auditorium_Stage.007') {
        mesh.receiveShadows = true
      }

      // Add the ceiling light meshes with a white glow to the glowMeshes map
      if (mesh.name.substring(0, 15) === 'Auditorium_Lamp') {
        simScene.glowMeshes.set(mesh.name, new BABYLON.Color4(1, 1, 1, 0.15))
        simScene.glLeds.addExcludedMesh(mesh) // Exclude this mesh from the LED glow layer
      }

      makeImpostor(mesh, this.ground)
      mesh.freezeWorldMatrix()
    }))

    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
    this.ground.freezeWorldMatrix()
  }

  unload = async () => {
    // Don't dispose environment model, just remove from scene
    this.rootMesh.parent = null
    this.auditoriumMesh.removeAllFromScene()

    await disposePromise(this.ground)
  }
}

class SimObstacles extends Entity {
  constructor(ground) {
    super()
    this.ground = ground
    this.balloons = []
  }

  createBalloon = async (id, x, y, z, color, rotation, scene) => {
    let path, name
    [path, name] = assetPath(Balloon)

    /* Compound collider method:  (see: https://doc.babylonjs.com/divingDeeper/physics/compoundBodies#compound-covering-meshes-to-the-complex-mesh)
       1. Create standard meshes to fit over irregular mesh (balloon)
       2. Parent all standard meshes to irregular mesh
       3. Create physics impostors for all standard meshes
       4. Create physics impostor for irregular mesh
    */

    const centerMassPosition = new BABYLON.Vector3(0, 0, 0)
    const startPosition = new BABYLON.Vector3(x, y, z)
    const BALLOON_FRICTION = 0.1
    const BALLOON_RESTITUTION = 0.9
    const BALLOON_MASS = 1.0

    const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
    const balloon = getRootMesh(meshes)
    balloon.position = centerMassPosition
    const imps = []

    // Create and parent standard meshes - no Physics yet, since this is a compound collider
    await Promise.all(meshes.map(async (mesh) => {
      const imp = makeImpostor(mesh, balloon, null)
      if (imp) {
        imps.push(imp)
      }
    }))

    // Next create PhysicsImpostors
    await Promise.all(imps.map(async ([impObj, impType]) => {
      impObj.physicsImpostor = new BABYLON.PhysicsImpostor(
        impObj, impType, { mass: 0, friction: BALLOON_FRICTION, restitution: BALLOON_RESTITUTION }
      )
      impObj.checkCollisions = false  // disable camera collisions
    }))

    // Lastly, create parent physics imposter
    balloon.physicsImpostor = new BABYLON.PhysicsImpostor(
      balloon,
      BABYLON.PhysicsImpostor.NoImpostor,
      { mass: BALLOON_MASS, friction: BALLOON_FRICTION, restitution: BALLOON_RESTITUTION }
    )

    balloon.name = id
    balloon.rotate(BABYLON.Axis.X, rotation, BABYLON.Space.WORLD)
    balloon.position = startPosition
    balloon.material.albedoColor = color
    BABYLON.Tags.AddTagsTo(balloon, 'BotCollider')
    // localAxes(1, scene).parent = balloon

    this.balloons.push(balloon)
  }

  load = async (scene, params) => {
    if (!params.balloons) {
      return
    }

    // Create a bunch of balloons on the stage

    // Potential future performance improvement:
    //  - Modify createBalloon() so we can pass-in a previously loaded mesh and it will use instancing.
    const colors = [BABYLON.Color3.Red(), BABYLON.Color3.Blue(), BABYLON.Color3.Green(), BABYLON.Color3.Yellow()]
    const sample = arr => arr[Math.floor(Math.random() * arr.length)]

    const promises = []

    const Y_START = 14
    const X_START = -7
    const X_SPACING = 5
    const Z_START = -5
    const Z_SPACING = 5
    let i = 0
    for (let row = 0; row < 3; ++row) {
      for (let col = 0; col < 4; ++col) {
        const color = sample(colors)
        const rotation = Math.random() * Math.PI
        const x = X_START + col * X_SPACING
        const z = Z_START + row * Z_SPACING
        promises.push(this.createBalloon('balloon' + i, x, Y_START, z, color, rotation, scene))
        ++i
      }
    }
    await Promise.all(promises)
  }

  unload = async () => {
    await Promise.all(this.balloons.map(disposePromise))
    this.balloons.length = 0
  }
}

class Auditorium extends Entity {
  constructor() {
    super()
    this.name = 'Auditorium'
    this.lights = new SimLights()
    this.ground = new SimGround()
    this.obstacles = new SimObstacles(this.ground.ground)
    this.defaultParams = {
      'balloons': false,
      'bot': [
        {
          'playerPosition': [0, 10, -5],  // Raised up one meter (10 decimeters) for stage placement above floor
          'playerRotation': 180,  // Facing the audience
        },
      ],
    }
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false
    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.obstacles.load(scene, this.params),
    ])
  }
  unload = () => Promise.all([
    this.lights.unload(),
    this.ground.unload(),
    this.obstacles.unload(),
  ])
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
}

export default Auditorium