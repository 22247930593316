import logger from './logger'
const fetchHandler = async (url, options, acceptedErrorCodes) => {
  try {
    logger.info(`Fetching ${url}`)
    const resp = await fetch(url, options)
    if (resp.ok) {
      logger.debug(`Successfully fetched "${url}"`)
      return resp
    }

    // If the response code is not an acceptedErrorCode, log the error
    if (!(acceptedErrorCodes?.length > 0 && acceptedErrorCodes.find(errCode => errCode === resp.status))) {
      logger.error(`Failed to fetch "${url}" with error status ${resp.status}`)
    }

    return resp
  } catch (err) {
    logger.info(`Failed to fetch "${url}"`)
    logger.error(err)
    throw err
  }
}


export default fetchHandler