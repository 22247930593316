import * as BABYLON from '@babylonjs/core'
import { Entity } from '../SimScene'
import '@babylonjs/loaders'
import CodeAIR_body from '../assets/CodeAirBody.glb'
import { disposePromise } from '../utils/BabylonUtils'

class CodeAIRModel extends Entity {
  constructor(player_num) {
    super()

    this.deviceType = 'CodeAIR'
    this.player_num = player_num
    this.uniqueId = `${this.deviceType}_${this.player_num}`

    this.loadedObservable = new Set()   // add/delete callbacks here: observer(isLoaded)

    // Center of Gravity
    // Relative offset of all rigid bodies from the center of airBody, which we historically defined as the center of the PCB.
    this.cog = new BABYLON.Vector3(0, 0, 0)

    this.airBody = null

    this.initialPosition = new BABYLON.Vector3(0, 0, 0)
    this.initialRotation = Math.PI / 2

    this.shadowCasters = []
    this.shadowModelChanged = false

    // Peripherals
    this.peripherals = []

    this.isLoaded = false
  }

  notifyLoadedObservers = (scene) => {
    // Notify Observers when model is loaded / unloaded
    this.loadedObservable.forEach(observer => observer(scene))
  }

  getRootMesh = () => {
    return this.airBody
  }

  getShadowCasters = () => {
    return this.shadowCasters
  }

  hasShadowModelChanged = () => {
    const hasChanged = this.shadowModelChanged
    this.shadowModelChanged = false
    return hasChanged
  }

  unload = async () => {
    const disposeList = []

    // Remove from scene and cleanup memory
    if (this.airBody) {
      disposeList.push(this.airBody)
    }

    this.airBody = null
    this.initialPosition = new BABYLON.Vector3(0, 1, 0)

    const periphUnloads = this.peripherals.map(p => p.unload())

    this.shadowCasters = []
    this.shadowModelChanged = false

    await Promise.all([...disposeList.map(disposePromise), ...periphUnloads])

    this.isLoaded = false
    this.notifyLoadedObservers(false)
  }

  load = async (scene) => {
    // Create a new mesh to be our root for the physics-enabled "compound" object
    this.airBody = new BABYLON.Mesh('CodeAIR', scene)
    this.airBody.position.addInPlace(this.initialPosition)  // Offset by environment-specified position
    this.airBody.rotation.y = this.initialRotation
    this.airBody.scaling = new BABYLON.Vector3(10.0, 10.0, 10.0)

    // Enable collision checks for Camera
    this.airBody.checkCollisions = true

    const promises = []
    this.peripherals = [] // TODO: Allow peripheral input from scene

    // Load GLB visual model components
    promises.push(this.loadModel(scene))

    await Promise.all(promises)

    // this.loadSounds(scene)

    this.isLoaded = true
    this.notifyLoadedObservers(scene)
  }

  assetPath = asset => (
    // Extract file path/name from Webpack static media location
    [asset.substring(0, asset.lastIndexOf('/') + 1), asset.substring(asset.lastIndexOf('/') + 1)]
  )

  loadModel = async (scene) => {
    const loadBody = async () => {
      const [path, name] = this.assetPath(CodeAIR_body)
      // const [path, name] = this.assetPath(CodeX_body)
      const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
      const bodyMesh = meshes[0]  // root transform
      bodyMesh.rotation = new BABYLON.Vector3(0, 0, 0)
      bodyMesh.parent = this.airBody
      bodyMesh.position.addInPlace(this.cog)
      this.shadowCasters.push(bodyMesh)
      this.shadowModelChanged = true
    }

    await Promise.all([loadBody()])
  }
}

export { CodeAIRModel }