import authFetchHandler from '../../tools/auth/authFetchHandler'
import { endUserSession } from '../user-session/user-session-use-cases/endUserSession'
import { userFileController } from '../user-files/user-file-controller'
import { userFileStore } from '../user-files/user-file-store'
import { isNetworkError } from './is-network-error'
import { userSessionController } from '../user-session/user-session-controller'

async function handleErrorState() {
  userFileController.handleUserProgressFetchErrorState()
  await endUserSession()
  throw new Error('failed to fetch low tolerance data')
}

const RETRY_TIMES = 5
async function lowToleranceAuthFetcher(url, options = {}, acceptableStatusCodes = [], initialization=false) {
  for (let i = 0; i < RETRY_TIMES; i++) {
    try {
      if (!initialization && userFileStore.savingIsDisabled()) {
        return
      }
      const resp = await authFetchHandler(url, options, acceptableStatusCodes)
      const status = resp.status
      if ([200, ...acceptableStatusCodes].includes(status)) {
        return resp
      }
    } catch (err) {
      if (!initialization && isNetworkError(err)) {
        userSessionController.handleSessionWentOffline()
        return
      }
    }
  }

  if (!initialization && userFileStore.savingIsDisabled()) {
    return
  }
  await handleErrorState()
}

export { lowToleranceAuthFetcher }