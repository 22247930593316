import { readMissionStoreByMissionId } from '../../lesson-content/lesson-content-store'
import { readMissionProgressFromUserProgressStore, readFlowProgressFromUserProgressStore } from '../user-progress-store'

function isFlowItemAvailableAndUncompleted(missionStarted, flowId, lastFlowId) {
  const flowProgress = readFlowProgressFromUserProgressStore(flowId)
  const lastFlowProgress = readFlowProgressFromUserProgressStore(lastFlowId)

  if (flowProgress?.completed) {
    return
  }

  if (flowProgress?.started && !flowProgress?.completed) {
    return true
  }

  if (!flowProgress?.started && !flowProgress?.completed && lastFlowProgress?.completed) {
    return true
  }

  return !lastFlowId && missionStarted
}

async function findNextUncompletedFlowItem(missionId) {
  const mission = readMissionStoreByMissionId(missionId)
  if (!mission?.flow) {
    return
  }


  const missionProgress = readMissionProgressFromUserProgressStore(missionId)
  if (missionProgress?.completed) {
    return null
  }

  const missionStarted = !!missionProgress?.started
  for (const i in mission.flow) {
    const { id: flowId } = mission.flow[i]

    let lastFlowId
    if (i !== 0) {
      const lastFlowItem = mission.flow[i-1]
      lastFlowId = lastFlowItem?.id
    }

    const flowItemAvailableAndUncompleted = isFlowItemAvailableAndUncompleted(missionStarted, flowId, lastFlowId)
    if (flowItemAvailableAndUncompleted) {
      return flowId
    }
  }
  return mission?.flow[0]?.id
}

export { findNextUncompletedFlowItem }