import { getContestsFromStore } from '../contest-store.js'

function getContestBandLeaderboardFromScoreId(scoreId) {
  // To enable mission/objective level leaderboards, we need to find the mission pack from this lessonContent object.
  // const lessonContent = getLessonContentItemByScoreId(scoreId)
  // const contest = getContestsFromStore().find(contest => contest.missionPackId === lessonContent.pack.id)
  const contest = getContestsFromStore().find(contest => contest.id === 'hween_2024')

  const leaderboardsByScoreId = contest?.leaderboardsByScoreId
  if (!leaderboardsByScoreId) {
    return
  }

  const key = Object.keys(leaderboardsByScoreId)?.find(id => !id.startsWith('default'))
  if (!(key in leaderboardsByScoreId) || !leaderboardsByScoreId[key] || !(scoreId in leaderboardsByScoreId[key])) {
    return
  }
  return leaderboardsByScoreId[key][scoreId]
}

export { getContestBandLeaderboardFromScoreId }