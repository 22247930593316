/* eslint-disable import/no-webpack-loader-syntax */
import Worker from 'worker-loader!./CodeWorker.js'
import WorkerRPCmessenger from './WorkerRPCmessenger'
import { FileOpStatus } from './utils/file-operation-utils'
import { simFsController } from './SimFileSystemControl'
import { validatorController } from './ValidatorControl'
import { deserialize } from '@ungap/structured-clone'

export class MainRpcWorker extends WorkerRPCmessenger {
  // Main-thread control/comms with a Worker, used by CodeRunner.

  constructor(codeRunner) {
    super(new Worker(), 'Main')
    this.codeRunner = codeRunner
    this.pendingTimer = null
    this.readFileData = []
  }

  terminate = () => {
    clearTimeout(this.pendingTimer)
    this.worker.terminate()
  }

  // --- Call-ins from worker ---
  // TODO RE-order these

  sleep = (ms) => {
    this.codeRunner.modelController.notifySleep(ms)
  }

  codeError = (err) => {
    this.codeRunner.notifyUserProgramError(err)
  }

  stdout = (text) => {
    this.codeRunner.notifyStdout(text)
  }

  runComplete = () => {
    // console.log("Code Finished!")
    this.codeRunner.notifyRunComplete()
  }

  result = (val) => {
    console.log('result = ', val)
  }

  sharedBufferReceived = (runID) => {
    this.codeRunner.notifySharedBufferReceived(runID)
  }

  beginExecution = () => {
    this.codeRunner.notifyBeginExecution()
  }

  reportDebugState = (name, file, line, locals, globals) => {
    this.codeRunner.notifyDebugState(name, file, line, deserialize(locals), deserialize(globals))
  }

  fileRequest = async (filename, operation) => {
    const [status, data] = await simFsController.processFileRequest(filename, operation)
    this.codeRunner.modelController.hw.sendFileData(status, data)
  }

  fileRequestContinue = async () => {
    const [status, data] = await simFsController.readyNextFileData()
    this.codeRunner.modelController.hw.sendFileData(status, data)
  }

  fileAction = async (filename, operation, data) => {
    const status = await simFsController.processFileAction(filename, operation, data)
    this.codeRunner.modelController.hw.fileOpDone(status)
  }

  fileNotify = (filename, operation, data) => {
    validatorController.handleFileOp(filename, operation, FileOpStatus.NONE, data)
  }

  uartCmd = (cmd, id, data) => {
    this.codeRunner.modelController.uartCmd(cmd, id, data)
  }
}
