import React from 'react'
import { endUserSession } from '../content-manager/user-session/user-session-use-cases/endUserSession'
import { handleContentManagerOnUserSessionEnded } from '../content-manager/content-manager-use-cases'
import { setUserAuthCallbacks, hasCookieSessionIdInitializationCompleted, hasUserSessionInfoInitializationCompleted } from '../tools/auth/userAuthStore'

export const LoginActions = Object.freeze({
  // e.g. Login, Logout
  SIGNUP: Symbol('sign up'),
  EMAIL_LOGIN: Symbol('email login'),
  GOOGLE_LOGIN: Symbol('google login'),
  FORGOT_PASSWORD: Symbol('forgot password'),
  LOGOUT: Symbol('logout'),
  SET_DISPLAY_USER_LOGGED_OUT_MESSAGE: Symbol('set display user logged out message'),
})

const LoginStateContext = React.createContext()
const LoginDispatchContext = React.createContext()
var loginDispatch = () => {}
export const LoginProvider = ({ children }) => {
  const [user, setUser] = React.useState(hasUserSessionInfoInitializationCompleted())
  const [loginReady, setLoginReady] = React.useState(hasCookieSessionIdInitializationCompleted())
  const [displayUserLoggedOutMessage, setDisplayUserLoggedOutMessage] = React.useState(false)
  const [ltiLogoutPageVisible, setLTILogoutPageVisible] = React.useState(false)
  setUserAuthCallbacks(
    (usr) => {
      if (user && !usr) {
        handleContentManagerOnUserSessionEnded()
      }
      setUser(usr)
    },
    (userExists) => {
      if (!userExists) {
        handleContentManagerOnUserSessionEnded()
      }
      setLoginReady(true)
    }
  )


  const dispatch = async (action) => {
    switch (action.type) {
      case LoginActions.LOGOUT:
        const consumerKey = user.ltiConsumerKey
        await endUserSession()
        // This is likely a temporary fix. In a regular school system our current method of
        // logging out is probably acceptable. In the case that closing the tab is standard, simply
        // remove the if statement.
        // console.log(consumerKey)
        if (!!consumerKey) {
          setLTILogoutPageVisible(true)
        }
        break
      case LoginActions.SET_DISPLAY_USER_LOGGED_OUT_MESSAGE:
        setDisplayUserLoggedOutMessage(action.displayUserLoggedOutMessage)
        break
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }

  loginDispatch = dispatch
  return (
    <LoginStateContext.Provider value={{ user, loginReady, ltiLogoutPageVisible, displayUserLoggedOutMessage }}>
      <LoginDispatchContext.Provider value={dispatch}>
        {children}
      </LoginDispatchContext.Provider>
    </LoginStateContext.Provider>
  )
}

export { loginDispatch }
export const useLogin = () => {
  const state = React.useContext(LoginStateContext)
  const dispatch = React.useContext(LoginDispatchContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useLogin must be used within a child of a LoginProider')
  }
  return [state, dispatch]
}
