// Kitchen Environment
import { Entity, simScene } from '../../SimScene'
import * as BABYLON from '@babylonjs/core'
import { TemplateLights, TemplateGround, TemplateSounds } from '../EnvTemplate'
import {
  disposePromise, loadGLB,
} from '../../utils/BabylonUtils'
import PbrLightingTextureEnv from '../../assets/MistyBrightSky.env'
import EnvModel from '../../assets/HauntedHouse/Kitchen.glb'
import BoolModel from '../../assets/HauntedHouse/bool.glb'
import EnvThemeMusic from '../../assets/HauntedHouse/sounds/Kitchen.mp3'
import { Trinkets } from './Trinkets'
import { Waypoints } from '../CommonProps'
import LevelEndSound from '../../assets/HauntedHouse/sounds/LevelEnd.mp3'
import BoolSound from '../../assets/HauntedHouse/sounds/Bool.mp3'
import LanternUnhexSound from '../../assets/HauntedHouse/sounds/Jackolantern.mp3'
import SpiritReleaseSound from '../../assets/HauntedHouse/sounds/SpiritRelease.mp3'

const environmentName = 'Kitchen'

// Environment template options
const lightOptions = {
  envLightingName: 'MistyBrightSky',
  envLightingSource: PbrLightingTextureEnv,
  envIntensity: 0.2,
  fogMode: BABYLON.Scene.FOGMODE_NONE,  //BABYLON.Scene.FOGMODE_EXP2,
  fogDensity: 0.003,
  fogColor: new BABYLON.Color3(0.9, 0.9, 0.85),
}
const soundOptions = {
  envMusicName: 'KitchenThemeMusic',
  envMusicSource: EnvThemeMusic,
  envSoundFx: [
    {name: 'levelEndSound', source: LevelEndSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'boolSound', source: BoolSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'lanternUnhexSound', source: LanternUnhexSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'spiritReleaseSound', source: SpiritReleaseSound, options: { spatialSound: false, volume: 1.0 }},
  ],
}
const groundOptions = {
  envModelName: 'KitchenModel',
  envModelSource: EnvModel,
}

// Default environment params which the Mission Editor may override
const defaultEnvParams = {
  'bot': [
    {
      'playerPosition': [17, 0, 22],
      'playerRotation': 20,
    },
  ],
  'rotateCam': {  // Angles for rotate camera. Usual default is {alpha:2.5, beta:1.3, radius:4}
    alpha: 1,  // azimuth radians
    beta: 1.5, // elevation radians
    radius: 4, // distance from bot
  },
  'trinkets': [
    {
      pos: [5, 0, 5],
      rot: 0,
      scale: 1.0,
      scoreValue: 10,
      shape: null,   // null for random choice. Choices are: 'cat', 'ghost', 'pumpkin'
    },
  ],
  'mats': false,  // show mats beneath pumpkins
  'hexPumpkins': true, // show evil glow and hex codes over pumpkins
  'boolMessage': {  // show bool's message card
    pos: [15.7, 3, 19.25],
    riddleType: 'logic',   // 'logic', 'bitwise bin', 'bitwise hex'
  },
  'boolGhost' : {   // null for no ghost
    pos: [13, 0, 15],
    rot: 30,
  },
}

// Interactives - things to interact with in the environment:
//  * Movable objects, treats, NPCs, etc.
class Interactives extends Entity {
  constructor(mainEnv) {
    super()
    this.env = mainEnv
    this.waypoints = new Waypoints()
    this.pumpkins = new Array(4)
    this.zones = new Array(4)
    this.pumpkinBaselineEmissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1)
    this.pumpkinBaselineEmissivity = 1.0
    this.pumpkinEvilEmissiveColor = new BABYLON.Color3(0.4, 0.0, 0.1)
  }

  loadMessageCard = (message, pos, rot) => {
    // Creates and returns a new message card. Will dispose on unload.
    const rotation = [0, rot, -90]
    const rotationRadians = rotation.map(BABYLON.Tools.ToRadians)
    const pad = this.waypoints.createPad(message, pos, rotationRadians)
    this.disposeList.push(pad)
    pad.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_Y  // Face the camera!
    return pad
  }

  loadGhost = async (pos, rot) => {
    this.ghost = await loadGLB(BoolModel, 'BoolGhost', 1.0, pos, rot)
    return this.ghost
  }

  load = async (scene, params) => {
    this.scene = scene
    this.params = params
    this.timers = []  // timer IDs for cleanup
    this.disposeList = []

    const interactivesPromises = []

    if (params.boolGhost) {
      interactivesPromises.push(this.loadGhost(params.boolGhost.pos, params.boolGhost.rot))
    }

    this.meshes = await Promise.all(interactivesPromises)
    this.disposeList.push(...this.meshes)
  }

  unload = async () => {
    // Stop any active timers
    this.timers.forEach(id => clearTimeout(id))

    await Promise.all(this.disposeList.map(disposePromise))
  }

  configure = () => {
    // Evil glow and load HEX cards over pumpkins if requested
    if (this.params.hexPumpkins) {
      this.lanternUnhexSfx = this.env.sounds.cloneSfx('lanternUnhexSound')

      this.animPulseEmissiveIntensity = new BABYLON.Animation('pulseIntensity', 'material.emissiveIntensity', 30,
        BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)

      this.animPulseEmissiveIntensity.setKeys([
        { frame: 0, value: 1.0 },
        { frame: 5, value: 10.0 },
        { frame: 10, value: 2.0 },
        { frame: 15, value: 10.0 },
        { frame: 30, value: 1.0 },
      ])
    
      this.pumpkins.forEach((p, index) => {
        this.enchantPumpkin(index, true)
      })
    }

    if (this.params.mats) {
      this.zones.forEach((z, index) => {
        z.isVisible = true
      })
    }

    if (this.params.boolGhost) {
      this.spiritReleaseSfx = this.env.sounds.cloneSfx('spiritReleaseSound')

      const anim = this.ghost.animationGroups[0]  // Just one animation
      anim.speedRatio = 0.2
      anim.play(true)

      if (!this.params.boolSilent) {
        this.boolSfx = this.env.sounds.cloneSfx('boolSound')
        anim.onAnimationGroupLoopObservable.addOnce(() => this.boolSfx.play())
      }
    }

    if (this.params.boolMessage) {
      const pos = this.params.boolMessage.pos
      this.ghostMessage = this.loadMessageCard('', pos, 90)
      const riddleType = this.params.boolMessage.riddleType
      this.ghostMessage.name = 'GhostMessage'
      simScene.glowMeshes.set(this.ghostMessage.name, new BABYLON.Color4(0.1, 0.1, 0.1, 1.0))

      this.createRiddle(riddleType)
    }
  }

  createRiddle = (riddleType) => {
    if (riddleType === 'logic') {
      const p1 = Math.random() > 0.5
      const p2 = Math.random() > 0.5
      const opAnd = Math.random() > 0.5
      const userLed = Math.trunc(Math.random() * 8)
      const line1 = 'BooooooooL!'
      const line2 = `LED ${userLed}`
      const line3 = (p1 ? 'True' : 'False') + (opAnd ? ' and ' : ' or ') + (p2 ? 'True' : 'False') + ' ?'
      this.riddleAnswer = opAnd ? (p1 && p2) : (p1 || p2)
      const bit = 1 << Number(userLed)
      this.riddleAnswer = this.riddleAnswer ? bit : (0xff & ~bit)
      const font='50px monospace'
      const font2='70px monospace'
      this.ghostMessage._dynamicTexture.drawText(line1, 60, 50, 'bold ' + font, 'white', 'green', true, true)
      this.ghostMessage._dynamicTexture.drawText(line2, 100, 140, font2, 'red', null, true, true)
      this.ghostMessage._dynamicTexture.drawText(line3, 20, 210, font, 'white', null, true, true)
    } else if (riddleType === 'bitwise bin') {
      const p1 = Math.trunc(Math.random() * 256)
      const p2 = Math.trunc(Math.random() * 256)
      const opAnd = Math.random() > 0.5
      const line1 = 'Bitwise Binary!'
      const line2 = '0b' + p1.toString(2).padStart(8, '0')
      const line3 = (opAnd ? ' and ' : ' or ')
      const line4 = '0b' + p2.toString(2).padStart(8, '0') + ' ?'
      this.riddleAnswer = opAnd ? (p1 & p2) : (p1 | p2)
      const font='50px monospace'
      this.ghostMessage._dynamicTexture.drawText(line1, 30, 40, font, 'white', 'green', true, true)
      this.ghostMessage._dynamicTexture.drawText(line2, 60, 110, font, 'white', null, true, true)
      this.ghostMessage._dynamicTexture.drawText(line3, 140, 170, font, 'white', null, true, true)
      this.ghostMessage._dynamicTexture.drawText(line4, 60, 230, font, 'white', null, true, true)
    } else if (riddleType === 'bitwise hex') {
      const p1 = Math.trunc(Math.random() * 256)
      const p2 = Math.trunc(Math.random() * 256)
      const opAnd = Math.random() > 0.5
      const line1 = 'Bitwise Hex!'
      const line2 = '0x' + p1.toString(16).padStart(2, '0').toUpperCase()
      const line3 = (opAnd ? 'and' : 'or')
      const line4 = '0x' + p2.toString(16).padStart(2, '0').toUpperCase() + ' ?'
      this.riddleAnswer = opAnd ? (p1 & p2) : (p1 | p2)
      const font='50px monospace'
      this.ghostMessage._dynamicTexture.drawText(line1, 40, 40, font, 'white', 'green', true, true)
      this.ghostMessage._dynamicTexture.drawText(line2, 80, 110, font, 'white', null, true, true)
      this.ghostMessage._dynamicTexture.drawText(line3, 100, 170, font, 'white', null, true, true)
      this.ghostMessage._dynamicTexture.drawText(line4, 80, 230, font, 'white', null, true, true)
    }  
  }

  // Validator will call this to un-enchant each pumpkin
  enchantPumpkin = (index, isEvil) => {
    const hexCode = ['84', '2C', '48', '9E'][index]
    const offset = [
      {x:1, y:3.5, z:0},
      {x:1, y:4.9, z:1},
      {x:1, y:3.0, z:1},
      {x:0, y:3.6, z:1},
    ][index]
    const p = this.pumpkins[index]

    if (isEvil) {
      p.material.emissiveColor = this.pumpkinEvilEmissiveColor
      p.messageCard = this.loadMessageCard('', [-p.position.x + offset.x, offset.y, p.position.z + offset.z], 90)
      p.messageCard._dynamicTexture.drawText(hexCode, null, null, '200px monospace', 'yellow', 'red', true, true)
      p.messageCard.material.emissiveColor.r = 0.1  // Faint red glow
    } else {
      this.lanternUnhexSfx.play()
      this.scene.environmentIntensity += 0.1  // A smidge brighter in the room
      p.material.emissiveColor = this.pumpkinBaselineEmissiveColor
      if (p.messageCard) {
        // p.messageCard.dispose()   // Physics crashes if we dispose here (no-contact collider registerd in bot's impostorCandidateList)
        p.messageCard.isVisible = false
      }

      this.scene.beginDirectAnimation(p, [this.animPulseEmissiveIntensity], 0, 30, false, 0.2)  // loop, speed
    }
  }

  // The 'init' functions ensure non-cloned env mesh is reset to baseline after each load.
  initPumpkin = (mesh) => {
    // Set pumpkin glow to baseline
    const num = parseInt(mesh.name[7]) - 1
    this.pumpkins[num] = mesh
    mesh.material.emissiveIntensity = this.pumpkinBaselineEmissivity
    mesh.material.emissiveColor = this.pumpkinBaselineEmissiveColor
  }
  initZone = (mesh) => {
    // Init zone (mat beneath pumpkin)
    const num = parseInt(mesh.name[4]) - 1
    this.zones[num] = mesh
    mesh.isVisible = false  // Not shown by default
  }

  vanquishBool = () => {
    // Look at George!
    const gFace = this.ghost.position.clone()
    gFace.y = 8
    this.scene.activeCamera.setTarget(gFace)
    
    // He's getting excited!
    const anim = this.ghost.animationGroups[0]
    anim.stop()
    anim.reset()
    anim.speedRatio = 1.5
    this.ghost.getChildMeshes(false, m => !!m.material).forEach(m => {
      m.material.emissiveColor.r=1
      m.material.emissiveColor.b=1
    })

    // Play release sound, and dispose ghost
    let loops = 4
    anim.onAnimationGroupLoopObservable.add((ev) => {
      if (--loops === 0) {
        this.ghost.dispose()
      }
    })
    this.spiritReleaseSfx.play()
    anim.play(true)
  }
}

class MainEnvironment extends Entity {
  constructor() {
    super()
    groundOptions.meshVisitor = this.meshVisitor
    this.name = environmentName
    this.lights = new TemplateLights(lightOptions)
    this.sounds = new TemplateSounds(soundOptions)
    this.ground = new TemplateGround(groundOptions)
    this.interactives = new Interactives(this)
    this.trinkets = new Trinkets(this.sounds)
    this.defaultParams = defaultEnvParams
  }
  meshVisitor = (mesh) => {
    if (mesh.name === 'Checkpoint1') {
      this.doorCheckpointMesh = mesh
    } else if (mesh.name.startsWith('Pumpkin')) {
      this.interactives.initPumpkin(mesh)
    } else if (mesh.name.startsWith('Zone')) {
      this.interactives.initZone(mesh)
    }
  }
  finalObjectiveHit = () => {
    // Called by validator after it detects "Checkpoint1" hit on the last Objective
    // Light doorway and stop bot
    if (this.doorCheckpointMesh) {
      this.doorCheckpointMesh.isVisible = true
      this.doorCheckpointMesh.material.emissiveColor.g = 1.0  // Glow Green!
      this.playerController.suspendActivity(true)  // Stop bot from moving through door
    }
  }
  missionCompleteMusic = () => {
    if (this.levelEndSfx) {
      this.levelEndSfx.play()
    }
  }
  load = async (scene) => {
    this.scene = scene
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false

    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.sounds.load(scene),
      this.interactives.load(scene, this.params),
      this.trinkets.load(this.params),
    ])

    // Configuration after async loading
    this.interactives.configure()
    this.trinkets.configure()
    this.levelEndSfx = this.sounds.cloneSfx('levelEndSound')

    // --- DEBUG ---
    // this.sounds.setVolume([0, 0.5])
    // window.simEnv = this
  }
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.sounds.unload(),
      this.ground.unload(),
      this.interactives.unload(),
      this.trinkets.unload(),
    ])
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
  setVolume = (volumeParams) => {
    this.sounds.setVolume(volumeParams)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController

    // Disallow keyboard CB buttons for this challenge (no driving!)
    if (this.playerController) {
      this.playerController.allowKeyboardButtons = false

      // Not using prox, save CPU
      this.playerController.sensorOverrides.prox = (num) => {}
    }
  }
}

export default MainEnvironment
