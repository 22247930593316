// Living Room Environment
import { Entity } from '../../SimScene'
import * as BABYLON from '@babylonjs/core'
import { TemplateLights, TemplateGround, TemplateSounds } from '../EnvTemplate'
import {
  disposePromise, loadGLB, loadGLBWithPhysics,
} from '../../utils/BabylonUtils'
import { SerialRunner } from '../../utils/SerialRunner'
import PbrLightingTextureEnv from '../../assets/MistyBrightSky.env'
import EnvModel from '../../assets/HauntedHouse/LivingRoom.glb'
import EnvThemeMusic from '../../assets/HauntedHouse/sounds/LivingRoom.mp3'
import RatBlastSound from '../../assets/HauntedHouse/sounds/RatBlast.mp3'
import SnackCrunchSound from '../../assets/HauntedHouse/sounds/SnackCrunch.mp3'
import TvSound from '../../assets/HauntedHouse/sounds/TVPoltergeist.mp3'
import PopcornBucketModel from '../../assets/HauntedHouse/PopcornBucket.glb'
import PopcornModel from '../../assets/HauntedHouse/Popcorn.glb'
import LevelEndSound from '../../assets/HauntedHouse/sounds/LevelEnd.mp3'

const environmentName = 'Living Room'

// Environment template options
const lightOptions = {
  envLightingName: 'MistyBrightSky',
  envLightingSource: PbrLightingTextureEnv,
  envIntensity: 0.2,
  fogMode: BABYLON.Scene.FOGMODE_EXP2,
  fogDensity: 0.003,
  fogColor: new BABYLON.Color3(0.9, 0.9, 0.85),
}
const soundOptions = {
  envMusicName: 'LivingRoomThemeMusic',
  envMusicSource: EnvThemeMusic,
  envSoundFx: [
    {name: 'ratBlastSound', source: RatBlastSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'snackCrunchSound', source: SnackCrunchSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'tvSound', source: TvSound, options: { spatialSound: true, loop: true, volume: 0.4, distanceModel:'linear', maxDistance:50 }},
    {name: 'levelEndSound', source: LevelEndSound, options: { spatialSound: false, volume: 1.0 }},
  ],
}
const groundOptions = {
  envModelName: 'LivingRoomModel',
  envModelSource: EnvModel,
}

// Default environment params which the Mission Editor may override
const defaultEnvParams = {
  'bot': [
    {
      'playerPosition': [0, 0, 0],
      'playerRotation': 180,
    },
  ],
  'popcorn': [
    {
      pos: [5, 0, 5],
      rot: 0,
      scale: 1.0,
      scoreValue: 10,
    },
  ],
  'buckets': [
    {
      pos: [-5, 1, -5],
      rot: 0,
      scale: 1.0,
    },
  ],
}

// Interactives - things to interact with in the environment:
//  * Movable objects, treats, NPCs, etc.
class Interactives extends Entity {
  constructor(mainEnv) {
    super()
    this.env = mainEnv
    this.isTvOn = false
  }

  startTvTimer = async () => {
    // At random intervals start TV for a random duration (> 1sec). During this interval IF CodeBot moves
    // then advance the door toward the CLOSED position.
    this.isTvOn = false
    this.doorClosed = false
    this.targetRunCount = null
    this.botRunEnded = false

    // Configure sound
    const tvSFx = this.env.sounds.cloneSfx('tvSound')
    tvSFx.attachToMesh(this.tvScreenMesh)

    this.runner = new SerialRunner()
    this.runner.add(async () => {
      this.isTvOn = false
      tvSFx.pause()
      this.tvScreenMesh.material.emissiveIntensity = 0
      // this.tvScreenMesh.material.albedoColor = BABYLON.Color3.Black()
      await this.runner.wait(2000 + 5000 * Math.random())
    })
    this.runner.add(async () => {
      this.isTvOn = true
      tvSFx.play()
      this.tvScreenMesh.material.emissiveIntensity = 1.0
      await this.runner.wait(100)
    })
    this.runner.add(async () => {
      this.tvScreenMesh.material.emissiveIntensity = 0.01
      await this.runner.wait(100)
    })
    this.runner.add(async () => {
      this.tvScreenMesh.material.emissiveIntensity = 1.0
      await this.runner.wait(100)
    })
    this.runner.add(async () => {
      this.tvScreenMesh.material.emissiveIntensity = 0.1
      await this.runner.wait(1000 + 5000 * Math.random())
    })
    this.runner.add(async () => {
      this.tvScreenMesh.material.emissiveIntensity = 1.0
      await this.runner.wait(100)
    })
    this.runner.add(async () => {
      this.tvScreenMesh.material.emissiveIntensity = 0.1
      await this.runner.wait(1000 + 5000 * Math.random())
    })

    this.runner.start(true)

    // Now to monitor CodeBot and mind the door!
    // Config door animation
    const animDoor = this.env.ground.envModel.animationGroups[0]
    animDoor.reset()
    this.doorClosed = false
    animDoor.loopAnimation = false
    animDoor.speedRatio = 0.7
    animDoor.onAnimationEndObservable.add(() => {
      // console.log('Door is Closed!')
      this.doorClosed = true
      clearInterval(this?.doorMonitorTmr)
    })
    // Periodic Poll to check door
    this.doorMonitorTmr = setInterval(() => {
      const runCount = this.env.playerController?.runCounter
      if (typeof(runCount) === 'number') {
        if (this.targetRunCount === null) {
          this.targetRunCount = runCount + 1
        } else if (runCount > this.targetRunCount) {
          this.botRunEnded = true
        }
      }

      const botMoving = this.env.playerController?.motorsEnabled
      if ((this.isTvOn && botMoving) || this.botHitDoor || this.botRunEnded) {
        animDoor.play()
      } else {
        animDoor.pause()
      }
    }, 100)
    this.timers.push(this.doorMonitorTmr)
  }

  configureDoor = () => {
    const blastSFx = this.env.sounds.cloneSfx('ratBlastSound')
    let isBlasting = false
    this.botHitDoor = false

    // CodeBot collision with door should cause large physics blowback!
    // +z is toward front door, +x is toward fireplace side
    BABYLON.Tags.AddTagsTo(this.doorMesh, 'BotCollider')
    this.doorMesh.botCollision = (cbImpostor, cbController) => {
      this.botHitDoor = true
      if (isBlasting) {
        return
      }

      // No more blasting till sound stops (using sound as debounce!)
      isBlasting = true
      blastSFx.onEndedObservable.addOnce((ev) => {
        isBlasting = false
      })
      blastSFx.play()

      // Blast Bot!
      const bot = this.env.playerEntity.cbBody
      const blastSpot = BABYLON.Vector3.Zero()
      const blastDirection = new BABYLON.Vector3(0, 10, 40)
      bot.applyImpulse(blastDirection, blastSpot)
    }
  }

  configurePopcorn = () => {
    const crunchSfx = this.env.sounds.cloneSfx('snackCrunchSound')

    this.meshes.forEach((m) => {
      if (m.name.startsWith('popcorn')) {
        // Popcorn is non-physics mesh, so tag colliding body as BotCollider
        const kernel = m.getChildMeshes(false, m => m.name === 'Pop2_primitive1')?.[0]
        kernel.points = m.name
        try {
          kernel.points = parseInt(m.name.split('_+')[1])
        } catch (err) {
          //
        }

        BABYLON.Tags.AddTagsTo(kernel, 'BotCollider')
        kernel.botCollision = () => {
          // Play "crunch" sound and disappear
          crunchSfx.play()
          m.dispose()
        }
      }
    })
  }

  loadPopcorn = async (index, scoreValue, position, scaling, rotation) => {
    return await loadGLB(PopcornModel, `popcorn${index}_+${scoreValue}`, scaling, position, rotation)
  }

  loadBucket = async (index, position, scaling, rotation) => {
    return await loadGLBWithPhysics(PopcornBucketModel, `bucket${index}`, { mass: 10, friction: 10, restitution: 0.8 }, scaling, position, rotation)
  }

  load = async (scene, params) => {
    this.scene = scene
    this.params = params
    this.isTvOn = false
    this.timers = []  // timer IDs for cleanup
    this.disposeList = []

    let buckets = [], popcorn = []

    if (params.buckets) {
      buckets = params.buckets.map((b, index) => {
        return this.loadBucket(index, b.pos, b.scale, b.rot)
      })
    }

    if (params.popcorn) {
      popcorn = params.popcorn.map((p, index) => {
        return this.loadPopcorn(index, p.scoreValue, p.pos, p.scale, p.rot)
      })
    }

    const interactivesPromises = [...buckets, ...popcorn]
    this.meshes = await Promise.all(interactivesPromises)
    this.disposeList.push(...this.meshes)
  }

  unload = async () => {
    // Stop any active timers
    this.timers.forEach(id => clearTimeout(id))
    this.runner.stop()

    await Promise.all(this.disposeList.map(disposePromise))
  }

  configure = (params) => {
    // Finalize interactives AFTER loading all assets
    this.startTvTimer()
    this.configureDoor()
    this.configurePopcorn()
  }
}


class MainEnvironment extends Entity {
  constructor() {
    super()

    groundOptions.meshVisitor = this.meshVisitor

    this.name = environmentName
    this.lights = new TemplateLights(lightOptions)
    this.sounds = new TemplateSounds(soundOptions)
    this.ground = new TemplateGround(groundOptions)
    this.interactives = new Interactives(this)
    this.defaultParams = defaultEnvParams
  }
  meshVisitor = (mesh) => {
    if (mesh.name === 'TV_Screen') {
      this.interactives.tvScreenMesh = mesh
    } else if (mesh.name === 'door.004') {
      this.interactives.doorMesh = mesh
    } else if (mesh.name === 'Checkpoint_Door') {
      this.doorCheckpointMesh = mesh
    }
  }
  finalObjectiveHit = () => {
    // Called by validator after it detects checkpoint hit
    //   - Note, within validator: const env = controller.simScene.activeEnvironmentEntity
    // Light doorway and stop bot
    if (this.doorCheckpointMesh && !this.interactives.doorClosed) {
      this.doorCheckpointMesh.isVisible = true
      this.doorCheckpointMesh.material.emissiveColor.g = 1.0  // Glow Green!
      this.playerController.suspendActivity(true)  // Stop bot from moving through door

      // No more door shenanigans if you've reached checkpoint to exit
      this.interactives.runner.stop()
      this.interactives.doorMesh.botCollision = null
    }
  }
  missionCompleteMusic = () => {
    if (this.levelEndSfx) {
      this.levelEndSfx.play()
    }
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false

    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.sounds.load(scene),
      this.interactives.load(scene, this.params),
    ])
    // Configuration after async loading
    this.interactives.configure(this.params)
    this.levelEndSfx = this.sounds.cloneSfx('levelEndSound')
  }
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.sounds.unload(),
      this.ground.unload(),
      this.interactives.unload(),
    ])
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
  setVolume = (volumeParams) => {
    this.sounds.setVolume(volumeParams)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController

    // Disallow keyboard CB buttons for this challenge (no driving!)
    if (this.playerController) {
      this.playerController.allowKeyboardButtons = false

      this.playerController.sensorOverrides.prox = (num) => {
        // When TV is on we sense reflection < 2000
        const reflection = this.interactives.isTvOn ? 1000 * Math.random() : 2000 + 2000 * Math.random()
        return reflection
      }

      this.playerController.sensorOverrides.ls = (num) => {}  // LS not needed, save performance
    }
  }
}

export default MainEnvironment
