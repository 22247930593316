import {
  fetchLessonEditorServerMissionIds,
  fetchLessonEditorServerMission,
  fetchLessonEditorServerObjective,
} from './lesson-editor-cloud-access'
import {
  testFlowItemCodeParseValidators,
  findInvalidToolboxItemsInString,
} from './lesson-editor-use-cases'
import {
  presentValidatorTestResults,
  presentValidatorTestStarted,
} from './lesson-editor-presenters'
import {
  removeValidatorTestFromStore,
  resetTotalTests,
  decrementTotalTests,
  setMissionInfoByTestFlowIdInStore,
  setFlowInfoByTestFlowIdInStore,
} from './lesson-editor-repos'

async function initializeValidatorRegressionTests() {
  await new Promise(res => setTimeout(res, 1000))
  presentValidatorTestStarted()
  resetTotalTests()
  const lessonEditorServerMissionIds = await fetchLessonEditorServerMissionIds()
  const lessonEditorMissions = await Promise.all(lessonEditorServerMissionIds.map(fetchLessonEditorServerMission))
  lessonEditorMissions.forEach(setMissionInfoByTestFlowIdInStore)

  const lessonEditorFlowIds = lessonEditorMissions.map(mission => mission.flow.map(flowItem =>
    flowItem.type !== 'quiz' ? flowItem.id : null
  )).flat().filter(f => !!f)
  const lessonEditorFlowItems = await Promise.all(lessonEditorFlowIds.map(fetchLessonEditorServerObjective))

  lessonEditorFlowItems.forEach(setFlowInfoByTestFlowIdInStore)
  await Promise.all(lessonEditorFlowItems.map(testFlowItemCodeParseValidators))

  presentValidatorTestResults()

  runToolboxToolTest(lessonEditorFlowItems)
}

async function deleteValidatorRegressionTestByFlowItem(flowId, goalIdx) {
  presentValidatorTestStarted()
  decrementTotalTests()
  removeValidatorTestFromStore(flowId, goalIdx)
  presentValidatorTestResults()
}

async function runValidatorRegressionTestByFlowItem(flowItem) {
  presentValidatorTestStarted()
  decrementTotalTests()
  await testFlowItemCodeParseValidators(flowItem)
  presentValidatorTestResults()
}

var debounce = null
async function runValidatorRegressionTestByFlowItemWithDebounce(flowItem) {
  clearTimeout(debounce)
  debounce = null
  presentValidatorTestStarted()
  debounce = setTimeout(async () => {
    decrementTotalTests()
    await testFlowItemCodeParseValidators(flowItem)
    presentValidatorTestResults()
  }, 500)
}

let toolList = {}
function runToolboxToolTest(flowItems) {
  let invalidToolFound = false
  function logToolboxToolTest(flowId, name, str) {
    const testArr = findInvalidToolboxItemsInString(str)
    for (const tool of testArr) {
      toolList[tool] = true
      invalidToolFound = true
      console.log(`flowId: ${flowId}, section: ${name}, tool: ${tool}`)
    }
  }

  function handleArrayCandidates(id, title, arr) {
    for (const idx in arr) {
      const item = arr[idx]
      logToolboxToolTest(id, `${title} idx ${idx}`, item)
    }
  }

  console.log('--- Toolbox Invalid Tools Test Start ---')
  console.group()
  for (const { complete, goals, hints, instructions, codesteps, id } of flowItems) {
    if (!!complete) {
      logToolboxToolTest(id, 'complete', complete)
    }
    if (!!instructions) {
      logToolboxToolTest(id, 'instructions', instructions)
    }
    if (!!hints) {
      handleArrayCandidates(id, 'hints', hints)
    }
    if (!!goals) {
      handleArrayCandidates(id, 'goals', goals.map(g => g.md))
    }
    if (!!hints) {
      handleArrayCandidates(id, 'codesteps', codesteps.map(c => c.md))
    }
    // getToolboxItemsFromString
  }
  if (!invalidToolFound) {
    console.log('No invalid toolbox tools found in any flow items.')
  }
  console.groupEnd()
  console.log('--- Toolbox Invalid Tools Test End ---')
}

function isEditorMode() {
  if (process.env.REACT_APP_EDITOR_MODE) {
    return true
  }


  const pathname = window.location.pathname
  if (pathname === '/editorMode' && process.env.NODE_ENV === 'development') {
    return true
  }

  return false
}
const editorMode = isEditorMode()

export {
  initializeValidatorRegressionTests,
  runValidatorRegressionTestByFlowItem,
  runValidatorRegressionTestByFlowItemWithDebounce,
  deleteValidatorRegressionTestByFlowItem,
  editorMode,
}