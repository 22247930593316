const FIRMWARE_VERSIONS = {
  'cb3': [{
    sha: '82a1f8ae4',
    date: '10-20-2021',
    fileName: 'cb3_10-20-2021_g82a1f8ae4.bin',
  }],
  'cb2': [{
    sha: 'd43d6917c',
    date: '10-07-2019',
    fileName: 'cb2_10-07-2019_gd43d6917c.bin',
  }],
  'codeair': [{
    sha: 'CodeAIR',
    date: '09-29-2024',
    fileName: 'codeair_09292024.bin',
  }],
  'codex': [
    {
      sha: 'GA-050224',
      date: '05-21-2024',
      fileName: 'codex_05-21-2024_gGA-050224.bin',
    },
    {
      sha: 'GA-121223',
      date: '12-12-2023',
      fileName: 'codex_12-12-2023_gGA-121223.bin',
    },
    {
      sha: '1c8893bd0',
      date: '09-11-2022',
      fileName: 'codex_09-11-2022_g1c8893bd0.bin',
    },
    {
      sha: '693cd2a1a',
      date: '06-27-2022',
      fileName: 'codex_06-27-2022_g693cd2a1a.bin',
    },
    {
      sha: '56b4bf864',
      date: '05-17-2022',
      fileName: 'codex_05-17-2022_g56b4bf864.bin',
    },
    {
      sha: '82a1f8ae4',
      date: '10-20-2021',
      fileName: 'codex_10-20-2021_g82a1f8ae4.bin',
    },
  ],
}

export { FIRMWARE_VERSIONS }