import { getActiveGroup } from './getActiveGroup'
import { getActiveContentSequenceItem } from '../../content-director/content-director-use-cases/getActiveContentSequenceItem'
import { postAssignmentSubmission } from '../group-gateways'
import { fetchAssignments } from './fetchAssignments'
import {
  presentSubmitAssignmentResponseStarted,
  presentSubmitAssignmentResponseFailed,
  presentSubmitAssignmentResponseSucceeded,
} from '../group-presenter'
import { presentContentDirector } from '../../content-director/content-director-presenter'
import {mergeActiveGroupAndLessonContentSequence} from '../../content-director/content-director-use-cases/mergeActiveGroupAndLessonContentSequence'
import { readFlowProgressFromUserProgressStore } from '../../user-progress/user-progress-store'
import { userSessionStore } from '../../user-session/user-session-store'

async function submitAssignment(fileId, code, validationAuthenticated=false) {
  presentSubmitAssignmentResponseStarted()

  const activeGroup = getActiveGroup()
  const activeSequenceItem = getActiveContentSequenceItem()
  const progress = readFlowProgressFromUserProgressStore(activeSequenceItem.assignment.flowId)

  const lastRuntimeErrorMessage = userSessionStore.readUserSessionLastErrorMessage()
  const { error } = await postAssignmentSubmission(
    activeGroup.id,
    activeSequenceItem.assignment.id,
    fileId,
    !!progress?.completed,
    code,
    validationAuthenticated,
    lastRuntimeErrorMessage
  )

  if (error) {
    presentSubmitAssignmentResponseFailed(error)
    return
  }

  await fetchAssignments()
  presentSubmitAssignmentResponseSucceeded()
  mergeActiveGroupAndLessonContentSequence()
  presentContentDirector()
}

export { submitAssignment }