// Main application file for CodeBot Simulator

import React from 'react'
import './App.css'
import SimSceneComponent from './SimScene'
import SceneToolbar from './SceneToolbar'
import Split from 'react-split-pane'
import CodingPanel from './CodingPanel'
import { unstable_createMuiStrictModeTheme as createMuiTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles'
//       ^ to fix "scary" React.StrictMode warning. Should be fixed in @material-ui@v5 when it releases
import CssBaseline from '@material-ui/core/CssBaseline'
import InteractionDrawer from './InteractionDrawer'
import MissionBar from './MissionBar'
import { CameraHelp } from './CameraHelp'
import VersionCheck from './VersionCheck'
import { useUserConfig } from './contexts/UserConfigContext'
import AccountToolbar from './AccountToolbar'
import { useMission } from './contexts/MissionContext'
import { useLogin } from './contexts/LoginContext'
import { LTILogoutPage, UserSessionOverwrittenPage } from './logout-pages'
import CodingFooter from './CodingFooter'
import { FpsDisplay } from './SceneStatsProbe'

import EditMissionPanel from './EditMissionPanel'
import EditMissionToolbar from './EditMissionToolbar'
import LoadingOverlay from './LoadingOverlay'
import SimulatorOverlay from './SimulatorOverlay'
import HwVerMessage from './HwVerMessage'
import logger from './tools/tracking/logger'
import { smartlookInit } from './tools/tracking/smartlook'
import { AuthorizationDialog } from './AuthorizationDialog'
import { ManualAssignmentSubmissionDialog } from './AssignmentSubmissionPanels/ManualAssignmentSubmissionDialog'
import { UserProgressDialog } from './UserProgressDialog'
import { SceneVolume } from './SceneVolume'
import { setPaletteType } from './utils/shade-highlight-tools'
import { initializeContentManager } from './content-manager/content-manager-use-cases'
import { NoInternetWarningDialog } from './NoInternetWarningDialog'
import { userSessionController } from './content-manager/user-session/user-session-controller'
import {
  pollerController,
} from './content-manager/utils/poller-controller'

const useStyles = makeStyles(theme => ({
  mainApp: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  leftPanel: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rightPanel: {
    height: '100%',
    position: 'relative',
  },
  simPane: {
    flex: 1,
    position: 'relative',
    overflow: 'visible !important',
  },
  missionBar: {
    height: '100%',
    overflow: 'auto',
  },
}))

function AppContent({ theme }) {
  const missionViewRef = React.useRef(null)
  const [missionState] = useMission()
  const [loginState] = useLogin()

  const [draggingTerminal, setDraggingTerminal] = React.useState(false)

  const [editorSaveStatus, setEditorSaveStatus] = React.useState(null)

  const showFps = false
  const classes = useStyles()


  React.useEffect(() => {
    smartlookInit()
    initializeContentManager()

    window.addEventListener('offline', userSessionController.handleSessionWentOffline)
    return () => {
      window.removeEventListener('offline', userSessionController.handleSessionWentOffline)
      pollerController.stopPollers()
    }
  }, [])

  const rightSplitData = missionState.ui.consoleOpen ? {
    min: 210,
    max: -50,
    default: 300,
  } : {
    resizerStyle: { display: 'none' },
    min: 40,
    max: 40,
    default: 40,
  }

  window.onerror = (msg) => {
    logger.error(msg)
  }

  if (loginState?.displayUserLoggedOutMessage) {
    return <UserSessionOverwrittenPage />
  }

  if (loginState.ltiLogoutPageVisible) {
    return <LTILogoutPage />
  }

  return (
    <>
      <div className={classes.mainApp}>
        <Split
          split='vertical'
          primary='first'
          minSize={50}
          maxSize={-100  /* margin from right-edge */}
          onDragStarted={() => setDraggingTerminal(true)}
          onDragFinished={() => setDraggingTerminal(false)}
          defaultSize={'40%'}
        >
          {/* Left panel: Code */}
          <div className={classes.leftPanel} id='coding'>
            <Split
              split='vertical'
              primary='first'
              allowResize={false}
              style={{ position: 'static' }}
              resizerStyle={{ display: 'none' }}
              pane1Style={{
                padding: '8px',
                width: 'unset',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.outerBar,
              }}
              pane2Style={{
                minWidth: '0%',  // Allows CodingPanel width to shrink when resized
                // paddingBottom: 55, // Caused scrollbar to be hidden
              }}
            >
              {missionState.editorMode ? <EditMissionToolbar editorSaveStatus={editorSaveStatus}/> : <AccountToolbar />}
              {missionState.editorMode ? <EditMissionPanel setEditorSaveStatus={setEditorSaveStatus}/> : <CodingPanel />}
            </Split>
            {missionState.editorMode ? null : <CodingFooter />}
          </div>
          {/* Right panel: Mission & Simulator */}
          <SimulatorOverlay className={classes.rightPanel} id='3d'>
            <Split
              allowResize={false}
              defaultSize={60}
              resizerStyle={{ display: 'none' }}
              split='vertical'
              primary='second'
            >
              <Split
                allowResize={true}
                style={{overflow: 'clip'}}
                resizerStyle={rightSplitData.resizerStyle}
                minSize={rightSplitData.min}
                maxSize={rightSplitData.max}
                defaultSize={rightSplitData.default}
                onDragStarted={() => setDraggingTerminal(true)}
                onDragFinished={() => setDraggingTerminal(false)}
                split='horizontal'
                primary='second'
                pane1Style={{
                  display: 'flex',
                  flex: 1,
                  minHeight: 0,    // Allows this div to be smaller than its content. (versus default 'auto')
                  // Note: Hard-won CSS knowledge. Without this we have crazy-growing-3D-canvas effect
                  //       due to engine.resize() growing content exceeding size of parent, and if parent
                  //       resizes it triggers another engine.resize(). So we have to constrain canvas
                  //       inside its parent element.
                  position: 'relative',  // Allows absolute positioned child windows (overlay panels - help, missions)
                }}
              >
                {/* Mission view */}
                <>
                  {/* FPS display */}
                  {showFps && <FpsDisplay />}
                  {/* 3D Scene */}
                  <div id='sim' className={classes.simPane} ref={missionViewRef}>
                    <SimSceneComponent id='c2' requiresSim={missionState.requiresSim} />
                    <HwVerMessage />
                  </div>
                  {/* Mission Toolbar */}
                </>
                {/* Scene Toolbar */}
                <Split
                  split='horizontal'
                  primary='second'
                  allowResize={false}
                  resizerStyle={{ display: 'none' }}
                  pane1Style={{
                    minHeight: 0,
                  }}
                  pane2Style={{
                    height: 39,
                    borderWidth: 1,
                    borderTopStyle: 'solid',
                    borderTopWidth: 1,
                    marginTop: 1,
                    alignItems: 'center',
                  }}
                >
                  <>
                    {<InteractionDrawer draggingTerminal={draggingTerminal} setDraggingTerminal={setDraggingTerminal} isOpen={missionState.ui.consoleOpen} />}
                  </>
                  <div >
                    <CameraHelp anchorEl={missionViewRef.current} />
                    <SceneToolbar />
                    <SceneVolume  anchorEl={missionViewRef.current} />
                  </div>
                </Split>
              </Split>
              <div className={classes.missionBar}>
                <MissionBar missionViewElt={missionViewRef.current} />
              </div>
            </Split>
          </SimulatorOverlay>
        </Split>
        <LoadingOverlay />
        <VersionCheck url={'/index.html'} />
        <AuthorizationDialog />
        <ManualAssignmentSubmissionDialog />
        <UserProgressDialog />
        <NoInternetWarningDialog />
      </div>
    </>
  )
}

function App() {
  const [userConfigState] = useUserConfig()

  const theme = React.useMemo(
    () => {
      setPaletteType(userConfigState.theme)
      return createMuiTheme({
        palette: {
          type: userConfigState.theme,
          outerBar: userConfigState.theme === 'dark' ? null : '#f5f5f5',
        },
        overrides: {
          MuiTooltip: {
            tooltip: {
              fontSize: userConfigState.theme?.typography?.pxToRem(16) || 16,
              fontWeight: userConfigState.theme?.typography?.fontWeightLight || 300,
            },
          },
          MuiBreadcrumbs: {
            ol: {
              flexWrap: 'nowrap',
            },
          },
        },
      })
    },
    [userConfigState.theme]
  )

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AppContent theme={theme}/>
    </MuiThemeProvider>
  )
}

export default App
