// Given a string or an array of strings, returns whether the startSub, betweenSub,
// and endSub (if provided) exist sequentially.
function isSubBetween(str, startSub, betweenSub, endSub=null) {
  let strArr = str
  if (!Array.isArray(str)) {
    strArr = [str]
  }

  for (var y of strArr) {
    var idx1 = y.indexOf(startSub)
    var idx2 = y.indexOf(betweenSub)
    var idx3 = y.indexOf(endSub)

    if (idx1 === -1) {
      continue
    } else if (idx2 === -1) {
      continue
    } else if (idx3 === -1) {
      return true
    }

    return idx1 < idx2 < idx3
  }

  return false
}

let parsedMap = {}

/*
  Given a PARSED if statement, returns if the line exists in the same statement and block. The line needs to exist
  at a single indentation level, it doesn't check for lines in nested if statements for example.

  --- Example ---
  Given the code:
  ```
  if distance_cm < ALERT_DISTANCE:
      red_led.value = LED_ON
  elif distance_cm < WARNING_DISTANCE:
      amber_led.value = LED_ON
  ```

  If I want to check that amber_led is being assigned in the elif statement, my call would be:

  lineExistsInIfStatement('elif (distance_cm < WARNING_DISTANCE):', 'amber_led.value = LED_ON')
*/
function lineExistsInIfStatement(ifStatement, line) {
  const statementOrder = ['if', 'elif', 'else']
  let statementOrderIdx = -1
  statementOrder.forEach((sub, idx) => {
    if (ifStatement.startsWith(sub)) {
      statementOrderIdx = idx
    }
  })

  if (statementOrderIdx === -1) {
    return
  }

  const strArr = parsedMap.filterType('if', ifStatement)
  for (const str of strArr) {
    const statementIdx = str.indexOf(ifStatement)

    if (statementIdx < 0) {
      continue
    }

    // If the statement is an if, make sure we're not getting the index of an elif.
    if (statementOrderIdx === 0 && statementIdx > 2) {
      if (str.slice(statementIdx-2, statementIdx) === 'el') {
        continue
      }
    }

    // ---
    // Find the amount of indentation of the block
    let indentationSize = 0
    const splitStr = str.slice(0, statementIdx).split('')
    if (splitStr.length > 0) {
      for (let i=splitStr.length; i--; i===0) {
        if (splitStr[i] === ' ') {
          indentationSize++
        } else {
          break
        }
      }
    }


    const splitLines = str.slice(statementIdx).split('\n')
    let statementEndIdx = -1
    let indentationStr = ''
    for (let i = 0; i < indentationSize; i++) {
      indentationStr += ' '
    }

    for (const line of splitLines.slice(1)) {
      if (line.includes('else') || line.includes('elif')) {
        continue
      }
      if (!line.startsWith(indentationStr + ' ')) {
        statementEndIdx = splitLines.indexOf(line)
        break
      }
    }

    let ifBlockStr = str.slice(statementIdx, statementEndIdx)
    if (statementEndIdx < 0) {
      ifBlockStr = str.slice(statementIdx)
    }
    // ---

    // Find the content of the statement
    let ifStatementContent = ifBlockStr
    const statementIndexes = [...statementOrder.map(sub => ifBlockStr.indexOf(sub)), -1]
    if (statementIndexes[statementOrderIdx + 1] > 0) {
      ifStatementContent = ifStatementContent.slice(statementIndexes[statementOrderIdx], statementIndexes[statementOrderIdx + 1])
    } else {
      ifStatementContent = ifStatementContent.slice(statementIndexes[statementOrderIdx])
    }


    // Filter out any blocks within the if statement, such as nested if statements
    const splitContentLines = ifStatementContent.split('\n')
    ifStatementContent = splitContentLines.filter(line =>
      !line.startsWith(indentationStr + '     ') &&
      !line.startsWith(statementIndexes[0]) &&
      !line.startsWith(statementIndexes[1]) &&
      !line.startsWith(statementIndexes[2])
    ).join('\n')

    if (isSubBetween(ifStatementContent, ifStatement, line, null)) {
      return true
    }
  }
}

function isSubPrinted(sub) {
  let printLines = parsedMap.filterType('call', 'print')
  if (printLines.length === 0) {
    return
  }

  for (let line of printLines) {
    if (line.includes(sub)) {
      return true
    }
  }
}

function makeTools(newParsedMap, getParsedMap) {
  parsedMap = newParsedMap

  return Object.freeze({
    isSubBetween,
    lineExistsInIfStatement,
    isSubPrinted,
    getParsedMap,
  })
}

export { makeTools }