// Mission and Objective Panels
import React from 'react'
import { Typography, Dialog, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useGroups } from '../contexts/GroupsContext'
import { ManualAssignmentSubmissionButton } from './SubmissionButtons'
import { AssignmentSubmissionInfoPanel } from './AssignmentSubmissionInfoPanel'

function ManualAssignmentSubmissionDialog({ open=false, handleClose = () => {} }) {
  const { submitAssignmentSucceeded } = useGroups()

  React.useEffect(() => {
    if (submitAssignmentSucceeded) {
      handleClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitAssignmentSucceeded])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px'}}>
        <Typography variant='h6'>Manual Submission</Typography>
        <IconButton onClick={handleClose} size='small'>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
          <Typography style={{fontWeight: 600}}>
            {'Are you sure you want to submit manually?'}
          </Typography>
        </div>
        <div style={{height: 20}}></div>
        <AssignmentSubmissionInfoPanel />
        {/* <AssignmentSubmissionInfoPanel /> */}
        <div style={{height: 20}}></div>
        <ManualAssignmentSubmissionButton />
        <div style={{height: 10}}></div>
      </DialogContent>
    </Dialog>
  )
}

export { ManualAssignmentSubmissionDialog }
