const store = {}
const missionPacksStoreKey = 'mission-packs-store'
const missionStoreKey = 'mission-store'
const flowStoreKey = 'flow-store'
const missionPackPromiseKey = 'mission-pack-promise'

function setLessonContentStoreDefaults() {
  store[missionPacksStoreKey] = {}
  store[missionStoreKey] = {}
  store[flowStoreKey] = {}
  store[missionPackPromiseKey] = {}
}
setLessonContentStoreDefaults()

function setFlowStore(flowItem) {
  store[flowStoreKey][flowItem.id] = flowItem
}

function readFlowStoreByFlowId(flowId) {
  return store[flowStoreKey][flowId]
}

function setMissionStore(mission) {
  store[missionStoreKey][mission.id] = mission
  mission.flow.forEach(setFlowStore)
}

function readMissionStoreByMissionId(missionId) {
  return store[missionStoreKey][missionId]
}

function setMissionPackStore(pack) {
  store[missionPacksStoreKey][pack.id] = pack
  pack.missions.forEach(setMissionStore)
}

function readMissionPackStoreByPackId(packId) {
  return store[missionPacksStoreKey][packId]
}

function readMissionPackIdsFromMissionPackStore() {
  return Object.keys(store[missionPacksStoreKey])
}

function setFetchMissionPackPromiseInPackStore(missionPackId, promise) {
  store[missionPackPromiseKey][missionPackId] = promise
}

function readFetchMissionPackPromiseFromPackStore(missionPackId) {
  return store[missionPackPromiseKey][missionPackId]
}

export {
  setLessonContentStoreDefaults,
  setMissionPackStore,
  readMissionPackStoreByPackId,
  readMissionStoreByMissionId,
  readFlowStoreByFlowId,
  readMissionPackIdsFromMissionPackStore,
  setFetchMissionPackPromiseInPackStore,
  readFetchMissionPackPromiseFromPackStore,
}