import {
  setContentSequenceIndex,
  setActiveMissionId,
} from '../../content-director/content-director-store'
import { presentContentDirector, presentMission } from '../content-director-presenter'
import { mergeActiveGroupAndLessonContentSequence } from './mergeActiveGroupAndLessonContentSequence'
import { editorMode } from '../../lesson-editor/lesson-editor-controllers'

async function changeActiveMission(missionId, contentSequenceIndex = null, skipPresenters = false) {
  window.gtag('event', 'select_content', {'content_type':'mission','content_id':String(missionId)})
  setActiveMissionId(missionId)

  // if (!readContentDirectorInitialized()) {
  //   await initializeFlowIndex(missionId)
  //   return
  // }

  // const missionProgress = readMissionProgressFromUserProgressStore(missionId)
  if (contentSequenceIndex) {
    setContentSequenceIndex(contentSequenceIndex)
  } else if (!editorMode) {
    setContentSequenceIndex(-1)
  }

  if (!skipPresenters) {
    mergeActiveGroupAndLessonContentSequence()
    presentMission()
    presentContentDirector()
  }
}

export { changeActiveMission }
