import React, { useEffect, Fragment } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { editorController } from './EditorControl'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import { makeStyles } from '@material-ui/core/styles'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import EmailIcon from '@material-ui/icons/Email'
import GetAppIcon from '@material-ui/icons/GetApp'
import dark_vs from './dark_vs.json'
import DebugButtons from './DebugButtons'
import { useLogin } from './contexts/LoginContext'
import { useUserConfig } from './contexts/UserConfigContext'
import { useCodePanel, CodePanelActions } from './contexts/CodePanelContext'
import { downloadLogStore } from './tools/tracking/logStore'
// import CustomerFeedbackForm from './CustomerFeedbackForm'  // TODO - bring this back when HubSpot alternative is found

const prod = process.env.NODE_ENV === 'production'

const useStyles = makeStyles((theme) => {
  return ({
    list: {
      display: 'inline-block',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 8,
      paddingRight: 8,
    },
    listText: {
      fontSize: 12,
    },
    menuPaper: {
      minWidth: 275,
      maxWidth: 275,
    },
    menuDivider: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 3,
      marginBottom: 3,
    },
    menuItem: {
      justifyContent: 'space-between',
      paddingTop: 4,
      paddingBottom: 4,
    },
    menuText: {
      display: 'inline-block',
      fontSize: 13,
    },
    menuList: {
      outlineStyle: 'none',
    },
    codingHeaderContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      left: -60,
      paddingLeft: 5,
    },
    headerIcon: {
      alignSelf: 'center',
      width: 22,
      height: 22,
      marginLeft: 7,
      marginRight: 7,
    },
    debugButtonsContainer: {
      position: 'absolute',
      right: -60,
      alignItems: 'center',
      padding: '2px 1px 2px 6px',
    },
    menuIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
})

function FormattedMenu({ menuData, index, altNavMode, setAltNavMode, anchorRef, menuItems, selectedMenuItem, setOpen, classes }) {
  const [userConfigState] = useUserConfig()
  var open = selectedMenuItem === index

  const formatMenuName = (menuItem) => {
    if (altNavMode === true) {
      if ('altUnderlineIndex' in menuItem) {
        let index = menuItem['altUnderlineIndex']
        return <span>{menuItem.name.substring(0, index)}<u>{menuItem.name.charAt(index)}</u>{menuItem.name.substring(index + 1)}</span>
      } else {
        return <span><u>{menuItem.name.charAt(0)}</u>{menuItem.name.substring(1)}</span>
      }
    } else {
      return menuItem.name
    }
  }

  const handleToggle = () => {
    if (open === true) {
      setOpen(false)
    } else {
      setOpen(index)
    }
  }

  const handleMenuOnClick = (onClick) => {
    onClick()
    handleToggle()
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  const handleListItemClick = () => {
    handleToggle()
    if (open) {
      setAltNavMode(false)
    }
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <ListItem
        aria-controls={open ? 'menu-list-grow' : undefined}
        ref={anchorRef}
        selected={open}
        onMouseOver={() => (selectedMenuItem !== false ? setOpen(index) : null)}
        onClick={handleListItemClick}
        button
        className={classes.list}
      >
        <Typography className={classes.listText} style={{ color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }}>{formatMenuName(menuData)}</Typography>
      </ListItem>
      <Popper style={{ zIndex: 100 }} open={open} anchorEl={anchorRef.current} role={undefined} placement={'bottom-start'}>
        <Paper
          className={classes.menuPaper}
          style={{ backgroundColor: userConfigState.theme === 'dark' ? dark_vs.colors['menu.background'] : null }}
          square={true}
          elevation={3}
        >
          <ClickAwayListener onClickAway={(ev) => {
            handleClose(ev); setAltNavMode(false)
          }}>
            <MenuList className={classes.menuList} autoFocusItem={true} id='menu-list-grow' onKeyDown={handleListKeyDown}>
              {menuItems.map((item, index) => {
                if (item.name === 'divider') {
                  return <Divider key={index} className={classes.menuDivider} />
                } else {
                  return (
                    <MenuItem className={classes.menuItem} key={index} onClick={() => {
                      handleMenuOnClick(item.onClick); setAltNavMode(false)
                    }} disabled={item.disabled}>
                      <Typography className={classes.menuText} style={{ color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }}>{formatMenuName(item)}</Typography>
                      <Typography className={classes.menuText} style={{ color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }}>{item.shortcut}</Typography>
                    </MenuItem>
                  )
                }
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  )
}


export default function CodingHeader({ openDirectoryDialog, setNewFileDialogMode, openWelcomeDialog, openAboutDialog }) {
  const [selectedMenuItem, setSelectedMenuItem] = React.useState(false)
  const [altNavMode, setAltNavMode] = React.useState(false)
  const [loginState] = useLogin()
  const [userConfigState] = useUserConfig()
  const [codePanelState, codePanelDispatch] = useCodePanel()
  const altReleased = React.useRef(true)
  const altNavSkipped = React.useRef(false)
  const multiLineSelected = React.useRef(false)
  const classes = useStyles()
  const indexBoundary = 3

  React.useEffect(() => {
    if (!!codePanelState.editorInstance) {
      codePanelState.editorInstance.onKeyDown((e) => {
        if (e.altKey && e.code !== 'AltLeft' && e.code !== 'AltRight') {
          altNavSkipped.current = true
        }
      })
    }
  }, [codePanelState.editorInstance])

  const handleNavKey = (pressedKey) => {
    if (selectedMenuItem === false) {
      for (const key in menuData) {
        if (menuData[key].altNavKey === pressedKey) {
          menuData[parseInt(key)].ref.current.focus()
          setSelectedMenuItem(parseInt(key))
          break
        }
      }
    } else {
      for (const key in menuData[selectedMenuItem].menuItems) {
        if (menuData[selectedMenuItem].menuItems[key].altNavKey === pressedKey) {
          menuData[selectedMenuItem].menuItems[key].onClick()
          setSelectedMenuItem(false)
          setAltNavMode(false)
        }
      }
    }
  }

  const arrowNavHandler = (key) => {
    if (key.code === 'ArrowRight') {
      // If the selected menu is not in a position to wrap, else wrap
      if (selectedMenuItem < indexBoundary) {
        setSelectedMenuItem(selectedMenuItem + 1)
      } else {
        setSelectedMenuItem(0)
      }
    } else if (key.code === 'ArrowLeft') {
      if (selectedMenuItem > 0) {
        setSelectedMenuItem(selectedMenuItem - 1)
      } else {
        setSelectedMenuItem(indexBoundary)
      }
    }
  }

  const downHandler = (key) => {
    if (key.key === 'Alt') {
      // Required to stop chrome from taking focus
      key.preventDefault()
      // If ALT is pressed when the file menu is open and alt released, close the menu and exit nav mode
      if (selectedMenuItem !== false && altReleased.current) {
        setSelectedMenuItem(false)
        setAltNavMode(false)
      } else if (altNavMode === false) {
        setAltNavMode(true)
        // If we're in altmode and alt has already been released, then two alt clicks in
        // a row were registered, therefore exit alt mode
      } else if (altNavMode && altReleased.current) {
        setAltNavMode(false)
        editorController.focusEditor()
      }
      altReleased.current = false
      // If a menu item is opened, or the first is selected, and an arrow key was pressed
    } else if ((selectedMenuItem !== false || menuData[0].ref.current === document.activeElement) && (key.code === 'ArrowRight' || key.code === 'ArrowLeft')) {
      arrowNavHandler(key)
      // If escape is pressed when the menu is open, close it
    } else if ((selectedMenuItem !== false && key.code === 'Escape')) {
      setSelectedMenuItem(false)
    } else if ((selectedMenuItem === false && key.code === 'Escape' && altNavMode)) {
      setAltNavMode(false)
      editorController.focusEditor()
      // If we're in alt mode and a key is pressed, figure out whether it was a menu nav command,
      // This won't trigger if the user runs a monaco-editor command like "Alt+C"
    } else if (altNavMode) {
      key.preventDefault()
      handleNavKey(key.key)
    }
  }

  const upHandler = (key) => {
    if (key.key === 'Alt') {
      // If the menu is closed, and either a monaco-editor command was called, or the user selected multi lines
      if (selectedMenuItem === false && (altNavSkipped.current === true || multiLineSelected.current === true)) {
        setAltNavMode(false)
        // If ALT has just been released, and no key was pressed, go into 'Alt menu nav' mode
      } else if (altNavMode && selectedMenuItem === false) {
        menuData[0].ref.current.focus()
      }
      altNavSkipped.current = false
      altReleased.current = true
    }
    multiLineSelected.current = false
  }

  const clickHandler = () => {
    // If the menu is in alt nav mode, and a click occurs that isn't on a menu, exit nav mode
    if (altNavMode && altReleased.current && selectedMenuItem === false) {
      setAltNavMode(false)
      // If the user clicked multi lines while holding alt
    } else if (!altReleased.current) {
      multiLineSelected.current = true
    }
  }

  const doDownload = () => {
    codePanelDispatch({ type: CodePanelActions.DOWNLOAD_TAB })
  }

  const focusOutHandler = () => {
    // Prevent getting stuck in AltNavMode when user ALT-TAB's away and back.
    setAltNavMode(false)
  }

  const fileRef = React.useRef()
  const editRef = React.useRef()
  const viewRef = React.useRef()
  const helpRef = React.useRef()
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    window.addEventListener('blur', focusOutHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
      window.removeEventListener('blur', focusOutHandler)
    }
  })

  const menuData = React.useMemo(() => ({
    0: {
      name: 'File',
      altNavKey: 'f',
      ref: fileRef,
      menuItems: [
        {
          name: 'New File...', altNavKey: 'n', shortcut: '', onClick: () => {
            setNewFileDialogMode('Create')
          }, disabled: !loginState?.user,
        },
        {
          name: 'Browse Files...', altNavKey: 'b', shortcut: '', onClick: () => {
            openDirectoryDialog()
          }, disabled: !loginState?.user,
        },
        { name: 'divider' },
        {
          name: 'Save As...', altNavKey: 'a', altUnderlineIndex: 5, shortcut: '', onClick: () => {
            setNewFileDialogMode('Save As')
          }, disabled: !loginState?.user,
        },
        {
          name: 'Download', altNavKey: 'd', shortcut: '', onClick: () => {
            doDownload()
          }, disabled: !loginState?.user,
        },
        { name: 'divider' },
        {
          name: 'Versions', altNavKey: 'v', shortcut: '', onClick: () => {
            console.log('Versions')
          }, disabled: true,
        },
      ],
    },
    1: {
      name: 'Edit',
      altNavKey: 'e',
      ref: editRef,
      menuItems: [
        {
          name: 'Undo', altNavKey: 'u', shortcut: 'Ctrl+Z', onClick: () => {
            editorController.editorTrigger('undo')
          }, disabled: false,
        },
        {
          name: 'Redo', altNavKey: 'r', shortcut: 'Ctrl+Y', onClick: () => {
            editorController.editorTrigger('redo')
          }, disabled: false,
        },
        { name: 'divider' },
        {
          name: 'Cut', altNavKey: 't', altUnderlineIndex: 2, shortcut: 'Ctrl+X', onClick: () => {
            editorController.editorTrigger('cut')
          }, disabled: false,
        },
        {
          name: 'Copy', altNavKey: 'c', shortcut: 'Ctrl+C', onClick: () => {
            editorController.editorTrigger('editor.action.clipboardCopyAction')
          }, disabled: false,
        },
        {
          name: 'Paste', altNavKey: 'p', shortcut: 'Ctrl+V', onClick: () => {
            editorController.editorTrigger('editor.action.clipboardPasteAction')
          }, disabled: false,
        },
        { name: 'divider' },
        {
          name: 'Find', altNavKey: 'f', shortcut: 'Ctrl+F', onClick: () => {
            editorController.editorTrigger('actions.findWithSelection')
          }, disabled: false,
        },
        {
          name: 'Replace', altNavKey: 'e', altUnderlineIndex: 1, shortcut: 'Ctrl+H', onClick: () => {
            editorController.editorTrigger('editor.action.startFindReplaceAction')
          }, disabled: false,
        },
        { name: 'divider' },
        {
          name: 'Find In Files', altNavKey: 'i', altUnderlineIndex: 5, shortcut: '', onClick: () => {
            console.log('Find In Files')
          }, disabled: true,
        },
        {
          name: 'Replace In Files', altNavKey: 'i', altUnderlineIndex: 8, shortcut: '', onClick: () => {
            console.log('Replace In Files')
          }, disabled: true,
        },
        { name: 'divider' },
        {
          name: 'Toggle Line Comment', altNavKey: 'l', altUnderlineIndex: 7, shortcut: 'Ctrl+/', onClick: () => {
            editorController.editorTrigger('editor.action.commentLine')
          }, disabled: false,
        },
        {
          name: 'Toggle Block Comment', altNavKey: 'b', altUnderlineIndex: 7, shortcut: '', onClick: () => {
            editorController.editorTrigger('editor.action.blockComment')
          }, disabled: false,
        },
      ],
    },
    2: {
      name: 'View',
      altNavKey: 'v',
      ref: viewRef,
      menuItems: [
        {
          name: 'Command Palette...', altNavKey: 'c', shortcut: 'F1', onClick: () => {
            editorController.editorTrigger('editor.action.quickCommand')
          }, disabled: false,
        },
      ],
    },
    3: {
      name: 'Help',
      altNavKey: 'h',
      ref: helpRef,
      menuItems: [
        {name: 'Firia Labs Documentation', altNavKey: 'f', shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open('https://docs.firialabs.com/', '_blank')
        }},
        {name: 'Python 3 Documentation', altNavKey: 'p', shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open('https://docs.python.org/3/', '_blank')
        }},
        {name: 'Firia Labs Learning Portal', altNavKey: 'k', altUnderlineIndex: 10, shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open('https://learn.firialabs.com/', '_blank')
        }},
        {name: 'divider'},
        {name: 'Class Dashboard', altNavKey: 'd', shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open(`https://${prod ? 'dashboard.firialabs' : 'dev-dashboard.firialabs'}.com/`, '_blank')
        }},
        {name: 'Licensing Dashboard', altNavKey: 'l', shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open(`https://${prod ? 'license.firialabs' : 'dev-license.firialabs'}.com/`, '_blank')
        }},
        {name: 'More Mission Packs and Products', altNavKey: 'm', shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open('https://firialabs.com/', '_blank')
        }},
        {name: 'divider'},
        {name: 'Troubleshooting', altNavKey: 't', shortcut: <OpenInNewIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open('https://learn.firialabs.com/support/faq', '_blank')
        }},
        {name: 'Contact Support', altNavKey: 'c', altUnderlineIndex: 8, shortcut: <EmailIcon className={classes.menuIcon} fontSize='inherit' />, onClick: () => {
          window.open('mailto:support@firia.com')
        }},
        {name: 'Download Session Logs', altNavKey: 's', altUnderlineIndex: 10, shortcut: <GetAppIcon className={classes.menuIcon} fontSize='inherit' />, onClick: downloadLogStore},
        {name: 'divider'},
        {name: 'About', altNavKey: 'a', shortcut: '', onClick: () => {
          openAboutDialog()
        }},
      ],
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [loginState, codePanelState, codePanelDispatch])

  return (
    <Fragment>
      <ClickAwayListener onClickAway={(ev) => {
        clickHandler(ev)
      }}>
        <div className={classes.codingHeaderContainer} style={{ backgroundColor: userConfigState.theme === 'dark' ? '#3C3C3C' : '#e6e6e6' }}>
          {/* Editor Menu */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img className={classes.headerIcon} alt={''} src={'pub/codebot_head_sm.png'} />
            {Object.keys(menuData).map(function (key, index) {
              return <div key={index}>
                <FormattedMenu
                  menuData={menuData[key]}
                  anchorRef={menuData[key].ref}
                  index={index}
                  altNavMode={altNavMode}
                  setAltNavMode={setAltNavMode}
                  selectedMenuItem={selectedMenuItem}
                  setOpen={setSelectedMenuItem}
                  menuItems={menuData[key].menuItems}
                  classes={classes}
                  userConfigState={userConfigState}
                />
              </div>
            })}
          </div>
          <div className={classes.debugButtonsContainer} style={{ backgroundColor: userConfigState.theme === 'dark' ? '#3C3C3C' : '#e6e6e6' }}>
            <DebugButtons />
          </div>
        </div>
      </ClickAwayListener>
    </Fragment>
  )
}
