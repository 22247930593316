import { MainRpcWorker } from './MainRpcWorker'
import { TargetStates, DebugCommands } from './TargetInterface'
import { userSessionStore } from './content-manager/user-session/user-session-store'

class RpcWorkerPool {
  // Maintain a pool of Worker Threads (MainRpcWorker instances).
  // This is an optimization to ameliorate slow thread startup times.
  // Note that we always have one active thread - either the REPL or the user code.
  constructor(codeRunner, poolSize) {
    this.codeRunner = codeRunner
    this.poolSize = poolSize
    this.pool = []
    this.refill()
  }

  refill = () => {
    while (this.pool.length < this.poolSize) {
      this.pool.push(new MainRpcWorker(this.codeRunner))
    }
  }

  take = () => {
    if (this.pool.length === 0) {
      // Refill should be pending... take fastest action when on empty.
      return new MainRpcWorker(this.codeRunner)
    }

    const w = this.pool.shift()
    setTimeout(this.refill, 0)
    return w
  }
}

class TimeoutTail {
  // Wrap setTimeout() and ensure when timer expires the timeout event is placed at the tail of the queue.
  // Made for timing-out events that could be held up due to heavy queue congestion.
  constructor(callback, delayMinMs) {
    this.tDelay = setTimeout(() => {
      this.tQueueTail = setTimeout(callback, 100)
    }, delayMinMs)
  }
  cancel = () => {
    clearTimeout(this.tQueueTail)
    clearTimeout(this.tDelay)
  }
}

export class CodeRunner {
  // Manage worker thread connected to a codeBotModelController.

  constructor() {
    this.modelController = null
    this.workerPool = new RpcWorkerPool(this, 3)  // Set number = (active + standby) threads
    this.runStateCallbacks = new Set()   // add/delete callbacks here: cb(runState)
    this.debugStateCallbacks = new Set()   // add/delete callbacks here: cb(name, file, line, locals, globals)
    this.userProgramErrorCallbacks = new Set()   // add/delete callbacks here: cb(err)
    this.stdoutCallbacks = new Set()
    this.rebootCallbacks = new Set() // overridden when modeController is set
    this.rpcWorker = null
    this.lastRunState = TargetStates.STOPPED
    this.debugInfo = {}
    this.resolveStart = this.rejectStart = null
    this.startTimer = null
    this.pendingWorkerStartup = false
    this.runCount = 0
    this.cntStdoutBufs = 0  // counter for debugging stdout performance
  }

  rebootFromScene = () => {
    this.rebootCallbacks.forEach(callback => callback())
  }

  setModelController = (modelController) => {
    if (this.modelController !== null && this.lastRunState !== TargetStates.STOPPED) {
      // TODO: May need to block the REPL from restarting here
      // console.log(`setModelController: ${this.modelController.model.deviceType} -> ${modelController.model.deviceType}`)
      this.stop()
    }
    modelController.onReboot = this.rebootFromScene
    this.modelController = modelController
  }

  forwardText = (text) => {
    if (this.rpcWorker) {
      // Intercept CTRL-C KeyboardInterrupt and inject appropriate error
      if (text === '\x03') {
        this.notifyUserProgramError(
          {
            module: '',
            lineNumber: 1,
            columnNumber: 1,
            name:'KeyboardInterrupt',
            message: 'User break from console',
          }
        )
      } else {
        this.modelController.hw.setConsoleData(text)
        this.modelController.hw.indicateConsoleDataAvailable()
      }
    }
  }

  debugCommands = (debugCommand) => {
    switch (debugCommand) {
      case DebugCommands.CONTINUE: {
        this.forwardText('continue')
        break
      }
      case DebugCommands.PAUSE: {
        // TODO
        break
      }
      case DebugCommands.STEP_OVER: {
        this.forwardText('next')
        break
      }
      case DebugCommands.STEP_INTO: {
        this.forwardText('step')
        break
      }
      case DebugCommands.STEP_OUT: {
        this.forwardText('return')
        break
      }
      case DebugCommands.RESTART: {
        // TODO
        break
      }
      case DebugCommands.STOP: {
        this.stopDebug()
        break
      }
      default: {
        throw new Error('Unhandled action type')
      }
    }
  }

  onRunStateChange = (runState) => {
    // Notify observers
    if (runState !== this.lastRunState) {
      // console.log(`CodeRunner: onRunStateChange(${this.lastRunState} -> ${runState})`)
      this.lastRunState = runState
      this.runStateCallbacks.forEach(callback => callback(runState))
    }
  }

  start = (resetHardware=true) => {
    // Start worker thread and prepare to run code
    return new Promise((resolve, reject) => {
      if (this.rpcWorker === null) {
        // TODO: Make SimDebugBackend track connected/disconnected, so
        //       start/stop buttons are disabled when target not connected.
        //       That will eliminate the need for this check.
        if (!this.modelController || !this.modelController.modelReady) {
          // Our sim target is not connected (must have failed to load the environment / model)
          const err = 'Start invoked with no connected target (sim)'
          console.debug(err)
          return reject(err)
        }

        // We could call clear markers here, but the repl is going to clear the markers every time the program ends.
        this.onRunStateChange(TargetStates.LOADING)
        this.modelController.startupActions(resetHardware)
        this.pendingWorkerStartup = true
        ++this.runCount
        // console.log(`>>>starting (${this.runCount})`)
        this.resolveStart = resolve
        this.rejectStart = reject
        this.rpcWorker = this.workerPool.take()   // new MainRpcWorker(this)
        this.rpcWorker.postRPC('setSharedCodebotBuffer', [this.modelController.sharedArray, this.runCount])

        // Enforce timeout, reject promise and reset loading state if thread failed to start
        this.startTimer = new TimeoutTail(() => {
          if (this.pendingWorkerStartup) {
            // console.log(`startTimer: rejecting due to timeout. (${this.runCount})`)
            this.rejectStart('Timeout starting Worker thread')
            this.resolveStart = this.rejectStart = null
            this.rpcWorker.terminate()
            this.rpcWorker = null
            this.pendingWorkerStartup = false
            this.onRunStateChange(TargetStates.STOPPED)
          }
        }, 10000)
      } else {
        const err = 'Start invoked with active worker'
        console.debug(err)
        return reject(err)
      }
    })
  }

  runJS = (code) => {
    this.rpcWorker.postRPC('runJS', [code])
  }

  runPython = (code, breakpoints) => {
    this.rpcWorker.postRPC('runPython', [code, breakpoints])
  }

  updateBreakpoints = (breakpoints) => {
    this.modelController.hw.updateBreakpoints(breakpoints)
  }

  stop = () => {
    // Terminate worker thread (stop program)
    // console.log(`<<<stopping (${this.runCount})`)
    if (this.rpcWorker) {
      if (this.pendingWorkerStartup) {
        // Startup still in-process. Currently this happens as the REPL is stopped automatically
        // when "Run" button is pressed quickly after "Stop" while the REPL is still starting up.
        this.pendingWorkerStartup = false
        this.startTimer.cancel()
        console.log(`Stop issued while awaiting worker startup (${this.runCount})`)
      }

      // Kill worker thread immediately. Sort of. See comment below.
      this.rpcWorker.terminate()
      this.rpcWorker = null

      // There's no callback to know when a Worker is really dead. And in fact, it can still be looping away
      // hammering new values into our SharedArrayBuffer. That used to be a problem when we reset the buffer in
      // the terminalActions() function. But now we reset the SAB in startupActions() so it's no biggie.
      // Experimented with setTimeout() to defer and make sure the Worker was really dead before terminalActions() ran.
      // However, with a tight loop it could be hundreds of milliseconds before it terminates.
      this.modelController.terminalActions()
      this.modelController.recreateSharedArrayBuffer()
      this.onRunStateChange(TargetStates.STOPPED)
    } else {
      console.debug('Stop invoked with no active worker')
    }
  }

  notifySharedBufferReceived = (runID) => {
    // Worker thread has just started and received shared memory buffer
    // console.log(`notifySharedBufferReceived(${runID}) -> (${this.runCount})`)
    if (this.pendingWorkerStartup) {
      this.startTimer.cancel()
      this.pendingWorkerStartup = false
      this.resolveStart()
      this.resolveStart = this.rejectStart = null
    }
  }

  notifyBeginExecution = () => {
    // Brython imports complete and code has been parsed by worker thread
    this.onRunStateChange(TargetStates.RUNNING)
    this.cntStdoutBufs = 0
  }

  notifyStdout = (text) => {
    // this passes information back up to the target interface
    this.cntStdoutBufs++
    // console.log(`${this.cntStdoutBufs}:stdout[${text.length}]`)
    this.modelController.hw.setStdoutBufQueued(0)
    this.stdoutCallbacks.forEach(callback => callback(text))
  }

  notifyRunComplete = () => {
    // Code has terminated.
    // Allow time for next updateFromWorker() to update HW model prior to stopping updates.
    //   - Without this delay for example, if you set an LED and end immediately it will not be lit.
    // TODO: Replace timeout with a flag in updateFromWorker() so the terminalActions() happen
    //       immediately AFTER the update.
    setTimeout(() => {
      this.stop()
    }, 20)
  }

  notifyDebugState = (name, file, line, locals, globals) => {
    // TODO Get a team consensus on "single dictionary versus argument list"
    // For now, sticking with the model Jeff established
    this.debugInfo = {
      line : line,
      variables : {
        Locals : locals,
        Globals : globals,
      },
      // Upcoming, these were not in Jeff's code
      name : name,
      file : file,
    }
    this.debugStateCallbacks.forEach(callback => callback(this.debugInfo))
  }

  notifyUserProgramError = (err) => {
    userSessionStore.setUserSessionLastErrorMessage(err)
    this.userProgramErrorCallbacks.forEach(callback => callback(err))
  }
}
