import React from 'react'
import { useContentDirector } from './ContentDirectorContext'
import { RESPONSE_STATUS } from '../content-manager/fetch-responses'
import { getContestBandLeaderboardFromScoreId } from '../content-manager/contests/contest-use-cases/getContestBandLeaderboardFromScoreId'
import { getTotalScoreEarnedByScoreId } from '../content-manager/user-progress/user-progress-use-cases/getTotalScoreEarnedByMissionPack'
import { useMission } from './MissionContext'

let contestsStateInterface = {
  openDialog: () => {},
  closeDialog: () => {},
  setFetchContestsResponseState: () => {},
  setContestsState: () => {},
  setSelectedLeaderboardScoreIdState: () => {},
  toggleIndyRegistrationPanelOpen: () => {},
  setPutIndyContestRegistrationResponseState: () => {},
}

const ContestsStateContext = React.createContext()
function ContestsProvider({ children }) {
  const [fetchContestsResponse, setFetchContestsResponse] = React.useState({})
  const [putIndyContestRegistrationResponseState, setPutIndyContestRegistrationResponseState] = React.useState({})
  const [contestsDialogOpen, setContestsDialogOpen] = React.useState(false)
  const [contests, setContests] = React.useState([])
  const [selectedLeaderboardScoreId, setSelectedLeaderboardScoreId] = React.useState(null)
  const [contestsInitialized, setContestsInitialized] = React.useState(false)
  const [indyRegistrationPanelOpen, setIndyRegistrationPanelOpen] = React.useState(false)
  const [missionState] = useMission()

  function toggleIndyRegistrationPanelOpen() {
    setIndyRegistrationPanelOpen(x => !x)
  }

  React.useEffect(() => {
    if (fetchContestsResponse?.status === RESPONSE_STATUS.SUCCEEDED) {
      setContestsInitialized(true)
    }
  }, [fetchContestsResponse])

  const { contentSequenceItemScored, contentSequenceItem } = useContentDirector()
  let objectiveHighScore = null
  if (contentSequenceItemScored) {
    objectiveHighScore = getContestBandLeaderboardFromScoreId(contentSequenceItem?.flow?.id)?.highScore
  }

  let selectedLeaderboardData = null
  if (selectedLeaderboardScoreId) {
    selectedLeaderboardData = getContestBandLeaderboardFromScoreId(selectedLeaderboardScoreId)
    if (selectedLeaderboardData) {
      selectedLeaderboardData.userScore = getTotalScoreEarnedByScoreId(selectedLeaderboardScoreId)
    }
  }

  contestsStateInterface.setFetchContestsResponseState = setFetchContestsResponse
  contestsStateInterface.setPutIndyContestRegistrationResponseState = setPutIndyContestRegistrationResponseState
  contestsStateInterface.openDialog = () => setContestsDialogOpen(true)
  contestsStateInterface.closeDialog = () => setContestsDialogOpen(false)
  contestsStateInterface.setContestsState = setContests
  contestsStateInterface.setSelectedLeaderboardScoreIdState = setSelectedLeaderboardScoreId
  contestsStateInterface.toggleIndyRegistrationPanelOpen = toggleIndyRegistrationPanelOpen

  let activePackHasActiveContest = false
  contests.forEach((contest) => {
    if (contest.active && contest.registered && contest.missionPackId === missionState?.activeMissionPack?.id) {
      activePackHasActiveContest = true
    }
  })
  return (
    <ContestsStateContext.Provider
      value={{
        fetchContestsResponse,
        fetchContestsInProgress: fetchContestsResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        fetchContestsFailed: fetchContestsResponse?.status === RESPONSE_STATUS.FAILED,
        contestsDialogOpen,
        contests,
        contestVisible: !!contests.find(contest => contest.visible),
        contestsStateInterface,
        objectiveHighScore,
        selectedLeaderboardScoreId,
        selectedLeaderboardData,
        contestsInitialized,
        indyRegistrationPanelOpen,
        putIndyContestRegistrationResponseState,
        activePackHasActiveContest,
      }}>
      {children}
    </ContestsStateContext.Provider>
  )
}

function useContests() {
  return React.useContext(ContestsStateContext)
}

export {
  ContestsProvider,
  useContests,
  contestsStateInterface,
}
