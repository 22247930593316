import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { Button } from '@material-ui/core'
import { usbDebugBackend } from './Players'
import { getDeviceIdByTarget } from './device-flasher-dialog/device-flash-constants'
import { openDeviceFlasherDialog } from './device-flasher-dialog/DialogFlasherContext'

const useStyles = makeStyles((theme) => {
  const darkMode = theme?.palette?.type === 'dark' ?? false
  return ({
    snack: {
    },
    refText: {
      color: darkMode ? 'blue' : 'white',
    },
  })
})

export default function HwVerMessage(props) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [target, setTarget] = React.useState('')
  const [displayName, setDisplayName] = React.useState('')

  // this callback could be moved to a context if necessary
  const upgradeMsg = (device, displayName) => {
    setDisplayName(displayName)
    setTarget(device)
    setOpen(true)
  }

  usbDebugBackend.setUpgradeDeviceCb(upgradeMsg)

  const handleClose = (ev) => {
    setOpen(false)
  }

  function handleButtonPress() {
    handleClose()
    openDeviceFlasherDialog(getDeviceIdByTarget(target))
  }

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  )

  const msg = (
    <>
      <p>Your <b>{displayName}</b>'s firmware version is out of date.</p>
      <center>
        <Button
          onClick={handleButtonPress}
          variant='text'
          style={{color: 'blue', textTransform: 'none', textDecoration: 'underline', fontWeight: 400}}
        >
          {'Click here to upgrade!'}
        </Button>
      </center>
    </>
  )

  return (
    <Snackbar
      className={classes.snack}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={null}
      open={open}
      message={msg}
      action={action}
    />
  )
}