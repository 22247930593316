import { ESP_ROM_BAUD } from './const'
import { ESPLoader } from './esp_loader'
export { ESPLoader } from './esp_loader'
export { CHIP_FAMILY_ESP32, CHIP_FAMILY_ESP32S2, CHIP_FAMILY_ESP32S3, CHIP_FAMILY_ESP8266, CHIP_FAMILY_ESP32C3, CHIP_FAMILY_ESP32C6, CHIP_FAMILY_ESP32H2 } from './const'

let espStub
let port

async function disconnectFromDevice() {
  // try {
  //   if (espStub) {
  //     await espStub.disconnect()
  //     espStub = undefined
  //   }
  // } catch {
  //   //
  // }
  espStub = undefined
  try {
    if (port) {
      try {
        await port.forget()
        port.removeEventListener('disconnect', () => {
          handleDeviceDisconnection()
        })
        await port.close()
      } catch (e) {
        //
      }
      port = undefined
    }
  } catch {
    //
  }
}

async function connectToDevice(pid, vid) {
  await disconnectFromDevice()

  port = await navigator.serial.requestPort()
  port.addEventListener('disconnect', () => {
    handleDeviceDisconnection()
  })
  const { usbProductId, usbVendorId } = port.getInfo()
  return !(usbProductId === pid && usbVendorId === vid)
}

async function flash(setProgress, fileName, logger) {
  try {
    await port.open({ baudRate: ESP_ROM_BAUD })
  } catch (err) {
    console.error(err)
    return 'Failed to connect to device'
  }
  setProgress(2)


  const esploader = new ESPLoader(port, logger)
  try {
    await esploader.initialize()

    espStub = await esploader.runStub()
    espStub.addEventListener('disconnect', () => {
      espStub = false
      return 'Failed'
    })
  } catch (err) {
    await disconnectFromDevice()
    console.error(err)
    return 'Failed to initialize device'
  }
  setProgress(3)

  let contents
  try {
    let fetchUrl = process.env.PUBLIC_URL + '/firmware/' + fileName
    const file = await fetch(fetchUrl)
    contents = await file.arrayBuffer()
  } catch (err) {
    console.error(err)
    return 'Failed to fetch firmware'
  }

  setProgress(5)
  try {
    await espStub.flashData(
      contents,
      (bytesWritten, totalBytes) => {
        Math.floor((bytesWritten / totalBytes) * 95)
        setProgress((Math.floor((bytesWritten / totalBytes) * 95) + 5))
      },
      0
    )
    await new Promise(resolve => setTimeout(resolve, 100))
  } catch (err) {
    await disconnectFromDevice()
    console.error(err)
    return 'Failed to flash firmware'
  }
}

let handleDeviceDisconnection = () => {}
async function flashDevice(onDeviceDisconnected, setProgress, fileName) {
  let resp

  handleDeviceDisconnection = onDeviceDisconnected
  try {
    const logger = {
      // log: (...args) => console.log(args),
      // debug: (...args) => console.log(args),
      // error: (...args) => console.error(args),
      log: () => {},
      debug: () => {},
      error: () => {},
    }

    resp = await flash(setProgress, fileName, logger)
  } catch (err) {
    console.error(err)
    resp = 'An unexpected error has occurred'
  }
  handleDeviceDisconnection = () => {}

  await disconnectFromDevice()
  return resp
}

export { connectToDevice, flashDevice }