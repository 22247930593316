import { getFloatingLicenseSeatsFromCloud } from '../group-gateways'
import {
  presentFetchFloatingLicenseSeatsResponseStarted,
  presentFetchFloatingLicenseSeatsResponseFailed,
  presentFetchFloatingLicenseSeatsResponseSucceeded,
  presentGroups,
  presentFloatingLicenseSeats,
} from '../group-presenter'
import { setFloatingLicenseSeatsInStore } from '../group-store'

let fetchRunning = false

async function fetchFloatingLicenseSeats(){
  if (fetchRunning) {
    return
  }
  fetchRunning = true
  presentFetchFloatingLicenseSeatsResponseStarted()
  const { data, error } = await getFloatingLicenseSeatsFromCloud()
  if (error) {
    presentFetchFloatingLicenseSeatsResponseFailed(error)
    return
  }

  fetchRunning = false
  setFloatingLicenseSeatsInStore(data)
  presentFetchFloatingLicenseSeatsResponseSucceeded()
  presentFloatingLicenseSeats()
  presentGroups()
}

export { fetchFloatingLicenseSeats }