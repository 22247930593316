// Utility functions for Babylon.js

import * as BABYLON from '@babylonjs/core'
import CheckMark from './../assets/check.glb'

// Asset Cache Plan:
// Cache assets for lifetime of an Environment. When another env starts adding assets, prior env assets can be disposed.
// To achieve this we can have the root Env.load() call initAssetCache(envName) which will flush the cache if envName differs
// from the current assetCache env name value. Flushing means calling dispose() and removing ref from cache.
// Note that some objects can be marked "noDispose", e.g. CodeBot parts.
//   - With that addition, proposed cache obj structure = {ref:val, dispose:true}
// NOTE: Currently the cache is not cleared per-Environment. That's a TODO if we decide we're using too much RAM.
//       So basically, this stuff never gets disposed!
export const assetCache = new Map()
export const hitCache = async (objName, ctor) => {
  // Helper func to handle single-value cache item. Every cache item is a Promise. (await a non-promise wraps it...)
  // For aggregate items (e.g. Promise lists) just name the aggregate and cache the Promise.all().
  let obj = assetCache.get(objName)
  if (obj === undefined) {
    obj = await ctor(objName)
    assetCache.set(objName, obj)
  }
  return obj
}

export function localAxes(size, scene) {
  const pilot_local_axisX = BABYLON.Mesh.CreateLines('pilot_local_axisX', [
    new BABYLON.Vector3.Zero(), new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, 0.05 * size, 0),
    new BABYLON.Vector3(size, 0, 0), new BABYLON.Vector3(size * 0.95, -0.05 * size, 0),
  ], scene)
  pilot_local_axisX.color = new BABYLON.Color3(1, 0, 0)

  const pilot_local_axisY = BABYLON.Mesh.CreateLines('pilot_local_axisY', [
    new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(-0.05 * size, size * 0.95, 0),
    new BABYLON.Vector3(0, size, 0), new BABYLON.Vector3(0.05 * size, size * 0.95, 0),
  ], scene)
  pilot_local_axisY.color = new BABYLON.Color3(0, 1, 0)

  const pilot_local_axisZ = BABYLON.Mesh.CreateLines('pilot_local_axisZ', [
    new BABYLON.Vector3.Zero(), new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, -0.05 * size, size * 0.95),
    new BABYLON.Vector3(0, 0, size), new BABYLON.Vector3(0, 0.05 * size, size * 0.95),
  ], scene)
  pilot_local_axisZ.color = new BABYLON.Color3(0, 0, 1)

  const local_origin = BABYLON.MeshBuilder.CreateBox('local_origin', { size: 1 }, scene)
  local_origin.isVisible = false

  pilot_local_axisX.parent = local_origin
  pilot_local_axisY.parent = local_origin
  pilot_local_axisZ.parent = local_origin

  return local_origin
}

export function assetPath(asset) {
  // Extract file path/name from Webpack static media location
  return [asset.substring(0, asset.lastIndexOf('/') + 1), asset.substring(asset.lastIndexOf('/') + 1)]
}

// Cache image textures for local use, e.g. getPickedColor().
// Normally image textures only reside in GPU, and texture.readPixels() is computationally expensive,
// allocating a large buffer on each invocation.
const pixBufCache = new Map()

async function getCachedPixelBuffer(texture) {
  // Use url as key. Tried using uniqueId, but it changes each time GLB is loaded.
  let pixBuf = pixBufCache.get(texture.url)
  if (!pixBuf) {
    pixBuf = await texture.readPixels()
    pixBufCache.set(texture.url, pixBuf)
  }
  return pixBuf
}

export async function getPickedColor(pickResult) {
  // Given a pickResult (normally from a raycast) return the color that was hit. Return null if indeterminate.
  // Note: This currently requires mesh to have a Material assigned. It doesn't support per-vertex colors.
  //       When you get around to adding that, see implementation of getTextureCoordinates() for details on
  //       how to get nearest vertices: https://github.com/BabylonJS/Babylon.js/blob/57cfc50feb0762b22128dcd8a9207ebf474cccfd/src/Collisions/pickingInfo.ts
  if (pickResult.hit) {
    const mesh = pickResult.pickedMesh

    if (mesh.material) {
      const texture = mesh.material.albedoTexture || mesh.material.diffuseTexture
      if (texture) {
        const { x, y } = pickResult.getTextureCoordinates()  // uv coords (0.0 to 1.0)

        // Convert uv coordinates to pixel offsets
        const { width, height } = texture.getBaseSize()
        let xpix = Math.trunc(x * width)
        let ypix = Math.trunc(y * height)   // TODO: Should it be ((1 - y) * height) ???

        // Constrain to bounds of texture. Note: I've seen getTextureCoordinates() return small negative y-values, so we need to enforce lower bounds too.
        xpix = Math.min(Math.max(xpix, 0), width - 1)
        ypix = Math.min(Math.max(ypix, 0), height - 1)

        // Read pixel color from image texture
        const pixBuf = await getCachedPixelBuffer(texture)

        // TODO: Confirm texture is RGBA; adapt to other formats.
        const chunkOffset = ypix * 4 * width + xpix * 4
        if (chunkOffset + 3 > pixBuf.length || pixBuf[chunkOffset] === undefined) {
          console.log('Error picking texture color: ', pickResult)
          // debugger;
        }
        const color = {
          r: pixBuf[chunkOffset + 0],
          g: pixBuf[chunkOffset + 1],
          b: pixBuf[chunkOffset + 2],
          a: pixBuf[chunkOffset + 3],
        }

        // console.log("Picked texture color = ", color)
        return color
      } else {
        // Attempt to find a material color
        let color = mesh.material.albedoColor || mesh.material.diffuseColor || mesh.material.baseColor || mesh.material.mainColor
        if (color) {
          if (color instanceof BABYLON.Color3) {
            color = color.toColor4()
          }
          // For consistency with texture default above, scale to INT color.
          // TODO: Probably should change all color refs returned by getPickedColor() to float (0-1.0) format.
          return color.scale(255)
        }
      }
    }
  }
  return null
}

export function showNormals(mesh, size, color, sc) {
  const normals = mesh.getVerticesData(BABYLON.VertexBuffer.NormalKind)
  const positions = mesh.getVerticesData(BABYLON.VertexBuffer.PositionKind)
  if (!(normals && positions)) {
    return null
  }
  color = color || BABYLON.Color3.White()
  size = size || 1
  const lines = []
  for (let i = 0; i < normals.length; i += 3) {
    const v1 = BABYLON.Vector3.FromArray(positions, i)
    const v2 = v1.add(BABYLON.Vector3.FromArray(normals, i).scaleInPlace(size))
    lines.push([v1.add(mesh.position), v2.add(mesh.position)])
  }
  const normalLines = BABYLON.MeshBuilder.CreateLineSystem('normalLines', { lines: lines }, sc)
  normalLines.color = color
  return normalLines
}

const OBSTACLE_FRICTION = 5.9
const CYLINDER_TESSELLATION = 8
const SPHERE_SEGMENTS = 16
const CAPSULE_SUBDIVISIONS = 4

export function makeImpostor(mesh, parent, physicsOptions = { mass: 0, friction: OBSTACLE_FRICTION }, impostorsVisible = false) {
  /* Check if given mesh has special "Impostor..." name, and if so:
       - Create corresponding impostorObject (simple mesh geometry) with appropriate scale/rotation/position.
       - Create PhysicsImpostor with provided physicsOptions, bound to impostorObject.
       - Set parent of impostorObject to provided 'parent' mesh.
       - Hide given mesh as well as impostorObject.
       - Return the impostorObject mesh, or null if no impostor created.
  */

  let impostorObject
  let impostorType = BABYLON.PhysicsImpostor.NoImpostor

  if (!mesh.name.startsWith('Impostor')) {
    return null
  }

  let diameter, height, scaling, centerWorld, max, min, rotation, rotationQuat
  let meshImpostorClone = null, boundingInfo

  // Merge parent transforms into this mesh so we have absolute position
  // Strive to be idempotent with respect to mesh object, to allow cached GLB mesh impostors
  // to be passed to makeImpostor() multiple times. The 'firiaMerged' status flag is for that purpose.
  if (!mesh.firiaMerged) {
    mesh.firiaMerged = true  // Flag that we've already merged parent transforms, and adorned the mesh object.

    // Use the parent's rotation if this is a primitive0 child mesh
    // (DE) Confirmed this is here from Curt's line-follower classroom hacking
    // TODO: Clean all this up!
    if (mesh.name.endsWith('_primitive0')) {
      // Capture the parent mesh rotation and quaternion if any before getting bounding box info
      rotation = mesh.parent.rotation
      rotationQuat = mesh.parent.rotationQuaternion

      // Remove the parent mesh rotation quaternions
      mesh.parent.rotationQuaternion = null

      // Clone the incomming impostor mesh parent for bounding info
      meshImpostorClone = mesh.clone(mesh.name + 'Clone')

      // Restore the mesh rotation
      if (rotationQuat !== null) {
        mesh.parent.rotationQuaternion = rotationQuat
        // const euler = rotationQuat.toEulerAngles()
        // mesh.parent.rotation = new BABYLON.Vector3(euler.x, euler.y, euler.z)
      } else {
        mesh.parent.rotation = rotation
      }

      mesh.setParent(null)
    } else {
      mesh.setParent(null)
      // Capture the mesh rotation and quaternion if any before getting bounding box info
      rotation = mesh.rotation
      rotationQuat = mesh.rotationQuaternion

      // Remove the mesh rotation quaternions
      mesh.rotationQuaternion = null

      // Clone the incomming impostor mesh parent for bounding info
      meshImpostorClone = mesh.clone(mesh.name + 'Clone')
    }

    mesh.firiaBoundingInfo = meshImpostorClone.getBoundingInfo()
    mesh.firiaRotation = rotation
    mesh.firiaRotationQuat = rotationQuat
    // Done with this mesh
    meshImpostorClone.dispose()
  }
  boundingInfo = mesh.firiaBoundingInfo
  rotation = mesh.firiaRotation
  rotationQuat = mesh.firiaRotationQuat

  if (mesh.name.startsWith('ImpostorSphere')) {
    // diameter = boundingInfo.boundingSphere.radiusWorld * 2   // As of 6/14/2021 returns wrong value
    diameter = Math.abs(boundingInfo.boundingBox.minimumWorld.x - boundingInfo.boundingBox.maximumWorld.x)
    centerWorld = mesh.position
    scaling = new BABYLON.Vector3(1, 1, 1)
    // mesh.showBoundingBox = true
    // console.log(`Sphere ${mesh.name} at ${centerWorld} diameter = ${diameter}`)
  } else {
    scaling = boundingInfo.boundingBox.extendSizeWorld
    centerWorld = boundingInfo.boundingBox.centerWorld
    diameter = scaling.x
    height = scaling.y

    // Determine if this mesh is a cylinder assuming a circular (non eliptical) shape
    min = boundingInfo.boundingBox.minimum
    max = boundingInfo.boundingBox.maximum

    // Assign diameter and height of cylindrical mesh
    if ((max.x - min.x) === (max.y - min.y)) {
      diameter = (max.x - min.x)
      height = (max.z - min.z)
    }

    if ((max.x - min.x) === (max.z - min.z)) {
      diameter = (max.x - min.x)
      height = (max.y - min.y)
    }

    if ((max.y - min.y) === (max.z - min.z)) {
      diameter = (max.y - min.y)
      height = (max.x - min.x)
    }
  }

  // Set appropriate impostor type (Note: small cylinders will be considered box types)
  const imp_name = mesh.name + '_imp'
  if (mesh.name.startsWith('ImpostorBox')) {
    impostorType = BABYLON.PhysicsImpostor.BoxImpostor
    impostorObject = BABYLON.MeshBuilder.CreateBox(mesh.name, { height: max.x - min.x, width: max.y - min.y, depth: max.z - min.z })
  } else if (mesh.name.startsWith('ImpostorCylinder')) {
    impostorType = BABYLON.PhysicsImpostor.CylinderImpostor
    impostorObject = BABYLON.MeshBuilder.CreateCylinder(mesh.name, { diameter: diameter, height: height, tessellation: CYLINDER_TESSELLATION })
  } else if (mesh.name.startsWith('ImpostorSphere')) {
    impostorType = BABYLON.PhysicsImpostor.SphereImpostor
    impostorObject = BABYLON.MeshBuilder.CreateSphere(imp_name, { diameter: diameter, segments: SPHERE_SEGMENTS })
  } else if (mesh.name.startsWith('ImpostorPlane')) {  // NOT FULLY VETTED
    impostorType = BABYLON.PhysicsImpostor.PlaneImpostor
    impostorObject = BABYLON.MeshBuilder.CreatePlane(mesh.name, { width: scaling.y * 4, height: scaling.z * 4 })
  } else if (mesh.name.startsWith('ImpostorCapsule')) {  // NOT FULLY VETTED
    impostorType = BABYLON.PhysicsImpostor.CapsuleImpostor
    impostorObject = BABYLON.MeshBuilder.CreateCapsule(mesh.name, { radius: scaling.x * 1.5, height: scaling.y * 2, capSubdivisions: CAPSULE_SUBDIVISIONS })
  } else {
    console.log('Unsupported imposter type!')
    return null
  }

  // Position, scale and rotate the impostor object in the correct location in the scene
  impostorObject.position.set(centerWorld.x, centerWorld.y, centerWorld.z)
  impostorObject.scaling.copyFromFloats(scaling.x, scaling.y, scaling.z)

  // Adjust the impostor mesh to the correct rotation
  if (rotationQuat !== null) {
    const euler = rotationQuat.toEulerAngles()
    if (mesh.name.endsWith('_primitive0')) {
      // Compatible with classroom environment hack.
      impostorObject.rotation = new BABYLON.Vector3(-euler.x, -euler.y, -euler.z)
    } else {
      impostorObject.rotation = new BABYLON.Vector3(euler.x, euler.y, euler.z)
    }
  } else {
    impostorObject.rotation = rotation
  }

  // Create the physics impostor
  if (physicsOptions !== null) {
    impostorObject.physicsImpostor = new BABYLON.PhysicsImpostor(impostorObject, impostorType, physicsOptions)
  }

  // Finally hide the impostor, have it check for camera collisions and assign its parent
  mesh.isVisible = false
  mesh.isPickable = false  // Make invisible also to raycasts (line sensors / prox sensors)
  impostorObject.isVisible = impostorsVisible
  impostorObject.isPickable = false
  impostorObject.checkCollisions = true  // For camera collisions
  impostorObject.parent = parent

  // For debugging set impostorsVisible = true
  if (impostorsVisible) {
    const material = new BABYLON.StandardMaterial('mat')
    material.diffuseColor = new BABYLON.Color3(0.0, 0.0, 0.8)
    material.ambientColor = new BABYLON.Color3(0.0, 0.0, 0.8)
    material.alpha = 0.5
    impostorObject.material = material
    // impostorObject.material.wireframe = true
  }

  return [impostorObject, impostorType]
}

export function getRootMesh(importedMeshes) {
  /* Given a 'meshes' hierarchy from BABYLON.SceneLoader.ImportMesh callback, remove the
     root transform and return the naked root mesh object.
     This is necessary for physics to handle imported objects that have been scaled/translated.
     See: https://forum.babylonjs.com/t/how-to-correctly-import-gltf-and-add-physics/20234/2

     Synopsis:
     - Blender is a z-up right handed world.
     - Babylon is a y-up left handed world by default.
     - To complicate things, .gltf is also a right handed coordinate system.
     - This could be resolved by setting scene.useRightHandedSystem = true in Babylon... BUT:
       * That would change all other positioned models.
       * Babylon forums indicate there are a number of bugs where useRightHandedSystem isn't accounted
         for by other functions, and it is not well tested. Left-handed is the mainstream.

     What if you don't use this function?
       At minimum, due to the "helpful" root transform that the GLTF loader inserts for you,
       the y-coordinate will be flipped (negated) from the Physics Engine's perspective.
  */
  const meshParent = importedMeshes[0]
  const mesh = meshParent.getChildMeshes()[0]
  mesh.setParent(null)
  meshParent.dispose()
  return mesh
}

export class PerformanceTicker {
  // Track average rate (Hz) of periodic operations such as render frames (FPS), over a fixed count update window.
  // Simplistic for minimal performance impact (vs sliding-window approach, etc.)
  constructor(avgTickWindow = 300) {
    this.window = avgTickWindow   // Ex: 300 gives update at 10s rate for 30Hz event
    this.tickCount = 0
    this.tm = performance.now()
    this.hz = null
  }

  tick = () => {
    // Periodic event update. Returns true on new Hz calculation.
    ++this.tickCount
    if (this.tickCount === this.window) {
      const ms = performance.now()
      this.hz = this.tickCount * 1000 / (ms - this.tm)
      this.tm = ms
      this.tickCount = 0
      // console.log(`PerformanceTicker: ${Math.round(this.hz)}Hz`)
      return true
    }
    return false
  }
}

export function disposePromise(disposable) {
  return new Promise((resolve, reject) => {
    try {
      if (!disposable) {
        // Acceptable to call with null object
        resolve()
        return
      }
      if (disposable.onDisposeObservable) {
        disposable.onDisposeObservable.add((obj) => {
          resolve()
        })
      }
      if (disposable.disposeArgs) {
        disposable.dispose(...disposable.disposeArgs)
      } else {
        disposable.dispose()
      }
      if (!disposable.onDisposeObservable) {
        // console.warn('dispose() with no callback', disposable)
        resolve()
      }
    } catch (err) {
      return reject(err)
    }
  })
}

export function getFontFitDynamicText(textStr, ctx, dtWidth, dtHeight, pctMargin) {
  // Return a font sized to fit textStr within DynamicTexture ctx
  const fontType = 'monospace'
  const size = 12  // arbitrary
  ctx.font = size + 'px ' + fontType
  const textWidth = ctx.measureText(textStr).width
  const ratio = textWidth / size
  const font_size = Math.floor(dtWidth / (ratio * (1 + pctMargin)))
  const font = font_size + 'px ' + fontType
  return font
}

export function setPhysicsNoContactCollision(physicsImpostor, doSet=true) {
  // Use Ammo.JS native body flags since Babylon doesn't yet support ghost bodies
  const ammoBody = physicsImpostor.physicsBody
  const CF_NO_CONTACT_RESPONSE = 4  // BABYLON.CollisionFlags.CF_NO_CONTACT_RESPONSE
  const curFlags = ammoBody.getCollisionFlags()
  ammoBody.setCollisionFlags(
    doSet ? curFlags | CF_NO_CONTACT_RESPONSE : curFlags & (~CF_NO_CONTACT_RESPONSE)
  )
}

export async function loadGLBWithPhysics(modelAsset, label, physicsOptions = { mass: 0, friction: 6.0 },
                                         scaling = 1.0, position = null, rotation = null, scene = undefined) {
  let path, name
  [path, name] = assetPath(modelAsset)
  const noCache = false

  if (position instanceof Array) {
    position = new BABYLON.Vector3(...position)
  }

  // Load array of meshes from the GLB file
  let meshes
  if (noCache) {
    const imported = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
    meshes = imported.meshes
  } else {
    // Cached implementation - only return clones. Cache all GLB assets, and never dispose them.
    const modelAssets = await hitCache(`MD_${path}/${name}`, async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync(path, name, scene)
    })
    // Clone assetContainer into the scene. Keep same names, create clones not instances.
    const instModel = modelAssets.instantiateModelsToScene(nm => nm, true, false)
    const rootMesh = instModel.rootNodes[0]
    meshes = rootMesh.getChildMeshes()  // Get all child Mesh objects recursively
    meshes.unshift(rootMesh)  // Prepend the root itself
  }

  // Create a new mesh to be our root
  const root = new BABYLON.Mesh(label, scene)

  // Position the Center of Gravity if specified in mesh (can be a simple "marker" cube, etc.)
  for (const m of meshes) {
    if (m.name === 'COG') {
      // Merge parent transforms into this mesh so we have absolute position
      m.setParent(null)

      // Make invisible
      m.isVisible = false
      m.isPickable = false

      // Adjust center of gravity
      const cog = m.position
      root.position.addInPlace(cog)
      break
    }
  }

  // Define mesh name parsing for identifying Physics Impostors.
  // We distinguish two types: "Impostors" are invisible stand-ins, while "Prototypes" remain visible.
  // (both are PhysicsImpostor objects to the physics engine)
  const impostorShapeRegex = /(?<type>Impostor|Proto)(?<shape>Box|Cylinder|Sphere)/
  const impostorShapeIDs = {
    'Box' : BABYLON.PhysicsImpostor.BoxImpostor,
    'Cylinder' : BABYLON.PhysicsImpostor.CylinderImpostor,
    'Sphere' : BABYLON.PhysicsImpostor.SphereImpostor,
    // 'Mesh' : BABYLON.PhysicsImpostor.MeshImpostor,    // Mesh impostors cannot be physics children (no compound colliders)
  }

  // Add meshes to our root, creating imposters as we go.
  meshes.forEach((m) => {
    const match = m.name.match(impostorShapeRegex)
    if (match) {
      // Merge parent transforms into this mesh, for physics engine
      m.setParent(null)

      if (match.groups.type === 'Impostor') {
        // Make invisible
        m.isVisible = false
        m.isPickable = false
      }
      const shapeID = impostorShapeIDs[match.groups.shape]
      if (shapeID === undefined) {
        console.log('Unsupported impostor type found in GLB!')
      } else {
        // Create impostor. Physics options will be overidden by root Impostor
        m.physicsImpostor = new BABYLON.PhysicsImpostor(m, shapeID, { mass: 0 }, scene)
      }

      root.addChild(m)
    } else if (m.parent == null) {
      root.addChild(m)
    }
  })

  // Apply requested pre-physics transformations (pos/rot/scale)
  if (rotation instanceof BABYLON.Vector3) {
    root.rotation = rotation.clone()
  } else {
    root.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(rotation), 0)
  }
  if (position instanceof BABYLON.Vector3) {
    root.position = position.clone()
  }
  root.scaling.scaleInPlace(scaling)

  // Create root Impostor object
  root.physicsImpostor = new BABYLON.PhysicsImpostor(root, BABYLON.PhysicsImpostor.NoImpostor, physicsOptions, scene)
  return root
}

export async function loadGLB(modelAsset, label, scaling = 1.0, position = null, rotation = null, scene = undefined) {
  let path, name
  [path, name] = assetPath(modelAsset)

  if (position instanceof Array) {
    position = new BABYLON.Vector3(...position)
  }

  // Load array of meshes from the GLB file
  // const { meshes, animationGroups } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)

  // Cached implementation - only return clones. Cache all GLB assets, and never dispose them.
  const modelAssets = await hitCache(`MD_${path}/${name}`, async () => {
    return await BABYLON.SceneLoader.LoadAssetContainerAsync(path, name, scene)
  })
  // Clone assetContainer into the scene. Keep same names, create clones not instances.
  const instModel = modelAssets.instantiateModelsToScene(nm => nm, true, false)
  const rootMesh = instModel.rootNodes[0]
  const meshes = rootMesh.getChildren(null, false)  // Get all children recursively
  meshes.unshift(rootMesh)  // Prepend the root itself
  const animationGroups = instModel.animationGroups

  // Create a new mesh to be our root
  // const root = new BABYLON.TransformNode(label)
  const root = new BABYLON.Mesh(label, scene)   // Change to empty mesh rather than TransformNode so scene.getMeshesByTags() works
  meshes[0].parent = root

  // Apply requested pre-physics transformations (pos/rot/scale)
  if (rotation instanceof BABYLON.Vector3) {
    root.rotation = rotation.clone()
  } else {
    root.rotation = new BABYLON.Vector3(0, BABYLON.Tools.ToRadians(rotation), 0)
  }
  if (position instanceof BABYLON.Vector3) {
    root.position = position.clone()
  }
  root.scaling.scaleInPlace(scaling)
  root.animationGroups = animationGroups

  return root
}

export async function checkMark(hoverMesh, posOffset = [0, 1, 0], scale = 0.07) {
  // Create a translucent 3D check mark that hovers above given mesh. (initially disabled)
  let path, name
  [path, name] = assetPath(CheckMark)

  // Load array of meshes from the GLB file
  const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name)
  const mark = meshes[0]
  mark.setParent(null)
  mark.scaling = new BABYLON.Vector3(-scale, scale, scale)  // Scale, and correct model inverted x-direction.
  mark.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_Y  // Face the camera!
  mark.setEnabled(false)  // Not visible until externally enabled.

  // Follow the position of hoverMesh
  hoverMesh.onAfterWorldMatrixUpdateObservable.add((node) => {
    mark.position.x = node.position.x + posOffset[0]
    mark.position.y = node.position.y + posOffset[1]
    mark.position.z = node.position.z + posOffset[2]
  })

  hoverMesh.onDisposeObservable.add(() => {
    mark.dispose()
  })

  return mark
}

// Add radian angles, normalized to 0-2PI
export function radAdd(angle1, angle2) {
  let angle = angle1 + angle2
  if (angle < 0) {
    angle += 2 * Math.PI
  } else if (angle > 2 * Math.PI) {
    angle -= 2 * Math.PI
  }
  return angle
}

// Difference of radian angles, returns signed smallest angle diff.
export function radDiff(a1, a2) {
  // Return smallest angle to add to a2 to reach a1
  const da = a1 - a2
  const diffs = [da, da + 2 * Math.PI, da - 2 * Math.PI]
  return diffs.reduce((prev, cur) => {
    return Math.abs(prev) > Math.abs(cur) ? cur : prev
  })
}

// Register a list of non-physics colliders with 'mesh's ActionManager
export function registerMeshColliders(mesh, colliderList, callback, usePreciseIntersection = false) {
  // Assumes that 'mesh' has an ActionManager. We're registering "OnIntersectionEnterTrigger" actions to trigger
  // an ExecuteCodeAction which invokes the callback. A separate action must be registered for each collider mesh.
  mesh.object = mesh  // Impersonate an Impostor, so same callback can be used for both physics and non-physics.
  colliderList.forEach((meshCollider) => {
    const action = new BABYLON.ExecuteCodeAction(
      {
        trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger,
        parameter: {
          mesh: meshCollider,
          usePreciseIntersection: usePreciseIntersection,
        },
      },
      () => callback(mesh, meshCollider)
      // () => console.log('MeshCollider Hit:', mesh, meshCollider)
    )

    meshCollider.object = meshCollider  // Like an impostor

    // Unregister colliders disposed while mesh is active (prevents "ghost" collision events)
    meshCollider.onDisposeObservable.add(() => mesh?.actionManager && mesh.actionManager.unregisterAction(action))

    mesh.actionManager.registerAction(action)
  })
}

export async function loadSoundAsync(name, path, scene, options) {
  return new Promise((resolve, reject) => {
    try {
      let sound
      const readyCb = () => resolve(sound)
      sound = new BABYLON.Sound(name, path, scene, readyCb, options)
    } catch (err) {
      return reject(err)
    }
  })
}

export function loadSoundCachedAsync(name, path, scene, options) {
  return hitCache(`SND_${path}/${name}`, () => {
    return loadSoundAsync(name, path, scene, options)
  })
}

