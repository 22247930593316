// Creation functions for various 3D objects. If this gets large we can break into categories.
// - These functions will typically handle loading, rendering, and physics.
// - They return a top-level mesh with a dispose() method for cleanup.

import * as BABYLON from '@babylonjs/core'
import { assetPath, getFontFitDynamicText, setPhysicsNoContactCollision, disposePromise } from '../utils/BabylonUtils'
import TennisBallModel from '../assets/Tennis1.glb'
import { Entity } from '../SimScene'


export async function tennisBall(id, x, y, z, scene) {
  const ball = BABYLON.MeshBuilder.CreateSphere(id, { diameter: 0.66 }, scene)
  // localAxes(1, scene).parent = wheel
  ball.isVisible = false
  ball.material = new BABYLON.StandardMaterial('', scene)
  ball.material.diffuseColor = new BABYLON.Color3(0, 1, 0)
  ball.position.x = x
  ball.position.y = y
  ball.position.z = z
  ball.physicsImpostor = new BABYLON.PhysicsImpostor(ball, BABYLON.PhysicsImpostor.SphereImpostor,
    { mass: 3.0, friction: 0.7, restitution: 0.7 })

  // A 2m diameter ball, scaled to 0.66m (10x the standard 0.066m tennis ball size)
  const [path, name] = assetPath(TennisBallModel)
  const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
  const ballMesh = meshes[0]  // root transform
  ballMesh.scaling.x = 0.33
  ballMesh.scaling.y = 0.33
  ballMesh.scaling.z = 0.33
  ballMesh.parent = ball

  BABYLON.Tags.AddTagsTo(ball, 'BotCollider')

  return ball
}

export function trafficCone(id, x, y, z, scene) {
  const CONE_H = 2.9
  const CONE_BD = 1.33
  const CONE_TD = 0.54
  const CONE_PLAT_W = 2.2
  const CONE_PLAT_H = 0.133
  const CONE_SCALE = 2.0 / CONE_H  // We want a 20cm high cone

  const orangeMaterial = new BABYLON.PBRMetallicRoughnessMaterial('pbrMatteOrange', scene)
  orangeMaterial.baseColor = new BABYLON.Color3(0.8, 0.13, 0.003)
  orangeMaterial.metallic = 0
  orangeMaterial.roughness = 0.2

  const orangeShinyMaterial = new BABYLON.PBRMetallicRoughnessMaterial('pbrOrange', scene)
  orangeShinyMaterial.baseColor = new BABYLON.Color3(0.8, 0.13, 0.003)
  orangeShinyMaterial.metallic = 0.2
  orangeShinyMaterial.roughness = 0.1

  const platform = BABYLON.MeshBuilder.CreateBox(id, { width: CONE_PLAT_W * CONE_SCALE, height: CONE_PLAT_H * CONE_SCALE , depth: CONE_PLAT_W * CONE_SCALE }, scene)
  platform.isVisible = true
  platform.position.x = x
  platform.position.y = (CONE_PLAT_H * CONE_SCALE / 2) + y
  platform.position.z = z
  platform.material = orangeShinyMaterial

  const cone = BABYLON.MeshBuilder.CreateCylinder('cone',
    {
      height: CONE_H * CONE_SCALE,
      diameterTop: CONE_TD * CONE_SCALE,
      diameterBottom: CONE_BD * CONE_SCALE,
    }, scene)
  cone.isVisible = true
  cone.parent = platform
  cone.position.y = (CONE_H + CONE_PLAT_H) * CONE_SCALE / 2
  cone.material = orangeMaterial

  cone.physicsImpostor = new BABYLON.PhysicsImpostor(cone, BABYLON.PhysicsImpostor.CylinderImpostor,
    { mass: 0, friction: 0.7, restitution: 0.4 }
  )
  platform.physicsImpostor = new BABYLON.PhysicsImpostor(platform, BABYLON.PhysicsImpostor.BoxImpostor,
    { mass: 10, friction: 0.7, restitution: 0.4 }
  )

  return platform
}

export class Waypoints extends Entity {
  static reduceRandRange(val) {
    // Waypoint position and rotation coords can be passed as plain Numbers OR [min,max,step] tuples
    if (Array.isArray(val) && val.length === 3) {
      const [ min, max, step ] = val
      return Math.floor(Math.random() * (max - min) / step) * step + min
    } else {
      return val
    }
  }

  createPad = (name, position, rotation, size = {height: 0.5, width: 1.0}, scene) => {
    // Rectangular pad (flag) for waypoint visualization

    // Map text (DynamicTexture) to only one face
    const faceUVs = new Array(6)
    for (let i = 0; i < 6; i++) {
      faceUVs[i] = new BABYLON.Vector4(0, 0, 0, 0)
    }
    faceUVs[4] = new BABYLON.Vector4(0, 0, 1, 1)

    // Border color
    const faceColors = new Array(6)
    for (let i = 0; i < 4; i++) {
      faceColors[i] = new BABYLON.Color4(1, 0, 0, 1)
    }

    const boxOptions = { width: size.height, height: 0.03, depth: size.width, faceUV: faceUVs, faceColors: faceColors}

    const materialPad = new BABYLON.StandardMaterial('PadMat', scene)
    materialPad.alpha = 0.5

    const pad = BABYLON.MeshBuilder.CreateBox(name, boxOptions, scene)
    pad.material = materialPad
    pad.isPickable = false

    pad.position.set(...position.map(Waypoints.reduceRandRange))
    pad.rotation.set(...rotation.map(Waypoints.reduceRandRange))

    // Create dynamic texture on standard material
    const dtWidth = boxOptions.depth * 512
    const dtHeight = boxOptions.width * 512
    const texturePad = new BABYLON.DynamicTexture('dynamic texture', {width:dtWidth, height:dtHeight}, scene)
    // Calculate font size to fit text string
    const font = getFontFitDynamicText(name, texturePad.getContext(), dtWidth, dtHeight, 0.1)
    // Add text to dynamic texture
    texturePad.drawText(name, null, null, font, 'white', 'green', true, true)

    // Save reference for external use
    pad._dynamicTexture = texturePad

    materialPad.emissiveTexture = texturePad
    materialPad.diffuseTexture = texturePad

    // Use physics engine to detect collisions but pass-through (a "trigger volume")
    pad.physicsImpostor = new BABYLON.PhysicsImpostor(pad, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 0, group: 0, mask: 0 })
    setPhysicsNoContactCollision(pad.physicsImpostor)
    BABYLON.Tags.AddTagsTo(pad, 'BotCollider')

    return pad
  }

  load = async (scene) =>  {
    this.scene = scene
    this.pads = []
    if (this.params?.waypoints) {
      for (const w of this.params.waypoints) {
        const rotationRadians = w.rotationRad || w.rotation.map(BABYLON.Tools.ToRadians)
        const pad = this.createPad(w.name, w.position, rotationRadians, w.size, scene)
        this.pads.push(pad)
      }
    }
  }

  unload = async () => {
    if (this.pads) {
      await Promise.all(this.pads.map(disposePromise))
      this.pads.length = 0
    }
  }
  setOptions = (params) => {
    this.params = params
  }
}