import { Entity } from '../SimScene'
import { GridMaterial } from '@babylonjs/materials'
import * as BABYLON from '@babylonjs/core'
import { disposePromise, loadGLB } from '../utils/BabylonUtils'
import StudioSoftboxEnv from '../assets/StudioSoftbox.env'
import LightningBolt from '../assets/LightningBolt.glb'
import { hitCache } from '../utils/BabylonUtils'



const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7


class SimLights extends Entity {
  load = async (scene) => {
    this.scene = scene

    // TODO: Needed? Effectual?
    // Brighten things up a bit
    scene.ambientColor = new BABYLON.Color3(0.5, 0.5, 0.5)


    // Ambient lighting provided by HDR environment
    // this.envTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    this.envTexture = await hitCache('Env_StudioSoftbox', () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    })

    scene.environmentTexture = this.envTexture

    // Directional shadow-casting light.
    this.light = new BABYLON.DirectionalLight('dir01', new BABYLON.Vector3(1, 1, 1), scene)

    // Position the shadow-casting light near player-1
    this.light.position = new BABYLON.Vector3(-3, 3, 0)
    this.light.intensity = 1.0

    // Visualize directional light source (beautiful yellow sun!)
    // const lightSphere = BABYLON.Mesh.CreateSphere('sphere', 10, 2, scene)
    // lightSphere.position = this.light.position
    // lightSphere.material = new BABYLON.StandardMaterial('light', scene)
    // lightSphere.material.emissiveColor = new BABYLON.Color3(1, 1, 0)
    // localAxes(3, scene).parent = lightSphere

    this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light)
    this.shadowGenerator.useExponentialShadowMap = true
    // this.shadowGenerator.forceBackFacesOnly = true
  }

  getShadowGenerators = () => {
    return [this.shadowGenerator]
  }

  unload = async () => {
    const disposeList = [this.shadowGenerator, this.light]
    await Promise.all(disposeList.map(disposePromise))
    this.scene.environmentTexture = null
  }
}

class SimGround extends Entity {
  load = (scene) => {
    this.ground = BABYLON.Mesh.CreateGround('ground', 10, 10, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions

    this.ground.material = new GridMaterial('groundMaterial', scene)
    // localAxes(3, scene).parent = this.ground

    this.ground.freezeWorldMatrix()
    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
  }

  unload = async () => {
    await disposePromise(this.ground)
  }
}

class SimObstacles extends Entity {
  loadBolt = async (name, pos, rot, scene) => {
    const bolt = await loadGLB(LightningBolt, 'child_' + name, 1)
    let boltBounds = bolt.getHierarchyBoundingVectors()
    const w = Math.abs(boltBounds.max.x - boltBounds.min.x)
    const h = Math.abs(boltBounds.max.y - boltBounds.min.y)
    const d = Math.abs(boltBounds.max.z - boltBounds.min.z)
    bolt.position.set(0, -(h / 2), d / 2)

    const boxOptions = { width: w * 2, height: h, depth: d }
    let parent = BABYLON.MeshBuilder.CreateBox(name, boxOptions)
    bolt.setParent(parent)
    parent.position.set(...pos)
    parent.rotation.set(...rot.map(deg => deg * Math.PI / 180))

    var mat = new BABYLON.StandardMaterial('mat')
    mat.alpha = 0.001
    parent.material = mat

    // Disappear when picked
    parent.actionManager = new BABYLON.ActionManager(scene)
    parent.actionManager.registerAction(
      new BABYLON.InterpolateValueAction(
        BABYLON.ActionManager.OnPickTrigger,
        parent,
        'scaling',
        new BABYLON.Vector3(0, 0, 0),
        500
      )
    )

    return parent
  }

  load = async (scene) => {
    this.boltPromises = []
    if (this.params.bolts) {
      this.boltPromises = this.params.bolts.map((bolt, i) => {
        return this.loadBolt('bolt' + i, bolt.position, bolt.rotation, scene)
      })
    }
    this.bolts = await Promise.all(this.boltPromises)
  }

  unload = async () => {
    if (this.bolts) {
      await Promise.all(this.bolts.map(disposePromise))
    }
  }

  setOptions = (params) => {
    this.params = params
  }
}

const ShowroomDefaultParams = {
  bot: [
    {
      playerPosition: [0, 1, 0], // Setup for Codex
    },
  ],
}

export class Showroom extends Entity {
  constructor() {
    super()
    this.name = 'Showroom'
    this.defaultParams = ShowroomDefaultParams
    this.lights = new SimLights()
    this.ground = new SimGround()
    this.obstacles = new SimObstacles()
  }
  load = scene => Promise.all([
    scene.autoClear = true, // Required for transparent background
    scene.autoClearDepthAndStencil = true,
    this.lights.load(scene),
    this.ground.load(scene),
    this.obstacles.load(scene),
  ])
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.ground.unload(),
      this.obstacles.unload(),
    ])
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = this.params
    this.obstacles.setOptions(this.params)
  }
}
