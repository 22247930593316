import { contestsStateInterface } from '../../contexts/ContestContext'
import { RESPONSE_STATUS } from '../fetch-responses'
import { enqueueSnack } from '../../contexts/GroupsContext'

function presentFetchContestsResponseStarted() {
  contestsStateInterface.setFetchContestsResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentFetchContestsResponseFailed(reason) {
  contestsStateInterface.setFetchContestsResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentFetchContestsResponseSucceeded() {
  contestsStateInterface.setFetchContestsResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
}

function presentPutIndyContestRegistrationResponseStarted() {
  contestsStateInterface.setPutIndyContestRegistrationResponseState({
    status: RESPONSE_STATUS.IN_PROGRESS,
  })
}

function presentPutIndyContestRegistrationResponseFailed(reason) {
  contestsStateInterface.setPutIndyContestRegistrationResponseState({
    status: RESPONSE_STATUS.FAILED,
    reason,
  })
}

function presentPutIndyContestRegistrationResponseSucceeded() {
  contestsStateInterface.setPutIndyContestRegistrationResponseState({
    status: RESPONSE_STATUS.SUCCEEDED,
  })
  enqueueSnack('Registration Successful!')
}

export {
  contestsStateInterface,
  presentFetchContestsResponseStarted,
  presentFetchContestsResponseFailed,
  presentFetchContestsResponseSucceeded,
  presentPutIndyContestRegistrationResponseStarted,
  presentPutIndyContestRegistrationResponseFailed,
  presentPutIndyContestRegistrationResponseSucceeded,
}