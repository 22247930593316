import { identifyRollbarUser, clearRollbarUser } from './rollbar'
import { identifySmartlookUser, clearSmartlookUser } from './smartlook'
import { identifyLoggerUser, clearLoggerUser } from './logger'

const identifyUser = (user) => {
  if (!user) {
    clearSmartlookUser()
    clearRollbarUser()
    clearLoggerUser()
    return
  }

  const { id, username, email } = user
  identifySmartlookUser({ id, name: username, email })
  identifyRollbarUser({ id, username, email })
  identifyLoggerUser({ id, username, email })
}

export default identifyUser