import React from 'react'
import { Typography, Dialog, CircularProgress, DialogContent, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useOnlineStatus } from './contexts/OnlineStatusContext'

const useStyles = makeStyles((theme) => {
  return {
    spinner: {
      color: theme.palette.type === 'dark' ? 'white' : 'black',
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems:'center',
    },
    textContainer: {
      padding: 10,
      paddingBottom: 15,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems:'center',
    },
    spinnerContainer: {
      display: 'flex',
      alignItems:'center',
      justifyContent: 'center',
      gap: 10,
      padding: 20,
    },
  }
})

function NoInternetWarningDialog() {
  const classes = useStyles()
  const [{ isOnline }] = useOnlineStatus()

  return (
    <Dialog
      open={!isOnline}
    >
      <>
        <DialogContent  className={classes.dialogContent}>
          <div className={classes.textContainer}>
            <Typography variant='h5' style={{paddingBottom: 5}}>{'We\'ve lost our connection to you.'}</Typography>
            <Typography variant='subtitle1'>{'To prevent issues, we\'ve disabled the site until we reconnect.'}</Typography>
          </div>
          <Divider style={{width: '100%'}} />
          <div className={classes.spinnerContainer}>
            <Typography variant='h6'>{'Reconnecting...'}</Typography>
            <CircularProgress size={26} className={classes.spinner} />
          </div>
        </DialogContent>
      </>
    </Dialog>
  )
}

export { NoInternetWarningDialog }