import { loginDispatch, LoginActions } from '../../contexts/LoginContext'
import { onlineStatusDispatch, OnlineStatusActions } from '../../contexts/OnlineStatusContext'

class UserSessionPresenter {
  displayOnlineStatus(isOnline) {
    onlineStatusDispatch({ type: OnlineStatusActions.UPDATE_STATUS, status: isOnline })
  }

  displayUserLogoutMessage(message) {
    loginDispatch({ type: LoginActions.SET_DISPLAY_USER_LOGGED_OUT_MESSAGE, displayUserLoggedOutMessage: message })
  }
}

const userSessionPresenter = new UserSessionPresenter()
export { userSessionPresenter }