import { isMissionManuallyLocked } from './isMissionManuallyLocked'
import { getActiveMissionPack } from './getActiveMissionPack'
import { getActiveGroup } from '../../groups/group-use-cases/getActiveGroup'

// Checks if the mission is unlocked by a group or a pack.
function isMissionOrganizationallyUnlocked(missionId) {
  if (isMissionManuallyLocked(missionId)) {
    return false
  }

  const activeMissionPack = getActiveMissionPack()
  if (activeMissionPack?.unlocked) {
    return true
  }

  const activeGroup = getActiveGroup()

  if (activeGroup?.unlockedLessonItemIds) {
    return activeGroup.unlockedLessonItemIds.includes(missionId)
  }
}

export { isMissionOrganizationallyUnlocked }