// Camera Help
import React, { useState } from 'react'
import { PositionedPanel } from './PositionedPanels'
import Markdown from './FiriaMarkdown'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { useScene, SceneActions } from './contexts/SceneContext'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { cameraList } from './entities/Cameras'
import hexToRGBA from './hexToRGBA'

const cameraHelpMDs = {
  'Rotate': (
    <Markdown>
      {`The camera rotates around the map
#### <i class="material-icons">mouse</i> Mouse *(Click and drag)*
**Left Button**
* **Rotate** the camera view  
    
**Right Button**  
* **Slide** the camera *Up/Down* and *Left/Right*  

**Scroll Wheel**  
* **Zoom** *in* and *out*  

#### <i class="material-icons">keyboard</i> Keyboard *(Click in 3D window first)*  
**Arrow Keys**  
* **Rotate** the view  

**CTRL-arrows**  
* **Slide** *Up/Down* and *Left/Right*  

**ALT-arrows**  
* **Zoom** *in* and *out* (only Up/Down arrows)  

#### <i class="material-icons">pan_tool_alt</i> Trackpad
**Press and Drag**
* **Rotate** the camera view

**Two Finger Press and Drag**
* **Slide** the camera *Up/Down* and *Left/Right*

**Pinch**
* **Zoom** *in* and *out*
`}
    </Markdown>
  ),
  'Chase': (
    <Markdown>
      {`The camera rotates around the robot
#### <i class="material-icons">mouse</i> Mouse *(Click and drag)*
**Left and Right Button**
* **Rotate** the camera view  

**Scroll Wheel**  
* **Zoom** *in* and *out*  

#### <i class="material-icons">keyboard</i> Keyboard *(Click in 3D window first)*  
**Arrow Keys**  
* **Rotate** the view  

**ALT-arrows**  
* **Zoom** *in* and *out* (only Up/Down arrows)  

#### <i class="material-icons">pan_tool_alt</i> Trackpad
**One or Two Finger Press and Drag**
* **Rotate** the camera view

**Pinch**
* **Zoom** *in* and *out*
`}
    </Markdown>
  ),
  'Universal': (
    <Markdown>
      {`The camera moves around the map
#### <i class="material-icons">mouse</i> Mouse *(Click and drag)*
**Left and Right Button**
* **Look** around with the camera  

**Scroll Wheel**  
* **Zoom** *in* and *out*  

#### <i class="material-icons">keyboard</i> Keyboard *(Click in 3D window first)*  
**Arrow Keys**  
* **Move** the camera *Forwards/Backwards* and *Left/Right*  

**"WASD" Keys**
* **W** *Move Forward*
* **A** *Move Left*
* **S** *Move Backward*
* **D** *Move Right*
* **E** *Move Up*
* **Q** *Move Down*

#### <i class="material-icons">pan_tool_alt</i> Trackpad
**One or Two Finger Press and Drag**
* **Look** around with the camera 

**Pinch**
* **Zoom** *in* and *out*
`}
    </Markdown>
  ),
  'Attached': (
    <Markdown>
      {'The camera is attached to the robot and cannot be moved'}
    </Markdown>
  ),
}

const useStyles = makeStyles(theme => ({
  closeIcon: {
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  transparent: {
    backgroundColor: hexToRGBA(theme.palette.background.paper, 0.6), // add a little transparency
  },
}))

export function CameraHelp(props) {
  const classes = useStyles()

  const [sceneState, dispatch] = useScene()
  const [expanded, setExpanded] = useState(-1)
  const [oldCamIndex, setOldCamIndex] = useState(-1)

  function checkIfExpanded(index) {
    if (oldCamIndex === sceneState.camIndex) {
      if (index === expanded) {
        return true
      } else {
        if (expanded !== -1) {
          return false
        } else {
          setExpanded(sceneState.camIndex)
          if (index === expanded) {
            return true
          } else {
            return false
          }
        }
      }
    } else {
      setOldCamIndex(sceneState.camIndex)
      setExpanded(sceneState.camIndex)
      if (index === expanded) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <PositionedPanel
      style={{ left: 50, bottom: 50, zIndex: 10, maxHeight: '50%', maxWidth: '50%', display: 'flex', flexDirection: 'column' }}
      direction={'up'}
      // onClick={props.onClose}
      open={sceneState.showCameraHelp}
      onBackdropClick={() => {
        dispatch({ type: SceneActions.CAMERA_HELP, shouldShow: false })
      }}
      {...props}
    ><div style={{ display: 'flex', flexDirection: 'column', minHeight: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h2>Camera Controls</h2>
          <CloseIcon className={classes.closeIcon} onClick={() => {
            dispatch({ type: SceneActions.CAMERA_HELP, shouldShow: false })
          }} />
        </div>
        <div style={{ overflow: 'auto', marginTop: 0, paddingRight: '1em' }}>
          <Markdown>{`The *camera* is your view into the 3D Simulation Window.  
A <i class="material-icons">mouse</i> mouse is recommended for the best experience.
`}</Markdown>
          {cameraList.map((cam, index) => (
            <Accordion className={classes.transparent} key={index} id={'panel' + index} expanded={checkIfExpanded(index)}
              TransitionProps={{
                onEntered: () => {
                  setExpanded(index)
                  document.getElementById('panel' + index).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
                },
                timeout: 150,
              }}>
              <AccordionSummary onClick={() => index === expanded ? setExpanded(-2) : setExpanded(index)} expandIcon={<ExpandMoreIcon />}>
                <h3 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>{cam.name}</h3>
              </AccordionSummary>
              <AccordionDetails style={{ top: '-24px', position: 'relative' }}>
                {cameraHelpMDs[cam.name]}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </PositionedPanel>
  )
}
