import { readMissionPackStoreByPackId, readMissionStoreByMissionId, readFlowStoreByFlowId } from '../../lesson-content/lesson-content-store'

function getLessonContentItemByScoreId(scoreId) {
  const pack = readMissionPackStoreByPackId(scoreId)
  if (!!pack) {
    return { type: 'pack', pack }
  }

  const mission = readMissionStoreByMissionId(scoreId)
  if (!!mission) {
    return { type: 'mission', mission }
  }

  const flowItem = readFlowStoreByFlowId(scoreId)
  if (!!flowItem) {
    return { type: 'flowItem', flowItem }
  }
}

export { getLessonContentItemByScoreId }