// CRC functions

// Function to generate the CRC32 lookup table
function makeCRCTable() {
  let c
  let crcTable = []
  for (let n = 0; n < 256; n++) {
    c = n
    for (let k = 0; k < 8; k++) {
      c = (c & 1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1)
    }
    crcTable[n] = c >>> 0
  }
  return crcTable
}

// Precompute the CRC32 table
const crcTable = makeCRCTable()

// CRC32 calculation function to match ESP32 crc32_le(0, buf)
export function crc32_le(buffer, crc = 0xFFFFFFFF) {
  crc = crc >>> 0
  for (let i = 0; i < buffer.length; i++) {
    const byte = buffer[i]
    crc = (crc >>> 8) ^ crcTable[(crc ^ byte) & 0xFF]
  }
  return (crc ^ 0xFFFFFFFF) >>> 0
}