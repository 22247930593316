import PeripheralBase from './PeripheralBase'
import * as BABYLON from '@babylonjs/core'
import { loadGLB, disposePromise } from '../../utils/BabylonUtils'
import WireModel from '../../assets/Wire1.glb'

class JumperWire extends PeripheralBase {
  // A single wire of your own color and positioning
  constructor(color, pos, rot) {
    super()
    this.color = BABYLON.Color3.FromInts(...color)  // 'color' is [R, G, B] 0-255
    this.pos = new BABYLON.Vector3(...pos)
    this.rot = new BABYLON.Vector3(...rot.map(x => x * Math.PI / 180))

    this.wireMaterial = new BABYLON.StandardMaterial('WireMaterial')
    this.wireMaterial.diffuseColor = this.color
    this.wireMaterial.ambientColor = this.color
  }

  load = async (scene, parent, posVec3, rotVec3) => {
    if (posVec3) {
      this.pos.addInPlace(posVec3)
    }
    if (rotVec3) {
      this.rot.addInPlace(rotVec3)
    }
    this.mesh = await loadGLB(WireModel, 'Wire', 1.0, this.pos, this.rot, scene)
    this.mesh.parent = parent

    // Set color
    this.mesh.getChildMeshes(false, (m) => {
      if (m.name === 'Wire') {
        m.material = this.wireMaterial
      }
    })
  }

  unload = async () => {
    await disposePromise(this.mesh)
  }
}

export class JumperWireSet4 extends PeripheralBase {
  // A set of 4 wires plugged into typical breadboard locations
  constructor() {
    super()

    this.wires = [
      {
        color: BABYLON.Color3.Red(),
        pos: [-0.03, 0.03, 0.24],
        rot: [-10, -58, 0],
      },
      {
        color: BABYLON.Color3.Green(),
        pos: [-0.06, 0, 0.25],
        rot: [0, -68, 0],
      },
      {
        color: BABYLON.Color3.Blue(),
        pos: [0.03, 0.03, 0.23],
        rot: [10, 181, 0],
      },
      {
        color: BABYLON.Color3.Yellow(),
        pos: [0.01, 0.03, 0.21],
        rot: [10, 170, 0],
      },
    ]
  }

  load = async (scene, parent, posVec3, rotVec3) => {
    const mesh = await loadGLB(WireModel, 'Wire')
    mesh.parent = parent
    this.wireMeshes = [mesh]

    // Set color and position of each wire in our list
    for (let i = 0; i < this.wires.length; ++i) {
      if (i !== 0) {
        // Clone the loaded GLB mesh. This is heavier weight but a little simpler than instancing,
        // since we want to change the material color.
        this.wireMeshes.push(mesh.clone(`Wire${i}`, parent, false))
      }
      const w = this.wireMeshes[i]
      const mat = new BABYLON.StandardMaterial('WireMaterial')
      mat.diffuseColor = this.wires[i].color
      mat.ambientColor = this.wires[i].color
      const pos = new BABYLON.Vector3(...this.wires[i].pos)
      const rot = new BABYLON.Vector3(...this.wires[i].rot.map(x => x * Math.PI / 180))
      posVec3 && pos.addInPlace(posVec3)
      rotVec3 && rot.addInPlace(rotVec3)
      w.position = pos
      w.rotation = rot

      // Set color
      w.getChildMeshes(false, (m) => {
        if (m.name.endsWith('Wire')) {   // Note: clone() adds prefix to each mesh name, so it will be '...__root__.Wire'
          m.material = mat
        }
      })
    }
  }

  unload = async () => {
    await Promise.all(this.wireMeshes.map(disposePromise))
  }
}

export default JumperWire
