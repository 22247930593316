import { postJoinCodeToCloud } from '../group-gateways'
import { fetchGroups } from './fetchGroups'
import { takeSeat } from './takeSeat'
import { presentJoinGroupResponseStarted, presentJoinGroupResponseFailed, presentJoinGroupResponseSucceeded } from '../group-presenter'
import { fetchContests } from '../../contests/contest-use-cases/fetchContests'

async function joinGroupWithJoinCode(joinCode) {
  presentJoinGroupResponseStarted()
  const {error, groupId} = await postJoinCodeToCloud(joinCode)
  if (error) {
    presentJoinGroupResponseFailed(error)
    return error
  }

  fetchContests()
  await fetchGroups()
  if (groupId) {
    await takeSeat(groupId)
  }

  presentJoinGroupResponseSucceeded()
}

export { joinGroupWithJoinCode }