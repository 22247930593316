import postProcessCodeTrekHTML from './postProcessCodeTrekHTML'

const findSubstringIndexes = (string, sub) => {
  return [...string.matchAll(new RegExp(sub, 'gi'))].map(a => a.index)
}

const idxFind = (str, startsWith, endsWith, startIdx=null) => {
  const strArr = str.split('')
  var index
  if (startIdx){
    index = startIdx
  } else {
    const indexArr = findSubstringIndexes(str, startsWith)
    if (indexArr.length === 0){
      return
    }
    index = indexArr[0]
  }

  var tagStartIdx = index

  while ((strArr[tagStartIdx] !== startsWith.split('')[0])){
    tagStartIdx -= 1
  }

  var iterator = tagStartIdx + startsWith.length

  var tag = ''
  while (strArr[iterator] !== endsWith){
    tag += strArr[iterator]
    iterator += 1
  }

  tag += endsWith
  tag = startsWith + tag

  const tagEndIdx = iterator + 1

  return {start: tagStartIdx, end: tagEndIdx, tag: tag}
}

const postProcessHTML = async ({ html, imagesPath, onFinish }) => {
  var postHTML = [...html]
  const postList = []
  var imageIndexes = findSubstringIndexes(html, '<img')

  const pushSplice = (data) => {
    postList.push(data)
  }

  const findTagEndIdx = ({ string, idx, tag }) => {
    return string.slice(idx).split(tag)[0].length + tag.length + idx
  }

  postProcessCodeTrekHTML({ html: html, findSubstringIndexes, idxFind, pushSplice })


  // VIDEO TAG SCRUBBER
  const videoIndexes = findSubstringIndexes(html, '<video')
  if (videoIndexes.length > 0) {
    videoIndexes.forEach((idx) => {
      const videoTagEnd = findTagEndIdx({ string: html, idx, tag: '</video>' })
      postList.push([idx, videoTagEnd - idx, ''])
    })
  }

  imageIndexes.forEach((index) => {
    const img = idxFind(html, '<', '>', index)
    let imgTag = img.tag

    const src = idxFind(imgTag, 'src="', '"')
    var slashSplit = src.tag.split('/')
    if (slashSplit.length < 2) {
      slashSplit = src.tag.split('\\')
    }
    const imgName = slashSplit[slashSplit.length-1].slice(0, slashSplit[slashSplit.length-1].length-1)
    imgTag = imgTag.replace(src.tag, `src="${imagesPath}/${imgName}"`)

    postList.push([img.start, img.end-img.start, `\r\n${imgTag}\r\n`])
  })

  postList.reverse()
  postList.forEach(el => postHTML.splice(...el))

  onFinish(postHTML.join(''))
}

export default postProcessHTML