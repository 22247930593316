import { readContentSequence, readContentSequenceIndex } from '../content-director-store'

function getActiveContentSequenceItem() {
  const contentSequenceIndex = readContentSequenceIndex() ?? 0
  if (contentSequenceIndex === -1) {
    return
  }

  const contentSequence = readContentSequence()
  if (!contentSequence) {
    return
  }

  return contentSequence[contentSequenceIndex]
}

export { getActiveContentSequenceItem }