import * as BABYLON from '@babylonjs/core'
import { Entity } from '../SimScene'
import '@babylonjs/loaders'
import CodeX_body from '../assets/CodeXBody.glb'
import { disposePromise } from '../utils/BabylonUtils'

// LED dimensions
const LED_WIDTH = 2
const LED_LENGTH = 1.4
const LED_HEIGHT = 1

class CodeXModel extends Entity {
  constructor(player_num) {
    super()

    this.impostersVisible = false
    this.doPhysics = true

    this.deviceType = 'CodeX'
    this.player_num = player_num
    this.uniqueId = `${this.deviceType}_${this.player_num}`

    this.loadedObservable = new Set()   // add/delete callbacks here: observer(isLoaded)

    this.BODY_MASS = 0
    this.SKID_FRICTION = 0.01

    // Center of Gravity
    // Relative offset of all rigid bodies from the center of cbBody, which we historically defined as the center of the PCB.
    this.cog = new BABYLON.Vector3(0, 0, 0)  // Move COG a little forward from center of PCB.

    this.cxBody = null

    this.initialPosition = new BABYLON.Vector3(0, 0, 0)
    this.initialRotation = Math.PI / 2

    this.shadowCasters = []
    this.shadowModelChanged = false

    // Peripherals
    this.peripherals = []

    this.isLoaded = false
  }

  notifyLoadedObservers = (scene) => {
    // Notify Observers when model is loaded / unloaded
    this.loadedObservable.forEach(observer => observer(scene))
  }

  getRootMesh = () => {
    return this.cxBody
  }

  getShadowCasters = () => {
    return this.shadowCasters
  }

  hasShadowModelChanged = () => {
    const hasChanged = this.shadowModelChanged
    this.shadowModelChanged = false
    return hasChanged
  }

  unload = async () => {
    const disposeList = []

    // Remove from scene and cleanup memory
    if (this.cxBody) {
      disposeList.push(this.cxBody)
    }

    this.cxBody = null
    this.initialPosition = new BABYLON.Vector3(0, 1, 0)

    // Dispose of sounds
    if (this.speakerOscillator) {
      this.speakerOscillator = null
    }
    if (this.speakerSound) {
      this.speakerSound.stop()
      disposeList.push(this.speakerSound)
      // this.speakerSound.dispose()
      this.speakerSound = null
    }
    this.speakerIsPlaying = false

    const periphUnloads = this.peripherals.map(p => p.unload())

    this.shadowCasters = []
    this.shadowModelChanged = false

    await Promise.all([...disposeList.map(disposePromise), ...periphUnloads])

    this.isLoaded = false
    this.notifyLoadedObservers(false)
  }

  load = async (scene) => {
    // Create a new mesh to be our root for the physics-enabled "compound" object
    this.cxBody = new BABYLON.Mesh('CodeX', scene)
    this.cxBody.position.addInPlace(this.initialPosition)  // Offset by environment-specified position
    this.cxBody.rotation.x = -Math.PI / 2  // TODO figure this out
    this.cxBody.rotation.y = this.initialRotation
    this.cxBody.scaling = new BABYLON.Vector3(.01, .01, .01)


    // Note:
    //   We are constructing a compound physics object with the root (empty) mesh being cxBody.
    //   All the other rigid bodies (shaped impostors) are parented to this mesh, and the root is the only
    //   impostor (type "NoImpostor") whose mass and friction have effect.
    //   By consequence, the center of gravity (COG) of the compound CodeX is determined solely by the position
    //   of this invisible root mesh (cxBody) _relative_ to the parented rigid bodies.
    //   To clarify, the cxBody.position does not matter. We are only adjusting it above to allow environment params to place
    //   the compound CodeX object independent of COG location, referencing the historical position at center of PCB.

    // Create Physics impostors
    const promises = []
    let cxPcb
    // let cbBatt  // TODO
    promises.push(new Promise((resolve) => {
      cxPcb = BABYLON.MeshBuilder.CreateBox('cxPcb', { width: this.CB_WIDTH_MM / 100, height: this.CB_HEIGHT_MM / 100, depth: this.CB_LENGTH_MM / 100 }, scene)
      cxPcb.position.addInPlace(this.cog)
      cxPcb.parent = this.cxBody
      cxPcb.isVisible = this.impostersVisible

      // TODO
      // cbBatt = BABYLON.MeshBuilder.CreateBox('cbBattPack', { width: this.BATT_WIDTH_MM / 100, height: this.BATT_HEIGHT_MM / 100, depth: this.BATT_LENGTH_MM / 100 }, scene)
      // cbBatt.parent = this.cxBody
      // cbBatt.position.y = (-this.BATT_HEIGHT_MM / 2 - this.CB_HEIGHT_MM / 2) / 100
      // cbBatt.position.z = -(this.CB_LENGTH_MM / 2 - this.BATT_LENGTH_MM / 2 - this.BATT_X_OFS_MM) / 100
      // cbBatt.position.addInPlace(this.cog)
      // cbBatt.isVisible = this.impostersVisible
      resolve()
    }))

    await Promise.all(promises)

    // Enable collision checks for Camera
    this.cxBody.checkCollisions = true

    // Next set of promises
    promises.length = 0

    this.peripherals = [] // TODO: Allow peripheral input from scene

    // Load GLB visual model components
    promises.push(this.loadModel(scene))

    await Promise.all(promises)

    if (this.doPhysics) {
      // cbBatt.physicsImpostor = new BABYLON.PhysicsImpostor(cbBatt, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: this.SKID_FRICTION })
      cxPcb.physicsImpostor = new BABYLON.PhysicsImpostor(cxPcb, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: this.SKID_FRICTION })

      // Must create root "compound" Impostor object LAST. (Note: it has the only friction and mass that are actually used by the Ammo.js physics engine)
      this.cxBody.physicsImpostor = new BABYLON.PhysicsImpostor(this.cxBody, BABYLON.PhysicsImpostor.NoImpostor, { mass: this.BODY_MASS, friction: this.SKID_FRICTION }, scene)
    }

    // this.loadSounds(scene)

    this.isLoaded = true
    this.notifyLoadedObservers(scene)
  }

  buildProceduralMeshes = async () => {
    // LEDs and such
    // Create LEDs

    // TODO
  }

  assetPath = asset => (
    // Extract file path/name from Webpack static media location
    [asset.substring(0, asset.lastIndexOf('/') + 1), asset.substring(asset.lastIndexOf('/') + 1)]
  )

  loadModel = async (scene) => {
    const loadBody = async () => {
      const [path, name] = this.assetPath(CodeX_body)
      const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
      const bodyMesh = meshes[0]  // root transform
      bodyMesh.rotation = new BABYLON.Vector3(0, 0, 0)
      bodyMesh.parent = this.cxBody
      bodyMesh.position.addInPlace(this.cog)
      this.shadowCasters.push(bodyMesh)
      this.shadowModelChanged = true

      // Traverse all meshes in the CodeX body
      const childMeshes = bodyMesh.getChildMeshes()
      await Promise.all(childMeshes.map(mesh => new Promise((resolve) => {
        // Add tags identifiying the meshes, useful for raycast tests, collisions...
        BABYLON.Tags.AddTagsTo(mesh, this.deviceType + ' ' + this.uniqueId)

        // When pointer hovers over buttons change cursor to a hand and highlight with green glow
        if (mesh.name === 'Cylinder.004' || mesh.name === 'Cylinder.006') {  // TODO!!!
          mesh.isPickable = true
          mesh.actionManager = new BABYLON.ActionManager(scene)

          // Create an inner glow highlight layer
          const hl = new BABYLON.HighlightLayer('hl1', scene, {
            innerGlow: true,
          })

          // On pointer over mesh (mouse enter) and add green glow
          mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
            scene.hoverCursor = 'pointer'
            hl.addMesh(mesh, BABYLON.Color3.Green())
          }))

          // On pointer not over mesh (mouse exit) and remove green glow
          mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
            hl.removeMesh(mesh, BABYLON.Color3.Green())
          }))
        }
        resolve()
      })))
    }
    await Promise.all([loadBody()])
    // await this.buildProceduralMeshes()  // Currently none
  }

  createLed = (positionVec2, colorVec3, scene) => {
    const led = BABYLON.MeshBuilder.CreateBox('LED', { width: LED_WIDTH / 100, height: LED_HEIGHT / 100, depth: LED_LENGTH / 100 }, scene)
    led.material = new BABYLON.StandardMaterial('', scene)
    led.material.diffuseColor = new BABYLON.Color3(0.956, 0.921, 0.721)
    led.material.alpha = 0.8
    led.FL_onColor = colorVec3
    led.FL_offColor = new BABYLON.Color3(0, 0, 0)
    led.material.emissiveColor = led.FL_offColor
    led.material.specularColor = new BABYLON.Color3(0, 0, 0)
    led.parent = this.cxBody

    led.position.x = positionVec2.x / 100
    led.position.y = (LED_HEIGHT / 2) / 100
    led.position.z = positionVec2.y / 100
    led.position.addInPlace(this.cog)

    return led
  }

  getBodyRotation = () => {
    return this.cxBody.rotationQuaternion.toEulerAngles()
  }

  calcPercentRange = (metric, min, max) => {
    return ((max - min) * metric / 100 + min) / 100
  }
}

export { CodeXModel }