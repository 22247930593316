/* CodeAIRModelController: In main thread, uses shared memory to let a worker thread interface to a CodeBot model.
   * The worker is started/terminated for each run.
*/

/* eslint-disable no-undef */
import CodeAIRHWschema from './CodeAIRHWschema'
// import { getPickedColor, PerformanceTicker, radAdd, radDiff } from '../utils/BabylonUtils'
import { PerformanceTicker } from '../utils/BabylonUtils'
import { UartCmd, UARTBuffer } from '../Busio'
import { validatorController } from '../ValidatorControl'

export default class CodeAIRModelController {
  // Control a CodeAIRModel via SharedArrayBuffer representing HW state
  // An instance of this class persists while the CodeBot model exists.
  // With just a single 'bot in the first version of botsim, there's a singleton instance of this.
  constructor(model) {
    this.model = model
    this.scene = null
    this.createSharedArrayBuffer()
    this.userCodeIsRunning = false
    this.modelEventObservable = new Set()   // add/delete callbacks here: observer(changes, current)
    this.modelTrackChanges = new Map()      // collects changed values each tick, used to notify observers and update cached values.
    this.modelCollisionObservable = new Set()
    this.modelReady = false

    this.model.loadedObservable.add(this.modelLoadedObserver)

    this.ctrKeyDown = false

    this.lastAudioUpdateMs = 0

    this.resetCachedValues()
    this.perf = new PerformanceTicker()
    this.uartBuffers = {}
  }

  // At program startup we want a fresh clean sharedArry.
  // See also recreateSharedArrayBuffer()
  createSharedArrayBuffer = () => {
    this.sharedArray = new SharedArrayBuffer(1024)
    this.useSharedArrayBuffer()
  }

  // We don't want to risk our sharedArray getting written to by a PREVIOUS worker thread
  // who is somehow still running (zombie?), BUT we also need to maintain CodeAIR state
  // BETWEEN program runs. So, we switch to a COPY of the previous sharedArray
  // Assumption - That the docs are telling the truth:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer
  recreateSharedArrayBuffer = () => {
    this.sharedArray = this.sharedArray.slice()
    this.useSharedArrayBuffer()
  }

  useSharedArrayBuffer = () => {
    // initArrayView requires the sharedArray instance, meaning we need to re-init codeair schema
    this.hw = new CodeAIRHWschema(this.sharedArray)
  }

  modelLoadedObserver = (scene) => {
    if (scene) {
      this.scene = scene

      // In case model is re-loaded while code is active, invalidate cache so it gets full update of state.
      this.resetCachedValues()

      // Model is loaded and ready
      this.modelReady = true
    } else {
      // Model is unloaded from scene
      this.modelReady = false
    }
  }

  onCollide = (imposter) => {
    this.notifyCollisionObservers(imposter.object)

    // A callback can be attached to a mesh for mutual notification.
    // This allows objects to react to bot collision independent of being hooked into the controller.
    if (imposter.object.botCollision) {
      imposter.object.botCollision()
    }
  }

  uartCmd = (cmd, id, data) => {
    validatorController.handleUARTCmd(cmd, id, data)
    switch (cmd) {
      case UartCmd.INIT: // obj {tx, rx, baudrate, bits, parity, stopBits, timeout, rxBufSize}
        this.uartBuffers[id] = new UARTBuffer(data, this.model.peripherals, this.hw)
        break
      case UartCmd.DEINIT: // null
        delete this.uartBuffers[id]
        break
      default:
        this.uartBuffers[id].cmd(cmd, data)
        break
    }
  }

  // callback overridden by CodeRunner
  onReboot = () => {}

  notifyModelEventObservers = () => {
    // Observers receive change map AND 'this' prior to updating with changed values.
    this.modelEventObservable.forEach(observer => observer(this.modelTrackChanges, this))
  }

  resetCachedValues = () => {
  }

  startupActions = (resetHardware=true) => {
    // Conditionally initialize stuff when bot program starts
    // Turn off LEDs, motors, speaker
    if (resetHardware) {
      // Atomics.store(this.hw.speaker, 0, 0)
      // Atomics.store(this.hw.speaker, 1, 0)
    }
    this.userCodeIsRunning = true
    this.resetCachedValues()
  }

  terminalActions = () => {
    // Shut down stuff when bot program terminates (motors, speaker)
    this.userCodeIsRunning = false
  }

  updatePhysicsToWorker = async () => {
  }

  updateFromWorker = () => {
    // Update cxModel in main thread with any changes made by worker thread to CodebotHWschema

    if (!this.userCodeIsRunning || !this.model.isLoaded || !this.model.doPhysics) {
      return
    }

    const changes = this.modelTrackChanges
    changes.clear()

    // Track accumulated changes
    if (changes.size > 0) {
      this.notifyModelEventObservers()
      const changeObj = Object.fromEntries(changes)
      Object.assign(this, changeObj)
    }
  }
}