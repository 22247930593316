import authFetchHandler from '../../tools/auth/authFetchHandler'
import { presentAuthorizationRequired } from './group-presenter'
import { groupIsDefault } from './group-use-cases/groupIsDefault'
import { isFetchResponseLMSAuthorizationError } from '../user-session/user-session-use-cases/isFetchResponseLMSAuthorizationError'
import { readGroupFromStore } from './group-store'

// eslint-disable-next-line no-unused-vars
function formatGroupEventsByDueDate(events) {
  const db = {}
  for (const i in events) {
    const event = events[i]
    if (event.source === 'assignment') {
      continue
    }

    if ('dueDateTs' in event) {
      event.ts = event.dueDateTs
      event.eventType = 'assignment'
    } else {
      event.ts = event.lockedUntilTs
      event.eventType = 'progressionGate'
    }

    const dueDate = event.dueDateTs ?? event.lockedUntilTs

    if (!(dueDate in db)) {
      db[dueDate] = []
    }
    db[dueDate].push(event)
  }

  const orderedGroupEventsByTimestamp = Object.entries(db).sort(([dueDateA], [dueDateB]) => {
    return parseInt(dueDateA) - parseInt(dueDateB)
  })

  const orderedGroupEvents = []
  orderedGroupEventsByTimestamp.forEach(([_, events]) =>
    orderedGroupEvents.push(...events)
  )

  return orderedGroupEvents
}

function deriveRGBColorCodeArrayFromGroupId(groupId) {
  const ceilCode = (codeItem) => {
    return (parseInt(codeItem) / 999 * 155) + 100
  }
  let groupIdInts = groupId.replace(/\D/g, '')
  if (groupIdInts.length < 9) {
    groupIdInts += '0' * 9 - groupIdInts.length
  }

  return [ceilCode(groupIdInts.slice(0, 3)), ceilCode(groupIdInts.slice(3, 6)), ceilCode(groupIdInts.slice(6, 9))]
}

function formatColorCodeArrayByGroupId(groups) {
  const colorCodeArrayByGroupId = {}
  groups.forEach(({ groupId }) => {
    colorCodeArrayByGroupId[groupId] = deriveRGBColorCodeArrayFromGroupId(groupId)
  })
  return colorCodeArrayByGroupId
}

function formatGroupsFromCloud(groupsData) {
  const { groups, lastActiveGroup } = groupsData

  // const assignments = unformattedGroups.map((group) => {
  //   const { id: groupId, assignments } = group
  //   return assignments.map((assignment) => {
  //     assignment.groupId = groupId
  //     return assignment
  //   })
  // }).flat()
  // const progressionGates = unformattedGroups.map((group) => {
  //   const { id: groupId, progressionGates } = group
  //   return progressionGates.map((progressionGate) => {
  //     progressionGate.groupId = groupId
  //     return progressionGate
  //   })
  // }).flat()
  // const groups = unformattedGroups.map((group) => {
  //   group.assignmentIds = group.assignments.map(assignment => assignment.id)
  //   group.progressionGateIds = group.progressionGates.map(progressionGate => progressionGate.id)
  //   return group
  // })

  // const groupEventsByDueDate = formatGroupEventsByDueDate([
  //   ...assignments,
  //   ...progressionGates,
  // ]
  // )

  const colorCodeArrayByGroupId = formatColorCodeArrayByGroupId(groups)
  return {
    groups,
    // assignments,
    // progressionGates,
    lastActiveGroup,
    // groupEventsByDueDate,
    colorCodeArrayByGroupId,
  }
}

async function fetchGroupsFromCloud() {
  try {
    const resp = await authFetchHandler('groups',
      {
        method: 'GET',
      })

    if (resp.status === 200) {
      const json = await resp.json()
      return formatGroupsFromCloud(json)
    }
  } catch (err){
    //
  }
}

function formatAssignmentsFromCloud(assignments) {
  assignments.forEach((assignment) => {
    assignment.submissionsRemaining = assignment.attemptsAllowed > 0 && assignment.totalSubmissions < assignment.attemptsAllowed
    assignment.submissionsMade = `${assignment.totalSubmissions}/${assignment.attemptsAllowed}` ?? '∞'
  })
  return assignments
}

async function fetchAssignmentsFromCloud(groupId) {
  for (const i in [1, 2, 3]) {
    try {
      const resp = await authFetchHandler(`groups/${groupId}/assignments`,
        {
          method: 'GET',
        })

      const json = await resp.json()
      if (await isFetchResponseLMSAuthorizationError(resp)) {
        presentAuthorizationRequired(groupId)
        return
      }

      if (resp.status === 200) {
        return formatAssignmentsFromCloud(json)
      }
    } catch (err){
      //
    }
    console.log(`Fetching assignments attempt ${i} failed.`)
  }
}

async function fetchProgressionGatesFromCloud() {
  try {
    const resp = await authFetchHandler('progression-gates',
      {
        method: 'GET',
      })

    if (resp.status === 200) {
      return await resp.json()
    }
  } catch (err){
  //
  }
}

async function postSeatInCloud(groupId) {
  try {
    const respObj = { authorize: false, error: null, assignments: [] }
    const resp = await authFetchHandler(groupIsDefault(groupId) ? `groups/${groupId}/freeSeat` : `groups/${groupId}/seat`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          groupIsDefault: groupIsDefault(groupId),
          packId: readGroupFromStore(groupId)?.missionPackId,
        }),
      }
    )

    if (await isFetchResponseLMSAuthorizationError(resp)) {
      presentAuthorizationRequired(groupId, true)
      respObj.authorize = true
      return respObj
    }

    if (resp.status === 200) {
      respObj.assignments = await resp.json()
      return respObj
    }

    respObj.error = (await resp.json()).message
    return respObj
  } catch (err){
    console.log(err)
    return
  //
  }
}

async function postAssignmentSubmission(groupId, assignmentId, fileId, validated, code, validationAuthenticated, lastRuntimeErrorMessage) {
  let respObj = { error: true }
  try {
    const resp = await authFetchHandler(`groups/${groupId}/assignments/${assignmentId}/submissions`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ submittedFileId: fileId, validated, code, validationAuthenticated, lastRuntimeErrorMessage }),
      }
    )

    if (await isFetchResponseLMSAuthorizationError(resp)) {
      presentAuthorizationRequired(groupId)
    } else if (resp.status === 200) {
      respObj.error = false
    } else {
      respObj.error = (await resp.json())?.message
    }
    console.log('Post assignment returned error object: ', respObj.error)
  } catch (err){
    console.log(err)
  //
  }
  return respObj
}

async function postJoinCodeToCloud(joinCode) {
  let respObj = { error: true, groupId: null }
  try {
    const resp = await authFetchHandler('groups/members',
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ joinCode }),
      })

    if (resp.status === 200) {
      respObj.groupId = (await resp.json())?.groupId
      respObj.error = false
    } else {
      respObj.error = (await resp.json())?.message
    }
  } catch (err){
    respObj.error = err
  }

  return respObj
}

async function deleteGroupMemberFromCloud(groupId, memberId) {
  try {
    const resp = await authFetchHandler(`groups/${groupId}/members/${memberId}`,
      {
        method: 'DELETE',
      }
    )
    if (resp.status !== 200) {
      return (await resp.json())?.message
    }
  } catch (err){
    return err
  }
}

async function getAuthorizationStatusFromCloud(lmsId) {
  try {
    const resp = await authFetchHandler(`lms/${lmsId}/authorization/3-leg/status?role=student`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return await resp.json()
  } catch (err){
    console.log(err)
  //
  }
}

async function getAuthorizationURLFromCloud(lmsId) {
  try {
    const resp = await authFetchHandler(`lms/${lmsId}/authorization/3-leg?role=student`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const data = await resp.text()
    if (data && resp.status === 200) {
      return data
    }
  } catch (err){
    console.log(err)
  //
  }
}

async function getFloatingLicenseSeatsFromCloud() {
  try {
    const resp = await authFetchHandler('groups/floating-license-seat-counts',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const data = await resp.json()
    if (resp.status === 200) {
      return { data }
    } else {
      return { data: null, error: data }
    }
  } catch (err){
    console.log(err)
    return { data: null, error: err }
  }
}

export {
  fetchGroupsFromCloud,
  fetchProgressionGatesFromCloud,
  postSeatInCloud,
  postAssignmentSubmission,
  fetchAssignmentsFromCloud,
  formatAssignmentsFromCloud,
  postJoinCodeToCloud,
  deleteGroupMemberFromCloud,
  getAuthorizationStatusFromCloud,
  getAuthorizationURLFromCloud,
  getFloatingLicenseSeatsFromCloud,
}
