import React from 'react'
import { useMission, MissionActions, LessonContentViews } from './MissionContext'
import { missionEditorController } from '../MissionEditorControl'
import { validatorController } from '../ValidatorControl'
import { useDebugger, DebuggerActions } from './DebuggerContext'
import { TargetStates, TargetModes, DebugCommands } from '../TargetInterface'
import { environmentList } from '../entities/Environments'
import { Targets } from '../Players'
import { syncMissionEditor } from '../content-manager/content-director/content-director-use-cases/syncMissionEditor'
import {
  runValidatorRegressionTestByFlowItemWithDebounce,
  deleteValidatorRegressionTestByFlowItem,
} from '../content-manager/lesson-editor/lesson-editor-controllers'

export const MissionEditorActions = Object.freeze({
  CHANGE_ANSWER_CORRECTNESS: Symbol('change answer correctness'),
  CHANGE_TOOL: Symbol('change the tool selected'),
  DELETE_TAB: Symbol('delete a tab'),
  ADD_TAB: Symbol('add a tab'),
  SELECT_TAB: Symbol('select a tab'),
  CHANGE_STEP_MARKER: Symbol('update marker for walkthrough step'),
  UPDATE_STEP_HEIGHT: Symbol('update the pixel height for walkthrough step'),
  SET_VALIDATOR_TYPE: Symbol('update the validator type for a goal'),
  UPDATE_VALIDATOR: Symbol('update the validator text for a goal'),
  SET_VALIDATOR_OTHER: Symbol('a second text field is required for some validators'),
  UPDATE_CODE: Symbol('update code on current tab'),
  IMPORT_MSN_JSON: Symbol('import a JSON objective file to browser'),
  CHANGE_EDITOR: Symbol('change editor'),
  SET_MANIFEST_VAL: Symbol('give a key and a value to set a manifest value'),
  SYNC_MSN: Symbol('synchronize the mission with the editor'),
  JSON_LOADED: Symbol('a json was loaded and we are going to switch tools'),
  ADD_FLOW_TITLE: Symbol('add a flow object title for viewing'),
})

const MissionEditorContext = React.createContext()

export const optionalFields = {
  'pack': [],
  'mission': ['remix'],
  'quiz': [],
  'question': [],
  'objective': ['sceneParams', 'solution', 'hints'],
}

export const genDefault = {
  'pack': () => {
    return {
      id: 'default',
      title: 'Default Pack',
      brief: '# Brief Markdown Here',
      overview: '# Long Markdown Here',
      complete: '# Outbrief Markdown Here',
      avatar: 'codebot_head_sm.png',
      missions: [
        // {id: 'default'},
      ],
    }
  },
  'mission' : () => {
    return {
      id: 'default',
      title: 'Default Mission',
      brief: '# Brief Markdown Here',
      overview: '# Long Markdown Here',
      complete: '# Outbrief Markdown Here',
      avatar: 'codebot_head_sm.png',
      remix: '', // OPTIONAL markdown
      flow: [
        // { type: 'objective', id: 'default'},
        // { type: 'quiz', id: 'default'},
      ],
    }
  },
  'quiz': () => {
    return {
      id: 'default',
      title: 'Default Quiz',
      overview: '', // OPTIONAL this is an intro to the quiz
      complete: '# Complete Markdown Here',
      questions: [
        // { id: 'questionId' },
      ],
    }
  },
  'question': () => {
    return {
      id: 'default',
      xp: 5,
      type: 'mc', // multiple choice
      q: 'What is the right answer?',
      answers: [
        { key: 0, a: 'Right Answer', correct: true },
        { key: 1, a: 'Wrong Answer 1'},
        { key: 2, a: 'Wrong Answer 2'},
      ],
    }
  },
  'objective': () => {
    return {
      id: 'default',
      title: 'Default Objective',
      xp: 5,
      language: 'python',
      instructions: '# Instructions Markdown Here',
      code: '', // OPTIONAL this is code viewed with the codesteps
      solution: '', // OPTIONAL this is the final solution code
      complete: '# Way to Go Markdown Here',
      camera: 0,
      scene: 0,
      sceneParams: {}, // OPTIONAL parameters for selected scene
      files: [
        // OPTIONAL name of files to inject into user filesystem at start of objective
      ],
      hints: [
        // OPTIONAL "# Hint Markdown Here",
      ],
      codesteps: [
        // OPTIONAL { md: "# Step Markdown Here", marker: 1, pixelHeight: 400 },
      ],
      goals: [
        {md: '# Goal Markdown Here', validatorType: 'CODE_REGEX', validator: '/ /', other: ''},
      ],
      target: Targets.SIM_CB2,
    }
  },
}

var setMissionEditorValidatorTestState = () => {}
export const MissionEditorProvider = ({ children }) => {
  const [debuggerState, debuggerDispatch] = useDebugger()
  const [missionState, missionDispatch] = useMission()
  const previousFlowIndex = React.useRef(null)
  const restoreFlowTimeout = React.useRef(null)
  const isMounted = React.useRef(null)
  const [state, setState] = React.useState({
    tabs: [],
    flowTitles: {},
    tabSelected: 0,
    multipleTabs: false,
    editorSelected: 'mission',
    tabName: 'Manifest',
    toolSelected: 'Manifest',
    showCode: false,
    serverStatus: 'online',
    fileDownloadBlob: null,
    pack: genDefault.pack(),
    mission: genDefault.mission(),
    quiz: genDefault.quiz(),
    question: genDefault.question(),
    objective: genDefault.objective(),
  })
  const stateCache = React.useRef(state)
  const [validatorTestState, setValidatorTestState] = React.useState({
    status: null,
    tests: [],
    testsByFlowId: [],
  })
  setMissionEditorValidatorTestState = setValidatorTestState

  const objectiveId = state?.objective?.id
  const objectiveSolution = state?.objective?.solution
  var objectiveGoals = ''
  if (state?.objective?.goals){
    // eslint-disable-next-line no-unused-expressions
    state?.objective?.goals.forEach((goal) => {
      objectiveGoals += goal.validator
    })
  }
  React.useEffect(() => {
    if (objectiveId !== genDefault.objective()?.id) {
      runValidatorRegressionTestByFlowItemWithDebounce(state.objective)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveSolution, objectiveGoals])

  React.useEffect(() => {
    if (missionState.editorMode) {
      validatorController.errorObservers = []
      validatorController.subscribeToErrors((err) => {
        // alert('Error in validator: ' + err)
      })
    }
  })

  const missionDispatchRef = React.useRef()
  React.useEffect(() => {
    missionDispatchRef.current = missionDispatch
  }, [missionDispatch])

  React.useEffect(()=>{
    isMounted.current = true
    return ()=>{
      clearTimeout(restoreFlowTimeout)
      isMounted.current = false
    }
  }, [])

  const focusedContent = missionState.ui?.focusedContent
  const activeMissionFlowLength = missionState.activeMission?.flow?.length
  React.useEffect(() => {
    if (focusedContent === LessonContentViews.MISSION && previousFlowIndex.current) {
      if (activeMissionFlowLength && activeMissionFlowLength + 1 > previousFlowIndex.current){
        restoreFlowTimeout.current = setTimeout(async ()=>{
          if (isMounted.current){
            await missionDispatchRef.current({ type: MissionActions.SET_CURR_FLOW, index: previousFlowIndex.current })
            previousFlowIndex.current = null
          }
        }, 50)
      }
    }
  }, [focusedContent, activeMissionFlowLength])

  function saveCode(code) {
    const copyOfState = {...stateCache.current}
    copyOfState.tabs[copyOfState.tabSelected].code = code
    switch (copyOfState.toolSelected) {
      case 'Instructions':
        copyOfState.objective.instructions = code
        break
      case 'Complete':
        copyOfState[copyOfState.editorSelected].complete = code
        break
      case 'Code':
        copyOfState.objective.code = code
        break
      case 'Solution':
        copyOfState.objective.solution = code
        break
      case 'Hints':
        copyOfState.objective.hints[copyOfState.tabSelected] = code
        break
      case 'Walkthrough':
        copyOfState.objective.codesteps[copyOfState.tabSelected].md = code
        break
      case 'Goals':
        copyOfState.objective.goals[copyOfState.tabSelected].md = code
        break
      case 'Answers':
        copyOfState.question.answers[copyOfState.tabSelected].a = code
        break
      case 'Question':
        copyOfState.question.q = code
        break
      case 'Brief':
        copyOfState[copyOfState.editorSelected].brief = code
        break
      case 'Overview':
        copyOfState[copyOfState.editorSelected].overview = code
        break
      default:
        break
    }
    stateCache.current = copyOfState
    setState(copyOfState)
  }

  function toolSelected(tool) {
    const copyOfState = {...stateCache.current}
    copyOfState.tabSelected = 0
    copyOfState.toolSelected = tool
    copyOfState.tabName = tool
    copyOfState.multipleTabs = false
    copyOfState.showCode = true
    copyOfState.tabs = []

    switch (tool) {
      case 'Manifest':
        copyOfState.showCode = false
        break
      case 'Instructions':
        copyOfState.tabs = [{
          code: copyOfState.objective.instructions,
          language: 'markdown',
          title: 'Instructions',
        }]
        break
      case 'Complete':
        copyOfState.tabs = [{
          code: copyOfState[copyOfState.editorSelected].complete,
          language: 'markdown',
          title: 'Complete Message',
        }]
        break
      case 'Code':
        copyOfState.tabs = [{
          code: copyOfState.objective.code,
          language: copyOfState.objective.language,
          title: 'Code',
        }]
        break
      case 'Solution':
        copyOfState.showCode = false
        copyOfState.tabs = [{
          code: copyOfState.objective.solution,
          language: copyOfState.objective.language,
          title: 'Solution',
        }]
        break
      case 'Hints':
        copyOfState.tabName = 'Hint'
        copyOfState.multipleTabs = true
        copyOfState.tabs = copyOfState.objective.hints.map((x) => {
          return {
            code: x,
            language: 'markdown',
            title: copyOfState.tabName,
          }
        })
        break
      case 'Walkthrough':
        copyOfState.tabName = 'Step'
        copyOfState.multipleTabs = true
        copyOfState.tabs = copyOfState.objective.codesteps.map((x) => {
          return {
            code: x.md,
            language: 'markdown',
            title: copyOfState.tabName,
            walkthroughMarker: parseInt(x.marker),
          }
        })
        break
      case 'Goals':
        copyOfState.tabName = 'Goal'
        copyOfState.multipleTabs = true
        copyOfState.tabs = copyOfState.objective.goals.map((x) => {
          return {
            code: x.md,
            language: 'markdown',
            title: copyOfState.tabName,
            validator: x.validator,
            validatorType: x.validatorType,
            other: x.other,
          }
        })
        break
      case 'Answers':
        copyOfState.tabName = 'Answer'
        copyOfState.multipleTabs = true
        copyOfState.tabs = copyOfState.question.answers.map((x) => {
          const ans = {
            code: x.a,
            language: 'markdown',
            title: copyOfState.tabName,
            key: x.key,
          }
          if (x.correct) {
            ans.correct = x.correct
          }
          return ans
        })
        break
      case 'Question':
        copyOfState.tabs = [{
          code: copyOfState.question.q,
          language: 'markdown',
          title: 'Question',
        }]
        break
      case 'Brief':
        copyOfState.tabs = [{
          code: copyOfState[copyOfState.editorSelected].brief,
          language: 'markdown',
          title: 'Brief',
        }]
        break
      case 'Overview':
        copyOfState.tabs = [{
          code: copyOfState[copyOfState.editorSelected].overview,
          language: 'markdown',
          title: 'Overview',
        }]
        break
      default:
        break
    }

    if (copyOfState.tabs.length < 1) {
      copyOfState.tabSelected = null
    }

    stateCache.current = copyOfState
    setState(copyOfState)
  }

  function saveComplete(type, res) {
    // console.log(type + ' save: ' + res.msg)
  }

  function deleteComplete(type, res) {
    // console.log(type + ' delete: ' + res.msg)
  }

  function selectEditor(editor) {
    const copyOfState = {...stateCache.current}
    missionEditorController.saveJson(copyOfState[copyOfState.editorSelected], copyOfState.editorSelected, (res) => {
      saveComplete(copyOfState.editorSelected, res)
    }, true)
    copyOfState.editorSelected = editor
    copyOfState.showCode = false
    copyOfState.toolSelected = 'Manifest'
    stateCache.current = copyOfState
    setState(copyOfState)
  }

  const dispatch = async (action) => {
    const copyOfState = {...stateCache.current}
    switch (action.type) {
    // { type: ..., editorInstance: Any }
      case MissionEditorActions.CHANGE_EDITOR:
        if ((debuggerState.mode === TargetModes.RUN &&
          (debuggerState.targetState === TargetStates.RUNNING || debuggerState.targetState === TargetStates.LOADING))) {
          debuggerDispatch({ type:DebuggerActions.RUN })
        }
        if (debuggerState.mode === TargetModes.DEBUG){
          debuggerDispatch({ type: DebuggerActions.DEBUG_USER_COMMAND, debugCommand: DebugCommands.STOP  })
        }
        selectEditor(action.editorSelected)
        break
      case MissionEditorActions.CHANGE_TOOL:
        toolSelected(action.tool)
        break
      case MissionEditorActions.UPDATE_CODE:
        saveCode(action.code)
        break
      case MissionEditorActions.DELETE_TAB:
        copyOfState.tabs.splice(copyOfState.tabSelected, 1)
        if (copyOfState.toolSelected === 'Walkthrough') {
          copyOfState.objective.codesteps.splice(copyOfState.tabSelected, 1)
        } else if (copyOfState.toolSelected === 'Goals') {
          copyOfState.objective.goals.splice(copyOfState.tabSelected, 1)
          deleteValidatorRegressionTestByFlowItem(copyOfState.objective.id, copyOfState.tabSelected)
        } else if (copyOfState.toolSelected === 'Hints') {
          copyOfState.objective.hints.splice(copyOfState.tabSelected, 1)
        } else if (copyOfState.toolSelected === 'Answers') {
          copyOfState.question.answers.splice(copyOfState.tabSelected, 1)
        }
        copyOfState.tabSelected = copyOfState.tabs.length ? 0 : null
        break
      case MissionEditorActions.ADD_TAB:
        const newTab = {
          title: copyOfState.tabName,
          code: '# Your Markdown Here',
          language: 'markdown',
        }
        if (copyOfState.toolSelected === 'Walkthrough') {
          newTab.walkthroughMarker = copyOfState.objective.codesteps.length + 1
          copyOfState.objective.codesteps.push({
            md: newTab.code,
            marker: parseInt(newTab.walkthroughMarker),
            pixelHeight: 40,
          })
        } else if (copyOfState.toolSelected === 'Goals') {
          newTab.validator = '/ /'
          newTab.validatorType = 'CODE_REGEX'
          newTab.other = ''
          copyOfState.objective.goals.push({
            md: newTab.code,
            validator: newTab.validator,
            validatorType: newTab.validatorType,
            other: newTab.other,
          })
        } else if (copyOfState.toolSelected === 'Hints') {
          copyOfState.objective.hints.push(newTab.code)
        } else if (copyOfState.toolSelected === 'Answers') {
          newTab.code = 'Answer'
          newTab.key = copyOfState.tabs.length
          copyOfState.question.answers.push({
            a: newTab.code,
            key: newTab.key,
          })
        }
        copyOfState.tabs.push(newTab)
        copyOfState.tabSelected = copyOfState.tabs.length - 1
        break
      case MissionEditorActions.SELECT_TAB:
        copyOfState.tabSelected = action.idx
        break
      case MissionEditorActions.CHANGE_ANSWER_CORRECTNESS:
        if (action.correct) {
          copyOfState.tabs[copyOfState.tabSelected].correct = action.correct
          copyOfState.question.answers[copyOfState.tabSelected] = {
            ...copyOfState.question.answers[copyOfState.tabSelected],
            correct: action.correct,
          }
        } else {
          if ('correct' in copyOfState.tabs[copyOfState.tabSelected]) {
            delete copyOfState.tabs[copyOfState.tabSelected].correct
          }
          if ('correct' in copyOfState.question.answers[copyOfState.tabSelected]) {
            delete copyOfState.question.answers[copyOfState.tabSelected].correct
          }
        }
        break
      case MissionEditorActions.CHANGE_ANSWER_KEY:
        copyOfState.tabs[copyOfState.tabSelected].key = action.key
        copyOfState.question.answers[copyOfState.tabSelected] = {
          ...copyOfState.question.answers[copyOfState.tabSelected],
          key: action.key,
        }
        break
      case MissionEditorActions.CHANGE_STEP_MARKER:
        copyOfState.tabs[copyOfState.tabSelected].walkthroughMarker = parseInt(action.marker)
        copyOfState.objective.codesteps[copyOfState.tabSelected].marker = parseInt(action.marker)
        break
      case MissionEditorActions.UPDATE_STEP_HEIGHT:
        copyOfState.objective.codesteps[copyOfState.tabSelected].pixelHeight = parseInt(Math.ceil(action.value))
        break
      case MissionEditorActions.UPDATE_VALIDATOR:
        copyOfState.tabs[copyOfState.tabSelected].validator = action.value
        copyOfState.objective.goals[copyOfState.tabSelected].validator = action.value
        break
      case MissionEditorActions.SET_VALIDATOR_TYPE:
        const oldType = copyOfState.objective.goals[copyOfState.tabSelected].validatorType
        copyOfState.tabs[copyOfState.tabSelected].validatorType = action.value
        copyOfState.objective.goals[copyOfState.tabSelected].validatorType = action.value

        if (action.value === 'CODE_PARSE') {
          runValidatorRegressionTestByFlowItemWithDebounce(copyOfState.objective)
        } else if (oldType === 'CODE_PARSE') {
          deleteValidatorRegressionTestByFlowItem(copyOfState.objective.id, copyOfState.tabSelected)
        }
        break
      case MissionEditorActions.SET_VALIDATOR_OTHER:
        copyOfState.tabs[copyOfState.tabSelected].other = action.value
        copyOfState.objective.goals[copyOfState.tabSelected].other = action.value
        break
      case MissionEditorActions.SET_MANIFEST_VAL:
        const prevId = copyOfState[copyOfState.editorSelected].id
        copyOfState[copyOfState.editorSelected][action.key] = action.value
        if (action.key === 'scene'){
          copyOfState[copyOfState.editorSelected]['sceneParams'] = environmentList[action.value].defaultParams ? environmentList[action.value].defaultParams : {}
        }
        if (action.key === 'id' && prevId !== '') {
          missionEditorController.deleteJson(prevId, copyOfState.editorSelected, (res) => {
            deleteComplete(copyOfState.editorSelected, res)
          })
        }
        if (action.key === 'title') {
          if (copyOfState.flowTitles.hasOwnProperty(copyOfState[copyOfState.editorSelected].id)) {
            copyOfState.flowTitles[copyOfState[copyOfState.editorSelected].id] = action.value
          }
        }
        break
      case MissionEditorActions.SET_QUESTION_TYPE:
        copyOfState.question.type = action.value
        break
      case MissionEditorActions.SET_QUESTION_XP:
        copyOfState.question.xp = action.value
        break
      case MissionEditorActions.SYNC_MSN:
        const { pack, mission } = await missionEditorController.formatMissionFromEditor(
          copyOfState.mission
        )
        await syncMissionEditor(copyOfState)
        if (missionState.activeMissionPack?.title === pack?.title &&
          missionState.activeMission?.title === mission?.title &&
          missionState.ui.curFlowIndex !== -1){
          previousFlowIndex.current = missionState.ui.curFlowIndex
        }
        await missionDispatch({type: MissionActions.SYNC_FROM_EDITOR, pack, mission})
        break
      case MissionEditorActions.IMPORT_MSN_JSON:
        copyOfState.flowTitles = action.titles
        copyOfState.mission = action.value
        copyOfState.toolSelected = 'Manifest'
        copyOfState.showCode = false
        copyOfState.editorSelected = 'mission'
        syncMissionEditor(copyOfState)
        break
      case MissionEditorActions.JSON_LOADED:
        copyOfState[action.editor] = action.json
        copyOfState.toolSelected = 'Manifest'
        copyOfState.showCode = false
        copyOfState.editorSelected = action.editor
        break
      case MissionEditorActions.ADD_FLOW_TITLE:
        copyOfState.flowTitles[action.id] = action.title
        break
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }

    if (copyOfState[copyOfState.editorSelected].id !== '' &&
      action.type !== MissionEditorActions.SYNC_MSN &&
      action.type !== MissionEditorActions.SELECT_TAB &&
      action.type !== MissionEditorActions.CHANGE_EDITOR &&
      action.type !== MissionEditorActions.CHANGE_TOOL &&
      action.type !== MissionEditorActions.JSON_LOADED &&
      action.type !== MissionEditorActions.IMPORT_MSN_JSON) {
      missionEditorController.saveJson(copyOfState[copyOfState.editorSelected], copyOfState.editorSelected, (res) => {
        saveComplete(copyOfState.editorSelected, res)
      }, true)
    }

    if (action.type !== MissionEditorActions.SYNC_MSN &&
       action.type !== MissionEditorActions.CHANGE_TOOL &&
       action.type !== MissionEditorActions.UPDATE_CODE &&
       action.type !== MissionEditorActions.CHANGE_EDITOR) {
      stateCache.current = copyOfState
      setState(copyOfState)
    }
  }

  return (
    <MissionEditorContext.Provider value={{state: {...state, validatorTestState}, dispatch}}>
      {children}
    </MissionEditorContext.Provider>
  )
}

const useMissionEditor = () => {
  const {state, dispatch} = React.useContext(MissionEditorContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useMissionEditor must be used within a child of a MissionEditorProvider'
    )
  }
  return [state, dispatch]
}

export { useMissionEditor, setMissionEditorValidatorTestState }