// Base class for exposing method calls via 'postMessage' API

export default class WorkerRPCmessenger {
  constructor(worker, name) {
    this.worker = worker
    this.name = name
    this.worker.onmessage = this.onmessage
  }

    // Post an RPC call to the other thread
    postRPC = (funcName, argArray) => {
      this.worker.postMessage({ func: funcName, args: argArray })
    }

    // Receive and execute {func, args} RPC messages from other thread
    onmessage = (e) => {
      // console.log(`Received by ${this.name}:`, e)
      const message = e.data
      try {
        this[message.func].apply(this, message.args)
      } catch (err) {
        console.error(`Error in ${this.name} executing RPC: `, err)
      }
    }
}

