import fetchHandler from '../tracking/fetchHandler'
import { getUserSessionId } from './userAuthStore'
import { firebaseDomain } from '../domain'

var respMiddlewareSubscribersObject = {}
var respMiddlewareSubscribers = []
function subscribeToAuthFetchResponses(id, subscriber) {
  respMiddlewareSubscribersObject[id] = subscriber
  respMiddlewareSubscribers = Object.values(respMiddlewareSubscribersObject)
}

const authFetchHandler = async (url, options, acceptedErrorCodes) => {
  const userSessionId = getUserSessionId()
  const headersClone = options?.headers ?? {}
  const headers = {...headersClone, Authorization: 'Bearer ' + userSessionId}

  const resp = await fetchHandler(`${firebaseDomain}/${url}`, {...options, headers}, acceptedErrorCodes)
  respMiddlewareSubscribers.forEach(subscriber => subscriber(resp.clone()))
  return resp
}

export { subscribeToAuthFetchResponses }
export default authFetchHandler
