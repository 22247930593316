import { userIsLoggedIn } from '../user-session/user-session-use-cases/userIsLoggedIn'
import {
  codeFileGateway,
} from './code-file-gateways'
import { codeFileUseCases } from './code-file-use-cases'
import { userFileUseCases } from '../user-files/user-file-use-cases'
import { appDefaults } from '../../BotSimDefaults'
import { setSharedFileInRepo } from './code-file-store'
import { presentSharedFileTabs } from './code-file-presenter'
import { codeFileLocalStorageController, getUserFilesLocalStorageController } from '../../databaseworker/database.worker.controller'

class CodeFileController {
  async handleUserLoggedOut() {
    await Promise.all([
      codeFileLocalStorageController.deleteDatabase(),
      getUserFilesLocalStorageController('').deleteDatabase(),
    ])
  }

  async initializeUserFileTree() {
    const userFileTree = await codeFileGateway.getFileTreeFromCloud()
    await codeFileLocalStorageController.saveMetadata(userFileTree)

    await Promise.all([...userFileTree?.tree?.keys()].map(async (uid) => {
      const metadata = userFileTree.tree.get(uid)
      // If an entry does not contain a 'tree' array, it is a file
      if (!Array.isArray(metadata.tree)) {
        const file = await codeFileGateway.getFromCloud(uid, true)
        await codeFileLocalStorageController.saveFile(uid, file)
      }
    }))

    return userFileTree
  }

  async resetFiles() {
    if (!userIsLoggedIn()) {
      await codeFileUseCases.saveFile(null, appDefaults.initialCodeSnippet.python)
    }

    return await userFileUseCases.saveUserProgress(appDefaults.userProgress)
  }

  async getSharedFile(fileId) {
    const metaData = await codeFileGateway.getMetaDataFromCloud(fileId)
    if (!metaData) {
      return
    }

    const fileContents = await codeFileGateway.getFromCloud(fileId)
    if (!fileContents) {
      return
    }

    setSharedFileInRepo({ ...metaData, content: fileContents, loadTs: Date.now() })
    presentSharedFileTabs()
    return metaData
  }
}

const codeFileController = new CodeFileController()
export {
  codeFileController,
}