import {
  setGroupsInStore,
} from '../group-store'
import { fetchGroupsFromCloud } from '../group-gateways'
import {
  fetchLessonContent,
  getMissionPackWithMissionId,
  getMissionIdMissionsIndex,
  getPackAndMissionFromFlowId,
} from '../../lesson-content/lesson-content-use-cases'
import {
  presentFetchGroupsResponseFailed,
  presentFetchGroupsResponseStarted,
  presentFetchGroupsResponseSucceeded,
} from '../group-presenter'
import { userIsLoggedIn } from '../../user-session/user-session-use-cases/userIsLoggedIn'
import { generateFreeGroups, generateDefaultGroups, DEFAULT_GROUP_ID } from './generateDefaultGroups'
import { readMissionPackStoreByPackId  } from '../../lesson-content/lesson-content-store'
import { decodeLessonAccessCode } from './lesson-access-code-operations'
import { fetchFloatingLicenseSeats } from './fetchFloatingLicenseSeats'

async function handleGetGroupsData() {
  let groups = generateFreeGroups()
  let lastActiveGroup = DEFAULT_GROUP_ID

  if (userIsLoggedIn()) {
    fetchFloatingLicenseSeats()
    const groupDataFromCloud = await fetchGroupsFromCloud()
    if (!groupDataFromCloud) {
      presentFetchGroupsResponseFailed()
    } else {
      groups = [...groups, ...groupDataFromCloud.groups, ...(await generateDefaultGroups())]
      lastActiveGroup = groupDataFromCloud.lastActiveGroup ?? lastActiveGroup
    }
  }

  return {
    groups,
    lastActiveGroup,
  }
}

async function fetchGroups(){
  presentFetchGroupsResponseStarted()
  const {
    groups,
    lastActiveGroup,
  } = await handleGetGroupsData()

  setGroupsInStore(groups)

  const missionPackIds = {}
  for (const i in groups) {
    const group = groups[i]
    if (!readMissionPackStoreByPackId(group.missionPackId)) {
      missionPackIds[group.missionPackId] = null
    }
  }

  await fetchLessonContent(Object.keys(missionPackIds))

  groups.forEach((group) => {
    let lessonLockCode = group.lessonLockCode
    let lessonUnlockCode = group.lessonUnlockCode
    if (!!group.memberLessonLockCode || !!group.memberLessonUnlockCode) {
      lessonLockCode = group.memberLessonLockCode
      lessonUnlockCode = group.memberLessonUnlockCode
    }

    group.lockedLessonItemIds = []
    group.unlockedLessonItemIds = []
    if (lessonLockCode) {
      group.lockedLessonItemIds = decodeLessonAccessCode(readMissionPackStoreByPackId(group.missionPackId), lessonLockCode)
    }

    if (lessonUnlockCode) {
      group.unlockedLessonItemIds = decodeLessonAccessCode(readMissionPackStoreByPackId(group.missionPackId), lessonUnlockCode).filter(id => !group.lockedLessonItemIds.includes(id))
    }

    let progressionGates = []
    if (group.lockedLessonItemIds && group.lockedLessonItemIds.length > 0) {
      let packId
      let missionId
      let flowId

      const lessonId = group.lockedLessonItemIds[0]
      const lessonDataFromFlowId = getPackAndMissionFromFlowId(lessonId)
      if (!!lessonDataFromFlowId) {
        const { pack, mission } = lessonDataFromFlowId
        packId = pack.id
        missionId = mission.id
        flowId = lessonId
      } else {
        const pack = getMissionPackWithMissionId(lessonId)
        const missionIdx = getMissionIdMissionsIndex(lessonId)
        const mission = pack.missions[missionIdx]
        packId = pack.id
        missionId = mission.id
        flowId = mission.flow[0].id
      }

      progressionGates.push({
        id: lessonId,
        // lockedUntilTs = null,
        source: 'progressionLocker',
        progressionLockerSource: true,
        // sourceId = null,
        locked: true,
        missionPackId: packId,
        missionId,
        flowId,
        appearsBeforeFlow: true,
      })
      group.progressionGates = progressionGates
    }
  })
  setGroupsInStore(groups)

  presentFetchGroupsResponseSucceeded()
  return {groups: Object.values(groups), missionPackIds, lastActiveGroup: lastActiveGroup !== '' ? lastActiveGroup : null}
}

export {
  fetchGroups,
}