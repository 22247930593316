export const funQuotes = [
  // Curated GPT/Gemini
  'Whispering to the code sprites',
  'Tickling the circuits',
  'Greasing the gears of thought',
  'Polishing the bytes',
  'Knitting network cables',
  'Stretching the fabric of cyberspace',
  'Feeding the firewall flames',
  'Tuning the data streams',
  'Harvesting pixel crops',
  'Herding the bits',
  'Casting code spells',
  'Uncoiling the Python loops',
  'Decorating functions with syntactic sugar',
  'Feeding the garbage collector',
  'Spawning child processes to do our bidding',
  'Embedding Easter eggs',
  'Counting reference cycles',
  'Comprehending list comprehensions',
  'Beautifying code with PEP 8 stylings',
  'Breaking out of loops with style',
  'Routing circuit paths with precision',
  'Charging capacitors for a quick start',
  'Tuning resistors for perfect balance',
  'Testing transistor logic',
  'Blinking LEDs in sequence',
  'Boosting signals to reach far away',
  'Amplifying the whispers of electrons',
  'Deploying microcontrollers on missions',
  'Isolating faults in the network',
  'Harnessing the power of electromagnetism',
  'Catching sparks of inspiration',
  'Energizing the power supply units',

  // from Sim City
  'Deciding What Message to Display Next',
  'Extracting Resources',
  'Gathering Particle Sources',
  'Reticulating Splines',
  'Perturbing Matrices',
  'Resolving GUID Conflict',
  'Synthesizing Gravity',
]