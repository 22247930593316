import * as PythonParser from '@qoretechnologies/python-parser'
import { printNode } from './utils/PythonParseTools'
import { makeTools } from './utils/code-parse-tools'
import { isRegExp } from 'lodash'
import { scoreInterface } from './content-manager/content-director/content-director-use-cases/score-actions'

export const ValidatorTypes = Object.freeze({
  CODE_REGEX: 0,
  CONSOLE_REGEX: 1,
  RUN_TIMER: 2,
  MODEL_EV: 3,
  MODEL_CHANGE: 4,
  MODEL_COLLISION: 5,
  SCENE_PICK: 6,
  SCENE: 7,
  CONTEXT_EV: 8,
  CONTEXT_SINGLE: 9,
  CODE: 10,
  CONSOLE: 11,
  CODE_ERROR: 12,
  FILE_OPERATION: 13,
  UART_COMMAND: 14,
  POLL_BACKEND: 15,
  RUN_STATE: 16,
  CODE_PARSE: 17,
})

export const ValidatorEditorNotes = [
  {id: 'CODE_REGEX', name: 'Code Regex', input: 'regex'},
  {id: 'CONSOLE_REGEX', name: 'Console Regex', input: 'regex'},
  {id: 'RUN_TIMER', name: 'Run Timer', input: 'float'},
  {id: 'MODEL_EV', name: 'Model Event', input: 'func', params: ['modelEvent', 'controller','dataStore', 'score'], ret: 'bool'},
  {id: 'MODEL_CHANGE', name: 'Model Change', input: 'func', params: ['newVal', 'oldVal', 'controller', 'dataStore', 'score'], ret: 'bool'},
  {id: 'MODEL_COLLISION', name: 'Model Collision', input: 'func', params: ['meshName', 'controller', 'dataStore', 'score', 'meshObj'], ret: 'bool'},
  {id: 'SCENE_PICK', name: 'Scene Pick', input: 'regex'},
  {id: 'SCENE', name: 'Scene General', input: 'func', params: ['successCb', 'scene', 'dataStore', 'score'], ret: 'destroyFunc'},
  {id: 'CONTEXT_EV', name: 'Context Event', input: 'none'},
  {id: 'CONTEXT_SINGLE', name: 'Context Single Event', input: 'func', params: ['action', 'dataStore', 'score'], ret: 'bool'},
  {id: 'CODE', name: 'Code', input: 'func', params: ['code', 'hasVar', 'funcUsed', 'score'], ret: 'bool'},
  {id: 'CONSOLE', name: 'Console', input: 'func', params: ['text', 'dataStore', 'score'], ret: 'bool'},
  {id: 'CODE_ERROR', name: 'Code Error', input: 'func', params: ['err', 'score'], ret: 'bool'},
  {id: 'FILE_OPERATION', name: 'File Operation', input: 'func', params: ['filename', 'op', 'status', 'data', 'dataStore', 'score'], ret: 'bool'},
  {id: 'UART_COMMAND', name: 'UART Command', input: 'func', params: ['cmd', 'deviceId', 'data', 'dataStore', 'score'], ret: 'bool'},
  {id: 'POLL_BACKEND', name: 'Poll Debug Backend', input: 'func', params: ['successCb', 'backend', 'dataStore', 'score'], ret: 'null'},
  {id: 'RUN_STATE', name: 'Run State', input: 'func', params: ['runState', 'isCodeRunner', 'dataStore', 'score'], ret: 'bool'},
  {id: 'CODE_PARSE', name: 'Code Parse', input: 'func', params: ['parsedMap', 'score'], ret: 'bool'},
]

const createRegex = (text) => {
  const parts = /\/(.*)\/(.*)/.exec(text)
  return new RegExp(parts[1], parts[2])
}

const createContextEv = (text, contextEvList) => {
  const parts = text.split('.')
  const actions = contextEvList[parts[0]]
  return actions[parts[1]]
}

class Validator {
  constructor(type, successCb, failCb, updateCb) {
    this.successObservers = [successCb]
    this.failObservers = [failCb]
    this.updateObservers = [updateCb]
    this.type = type
    this.validated = false
    this.paused = false
    this.dataStore = {}
    this.score = scoreInterface
  }

  success = () => {
    this.validated = true
    if (!this.paused) {
      this.successObservers.forEach((cb) => {
        cb()
      })
    }
    this.paused = true
  }

  fail = () => {
    this.validated = false
    if (!this.paused) {
      this.failObservers.forEach((cb) => {
        cb()
      })
    }
    this.paused = true
  }

  // this is called to inform external watchers that state has changed (but not a fail or success)
  update = () => {
    this.updateObservers.forEach((cb) => {
      cb()
    })
  }

  destroy() { // override this if necessary

  }

  reset() { // override this if necessary
    this.dataStore = {}
    this.paused = false
    this.validated = false // get this by calling super.reset()
  }
}

export class CodeValidator extends Validator {
  constructor(successCb, failCb, validator) {
    super(ValidatorTypes.CODE, successCb, failCb, null)

    this.checkFunc = new Function('code', 'score', validator) // eslint-disable-line no-new-func
  }

  check(code) {
    this.paused = false // calls listeners on each check
    if (this.checkFunc(code, this.score)) {
      this.success()
    } else {
      this.fail()
    }
  }
}

export class CodeParseValidator extends Validator {
  // Cache the most recent parse result
  static cachedCode = ''
  static cachedParseMap = null

  constructor(successCb, failCb, validator) {
    super(ValidatorTypes.CODE_PARSE, successCb, failCb, null)

    this.checkFunc = new Function('parsedMap', 'score', validator) // eslint-disable-line no-new-func
  }

  getParsedMap = (codeString) => {
    const result = new Map()
    let ast
    try {
      ast = PythonParser.parse(codeString)
    } catch (err) {
      // The err object has a useful message, but for now we discard.
      return false
    }

    const tree = PythonParser.walk(ast)
    tree.forEach((node) => {
      let res = result.get(node.type)
      if (res === undefined) {
        res = []
        result.set(node.type, res)
      }
      res.push(printNode(node))
    })

    // --- Add some helper methods for use by Validator authors ---
    result.contains = (nodeType, substr) => {
      // Return true if any nodeType (key) array includes the substr (or regex).
      // Ex: parsedMap.contains('from', 'time import sleep')
      const nodes = result.get(nodeType) ?? []
      if (!substr) {
        return nodes
      } else if (isRegExp(substr)) {
        return nodes.some(n => n.match(substr))
      } else {
        return nodes.some(n => n.includes(substr))
      }
    }

    result.filterType = (nodeType, substr) => {
      // Return array of nodes of given nodeType matching substr
      const nodes = result.get(nodeType) ?? []
      if (!substr) {
        return nodes
      } else if (isRegExp(substr)) {
        return nodes.filter(n => n.match(substr))
      } else {
        return nodes.filter(n => n.includes(substr))
      }
    }

    result.tools = makeTools(result, this.getParsedMap)
    return result
  }

  pyParse = (codeString) => {
    if (CodeParseValidator.cachedParseMap && codeString === CodeParseValidator.cachedCode) {
      return CodeParseValidator.cachedParseMap
    }

    const result = this.getParsedMap(codeString)
    // Cache result
    CodeParseValidator.cachedCode = codeString
    CodeParseValidator.cachedParseMap = result

    return result
  }

  check(code) {
    this.paused = false // calls listeners on each check
    const parsed = this.pyParse(code)
    if (parsed && this.checkFunc(parsed, this.score)) {
      this.success()
    } else {
      this.fail()
    }
  }
}

export class CodeErrorValidator extends Validator {
  constructor(successCb, validator) {
    super(ValidatorTypes.CODE_ERROR, successCb, null, null)

    this.checkFunc = new Function('err', 'score', validator) // eslint-disable-line no-new-func
  }

  check(err) {
    if (this.checkFunc(err, this.score)) {
      this.success()
    }
  }
}

export class ConsoleValidator extends Validator {
  constructor(successCb, validator) {
    super(ValidatorTypes.CONSOLE, successCb, null, null)
    this.checkFunc = new Function('text', 'dataStore', 'score', validator) // eslint-disable-line no-new-func
  }

  check(text) {
    if (this.checkFunc(text, this.dataStore, this.score)) {
      this.success()
    }
  }
}

export class ContextSingleValidator extends Validator {
  constructor(successCb, failCb, contextEv, validator, evList) {
    super(ValidatorTypes.CONTEXT_SINGLE, successCb, failCb, null)
    this.checkFunc = new Function('action', 'dataStore', 'score', validator) // eslint-disable-line no-new-func
    this.contextEv = createContextEv(contextEv, evList)
  }

  check(contextAction) {
    if (this.contextEv === contextAction.type) {
      if (this.checkFunc(contextAction, this.dataStore, this.score)) {
        this.success()
      }
    }
  }
}

/* Example ContextSingleValidator (contextEv = 'SCENE.CAMERA_HELP')

if(action.shouldShow) {
  dataStore.opened = true
} else {
  dataStore.closed = true
}

return dataStore.opened && dataStore.closed

*/

export class ContextEventValidator extends Validator {
  constructor(successCb, failCb, contextEv, evList) {
    super(ValidatorTypes.CONTEXT_EV, successCb, failCb, null)
    this.contextEv = createContextEv(contextEv, evList)
  }

  check(contextEvent) {
    if (this.contextEv === contextEvent.type) {
      this.success()
    }
  }
}

class BaseRegexValidator extends Validator {
  constructor(type, successCb, failCb, regex) {
    super(type, successCb, failCb, null)
    this.regex = createRegex(regex)
  }

  check(text) {
    if (text.match(this.regex)) {
      this.success()
    }
  }
}

export class ConsoleRegexValidator extends BaseRegexValidator {
  constructor(successCb, regex) {
    super(ValidatorTypes.CONSOLE_REGEX, successCb, ()=>{}, regex)
  }
}

/* EXAMPLE ConsoleRegexValidator

/Hello/

*/

export class CodeRegexValidator extends BaseRegexValidator {
  constructor(successCb, failCb, regex) {
    super(ValidatorTypes.CODE_REGEX, successCb, failCb, regex)
  }

  check(text) {
    this.paused = false // calls listeners on each check
    if (text.match(this.regex)) {
      this.success()
    } else {
      this.fail()
    }
  }
}

/* EXAMPLE CodeRegexValidator

/import/

*/

export class RunTimerValidator extends Validator {
  constructor(successCb, failCb, updateCb, modelController, timeSec) {
    // pass in a list of validators you want to monitor for success
    super(ValidatorTypes.RUN_TIMER, successCb, failCb, updateCb)
    this.pollTimer = null
    this.seconds = timeSec
    this.timeStarted = null
    this.controller = modelController
    this.controller.getRunTimeRemaining = this.getTimeRemaining
  }

  addTimedValidators = (timedVals) => {
    this.validators = timedVals
    timedVals.forEach((validator) => {
      const validatorSuccessFunc = () => {
        if (this.checkSuccess()) {
          this.stop()
          this.success()
        }
      }
      const validatorFailFunc = () => {
        this.stop()
        this.fail()
      }
      validator.successObservers.push(validatorSuccessFunc)
      validator.failObservers.push(validatorFailFunc)
    })
  }

  getTimeRemaining = () => {
    return (this.seconds * 1000) - (this.controller.getPhysicsElapsedMs() - this.timeStarted)
  }

  start = () => {
    this.stop()

    // only run the timer again if you were unsuccessful before or were reset
    if (!this.validated) {
      if (this.seconds !== undefined && this.seconds) {
        this.reset()
        this.validators.forEach((v) => {
          // when run it will reset every validator that it is watching
          v.reset()
        })
        this.update() // force an update in all external observers (clear the goals)
        this.timeStarted = this.controller.getPhysicsElapsedMs()
        this.pollTimer = setInterval(this.pollTimeout, 1000)  // Poll timeout every second
      }
    }
  }

  stop = () => {
    clearTimeout(this.pollTimer)
    this.pollTimer = null
  }

  pollTimeout = () => {
    const elapsed = this.controller.getPhysicsElapsedMs() - this.timeStarted
    if (elapsed < this.seconds * 1000) {
      return
    }
    this.stop()

    if (this.checkSuccess()) {
      this.success()
    } else {
      this.fail()
    }
  }

  checkSuccess = () => {
    return this.validators.every(v => v.validated === true)
  }

  destroy() {
    this.stop()
  }
}

/* EXAMPLE RunTimerValidator

10.0

*/

// In the future, information about the simulator should be included in the model event validator.
// Currently, the only value of importance is time elapsed in the simulator. For now, in Mission 6 Objective 4,
// I've used a work-around by reaching into the controller. When we add a 'sim info' field to the validator, remove that work-around.
class BaseModelEventValidator extends Validator {
  constructor(type, successCb, failCb, controller, func) {
    super(type, successCb, failCb, null)
    this.controller = controller
    this.validatorFunc = func
  }

  check = (modelEvent) => {
    if (this.validatorFunc(modelEvent, this.controller, this.dataStore, this.score)) {
      this.success()
    }
  }
}

export class ModelEventValidator extends BaseModelEventValidator {
  constructor(successCb, controller, validatorCode) {
    const func = new Function('modelEvent', 'controller', 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
    super(ValidatorTypes.MODEL_EV, successCb, null, controller, func)
  }
}

/* EXAMPLE ModelEventValidator

const changeObj = Object.fromEntries(modelEvent)
Object.assign(dataStore, changeObj)
return (
  dataStore.motorL &&
  dataStore.motorR &&
  dataStore.motorsEnabled &&
        (dataStore.motorL !== dataStore.motorR) &&
        ((dataStore.motorL > 0) === (dataStore.motorR > 0))
)

*/

export class ModelChangeValidator extends BaseModelEventValidator {
  constructor(successCb, controller, validatorCode, modelParam) {
    const valueCheckFunc = new Function('newVal', 'oldVal', 'controller', 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
    const func = (ev, controller, dataStore) => {
      const newValue = ev.get(modelParam)
      const oldValue = controller[modelParam]
      return (newValue !== undefined && valueCheckFunc(newValue, oldValue, controller, dataStore, this.score))
    }
    super(ValidatorTypes.MODEL_CHANGE, successCb, null, controller, func)
  }
}

/* EXAMPLE ModelChangeValidator (Model Name = 'userLeds')

const isOn = newVal & 0x01
const wasOn = oldVal & 0x01
return (isOn && !wasOn)

*/

export class ModelCollisionValidator extends Validator {
  constructor(successCb, controller, validatorCode) {
    super(ValidatorTypes.MODEL_COLLISION, successCb, null, null)

    this.validatorFunc = new Function('meshName', 'controller', 'dataStore', 'score', 'meshObj', validatorCode)  // eslint-disable-line no-new-func
    this.controller = controller
  }

  check = (mesh) => {
    if (this.validatorFunc(mesh.name, this.controller, this.dataStore, this.score, mesh)) {
      this.success()
    }
  }
}

/* EXAMPLE ModelCollisionValidator

if(!dataStore.started) {
  dataStore.started = true
  dataStore.ballTimes = new Map()  // {name : timeMs}
}
if(meshName.slice(0, 4) === 'ball') {
    dataStore.ballTimes.set(meshName, Date.now())
    if (dataStore.ballTimes.size === 2) {
        const vals = [...dataStore.ballTimes.values()]
        const tSpan = Math.max(...vals) - Math.min(...vals)
        if (tSpan <= 30000) {
            dataStore.ballTimes.clear()
            return true
        }
    }
}
return false

*/

export class ScenePickValidator extends Validator {
  constructor(successCb, scene, regex) {
    super(ValidatorTypes.SCENE_PICK, successCb, null, null)
    this.regex = createRegex(regex)
    this.scene = scene
    this.scene.pickObservable.add(this.simPickHandle)
  }

  simPickHandle = (pickResult) => {
    // console.log(pickResult.pickedMesh.name)
    if (pickResult.pickedMesh.name.match(this.regex)) {
      this.success()
    }
  }

  destroy() {
    this.scene.pickObservable.delete(this.simPickHandle)
  }
}

/* EXAMPLE ScenePickValidator (Wheel Encoder)

/Cylinder.015$/

*/


export class ScenePassThroughValidator extends Validator {
  constructor(successCb, scene, validatorCode) {
    super(ValidatorTypes.SCENE, successCb, null, null)
    const func = new Function('successCb', 'scene', 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
    this.destroy = func(this.success, scene, this.dataStore, this.score) // this function MUST return a destroy func
  }
}

/* EXAMPLE ScenePassThroughValidator

const pos = scene.scene.activeCamera.position

dataStore.camInitX = pos.x
dataStore.pollCameraTimer = setInterval(() => {
    const pos = scene.scene.activeCamera.position
    if(Math.abs(pos.x - dataStore.camInitX) > 2) {
        successCb()
    }
}, 100)

const destroy = () => {
    clearInterval(dataStore.pollCameraTimer)
}

return destroy

*/

export class FileOperationValidator extends Validator {
  constructor(successCb, failCb, validatorCode) {
    super(ValidatorTypes.FILE_OPERATION, successCb, failCb, null)
    this.validatorFunc = new Function('filename', 'op', 'status', 'data', 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
  }
  check = (filename, op, status, data) => {
    if (this.validatorFunc(filename, op, status, data, this.dataStore, this.score)) {
      this.success()
    }
  }
}

export class UARTCommandValidator extends Validator {
  constructor(successCb, failCb, validatorCode) {
    super(ValidatorTypes.UART_COMMAND, successCb, failCb, null)
    this.validatorFunc = new Function('cmd', 'deviceId', 'data', 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
  }
  check = (cmd, id, data) => {
    if (this.validatorFunc(cmd, id, data, this.dataStore, this.score)) {
      this.success()
    }
  }
}

class BasePollObjectValidator extends Validator {
  constructor(type, successCb, obj, objName, pollTimeMs, validatorCode) {
    super(type, successCb, null, null)
    const func = new Function('successCb', objName, 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
    this.intvl = setInterval(func, pollTimeMs, this.success, obj, this.dataStore, this.score)
  }
  destroy = () => {
    if (this.intvl) {
      clearInterval(this.intvl)
      this.intvl = null
    }
  }
}

export class PollBackendValidator extends BasePollObjectValidator {
  constructor(successCb, backend, pollTimeMs, validatorCode){
    super(ValidatorTypes.POLL_BACKEND, successCb, backend, 'backend', pollTimeMs, validatorCode)
  }
}

export class RunStateValidator extends Validator {
  constructor(successCb, validatorCode) {
    super(ValidatorTypes.RUN_STATE, successCb, null, null)

    this.validatorFunc = new Function('runState', 'isCodeRunner', 'dataStore', 'score', validatorCode)  // eslint-disable-line no-new-func
  }

  check = (runState, isCodeRunner) => {
    if (this.validatorFunc(runState, isCodeRunner, this.dataStore, this.score)) {
      this.success()
    }
  }
}