import { CodeParseValidator } from '../../Validators'
import { removeValidatorTestFromStore, setValidatorTestInStore, iterateTotalTests } from './lesson-editor-repos'
import { isToolValid } from '../../Toolbox/ToolboxUtil'

function getParsedCodeMap(code) {
  const val = new CodeParseValidator((e) => {}, () => {}, '')
  return val.pyParse(code)
}

async function goalCodeParseValidatorWorks(goal, solutionCode) {
  if (goal.validatorType !== 'CODE_PARSE') {
    return null
  }

  var succeeded = null
  var val
  try {
    val = new CodeParseValidator((e) => {
      succeeded = true
    }, () => {
      succeeded = false
    }, goal.validator)
    val.check(solutionCode)
  } catch {
    return
  }

  let startTs = Date.now()
  function checkSuccess(res) {
    if (startTs + 10000 < Date.now()) {
      return res(false)
    }

    if (succeeded !== null) {
      if (succeeded) {
        res(true)
      } else {
        res(false)
      }
    } else {
      setTimeout(() => checkSuccess(res), 50)
    }
  }

  const resp = await new Promise((res) => {
    checkSuccess(res)
  })
  val.destroy()
  return resp
}

async function testFlowItemCodeParseValidators(flowItem) {
  const goalResults = await Promise.all(flowItem.goals.map(goal => goalCodeParseValidatorWorks(goal, flowItem.solution)))
  for (const i in goalResults) {
    const result = goalResults[i]
    if (result === null) {
      continue
    }

    iterateTotalTests()
    removeValidatorTestFromStore(flowItem.id, i)

    if (!result) {
      setValidatorTestInStore(flowItem.id, i)
    }
  }
}

function findInvalidToolboxItemsInString(str) {
  const items = str.matchAll(/==(\w+[\s|.|\-|:\w+]+)==/g).map(x => x[1])
  return items.filter(item => !isToolValid(item))
}

export { testFlowItemCodeParseValidators, goalCodeParseValidatorWorks, getParsedCodeMap, findInvalidToolboxItemsInString }