import { closeUserSession } from './endUserSession'
import { USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE, FAILED_TO_AUTHENTICATE_USER } from './user-session-error-codes'
import { subscribeToAuthFetchResponses } from '../../../tools/auth/authFetchHandler'
import { userSessionPresenter } from '../user-session-presenter'

async function handleSessionExpired(message) {
  if (message !== USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE && message !== FAILED_TO_AUTHENTICATE_USER) {
    return
  }

  closeUserSession()
  userSessionPresenter.displayUserLogoutMessage(message)
  return true
}

async function sessionExpirationSubscriber(resp) {
  if (resp.status !== 401) {
    return
  }

  const message = await resp.json()
  handleSessionExpired(message?.message)
}
subscribeToAuthFetchResponses('sessionExpirationSubscriber', sessionExpirationSubscriber)

export { handleSessionExpired, USER_LOGGED_IN_ON_MULTIPLE_DEVICES_ERROR_CODE }