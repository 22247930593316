// Mission and Objective Panels
import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { Assignment as AssignmentIcon } from '@material-ui/icons'
import { useContentDirector } from '../contexts/ContentDirectorContext'
import { PanelHeader } from '../ObjectivePanel'
import { ManualAssignmentSubmissionDialog } from './ManualAssignmentSubmissionDialog'

function DetailField({ title, value, noPadding }) {
  return (
    <div style={{paddingBottom: noPadding ? 0 : 25}}>
      <Typography variant='caption' color='textSecondary'>{title}</Typography>
      {typeof value === 'string' || typeof value === 'number' ?
        <Typography variant='subtitle1' style={{fontWeight: 500, fontSize: 18}}>{value}</Typography>:
        value}
    </div>
  )
}

function AssignmentDetail() {
  const { assignment } = useContentDirector()
  if (!assignment) {
    return null
  }

  return (
    <div>
      <div style={{display: 'flex', alignItems:'flex-start', justifyContent: 'space-between'}}>
        <DetailField title={'Submissions Made'} value={`${assignment.submissionsMade}`}/>
        <div></div>
      </div>
      <DetailField title='Submission Instructions' value={'Submit the code used to complete the objective.'} noPadding={true}/>
    </div>
  )
}

function AssignmentSubmissionPanelBody() {
  const [manualSubmissionDialogOpen, setManualSubmissionDialogOpen] = React.useState(false)
  const { assignment } = useContentDirector()
  if (!assignment) {
    return null
  }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', padding: 10, justifyContent: 'space-between', height: '100%'}}>
        <AssignmentDetail />
        <div style={{display: 'flex', alignItems:'space-between', justifyContent: 'center'}}>
          <Button
            variant='outlined'
            style={{width: '100%', height: 61}}
            onClick={() => setManualSubmissionDialogOpen(true)}
            disabled={!assignment.submissionsRemaining}
          >
            {!assignment.submissionsRemaining ? 'No Submissions Remaining' : 'Submit Assignment Manually'}
          </Button>
        </div>
      </div>
      <ManualAssignmentSubmissionDialog
        open={manualSubmissionDialogOpen}
        handleClose={() => setManualSubmissionDialogOpen(false)}
      />
    </>
  )
}

function AssignmentSubmissionPanel(props) {
  const { contentSequenceItem }  = useContentDirector()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: 0, padding: 5, height: '100%' }}>
      <PanelHeader
        title={contentSequenceItem?.assignment?.title}
        onClose={props.toggleSubmissionDialog}
        icon={<AssignmentIcon />}
      />
      <AssignmentSubmissionPanelBody contentSequenceItem={contentSequenceItem} />
    </div>
  )
}

export { AssignmentSubmissionPanel, AssignmentDetail }
