import { setActiveMissionPackId,
  setContentDirectorInitialized,
  readContentDirectorInitialized,
} from '../content-director-store'
import { mergeActiveGroupAndLessonContentSequence } from './mergeActiveGroupAndLessonContentSequence'
import { presentContentDirector, presentMissionPack, presentCloseMissionSelect } from '../content-director-presenter'
import { changeActiveMission } from './changeActiveMission'
import { findNextUncompletedMission } from '../../user-progress/user-progress-use-cases/findNextUncompletedMission'
import { userSessionStore } from '../../user-session/user-session-store'
import { getMissionIdMissionsIndex } from '../../lesson-content/lesson-content-use-cases'
import { usbDebugBackend } from '../../../Players'

async function initializeMission(missionPackId) {
  const initializedUserProgress = userSessionStore.readInitializedUserSession()

  const nextUncompletedMissionId = await findNextUncompletedMission(missionPackId)
  const nextUncompletedMissionIdIndex =  getMissionIdMissionsIndex(nextUncompletedMissionId)
  const initializedMissionIdIndex =  getMissionIdMissionsIndex(initializedUserProgress.activeMissionId)

  if (
    !nextUncompletedMissionId ||
    (initializedUserProgress.activeMissionPackId === missionPackId &&
    nextUncompletedMissionIdIndex >= initializedMissionIdIndex)
  ) {
    await changeActiveMission(initializedUserProgress.activeMissionId, null, true)
    return
  }

  const missionId = await findNextUncompletedMission(missionPackId)
  await changeActiveMission(missionId, null, true)
}

async function changeActiveMissionPack(missionPackId){
  window.gtag('event', 'select_content', {'content_type':'mission_pack','content_id':String(missionPackId)})
  setActiveMissionPackId(missionPackId)

  if (!readContentDirectorInitialized()) {
    await initializeMission(missionPackId)
    setContentDirectorInitialized()
  } else {
    const nextUncompletedMission = await findNextUncompletedMission(missionPackId)
    await changeActiveMission(nextUncompletedMission, null, true)
  }

  usbDebugBackend.checkVersion()
  presentCloseMissionSelect()
  mergeActiveGroupAndLessonContentSequence()
  presentMissionPack()
  presentContentDirector()
}

export { changeActiveMissionPack }
