// Mission Select Dialog
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Done as DoneIcon, ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'
import { Table, TableRow, TableHead, TableCell, TableBody, Typography, Button, CircularProgress } from '@material-ui/core'
import { useGroups } from '../contexts/GroupsContext'
import { readMissionPackStoreByPackId } from '../content-manager/lesson-content/lesson-content-store'
import { useLogin } from '../contexts/LoginContext'
import { setSelectedGroupIdState } from '../contexts/GroupsContext'
import { LeaveClassDialog } from './LeaveClassDialog'
import { LeaveClassButton } from './LeaveClassButton'
import { SeatTableCell } from './SeatTableCell'
import { genShadeColor, genContrastColor } from '../utils/shade-highlight-tools'
import { takeSeat } from '../content-manager/groups/group-use-cases/takeSeat'
import { GatewayResponseIndicator } from './GatewayResponseIndicator'
import { RESPONSE_STATUS } from '../content-manager/fetch-responses'

const alertColor = '#ff3333'
const errorColor = '#D32F2F'
const useStyles = makeStyles(theme => ({
  infinityCell: {
    fontSize: 22,
    height: 37,
  },
  tableRowBase: {
    height: '37px !important',
    lineHeight: '14px',
  },
  selectedTableRow: {
    backgroundColor: '#F4D038 !important',
  },
  alternatedTableRow: {
    backgroundColor: theme.palette.type === 'dark' ? '#4b4b4b' : '#f0f0f0',
  },
  error: {
    color: errorColor,
    position:'absolute',
  },
  errorIcon: {
    color: alertColor,
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doneIcon: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableHead: {
    backgroundColor: theme.palette.type === 'dark' ? '#323232' : '#CCCCCC',
  },
  buttonCell: {
    padding: '0px !important',
    margin: 0,
    width: 107,
    height: '100%',
  },
  errorHeaderContent: {
    backgroundColor: '#5526b6',
    textTransform: 'none',
    display: 'flex',
    alignItems:'center',
    justifyContent: 'space-between',
    borderRadius: 3,
    width: '100%',
    padding: 5,
    userSelect: 'none',
  },
  dialogContent: {
    border: '1px rgba(0,0,0,0.07) solid',
    borderRadius: 3,
    backgroundColor: 'rgba(0,0,0,0.06)',
    height: '100%',
    maxHeight: 'calc(100% - 30px)',
    overflowY: 'auto',
  },
}))

export const ROW_HEIGHT = 42

function ActivateClassButtonIcon({ groupId, groupActive }) {
  const { takeSeatResponse } = useGroups()

  if (takeSeatResponse?.groupId !== groupId || takeSeatResponse?.status === 'succeeded' || takeSeatResponse?.status === undefined) {
    return (
      <DoneIcon
        stroke={genShadeColor(0.5)}
        strokeWidth={1}
        style={{color: groupActive ? genContrastColor(0.3) : '#24b326', fontSize: 29}}
      />
    )
  }

  return (
    <GatewayResponseIndicator
      placement='left'
      themedSpinner
      open
      fetchState={takeSeatResponse}
      noCheck
      size={24}
    />
  )
}

function ActivateClassTableCellButton({ groupId }) {
  const { activeGroupId, takeSeatInProgress } = useGroups()

  const groupActive = groupId === activeGroupId


  async function handleTakeSeat() {
    await takeSeat(groupId)
  }

  return (
    <Button
      style={{borderRadius: 0, width: '100%', height: ROW_HEIGHT}}
      onClick={handleTakeSeat}
      disabled={groupActive || takeSeatInProgress}
    >
      <ActivateClassButtonIcon {...{ groupActive, groupId }}/>
    </Button>
  )
}

function ClassesTableRow({group, index, openLeaveClassDialog}) {
  const classes = useStyles()
  const { activeGroupId, takeSeatInProgress } = useGroups()
  const missionPack = readMissionPackStoreByPackId(group.missionPackId) ?? {}
  const groupActive = group.id === activeGroupId

  function onLeaveClick() {
    setSelectedGroupIdState(group.id)
    openLeaveClassDialog(true)
  }

  return (
    <TableRow
      style={{backgroundColor: takeSeatInProgress ? 'rgba(0,0,0,0.1)':null, height: ROW_HEIGHT}}
      key={group.groupId}
      className={
        `
          ${classes.tableRowBase} 
          ${index%2 === 0 ? classes.alternatedTableRow : null} 
          ${groupActive ? classes.selectedTableRow : null}
          `
      }
    >
      <TableCell style={{maxWidth: 370}}>
        <Typography style={{fontSize: 14, color: groupActive ? 'black' : null}} noWrap>
          {group.groupName}
        </Typography>
      </TableCell>
      <TableCell style={{maxWidth: 370}}>
        <Typography style={{fontSize: 14, color: groupActive ? 'black' : null}} noWrap>
          {missionPack?.title}
        </Typography>
      </TableCell>
      <SeatTableCell
        group={group}
      />
      <TableCell align='center' className={classes.buttonCell}>
        <ActivateClassTableCellButton
          groupId={group.id}

        />
      </TableCell>
      <TableCell align='center' className={classes.buttonCell}>
        <LeaveClassButton
          onLeaveClick={onLeaveClick}
          group={group}
        />
      </TableCell>
    </TableRow>
  )
}

function LoginErrorHeader() {
  const classes = useStyles()
  const [{ user }] = useLogin()

  if (user) {
    return null
  }

  return (
    <div style={{flex:1, padding: 10}}>
      <div className={classes.errorHeaderContent} >
        <ErrorOutlineIcon style={{color: 'white'}}/>
        <Typography style={{color: 'white'}}>
          {'Please log in to view Mission Packs for your classes!'}
        </Typography>
        <ErrorOutlineIcon style={{color: 'white'}}/>
      </div>
    </div>
  )
}

function ClassesDialogContent() {
  const classes = useStyles()
  const { groups, fetchFloatingLicenseSeatsResponse } = useGroups()
  const [leaveClassDialogOpen, setLeaveClassDialogOpen] = React.useState(false)

  function openLeaveClassDialog() {
    setLeaveClassDialogOpen(true)
  }

  function closeLeaveClassDialog() {
    setLeaveClassDialogOpen(false)
  }

  return (
    <div className={classes.dialogContent}>
      <Table className={classes.table} size='small' stickyHeader={true} aria-label='a dense table'>
        <TableHead className={classes.tableHead}>
          <TableRow style={{height: ROW_HEIGHT}}>
            <TableCell>{'Class Name'}</TableCell>
            <TableCell>{'Mission Pack'}</TableCell>
            <TableCell style={{width: 330}}>
              <div style={{ display: 'flex', alignItems:'center', gap: 5 }}>
                {'Seats Taken / Licensed'}
                {fetchFloatingLicenseSeatsResponse.status === RESPONSE_STATUS.IN_PROGRESS && <CircularProgress size={14} style={{color: genContrastColor(0.7)}}/>}
              </div>
            </TableCell>
            <TableCell align='center'>{'Activate'}</TableCell>
            <TableCell style={{borderTopRightRadius: 3}} align='center'>{'Delete'}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groups.map((group, index) => <ClassesTableRow key={group.id} group={group} index={index} openLeaveClassDialog={openLeaveClassDialog}/>)}
        </TableBody>
      </Table>
      <LoginErrorHeader />
      <LeaveClassDialog open={leaveClassDialogOpen} handleClose={closeLeaveClassDialog}/>
    </div>
  )
}

export { ClassesDialogContent }