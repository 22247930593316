import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core'
import { LoginActions, useLogin } from './contexts/LoginContext'
import { useMission, MissionActions } from './contexts/MissionContext'

const useStyles = makeStyles(theme => ({
  contentContainer: {
    textAlign: 'center',
  },
  logoBanner: {
    width: '50%',
    padding: theme.spacing(1),
  },
  formFields: {
    maxWidth: '25em',
    width: '100%',
  },
  optionFieldsContainer: {
    maxWidth: '25em',
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionField: {
    fontSize: theme.typography.caption.fontSize,
    textTransform: 'none',
    verticalAlign: 'text-top',
    paddingTop: theme.spacing(1),
  },
  signInButtonContainer: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  forgotPasswordButton: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  signInButton: {
    maxWidth: '25em',
    width: '100%',
    backgroundColor: '#db4437',
  },
  dividerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dividers: {
    maxWidth: '10em',
    width: '100%',
    flexShrink: 'inherit',
  },
  loginProviderList: {
    margin: '1em 0',
    padding: '0 24px',
  },
  defaultCursor: {
    cursor: 'default',
  },
  footer: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '100%',
    height: '5em',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const SecureLogoutUserInfo = ({ userEmail }) => {
  const formatEmail = () => {
    const splitEmail = userEmail.split('@')
    if (splitEmail.length === 2) {
      return splitEmail[0]
    }
    return userEmail
  }

  return (
    <DialogContentText>{`Logged in as ${formatEmail()}`}</DialogContentText>
  )
}

export const LogoutComponent = ({ open, onClose }) => {
  const classes = useStyles()
  const [loginState, loginDispatch] = useLogin()
  const [, missionDispatch] = useMission()
  const userEmail = loginState?.user?.email ?? 'unknown'
  const preventDefault = event => event.preventDefault()

  return (
    <Dialog open={open}>
      <DialogTitle className={classes.contentContainer}>
        <div>
          <Typography variant='h5'>Log Out</Typography>
        </div>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        {/* This is super specific because atlo is the only 'secured' client. In the future there should be a way to tell from loginState whether content is secured. */}
        {!!loginState?.user?.ltiConsumerKey ?
          <SecureLogoutUserInfo userEmail={userEmail}/>:
          <DialogContentText>Logged in as <Link color='inherit' underline='always' href={'mailto:' + userEmail} onClick={preventDefault}>{userEmail}</Link></DialogContentText>}
        <DialogContentText>Are you sure you want to log out?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            !!onClose && onClose()
          }}
          color='primary'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            loginDispatch({ type: LoginActions.LOGOUT })
            missionDispatch({ type: MissionActions.SET_INITIAL_LOAD_DONE, initialLoadDone: false })
            !!onClose && onClose()
          }}
        >
          Log Out
        </Button>
      </DialogActions>
    </Dialog>
  )
}
