import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Markdown from './FiriaMarkdown'
import explode from './utils/Explosion'
import Confetti from 'react-confetti'
import { useMission, MissionActions, LessonContentViews } from './contexts/MissionContext'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import RegionalDialog from './RegionalDialog'
import { PositionedPanel } from './PositionedPanels'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Slide from '@material-ui/core/Slide'
import { iterateActiveMissionIndex } from './content-manager/content-director/content-director-use-cases/iterateActiveMissionIndex'
import { ValidatedAssignmentSubmissionDialog } from './AssignmentSubmissionPanels/ValidatedAssignmentSubmissionDialog'
import { ReminderAssignmentSubmissionDialog } from './AssignmentSubmissionPanels/ReminderAssignmentSubmissionDialog'
import { useContentDirector } from './contexts/ContentDirectorContext'
import { isNextMissionManuallyLocked } from './content-manager/content-director/content-director-use-cases/isMissionManuallyLocked'

const useStyles = makeStyles((theme) => {
  return ({
    bar: {
      display:'flex',
      flexDirection:'column',
      justifyContent:'space-between',
      padding: 4,
      overflowY: 'auto',
      height:'100%',
    },
    barItem:{
      display:'flex',
      flexDirection:'column',
    },
    objectiveButtons: {
      minWidth: 0,
      width:51,
      height:32,
      marginBottom:2,
    },
    infoButton: {
      marginBottom: '0.5em',
    },
    doneIcon: {
      color: 'green',
      paddingRight: '0.2em',
    },
    missionButton: {
      display: 'flex',
      flexDirection: 'column',
    },
    positionedPanel: {
      position: 'absolute',
      zIndex: 1500,
      top: 5,
      maxHeight:'33%',
      left:'50%',
      pointerEvents:'none',
    },
    bannerContent: {
      borderRadius: 3,
      padding: '5px',
      width:292,
      backgroundColor: theme.palette.type === 'dark' ? '#595858' : '#E6E6E6',
      filter: 'drop-shadow(0 2px 10px rgba(0,0,0,0.7))',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    bannerButton: {
      pointerEvents:'auto',
      height: 35,
      width: 106,
      borderRadius: 3,
      justifyContent:'space-between',
    },
    bannerButtonLabel: {
      display:'flex',
      flexDirection: 'row',
      justifyContent:'flex-start',
      alignItems:'center',
    },
    dialogButtons: {
      display: 'flex',
      flexDirection:'row',
      alignItems:'center',
      justifyContent:'flex-end',
    },
    bannerIcon: {
      fontSize: 25,

    },
    buttonContent: {
      '-webkit-filter': `drop-shadow( 0.1rem 0.1rem 0 ${theme.palette.type === 'dark' ? '#000' : 'white'} )`,
      display:'flex',
      flexDirection: 'row',
      alignItems:'center',
      justifyContent:'center',
      position:'absolute',
      left:6,
    },
    bannerText: {
      width: 170,
      fontSize: 15,
      '-webkit-filter': `drop-shadow( 0.1rem 0.1rem 0 ${theme.palette.type === 'dark' ? '#000' : 'white'} )`,
    },
    completionDialog: {
      display:'flex',
      zIndex: 100,
      top:'-5%',
      maxWidth:'76%',
      margin:'0px !important',
      overflow:'hidden',
    },
  })
})

export const ConfettiContainer = (props) => {
  const childrenRef = React.useRef(null)
  const isMounted = React.useRef(false)
  const confettiDimensionsRef = React.useRef({height:0, width:0})
  const [runConfetti, setRunConfetti] = React.useState(false)

  React.useEffect(() => {
    const triggerConfetti = () => {
      if (childrenRef.current !== null){
        let rect = childrenRef.current.getBoundingClientRect()
        confettiDimensionsRef.current = {height:rect.height, width:rect.width}
      }
      // console.log(confettiDimensionsRef.current)
      if (isMounted.current){
        setRunConfetti(true)
      }
    }
    if (props.run){
      // Without setTimeout getBoundingClientRect() returns bad position data
      setTimeout(triggerConfetti, 0)
    } else {
      setRunConfetti(false)
    }
  }, [props.run])

  React.useEffect(()=>{
    isMounted.current = true
    return ()=>{
      isMounted.current = false
    }
  } ,[])

  return (
    <>
      <div style={{position:'relative', overflow:'visible'}}>
        <Confetti
          width={props.run ? confettiDimensionsRef.current.width : 0}
          height={props.run ? confettiDimensionsRef.current.height : 0}
          recycle={false}
          gravity={0.2}
          {...props}
          run={runConfetti}
        />
      </div>
      <div ref={childrenRef}  style={{overflow:'auto'}}>
        {props.children}
      </div>
    </>
  )
}

export function CompletionBanner({ missionState, onBannerContinue, objectiveNumber, completionBannerVisible, setCompletionBannerVisible }) {
  const classes = useStyles()
  const lastFlowData = React.useRef({'id': null, 'completed': null})
  const bannerContainerRef = React.useRef(null)

  const currentFlowId = missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.id
  const flowCompleted = missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.progress?.completed
  const flowType = missionState.activeMission?.flow[missionState.ui.curFlowIndex]?.type

  React.useEffect(() => {
    if (lastFlowData.current.id === currentFlowId && lastFlowData.current.completed !== flowCompleted){
      setCompletionBannerVisible(true)
    } else {
      setCompletionBannerVisible(false)
    }
    lastFlowData.current.id = currentFlowId
    lastFlowData.current.completed = flowCompleted
  }, [currentFlowId, flowCompleted, flowType, setCompletionBannerVisible])

  const calcShift = (width) => {
    // If you want to use 3d as the panel anchor, add 30
    // to this equation to account for the mission bar width
    return width/2
  }

  const entered = () => {
    if (bannerContainerRef.current) {
      const pos = bannerContainerRef.current.getBoundingClientRect()
      const halfHeight = pos.height/2
      explode(pos.x , pos.y + halfHeight, 350, 30)
      explode(pos.x + pos.width, pos.y + halfHeight, 350, 30)
    }
  }

  const visible = !(missionState.ui.focusedContent === LessonContentViews.COMPLETION_MESSAGE || !completionBannerVisible || missionState.ui.isExploreMode)
  return (
    <>
      <ValidatedAssignmentSubmissionDialog completionBannerVisible={completionBannerVisible} onBannerContinue={onBannerContinue}/>
      <ReminderAssignmentSubmissionDialog onContinue={onBannerContinue}/>
      <PositionedPanel
        className={classes.positionedPanel}
        style={{
          display: !visible ? 'none' : null,
        }}
        direction={'left'}
        anchorEl={document.getElementById('sim')}
        open={completionBannerVisible}
        timeout={{enter: 200, exit: 200}}
        onEntered={entered}
      >
        <div ref={bannerContainerRef} style={{position:'relative', right: calcShift(292)}} >
          <div className={classes.bannerContent}>
            <div className={classes.buttonsContainer} style={{gap:5}}>
              <div className={classes.bannerText}>
                {`Objective ${objectiveNumber} Completed!`}
              </div>
              <Button
                className={classes.bannerButton}
                onClick={async () => await onBannerContinue()}
                classes={{label: `${classes.bannerButton} ${classes.bannerButtonLabel}`}}
                variant='outlined'
              >
                <div className={classes.buttonContent}>
                  <div style={{fontSize: 15}}>Continue</div>
                  <ArrowRightIcon className={classes.bannerIcon} />
                </div>
              </Button>
              {/* <Button
              onClick={()=>missionDispatch({ type: MissionActions.CHANGE_VIEW, view: LessonContentViews.COMPLETION_MESSAGE })}
              className={classes.bannerButton}
              variant='outlined'
            >
              Next
            </Button> */}
            </div>
          </div>
        </div>
      </PositionedPanel>
    </>
  )
}

export function CompletionPanelDialogContainer(props) {
  const classes = useStyles()
  return (
    <RegionalDialog
      containerId={'3d'}
      open={props.open}
      onClose={props.onNext}
      PaperProps={{
        square: true,
        classes: { root: classes.completionDialog },
      }}
      // style={{backgroundColor: 'red'}}
      BackdropProps={{ style: { position: 'absolute', backgroundColor: props.darkBackground ? 'rgba(0,0,0,0.7)' : null } }}
      TransitionComponent={Slide}
      TransitionProps={{
        direction:'down',
      }}
    >
      {props.children}
    </RegionalDialog>
  )
}

export default function CompletionPanel(props) {
  const [missionState, missionDispatch] = useMission()
  const { assignment } = useContentDirector()
  const [focusedCompletionMessage, setFocusedCompletionMessage] = React.useState(null)
  const [content, setContent] = React.useState({title: '', md: '', content: ''})
  const continueUsed = React.useRef(true)
  const selectedFlowCompletesMission = missionState.ui.curFlowIndex+1 > missionState.activeMission.flow.length-1
  const curIndex = missionState.activeMissionPack.missions.findIndex(msn => msn.id === missionState.ui.activeMissionId)
  const selectedMissionCompletesPack = missionState.activeMissionPack.missions.length <= curIndex + 1

  React.useEffect(() => {
    if (missionState.ui.focusedContent === LessonContentViews.COMPLETION_MESSAGE){
      if (assignment?.totalSubmissions === 0) {
        setFocusedCompletionMessage('assignment')
      } else {
        setFocusedCompletionMessage('flow')
      }
      continueUsed.current = false
    } else {
      setFocusedCompletionMessage(null)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionState.ui.focusedContent])

  React.useEffect(() => {
    if (focusedCompletionMessage === 'flow' && !selectedFlowCompletesMission){
      const elt = document.getElementById(`objective-btn-${missionState.ui.curFlowIndex+1}`)
      if (elt) {
        const pos = elt.getBoundingClientRect()
        explode(pos.x + pos.width / 2, pos.y + pos.height / 2)
      }
    }
  }, [focusedCompletionMessage, missionState.ui.curFlowIndex, selectedFlowCompletesMission])

  const onNext = () => {
    var newPanel = null
    if (focusedCompletionMessage === 'assignment') {
      newPanel = 'flow'
    } else if (focusedCompletionMessage === 'flow' && selectedFlowCompletesMission){
      newPanel = 'mission'
    } else if (focusedCompletionMessage === 'mission'){
      if (selectedMissionCompletesPack){
        newPanel = 'pack'
      } else {
        iterateActiveMissionIndex()
        return
      }
    } else if (focusedCompletionMessage === 'pack'){
      missionDispatch({ type: MissionActions.VIEW_NOTHING })
      newPanel = null
    } else if (!continueUsed.current){
      continueUsed.current = true
      props.continueHandler()
    }

    setFocusedCompletionMessage(newPanel)
    if (!newPanel) {
      return
    }
    setContent(getContent(newPanel))
  }

  const flowTitle = `${missionState.activeMission.flow[missionState.ui.curFlowIndex]?.type === 'objective' ?
    'Objective ' + props.objectiveNumber : 'Quiz'} Completed!`
  const getContent = (visiblePanel = focusedCompletionMessage) => {
    var content = {}
    switch (visiblePanel) {
      case 'flow':
        content.title = flowTitle
        content.md =
        missionState.activeMission.flow[missionState.ui.curFlowIndex]?.obj.complete === undefined ?
          '' : missionState.activeMission.flow[missionState.ui.curFlowIndex].obj.complete
        content.buttonText = selectedFlowCompletesMission ? 'Complete Mission':'Next Objective'
        break
      case 'mission':
        content.title =
            <p style={{margin:0}}>
              <em>{`Completed Mission ${props.curMissionNumber}:`}</em>
              <span style={{marginLeft:'12px'}}>{missionState.activeMission.title}</span>
            </p>

        content.md = missionState.activeMission.complete
        content.buttonText = selectedMissionCompletesPack ? 'Complete Mission Pack': isNextMissionManuallyLocked() ? 'Next Mission Coming Soon!' : 'Next Mission'
        break
      case 'pack':
        content.title = ''// missionState.activeMissionPack.title + ' Complete!'
        content.md = missionState.activeMissionPack.complete
        content.buttonText = 'Close'
        break
      case 'assignment':
        content.title = 'Don\'t forget to submit your assignment!'
        content.md = 'You\'ve got an assignment without a submission on this objective. \n\n Are you sure you want to continue?'
        content.buttonText = 'Continue Anyway'
        break
      default:
        content.title = ''
        content.md = ''
        content.buttonText = ''
        break
    }
    return content
  }

  const open = !!(focusedCompletionMessage !== null)
  React.useEffect(() => {
    if (open) {
      setContent(getContent())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <CompletionPanelDialogContainer
      open={open}
      onClose={onNext}
      darkBackground={props.fsMode}
    >
      <ConfettiContainer
        run={focusedCompletionMessage === 'mission'}
      >
        <div style={{overflow:'hidden'}}>
          <DialogTitle style={{ paddingBottom: 0 }}>
            {content.title}
          </DialogTitle>
          <DialogContent style={{maxHeight: 600, overflowY: 'auto'}}>
            <Markdown>
              {content.md}
            </Markdown>
          </DialogContent>
          <DialogActions>
            {(focusedCompletionMessage === 'mission' && isNextMissionManuallyLocked()) || (missionState.activeMission.flow[missionState.ui.curFlowIndex]?.type !== 'quiz' && focusedCompletionMessage === 'flow') || focusedCompletionMessage === 'assignment'?
              <Button
                onClick={()=>missionDispatch({ type: MissionActions.CHANGE_VIEW, view: props.fsMode ? LessonContentViews.FLOW : LessonContentViews.NOTHING })}
                variant='outlined'
              >
                  Close
              </Button>: null
            }
            <Button
              onClick={onNext}
              disabled={focusedCompletionMessage === 'mission' && isNextMissionManuallyLocked()}
              variant='outlined'
            >
              {content.buttonText}
            </Button>
          </DialogActions>
        </div>
      </ConfettiContainer>
    </CompletionPanelDialogContainer>
  )
}