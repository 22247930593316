import { makeLessonSectionPlugin } from './makeLessonSectionPlugin'
import { lessonSectionPanelMarkdownWrapper } from './lessonSectionPanelMarkdownWrapper'
import {
  ConceptPanel,
  CautionPanel,
  RunItPanel,
  DebugPanel,
  FilePanel,
  TypeInTheCodePanel,
  PeekPanel,
  RemixPanel,
  CheckTheTrekPanel,
  TouchPanel,
  ConnectPeripheralPanel,
} from './LessonSectionMarkdownPanels'

const LESSON_SECTIONS = [
  { name: 'concept', component: ConceptPanel },
  { name: 'caution', component: CautionPanel },
  { name: 'run', component: RunItPanel },
  { name: 'debug', component: DebugPanel },
  { name: 'file', component: FilePanel },
  { name: 'typeInTheCode', component: TypeInTheCodePanel },
  { name: 'peek', component: PeekPanel },
  { name: 'remix', component: RemixPanel },
  { name: 'checkTheTrek', component: CheckTheTrekPanel },
  { name: 'touch', component: TouchPanel },
  { name: 'connectPeripheral', component: ConnectPeripheralPanel },
]


/*
Here's an example of rendering a concept plugin from the above array in FiriaMarkdown:

--Concept title={test concept title}--
## Here is some concept content!!
--/Concept--

Anything between the opening and closing tags be passed into the component as the "content" prop.
You can supply any arguments, just remember the content within the argument brackets are passed in as a string.
It's not particularly robust, treat it nicely or upgrade it.

If you don't need content in your panel, you can self close the opening tag like this:
--File title={FileName}/--
*/

function formatLessonSectionPanels() {
  const panels = {}
  for (const section of LESSON_SECTIONS) {
    panels[section.name] = lessonSectionPanelMarkdownWrapper(section.component)
  }
  return panels
}

function formatLessonSectionPlugins() {
  return LESSON_SECTIONS.map(({ name }) => makeLessonSectionPlugin(name))
}

const LESSON_SECTION_PLUGINS = formatLessonSectionPlugins()
const LESSON_SECTION_PANELS = formatLessonSectionPanels()

export { LESSON_SECTION_PANELS, LESSON_SECTION_PLUGINS }