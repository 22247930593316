// NOTE: the `name` field is also used to identify both the Firestore's collection name,
// as well as the Firebase Storage folder name. So, make sure to adhere to those naming rules.
// Specifically, NO SPACES.

const codeFilesDatabase = {
  name: 'code_files',
  version: 1,
}
const userFilesDatabase = userUid => ({
  name: 'users/' + (userUid ?? ''),
  version: 1,
})
const loggedOutDatabase = {
  name: 'noLogin',
  version: 1,
}

export {
  codeFilesDatabase,
  userFilesDatabase,
  loggedOutDatabase,
}