/* CodeBotModelController: In main thread, uses shared memory to let a worker thread interface to a CodeBot model.
   * The worker is started/terminated for each run.
*/

/* eslint-disable no-undef */
import * as BABYLON from '@babylonjs/core'
import CodebotHWschema from './CodebotHWschema'
import { getPickedColor, PerformanceTicker, radAdd, radDiff, registerMeshColliders } from '../utils/BabylonUtils'
import { simScene } from '../SimScene'
import { UartCmd, UARTBuffer } from '../Busio'
import { validatorController } from '../ValidatorControl'

const LEFT = 0
const RIGHT = 1
// const PROX_RANGE_MAX = 5.0  // Maximum proximity sensor range (decimeters)

export default class CodeBotModelController {
  // Control a CodeBotModel via SharedArrayBuffer representing HW state
  // An instance of this class persists while the CodeBot model exists.
  // With just a single 'bot in the first version of botsim, there's a singleton instance of this.
  constructor(model) {
    this.model = model
    this.scene = null
    this.simScene = simScene  // a reference just so model validators can access simScene (TODO: better, direct way to do this.)
    this.createSharedArrayBuffer()
    this.userCodeIsRunning = false  // Run state. Usually True because REPL also counts as "running".
    this.runCounter = 0  // How many "startup" events. Includes REPL starts. (meaning this is 2x user "run" actions)
    this.modelEventObservable = new Set()   // add/delete callbacks here: observer(changes, current)
    this.modelTrackChanges = new Map()      // collects changed values each tick, used to notify observers and update cached values.
    this.modelCollisionObservable = new Set()
    this.modelReady = false

    this.model.loadedObservable.add(this.modelLoadedObserver)
    simScene.pointerObservable.add(this.simPointerObserver)
    simScene.keyboardObservable.add(this.simKeyboardObserver)

    this.ctrKeyDown = false
    this.allowKeyboardButtons = true  // Allow keyboard 0/1 keys to operate CB buttons

    this.lastAudioUpdateMs = 0

    this.resetCachedValues()
    this.perf = new PerformanceTicker()
    this.uartBuffers = {}
    this.sleepTimeout = 0

    this.suspended = false  // Bot can be stopped by the environment (e.g. when an objective is hit)

    // Allow environments to override/bypass sensor readings via callbacks
    this.clearSensorOverrides()

    // window.encoderTrace = {}
  }

  clearSensorOverrides = () => {
    this.sensorOverrides = {
      prox: null,  // prox(num) returns 0-4095 sensor reading
      ls: null,    // ls(num) returns 0-4095 sensor reading
    }
  }

  suspendActivity = (doSuspend) => {
    // Allow validators to stop motors
    this.suspended = doSuspend
    if (doSuspend) {
      this.model.setMotors(0, 0)
    }
  }

  overrideMotors = (spdL, spdR) => {
    // Allow validators to set motors
    this.model.setMotors(spdL, spdR)
    Atomics.store(this.hw.motors, 0, spdL)
    Atomics.store(this.hw.motors, 1, spdR)
  }

  // At program startup we want a fresh clean sharedArray.
  // See also recreateSharedArrayBuffer()
  createSharedArrayBuffer = () => {
    this.sharedArray = new SharedArrayBuffer(1024)
    this.useSharedArrayBuffer()
  }

  // We don't want to risk our sharedArray getting written to by a PREVIOUS worker thread
  // who is somehow still running (zombie?), BUT we also need to maintain CodeBot state
  // BETWEEN program runs. So, we switch to a COPY of the previous sharedArray
  // Assumption - That the docs are telling the truth:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/SharedArrayBuffer
  recreateSharedArrayBuffer = () => {
    this.sharedArray = this.sharedArray.slice()
    this.useSharedArrayBuffer()
  }

  useSharedArrayBuffer = () => {
    // initArrayView requires the sharedArray instance, meaning we need to re-init codebot schema
    this.hw = new CodebotHWschema(this.sharedArray)
  }

  modelLoadedObserver = (scene) => {
    if (scene) {
      this.scene = scene
      this.suspended = false
      this.clearSensorOverrides()

      // In case model is re-loaded while code is active, invalidate cache so it gets full update of state.
      this.resetCachedValues()
      this.sleepTimeout = 0

      if (this.model.doPhysics) {
        const body = this.model.cbBody.physicsImpostor

        // Hook Collisions with designated objects in current scene
        // Physics colliders require list of candidate objects to collide with
        // Assume scene colliders already loaded.
        const meshes = scene.getMeshesByTags('BotCollider')

        // Also support non-physics intersections ("mesh colliders") using ActionManager
        // https://doc.babylonjs.com/guidedLearning/createAGame/collisionsTriggers#player-and-collisions

        if (meshes.length > 0) {
          // const impostorCandidateList = meshes.map(mesh => mesh.physicsImpostor)
          const impostorCandidateList = []
          const meshColliderList = []
          for (const mesh of meshes) {
            if (mesh.physicsImpostor) {
              impostorCandidateList.push(mesh.physicsImpostor)
            } else {
              meshColliderList.push(mesh)
            }
          }

          // Register physics colliders
          body.registerOnPhysicsCollide(impostorCandidateList, (main, collided) => {
            this.onCollide(main, collided)
          })
          // Okay, wheels too.
          const leftWheel = this.model.leftWheel.physicsImpostor
          leftWheel.registerOnPhysicsCollide(impostorCandidateList, (main, collided) => {
            this.onCollide(main, collided)
          })
          const rightWheel = this.model.rightWheel.physicsImpostor
          rightWheel.registerOnPhysicsCollide(impostorCandidateList, (main, collided) => {
            this.onCollide(main, collided)
          })

          // Register mesh colliders
          const pcbMesh = body.object.getChildMeshes(false, m => m.id==='Plane.015_primitive0')?.[0]
          pcbMesh.actionManager = new BABYLON.ActionManager(scene)
          registerMeshColliders(pcbMesh, meshColliderList, this.onCollide)
          const battMesh = body.object.getChildMeshes(false, m => m.id==='Cube.030')?.[0]
          battMesh.actionManager = new BABYLON.ActionManager(scene)
          registerMeshColliders(battMesh, meshColliderList, this.onCollide)
          const leftWheelMesh = leftWheel.object.getChildMeshes(false, m => m.id==='Circle.008')?.[0]
          leftWheelMesh.actionManager = new BABYLON.ActionManager(scene)
          registerMeshColliders(leftWheelMesh, meshColliderList, this.onCollide)
          const rightWheelMesh = rightWheel.object.getChildMeshes(false, m => m.id==='instance of Circle.008')?.[0]
          rightWheelMesh.actionManager = new BABYLON.ActionManager(scene)
          registerMeshColliders(rightWheelMesh, meshColliderList, this.onCollide)
        }

        // Hook functions to check sensors after before and after physics calculations
        body.registerAfterPhysicsStep(this.onAfterPhysicsStep)
        body.registerBeforePhysicsStep(this.onBeforePhysicsStep)

        // Save wheel positions for modeling rotation
        this.wheelPos = [this.model.leftWheel.position.clone(), this.model.rightWheel.position.clone()]
        this.wheelRotation = [0, 0]  // Radians

        // Explicitly unregistering these physics callbacks since we have seen errors where they're being called
        // apparently after unload (cbBody is null)  https://rollbar.com/firia/BotSim/items/629/
        this.model.cbBody.onDisposeObservable.add((cbBody) => {
          const imp = cbBody.physicsImpostor
          if (imp) {
            console.warn('Caught a case where physicsImposter was not disposed.')
            imp.unregisterAfterPhysicsStep(this.onAfterPhysicsStep)
            imp.unregisterBeforePhysicsStep(this.onBeforePhysicsStep)
          }
        })
      }

      // Model is loaded and ready
      this.modelReady = true
    } else {
      // Model is unloaded from scene
      this.modelReady = false

      // Collisions are registered to model objects that already get disposed on unload.
    }
  }

  onCollide = (collider, collided) => {
    // Collider is CB impostor, collided is external impostor
    this.notifyCollisionObservers(collided.object)

    // A callback can be attached to a mesh for mutual notification.
    // This allows objects to react to bot collision independent of being hooked into the controller.
    if (collided.object.botCollision) {
      collided.object.botCollision(collider.object, this)
    }
  }

  readLs = async (num) => {
    if (this.sensorOverrides.ls) {
      return this.sensorOverrides.ls(num)
    }

    const ls = this.model.getLineSensorRay(num)

    // // Visualize Raycast
    // if (num === 0) {
    //   if (this.rayHelper) {
    //     this.rayHelper.dispose()
    //   }
    //   this.rayHelper = new BABYLON.RayHelper(ls)
    //   this.rayHelper.show(this.scene, BABYLON.Color3.Red)
    // }

    let sensorReflection = 4095  // default to no reflection

    // Note:
    //  We are casting a ray within the post-physics update callback since we want to update worker thread
    //  asap based on new sensor position. Unfortunately the 'scene' may not yet been updated, meaning it's possible
    //  our ray will intersect objects in their prior position. Only happens when objects are moving, which is usually
    //  not the case for surfaces you're line-detecting on top of. (sign boards typically just sit there!)
    //  However, the bot itself WILL be moving, and e.g. popping a wheelie can put the new raycast origin above the
    //  stale position of the PCB. So we either need to wait and cast the ray only after the scene has been updated,
    //  or live with the stale updates for fast-moving objects and workaround by filtering self-intersecting rays.

    const lsPickFilter = (mesh) => {
      // Filter results that hit the CodeBot itself (the latter approach mentioned above)
      // Note: This overrides the 'isPickable' flag, so it must be explicitly checked below.
      // Note: This predicate function is called for every mesh in the scene, not just the ones intersected by ray.
      //       I suppose that's so it can provide a pre-filter, faster than calculating geometry.
      if (!mesh.isPickable || BABYLON.Tags.MatchesQuery(mesh, this.model.uniqueId)) {
        return false
      }
      return true
    }

    const pickResult = this.scene.pickWithRay(ls, lsPickFilter)

    const color = await getPickedColor(pickResult)
    if (color) {
      // Convert color to 12-bit grayscale range (not human-eye corrected, just equal-weighted grayscale)
      // Note: Full reflection = 0; dark = 4095
      let reflectivity = (color.r + color.g + color.b) / (255 * 3)
      const distanceAttenuation = (1 - (pickResult.distance / 1.0))  // use linear falloff out to 10cm distance
      reflectivity *= distanceAttenuation
      sensorReflection = Math.trunc(4095 * (1 - reflectivity))
    } else {
      // console.log('No color found: ', pickResult)
    }

    // console.log("Sensor Reflection = ", sensorReflection)
    return sensorReflection
  }

  readProx = async (num) => {
    if (this.sensorOverrides.prox) {
      return this.sensorOverrides.prox(num)
    }

    // TODO: Re-enable this when we are using prox sensors in curriculum
    //       BUT first investigate lower CPU utilization approach!
    return 4095

    // const prox = this.model.getProximitySensorRay(num)

    // // if (num === 0)
    // // {
    // //   // Visualize Raycast
    // //   if (this.rayHelper) {
    // //       this.rayHelper.dispose()
    // //   }
    // //   this.rayHelper = new BABYLON.RayHelper(prox)
    // //   this.rayHelper.show(this.scene)
    // // }

    // const pickResult = this.scene.pickWithRay(prox)
    // const color = await getPickedColor(pickResult)
    // let sensorReflection = 4095  // default to no reflection
    // if (color) {
    //   // Convert color to 12-bit grayscale range (not human-eye corrected, just equal-weighted grayscale)
    //   // Note: Full reflection = 0; dark = 4095
    //   let reflectivity = (color.r + color.g + color.b) / (255 * 3)
    //   // Use linear falloff out to 50cm distance. Clamp max distance to PROX_RANGE_MAX to prevent round off error
    //   const distanceAttenuation = (1 - (pickResult.distance > PROX_RANGE_MAX ? PROX_RANGE_MAX : pickResult.distance) / PROX_RANGE_MAX)
    //   reflectivity *= distanceAttenuation
    //   sensorReflection = Math.trunc(4095 * (1 - reflectivity))

    //   // console.log("Sensor number = ", num)
    //   // console.log("Color = ", color)
    //   // console.log("Sensor Reflection = ", sensorReflection)
    //   // console.log("pickResult.distance = ", pickResult.distance)
    //   // console.log("distanceAttenuation = ", distanceAttenuation)
    //   // console.log("reflectivity = ", reflectivity)
    // }

    // return sensorReflection
  }

  updateWheelRotationTrack = () => {
    // Call after each physics tick to model wheel rotation based on linear position change
    // Note: this is not currently being used, since it's less accurate through turns (arcs are not accounted for)
    const wheelRadius = (this.model.WHEEL_DIA_MM / 100) / 2

    // Infer direction of rotation from applied motor speed
    const signL = Math.sign(this.model.targetVelocityL) || 1
    const signR = Math.sign(this.model.targetVelocityR) || 1

    const posL = this.model.leftWheel.position
    const distL = signL * BABYLON.Vector3.Distance(this.wheelPos[LEFT], posL)
    this.wheelPos[LEFT].copyFrom(posL)
    const rotL = distL / wheelRadius
    this.wheelRotation[LEFT] = radAdd(this.wheelRotation[LEFT], rotL)

    const posR = this.model.rightWheel.position
    const distR = signR * BABYLON.Vector3.Distance(this.wheelPos[RIGHT], posR)
    this.wheelPos[RIGHT].copyFrom(posR)
    const rotR = distR / wheelRadius
    this.wheelRotation[RIGHT] = radAdd(this.wheelRotation[RIGHT], rotR)

    // console.log(`distL=${distL}, rotL(delta)=${rotL}, wheelRotation[L]=${this.wheelRotation[LEFT]}`)
  }

  updateWheelRotationMesh = (side) => {
    // Call after each physics tick to model wheel rotation based on mesh rotation and angular velocity

    // TODO: Remove this after deterministic lockstep performance is verified on Chromebook
    // const deltaTime = this.scene.getEngine().getDeltaTime() / 1000

    const deltaTime = simScene.physicsPlugin.getTimeStep()  // Deterministic Lockstep discrete time slice

    // Estimate new rotation angle based on angular velocity
    const vel = this.model.getWheelAngularVelocity(side)
    const priorRot = this.wheelRotation[side]
    const estNewRot = radAdd(priorRot, vel * deltaTime)
    let newRot = this.model.getWheel(side).rotationQuaternion.toEulerAngles().x

    // Select which of the two possible rotations (x and 180-x) is closest
    const diff = radDiff(newRot, estNewRot)
    const diff180 = radDiff(Math.PI - newRot, estNewRot)
    newRot = Math.abs(diff) < Math.abs(diff180) ? newRot : Math.PI - newRot
    newRot = radAdd(newRot, 0)
    this.wheelRotation[side] = newRot
  }

  readEncoder = () => {
    // Update wheel encoder positions and slot counts.
    // Note: We calculate and store slot count for worker rather than storing actual encoder disc position
    //       and having the worker determine slot progress based on that. Previous version took the latter
    //       approach, using position/timestamp and angular velocity to track where the slot was and even
    //       yeilding analog values consistent with slot opening. This was very realistic, but depended on
    //       accurate time correlation between main thread and worker. Unfortunately on slow machines the
    //       "physics time" experienced by the worker is unpredictably different from clock time, so this
    //       approach had to be replaced. We know with certainty the prior and current encoder disc positions,
    //       so we can count slots here. A deliberate compromise is that the worker python code may see
    //       zero time delta between slots if it isn't checking the encoders quickly enough, or if the wheels
    //       are moving fast enough to see >1 slot in a physics update. This differs from real world where
    //       slots will be missed if not checked promptly. So the current approach optimizes for the common
    //       use case where the worker python code is continuously polling encoders, counting slots to
    //       determine the rotational speed of the wheels.

    // The getWheelRotationAngle() is not sufficient, so we are keeping track/updating wheel rotation here
    const priorRot = this.wheelRotation.slice()
    this.updateWheelRotationMesh(LEFT)
    this.updateWheelRotationMesh(RIGHT)

    this.updateSlotEdgeCounts(priorRot)
    // this.traceEncoder()
  }

  updateSlotEdgeCounts = (priorRot) => {
    // Track wheel encoder progress as # of light/dark edges crossed. Count is stored in this.hw.encSlots,
    // incremented here and decremented by worker thread as python code calls enc.read().

    // Cap accumulated edge counts. This limits how many edges will be reported "back-to-back" when the python code
    // is tardy in polling the encoders and finally reads them. At high wheel speed we might see about 2 edges in a physics
    // timeStep (16.67ms), so you can think of this as limiting how long to buffer updates before python starts to miss slots.
    const MAX_ACCUM_EDGE_COUNT = 4
    const stopThresh = 0.05  // rad/sec : less than this consider "stopped"
    const edgeSpan = Math.PI / 20
    const angVel = [this.model.getWheelAngularVelocity(LEFT), this.model.getWheelAngularVelocity(RIGHT)]
    const countAccumulator = [Atomics.load(this.hw.encSlots, 0), Atomics.load(this.hw.encSlots, 1)]

    for (let side = 0; side < 2; ++side) {
      if (Math.abs(angVel[side]) < stopThresh && countAccumulator[side] > 0) {
        // Zero-out edge counter when stopped
        Atomics.store(this.hw.encSlots, side, 0)
      } else if (countAccumulator[side] < MAX_ACCUM_EDGE_COUNT) {
        // Count slot edges between prior and current rotations

        // If cur-prior never crossed more than one edge in a single update we could simply look for differing sign
        // in a periodic sinusoid, increment the count, and that would be it.
        let slotCount = Math.cos(priorRot[side] * 20) * Math.cos(this.wheelRotation[side] * 20) < 0 ? 1 : 0

        // But in case it spans more than one edge, we must also add the number of whole edge crossings:
        slotCount += Math.floor(Math.abs(radDiff(priorRot[side], this.wheelRotation[side]) / edgeSpan))

        // Now we know how many slot edges were traversed since last update. Add it to accumulator.
        Atomics.add(this.hw.encSlots, side, slotCount)
      }
    }
  }

  traceEncoder = () => {
    // Trace left wheel encoder params for debug
    if (!this.motorsEnabled) {
      return
    }

    // Grab current values
    const tm = performance.now()
    const rot = this.wheelRotation[LEFT]
    const vel = this.model.getWheelAngularVelocity(LEFT)
    const pos = this.model.getWheel(LEFT).position

    const deltaTime = simScene.physicsPlugin.getTimeStep()

    window.encoderTrace.csv += `${tm}, ${deltaTime}, ${Math.round(rot * 180 / Math.PI)}, ${Math.round(vel * 180 / Math.PI)}, ${pos.x}, ${pos.y}, ${pos.z},\n`
  }

  readAccel = () => {
    // Get bot body Euler rotation x, y, z values
    const bodyRotation = this.model.getBodyRotation()

    // Calculate the gravitational accelerations about the x, y, and z axes
    const Ax = Math.round(-16384 * Math.sin(bodyRotation.z))
    const Ay = Math.round(-16384 * Math.cos(bodyRotation.z) * Math.sin(bodyRotation.x))
    const Az = Math.round(-16384 * Math.cos(bodyRotation.z) * Math.cos(bodyRotation.x))

    // Return the gravitational acceleration tuple
    return [Ax, Ay, Az]
  }

  uartCmd = (cmd, id, data) => {
    validatorController.handleUARTCmd(cmd, id, data)
    switch (cmd) {
      case UartCmd.INIT: // obj {tx, rx, baudrate, bits, parity, stopBits, timeout, rxBufSize}
        this.uartBuffers[id] = new UARTBuffer(data, this.model.peripherals, this.hw)
        break
      case UartCmd.DEINIT: // null
        delete this.uartBuffers[id]
        break
      default:
        this.uartBuffers[id].cmd(cmd, data)
        break
    }
  }

  // callback overridden by CodeRunner
  onReboot = () => { }

  // Pointer down event handler
  pointerDown = (pointerInfo) => {
    // Handle CodeBot button down events
    // First check to see if we have a hit
    if (pointerInfo.pickInfo.hit) {
      // If pointer is down on a button then set the button's on state
      // Set both is_pressed and was_pressed bits
      if (pointerInfo.pickInfo.pickedMesh.name === 'Cylinder.006') {
        Atomics.store(this.hw.button, LEFT, 3)
      } else if (pointerInfo.pickInfo.pickedMesh.name === 'Cylinder.004') {
        Atomics.store(this.hw.button, RIGHT, 3)
      } else if (pointerInfo.pickInfo.pickedMesh.name === 'Cylinder.002') {
        this.onReboot()
      }
    }
  }

  // Pointer up event handler
  pointerUp = (pointerInfo) => {
    // Handle CodeBot button up events
    // Button bitmask:
    //   bit 0 = is_pressed
    //   bit 1 = was_pressed

    // Use the Control key to simulate pressing both buttons at the same time
    if (this.ctrKeyDown === false) {
      // Clear is_pressed bit
      Atomics.and(this.hw.button, LEFT, 2)
      Atomics.and(this.hw.button, RIGHT, 2)
    }
  }

  // Pointer move event handler
  pointerMove = (pointerInfo) => {
  }

  // Pointer wheel event handler
  pointerWheel = (pointerInfo) => {
  }

  // Pointer pick event handler
  pointerPick = (pointerInfo) => {
  }

  // Pointer tap event handler
  pointerTap = (pointerInfo) => {
  }

  // Pointer double tap event handler
  pointerDoubleTap = (pointerInfo) => {
  }

  // SimScene pointer event observer handler
  simPointerObserver = (pointerInfo) => {
    // Switch on the pointer event type (DOWN, UP, MOVE etc.)
    switch (pointerInfo.type) {
      case BABYLON.PointerEventTypes.POINTERDOWN:
        // console.log("POINTER DOWN");
        this.pointerDown(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERUP:
        // console.log("POINTER UP");
        this.pointerUp(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERMOVE:
        // console.log("POINTER MOVE");
        this.pointerMove(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERWHEEL:
        // console.log("POINTER WHEEL");
        this.pointerWheel(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERPICK:
        // console.log("POINTER PICK");
        this.pointerPick(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERTAP:
        // console.log("POINTER TAP");
        this.pointerTap(pointerInfo)
        break
      case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
        // console.log("POINTER DOUBLE-TAP");
        this.pointerDoubleTap(pointerInfo)
        break
      default:
        // this.console.log("Unknown pointer event type")
        break
    }
  }

  // Keyboard key down event handler
  keyboardKeyDown = (kbInfo) => {
    if (kbInfo.event.key === 'Control') {
      this.ctrKeyDown = true
    } else if (kbInfo.event.key === '0' && this.allowKeyboardButtons) {
      Atomics.store(this.hw.button, LEFT, 3)
    } else if (kbInfo.event.key === '1' && this.allowKeyboardButtons) {
      Atomics.store(this.hw.button, RIGHT, 3)
    }
  }

  // Keyboard key up event handler
  keyboardKeyUp = (kbInfo) => {
    if (kbInfo.event.key === 'Control') {
      this.ctrKeyDown = false
    } else if (kbInfo.event.key === '0' && this.allowKeyboardButtons) {
      Atomics.and(this.hw.button, LEFT, 2)
    } else if (kbInfo.event.key === '1' && this.allowKeyboardButtons) {
      Atomics.and(this.hw.button, RIGHT, 2)
    }
  }

  // SimScene keyboard event observer handler
  simKeyboardObserver = (kbInfo) => {
    switch (kbInfo.type) {
      // Switch on the keyboard event type (DOWN and UP)
      case BABYLON.KeyboardEventTypes.KEYDOWN:
        // console.log("KEY DOWN: ", kbInfo.event.key);
        this.keyboardKeyDown(kbInfo)
        break
      case BABYLON.KeyboardEventTypes.KEYUP:
        // console.log("KEY UP: ", kbInfo.event.keyCode);
        this.keyboardKeyUp(kbInfo)
        break
      default:
        // console.log("Unknown keyboard event type")
        break
    }
  }

  onBeforePhysicsStep = (imposter) => {
    // Physics are about to be calculated, so we need to get the latest changes from worker that would
    // affect physics.
    // This gives the worker the max amount of time to respond to sensor changes (updated AfterPhysicsStep).
    // Any earlier wouldn't matter from the physics standpoint. However, if physics ticks are slow e.g. 33ms (30fps)
    // then we're limiting stuff like LEDs to a bit slower than needed. But if physics is running that slowly, then
    // probably you're on an underpowered machine, and thankful not to burn cycles on LEDs.

    if (this.model.cbBody === null) {
      // Babylon doesn't reliably unregister imposter callbacks on dispose. Catch and do that here.
      imposter.unregisterBeforePhysicsStep(this.onBeforePhysicsStep)
    } else {
      this.updateFromWorker()
    }
  }

  onAfterPhysicsStep = (imposter) => {
    // Called after each physics engine step. Check sensors.

    /* Currently checking all sensors every tick...
      * Considered Changing as Follows:
          Only check a sensor when user code has an outstanding read() operation on it.
          E.g. we only do raycast AFTER user invokes ls.read(), so a user call to ls.read()
          will yield until the next physics update.
          Benefits of this approach:
            1. Avoids performance hit of proactively checking all sensors every physics update.
            2. More realistic that Python code incurs delay in reading sensors, versus instantaneous return.
            3. Python code is awakened in sync with physics update, so can act immediately when new position
                is known rather than acting asynchronously on stale data.

          The above could be implemented by using the sleep mutex and adding a call 'requestUpdate' which the worker
          thread would invoke to register interest in a sensor value prior to taking the mutex. Then onPhysicsStep() would
          just have to read the sensors which had been requested, and give the mutex.

          Drawback of this approach:
          1. Physics update rate on a slow machine might be 30 Hz at best, so 33ms between updates. That's
              much larger a delay than sensor reads, and would unduly slow code execution.
    */

    // DEBUG: Track physics update rate
    // if (this.perf.tick()) {
    //   console.log(`PhysicsStep: ${Math.round(this.perf.hz)}Hz`)
    //   console.log(`FPS: ${Math.round(this.scene?.getEngine().getFps())}`)
    // }

    // Update atomics so worker thread sees physics changes ASAP
    if (this.model.cbBody === null) {
      // Babylon doesn't reliably unregister imposter callbacks on dispose. Catch and do that here.
      imposter.unregisterAfterPhysicsStep(this.onAfterPhysicsStep)
    } else {
      this.updatePhysicsToWorker()
    }
  }

  getPhysicsElapsedMs = () => {
    // Expose this for Validators, same way python code calculates it.
    return Number(Atomics.load(this.hw.physicsElapsedNs, 0)) / 1000000
  }

  notifyCollisionObservers = (mesh) => {
    this.modelCollisionObservable.forEach(observer => observer(mesh))
  }

  notifyModelEventObservers = () => {
    // Observers receive change map AND 'this' prior to updating with changed values.
    this.modelEventObservable.forEach(observer => observer(this.modelTrackChanges, this))
  }

  resetCachedValues = () => {
    // Cached values
    this.userLeds = null
    this.lsLeds = null
    this.proxLeds = null
    this.usbLed = null
    this.pwrLed = null
    this.motorL = null
    this.motorR = null
    this.motorsEnabled = null
    this.speakerDuty = null
    this.speakerFreq = null
  }

  startupActions = (resetHardware = true) => {
    // Conditionally initialize stuff when bot program starts
    // Turn off LEDs, motors, speaker
    if (resetHardware) {
      Atomics.store(this.hw.userLeds, 0, 0)
      Atomics.store(this.hw.lsLeds, 0, 0)
      Atomics.store(this.hw.proxLeds, 0, 0)
      Atomics.store(this.hw.usbLed, 0, 0)
      Atomics.store(this.hw.pwrLed, 0, 0)
      Atomics.store(this.hw.motors, 0, 0)
      Atomics.store(this.hw.motors, 1, 0)
      Atomics.store(this.hw.motors, 2, 0)
      Atomics.store(this.hw.speaker, 0, 0)
      Atomics.store(this.hw.speaker, 1, 0)
      Atomics.store(this.hw.button, 0, 0)
      Atomics.store(this.hw.button, 1, 0)
    }
    this.userCodeIsRunning = true
    this.runCounter++
    this.resetCachedValues()
  }

  terminalActions = () => {
    // Shut down stuff when bot program terminates (motors, speaker)
    this.userCodeIsRunning = false
    if (this.model.isLoaded) {
      this.model.setMotors(0, 0)
      this.model.setSpeaker(0, 0)
    }

    // Ensure the HWSchema gets updated too - the normal datapaths
    // won't work because we have shutdown the simulated program
    Atomics.store(this.hw.motors, 0, 0)
    Atomics.store(this.hw.motors, 1, 0)
    Atomics.store(this.hw.motors, 2, 0)
    Atomics.store(this.hw.speaker, 0, 0)
    Atomics.store(this.hw.speaker, 1, 0)
  }

  notifySleep = (ms) => {
    // Worker requests wakeup from sleep after ms elapsed
    this.sleepTimeout = ms
    // Will decrement timeout after each physics update, and awaken worker when it expires.
  }

  updatePhysicsToWorker = async () => {
    // Update worker thread based on physics-driven changes detected in main thread.
    // Non physics-driven changes such as button-presses are updated in other event-handlers as appropriate.

    // If BabylonJS physics is configured with a nonzero SubTimeStep (see SimScene.js) then what we really want is to
    // be notified when all the substeps are done. Unfortunately Babylon doesn't provide begin/end callbacks bracketing
    // the whole "subtime" loop. (it's in scene's advancePhysicsEngineStep() function BTW)
    // So here we peek at the _physicsTimeAccumulator to only perform updates when the final SubTimeStep has been run.
    const subTime = this.scene._physicsEngine.getSubTimeStep()
    if (subTime !== 0 && this.scene._physicsTimeAccumulator > 2 * subTime) {
      // We've completed a SubTimeStep, but not the last one.
      return
    }

    // Accumulate elapsd ms from physics engine's perspective
    const deltaTime = simScene.physicsPlugin.getTimeStep()  // Deterministic Lockstep discrete time slice (fractional seconds)
    Atomics.add(this.hw.physicsElapsedNs, 0, BigInt(Math.round(deltaTime * 1e9)))  // Scale to nanoseconds, store in UINT64 for atomic access

    this.readEncoder()

    // Check accelerometer [x, y, z]
    const accVal = this.readAccel()
    for (let i = 0; i < 3; ++i) {
      Atomics.store(this.hw.accel, i, accVal[i])
    }

    // TODO: await all promises at once!

    // Check line sensors
    for (let i = 0; i < 5; ++i) {
      const val = await this.readLs(i)
      Atomics.store(this.hw.lineSense, i, val)
    }

    // Check proximity sensors
    for (let i = 0; i < 2; ++i) {
      const val = await this.readProx(i)
      Atomics.store(this.hw.proxSense, i, val)
    }

    // Check sleep timeout (milliseconds)
    if (this.sleepTimeout) {
      const msDelta = Math.round(deltaTime * 1000)
      this.sleepTimeout -= msDelta
      if (this.sleepTimeout < msDelta / 2) {
        // Wakey, wakey.
        this.hw.controlSleep(0)
        this.sleepTimeout = 0
      }
    }
  }

  updateFromWorker = () => {
    // Update cbModel in main thread with any changes made by worker thread to CodebotHWschema

    if (!this.userCodeIsRunning || !this.model.isLoaded || !this.model.doPhysics || this.suspended) {
      return
    }

    const changes = this.modelTrackChanges
    changes.clear()

    const curUserLeds = Atomics.load(this.hw.userLeds, 0)
    if (curUserLeds !== this.userLeds) {
      changes.set('userLeds', curUserLeds)
      for (let i = 0; i < 8; ++i) {
        const isOn = curUserLeds & (1 << i)
        this.model.ledPower(this.model.userLeds[i], isOn)
      }
    }

    const curLsLeds = Atomics.load(this.hw.lsLeds, 0)
    if (curLsLeds !== this.lsLeds) {
      changes.set('lsLeds', curLsLeds)
      for (let i = 0; i < 5; ++i) {
        const isOn = curLsLeds & (1 << i)
        this.model.ledPower(this.model.lsLeds[i], isOn)
      }
    }

    const curProxLeds = Atomics.load(this.hw.proxLeds, 0)
    if (curProxLeds !== this.proxLeds) {
      changes.set('proxLeds', curProxLeds)
      for (let i = 0; i < 2; ++i) {
        const isOn = curProxLeds & (1 << i)
        this.model.ledPower(this.model.proxLeds[i], isOn)
      }
    }

    const curPwrLed = Atomics.load(this.hw.pwrLed, 0)
    if (curPwrLed !== this.pwrLed) {
      changes.set('pwrLed', curPwrLed)
      this.model.ledPower(this.model.pwrLed, Boolean(curPwrLed))
    }

    const curUsbLed = Atomics.load(this.hw.usbLed, 0)
    if (curUsbLed !== this.usbLed) {
      changes.set('usbLed', curUsbLed)
      this.model.ledPower(this.model.usbLed, Boolean(curUsbLed))
    }

    const curMotorsEnabled = Atomics.load(this.hw.motors, 2)
    if (curMotorsEnabled !== this.motorsEnabled) {
      changes.set('motorsEnabled', curMotorsEnabled)
      if (!curMotorsEnabled) {
        this.model.setMotors(0, 0)
      }
    }

    const curMotorL = Atomics.load(this.hw.motors, 0)
    if (curMotorL !== this.motorL) {
      changes.set('motorL', curMotorL)
    }

    const curMotorR = Atomics.load(this.hw.motors, 1)
    if (curMotorR !== this.motorR) {
      changes.set('motorR', curMotorR)
    }

    if (curMotorsEnabled && (changes.has('motorL') || changes.has('motorR') || changes.has('motorsEnabled'))) {
      this.model.setMotors(curMotorL, curMotorR)
    }

    const curSpeakerFreq = Atomics.load(this.hw.speaker, 0)
    if (curSpeakerFreq !== this.speakerFreq) {
      changes.set('speakerFreq', curSpeakerFreq)
    }

    const curSpeakerDuty = Atomics.load(this.hw.speaker, 1)
    if (curSpeakerDuty !== this.speakerDuty) {
      changes.set('speakerDuty', curSpeakerDuty)
    }

    if (changes.has('speakerFreq') || changes.has('speakerDuty')) {
      this.model.setSpeaker(curSpeakerFreq, curSpeakerDuty)
    }

    // Track accumulated changes
    if (changes.size > 0) {
      this.notifyModelEventObservers()
      const changeObj = Object.fromEntries(changes)
      Object.assign(this, changeObj)
    }
  }
}