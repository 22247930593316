/* Simulation scene management
   * Provides for loading/unloading entities from the scene.
   * Hooks entities into Babylon engine's render loop, and scene onReadyObservable.
*/

import React from 'react'
import SceneComponent from './SceneComponent'
import * as BABYLON from '@babylonjs/core'
import { getPickedColor } from './utils/BabylonUtils'
// import { default as Ammo } from 'ammo.js/builds/ammo'   // Now loading wasm version in index.html
import { merge } from 'lodash'

const devMode = process.env.NODE_ENV === 'development'
const enableDevelopmentLogs = devMode

// Local paths for GLB file decompression code (defaults to preview.babylonjs.com)
BABYLON.DracoCompression.Configuration =
{
  decoder: {
    wasmUrl: 'babylon/draco_wasm_wrapper_gltf.js',
    wasmBinaryUrl: 'babylon/draco_decoder_gltf.wasm',
    fallbackUrl: 'babylon/draco_decoder_gltf.js',
  },
}

BABYLON.SceneLoader.OnPluginActivatedObservable.add(function (loader) {
  if (loader.name === 'gltf') {
    // Override loader default options
    // https://doc.babylonjs.com/typedoc/classes/BABYLON.GLTFFileLoader#createInstances

    // Normally would be a good thing. Ran into an issue first when converting the Cafeteria
    // environment to using AssetContainer. The container.addAllToScene() worked but generated
    // many warnings as though BJS didn't like InstanceMesh's in the container. The following
    // gets rid of the warnings. Not sure about the impact to performance.
    // Ticket: https://forum.babylonjs.com/t/assetcontainer-throwing-warnings-with-instancedmesh/48857
    loader.createInstances = false  // Otherwise GLTF loader creates InstanceMesh objects
  }
})

// Currently this is a singleton
class SimScene {
  constructor() {
    this.entityList = new Set()
    this.scene = null
    this.pickObservable = new Set()  // add/delete callbacks here: cb(pickResult)
    this.pointerObservable = new Set()
    this.keyboardObservable = new Set()
    this.glowMeshes = new Map() // Mesh name to color map
    this.physicsPlugin = null
    this.sceneInitializedPromise = new Promise(resolve => this.resolveAfterInit = resolve)
  }

  sceneReadyPromise = () => {
    // return this.scene.whenReadyAsync()
    return this.sceneInitializedPromise
  }

  addEntity = async (entity) => {
    // console.log(`addEntity(${entity.constructor.name})`)
    if (this.entityList.has(entity)) {
      console.log('SimScene: tried to add duplicate Entity', entity, this.entityList)
      await entity.unload()
    }
    this.entityList.add(entity)
    if (this.scene) {
      await entity.load(this.scene)
      await this.updateShadows()
    }
  }

  removeEntity = async (entity) => {
    // console.log(`removeEntity(${entity.constructor.name})`)
    if (!this.entityList.has(entity)) {
      console.log('SimScene: tried to remove an Entity not in list', entity, this.entityList)
      return
    }
    // Remove from entityList
    this.entityList.delete(entity)
    await this.updateShadows()
    await entity.unload()
  }

  updateShadows = async () => {
    // Traverse entity list and register all shadowCasters / shadowGenerators
    // Aggregate all the casters and generators
    const entityArray = Array.from(this.entityList)
    const allCasters = entityArray.flatMap(cur => cur.getShadowCasters())
    const allGenerators = entityArray.flatMap(cur => cur.getShadowGenerators())

    // Add all casters to each generator
    await Promise.all(allGenerators.map(gen => new Promise(async (resolve) => {
      // First, clear any existing casters registered to this generator.
      gen.getShadowMap().renderList.length = 0
      await Promise.all(allCasters.map(cast => new Promise((resolve) => {
        gen.addShadowCaster(cast)  // recursively add this mesh and its descendants
        resolve()
      })))
      resolve()
    })))
  }

  onSceneReady = async (scene) => {
    this.scene = scene

    // Enable physics
    await window.Ammo()   // If using webpack bundled version, omit 'window.'

    this.physicsPlugin = new BABYLON.AmmoJSPlugin(0)  // useDeltaForWorldStep=false
    // this.physicsPlugin = new BABYLON.AmmoJSPlugin()  // If not using Determinstic Lockstep
    scene.enablePhysics(new BABYLON.Vector3(0, -9.8, 0), this.physicsPlugin)

    // SubTimeStep:
    // Compute physics at minimum 8ms rate (attempting to increase accuracy and decrease jitter)
    // Note: This doesn't change the real-time update rate. It just calls the physics engine's step(dt) function
    //   multiple times per update interval; as many times as can be accommodated by the update rate.
    //   Ex: I observe that at 30fps and a subTimeStep=8 (ms) Babylon's _advancePhysicsEngineStep() function
    //       loops 4 times in rapid succession, calling Ammo's _step(dt) function 4 times with dt=0.008 each time.
    //   Breaking updates into smaller chunks like this increases the fidelity of the physics simulation.

    // See https://forum.babylonjs.com/t/ammo-bullet-never-rests/14258 for more detail.
    // scene.getPhysicsEngine().setSubTimeStep(8)

    // Set resolution for physics calculations (performance vs accuracy)
    this.physicsPlugin.setTimeStep(1 / 60)   // Physics discrete step time in seconds (1/Hz)
    this.physicsPlugin.setFixedTimeStep(1 / 240)  // Incremental seconds to step within each timeStep.
    // Note: setFixedTimeStep defines smaller slices to increase accuracy within setTimeStep interval, which seems
    // like a physics-engine level version of what setSubTimeStep() does at the Babylon level...

    // Add a glow layer for emissive objects (specifically the LEDs)
    this.glLeds = new BABYLON.GlowLayer('glowLeds', scene, { mainTextureSamples: 2 })
    this.glLeds.isEnabled = true
    this.glLeds.intensity = 4.0

    // Add a glow layer for selective mesh objects in the glowMeshes map
    this.gl = new BABYLON.GlowLayer('glow', scene, { mainTextureSamples: 2 })
    this.gl.isEnabled = true
    this.gl.intensity = 2.0

    // Assign the callback for selective emissive coloring
    this.gl.customEmissiveColorSelector = this.emissiveColorSelector

    // We handle our own mute button
    BABYLON.Engine.audioEngine.useCustomUnlockedButton = true
    BABYLON.Engine.audioEngine.unlocked = false

    // Enable camera collisions
    scene.collisionsEnabled = true

    scene.onPointerDown = async (evt, pickResult) => {
      if (pickResult.hit) {
        // console.log("Scene: pickResult=", pickResult)

        if (enableDevelopmentLogs) {
          const colorPicked = await getPickedColor(pickResult)
          let lsVal = 4095
          if (colorPicked) {
            // Convert color to 12-bit grayscale range (not human-eye corrected, just equal-weighted grayscale)
            // Note: Full reflection = 0; dark = 4095
            let reflectivity = (colorPicked.r + colorPicked.g + colorPicked.b) / (255 * 3)
            const distanceAttenuation = 0.898
            reflectivity *= distanceAttenuation
            lsVal = Math.trunc(4095 * (1 - reflectivity))
          }
          console.log(`Scene: picked "${pickResult.pickedMesh.name}" @ ${pickResult.pickedPoint}, ls=${lsVal}, color=`, colorPicked)
        }

        // Notify observers
        this.pickObservable.forEach(cb => cb(pickResult))
      }
    }

    // Use this pointer observable to capture all pointer event information
    scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case BABYLON.PointerEventTypes.POINTERDOWN:
          // console.log("POINTER DOWN");
          break
        case BABYLON.PointerEventTypes.POINTERUP:
          // console.log("POINTER UP");
          break
        case BABYLON.PointerEventTypes.POINTERMOVE:
          // console.log("POINTER MOVE");
          break
        case BABYLON.PointerEventTypes.POINTERWHEEL:
          // console.log("POINTER WHEEL");
          break
        case BABYLON.PointerEventTypes.POINTERPICK:
          // console.log("POINTER PICK");
          break
        case BABYLON.PointerEventTypes.POINTERTAP:
          // console.log("POINTER TAP");
          break
        case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
          // console.log("POINTER DOUBLE-TAP");
          break
        default:
          // console.log("Unknown pointer event type")
          break
      }

      // Notify pointer event observers
      this.pointerObservable.forEach(cb => cb(pointerInfo))
    })

    // Use this keyboard observable to capture all keyboard event information
    scene.onKeyboardObservable.add((kbInfo) => {
      switch (kbInfo.type) {
        case BABYLON.KeyboardEventTypes.KEYDOWN:
          // console.log("KEY DOWN: ", kbInfo.event.key);
          break
        case BABYLON.KeyboardEventTypes.KEYUP:
          // console.log("KEY UP: ", kbInfo.event.keyCode);
          break
        default:
          // console.log("Unknown keyboard event type")
          break
      }

      // Notify keyboard event observers
      this.keyboardObservable.forEach(cb => cb(kbInfo))
    })

    // Resolve our local "scene ready AND initialized" promise
    this.resolveAfterInit()

    // Load any existing entities
    await Promise.all([...this.entityList].map(entity => new Promise(resolve => resolve(entity.load(scene)))))
    await this.updateShadows()
    await this.scene.whenReadyAsync()
  }

  onRender = async (scene) => {
    // Update entities on each render
    let needUpdateShadows = false
    await Promise.all([...this.entityList].map(entity => new Promise((resolve) => {
      entity.render(scene)
      needUpdateShadows = needUpdateShadows || entity.hasShadowModelChanged()
      resolve()
    })))

    if (needUpdateShadows) {
      await this.updateShadows()
    }
  }

  // The callback for selective emissive coloring on meshes in the map
  emissiveColorSelector = (mesh, subMesh, material, result) => {
    if (this.glowMeshes.has(mesh.name)) {
      const meshColor = this.glowMeshes.get(mesh.name)
      result.set(meshColor.r, meshColor.g, meshColor.b, meshColor.a)
    } else {
      result.set(0, 0, 0, 0)
    }
  }

  setActiveEnvironmentEntity = (env) => {
    this.activeEnvironmentEntity = env
  }

  setActiveTargetEntity = (player, controller) => {
    this.activeTargetEntity = player
    this.activeTargetController = controller
    if (this.activeEnvironmentEntity) {
      this.activeEnvironmentEntity.setTarget(player, controller)
    }
  }

  setSceneDefaults = () => {
    // Called prior to changing environments. Environments may override these defaults, but this gives a clean starting point.
    if (this.scene) {
      this.glowMeshes.clear()
      this.scene.ambientColor = new BABYLON.Color3(0.3, 0.3, 0.3)
      this.scene.fogMode = BABYLON.Scene.FOGMODE_NONE
      this.scene.environmentIntensity = 1.0
    }
  }
}
export const simScene = new SimScene()

const defaultPlayerAttributes = {
  initialPosition: new BABYLON.Vector3(0, 0, 0),
  initialRotation: Math.PI / 2,
  peripherals: [
    // type: 'SevenSegment',  // Must map to a factory type in PeripheralFactory.js
    // params: {},  // parameters specific to the peripheral (e.g. pins, placement location, etc.)
  ],
}

export class Entity {
  /* Base class for entities in the scene including player models, environments, and props.
     Special properties:
       this.defaultParams  // Environment entities define this to pre-populate the Curriculum Editor 'Scene Params'
       this.objectiveParams  // Environment entities: the params passed to setOptions() by the current Mission Objective
  */

  load = (scene) => {
  }
  unload = async () => {
  }
  render = (scene) => {
  }
  getRootMesh = () => {
    // If entity is mesh, return uppermost parent
    return null
  }
  hasShadowModelChanged = () => {
    return false
  }
  getShadowCasters = () => {
    return []
  }
  getShadowGenerators = () => {
    return []
  }
  getPlayerAttributes = (num) => {
    let attr = defaultPlayerAttributes

    if (this.objectiveParams?.bot?.length > num) {
      const bot = this.objectiveParams.bot[num]
      if (bot.playerPosition !== undefined) {
        bot.initialPosition = new BABYLON.Vector3(...bot.playerPosition)
      }
      if (bot.playerRotation !== undefined) {
        bot.initialRotation = bot.playerRotation * Math.PI / 180
      }
      attr = merge({}, defaultPlayerAttributes, bot)
    }
    return attr
  }
  setOptions = (params) => {
    this.objectiveParams = params
  }
  setVolume = (volumeParams) => {
    this.volumeParams = volumeParams
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController
  }
}

export default props => (
  <SceneComponent
    antialias

    // Deterministic Lockstep.
    // See: https://doc.babylonjs.com/features/featuresDeepDive/animation/advanced_animations#deterministic-lockstep
    // Allow up to 'lockstepMaxSteps' physics steps to recover accumulated delay due to late frame render time.
    engineOptions={{deterministicLockstep: true, lockstepMaxSteps: 4}}
    requiresSim={props.requiresSim}
    onSceneReady={simScene.onSceneReady}
    onRender={simScene.onRender}
    style={{ width: '100%', height: '100%' }}
    id={props.id}
  />
)