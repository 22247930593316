// Trinkets = Snacks for CodeBot to munch for score!
import * as BABYLON from '@babylonjs/core'
import {
  loadGLB, disposePromise,
} from '../../utils/BabylonUtils'
import SnackCrunchSound from '../../assets/HauntedHouse/sounds/SnackCrunch.mp3'
import CatTrinket from '../../assets/HauntedHouse/cat.glb'
import GhostTrinket from '../../assets/HauntedHouse/ghost.glb'
import PumpkinTrinket from '../../assets/HauntedHouse/pumpkin.glb'
import NanaimoTrinket from '../../assets/HauntedHouse/Nanaimo.glb'

/*
env params:
  'trinkets': [
    {
      pos: [5, 0, 5],
      rot: 0,
      scale: 1.0,
      scoreValue: 10,
      shape: null,   // null for random choice. Choices are: 'cat', 'ghost', 'pumpkin'
    },
*/

const trinketDefaults = {
  rot: null,    // random 0-360
  scale: 1.0,
  scoreValue: 10,
  shape: null,  // random
}

export class Trinkets {
  constructor(sounds) {
    this.sounds = sounds

    this.trinketMap = new Map([
      ['cat', CatTrinket],
      ['ghost', GhostTrinket],
      ['pumpkin', PumpkinTrinket],
      ['nanaimo', NanaimoTrinket],
    ])

    // Choose from these if none specified:
    this.randomSelection = [
      'cat', 'ghost', 'pumpkin'
    ]

    // Inject needed sound effects into the environments EnvTemplate.sound instance
    if (!sounds.options?.envSoundFx.some(s => s?.name === 'snackCrunchSound')) {
      sounds.options.envSoundFx.push(
        {name: 'snackCrunchSound', source: SnackCrunchSound, options: { spatialSound: false, volume: 1.0 }}
      )
    }
  }

  loadTrinket = async (index, shape, scoreValue, position, scaling, rotation) => {
    if (rotation === null) {
      rotation = Math.random() * 360
    }
    const model = this.trinketMap.get(shape) || this.trinketMap.get(this.randomSelection[Math.trunc(this.randomSelection.length * Math.random())])
    return await loadGLB(model, `trinket${index}_+${scoreValue}`, scaling, position, rotation)
  }

  load = async (params) => {
    let trinkets = []
    if (params.trinkets) {
      trinkets = params.trinkets.map((t, index) => {
        const trink = {...trinketDefaults, ...t}
        return this.loadTrinket(index, trink.shape, trink.scoreValue, trink.pos, trink.scale, trink.rot)
      })
    }
    this.meshes = await Promise.all(trinkets)
  }

  unload = async () => {
    await Promise.all(this.meshes.map(disposePromise))
  }

  configure = () => {
    const crunchSfx = this.sounds.cloneSfx('snackCrunchSound')

    this.meshes.forEach((m) => {
      if (m.name.startsWith('trinket')) {
        // Trinket is non-physics mesh, so tag colliding body as BotCollider
        const trinket = m.getChildMeshes(false, m => m.name === 'Circle' || m.name === 'Cat_primitive1' || m.name === 'Cube_primitive0')?.[0]
        trinket.name = m.name
        try {
          trinket.points = parseInt(m.name.split('_+')[1])
        } catch (err) {
          trinket.points = 1
        }

        BABYLON.Tags.AddTagsTo(trinket, 'BotCollider')
        trinket.botCollision = () => {
          // Play "crunch" sound and disappear
          crunchSfx.play()
          m.dispose()
        }
      }
    })
  }
}
