import React from 'react'
import { useSnackbar } from 'notistack'

import { DEVICES } from './device-flash-constants'
import { connectToDevice, flashDevice } from './fw-flasher/flash'

let snackId
let openDeviceFlasherDialog = () => {}
const DeviceFlasherContext = React.createContext()
function DeviceFlasherProvider({ children }) {
  const snacks = useSnackbar()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [selectedDeviceId, setSelectedDeviceId] = React.useState(null)
  const [progress, setProgress] = React.useState(null)
  const [connectionError, setConnectionError] = React.useState(null)
  const [upgradeError, setUpgradeError] = React.useState(null)
  const device = DEVICES[selectedDeviceId] ?? {}

  openDeviceFlasherDialog = (x) => {
    setDialogOpen(true)
    if (x) {
      setSelectedDeviceId(x)
    }
  }

  function clearUpgrade() {
    snacks.closeSnackbar(snackId)
    setConnectionError(null)
    setUpgradeError(null)
    setProgress(null)
  }

  function closeDialog() {
    clearUpgrade()
    setDialogOpen(false)
  }

  function goBack() {
    clearUpgrade()
    setSelectedDeviceId(null)
  }

  async function startUpgrade(fileName) {
    clearUpgrade()

    const { pid, vid } = device
    const connectionError = await connectToDevice(pid, vid)
    if (connectionError) {
      setConnectionError(connectionError)
      return
    }

    setProgress(0)
    const flashError = await flashDevice(() => setUpgradeError('The device unexpectedly disconnected'), setProgress, fileName)
    if (flashError) {
      setUpgradeError((x) => {
        if (!x) {
          return flashError
        }
        return x
      })
    }
  }

  const upgradeComplete = progress === 100 && upgradeError === null && connectionError === null
  const upgradeInProgress = progress !== null && !upgradeComplete && !upgradeError
  return (
    <DeviceFlasherContext.Provider value={{
      dialogOpen,
      device,
      selectedDeviceId,
      progress,
      upgradeError,
      connectionError,
      upgradeComplete,
      upgradeInProgress,
      setSelectedDeviceId,
      startUpgrade,
      closeDialog,
      goBack,
    }}>
      {children}
    </DeviceFlasherContext.Provider>
  )
}

function useDeviceFlasher() {
  return React.useContext(DeviceFlasherContext)
}

export { DeviceFlasherProvider, useDeviceFlasher, openDeviceFlasherDialog }