import React, { useRef } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import DescriptionIcon from '@material-ui/icons/Description'
import { useCodePanel, CodePanelActions } from './contexts/CodePanelContext'
import { useFileManagement } from './contexts/FileManagementContext'
import { useUserConfig } from './contexts/UserConfigContext'
import dark_vs from './dark_vs.json'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'
import SvgIcon from '@material-ui/core/SvgIcon'
import CreateIcon from '@material-ui/icons/Create'
import { FilePropertiesModal, WindowsButton } from './FileSystemModals'

const useStyles = makeStyles((theme) => {
  return ({
    closeButton: {
      '&:hover': {
        background: '#e80e23',
      },
      borderRadius: 0,
      height:29,
      width:47,
    },
    fileActionButtonContainer: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'flex-end',
    },
    dialogContainer:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems:'center',
      padding: 0,
    },
    tableDiv: {
      width:'100%',
      margin:0,
      padding:0,
    },
    bottomBarContainer: {
      display:'flex',
      flexDirection:'column',
      width:'100%',
      padding:10,
    },
    iconButton: {
      height:26,
      width:26,
      borderRadius: 0,
    },
    fileTypography:{
      paddingLeft:2,
      fontSize:14,
      fontWeight: 'lighter',
      userSelect: 'none',
      maxWidth:300,
    },
    treeRowContainer:{
      display:'flex',
      flexDirection:'row',
      alignItems:'center',
    },
    treeView:{
      height:350,
      paddingLeft:10,
      paddingTop:5,
      overflowY: 'scroll',
    },
  })
})

function MinusSquare(props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z' />
    </SvgIcon>
  )
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize='inherit' style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d='M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z' />
    </SvgIcon>
  )
}

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: '1px dashed',
  },
}))(props => <TreeItem {...props} />)

export default function DirectoryPanel({ open, setOpen }) {
  const [selectedRow, setSelectedRow] = React.useState()
  const [viewPropertiesForUid, setViewPropertiesForUid] = React.useState()
  const doubleClickTimer = useRef({ rowClicked: null, lastClicked: Date.now() })
  const skipDoubleClick = useRef(false)
  const componentMounted = useRef(null)
  const [, dispatch] = useCodePanel()
  const [fileManagementState] = useFileManagement()
  const [userConfigState] = useUserConfig()

  const handleClose = React.useCallback(() => {
    setSelectedRow(null)
    setOpen(false)
  }, [setOpen])

  const openFile = React.useCallback(async (uid) => {
    try {
      if (componentMounted.current) {
        await dispatch({ type: CodePanelActions.OPEN_TAB, fileUid: uid })
        handleClose()
      }
    } catch (err) {
      console.error(err)
    }
  }, [dispatch, handleClose])

  const handleRowClick = React.useCallback(async (uid) => {
    let currentTime = new Date().getTime()
    // Determines whether the user clicked the same row twice withing 500ms, checks whether the dialog was opened, in which case the double click is cancelled
    if (currentTime - doubleClickTimer.current.lastClicked < 500 &&
        doubleClickTimer.current.rowClicked === uid &&
        !skipDoubleClick.current) {
      await openFile(uid)
    } else {
      setSelectedRow(uid)
    }
    // Skip double click is required because a button press is considered a line selection. So if the user misses clicking
    // the button, and then clicks it correctly the second time, this prevents the file from being opened when the user
    // intended on opening the file properties modal.
    skipDoubleClick.current = false
    doubleClickTimer.current = {lastClicked: currentTime, rowClicked: uid}
  }, [openFile])

  const classes = useStyles()
  // Pass falsy value to start at user's root folder
  const renderTree = React.useCallback((uid) => {
    const { root, tree } = fileManagementState
    uid = uid ?? root

    const node = tree?.get(uid)
    return !!node?.tree ? (
      <StyledTreeItem
        key={uid}
        nodeId={uid}
        label={
          <Typography
            noWrap
            className={classes.fileTypography}>
            {node?.name}
          </Typography>}
      >
        {
          [...node.tree]
            .sort((a, b) => tree.get(a)?.name?.localeCompare(tree.get(b)?.name))
            .map(child => renderTree(child))
        }
      </StyledTreeItem>
    ) : (
      <StyledTreeItem
        key={uid}
        nodeId={uid}
        onClick={async () => await handleRowClick(uid)}
        onKeyDown={(ev)=>{
          if (ev.key === 'Enter'){
            openFile(uid)
          }
        }}
        label={
          <div className={classes.treeRowContainer} style={{gap:5}}>
            <Typography noWrap className={classes.fileTypography}>{tree?.get(uid)?.name}</Typography>
            <div>
              <IconButton onClick={() => {
                setViewPropertiesForUid(uid)
                skipDoubleClick.current=true
              }} className={classes.iconButton}>
                <CreateIcon style={{fontSize: 20}}/>
              </IconButton>
            </div>
          </div>
        }
      />
    )
  }, [classes.fileTypography, classes.iconButton, classes.treeRowContainer, fileManagementState, handleRowClick, openFile])

  React.useEffect(() => {
    componentMounted.current = true
    return () => {
      componentMounted.current = false
    }
  }, [])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={'sm'}
      PaperProps={{square: true}}
    >
      <div className={classes.dialogContainer}>
        <div></div>
        <div>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon style={{fontSize: 19}}/>
          </IconButton>
        </div>
      </div>
      <Divider style={{backgroundColor:dark_vs.colors['sideBarSectionHeader.border']}}/>
      <div style={{padding: 0}}>
        <div style={{backgroundColor:userConfigState.theme === 'dark' ? dark_vs.colors['editor.background']:null}}>
          <div className={classes.tableDiv}>
            <TreeView
              defaultCollapseIcon={<MinusSquare />}
              expanded={[fileManagementState.root]}
              defaultExpandIcon={<PlusSquare />}
              defaultEndIcon={<DescriptionIcon/>}
              className={classes.treeView}
            >
              {renderTree()}
            </TreeView>
          </div>
          <Paper square style={{backgroundColor:userConfigState.theme === 'dark' ? '#383838':null}}>
            <Divider style={{backgroundColor:dark_vs.colors['sideBarSectionHeader.border']}}/>
            <div className={classes.bottomBarContainer} style={{gap: 7}}>
              <div className={classes.fileActionButtonContainer} style={{gap: 10}}>
                <WindowsButton
                  onClick={()=>(openFile(selectedRow))}
                  label={'Open'}
                  disabled={!selectedRow}
                />
                <WindowsButton
                  onClick={handleClose}
                  label={'Close'}
                />
              </div>
            </div>
          </Paper>
        </div>
      </div>
      <FilePropertiesModal
        open={!!viewPropertiesForUid}
        close={() => {
          setSelectedRow(null)
          setViewPropertiesForUid(false)
        }}
        uid={viewPropertiesForUid}
      />
    </Dialog>
  )
}