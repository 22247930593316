// CodeBot Hardware Schema - common to both main and worker threads.
//   * The schema provides a view onto a SharedArrayBuffer

// Our ESlint "browser environment" doesn't include Atomics. TODO: better fix for this?
/* eslint-disable no-undef */

import { FileOperation, FileOpStatus, decodeFileStat, FILE_DATA_BUFFER_SIZE } from '../utils/file-operation-utils'

const MAX_NUM_BREAKPOINTS = 32

// Codebot sensors and actuators, exposed via ArrayBuffer interface
export default class CodebotHWschema {
  /* Usage with shared memory:
         Use Atomics.load(property, index) to read values
         USe Atomics.store(property, index, value) to write values
    */

  constructor(buf) {
    this.buf = buf   // shared array buffer
    this.ofs = 0     // incrementing byte offset into buf

    // Sensors
    this.encSlots = this.initArrayView(Int32Array, 2)  // [leftSlotEdgeCount, rightSlotEdgeCount]
    this.lineSense = this.initArrayView(Uint16Array, 5)
    this.proxSense = this.initArrayView(Uint16Array, 2)
    this.button = this.initArrayView(Uint16Array, 2)
    this.accel = this.initArrayView(Int16Array, 3)     // x,y,z

    // Actuators
    this.userLeds = this.initArrayView(Uint8Array, 1)
    this.lsLeds = this.initArrayView(Uint8Array, 1)
    this.proxLeds = this.initArrayView(Uint8Array, 1)
    this.usbLed = this.initArrayView(Uint8Array, 1)
    this.pwrLed = this.initArrayView(Uint8Array, 1)
    this.motors = this.initArrayView(Int8Array, 3)     // +-100% (LEFT, RIGHT, isEnabled)
    this.speaker = this.initArrayView(Uint16Array, 2)  // freq, duty

    // System
    // Note: Atomic sleep/wake only works for Int32Array types (per docs)
    this.doSleep = this.initArrayView(Int32Array, 1)  // 0 = wakeup, 1 = sleep

    // Internal engine params of use to worker
    this.physicsElapsedNs = this.initArrayView(BigUint64Array, 1)  // Elapsed nanosec from physics engine's perspective

    this.stdOutputBufQueued = this.initArrayView(Int32Array, 1)  // 0 = no buffer in queue, 1 = buffer in queue
    this.awaitingConsoleInput = this.initArrayView(Int32Array, 1)  // 0 = not awaiting input, 1 = awaiting input

    this.fileLength = this.initArrayView(Uint32Array, 1)
    this.fileData = this.initArrayView(Uint8Array, FILE_DATA_BUFFER_SIZE) // This is only a buffer - more data can be sent in multiple passes
    this.fileOpStatus = this.initArrayView(Int32Array, 1)

    this.seqBreakpoints = this.initArrayView(Uint32Array, 1)
    this.numBreakpoints = this.initArrayView(Uint32Array, 1)
    this.breakpoints = this.initArrayView(Uint32Array, MAX_NUM_BREAKPOINTS)

    this.consoleDataLength = this.initArrayView(Uint16Array, 1)
    this.consoleData = this.initArrayView(Uint16Array, 256) // TODO Decide on real max string length

    if (this.buf.byteLength < this.ofs) {
      console.error('CodebotHWmodel shared buf too small!')
    }
  }

  initArrayView = (arrayType, len) => {
    // Create an TypedArray instance and update byte offset.
    // Pad so that start offset is a multiple of BYTES_PER_ELEMENT (word alignment)
    if (this.ofs % arrayType.BYTES_PER_ELEMENT) {
      this.ofs += Math.trunc(this.ofs / arrayType.BYTES_PER_ELEMENT) + arrayType.BYTES_PER_ELEMENT
    }
    const arr = new arrayType(this.buf, this.ofs, len)
    this.ofs += arr.byteLength
    return arr
  }

  controlSleep = (doWake) => {
    // console.log("controlSleep=", doWake)
    Atomics.store(this.doSleep, 0, doWake)
    Atomics.notify(this.doSleep, 0)
  }

  waitSleep = () => {
    // Sleep until another thread changes doSleep from 1 to 0
    Atomics.store(this.doSleep, 0, 1)
    Atomics.wait(this.doSleep, 0, 1)
  }

  setStdoutBufQueued = (queueNonEmpty) => {
    const val = Number(!!queueNonEmpty)
    Atomics.store(this.stdOutputBufQueued, 0, val)
  }

  fileOpDone = (status) => {
    if (this.fileOpStatus[0] === FileOpStatus.IN_PROGRESS) {
      Atomics.store(this.fileOpStatus, 0, status)
      Atomics.notify(this.fileOpStatus, 0)
    } else {
      // this is to cover the case where the code thread is not already waiting
      // this can happen during a file read when not logged in
      var ctr = 0
      var intvl = setInterval(() => {
        ctr += 1
        if (this.fileOpStatus[0] === FileOpStatus.IN_PROGRESS) {
          clearInterval(intvl)
          Atomics.store(this.fileOpStatus, 0, status)
          Atomics.notify(this.fileOpStatus, 0)
        }
        if (ctr > 1000) {
          console.log('ERROR: code thread never changed status to IN_PROGRESS')
          clearInterval(intvl)
        }
      }, 1)
    }
  }

  sendFileData = (status, byteArray) => {
    Atomics.store(this.fileLength, 0, byteArray.length)
    for (let i = 0; i < byteArray.length; i++){
      Atomics.store(this.fileData, i, byteArray[i])
    }
    this.fileOpDone(status)
  }

  preFileOp = () => {
    Atomics.store(this.fileOpStatus, 0, FileOpStatus.NONE)
  }

  fileReadRequest = (postRPC, operation) => {
    let byteArray = []
    while (true) {
      // Sleep until another thread changes doSleep from 1 to 0
      Atomics.store(this.fileOpStatus, 0, FileOpStatus.IN_PROGRESS)
      Atomics.wait(this.fileOpStatus, 0, FileOpStatus.IN_PROGRESS)
      if (this.fileOpStatus[0] === FileOpStatus.PARTIAL_FILE_READ || this.fileOpStatus[0] === FileOpStatus.COMPLETE_FILE_READ) {
        for (let i=0; i < this.fileLength[0]; i++) {
          byteArray.push(this.fileData[i])
        }
      }
      if (this.fileOpStatus[0] !== FileOpStatus.PARTIAL_FILE_READ) {
        break
      }
      postRPC('fileRequestContinue', [])
    }
    if (this.fileOpStatus[0] === FileOpStatus.COMPLETE_FILE_READ && operation === FileOperation.OS_STAT) {
      return [FileOpStatus.OS_OP_SUCCESS, decodeFileStat(byteArray)]
    }
    return [this.fileOpStatus[0], byteArray]
  }

  fileActionRequest = () => {
    // Sleep until another thread changes doSleep from 1 to 0
    Atomics.store(this.fileOpStatus, 0, FileOpStatus.IN_PROGRESS)
    Atomics.wait(this.fileOpStatus, 0, FileOpStatus.IN_PROGRESS)
    return this.fileOpStatus[0]
  }

  // In the following code the data transfer portion is separate from
  // the signaling portion because I think it is likely to change

  setConsoleData = (consoleData) => {
    var index
    var length = consoleData.length
    Atomics.store(this.consoleDataLength, 0, length)
    for (index = 0; index < length; index++) {
      const code = consoleData.charCodeAt(index) // x00-xFFFF
      Atomics.store(this.consoleData, index, code)
    }
  }

  getConsoleData = () => {
    var index
    var result = ''
    var length = Atomics.load(this.consoleDataLength, 0)
    for (index = 0; index < length; index++) {
      const code = Atomics.load(this.consoleData, index) // x00-xFFFF
      result += String.fromCharCode(code)
    }
    Atomics.store(this.consoleDataLength, 0, 0)
    return result
  }

  indicateConsoleDataAvailable = () => {
    Atomics.store(this.awaitingConsoleInput, 0, 0)
    Atomics.notify(this.awaitingConsoleInput, 0)
  }

  awaitConsoleInput = () => {
    // Sleep until another thread changes awaitingConsoleInput from 1 to 0
    // (Sleep until another thread calls indicateConsoleDataAvailable())
    Atomics.store(this.awaitingConsoleInput, 0, 1)
    Atomics.wait(this.awaitingConsoleInput, 0, 1)
  }

  updateBreakpoints = (breakpoints) => {
    // Update breakpoints so worker thread can check them dynamically
    Atomics.add(this.seqBreakpoints, 0, 1)  // Incrementing sequence number to speed-up worker checks
    const num = Math.min(breakpoints.length, MAX_NUM_BREAKPOINTS)
    Atomics.store(this.numBreakpoints, 0, num)
    for (let i = 0; i < num; ++i) {
      Atomics.store(this.breakpoints, i, breakpoints[i])
    }
  }
}
