import { getUserSessionIdFromCookie } from './cookieOperations'
import { setUserSessionId, getUserSessionId, setCookieSessionIdStateOnSiteInitialization } from './userAuthStore'
import { setUserSessionIdCookie } from './cookieOperations'
import handleLogout from './handleLogout'
import handleSessionIdLogin from './handleSessionIdLogin'

const cookieIsUnique = (newSessionId) => {
  const userSessionId = getUserSessionId()
  // If the session cookie hasn't changed
  if (userSessionId === newSessionId) {
    return
  }

  // If the session id from cookie doesn't exist
  if (!newSessionId) {
    setUserSessionId(null)
    handleLogout()
    return
  }

  setUserSessionId(newSessionId)
  return true
}

const checkSessionIdURLParameter = async () => {
  const pathname = window.location.search ?? ''

  const keyText = '?jwt='
  const startIdx = pathname.indexOf(keyText)

  if (!(startIdx >= 0)) {
    return
  }

  const sessionId = pathname.slice(startIdx + keyText.length)
  window.history.replaceState({}, document.title, '/')
  // Here we're setting the browser cookie, and clearing our local storage of the last cookie,
  // that way when we check the browser cookie in the next function it'll be considered unique.
  setUserSessionIdCookie(sessionId)
  setUserSessionId(null)
}

const checkSessionIdCookie = async () => {
  const userSessionIdFromCookie = getUserSessionIdFromCookie()
  if (!cookieIsUnique(userSessionIdFromCookie)) {
    return
  }

  await handleSessionIdLogin()
}

var checkInProgress = false
const handleCheckSessionIdCookie = async () => {
  if (checkInProgress) {
    return
  }

  checkInProgress = true
  await checkSessionIdCookie()
  checkInProgress = false
}

var initialCheckCompleted = false
const initializeCookieSessionPoller = async () => {
  await handleCheckSessionIdCookie()
  if (!initialCheckCompleted) {
    setCookieSessionIdStateOnSiteInitialization()
    initialCheckCompleted = true
  }
}

const pollSessionIdCookie = async () => {
  await checkSessionIdURLParameter()
  initializeCookieSessionPoller()
  setInterval(handleCheckSessionIdCookie, 1000)
  document.addEventListener('visibilitychange', handleCheckSessionIdCookie)
}

export default pollSessionIdCookie