import { readContentSequence, readContentSequenceIndex } from '../content-director-store'
import { changeContentSequenceIndex } from './changeContentSequenceIndex'

async function iterateContentSequenceIndex() {
  const oldContentSequenceIndex = readContentSequenceIndex()
  const newContentSequenceIndex = oldContentSequenceIndex + 1
  const contentSequence = readContentSequence()

  if (contentSequence.length === newContentSequenceIndex) {
    return
  }

  changeContentSequenceIndex(newContentSequenceIndex)
}

export { iterateContentSequenceIndex }