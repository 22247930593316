// Audio Controls

import * as BABYLON from '@babylonjs/core'
import { codeBotCB2ModelOne } from './Players'

export function enableSound() {
  // Call this after user gesture (any UI interaction) to enable Web Audio API functions in Babylon.
  // Note: invoking this audioContext accessor will trigger onAudioUnlockedObservable registrants.
  const ctx = BABYLON.Engine.audioEngine.audioContext
  const audioState = ctx.state
  if (audioState !== 'running') {
    console.log('Unexpected audio state: ', audioState)
  }
}

export function setVolume(pct) {
  var vol = 1
  if (typeof pct === 'number' && pct <= 1 && pct >= 0) {
    vol = pct
  }
  BABYLON.Engine.audioEngine.setGlobalVolume(vol)
}

export function set3dSound(value) {
  codeBotCB2ModelOne.set3dSounds(value)
  return codeBotCB2ModelOne.useSpatialSound
}
