import { handleSessionExpired } from '../content-manager/user-session/user-session-use-cases/handleSessionExpired'
import { DATABASE_WORKER_ACTIONS } from './database.worker.actions'
import { codeFilesDatabase, userFilesDatabase, loggedOutDatabase } from './databases'
import { getUserId } from '../content-manager/user-session/user-session-use-cases/getUserId'
import { metadataDeserializer, metadataSerializer } from './jsonUtils'
// eslint-disable-next-line import/no-webpack-loader-syntax
import DatabaseWorker from 'worker-loader?filename=database.worker.js!../databaseworker/database.worker'
const databaseWorker = new DatabaseWorker()

/**
   *
   * @param {Object} action Object to send to WebWorker
   * @param {string} action.type Type of action (required)
   * @returns {Promise<any>} Data returned from WebWorker
   */
const sendMessage = action => new Promise((resolve, reject) => {
  const channel = new MessageChannel()
  channel.port1.onmessage = ({ data }) => {
    channel.port1.close()
    if (data.error) {
      const sessionExpired = handleSessionExpired(data.error)
      if (sessionExpired) {
        return resolve('')
      }
      console.error(data.error)
      reject(data.error)
    } else {
      resolve(data.result)
    }
  }
  databaseWorker.postMessage(action, [channel.port2])
})

function handleMetadataSerialization(content) {
  if (!content) {
    return
  }

  return JSON.stringify(content, metadataSerializer)
}

class LocalStorageController {
  constructor(database) {
    this.database = database
  }

  async readFile(fileId) {
    const resp = await sendMessage({
      type: DATABASE_WORKER_ACTIONS.READ_FILE,
      database: this.database,
      filename: fileId,
    })

    return resp
  }

  async readObject(fileId) {
    const resp = await sendMessage({
      type: DATABASE_WORKER_ACTIONS.READ_FILE,
      database: this.database,
      filename: fileId,
    })
    if (!!resp) {
      return JSON.parse(resp, metadataDeserializer)
    }
  }

  async saveFile(fileId, content, metadata=undefined) {
    let metadataPromise
    if (!!metadata) {
      metadataPromise= this.saveMetadata(metadata)
    }

    let savePromise = sendMessage({
      type: DATABASE_WORKER_ACTIONS.SAVE_FILE,
      database: this.database,
      filename: fileId,
      content: content,
    })
    return await Promise.all([ metadataPromise, savePromise ])
  }

  async saveObject(fileId, content, metadata=undefined) {
    return await sendMessage({
      type: DATABASE_WORKER_ACTIONS.SAVE_FILE,
      database: this.database,
      filename: fileId,
      content: JSON.stringify(content, metadataSerializer),
      metadata: handleMetadataSerialization(metadata),
    })
  }

  async deleteFile(fileId, metadata=undefined) {
    return await sendMessage({
      type: DATABASE_WORKER_ACTIONS.DELETE_FILE,
      database: this.database,
      filename: fileId,
      metadata: handleMetadataSerialization(metadata),
    })
  }

  async saveMetadata(metadata) {
    return await sendMessage({
      type: DATABASE_WORKER_ACTIONS.SAVE_METADATA,
      database: this.database,
      metadata: handleMetadataSerialization(metadata),
    })
  }

  async readMetadata() {
    const resp = await sendMessage({
      type: DATABASE_WORKER_ACTIONS.READ_METADATA,
      database: this.database,
    })
    if (!!resp) {
      return JSON.parse(resp, metadataDeserializer)
    }
  }

  async deleteDatabase() {
    return await sendMessage({
      type: DATABASE_WORKER_ACTIONS.DELETE_DATABASE,
      database: this.database,
    })
  }
}

const codeFileLocalStorageController = new LocalStorageController(codeFilesDatabase)
const loggedOutLocalStorageController = new LocalStorageController(loggedOutDatabase)
function getUserFilesLocalStorageController(uid) {
  return new LocalStorageController(userFilesDatabase(uid))
}
function getActiveUserFilesLocalStorageController() {
  const userId = getUserId()
  if (!userId) {
    throw new Error('userId required to get active user file controller')
  }
  return getUserFilesLocalStorageController(getUserId())
}

export {
  codeFileLocalStorageController,
  loggedOutLocalStorageController,
  getUserFilesLocalStorageController,
  getActiveUserFilesLocalStorageController,
}