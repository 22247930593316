import { fetchGroups } from './fetchGroups'
import { takeSeat } from './takeSeat'
import { DEFAULT_GROUP_ID } from './generateDefaultGroups'
import { readGroupFromStore } from '../group-store'
import { fetchLessonContent } from '../../lesson-content/lesson-content-use-cases'
import { presentOpenGroupsDialog } from '../group-presenter'
import { FREE_GROUPS } from './generateDefaultGroups'

async function initializeGroups() {
  const groupsData = await fetchGroups()

  let groupId = groupsData.lastActiveGroup ?? DEFAULT_GROUP_ID
  let group = readGroupFromStore(groupId)

  // If the user has been removed from their last active group
  if (!group) {
    groupId = DEFAULT_GROUP_ID
    group = readGroupFromStore(groupId)
  }

  await fetchLessonContent([group.missionPackId])
  const success = !(await takeSeat(groupId, true))
  if (!success) {
    await takeSeat(DEFAULT_GROUP_ID, true)
    presentOpenGroupsDialog()
  }

  const nonDefaultGroups = groupsData?.groups.filter(group => !FREE_GROUPS.find(freeGroup => group.id === freeGroup.id))
  if (nonDefaultGroups.length !== 0 && groupId === DEFAULT_GROUP_ID) {
    presentOpenGroupsDialog()
  }
  return async () => await fetchLessonContent(Object.keys(groupsData.missionPackIds))
}

export { initializeGroups }