import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
  },
  backdrop: {
    position: 'absolute',
  },
}))

// If container Id is supplied, make sure the container has position:'relative'
// without an id the dialog will use it's parent as a container.
const RegionalDialog = (props) => {
  const classes = useStyles()

  const parseProps = () => {
    const { containerId, onBackdropClick, disableBackdropClick, disableEscapeKeyDown, ...formattedProps } = props
    return formattedProps
  }

  return (
    <Dialog
      classes={{
        root: classes.root,
      }}
      BackdropProps={{
        classes: { root: classes.backdrop },
      }}
      style={{ position: 'absolute' }}
      disablePortal={!props.containerId}
      disableEnforceFocus
      container={() => document.getElementById(props.containerId)}
      {...parseProps()}
      onClose={(ev, reason) => {
        if (reason === 'backdropClick') {
          if (props.onBackdropClick) {
            props.onBackdropClick()
          }
          return
        }
        if (reason === 'escapeKeyDown' && props.disableEscapeKeyDown) {
          return
        }
        if (props.onClose) {
          props.onClose()
        }
      }}
    >
      {props.children}
    </Dialog>
  )
}

export default RegionalDialog