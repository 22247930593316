function parseURLForSharedFileId(){
  const pathname = window.location.search ?? ''

  const keyText = '?file='
  const startIdx = pathname.indexOf(keyText)

  if (!(startIdx >= 0)) {
    return
  }

  window.history.replaceState({}, document.title, '/')
  let fileId = pathname.slice(startIdx + keyText.length)
  if (fileId.endsWith('&authuser=0')) {
    fileId = fileId.slice(0, -11)
  }

  return fileId
}

export { parseURLForSharedFileId }