import { getContestsFromCloud } from '../contest-gateways'
import { setContestInStore } from '../contest-store'
import {
  contestsStateInterface,
  presentFetchContestsResponseStarted,
  presentFetchContestsResponseFailed,
  presentFetchContestsResponseSucceeded,
} from '../contest-presenter'
import { pollerController } from '../../utils/poller-controller'

async function fetchContests() {
  presentFetchContestsResponseStarted()
  const contests = await getContestsFromCloud()

  if (!contests) {
    presentFetchContestsResponseFailed()
    return
  }
  contests.forEach(setContestInStore)
  contestsStateInterface.setContestsState(contests)
  presentFetchContestsResponseSucceeded()
}

var poller = null
function stopPoller() {
  clearInterval(poller)
  poller = null
}

function startPoller() {
  setTimeout(fetchContests, 100)
  stopPoller()
  poller = setInterval(fetchContests, 60 * 1000)
}

const contestsPoller = {
  start: startPoller,
  stop: stopPoller,
}

pollerController.addPoller('contestsPoller', contestsPoller.stop)

export { fetchContests, contestsPoller }