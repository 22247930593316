// Gymnasium
import { Entity, simScene } from '../SimScene'
import * as BABYLON from '@babylonjs/core'
import { assetPath, makeImpostor, disposePromise, loadGLBWithPhysics, setPhysicsNoContactCollision, hitCache } from '../utils/BabylonUtils'
import Gymnasium1 from '../assets/Gymnasium.glb'
import Mountain from '../assets/Mountain.glb'
import StudioSoftboxEnv from '../assets/StudioSoftbox.env'
import { tennisBall, trafficCone, Waypoints } from './CommonProps'
import SimpleDumbbell from '../assets/SimpleDumbbell.glb'
// import { localAxes } from '../utils/BabylonUtils'

const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7
const OBSTACLE_FRICTION = 5.9

class SimLights extends Entity {
  setOptions = (params) => {
    this.params = params
  }

  load = async (scene) => {
    this.scene = scene

    // Ambient lighting provided by HDR environment
    // this.envTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    this.envTexture = await hitCache('Env_StudioSoftbox', () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    })
    scene.environmentTexture = this.envTexture

    // Directional shadow-casting light.
    this.light = new BABYLON.DirectionalLight('dir01', new BABYLON.Vector3(-1, -1, -1), scene)

    // Position the shadow-casting light near player-1
    this.light.position = new BABYLON.Vector3(...this.params.bot[0].shadowPosition)
    this.light.intensity = 1.0

    // Visualize directional light source (beautiful yellow sun!)
    // const lightSphere = BABYLON.Mesh.CreateSphere('sphere', 10, 2, scene)
    // lightSphere.position = this.light.position
    // lightSphere.material = new BABYLON.StandardMaterial('light', scene)
    // lightSphere.material.emissiveColor = new BABYLON.Color3(1, 1, 0)
    // localAxes(3, scene).parent = lightSphere

    this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light)
    this.shadowGenerator.useExponentialShadowMap = true
    // this.shadowGenerator.forceBackFacesOnly = true
  }

  getShadowGenerators = () => {
    return [this.shadowGenerator]
  }

  unload = async () => {
    const disposeList = [this.shadowGenerator, this.light]
    await Promise.all(disposeList.map(disposePromise))
    this.scene.environmentTexture = null
  }
}

class SimGround extends Entity {
  setOptions = (params) => {
    this.params = params
  }
  load = async (scene) => {
    // Create the invisible ground parent mesh
    this.ground = new BABYLON.TransformNode('ground')
    const floor = BABYLON.MeshBuilder.CreateBox('floor',
      { width: 500, depth: 500, height: 1 },
      scene
    )
    floor.position.y -= 0.5
    floor.checkCollisions = true  // For camera collisions
    floor.isVisible = false
    floor.physicsImpostor = new BABYLON.PhysicsImpostor(
      floor,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )

    floor.parent = this.ground

    const [path, name] = assetPath(Gymnasium1)

    // Import the Gymnasium environment
    this.gymnasiumMesh = await hitCache('GymnasiumModels', async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync (path, name, scene)
    })
    // Currently NOT cloning environment model, instead trying to keep makeImpostor() and other
    // operations that modify the mesh idempotent. That means we can't dispose this, and instead
    // must add/remove it from scene.
    this.gymnasiumMesh.addAllToScene()
    const meshes = this.gymnasiumMesh.meshes
    this.rootMesh = meshes[0]

    // Set the ground mesh as the root node's parent
    this.rootMesh.parent = this.ground
    this.rootMesh.position.y = -0.02

    // Traverse through all meshes in this scene
    await Promise.all(meshes.map(async (mesh) => {
      // Enable shadows for only the floor meshes
      if (mesh.name === 'Plane.002' || mesh.name === 'Gym_Floor') {
        mesh.receiveShadows = true
      }

      // Add the ceiling light meshes with a white glow to the glowMeshes map
      if (mesh.name.substring(0, 16) === 'Gym_CeilingLight') {
        simScene.glowMeshes.set(mesh.name, new BABYLON.Color4(1, 1, 1, 0.15))
        simScene.glLeds.addExcludedMesh(mesh) // Exclude this mesh from the LED glow layer
      }

      makeImpostor(mesh, this.ground)
      mesh.freezeWorldMatrix()
    }))
    floor.freezeWorldMatrix()
  }

  unload = async () => {
    // Don't dispose environment model, just remove from scene
    this.rootMesh.parent = null
    this.gymnasiumMesh.removeAllFromScene()

    await disposePromise(this.ground)
  }
}

class SimObstacles extends Entity {
  constructor(ground) {
    super()
    this.ground = ground
  }

  setOptions = (params) => {
    this.params = params
  }

  loadSurfaceProp = async (id, modelAsset, position, shadowMesh, impostorMesh = null, visibility = false) => {
    const prop = await loadGLBWithPhysics(modelAsset, id, { mass: 0, friction: OBSTACLE_FRICTION }, 1.0, position)

    // Traverse and enable shadows
    prop.getChildren(mesh => mesh.name.includes(shadowMesh), false).forEach((mesh) => {
      mesh.receiveShadows = true   // For shadows
      mesh.checkCollisions = true  // For camera collisions
    })

    this.impostorMeshes = []

    // Traverse and add mesh impostor and set visibility state
    prop.getChildren(mesh => mesh.name.includes(impostorMesh), false).forEach((mesh) => {
      this.impostorMeshes.push(mesh)
      mesh.visibility = visibility
      mesh.receiveShadows = visibility   // For shadows
      mesh.checkCollisions = visibility  // For camera collisions
      if (visibility) {
        mesh.setParent(null)
        mesh.physicsImpostor = new BABYLON.PhysicsImpostor(
          mesh,
          BABYLON.PhysicsImpostor.MeshImpostor,
          { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
          null
        )
      }
    })

    // Traverse and add box impostor for all checkpoints meshes here
    prop.getChildren(mesh => mesh.name.includes('Checkpoint'), false).forEach((mesh) => {
      this.impostorMeshes.push(mesh)
      mesh.visibility = false
      // Use physics engine to detect collisions but pass-through (a "trigger volume")
      mesh.setParent(null)
      mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: GROUND_FRICTION, group: 0, mask: 0 })
      setPhysicsNoContactCollision(mesh.physicsImpostor)
      BABYLON.Tags.AddTagsTo(mesh, 'BotCollider')
    })

    return prop
  }

  loadMountain = async (position) => {
    // Load the mountain prop, enable shadows, camera collisions, add mesh impostor to all climbing holds and set visibility
    const mountain = await this.loadSurfaceProp('mountain', Mountain, position, 'Plywood', 'hold', this.params.holds)
    BABYLON.Tags.AddTagsTo(mountain, 'BotCollider')
    return mountain
  }

  placeMountain = async (scene) => {
    const mountainPos = new BABYLON.Vector3(...[6, 0, 0]) // Centered and parallel to the length of the court
    this.mountain = await this.loadMountain(mountainPos)
  }

  hashMark = (scale, x, y, z, rotZ, scene) => {
    const options = { width: 0.80, height: 0.1 }
    const hashMesh = BABYLON.MeshBuilder.CreatePlane('hash', options, scene)

    const hashMaterial = new BABYLON.PBRMetallicRoughnessMaterial('hashMaterial', scene)
    hashMaterial.baseColor = new BABYLON.Color3(0.0, 0.0, 0.0)
    hashMaterial.metallic = 0.5
    hashMaterial.roughness = 0.2
    hashMesh.material = hashMaterial

    hashMesh.rotation = new BABYLON.Vector3(Math.PI / 2, 0, rotZ)
    hashMesh.position = new BABYLON.Vector3(x, y, z)
    hashMesh.scaling.scaleInPlace(scale)

    return hashMesh
  }

  createMeasureLine = (decimeters, startX, startZ, stepX, stepZ, rotDeg, scene) => {
    // Create a "tape measure" line of hash marks at 10cm and 1m intervals.
    // Params: overall length (decimeters), xz start coords, +/-1 step direction, and rotation of hash marks.
    const hash = new BABYLON.TransformNode('measureLine')
    const rot = rotDeg * Math.PI / 180

    for (let tick = 0, x = startX, z = startZ; tick < decimeters; ++tick, x += stepX, z += stepZ) {
      if (tick % 10 === 0) {
        // 1m interval
        this.hashMark(1.0, x, 0.001, z, rot, scene).parent = hash
      } else {
        // 10cm interval
        this.hashMark(0.25, x, 0.001, z, rot, scene).parent = hash
      }
    }

    return hash
  }

  createStaticProp = async (p) => {
    const props = {
      'dumbbell': SimpleDumbbell,
    }
    const modelAsset = props[p.model]
    if (modelAsset === undefined) {
      console.log(`Error: Unknown model '${p.model}`)
      return null
    }

    const startPosition = new BABYLON.Vector3(...p.position)
    const startRotation = new BABYLON.Vector3(...p.rotation.map(deg => deg * Math.PI / 180))
    const scaling = 1.0

    const prop = await loadGLBWithPhysics(modelAsset, p.id, { mass: 0, friction: 4.0 }, scaling, startPosition, startRotation)

    // BABYLON.Tags.AddTagsTo(treat, 'BotCollider')

    return prop
  }

  load = async (scene) => {
    // Create obstacles
    this.tennisBalls = this.params.tennisBalls.map((b, i) => {
      return tennisBall(`ball${i + 1}`, b[0], b[1], b[2], scene)
    })

    this.trafficCones = this.params.trafficCones.map((b, i) => {
      return trafficCone(`cone${i + 1}`, b[0], b[1], b[2], scene)
    })

    this.staticProps = this.params.staticProps.map(this.createStaticProp)

    const meshesWithShadowsPromises = [...this.tennisBalls, ...this.trafficCones, ...this.staticProps]
    this.meshesWithShadows = await Promise.all(meshesWithShadowsPromises)
    this.meshesWithoutShadows = []

    // Measuring hash marks along baseline of court
    if (this.params.sidelineHashmarks) {
      const hashmarks = this.createMeasureLine(201, 100, -60, -1, 0, 90, scene)
      this.meshesWithoutShadows.push(hashmarks)
    }

    if (this.params.measuringLines) {
      this.params.measuringLines.forEach((m) => {
        const line = this.createMeasureLine(m.len, m.startX, m.startZ, m.stepX, m.stepZ, m.rotDeg, scene)
        this.meshesWithoutShadows.push(line)
      })
    }

    // Create and place the plywood mountain
    if (this.params.mountain) {
      await this.placeMountain(scene)
    }
  }

  unload = async () => {
    let disposeList = this.meshesWithShadows.concat(this.meshesWithoutShadows)
    if (this.mountain) {
      disposeList.push(this.mountain)
    }
    if (this.params.holds) {
      disposeList = disposeList.concat(this.impostorMeshes)
    }

    await Promise.all(disposeList.map(disposePromise))
  }

  getShadowCasters = () => {
    return this.meshesWithShadows
  }
}

class Gymnasium extends Entity {
  constructor() {
    super()
    this.name = 'Gymnasium'
    this.defaultParams = GymnasiumDefaultParams
    this.lights = new SimLights()
    this.ground = new SimGround()
    this.obstacles = new SimObstacles(this.ground.ground)
    this.waypoints = new Waypoints()
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false
    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.obstacles.load(scene),
      this.waypoints.load(scene),
    ])

    this.initWaypoints()
  }
  unload = () => Promise.all([
    this.lights.unload(),
    this.ground.unload(),
    this.obstacles.unload(),
    this.waypoints.unload(),
  ])
  initWaypoints = () => {
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  getShadowCasters = () => {
    return this.obstacles.getShadowCasters()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = this.params
    this.lights.setOptions(this.params)
    this.ground.setOptions(this.params)
    this.obstacles.setOptions(this.params)
    this.waypoints.setOptions(this.params)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerController = playerController

    if (this.playerController) {
      // If not using prox or ls, save CPU
      if (this.params.noProx) {
        this.playerController.sensorOverrides.prox = (num) => {}
      }
      if (this.params.noLS) {
        this.playerController.sensorOverrides.ls = (num) => {}
      }
    }
  }
}

const GymnasiumDefaultParams = {
  bot: [
    {
      // playerPosition: [99.66, 0.2, -60.02], // Sideline end corner
      // playerPosition: [96, 0, 0], // Under the basket (shallow side)
      // playerPosition: [-96, 0, 0], // Under the basket (steep side)
      playerPosition: [0, 0.2, 0], // Center court
      playerRotation: 90, // Bot's rotation in degrees
      shadowPosition: [8, 4, 8],  // Near center court
    },
  ],
  tennisBalls: [
    // [4, 4, 4],
    // [4, 3, -4],
    // [-4, 2, 4],
    // [-4, 1, -4],
  ],
  trafficCones: [
    // [0, 0, 4],
    // [0, 0, -4],
  ],
  sidelineHashmarks: false,
  measuringLines: [
    // len: ,
    // startX: ,
    // startZ: ,
    // stepX: ,
    // stepZ: ,
    // rotDeg: ,
  ],
  waypoints: [
    // {
    //   name: 'Checkpoint 1',
    //   position: [26.7, 10, -3.88],   // Each coord can be int or [start, stop, step] for random-range positioning
    //   rotation: [0, 0, -90],
    // },
  ],
  staticProps: [
    // {
    //   model: 'dumbbell',
    //   id: 'prop1',
    //   position: [4, 1.23, 4],
    //   rotation: [0, 0, 90],
    // }
  ],
  mountain: false,
  holds: false,
}

export default Gymnasium