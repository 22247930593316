import React from 'react'
import { Backdrop, LinearProgress, Typography, Fade } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useLogin } from './contexts/LoginContext'
import { useFileManagement } from './contexts/FileManagementContext'
import { useCodePanel } from './contexts/CodePanelContext'
import { useMission } from './contexts/MissionContext'
import { debuggingQuotes } from './InspiringQuotes'
import LandingDialog from './LandingDialog'

const randomIndex = maxCount => Math.floor(Math.random() * maxCount)

const useStyles = makeStyles(theme => ({
  quoteContainer: {
    cursor: 'default',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '25%',
    zIndex: theme.zIndex.drawer + 3000,
    padding: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
  },
  smallBackdrop: {
    borderRadius: theme.shape.borderRadius,
    background: 'rgba(0, 0, 0, 0.85)',
  },
  loadingIndicator: {
    width: '100%',
    marginTop: '1em',
  },
}))

const clearRefTimeout = (ref) => {
  if (!!ref.current) {
    clearTimeout(ref.current)
    ref.current = null
  }
}
export default function LoadingOverlay() {
  const [loginState] = useLogin()
  const [fileManagementState] = useFileManagement()
  const [codePanelState] = useCodePanel()
  const [missionState] = useMission()
  const classes = useStyles()
  const [inspiringQuote, setInspiringQuote] = React.useState('')
  const [mousedOver, setMousedOver] = React.useState(false)
  const mousedOverTimeoutRef = React.useRef()

  const setNewQuote = React.useCallback( () =>
    setInspiringQuote(debuggingQuotes[randomIndex(debuggingQuotes.length)])
  , [])

  const ready = React.useMemo(() =>
    (loginState.loginReady && fileManagementState.fileSystemReady && !!codePanelState.editorReady) || (!!codePanelState.editorReady && !!missionState.editorMode)
  , [codePanelState.editorReady, fileManagementState.fileSystemReady, loginState.loginReady, missionState.editorMode])

  /**
   * If ready, set a timeout for the mousedOver event (to clear the quote).
   * If not ready, set mousedOver back to default (false)
   */
  React.useEffect(() => {
    if (!ready) {
      setMousedOver(false)
      clearRefTimeout(mousedOverTimeoutRef)
    } else {
      mousedOverTimeoutRef.current = setTimeout(() => setMousedOver(true), [5000])
    }
  }, [ready])

  /**
   * Stop the quote timeout if mousedOver changes,
   * since mousedOver will trigger the same event.
   */
  React.useEffect(() => {
    clearRefTimeout(mousedOverTimeoutRef)
  }, [mousedOver])

  /**
   * Conditionally set mousedOver to true
   */
  const handleMouseOverQuote = React.useCallback(() => {
    if (ready && !mousedOver) {
      setMousedOver(true)
    }
  }, [mousedOver, ready])

  return React.useMemo(() => (
    <>
      <Fade
        in={!ready || (ready && !mousedOver)}
        timeout={{
          exit: 1000,
        }}
      >
        <div
          className={classes.quoteContainer}
          onMouseOver={handleMouseOverQuote}
        >
          <Backdrop
            className={classes.smallBackdrop}
            open={!!ready && !mousedOver}
            timeout={{
              enter: 1000,
              exit: 1000,
            }}
          />
          <Typography variant='body1' style={{color: 'white'}}>
            {inspiringQuote.quote}
          </Typography>
          <Typography style={{float: 'right', color: 'white'}} variant='caption'>
            {`- ${inspiringQuote.author}`}
          </Typography>
          <Fade
            in={!ready}
            timeout={{
              exit: 1000,
            }}
          >
            <LinearProgress className={classes.loadingIndicator} />
          </Fade>
        </div>
      </Fade>
      <Backdrop
        className={classes.backdrop}
        open={!ready}
        onEnter={setNewQuote}
        timeout={{
          exit: 1000,
        }}
      />
      <LandingDialog ready={ready}/>
    </>
  ), [classes.backdrop, classes.loadingIndicator, classes.quoteContainer, classes.smallBackdrop, handleMouseOverQuote, inspiringQuote.author, inspiringQuote.quote, mousedOver, ready, setNewQuote])
}