// This only exists because I very frequently use rgba(255,255,255, ${opacity}) OR
// rgba(0,0,0, ${opacity}) depending on the palette type.

var paletteType = null
function setPaletteType(z) {
  paletteType = z
}

function makeDarkColor(opacity) {
  return `rgba(0,0,0,${opacity})`
}

function makeLightColor(opacity) {
  return `rgba(255,255,255,${opacity})`
}

// If you want the two to have different opacities, you can pass in lightOpacity
function genContrastColor(opacity, lightOpacity=null) {
  if (paletteType !== 'dark') {
    return makeDarkColor(lightOpacity ?? opacity)
  }
  return makeLightColor(opacity)
}

function genShadeColor(opacity, lightOpacity=null) {
  if (paletteType !== 'dark') {
    return makeDarkColor(lightOpacity ?? opacity)
  }
  return makeDarkColor(opacity)
}

function genHighlightColor(opacity, lightOpacity=null) {
  if (paletteType !== 'dark') {
    return makeLightColor(lightOpacity ?? opacity)
  }
  return makeLightColor(opacity)
}

function pickThemeColor(darkColor, lightColor) {
  if (paletteType !== 'dark') {
    return lightColor
  }
  return darkColor
}

export { genContrastColor, genShadeColor, genHighlightColor, setPaletteType, pickThemeColor }