import { DEFAULT_GROUP_ID } from './group-use-cases/generateDefaultGroups'

const groupsKey = 'groups'
const assignmentsKey = 'assignments'
const assignmentsByGroupIdKey = 'assignmentsXgroupId'
const progressionGatesKey = 'progressionGates'
const groupLMSAuthorizationInfo = 'groupLMSAuthorizationInfo'
const GROUP_AUTHORIZATION_SOURCES = Object.freeze({
  GOOGLE: 'google',
})

const store = {}
function setGroupsStoreDefaults() {
  store[groupsKey] = []
  store[groupLMSAuthorizationInfo] = {}
  store[assignmentsKey] = []
  store[progressionGatesKey] = []
  store[assignmentsByGroupIdKey] = {}
  store.activeGroupId = DEFAULT_GROUP_ID
  store.groupEventsByDueDate = []
  store.colorCodeArrayByGroupId = {}
  store.floatingLicenseSeats = {}
}
setGroupsStoreDefaults()

function setGroupLMSAuthorizationInfo(groupId, lmsAuthorizationInfo) {
  store[groupLMSAuthorizationInfo][groupId] = lmsAuthorizationInfo
}

function readGroupLMSAuthorizationInfo(groupId) {
  return store[groupLMSAuthorizationInfo][groupId]
}

function setAssignmentInStore(assignment) {
  store[assignmentsKey][assignment.id] = assignment
}

function readAssignmentFromStore(assignmentId) {
  return store[assignmentsKey][assignmentId]
}

function setAssignmentIdsByGroupId(groupId, assignmentIds) {
  store[assignmentsByGroupIdKey][groupId] = assignmentIds

  // TODO: This should be handled somewhere else, not intuitive that setting assignments
  // would mutate the group store
  const group = readGroupFromStore(groupId)
  group.assignments = assignmentIds.map(readAssignmentFromStore)
}

function readAssignmentIdsByGroupId(groupId) {
  return store[assignmentsByGroupIdKey][groupId]
}

function setActiveGroupIdInStore(activeGroupId) {
  store.activeGroupId = activeGroupId
}

function readActiveGroupIdFromStore() {
  return store.activeGroupId
}

function mixFloatingLicenseSeatsIntoGroups() {
  Object.keys(store.floatingLicenseSeats).forEach((groupId) => {
    const group = readGroupFromStore(groupId)
    if (!group) {
      return
    }
    const floatingLicenseSeats = readFloatingLicenseSeatsFromStore(groupId)
    if (!floatingLicenseSeats) {
      return
    }

    updateGroupInStore(groupId, floatingLicenseSeats)
  })
}

function setFloatingLicenseSeatsInStore(floatingLicenseSeatsArray) {
  for (const x of floatingLicenseSeatsArray) {
    const { id, ...floatingLicenseData } = x
    store.floatingLicenseSeats[id] = floatingLicenseData
  }
  mixFloatingLicenseSeatsIntoGroups()
}

function readFloatingLicenseSeatsFromStore(groupId) {
  return store.floatingLicenseSeats[groupId]
}

function setGroupsInStore(groups) {
  store[groupsKey] = {}
  for (const i in groups) {
    const group = groups[i]
    store[groupsKey][group.id] = group
  }
  mixFloatingLicenseSeatsIntoGroups()
}

function updateGroupInStore(groupId, newGroupData) {
  store[groupsKey][groupId] = {
    ...store[groupsKey][groupId],
    ...newGroupData,
  }
}

function readGroupFromStore(groupId) {
  return store[groupsKey][groupId]
}

function readGroupsFromStore() {
  return store[groupsKey]
}

function setProgressionGate(groupId, progressionGateId, progressionGate) {
  if (!store[progressionGatesKey][groupId]) {
    store[progressionGatesKey][groupId] = {}
  }
  store[progressionGatesKey][groupId][progressionGateId] = progressionGate
}

function readProgressionGate(groupId, progressionGateId) {
  return store[progressionGatesKey][groupId][progressionGateId]
}

function readAllProgressionGates(groupId) {
  Object.values(store[progressionGatesKey][groupId])
}

function setGroupEventsByDueDate(groupEventsByDueDate) {
  store.groupEventsByDueDate = groupEventsByDueDate
}

function getGroupEventsByDueDate() {
  return store.groupEventsByDueDate
}

function setColorCodeArrayByGroupId(colorCodeArrayByGroupId){
  store.colorCodeArrayByGroupId = colorCodeArrayByGroupId
}

function getColorCodeArrayByGroupId(groupId){
  return store.colorCodeArrayByGroupId[groupId]
}

export {
  setGroupsStoreDefaults,
  setGroupLMSAuthorizationInfo,
  readGroupLMSAuthorizationInfo,
  setActiveGroupIdInStore,
  readActiveGroupIdFromStore,
  setGroupsInStore,
  readGroupsFromStore,
  setFloatingLicenseSeatsInStore,
  readFloatingLicenseSeatsFromStore,
  setGroupEventsByDueDate,
  getGroupEventsByDueDate,
  setColorCodeArrayByGroupId,
  getColorCodeArrayByGroupId,
  readGroupFromStore,
  setAssignmentInStore,
  readAssignmentFromStore,
  setAssignmentIdsByGroupId,
  readAssignmentIdsByGroupId,
  GROUP_AUTHORIZATION_SOURCES,
  setProgressionGate,
  readProgressionGate,
  readAllProgressionGates,
}