import { getActiveContentSequenceItem } from './getActiveContentSequenceItem'
import { ContentSequenceItemTypes } from '../ContentSequenceItemTypes'

function getFlowIndexFromContentSequenceIndex() {
  const activeContentSequenceItem = getActiveContentSequenceItem()
  if (!!activeContentSequenceItem && activeContentSequenceItem.contentSequenceType === ContentSequenceItemTypes.FLOW) {
    return activeContentSequenceItem.flow.flowIndex
  } else {
    return -1
  }
}

export { getFlowIndexFromContentSequenceIndex }