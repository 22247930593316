/* VersionCheck - component to check app version, and alert if reload needed
  - Relies on Webpack's cache-busting hash id's tacked onto css and js refs within index.html
  Based on: https://marmelab.com/blog/2016/08/29/auto-reload-spa-on-mobile-setinterval.html

Note: Must disable cache for the file (url) you're using for versioning.
Ex: For Firebase Hosting, add the following to disable caching for specific by-name reference to index.html:
(add to firebase.json)
    "headers": [
      {
        "source": "/index.html",
        "headers": [
          {
            "key": "Cache-Control",
            "value": "no-cache"
          }
        ]
      }
    ]

*/

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import fetchHandler from './tools/tracking/fetchHandler'

const useStyles = makeStyles(theme => ({
  popPanel: {
    position: 'absolute',
    top: 10,
    right: 10,
    padding: '0.5em',
    zIndex: 1050,
    textAlign: 'center',
    cursor: 'pointer',
  },
}))


function VersionCheck(props)  {
  const classes = useStyles()
  const [codeHasChanged, setCodeHasChanged] = React.useState(false)

  React.useEffect(() => {
    let previousVersionHash = null

    const fetchSource = () => {
      return (
        fetchHandler(props.url)
          .then((response) => {
            if (response.status !== 200) {
              throw new Error('offline')
            }
            return response.text()
          })
          .then((html) => {
            const hash = getHash(html)
            if (!previousVersionHash) {
              console.log(`Init: vHash ${previousVersionHash}->${hash}`)
              previousVersionHash = hash
              // setCodeHasChanged(true)  //DEBUG
              return
            }
            if (previousVersionHash !== hash) {
              console.log(`New version: vHash ${previousVersionHash}->${hash}`)
              previousVersionHash = hash
              setCodeHasChanged(true)
            }
          })
          .catch(() => { /* do nothing */ })
      )
    }

    fetchSource()
    const interval = setInterval(fetchSource, props.tryDelay)

    return () => {
      clearInterval(interval)
    }
  }, [props.tryDelay, props.url])


  /**
     * Java-like hashCode function for strings
     *
     * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
     */
  const getHash = (str) => {
    const len = str.length
    let hash = 0
    if (len === 0) {
      return hash
    }
    let i
    for (i = 0; i < len; i++) {
      hash = ((hash << 5) - hash) + str.charCodeAt(i)
      hash |= 0 // Convert to 32bit integer
    }
    return hash
  }

  const reloadApp = (e) => {
    window.location.reload(true)
    e.preventDefault()
  }

  return (codeHasChanged && (
    <Paper
      elevation={3}
      className={classes.popPanel}
      onClick={reloadApp}
    >
      <div>{props.reloadMessage}<br />Click <b>here</b> to load</div>
    </Paper>
  ))
}
VersionCheck.propTypes = {
  url: PropTypes.string.isRequired,
  tryDelay: PropTypes.number.isRequired,
  reloadMessage: PropTypes.string.isRequired,
}
VersionCheck.defaultProps = {
  url: '/index.html',
  tryDelay: 5 * 60 * 1000, // 5 minutes
  reloadMessage: 'Aww Yeah, a new version of CodeSpace has arrived!',
}

export default VersionCheck