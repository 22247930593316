// Mission and Objective Panels
import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { PositionedPanel } from './PositionedPanels'
import Markdown from './FiriaMarkdown'
import IconButton from '@material-ui/core/IconButton'
import hexToRGBA from './hexToRGBA'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { Navigation } from '@material-ui/icons'
import { useMission, MissionActions } from './contexts/MissionContext'

const useStyles = makeStyles((theme) => {
  return ({
    missionPanel: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    panelHeader: {
      display: 'flex',
      marginBottom: '0.2em',
      justifyContent: 'space-between',
    },
    panelName: {
      marginLeft: '2px',
    },
    noMargin: {
      margin: 0,
    },
    collapseButton: {
      marginLeft: 'auto',
      width: 30,
      height: 30,
    },
    body: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
      padding: 5,
    },
    footer: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'space-between',
      alignItems:'center',
      marginTop:5,
    },
    minimizeIcon: {
      fontSize: 17,
      transform: 'scale(-1)',
    },
    maximizeIcon: {
      fontSize: 18,
    },
    tinyLeftMargin: {
      marginLeft: '4px',
    },
  })
})


export default function MissionPanel(props) {
  const classes = useStyles()
  const [missionState, missionDispatch] = useMission()
  const transparency = 0.95
  const theme = useTheme()
  const backgroundColor = hexToRGBA(theme.palette.background.paper, transparency)

  let panelStyle = {left:'10%', right:'10%', top:'5%', maxHeight:'90%', display:'flex', backgroundColor: backgroundColor}
  if (props.maximizeWindow) {
    panelStyle = {left:'0%', right:'0%', top:'0%', maxHeight:'100%', minHeight: '100%', display:'flex', backgroundColor: backgroundColor }
  }
  if (props.displayFullscreen) {
    panelStyle.backgroundColor = theme.palette.background.paper
  }

  function iconClick() {
    missionDispatch({ type: MissionActions.SHOW_MSN_SEL, isOpen: !missionState.ui.missionSelOpen })
  }


  return (
    <PositionedPanel
      style={{...panelStyle}}
      direction={'left'}
      anchorEl={props.missionViewElt}
      open={props.open}
      timeout={{enter:200, exit:200}}
      onExited={props.onPanelExit}
      onEntering={props.onPanelEnter}
      noSlide={props.panelSlidingDisabled}
    >
      <div className={classes.missionPanel}>
        <div className={classes.panelHeader}>
          <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
            <div style={{position:'relative', top:-2, left:-2}}>
              <IconButton
                size='small'
                onClick={iconClick}
              >
                <Navigation />
              </IconButton>
            </div>
            <div className={classes.panelName}>
              <p className={classes.noMargin}>
                <em>{`Mission ${props.missionNumber}:`}</em>
                <span className={classes.tinyLeftMargin}>{props.json.title}</span>
              </p>
            </div>
          </div>
          <div style={{marginLeft: 'auto', display: 'flex' }}>
            {/* <IconButton
              className={classes.collapseButton}
              onClick={() => (props.setMaximizeWindow(!props.maximizeWindow))}
            >
              {props.maximizeWindow ?
                missionState.requiresSim ?
                  <FilterNone className={classes.minimizeIcon}/>:
                  null:
                <CheckBoxOutlineBlank className={classes.maximizeIcon}/>
              }
            </IconButton> */}
          </div>
        </div>

        <Divider />
        <div className={classes.body}>
          <Markdown>{props.json.overview}</Markdown>
        </div>
        <Divider />
        <div className={classes.footer}>
          <div></div>
          <Button onClick={props.onContinueButton} variant='text' >
            Next
          </Button>
        </div>
      </div>

    </PositionedPanel>
  )
}
MissionPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  missionViewElt: PropTypes.any,
  onCollapseButton: PropTypes.func.isRequired,
  onContinueButton: PropTypes.func.isRequired,
  maximizeWindow: PropTypes.bool.isRequired,
  setMaximizeWindow: PropTypes.func.isRequired,
}
