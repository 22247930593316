import fetchHandler from '../../tools/tracking/fetchHandler'

async function fetchLessonEditorServerPackIds() {
  const response = await fetchHandler('http://localhost:3001/packs',
    {
      method: 'GET',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
    }
  )
  const dataObj = await response.json()
  return dataObj.data
}

async function fetchLessonEditorServerMissionIds() {
  const response = await fetchHandler('http://localhost:3001/missions',
    {
      method: 'GET',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
    }
  )
  const dataObj = await response.json()
  return dataObj.data.map(fp => fp.slice(0, -5))
}

async function fetchLessonEditorServerMission(missionId) {
  const response = await fetchHandler(`http://localhost:3001/mission/${missionId}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
    }
  )
  const dataObj = await response.json()
  return dataObj.data
}

async function fetchLessonEditorServerObjective(objectiveId) {
  const response = await fetchHandler(`http://localhost:3001/objective/${objectiveId}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {'Content-Type': 'application/json'},
    }
  )
  const dataObj = await response.json()
  return dataObj.data
}

export {
  fetchLessonEditorServerPackIds,
  fetchLessonEditorServerMissionIds,
  fetchLessonEditorServerMission,
  fetchLessonEditorServerObjective,
}