// Sumo Garden
import { Entity } from '../SimScene'
import * as BABYLON from '@babylonjs/core'
import DragonCityEnv from '../assets/DragonCity.env'
import stone_base from '../assets/materials/Stone_Tiles_004_basecolor_reduced.jpg'
import sand from '../assets/materials/EnergyPole_512_albedo.jpg'
import { disposePromise } from '../utils/BabylonUtils'
import { tennisBall, trafficCone } from './CommonProps'

const GROUND_FRICTION = 1.0
const GROUND_RESTITUTION = 0.7
const OBSTACLE_FRICTION = 0.9

class SimLights extends Entity {
  load = (scene) => {
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    this.light = new BABYLON.HemisphericLight('light', new BABYLON.Vector3(-1, 1, 0), scene)
    this.light.intensity = 0.7
    this.light.diffuse = new BABYLON.Color3(1, 1, 1)
    this.light.specular = new BABYLON.Color3(1, 1, 1)
  }

  unload = async () => {
    await disposePromise(this.light)
  }
}

class SimGroundSimple extends Entity {
  initBorder = (border, x, y, z, rot) => {
    border.position.y = y
    border.position.x = x
    border.position.z = z
    border.rotation.y = rot
    border.physicsImpostor = new BABYLON.PhysicsImpostor(border, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: OBSTACLE_FRICTION })
    border.parent = this.ground
    // border.checkCollisions = true  // For mesh, not physics collisions
  }

  load = (scene) => {
    const GROUND_WIDTH = 20
    const BORDER_THICKNESS = 0.1
    const BORDER_HEIGHT = 0.25

    this.ground = BABYLON.Mesh.CreateGround('ground', GROUND_WIDTH, GROUND_WIDTH, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions
    // localAxes(3, scene).parent = this.ground

    const mat = new BABYLON.StandardMaterial('sumoFloor', scene)
    mat.diffuseTexture = new BABYLON.Texture(stone_base, scene)
    mat.diffuseTexture.uScale = 2
    mat.diffuseTexture.vScale = 2
    // mat.bumpTexture = new BABYLON.Texture(stone_normal, scene)
    // mat.specularPower = 512
    this.ground.material = mat

    const borderXpos = BABYLON.MeshBuilder.CreateBox('', { width: BORDER_THICKNESS, height: BORDER_HEIGHT, depth: GROUND_WIDTH }, scene)
    const borderMaterial = new BABYLON.StandardMaterial('BorderMaterial', scene)
    borderMaterial.diffuseTexture = new BABYLON.Texture(sand, scene)
    borderMaterial.ambientColor = new BABYLON.Color3(0.9, 0.9, 0.9)
    borderMaterial.diffuseTexture.uScale = 1
    borderMaterial.diffuseTexture.vScale = 1
    borderXpos.material = borderMaterial

    const borderXneg = borderXpos.instantiateHierarchy(null)
    const borderZpos = borderXpos.instantiateHierarchy(null)
    const borderZneg = borderXpos.instantiateHierarchy(null)
    this.initBorder(borderXpos, (GROUND_WIDTH - BORDER_THICKNESS) / 2, BORDER_HEIGHT / 2, 0, 0)
    this.initBorder(borderXneg, -(GROUND_WIDTH - BORDER_THICKNESS) / 2, BORDER_HEIGHT / 2, 0, 0)
    this.initBorder(borderZpos, 0, BORDER_HEIGHT / 2, GROUND_WIDTH / 2, Math.PI / 2)
    this.initBorder(borderZneg, 0, BORDER_HEIGHT / 2, -GROUND_WIDTH / 2, Math.PI / 2)

    this.ground.freezeWorldMatrix()
    borderXpos.freezeWorldMatrix()
    borderXneg.freezeWorldMatrix()
    borderZpos.freezeWorldMatrix()
    borderZneg.freezeWorldMatrix()
    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
  }

  unload = async () => {
    await disposePromise(this.ground)
  }
}

class SimObstacles extends Entity {
  load = async (scene) => {
    // Create obstacles
    await Promise.all([
      this.ball1 = await tennisBall('ball1', 4, 4, 4, scene),
      this.ball2 = await tennisBall('ball2', 4, 3, -4, scene),
      this.ball3 = await tennisBall('ball3', -4, 2, 4, scene),
      this.ball4 = await tennisBall('ball4', -4, 1, -4, scene),

      // And a couple of traffic cones
      this.cone1 = trafficCone('cone1', 0, 0, 4, scene),
      this.cone2 = trafficCone('cone2', 0, 0, -4, scene),
    ])
  }

  unload = async () => {
    const disposeList = [this.ball1, this.ball2, this.ball3, this.ball4, this.cone1, this.cone2]
    await Promise.all(disposeList.map(disposePromise))
  }
}

// class SimSkyBoxPBR extends Entity {
//     load = (scene) => {
//         this.scene = scene
//         // Physically Based Rendering version - trying for realistic reflections...
//         this.skybox = BABYLON.Mesh.CreateBox("skyBox", 100.0, scene)
//         const skyboxMaterial = new BABYLON.PBRMaterial("skyBox", scene)
//         skyboxMaterial.backFaceCulling = false  // viewing texture on inside of cube
//         this.skybox.infiniteDistance = true   // follow camera's position
//         skyboxMaterial.disableLighting = true  // no reflections on box
//         skyboxMaterial.twoSidedLighting = true
//         skyboxMaterial.microSurface = 1.0

//         scene.environmentTexture = new BABYLON.CubeTexture(DragonCityEnv, scene)
//         skyboxMaterial.reflectionTexture = scene.environmentTexture.clone()
//         skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
//         this.skybox.material = skyboxMaterial
//     }

//     unload = () => {
//         this.scene.environmentTexture.dispose()
//         this.scene.environmentTexture = null
//         this.skybox.dispose(false, true)  // materials too
//     }

// }

// Skybox Note:
// Initially used createDefaultSkybox. Intrigued that switching back to no skybox caused amazing reflections on bot...
// this.envTexture = new BABYLON.CubeTexture(DragonCityEnv, scene)
// scene.createDefaultSkybox(this.envTexture, true, 100)

class SimSkyBoxSimple extends Entity {
  load = (scene) => {
    this.scene = scene
    this.skybox = BABYLON.Mesh.CreateBox('skyBox', 100.0, scene)
    const skyboxMaterial = new BABYLON.StandardMaterial('skyBox', scene)
    skyboxMaterial.backFaceCulling = false  // viewing texture on inside of cube
    this.skybox.infiniteDistance = true   // follow camera's position
    skyboxMaterial.disableLighting = true  // no reflections on box

    scene.environmentTexture = new BABYLON.CubeTexture(DragonCityEnv, scene)
    skyboxMaterial.reflectionTexture = scene.environmentTexture.clone()
    skyboxMaterial.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
    this.skybox.material = skyboxMaterial
  }

  unload = async () => {
    this.skybox.disposeArgs = [false, true] // materials too
    await disposePromise(this.skybox)
    // this.scene.environmentTexture.dispose(),
    // this.scene.environmentTexture = null,
    // this.skybox.dispose(false, true),  // materials too
  }
}

export default class SumoGarden extends Entity {
  constructor() {
    super()
    this.name = 'Sumo Garden'
    this.lights = new SimLights()
    this.ground = new SimGroundSimple()
    this.skybox = new SimSkyBoxSimple()
    this.obstacles = new SimObstacles()
  }
  load = scene => Promise.all([
    scene.autoClear = false, // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false,
    this.lights.load(scene),
    this.ground.load(scene),
    this.skybox.load(scene),
    this.obstacles.load(scene),
  ])
  unload = () => Promise.all([
    this.lights.unload(),
    this.ground.unload(),
    this.skybox.unload(),
    this.obstacles.unload(),
  ])
}
