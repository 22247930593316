// Mission Select Dialog
import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Typography, Dialog, Button, IconButton, Paper } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { GatewayResponseIndicator } from './GatewayResponseIndicator'
import { useGroups } from '../contexts/GroupsContext'
import { leaveGroup } from '../content-manager/groups/group-use-cases/leaveGroup'
import { RESPONSE_STATUS } from '../content-manager/fetch-responses'

const FiriaRedButtonBase = withStyles(theme => ({
  root: {
    borderRadius: 2,
    color: theme.palette.getContrastText('#B22222'),
    backgroundColor: '#B22222',
    '&:hover': {
      backgroundColor: '#8B0000',
    },
  },
}))(Button)

const FiriaRedButton = (props) => {
  return (
    <Paper elevation={5}>
      <FiriaRedButtonBase {...props}>
        {props.children}
      </FiriaRedButtonBase>
    </Paper>
  )
}

const useStyles = makeStyles(theme => ({
  joinCodeDialogRoot: {
    minWidth: '30%',
  },
}))

const LeaveClassDialog = ({open, handleClose}) => {
  const classes = useStyles()
  const { leaveGroupResponse, leaveGroupInProgress, selectedGroupId, selectedGroup } = useGroups()

  React.useEffect(() => {
    if (leaveGroupResponse?.status === RESPONSE_STATUS.SUCCEEDED) {
      handleClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaveGroupResponse])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{height: '85%'}}
      keepMounted
      PaperProps={{
        classes: { root: classes.joinCodeDialogRoot },
      }}
    >
      <>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'space-between', padding: '5px 10px'}}>
          <div style={{width: 30}}></div>
          <Typography>
            {'Are you sure you want to leave?'}
          </Typography>
          <IconButton onClick={handleClose} size='small' style={{width: 30, height: 30}}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={{padding: '15px', display:'flex', flexDirection: 'column', gap: 25, alignItems: 'center'}}>
          <div style={{height: 2}}></div>
          <Typography style={{fontWeight: 500}}>
            {selectedGroup?.groupName}
          </Typography>
          <div style={{display:'flex', alignItems:'center'}}>
            <div style={{display: 'flex', alignItems:'center', width: 30}}>
              <GatewayResponseIndicator
                fetchState={leaveGroupResponse}
                open={open}
                placement='bottom'
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FiriaRedButton
                onClick={() => leaveGroup(selectedGroupId)}
                disabled={leaveGroupInProgress}
                variant='contained'
                style={{ borderRadius: 2, width: 200 }}
              >
                {'Leave Class'}
              </FiriaRedButton>
              <div style={{width: 20}}></div>
              <Button style={{ borderRadius: 2, width: 200 }} variant='outlined' onClick={handleClose}>
                {'Cancel'}
              </Button>
            </div>
            <div style={{width: 30}}></div>
          </div>
        </div>
      </>
    </Dialog>
  )
}

export { LeaveClassDialog }