import { setMissionEditorValidatorTestState } from '../../contexts/MissionEditorContext'
import { RESPONSE_STATUS } from '../fetch-responses'
import { readValidatorTestDataFromStore, getTotalTests } from './lesson-editor-repos'


function presentValidatorTestStarted() {
  setMissionEditorValidatorTestState((z) => {
    return { ...z, status: RESPONSE_STATUS.IN_PROGRESS }
  })
}

function presentValidatorTestFailed() {
  setMissionEditorValidatorTestState((z) => {
    return { ...z, status: RESPONSE_STATUS.FAILED }
  })
}

function presentValidatorTestResults() {
  const tests = readValidatorTestDataFromStore()
  const testsByFlowId = {}
  for (const test of tests) {
    if (!testsByFlowId[test.id]) {
      testsByFlowId[test.id] = []
    }
    testsByFlowId[test.id].push(test.idx)
  }

  const state = {
    tests,
    testsByFlowId,
    totalTests: getTotalTests(),
    status: RESPONSE_STATUS.FAILED,
  }

  if (tests.length === 0) {
    state.status = RESPONSE_STATUS.SUCCEEDED
  }

  setMissionEditorValidatorTestState(state)
}

export {
  presentValidatorTestFailed,
  presentValidatorTestResults,
  presentValidatorTestStarted,
}