import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, LinearProgress } from '@material-ui/core'
import { missionEditorController } from '../../MissionEditorControl'
import { useUserConfig, UserConfigActions } from '../../contexts/UserConfigContext'

import HTMLRipper from './HTMLRipper'

import formatHTML from './tools/formatHTML'
import ripLessonContent from './tools/ripLessonContent'
import ripToolboxContent from './tools/ripToolboxContent'
import ripTableOfContents from './tools/ripTableOfContents'

const useStyles = makeStyles(theme => ({
  moduleContainer: {
    margin: 10,
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: 5,
    borderRadiusBottomRight: 5,
  },
  headerContainer: {
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderBottom: '1px solid rgba(0,0,0,0.3)',
  },
  header: {
    width: '100%',
    fontSize: 15,
    padding: 10,
  },
  moduleBody: {
    height: 100,
    display: 'flex',
    flexDirection: 'row',
  },
  buttonsBorder: {
    backgroundColor: 'rgba(0,0,0,0.1)',
    borderRight: '1px solid rgba(0,0,0,0.3)',
  },
  buttonContainer: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

export default function TeachersManualDialog ({ runningId, setRunning, pack, absImagePath, packs }) {
  const classes = useStyles()
  const styleClasses = React.useRef([])
  const tasks = React.useRef([])
  const content = React.useRef([])
  const imageSizeList = React.useRef([])
  const [, userConfigDispatch] = useUserConfig()
  const [progress, setProgress] = React.useState(0)
  const [html, setHtml] = React.useState(null)

  const running = runningId === pack.id
  const otherRunning = runningId !== null && !running

  const onFinish = async (css) => {
    setHtml(formatHTML(css, content.current.join('\n')))
    await missionEditorController.sendTeachersManualImages(pack.id, imageSizeList.current)
    console.log(imageSizeList.current)
    stopRipper()
  }

  const stopRipper = () => {
    tasks.current = []
    imageSizeList.current = []
    content.current = []
    userConfigDispatch({ type: UserConfigActions.THEME_SET, theme: 'dark' })
    setProgress(0)
    setRunning(false)
  }

  const startRipper = async () => {
    setHtml(null)

    const taskCb = (rippedContent) => {
      content.current.push(rippedContent)
    }

    const addTask = (taskContent, type='md') => {
      tasks.current.push({type: type, taskContent: taskContent, taskCb: taskCb})
    }

    const addSpacer = (size=2) => {
      addTask(size, 'spacer')
    }

    if (pack.id === 'toolbox') {
      ripToolboxContent({ addTask, addSpacer, packs: packs.current })
    } else if (pack.id === 'toc') {
      ripTableOfContents({ addTask, addSpacer, packs: packs.current })
    } else {
      ripLessonContent({ addTask, addSpacer, packs: [pack] })
    }

    tasks.current = tasks.current.reverse()
    userConfigDispatch({ type: UserConfigActions.THEME_SET, theme: 'light' })
    setRunning(true)
  }

  const download = () => {
    const element = document.createElement('a')
    const file = new Blob([html], {type: 'text/html'})
    element.href = URL.createObjectURL(file)
    element.download = `${pack.id}_teachers_manual_${new Date().toLocaleDateString()}_${new Date().toLocaleTimeString()}.html`
    document.body.appendChild(element)
    element.click()
  }

  React.useEffect(() => {
    const children = []
    for (var i = 0; i < document.head.children.length; i++) {
      children.push(document.head.children[i])
    }
    styleClasses.current = children.filter(el => el.tagName === 'STYLE')
  }, [])

  return (
    <div className={classes.moduleContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.header}>
          {pack.title}
        </div>
      </div>
      {running ?
        <LinearProgress variant='determinate' value={progress} style={{width: '100%'}}/>:
        null
      }
      <div className={classes.moduleBody}>
        <div className={classes.buttonsBorder}>
          <div className={classes.buttonContainer}>
            <Button
              onClick={startRipper}
              variant='contained'
              color='secondary'
              disabled={running || otherRunning}
              style={{marginBottom: 5}}
            >
              Rip
            </Button>
            <Button
              onClick={download}
              variant='contained'
              color='secondary'
              disabled={running || !html}
            >
              Download
            </Button>
          </div>
        </div>
        <div style={{overflow: 'hidden'}}>
          {running ?
            <HTMLRipper
              styleClasses={styleClasses.current}
              tasks={tasks}
              onFinish={onFinish}
              setProgress={setProgress}
              absImagePath={absImagePath.current+'/'+pack.id}
              imageSizeList={imageSizeList}
            />:
            null
          }
        </div>
      </div>
    </div>
  )
}