import fetchHandler from '../../tools/tracking/fetchHandler'
import { cacheBust } from '../../firebase/domain'

async function getMissionPacks() {
  const response = await fetchHandler(`lesson-content/missionPackList.json${cacheBust}`
    ,{
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }
  )
  return await response.json()
}

async function pullObj(folder, id, blob = false) {
  if (!blob) {
    const response = await fetchHandler(`lesson-content/${folder}/${id}.json${cacheBust}`
      , {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    )
    if (response.status === 200) {
      return await response.json()
    } else {
      console.log(`Error ${response.status} fetching lesson-content/${folder}/${id}`)
      return null
    }
  } else {
    const response = await fetchHandler(`lesson-content/${folder}/${id}${cacheBust}`
      , {
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/octet-stream',
        },
      }
    )
    if (response.status === 200) {
      return response
    } else {
      console.log(`Error ${response.status} fetching lesson-content/${folder}/${id}`)
      return null
    }
  }
}

async function formatMissionPack(newPackId) {
  const pack = await pullObj('packed', newPackId)
  const msnPromises = pack.missions.map((msn) => {
    return formatMission(msn)
  })
  const results = await Promise.all(msnPromises)
  pack.missions = results
  pack.avatarBlob = await fetchAvatar(pack, pullObj)
  return pack
}

async function formatMission(missionData) {
  try {
    var mission = missionData
    if (!('obj' in missionData)) {
      // TODO - Why was this being checked?
      // mission = await pullObj('mission', missionData.id)
    }
    const fullMission = await formatMissionFlow(mission, pullObj)
    fullMission.avatarBlob = await fetchAvatar(fullMission, pullObj)

    return formatFlowTypeIndex(fullMission)
  } catch (error) {
    console.log(`formatMission(mission=${missionData?.id}) error: `, error)
    return null
  }
}

async function fetchAvatar(obj, pullFunc) {
  const resp = await pullFunc('images', obj.avatar, 'blob')
  if (!resp) {
    console.warn('Failed to fetch avatar for:', obj)
    return null
  }
  return resp.blob()
}

async function getFileBlobs(flowObj, pullFunc) {
  const files = []
  if (!flowObj.files) {
    return files
  }
  const filePromises = flowObj.files.map((fileName, index) =>
    new Promise(async (resolve, reject) => {
      const data = await pullFunc('objective-files', fileName, 'blob')
      if (!flowObj.fileBlobs){
        flowObj.fileBlobs = []
      }
      try {
        files.push({id: fileName, obj: new Uint8Array(await data.arrayBuffer())})
        resolve()
      } catch (e) {
        console.error(`file: ${fileName}:`, e)
        reject(`${fileName}: ${e}`)
      }
    })
  )
  await Promise.allSettled(filePromises)
  return files
}

function formatMissionFlow(mission, pullFunc) {
  // Need to grab any files referenced
  mission.flow.map(async (flowObj) => {
    if (flowObj.obj?.files?.length) {
      // TODO - Temporary fix until pack doesn't include files
      delete flowObj.obj.fileBlobs
      flowObj.obj.fileBlobs = await getFileBlobs(flowObj.obj, pullFunc)
    }
  })
  return mission
}

function formatFlowTypeIndex(mission) {
  let totalObjectives = 0
  let totalQuizzes = 0
  mission.flow.forEach((flowItem, flowIndex) => {
    flowItem.flowIndex = flowIndex
    if (flowItem.type === 'objective') {
      flowItem.typeIndex = totalObjectives
      totalObjectives += 1
    } else {
      flowItem.typeIndex = totalQuizzes
      totalQuizzes += 1
    }
  })
  return mission
}

async function fetchMissionPackFromCloud(packId) {
  const packContent = await formatMissionPack(packId)
  packContent.missions = packContent.missions.filter(m => m)  // filter null (failed to load) missions
  return packContent
}

export { getMissionPacks, fetchMissionPackFromCloud }