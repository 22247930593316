import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  instructionsContainer: {
    position: 'absolute',
    zIndex: 5000,
    userSelect: 'text',
  },
  instructionsContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 15,
  },
}))


const RipperInstructions = ({ open, running, onClose }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.instructionsContainer} style={{display: open ? null : 'None'}}>
      <div className={classes.instructionsContent}>
        <Typography variant='h4'>
      Ripper Instructions:
        </Typography>
        <Typography>
          <strong>1.</strong> Press "Start" and allow the ripper to run through each page of content.
        </Typography>
        <Typography>
          <strong>2.</strong> Press "Download"
        </Typography>
        <Typography>
          <strong>3.</strong> Open the downloaded file in Chrome.<br />
          (You will need this MaterialIcons file in same directory: <a href='https://github.com/google/material-design-icons/blob/master/font/MaterialIcons-Regular.ttf' rel='noopener noreferrer' target='_blank'>MaterialIcons</a>)
        </Typography>
        <Typography>
          <strong>4.</strong> Print to PDF.
        </Typography>
      </div>
      <div style={{padding: 3, paddingBottom: 8, display: 'flex', alignItems:'center', justifyContent: 'space-between'}}>
        <div></div>
        <Button
          onClick={onClose}
          disabled={running}
          variant='outlined'
        >
          {'Close Ripper'}
        </Button>
        <div></div>
      </div>
    </Paper>
  )
}

export default RipperInstructions