import { isRemarkCompiler, isRemarkParser } from '../ToolboxPlugin'
const PRE_POST_FIX = '--'
const SELF_CLOSING_POST_FIX = `/${PRE_POST_FIX}`

function injectInterrupts(proto, type) {
  const blockMethods = proto.blockMethods
  const interruptParagraph = proto.interruptParagraph
  const interruptList = proto.interruptList
  const interruptBlockquote = proto.interruptBlockquote


  blockMethods.splice(-1, 0, type)

  // Inject math to interrupt rules
  interruptParagraph.splice(0, 0, [
    type,
  ])
  interruptList.splice(0, 0, [type])
  interruptBlockquote.splice(0, 0, [
    type,
  ])
}

function findMatchingArgValueBracket(argValueString) {
  const splitString = argValueString.split('')
  if (splitString.length === 0) {
    return
  }

  let bracketBank = 0
  for (let i=0; i<splitString.length; i++) {
    if (splitString[i] === '{') {
      bracketBank++
    }
    if (splitString[i] === '}') {
      bracketBank--
    }
    if (bracketBank === 0) {
      return argValueString.slice(1, i)
    }
  }
}

function parseArgsFromTagContent(tagContent) {
  let args = {}

  const firstArgKeyStartIdx = 0
  const firstArgKeyEndIdx = tagContent.indexOf('=')
  if (firstArgKeyEndIdx === -1) {
    return args
  }

  const valueString = findMatchingArgValueBracket(tagContent.slice(firstArgKeyEndIdx + 1))
  if (!valueString || valueString.length + firstArgKeyEndIdx + 1 === tagContent.length) {
    return args
  }

  args[tagContent.slice(firstArgKeyStartIdx, firstArgKeyEndIdx)] = valueString
  return {...args, ...parseArgsFromTagContent(tagContent.slice(valueString.length + firstArgKeyEndIdx + 3).trim())}
}

function makeLessonSectionPlugin(sectionName) {
  const upperSectionName = sectionName[0].toUpperCase() + sectionName.slice(1)
  function attachParser(parser, options) {
    const proto = parser.prototype

    proto.blockTokenizers[sectionName] = lessonSectionTokenizer
    injectInterrupts(proto, sectionName)

    function lessonSectionTokenizer(eat, value, silent) {
      const openingTagStartIdx = value.indexOf(`${PRE_POST_FIX}${upperSectionName}`)
      if (openingTagStartIdx === -1) {
        return
      }

      let error
      const openingTagSectionNameIdx = openingTagStartIdx + PRE_POST_FIX.length
      const openingTagSectionNameEndIdx = openingTagSectionNameIdx + upperSectionName.length

      let openingTagEndIdx
      let content = ''
      let closingTagEndIdx

      const selfClosingTagStartIdx = value.slice(openingTagSectionNameIdx).indexOf(SELF_CLOSING_POST_FIX)
      const normalClosingTagStartIdx = value.slice(openingTagSectionNameIdx).indexOf(PRE_POST_FIX)

      let selfClosing
      if (selfClosingTagStartIdx === -1) {
        selfClosing = false
      } else if (normalClosingTagStartIdx === -1) {
        selfClosing = true
      } else {
        selfClosing = selfClosingTagStartIdx < normalClosingTagStartIdx
      }

      // If the tag is self closing, we don't need to do all the work identifying the ending tag and isolating the content.
      if (selfClosing) {
        openingTagEndIdx = selfClosingTagStartIdx + openingTagSectionNameIdx + SELF_CLOSING_POST_FIX.length
        closingTagEndIdx = openingTagEndIdx
      } else {
        openingTagEndIdx = normalClosingTagStartIdx + openingTagSectionNameIdx + PRE_POST_FIX.length
        const closingTagStartIdx = value.slice(openingTagEndIdx).indexOf(`${PRE_POST_FIX}/${upperSectionName}`) + openingTagEndIdx
        if (closingTagStartIdx - openingTagEndIdx === -1) {
          error = `Failed to find ${upperSectionName} closing tag: ${PRE_POST_FIX}/${upperSectionName}`
        }

        const closingTagSectionNameIdx = closingTagStartIdx + `${PRE_POST_FIX}/${upperSectionName}`.length
        closingTagEndIdx  = value.slice(closingTagSectionNameIdx).indexOf(PRE_POST_FIX) + closingTagSectionNameIdx + PRE_POST_FIX.length

        content = value.slice(openingTagEndIdx, closingTagStartIdx)
      }

      const openingTagContent = value.slice(openingTagSectionNameEndIdx, openingTagEndIdx - PRE_POST_FIX.length).trim()
      const args = parseArgsFromTagContent(openingTagContent)
      return eat(value)({
        type: sectionName,
        pre: value.slice(0, openingTagStartIdx),
        post: value.slice(closingTagEndIdx, value.length),
        content,
        value: content,
        error,
        ...args,
        data: {
          hName: 'span',
          hChildren: [{type: 'text', value:content}],
        },
      })
    }
  }

  function attachCompiler(compiler) {
    const proto = compiler.prototype

    proto.visitors.lessonSection = compileLessonSection

    function compileLessonSection(node) {
      return `${PRE_POST_FIX}${upperSectionName}${PRE_POST_FIX}\n` + node.value + `\n${PRE_POST_FIX}${upperSectionName}${PRE_POST_FIX}`
    }
  }


  function LessonContentPlugin(options) {
    const parser = this.Parser
    const compiler = this.Compiler
    if (isRemarkParser(parser)) {
      attachParser(parser, options)
    }

    if (isRemarkCompiler(compiler)) {
      attachCompiler(compiler, options)
    }
  }

  return LessonContentPlugin
}

export { makeLessonSectionPlugin }
