import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SandboxIcon } from './assets/SvgIcons'
import Portal from '@material-ui/core/Portal'

const useStyles = makeStyles((theme) => {
  return ({
    portalChildrenContainer: {
      position: 'absolute',
      left: '0%',
      right: '0%',
      top: '0%',
      height: '100%',
      width: '100%',
      display: 'flex',
      backgroundColor: theme.palette.background.paper,
    },
    icon: {
      fontSize: 150,
      fill: 'rgba(0,0,0,0.2)',
    },
  })
})

export function ExploreModeNoSimOverlayPanel({ missionViewElt }) {
  const classes = useStyles()

  return (
    <Portal container={missionViewElt}>
      <div className={classes.portalChildrenContainer}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <SandboxIcon className={classes.icon}/>
        </div>
      </div>
    </Portal>
  )
}
