/* Mission toolbar
   When user is in "mission" mode rather than Explore, the Mission Toolbar is up.
*/

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { SandboxIcon } from './assets/SvgIcons'
import SandboxOffIcon from '@material-ui/icons/AssignmentReturn'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuIcon from '@material-ui/icons/Menu'
import MissionSelect from './MissionSelect'
import MissionPanel from './MissionPanel'
import ObjectivePanel from './ObjectivePanel'
import QuizPanel from './QuizPanel'
import ToolboxPanel from './Toolbox/Toolbox'
import { useMission, MissionActions, LessonContentViews, targetRequiresSim } from './contexts/MissionContext'
import { ExploreModeNoSimOverlayPanel } from './ExploreModeNoSimOverlayPanel'
import ValidatorUI from './ValidatorUI'
import CompletionPanel, { CompletionBanner } from './CompletionPanel'
import Avatar from '@material-ui/core/Avatar'
import { ContentSequencePanel } from './ContentSequencePanel'
import { ProgressionGatePanel } from './ProgressionGatePanel'
import { iterateContentSequenceIndex } from './content-manager/content-director/content-director-use-cases/iterateContentSequenceIndex'
import { useContentDirector } from './contexts/ContentDirectorContext'
import { GoogleSubmissionErrorDialog } from './AssignmentSubmissionPanels/GoogleSubmissionErrorDialog'
import { changeContentSequenceIndexToNextUncompletedObjective } from './content-manager/content-director/content-director-use-cases/changeContentSequenceIndexToNextUncompletedObjective'
import LoadingPanel from './LoadingPanel'
// import analytics from './Analytics'

const useStyles = makeStyles((theme) => {
  return ({
    bar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 4,
      height: '100%',
      overflow: 'hidden',
      backgroundColor: theme.palette.outerBar,
    },
    barItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    objBar: {
      overflowY: 'auto',
      overflowX: 'hidden',
      background: `
      linear-gradient(${theme.palette.background.default} 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), ${theme.palette.background.default} 70%) 0 100%,
      linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)) 0 100%`,
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.palette.background.default,
      backgroundSize: '100% 40px, 100% 40px, 100% 14px, 100% 14px',
      backgroundAttachment: 'local, local, scroll, scroll',
    },
    objectiveButtons: {
      minWidth: 0,
      width: 51,
      height: 32,
      marginBottom: 2,
    },
    infoButton: {
      marginBottom: '0.5em',
    },
    doneIcon: {
      color: 'green',
      paddingRight: '0.2em',
    },
    missionButton: {
      borderRadius: 5,
      height: 51,
      width: 51,
      marginBottom: 2,
    },
    avatar: {
      height: 35,
      width: 35,
      borderRadius: 5,
    },
  })
})

var fsMode = false
var transitioningFromFsToNonFs = false

function handleTransitioningFromFsToNonFs(seqIdx, msnState) {
  try {
    const currType = msnState.activeMission.flow[msnState.ui.curFlowIndex]?.type
    const nextFlow = msnState.activeMission.flow[seqIdx]
    const nextType = nextFlow?.type
    const nextTarget = nextFlow?.obj?.target
    if (currType !== 'objective' && nextType === 'objective' && targetRequiresSim(nextTarget)) {
      transitioningFromFsToNonFs = currType
    }
  } catch {
    //
  }
}

function MissionPanels(props) {
  const [isFocusedContentClear, setIsFocusedContentClear] = React.useState(true)
  const [openedPanel, setOpenedPanel] = React.useState(LessonContentViews.NOTHING)
  const [positionedPanelFlowTypeOpen, setPositionedPanelFlowTypeOpen] = React.useState(false)
  const [positionedPanelContent, setPositionedPanelContent] = React.useState({ mission: null, flow: null })
  const [missionState, missionDispatch] = useMission()
  const [completionBannerVisible, setCompletionBannerVisible] = React.useState(false)

  React.useEffect(() => {
    function setContent() {
      copyOfState.displayFullscreen = !missionState.requiresSim && missionState.requiresSim !== null
      setPositionedPanelContent(copyOfState)
    }

    // This use effect uses missionState focused panel to display content in a more user friendly way,
    // it triggers animations on the positioned panels, only renders another panel when the previous one is entirely off
    // screen, and also holds off on showing a panel if the content isn't finished being pulled yet
    var copyOfState = { ...positionedPanelContent }
    if (copyOfState.displayFullscreen) {
      return
    }

    if (missionState.ui.focusedContent === LessonContentViews.MISSION) {
      // If the mission content saved in the mission bar is different from that in the missionState, the content has changed
      // and the panel needs to change too
      if (positionedPanelContent.mission === null || positionedPanelContent.mission.id !== missionState.activeMission.id) {
        // If the content has changed, but the close animation hasn't been triggered, trigger it
        if (openedPanel !== LessonContentViews.NOTHING) {
          setOpenedPanel(LessonContentViews.NOTHING)
          // If the close animation has been triggered, update the mission bar content with mission state content
        } else if (isFocusedContentClear) {
          copyOfState.mission = missionState.activeMission
          setContent()
        }
      }
      if (copyOfState.mission && copyOfState.mission.id !== '' && isFocusedContentClear) {
        setOpenedPanel(LessonContentViews.MISSION)
      }
    } else if (missionState.ui.focusedContent === LessonContentViews.FLOW) {
      const oldFlowId = positionedPanelContent.flow?.data?.id
      const newFlowId = missionState.activeMission.flow[missionState.ui.curFlowIndex]?.id
      if (positionedPanelContent.flow === null || (oldFlowId && newFlowId)) {
        const flowIdChanged = oldFlowId !== newFlowId
        if (openedPanel !== LessonContentViews.NOTHING && flowIdChanged) {
          setOpenedPanel(LessonContentViews.NOTHING)
        } else if (isFocusedContentClear && missionState.initialLoadDone && !!missionState.activeMission.flow[missionState.ui.curFlowIndex]) {
          copyOfState.flow = {
            data: missionState.activeMission.flow[missionState.ui.curFlowIndex],
            progress: missionState.activeMission.flow[missionState.ui.curFlowIndex]?.progress,
          }
          setContent()
        }
      }

      // If the panel is cleared, and positioned panel content exists
      if (isFocusedContentClear && (positionedPanelContent?.flow || positionedPanelContent?.mission)) {
        setOpenedPanel(LessonContentViews.FLOW)
        setPositionedPanelFlowTypeOpen(positionedPanelContent?.flow?.data?.type)
      }
    }
  }, [isFocusedContentClear, missionState, openedPanel, positionedPanelContent])

  const { contentSequenceItem } = useContentDirector()
  const [swappingBetweenTwoProgressionGates, setSwappingBetweenTwoProgressionGates] = React.useState(false)
  const lastProgressionGateId = React.useRef(null)
  React.useEffect(() => {
    const handleProgressionGatePanelVisibility = () => {
      const progGateFocused = missionState.ui.focusedContent === LessonContentViews.PROGRESSION_GATE
      const contentSequenceItemIsProgGate = !!contentSequenceItem?.progressionGate
      if (!contentSequenceItemIsProgGate) {
        return
      }

      const { progressionGate } = contentSequenceItem
      const oldProgGateId = lastProgressionGateId.current
      lastProgressionGateId.current = progressionGate.id

      if (swappingBetweenTwoProgressionGates && isFocusedContentClear) {
        setOpenedPanel(LessonContentViews.PROGRESSION_GATE)
        setSwappingBetweenTwoProgressionGates(false)
        return
      }

      if (progressionGate.id !== oldProgGateId && oldProgGateId !== null) {
        setOpenedPanel(LessonContentViews.NOTHING)
        setSwappingBetweenTwoProgressionGates(true)
        return
      }

      if (progGateFocused && isFocusedContentClear) {
        setOpenedPanel(LessonContentViews.PROGRESSION_GATE)
        return
      }
    }
    handleProgressionGatePanelVisibility()
  }, [contentSequenceItem, isFocusedContentClear, missionState.ui.focusedContent, swappingBetweenTwoProgressionGates])

  const setMaximizeWindow = (val) => {
    missionDispatch({ type: MissionActions.RESIZE_LSN_WINDOW, isMax: val })
  }

  const objectiveIndex = (index) => {
    if (missionState.activeMission.id === '') {
      return
    }
    var formattedIndex = index
    for (let i = 0; i < index; i++) {
      if (missionState?.activeMission?.flow[i]?.type !== 'objective') {
        formattedIndex -= 1
      }
    }
    return (formattedIndex + 1).toString()
  }

  const reinstateDisplayFsModeOnExploreModeExit = React.useRef(false)
  React.useEffect(() => {
    if (missionState.ui.isExploreMode && positionedPanelContent.displayFullscreen) {
      reinstateDisplayFsModeOnExploreModeExit.current = true
    } else if (reinstateDisplayFsModeOnExploreModeExit.current) {
      setPositionedPanelContent((x) => {
        return { ...x, displayFullscreen: !missionState.requiresSim }
      })
      reinstateDisplayFsModeOnExploreModeExit.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [missionState.ui.isExploreMode])

  const onPanelExit = () => {
    if (transitioningFromFsToNonFs) {
      setPositionedPanelContent((x) => {
        return { ...x, displayFullscreen: !missionState.requiresSim }
      })
    }
    transitioningFromFsToNonFs = false
    setOpenedPanel(LessonContentViews.NOTHING)
    setIsFocusedContentClear(true)
  }

  const onPanelEnter = () => {
    setIsFocusedContentClear(false)
  }

  // The two useEffects below are used when a panel goes from non-fullscreen to fullscreen.
  // They cause the fullscreen panel to "bounce" out to mimic the non-fs panel.
  const awaitingInitialFullscreenSlide = React.useRef(false)
  React.useEffect(() => {
    if (!missionState.requiresSim) {
      awaitingInitialFullscreenSlide.current = true
    }
  }, [missionState.requiresSim])

  React.useEffect(() => {
    if (!isFocusedContentClear) {
      awaitingInitialFullscreenSlide.current = false
    }
  }, [isFocusedContentClear])

  const activePanelType = missionState.activeMission.flow[missionState.ui.curFlowIndex]?.type
  function getFlowTypeOpen() {
    if (transitioningFromFsToNonFs) {
      return transitioningFromFsToNonFs
    }

    if (positionedPanelContent?.displayFullscreen && !missionState.requiresSim) {
      return activePanelType
    }

    return positionedPanelFlowTypeOpen
  }

  function isFullScreenMode() {
    if (positionedPanelContent?.displayFullscreen && missionState.requiresSim) {
      return false
    }

    if (awaitingInitialFullscreenSlide.current) {
      return
    }

    return positionedPanelContent?.displayFullscreen
  }
  fsMode = !!isFullScreenMode()

  React.useEffect(() => {
    if (!missionState.requiresSim) {
      return
    }
    if (!isFocusedContentClear) {
      return
    }
    if (transitioningFromFsToNonFs) {
      return
    }
    setPositionedPanelContent((x) => {
      return { ...x, displayFullscreen: !missionState.requiresSim }
    })
  }, [missionState.requiresSim, isFocusedContentClear])

  function getOpenedPanel() {
    if (transitioningFromFsToNonFs) {
      return 'flow'
    }

    if (positionedPanelContent?.displayFullscreen && !missionState.requiresSim) {
      return missionState.ui.focusedContent
    }

    return openedPanel
  }

  const flowPanelIsVisible = (panelType) => {
    if (getFlowTypeOpen() !== panelType) {
      return
    }

    if (activePanelType !== panelType && !fsMode) {
      return
    }

    if (positionedPanelContent?.displayFullscreen && missionState.requiresSim) {
      return
    }

    if (!fsMode && missionState.ui.focusedContent !== LessonContentViews.FLOW) {
      return
    }

    if (fsMode && !(missionState.ui.focusedContent === LessonContentViews.FLOW || missionState.ui.focusedContent === LessonContentViews.COMPLETION_MESSAGE)) {
      return
    }

    return getOpenedPanel() === LessonContentViews.FLOW
  }

  const panelIsOpen = (visible) => {
    if (visible === undefined) {
      return
    }

    return visible || fsMode
  }

  const objectiveContentIsVisible = flowPanelIsVisible('objective')
  const objectivePanelIsOpen = !!panelIsOpen(objectiveContentIsVisible)
  const quizContentIsVisible = flowPanelIsVisible('quiz')
  const quizPanelIsOpen = !!panelIsOpen(quizContentIsVisible)

  const progressionGatePanelIsVisible = () => {
    if (missionState.ui.focusedContent !== LessonContentViews.PROGRESSION_GATE) {
      return
    }
    return getOpenedPanel() === LessonContentViews.PROGRESSION_GATE
  }
  const progressionGateContentIsVisible = progressionGatePanelIsVisible()
  const progressionGateIsOpen = !!panelIsOpen(progressionGateContentIsVisible)
  const missionPanelIsVisible = () => {
    if (missionState.ui.focusedContent !== LessonContentViews.MISSION) {
      return
    }
    return getOpenedPanel() === LessonContentViews.MISSION
  }

  const missionContentIsVisible =  missionPanelIsVisible()
  const missionPanelIsOpen = !!panelIsOpen(missionContentIsVisible) && missionState.initialLoadDone
  const onBannerContinue = async () => {
    setCompletionBannerVisible(false)
    await missionDispatch({ type: MissionActions.CHANGE_VIEW, view: LessonContentViews.COMPLETION_MESSAGE })
  }

  const isFlowItemComplete = (index) => {
    return Boolean(
      missionState.activeMission.flow[index]?.progress?.completed &&
      missionState.activeMission.flow[index]?.progress?.completed !== '' // TODO: completion should come from userProgressController
    )
  }


  function getObjectivePanelContent() {
    const activeFlow = missionState.activeMission.flow[missionState.ui.curFlowIndex]
    if (fsMode && !!activeFlow && activeFlow.type === 'objective') {
      return activeFlow?.obj
    }

    if (positionedPanelContent?.flow && positionedPanelContent?.flow?.data?.type === 'objective') {
      return positionedPanelContent?.flow?.data?.obj
    }

    return {}
  }

  function getMissionPanelContent() {
    if (fsMode || !positionedPanelContent?.mission) {
      return missionState.activeMission
    }

    return positionedPanelContent?.mission
  }

  function getQuizPanelContent() {
    const activeFlow = missionState.activeMission.flow[missionState.ui.curFlowIndex]
    if (fsMode && !!activeFlow && activeFlow.type === 'quiz') {
      return { data: activeFlow, progress: activeFlow?.progress }
    }
    if (positionedPanelContent?.flow && positionedPanelContent?.flow?.data?.type === 'quiz') {
      return positionedPanelContent?.flow
    }
    return  { data: { obj: { title: '', questions: [] } } }
  }

  const setShowMissionSelector = (val) => {
    missionDispatch({ type: MissionActions.SHOW_MSN_SEL, isOpen: val })
  }

  const getMissionNumber = (id) => {
    const num = missionState.activeMissionPack.missions.findIndex(msn => msn.id === id)
    if (num === -1) {
      return 1
    }
    return num + 1
  }

  // Prevents the mission completion dialog from displaying the next missions number before it's transition is over
  const curMissionNumber = getMissionNumber(getMissionPanelContent()?.id)
  const isLast = missionState.ui.curFlowIndex >= missionState.activeMission.flow.length - 1
  const objectiveNumber = objectiveIndex(missionState.ui.curFlowIndex)

  const lastFlowContinueTs = React.useRef(Date.now())
  function onFlowContinue() {
    const now = Date.now()
    if (lastFlowContinueTs.current + 500 > now) {
      return
    }

    lastFlowContinueTs.current = now
    if (completionBannerVisible) {
      onBannerContinue()
      return
    }

    onObjectiveCompletion()
  }

  function onObjectiveCompletion() {
    handleTransitioningFromFsToNonFs(missionState.ui.curFlowIndex + 1, missionState)
    iterateContentSequenceIndex()
  }

  function onPanelCollapse() {
    missionDispatch({ type: MissionActions.VIEW_NOTHING })
  }

  function handleMissionSelectClose() {
    setShowMissionSelector(false)
  }

  return (
    <>
      {/* Mission Panels */}
      <MissionPanel
        open={missionPanelIsOpen}
        missionViewElt={props.missionViewElt}
        missionNumber={curMissionNumber}
        json={getMissionPanelContent()}
        onCollapseButton={onPanelCollapse}
        onContinueButton={changeContentSequenceIndexToNextUncompletedObjective}
        maximizeWindow={missionState.ui.windowMaximized || !!positionedPanelContent?.displayFullscreen}
        setMaximizeWindow={setMaximizeWindow}
        onPanelExit={onPanelExit}
        onPanelEnter={onPanelEnter}
        displayFullscreen={positionedPanelContent?.displayFullscreen}
        panelSlidingDisabled={fsMode}
      />
      <ObjectivePanel
        open={objectivePanelIsOpen}
        missionViewElt={props.missionViewElt}
        isCompleted={isFlowItemComplete(missionState.ui.curFlowIndex)}
        isLast={isLast}
        objectiveNumber={objectiveNumber}
        json={getObjectivePanelContent()}
        onCollapseButton={onPanelCollapse}
        onContinueButton={onFlowContinue}
        maximizeWindow={missionState.ui.windowMaximized || !!positionedPanelContent?.displayFullscreen}
        setMaximizeWindow={setMaximizeWindow}
        onPanelExit={onPanelExit}
        onPanelEnter={onPanelEnter}
        displayFullscreen={positionedPanelContent?.displayFullscreen}
        panelSlidingDisabled={fsMode}
      />
      <QuizPanel
        open={quizPanelIsOpen}
        missionViewElt={props.missionViewElt}
        isCompleted={isFlowItemComplete(missionState.ui.curFlowIndex)}
        isLast={isLast}
        quiz={getQuizPanelContent()}
        onCollapseButton={onPanelCollapse}
        onContinueButton={onFlowContinue}
        maximizeWindow={missionState.ui.windowMaximized || !!positionedPanelContent?.displayFullscreen}
        setMaximizeWindow={setMaximizeWindow}
        onPanelExit={onPanelExit}
        onPanelEnter={onPanelEnter}
        displayFullscreen={positionedPanelContent?.displayFullscreen}
        panelSlidingDisabled={fsMode}
      />
      <ProgressionGatePanel
        missionViewElt={props.missionViewElt}
        onCollapseButton={onPanelCollapse}
        maximizeWindow={missionState.ui.windowMaximized || !!positionedPanelContent?.displayFullscreen}
        setMaximizeWindow={setMaximizeWindow}
        onPanelExit={onPanelExit}
        onPanelEnter={onPanelEnter}
        open={progressionGateIsOpen}
        panelSlidingDisabled={fsMode}
      />
      <CompletionBanner
        missionState={missionState}
        onBannerContinue={onBannerContinue}
        objectiveNumber={objectiveNumber}
        completionBannerVisible={completionBannerVisible}
        setCompletionBannerVisible={setCompletionBannerVisible}
      />
      {/* Dialogs */}
      <CompletionPanel
        missionViewElt={props.missionViewElt}
        curMissionNumber={curMissionNumber}
        continueHandler={onObjectiveCompletion}
        objectiveNumber={objectiveNumber}
        fsMode={fsMode}
        flowTypeOpen={getFlowTypeOpen()}
      />
      <MissionSelect
        open={missionState.ui.missionSelOpen}
        handleClose={handleMissionSelectClose}
      />
      {missionState.ui.isExploreMode && !missionState.requiresSim && <ExploreModeNoSimOverlayPanel missionViewElt={props.missionViewElt} />}
    </>
  )
}

function MissionBarButtons() {
  const classes = useStyles()
  const [missionState, missionDispatch] = useMission()

  function onMissionSelectIconClick() {
    missionDispatch({ type: MissionActions.SHOW_MSN_SEL, isOpen: !missionState.ui.missionSelOpen })
  }

  function exitExploreMode() {
    missionDispatch({ type: MissionActions.EXIT_EXPLORE_MODE })
  }

  function enterExploreMode() {
    missionDispatch({ type: MissionActions.ENTER_EXPLORE_MODE })
  }

  function toggleToolboxOpen() {
    missionDispatch({ type: MissionActions.TOOLBOX_SHOW, isOpen: !missionState.ui.toolboxOpen })
  }

  function toggleConsoleOpen() {
    missionDispatch({ type: MissionActions.CONSOLE_SHOW, isOpen: !missionState.ui.consoleOpen })
  }

  return (
    <>
      <div className={classes.bar}>
        <div className={classes.barItem} style={{ overflow: 'hidden' }}>
          <Tooltip
            title={missionState.activeMissionPack.title ? missionState.activeMissionPack.title : ''}
            placement='left'
            arrow>
            <IconButton
              id='mission-select-btn'
              onClick={onMissionSelectIconClick}
              className={classes.missionButton}
            >
              <Avatar
                className={classes.avatar}
                variant='square'
                src={missionState.activeMissionPack?.avatarBlob ? URL.createObjectURL(missionState.activeMissionPack?.avatarBlob) : ''}
              />
            </IconButton>
          </Tooltip>
          <ContentSequencePanel fsMode={fsMode} handleTransitioningFromFsToNonFs={handleTransitioningFromFsToNonFs}/>
        </div>
        <div className={classes.barItem}>
          {missionState.ui.isExploreMode ?
            <Tooltip title={<div style={{textAlign:'center'}}>Return to Mission<br/><em>(Exit Sandbox)</em></div>} placement='left' arrow>
              <IconButton
                id='explore-off-btn'
                onClick={exitExploreMode}
              >
                <SandboxOffIcon />
              </IconButton>
            </Tooltip> :
            <Tooltip title='Enter Sandbox Mode' placement='left' arrow>
              <IconButton
                id='explore-btn'
                onClick={enterExploreMode}
                disabled={missionState.activeMissionPack?.id === 'HWN'}
              >
                <SandboxIcon />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title={missionState.ui.toolboxOpen ? 'Close Toolbox' : 'Open Toolbox'} placement='left' arrow>
            <IconButton
              id='toolbox-show-btn'
              onClick={toggleToolboxOpen}
            >
              <BusinessCenterIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={missionState.ui.consoleOpen ? 'Close Console' : 'Open Console'} placement='left' arrow>
            <IconButton
              onClick={toggleConsoleOpen}
              className={classes.openDrawerButton}
              aria-label='Expand'
            >
              {missionState.ui.consoleOpen ? <KeyboardArrowDownIcon /> : <MenuIcon />}
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </>
  )
}

export default function MissionBar(props) {
  const [missionState, missionDispatch] = useMission()

  return (
    <>
      <MissionBarButtons />
      <MissionPanels {...props} />
      <GoogleSubmissionErrorDialog />
      <ValidatorUI anchorEl={props.missionViewElt} />
      <ToolboxPanel
        open={missionState.ui.toolboxOpen}
        missionViewElt={props.missionViewElt}
        onCollapseButton={() => missionDispatch({ type: MissionActions.TOOLBOX_SHOW, isOpen: false })}
        maximizeWindow={missionState.ui.toolboxMaximized}
        setMaximizeWindow={() => missionDispatch({ type: MissionActions.TOOLBOX_MAX, isMax: !missionState.ui.toolboxMaximized })}
      />
      <LoadingPanel />
      <GoogleSubmissionErrorDialog />
      <ValidatorUI anchorEl={props.missionViewElt} />
    </>
  )
}
