// Mission Select Dialog
import React from 'react'
import { Dialog } from '@material-ui/core'
import { useGroups } from '../contexts/GroupsContext'
import { ClassesDialogHeader } from './ClassesDialogHeader'
import { ClassesDialogContent } from './ClassesDialogContent'
import { Typography } from '@material-ui/core'

const DIALOG_WIDTH = 1250
function MyClassesDialog() {
  const { groupsDialogOpen } = useGroups()

  return (
    <Dialog
      open={groupsDialogOpen}
      keepMounted
      PaperProps={{
        style: {
          height: '79%',
          // backgroundColor: 'rgba(0,0,0,0.7)',
          overflow: 'hidden',
          maxWidth: DIALOG_WIDTH,
          width: DIALOG_WIDTH,
          boxShadow: '0 0 40px 5px rgba(0,0,0,0.6)',
        },
        elevation: 0,
      }}
    >
      <div style={{height: '100%'}}>
        <ClassesDialogHeader/>
        <Typography style={{marginLeft:'1em'}}>{'Activate a Class from the list'}</Typography>
        <div style={{padding: '5px 10px', height: 'calc(100% - 60px)'}}>
          <ClassesDialogContent />
        </div>
      </div>
    </Dialog>
  )
}

export { MyClassesDialog }
