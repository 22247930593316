// Attic Environment
import { Entity, simScene } from '../../SimScene'
import * as BABYLON from '@babylonjs/core'
import { TemplateLights, TemplateGround, TemplateSounds } from '../EnvTemplate'
import {
  disposePromise, loadGLB,
} from '../../utils/BabylonUtils'
import PbrLightingTextureEnv from '../../assets/MistyBrightSky.env'
import EnvModel from '../../assets/HauntedHouse/Attic.glb'
import TrunkModel from '../../assets/HauntedHouse/TreasureChest.glb'
import EnvThemeMusic from '../../assets/HauntedHouse/sounds/Attic.mp3'
import GameWinSound from '../../assets/HauntedHouse/sounds/GameWin.mp3'
import CodexAcquiredSound from '../../assets/HauntedHouse/sounds/CodexAcquired.mp3'
import { Trinkets } from './Trinkets'
import ParticleTexture from '../../assets/materials/flare.png'

const environmentName = 'Attic'

// Environment template options
const lightOptions = {
  envLightingName: 'MistyBrightSky',
  envLightingSource: PbrLightingTextureEnv,
  envIntensity: 0.2,
  fogMode: BABYLON.Scene.FOGMODE_EXP2,
  fogDensity: 0.003,
  fogColor: new BABYLON.Color3(0.9, 0.9, 0.85),
}
const soundOptions = {
  envMusicName: 'AtticThemeMusic',
  envMusicSource: EnvThemeMusic,
  envSoundFx: [
    {name: 'codexAcquiredSound', source: CodexAcquiredSound, options: { spatialSound: false, volume: 1.0 }},
    {name: 'gameWinSound', source: GameWinSound, options: { spatialSound: false, volume: 1.0 }},
  ],
}
const groundOptions = {
  envModelName: 'AtticModel',
  envModelSource: EnvModel,
}

// Default environment params which the Mission Editor may override
const defaultEnvParams = {
  'bot': [
    {
      'playerPosition': [0, 0, 38],
      'playerRotation': 180,
    },
  ],
  'rotateCam': null,  // Angles for rotate camera. Usual default is {alpha:2.5, beta:1.3, radius:4}
  'trinkets': [
    {
      pos: [5, 0, 5],
      rot: 0,
      scale: 1.0,
      scoreValue: 10,
      shape: null,   // null for random choice. Choices are: 'cat', 'ghost', 'pumpkin'
    },
  ],
  'evilVeil': false,
  'kittLeds': false,  // Show LEDs cycling on KITT poster
}

// Interactives - things to interact with in the environment:
//  * Movable objects, treats, NPCs, etc.
class Interactives extends Entity {
  constructor(mainEnv) {
    super()
    this.env = mainEnv
    this.kittLeds = new Array(8)  // Plane meshes
  }

  loadTrunk = async () => {
    const pos = [-6, 0.02, -29.4]
    const rot = 0
    this.trunk = await loadGLB(TrunkModel, 'Trunk', 1.0, pos, rot)
    return this.trunk
  }

  load = async (scene, params) => {
    this.scene = scene
    this.params = params
    this.timers = []  // timer IDs for cleanup
    this.disposeList = []

    const interactivesPromises = []

    interactivesPromises.push(this.loadTrunk())

    this.meshes = await Promise.all(interactivesPromises)
    this.disposeList.push(...this.meshes)
  }

  unload = async () => {
    // Stop any active timers
    this.timers.forEach(id => clearTimeout(id))

    await Promise.all(this.disposeList.map(disposePromise))

    // Post unload de-init
    if (this.veilMesh) {
      this.veilMesh.physicsImpostor = null
      this.veilMesh = null
    }
  }

  makeTrunkSpiritAnimations = () => {
    this.animSpiritEmissiveIntensity = new BABYLON.Animation('csparks', 'material.emissiveIntensity', 30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)

    this.animSpiritEmissiveIntensity.setKeys([
      { frame: 0, value: 1 },  // Idle pulsate 0-30
      { frame: 15, value: 0.2 },
      { frame: 30, value: 1 }, // Sputter 30-100
      { frame: 40, value: 0 },
      { frame: 50, value: 1 },
      { frame: 60, value: 0 },
      { frame: 70, value: 1 },
      { frame: 80, value: 0 },
      { frame: 90, value: 1 },
      { frame: 100, value: 0 },
    ])

    this.animFadeAlpha = new BABYLON.Animation('fadeAlpha', 'material.alpha', 30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)

    this.animFadeAlpha.setKeys([
      { frame: 0, value: 1 },
      { frame: 100, value: 0 },
    ])
  }

  makeCodexLEDAnimations = () => {
    this.animCycleEmissiveColor = new BABYLON.Animation('cledsColor', 'material.emissiveColor', 30,
      BABYLON.Animation.ANIMATIONTYPE_COLOR3, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)

    this.animCycleEmissiveColor.setKeys([
      { frame: 0, value: BABYLON.Color3.Blue() },
      { frame: 8, value: BABYLON.Color3.Red() },
      { frame: 16, value: BABYLON.Color3.Green() },
      { frame: 24, value: BABYLON.Color3.White() },
      { frame: 30, value: BABYLON.Color3.Blue() },
    ])

    this.animPulseEmissiveIntensity = new BABYLON.Animation('cledsIntensity', 'material.emissiveIntensity', 30,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE)

    this.animPulseEmissiveIntensity.setKeys([
      { frame: 0, value: 0.0 },
      { frame: 15, value: 1.0 },
      { frame: 30, value: 0.0 },
    ])
  }

  playTrunkSpiritSparks = () => {
    const loop = true
    const speed = 0.5
    return this.scene.beginDirectAnimation(this.trunkSpirit, [this.animSpiritEmissiveIntensity], 0, 30, loop, speed)
  }

  playTrunkSpiritSputter = () => {
    const loop = false
    const speed = 1.0
    this.scene.stopAnimation(this.trunkSpirit)
    this.scene.beginDirectAnimation(this.trunkSpirit, [this.animSpiritEmissiveIntensity], 0, 100, loop, speed)
    this.scene.beginDirectAnimation(this.trunkSpirit, [this.animFadeAlpha], 0, 100, loop, speed)
  }

  playCodexLEDthrob = () => {
    const loop = true
    const speed = 0.7
    // Fixed color, modulate intensity
    this.scene.stopAnimation(this.codexPixels, 'cledsColor')
    this.codexPixels.material.emissiveColor = BABYLON.Color3.Blue()
    return this.scene.beginDirectAnimation(this.codexPixels, [this.animPulseEmissiveIntensity], 0, 30, loop, speed)
  }

  playCodexRBGexciteLEDs = () => {
    const loop = true
    const speed = 1.5
    // Fixed intensity, modulate color
    this.scene.stopAnimation(this.codexPixels, 'cledsIntensity')
    this.codexPixels.material.emissiveIntensity = 1
    return this.scene.beginDirectAnimation(this.codexPixels, [this.animCycleEmissiveColor], 0, 30, loop, speed)
  }

  startParticleEmitter = (pos, dir1, dir2) => {
    // Celebrate! A particle point emitter coming from trunk. (Bring on the demerits!)
    // https://doc.babylonjs.com/features/featuresDeepDive/particles/particle_system/shape_emitters

    // Create a particle system
    const particleSystem = new BABYLON.ParticleSystem('particles', 2000, this.scene)  // capacity=2000

    // Texture of each particle
    particleSystem.particleTexture = new BABYLON.Texture(ParticleTexture, this.scene)

    // Where the particles come from
    const position = pos ?? [-6, 0.5, -29.4]
    particleSystem.emitter = new BABYLON.Vector3(...position) // the starting location

    // Colors of all particles
    particleSystem.color1 = new BABYLON.Color4(0.0, 0.9, 0.0, 1.0)
    particleSystem.color2 = new BABYLON.Color4(0.0, 0.5, 0.5, 1.0)
    particleSystem.colorDead = new BABYLON.Color4(0, 0, 0, 0.0)

    // Size of each particle (random between)
    particleSystem.minSize = 0.1
    particleSystem.maxSize = 0.5

    // Life time of each particle (random between)
    particleSystem.minLifeTime = 4.0
    particleSystem.maxLifeTime = 8.0

    particleSystem.gravity = new BABYLON.Vector3(0, -9.81, 0)

    // Emission rate
    particleSystem.emitRate = 1000

    // Emission Space
    const direction1 = dir1 ?? [-7, 8, 9]
    const direction2 = dir2 ?? [7, 8, 3]
    particleSystem.createPointEmitter(new BABYLON.Vector3(...direction1), new BABYLON.Vector3(...direction2))

    // Speed
    particleSystem.minEmitPower = 1
    particleSystem.maxEmitPower = 3
    particleSystem.updateSpeed = 0.01

    // Timing
    particleSystem.preWarmCycles = 100
    particleSystem.targetStopDuration = 4
    particleSystem.disposeOnStop = true

    // Start the particle system (with start delay in ms)
    particleSystem.start(1500)
  }

  configure = () => {
    if (this.params.kittLeds) {
      // Configure KITT
      let kittLedIndex = 0, kittLedDir = -1

      const timer = setInterval(() => {
        this.kittLeds[kittLedIndex].isVisible = false
        if (kittLedIndex === 7 || kittLedIndex ===  0) {
          kittLedDir *= -1
        }
        kittLedIndex += kittLedDir
        this.kittLeds[kittLedIndex].isVisible = true
      }, 100)
      this.timers.push(timer)
    }

    // Configure trunk
    const anim = this.trunk.animationGroups[0]  // Just one animation
    anim.speedRatio = 0.2
    anim.reset()
    this.trunkSfx = this.env.sounds.cloneSfx('codexAcquiredSound')
    this.trunkAnimation = anim

    this.trunkSpirit = this.trunk.getChildMeshes(false, m=>m.name==='GlowCrystal')[0]
    this.makeTrunkSpiritAnimations()

    // Configure CodeX
    this.codexLCD = this.trunk.getChildMeshes(false, m=>m.name==='LCD')[0]
    this.codexLCD.material.emissiveIntensity = 0.1    // Normal brightness "XYZZY" on display
    simScene.glLeds.addExcludedMesh(this.codexLCD)    // Exclude this mesh from the LED glow layer (no blur)
    this.codexPixels = this.trunk.getChildMeshes(false, m=>m.name==='CodeX_Pixels')[0]   // Currently single material shared among all Pixel LEDs
    this.makeCodexLEDAnimations()

    if (this.params.trunkOpen) {
      // Trunk has already been open. Make it so.
      this.trunkAnimation.start(false, 1, this.trunkAnimation.to)  // Last frame
      this.playTrunkSpiritSparks()
      this.playCodexLEDthrob()
    }

    // Configure veil
    if (this.veilMesh) {
      if (this.params.evilVeil) {
        this.veilMesh.checkCollisions = true // camera collisions
        this.veilMesh.isVisible = true
        this.veilMesh.material.emissiveColor.copyFromFloats(0.1, 0, 0.3)  // Just a little purple gloooow
        this.veilMesh.material.emissiveIntensity = 0.4
        this.veilMesh.material.alpha = 0.5
        this.veilMesh.setParent(null)  // Collapse transforms for physics
        this.veilMesh.physicsImpostor = new BABYLON.PhysicsImpostor(this.veilMesh, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: 1.0 })
        this.disposeList.push(this.veilMesh.physicsImpostor)
      } else {
        this.veilMesh.checkCollisions = false
        this.veilMesh.isVisible = false
      }
    }
  }

  openTrunk = () => {
    // Validator will call to open trunk and present CodeX
    this.trunkAnimation.play()
    this.playTrunkSpiritSparks()
    this.trunkSfx.play()
    this.playCodexLEDthrob()

    // Look at codex!
    const codexPos = this.trunk.position.clone()
    codexPos.y = 4
    this.scene.activeCamera.setTarget(codexPos)
  }

  releaseCodex = () => {
    // Validator will call to release CodeX after the spell is broken
    this.playTrunkSpiritSputter()
    this.playCodexRBGexciteLEDs()
    this.startParticleEmitter()

    // Look at codex!
    const codexPos = this.trunk.position.clone()
    codexPos.y = 4
    this.scene.activeCamera.setTarget(codexPos)
  }
}

class MainEnvironment extends Entity {
  constructor() {
    super()
    groundOptions.meshVisitor = this.meshVisitor
    this.name = environmentName
    this.lights = new TemplateLights(lightOptions)
    this.sounds = new TemplateSounds(soundOptions)
    this.ground = new TemplateGround(groundOptions)
    this.interactives = new Interactives(this)
    this.trinkets = new Trinkets(this.sounds)
    this.defaultParams = defaultEnvParams
  }
  meshVisitor = (mesh) => {
    if (mesh.name === 'EvilVeil') {
      this.interactives.veilMesh = mesh
    } else if (mesh.name.startsWith('Kitt')) {
      const ledIndex = parseInt(mesh.name[4])
      this.interactives.kittLeds[ledIndex] = mesh
      mesh.isVisible = false
    }
  }
  missionCompleteMusic = () => {
    if (this.levelEndSfx) {
      this.levelEndSfx.play()
    }
  }
  load = async (scene) => {
    this.scene = scene
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false

    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)

    await Promise.all([
      this.lights.load(scene),
      this.sounds.load(scene),
      this.interactives.load(scene, this.params),
      this.trinkets.load(this.params),
    ])

    // Configuration after async loading
    this.interactives.configure()
    this.trinkets.configure()
    this.levelEndSfx = this.sounds.cloneSfx('gameWinSound')

    // --- DEBUG ---
    // this.sounds.setVolume([0, 0.5])
    // window.simEnv = this
  }
  unload = async () => {
    await Promise.all([
      this.lights.unload(),
      this.sounds.unload(),
      this.ground.unload(),
      this.interactives.unload(),
      this.trinkets.unload(),
    ])
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = params  // used by base Entity class
  }
  setVolume = (volumeParams) => {
    this.sounds.setVolume(volumeParams)
  }
  setTarget = (playerEntity, playerController) => {
    this.playerEntity = playerEntity
    this.playerController = playerController

    // Disallow keyboard CB buttons for this challenge (no driving!)
    if (this.playerController) {
      this.playerController.allowKeyboardButtons = false

      // Not using prox or ls, save CPU
      this.playerController.sensorOverrides.prox = (num) => {}
      this.playerController.sensorOverrides.ls = (num) => {}
    }
  }
}

export default MainEnvironment
