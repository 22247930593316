import React from 'react'
import FiriaMarkdown from '../../FiriaMarkdown'

function lessonSectionPanelMarkdownWrapper(Component) {
  return ({ pre, post, ...args }) => (
    <>
      {
        !!pre ?
          <FiriaMarkdown>
            {pre}
          </FiriaMarkdown>:
          null
      }
      <Component {...args}/>
      {
        !!post ?
          <FiriaMarkdown>
            {post}
          </FiriaMarkdown> :
          null
      }
    </>
  )
}

export { lessonSectionPanelMarkdownWrapper }