const getExtraSpaces = ({ code, idx }) => {
  const spaces = `${code.split('\n').length+2}`.length - `${idx}`.length
  return spaces > 0 ? spaces : 0
}

// Adds numbers to the left side of a codeblock
const addCodeBlockNumbers = ({ code }) => {
  var idx = 0

  var finishedCode = ''
  code.split('\n').forEach((line, index) => {
    idx += 1
    finishedCode += `${' '.repeat(getExtraSpaces({ code, idx }))}${idx}  ${line}\n`
  })

  if (finishedCode === ''){
    finishedCode = `${' '.repeat(getExtraSpaces({ code, idx }))}${idx}  \n`
  }

  return finishedCode
}

export default addCodeBlockNumbers