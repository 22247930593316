import React from 'react'
import { Divider, CircularProgress, Button, Dialog, Typography } from '@material-ui/core'
import { useGroups, toggleAuthorizationDialogOpen, setAuthorizationRequiredState } from './contexts/GroupsContext'
import { FiriaGreenButton } from './FiriaGreenButton'
import { authorizeGroupLMS } from './content-manager/groups/group-use-cases/authorizeGroupLMS'
import { getAuthorizationStatusFromCloud } from './content-manager/groups/group-gateways'

function AuthorizationAwaitingPanel({ handleAuthorizeClick }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Typography style={{fontSize: 16}}>
        {'Awaiting authorization.'}
      </Typography>
      <Typography style={{fontSize: 12}}>
        {'(A window should have popped out)'}
      </Typography>
      <div style={{height: 15}}></div>
      <CircularProgress size={50} style={{color: '#4CAF50'}} />
      <div style={{height: 55}}></div>
      <Typography style={{fontSize: 13}}>
        {'Did something go wrong?'}
      </Typography>
      <div style={{height: 10}}></div>
      <FiriaGreenButton onClick={handleAuthorizeClick}>
        {'Retry'}
      </FiriaGreenButton>
    </div>
  )
}

function AuthorizationBasePanel({ handleAuthorizeClick, children }) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
      <div style={{width: '70%'}}>
        <Typography style={{ textAlign: 'center' }}>
          {children}
        </Typography>
      </div>
      <div style={{height: 15}}></div>
      <FiriaGreenButton onClick={handleAuthorizeClick}>
        {'Authorize'}
      </FiriaGreenButton>
    </div>
  )
}

function AuthorizationLandingPanel({ handleAuthorizeClick }) {
  return (
    <AuthorizationBasePanel handleAuthorizeClick={handleAuthorizeClick}>
      {'This class is integrated with Google Classroom and requires authorization to participate.'}
    </AuthorizationBasePanel>
  )
}

function AuthorizationErrorPanel({ handleAuthorizeClick }) {
  return (
    <AuthorizationBasePanel handleAuthorizeClick={handleAuthorizeClick}>
      {'An error occurred during authorization!'}
    </AuthorizationBasePanel>
  )
}

function AuthorizationDialogHeader() {
  return (
    <div style={{paddingBottom: '6px'}}>
      <div style={{padding: '6px 15px'}}>
        <Typography style={{fontSize: 17}}>
          {'Class LMS Authorization'}
        </Typography>
      </div>
      <Divider style={{margin: '0px 10px'}}/>
    </div>
  )
}

function AuthorizationDialogFooter() {
  return (
    <div style={{paddingTop: '6px'}}>
      <Divider style={{margin: '0px 10px'}}/>
      <div style={{display: 'flex', alignItems: 'center', padding: '6px 15px'}}>
        <div style={{flex: 1}}></div>
        <Button onClick={() => {
          setAuthorizationRequiredState(false)
        }}>
          {'Cancel'}
        </Button>
      </div>
    </div>

  )
}

function AuthorizationDialog() {
  const { authorizationRequired, groups, authorizingGroupId, authorizingCb } = useGroups()
  const [authorizationStartedTs, setAuthorizationStartedTs] = React.useState(null)
  const [authStatus, setAuthStatus] = React.useState(null)
  const authorizingGroupLMSId = groups.find(group => group.id === authorizingGroupId)?.lmsId

  async function handleAuthorizeClick() {
    setAuthorizationStartedTs(new Date())
    await authorizeGroupLMS(authorizingGroupLMSId)
  }

  const authorizationStatusPollerRef = React.useRef(null)
  React.useEffect(() => {
    if (authorizationStartedTs) {
      authorizationStatusPollerRef.current = setInterval(async () => {
        const authState = await getAuthorizationStatusFromCloud(authorizingGroupLMSId)
        setAuthStatus(authState)
        if (!(new Date(authState?.started) >= authorizationStartedTs)) {
          return
        }
        if (authState?.completed) {
          clearInterval(authorizationStatusPollerRef.current)
        }
        if (authState?.succeeded) {
          if (!!authorizingCb) {
            authorizingCb()
          }
          setAuthorizationRequiredState(false)
        }
      }, 500)
    } else {
      clearInterval(authorizationStatusPollerRef.current)
    }

    return () => {
      clearInterval(authorizationStatusPollerRef.current)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationStartedTs])

  function handlePanelExit() {
    setAuthStatus(null)
    clearInterval(authorizationStatusPollerRef.current)
    setAuthorizationStartedTs(null)
  }

  const LANDING_PANEL_VISIBLE = !authorizationStartedTs
  const ERROR_PANEL_VISIBLE = authStatus?.error
  const AUTH_AWAITING_PANEL_VISIBLE = !!authorizationStartedTs && !ERROR_PANEL_VISIBLE

  return (
    <Dialog
      open={!!authorizationRequired}
      onClose={toggleAuthorizationDialogOpen}
      onExited={handlePanelExit}
      style={{height: '85%', zIndex: 1500}}
      keepMounted
    >
      <div style={{width: 600}}>
        <AuthorizationDialogHeader />
        <div style={{padding: '15px', display:'flex', flexDirection: 'column', gap: 25}}>
          {LANDING_PANEL_VISIBLE  ?
            <AuthorizationLandingPanel
              handleAuthorizeClick={handleAuthorizeClick}
            /> : null}
          {AUTH_AWAITING_PANEL_VISIBLE ?
            <AuthorizationAwaitingPanel
              handleAuthorizeClick={handleAuthorizeClick}
              authorizationStartedTs={authorizationStartedTs}
              setAuthStatus={setAuthStatus}
            /> : null}
          {ERROR_PANEL_VISIBLE ?
            <AuthorizationErrorPanel
              handleAuthorizeClick={handleAuthorizeClick}
            /> :
            null}
        </div>
        <AuthorizationDialogFooter />
      </div>
    </Dialog>
  )
}

export { AuthorizationDialog }