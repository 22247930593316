import { Targets } from './Players'

// Error codes for navigator.usb
const USB_DEV_DISCONNECT = 8
const USB_INVALID_STATE_ERROR = 11  // name="InvalidStateError"
const USB_TRANSFER_ERROR = 19
// const USB_TRANSFER_CANCEL = ??
const USB_ABORT_ERROR = 20

const deviceFilters = [
  // Microbit
  {
    'vendorId': 0x0D28,
    'productId': 0x0204,
  },
  // CodeBot-CB2
  {
    'vendorId': 0x0483,
    'productId': 0x5740,
  },
  // CodeBot-CB3
  {
    'vendorId': 0x544d,
    'productId': 0xcb03,
  },
  // CodeX Mk1
  {
    'vendorId': 0x544d,
    'productId': 0xc0de,
  },
  // CodeAIR
  {
    'vendorId': 0x544d,
    'productId': 0xca00,
  },
]

class WebSerialPort {
  constructor(device) {
    this.device = device
    this.interfaceNumber = null
    this.endpointIn = null
    this.endpointOut = null
  }

  onReceiveError = (error) => {
    // console.warn('In WebUsbControl.onReceiveError')
    if (error.code !== USB_TRANSFER_ERROR &&
      error.code !== USB_DEV_DISCONNECT &&
      error.code !== USB_ABORT_ERROR) {
      console.error(`USB Receive error: name=${error.name}, code=${error.code}`, 'usb', 'error', error)
    }
  }

  read = async () => {
    try {
      const result = await this.device.transferIn(this.endpointIn, 512)
      this.onReceive(result.data)
      this.read()
    } catch (e) {
      try {
        if (e.code === USB_DEV_DISCONNECT) {
          // Do not continue to thrash readLoop in disconnect case.
          console.log('Web USB read ended in device disconnect')
        } else if (e.code === USB_INVALID_STATE_ERROR) {
          // Experimental...
          console.error('WebUSB: InvalidStateError', e)
          // await this.device.close()
        } else {
          // Transfer interrupted (possible timeout) - initiate another read
          // Known cases to accept: USB_TRANSFER_ERROR, USB_TRANSFER_CANCEL, USB_ABORT_ERROR
          this.read()
        }
        this.onReceiveError(e)
      } catch (err) {
        // EXPERIMENTAL -- TODO: test this exception case, make sure we don't spinlock Chrome here...
        console.error('USB unhandled transferIn error', 'usb', 'error', err)
        // this.read()  // TODO It *was* spinlocking!
      }
    }
  }

  connect = async (onReceiveCb) => {
    if (this.device.opened) {
      // console.warn('Trying to connect to an already opened device!')
      return
    }
    // console.log('In WebUsbControl.connect(', onReceiveCb, ')')
    this.onReceive = onReceiveCb
    let isClaimed = false
    try {
      await this.device.open()
    } catch (e) {
      console.error('WebUSB: Error opening device.', e)
      return
    }
    try {
      if (this.device.configuration === null) {
        await this.device.selectConfiguration(1)
      }
      this.device.configuration.interfaces.forEach((element) => {
        element.alternates.forEach((elementalt) => {
          if (elementalt.interfaceClass === 0xFF) {
            this.interfaceNumber = element.interfaceNumber
            isClaimed = element.claimed
            elementalt.endpoints.forEach((elementendpoint) => {
              if (elementendpoint.direction === 'out') {
                this.endpointOut = elementendpoint.endpointNumber
              } else if (elementendpoint.direction === 'in') {
                this.endpointIn = elementendpoint.endpointNumber
              }
            })
          }
        })
      })
    } catch (e) {
      console.error('WebUSB: Error selecting configuration of device')
      return
    }
    if (!isClaimed) {
      try {
        await this.device.claimInterface(this.interfaceNumber)
      } catch (e) {
        console.error('WebUSB: Error claiming interface for device')
        return
      }
    }
    // try {
    //   await this.device.selectAlternateInterface(this.interfaceNumber, 0)
    // } catch (e) {
    //   console.error('WebUSB: Error select alternate interface for device')
    //   return
    // }
    try {
      await this.device.controlTransferOut({
        'requestType': 'class',
        'recipient': 'interface',
        'request': 0x22,
        'value': 0x01,
        'index': this.interfaceNumber,
      })
    } catch (e) {
      console.error('WebUSB: Error Transferring 0x22 request')
      return
    }
    try {
      this.read()
    } catch (e) {
      console.error('WebUSB: Error starting up the read loop.')
    }
  }

  disconnect = async () => {
    // console.trace('In WebUsbControl.disconnect')
    await this.device.controlTransferOut({
      'requestType': 'class',
      'recipient': 'interface',
      'request': 0x22,
      'value': 0x00,
      'index': this.interfaceNumber,
    })

    await this.device.close()
  }

  send = async (data) => {
    try {
      await this.device.transferOut(this.endpointOut, data)
      // const usbTxOutRes = await this.device.transferOut(this.endpointOut, data)
      // console.log('tx:', data, usbTxOutRes)
    } catch (e) {
      // console.error('USB Transfer out error', 'usb', 'error', e)  // This happens on disconnect so it's OK
      try {
        await this.device.close()
      } catch (f) {
      }
    }
  }
}

class WebUsbControl {
  constructor() {
    this.port = null
    this.enabled = !!navigator.usb

    // console.log('In WebUsbControl constructor()')

    this.deviceDisconnectedCb = () => {
      // console.log('device disconnected CB -- you probably shouldn\'t see this')
    }
    this.deviceConnectedCb = () => {
      // console.warn('device connected CB -- you shouldn\'t see this!')
    }
    this.dataReceivedCb = (data) => {
      // console.log('data rx CB', data)
    }
    // Create simple event notifiers for usb connect/disconnect.
    // Fires when paired device is plugged/unplugged, OR as result of connectAvailable() resolve on start
    if (this.enabled) {
      navigator.usb.addEventListener('connect', (ev) => {
        // console.log('Event! Connected to ', ev.device)
        if (!this.isIncompatibleWebUSBDevice(ev.device)) {
          // console.log('attempt connect')
          this.connectAvailable()
        }
      })
      navigator.usb.addEventListener('disconnect', (ev) => {
        // console.log('disconnecting from device: ', ev.device)
        this.dataReceivedCb('\nDisconnected.')
        this.disconnect()
      })
    }
  }

  isIncompatibleWebUSBDevice = (device) => {
    // Normally every device that passes our 'requestPort filter' is compatible. This allows us
    // to detect special-case incompatible devices that match our filter settings.
    // Prevent connnection to incompatible DAPLINK WebUSB devices (newer "stock" micro:bits)
    return (device.vendorId === 0x0D28 && device.deviceVersionMajor !== 1)
  }

  getPorts = async () => {
    const devices = await navigator.usb.getDevices()
    const compatDevices = devices.filter(device => !this.isIncompatibleWebUSBDevice(device))
    return compatDevices.map(device => new WebSerialPort(device))
  }

  disconnect = async () => {
    this.port = null
    this.deviceDisconnectedCb()
  }

  read = (data) => {
    const dataBuf = new Uint8Array(data.buffer)
    this.dataReceivedCb(dataBuf)
  }

  send = async (text) => {
    let encdr = new TextEncoder()
    if (this.port && this.port.send) {
      await this.port.send(encdr.encode(text))
    } else {
      // TODO should we try to connect here?
      console.warn('USB send called with no port', 'usb', 'info')
    }
  }

  connectAvailable = async () => {
    // console.trace('In WebUsbControl.connectAvailable - bugs lay here!')
    try {
      const ports = await this.getPorts()
      if (ports.length === 0) {
        // this.print('\nNo devices connected.');   // Is this useful?? (DBE)
        // console.log('WebUsb: No devices connected.')
      } else {
        this.port = ports[0]
        this.connect()
      }
    } catch (e) {
      console.error('WebUsb exception in GetPorts: ', e)
    }
  }

  connect = async () => {
    try {
      await this.port.connect(this.read)
      // console.log(`USB connected to device ${this.port.device.productName}`, 'usb', 'info')
      let productName = null
      switch (this.port.device.productName) {
        case 'CodeBot CB2':
          productName = Targets.USB_CB2
          break
        case 'CodeBot':
          productName = Targets.USB_CB3
          break
        case 'CodeX':
          productName = Targets.USB_CODEX
          break
        case 'CodeAIR':
          productName = Targets.USB_CODEAIR
          break
        default:
          productName = null  // UNKNOWN!
      }
      this.deviceConnectedCb(productName)
    } catch (e) {
      console.error('USB connection error', 'usb', 'error', e)
      this.disconnect()
    }
  }

  requestPort = async () => {
    let device = null
    try {
      device = await navigator.usb.requestDevice({
        'filters': deviceFilters,
      })
    } catch (e) {
      console.error('USB requestDevice error', 'usb', 'error', e)
    }
    if (device) {
      try {
        if (this.isIncompatibleWebUSBDevice(device)) {
          console.warn('USB: Non FiriaLabs microbit selected!')
        } else {
          return new WebSerialPort(device)
        }
      } catch (e) {
        console.error('Error creating a new web serial port from device', e)
      }
    }
    return null
  }

  request = async () => {
    // console.log('USB device requested. Port is currently', this.port, 'usb')
    if (!this.port) {
      try {
        // console.log('Trying to request USB port...')
        const selectedPort = await this.requestPort()
        // console.log('Got USB port!', selectedPort)
        // If a user connects the USB AFTER opening the dialog - we need to make sure the connection
        // has not already been re-established
        if (!this.port && selectedPort) {
          this.port = selectedPort
          // console.log('Set WebUsbControl.port')
          this.connect()
        }
      } catch (e) {
        console.error('Error selecting USB device', 'usb', 'info', e.message)
        this.disconnect()
      }
    } else {
      // TEST - how did we get in this state?
      // console.error('WebUsb: reconnecting to already established port')
      // console.log('in WebUsbControl.request() where port exists. Doing nothing.')
      // this.connect() // DO NOTHING
    }
  }

  isConnected = () => {
    return this.port !== null
  }
}

const webUsbController = new WebUsbControl()
export { webUsbController }
