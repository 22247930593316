import PeripheralBase from './PeripheralBase'
import * as BABYLON from '@babylonjs/core'
import { loadGLBWithPhysics, disposePromise } from '../../utils/BabylonUtils'
import NeoStripModel from '../../assets/Neo16.glb'

class Neopixel16 extends PeripheralBase {
  // 16-pixel strip
  constructor(pos, rot, dataPin) {
    super()
    this.numPixels = 16
    pos = pos ?? [0, 0, 0]
    rot = rot ?? [0, 0, 0]
    this.pos = new BABYLON.Vector3(...pos)
    this.rot = new BABYLON.Vector3(...rot.map(x => x * Math.PI / 180))
    this.dataPin = dataPin
  }

  load = async (scene, parent, posVec3, rotVec3) => {
    if (posVec3) {
      this.pos.addInPlace(posVec3)
    }
    if (rotVec3) {
      this.rot.addInPlace(rotVec3)
    }
    this.mesh = await loadGLBWithPhysics(NeoStripModel, 'Neo16', { mass: 0 }, 1.0, this.pos, this.rot, scene)
    this.mesh.parent = parent

    this.createLeds()
  }

  unload = async () => {
    await Promise.all([this.mesh, ...this.ledMeshes].map(disposePromise))
  }

  // The 'neopixel' lib uses the UART as an async stream for neopixel data.
  hasUart = () => {
    return true
  }

  uartWrite = (pin, baudrate, bits, parity, stopBits, bytesArr) => {
    if (pin !== this.dataPin || bytesArr.length !== (3 * this.numPixels)) {
      return
    }

    let colorTuple = []
    const colors = bytesArr.reduce((f, c) => {
      colorTuple.push(c)
      if (colorTuple.length === 3) {
        f.push(colorTuple)
        colorTuple = []
      }
      return f
    }, [])

    this.setLedColors(colors)
  }

  createLeds = () => {
    // Create glowing transluscent discs to serve as Neopixel LED elements

    // First collect all the Neopix meshes in ordered array to access their positions
    const neoMeshes = new Array(this.numPixels)
    // this.mesh.getChildTransformNodes(false, (t) => {
    this.mesh.getChildren((t) => {
      const m = t.name.match(/LED\.(\d\d\d)$/)
      if (m) {
        const index = parseInt(m[1])
        if (index < this.numPixels) {
          neoMeshes[index] = t
        }
      }
    }, false)

    // Now for the discs
    this.ledMeshes = []
    for (let i = 0; i < this.numPixels; ++i) {
      if (!neoMeshes[i]) {
        continue
      }

      const led = BABYLON.MeshBuilder.CreateCylinder(neoMeshes[i].name + '-element', {height: 0.01, diameter: 0.03})
      led.material = new BABYLON.StandardMaterial('')
      // led.material.diffuseColor = new BABYLON.Color3(0.9, 0.9, 0.9)
      led.material.alpha = 0
      led.material.emissiveColor = new BABYLON.Color3(0, 0, 0)
      led.parent = this.mesh
      led.position = neoMeshes[i].position.clone()
      led.position.addInPlaceFromFloats(-.06, 0, 0)  // Nearly flush with face
      led.rotation.z = Math.PI / 2

      this.ledMeshes.push(led)
    }
  }

  setLedColors = (colors) => {
    this.ledMeshes.forEach((led, i) => {
      led.material.emissiveColor = BABYLON.Color3.FromInts(...colors[i])

      if (colors[i].every(val => val < 9)) {
        led.material.alpha = Math.max(...colors[i]) / 10
      } else {
        led.material.alpha = 0.8
      }
    })
  }
}

export default Neopixel16
