// SceneComponent - based on simple Babylonjs example of using Babylon with React.JS
import { Engine, Scene } from '@babylonjs/core'
import React, { useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'

export default (props) => {
  const reactCanvas = useRef(null)
  const { antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, requiresSim, ...rest } = props

  const [loaded, setLoaded] = useState(false)
  const [scene, setScene] = useState(null)

  // Debouncing the resize events.
  // TODO: Why do we see scaling/jumps when resizing even with fine-grained debounce?
  const resizeObserver = new ResizeObserver(debounce(() => {
    if (scene) {
      scene.getEngine().resize()
      // console.log("resize")
    }
  }, 50))

  useEffect(() => {
    if (reactCanvas) {
      const canvasElement = reactCanvas.current
      canvasElement.style.outline = 'none'
      resizeObserver.observe(canvasElement)
      return () => {
        resizeObserver.unobserve(canvasElement)
      }
    }
  }, [scene, resizeObserver])

  const renderSim = React.useRef(requiresSim)
  useEffect(() => {
    renderSim.current = requiresSim
  }, [requiresSim])

  useEffect(() => {
    // Inialize the Babylon engine just once.
    if (!loaded) {
      const handleSceneLoaded = async () => {
        setLoaded(true)
        const engine = new Engine(reactCanvas.current, antialias, engineOptions, adaptToDeviceRatio)
        const scene = new Scene(engine, sceneOptions)
        setScene(scene)
        if (scene.isReady()) {
          await props.onSceneReady(scene)
        } else {
          scene.onReadyObservable.addOnce(async scene => await props.onSceneReady(scene))
        }

        engine.runRenderLoop(async () => {
          if (typeof onRender === 'function') {
            await onRender(scene)
          }
          // console.log('REND SIM: ', renderSim.current)
          if (renderSim.current) {
            scene.render()
          }
        })
      }
      handleSceneLoaded()
    }

    // No cleanup phase, despite what BabylonJS docs recommend. (Firia - understand this better!?)
    // console.log("Effect: SceneComponent was rendered.")
    /*
        return () => {
            if (scene !== null) {
                scene.dispose();
            }
        }
        */
  }, [loaded, antialias, engineOptions, adaptToDeviceRatio, sceneOptions, props, onRender, scene, renderSim])

  useEffect(() => {
    // Event handler to unlock audio
    const unlockAudio = () => {
      // console.log('Unlock audio!')
      if (!Engine.audioEngine.unlocked) {
        try {
        // Try to resume audio context
          Engine.audioEngine.unlock()

          // Once unlocked, remove the event listeners so this doesn't keep firing
          document.removeEventListener('click', unlockAudio)
          document.removeEventListener('touchstart', unlockAudio)
          document.removeEventListener('keydown', unlockAudio)
        } catch (err) {
          //
        }
      }
    }

    // Attach the event handler to various user gesture events
    document.addEventListener('click', unlockAudio)
    document.addEventListener('touchstart', unlockAudio)
    document.addEventListener('keydown', unlockAudio)
  }, [])

  // Prevent pinch-zoom on trackpad from bubbling to browser.
  // - Without this, a pinch zoom will also zoom the UI components outside canvas.
  React.useLayoutEffect(() => {
    const target = reactCanvas.current
    if (!target) {
      return
    }
    const disablePinchZoom = (e) => {
      e.preventDefault()
    }
    target.addEventListener('wheel', disablePinchZoom, {capture: true, passive: false})
    return () => {
      target.removeEventListener('wheel', disablePinchZoom)
    }
  }, [])

  return (
    <canvas ref={reactCanvas} {...rest} />
  )
}