// Mission Select Dialog
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, Dialog, Button } from '@material-ui/core'
import { FiriaGreenButton } from '../FiriaGreenButton'
import { OutlinedTextField } from '../FileSystemModals'
import { joinGroupWithJoinCode } from '../content-manager/groups/group-use-cases/joinGroup'
import { GatewayResponseIndicator } from './GatewayResponseIndicator'
import { useGroups } from '../contexts/GroupsContext'


const useStyles = makeStyles(theme => ({
  joinCodeDialogRoot: {
    minWidth: '30%',
  },
}))

const JoinClassDialog = ({open, handleClose}) => {
  const classes = useStyles()
  const [joinCodeField, setJoinCodeField] = React.useState('')
  const { joinGroupResponse, joinGroupInProgress } = useGroups()

  const handleJoinCodeSubmit = async () => {
    // trim white spaces from join code before submitting
    const error = await joinGroupWithJoinCode(joinCodeField.trim().toLowerCase())
    if (error){
      return
    }
    setJoinCodeField('')
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{height: '85%'}}
      keepMounted
      PaperProps={{
        classes: { root: classes.joinCodeDialogRoot },
      }}
    >
      <div style={{padding: '15px', display:'flex', flexDirection: 'column', gap: 25}}>
        <Typography>
          Enter Join Code
        </Typography>
        <OutlinedTextField
          fullWidth
          label={'Join Code'}
          value={joinCodeField}
          onChange={ev => (setJoinCodeField(ev.target.value))}
        />
        <div style={{display:'flex', alignItems:'center', justifyContent: 'flex-end', gap: 10}}>
          <GatewayResponseIndicator
            fetchState={joinGroupResponse}
            open={open}
            placement='bottom'
          />
          <FiriaGreenButton
            onClick={handleJoinCodeSubmit}
            disabled={joinCodeField === '' || joinGroupInProgress}
          >
            Submit Join Code
          </FiriaGreenButton>
          <Button
            onClick={handleClose}
            disabled={joinGroupInProgress}
          >
            Close
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export { JoinClassDialog }