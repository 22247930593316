const peripheralsKitData = [
  {
    title: 'Button',
    description: 'A standard momentary push button. Buttons are used in a wide variety of applications to take user input.',
    selector: 'pk_button',
    powerInput: '0 - 5V',
    image: 'peripherals/pk_button.png',
  },
  {
    title: 'Motion Sensor',
    description: 'A PIR (Passive Infrared) Sensor measures IR Light radiating from objects. Typically used in applications such as motion lights and alarms.',
    selector: 'pk_pir',
    powerInput: '2.7 - 3.3V',
    image: 'peripherals/pk_pir.png',
  },
  {
    title: 'White LED',
    description: 'A white LED (Light Emitting Diode). Outputs a white light.',
    selector: 'pk_wh_led',
    powerInput: '2 - 5V',
    image: 'peripherals/pk_wh_led.png',
  },
  {
    title: '180 Servo',
    description: 'More commonly called a Positional Servo. This servo can rotate clockwise or counterclockwise 180 degrees. It can be used to move project components to a specific location.',
    selector: 'pk_180_serv',
    powerInput: '3 - 5.5V',
    image: 'peripherals/pk_180_serv.png',
  },
  {
    title: 'Switch',
    description: 'This switch locks into place when pressed. Switches are generally used to apply power or change a setting.',
    selector: 'pk_switch',
    powerInput: '0 - 5V',
    image: 'peripherals/pk_switch.png',
  },
  {
    title: 'Temperature Sensor',
    description: 'Can read a raw value of the temperature. Requires some calibration to provide a specific temperature value in C or F.',
    selector: 'pk_temp',
    powerInput: '2.7 - 5.5V',
    image: 'peripherals/pk_temp.png',
  },
  {
    title: 'Red LED',
    description: 'A red LED (Light Emitting Diode). Outputs a red light.',
    selector: 'pk_red_led',
    powerInput: '2 - 5V',
    image: 'peripherals/pk_red_led.png',
  },
  {
    title: '360 Servo',
    description: 'More commonly called a Continuous Rotation Servo. This servo can rotate 360 degrees. It operates continuously in the clockwise or counterclockwise direction.',
    selector: 'pk_360_serv',
    powerInput: '3 - 5.5V',
    image: 'peripherals/pk_360_serv.png',
  },
  {
    title: 'Potentiometer',
    description: 'Often referred to as a knob. The potentiometer can be physically turned. It returns a value based on the position it is set to.',
    selector: 'pk_pot',
    powerInput: '3 - 5V', // elecfreaks website says only for 5V... should work at 3V
    image: 'peripherals/pk_pot.png',
  },
  {
    title: 'Sound Sensor',
    description: 'This sensor is sensitive to sound intensity. It can be used to detect noises.',
    selector: 'pk_audio',
    powerInput: '3 - 5V',
    image: 'peripherals/pk_audio.png',
  },
  {
    title: '8 RGB LED Ring',
    description: '8 RGB (Red Green Blue) LED Ring. Also called a NeoPixel ring. This device has 8 individual RGB LED pixels. Pixels can be illuminated any color and assigned individually.',
    selector: 'pk_neo_pix',
    powerInput: '4 - 7V', // elecfreaks website says will not work at 3V
    image: 'peripherals/pk_neo_pix.png',
  },
  {
    title: 'Water Pump',
    description: 'This is a small water pump. It is designed for vertical, submersible operations and can be used to transfer water from one location to another.',
    selector: 'pk_pump',
    powerInput: '3 - 4.5V',
    image: 'peripherals/pk_pump.png',
  },
  {
    title: 'Microswitch',
    description: 'The microswitch is used in a wide variety of applications. Operationally, it is nothing more than a button. It can be used as a crash sensor to detect robot touch.',
    selector: 'pk_crash',
    powerInput: '0 - 5V',
    image: 'peripherals/pk_crash.png',
  },
  {
    title: 'Light Sensor',
    description: 'The photocell is used to measure the amount of ambient light. Many uses such as smoke detection, solar monitoring, and light dimming.',
    selector: 'pk_light',
    powerInput: '0 - 5V',
    image: 'peripherals/pk_light.png',
  },
  {
    title: 'Object Sensor',
    description: 'Sometimes referred to as a Hunt Sensor. It contains an LED that emits Infrared (IR) light, and a Phototransistor that detects IR light reflected from nearby objects. Many uses such as object detection and line following.',
    selector: 'pk_hunt',
    powerInput: '3 - 5V', // from the elecfreaks website.  should work at 3V?
    image: 'peripherals/pk_hunt.png',
  },
  {
    title: 'Soil Moisture Sensor',
    description: 'Detects the amount of moisture present in the soil surrounding it. Most often used to determine when a plant needs water.',
    selector: 'pk_soil',
    powerInput: '3.3 - 5V',
    image: 'peripherals/pk_soil.png',
  },
  {
    title: 'Relay',
    description: 'Can be used to switch larger power and voltages to devices. Could be used for a DC Motor or an AC Lamp. Contact Ratings: 2A 120VAC / 2A 24VDC',
    selector: 'pk_relay',
    powerInput: '2.5 - 3.9V',
    image: 'peripherals/pk_relay.png',
  },
]

export default peripheralsKitData
