const missionProgressKey = 'mission-item-progress'
const flowItemProgressKey = 'flow-item-progress'
const store = {}

function setUserProgressStoreDefaults(){
  store[missionProgressKey] = {}
  store[flowItemProgressKey] = {}
}
setUserProgressStoreDefaults()

function setMissionProgressInUserProgressStore(missionId, missionProgress){
  store[missionProgressKey][missionId] = missionProgress
}

function readMissionProgressFromUserProgressStore(missionId) {
  return store[missionProgressKey][missionId]
}

function readAllMissionProgressFromUserProgressStore() {
  return store[missionProgressKey]
}

function setFlowProgressInUserProgressStore(flowItemProgress){
  store[flowItemProgressKey][flowItemProgress.id] = flowItemProgress
}

function readFlowProgressFromUserProgressStore(flowItemId){
  return store[flowItemProgressKey][flowItemId]
}

export {
  setUserProgressStoreDefaults,
  setMissionProgressInUserProgressStore,
  readMissionProgressFromUserProgressStore,
  setFlowProgressInUserProgressStore,
  readFlowProgressFromUserProgressStore,
  readAllMissionProgressFromUserProgressStore,
}