// Mission Select Dialog
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons'
import { TableCell, CircularProgress } from '@material-ui/core'
import { useGroups } from '../contexts/GroupsContext'
import { RESPONSE_STATUS } from '../content-manager/fetch-responses'
import { genContrastColor } from '../utils/shade-highlight-tools'

const useStyles = makeStyles(theme => ({
  infinityCell: {
    fontSize: 22,
    height: 37,
  },
}))

function GenericCell({ children, fontSize=14, blackText=false, textColor, ...props }) {
  const classes = useStyles()
  return (
    <TableCell
      style={{fontSize, lineHeight: '14px', color: textColor ? textColor : blackText ? 'black' : null}}
      classes={{root: classes.infinityCell}}
      {...props}
    >
      {children}
    </TableCell>
  )
}

function SeatTableCell({ group }) {
  const { fetchFloatingLicenseSeatsResponse, activeGroupId } = useGroups()
  const groupActive = group.groupId === activeGroupId

  if (group?.default) {
    return (
      <GenericCell blackText={groupActive} fontSize={22}>
        {'∞'}
      </GenericCell>
    )
  }

  if (group.type === 'Assigned') {
    return (
      <GenericCell blackText={groupActive}>
        {'N/A'}
      </GenericCell>
    )
  }

  if (fetchFloatingLicenseSeatsResponse.status === RESPONSE_STATUS.IN_PROGRESS && group.seats === null) {
    return (
      <GenericCell blackText={groupActive}>
        <CircularProgress size={18} style={{color: groupActive ? 'black' : genContrastColor(1)}}/>
      </GenericCell>
    )
  }

  if (fetchFloatingLicenseSeatsResponse.status === RESPONSE_STATUS.IN_PROGRESS && group.seats !== null) {
    return (
      <GenericCell blackText={groupActive} textColor={groupActive ? '#636363' : '#949494'}>
        {`${group.activeUsers} / ${group.seats}`}
      </GenericCell>
    )
  }

  if (fetchFloatingLicenseSeatsResponse.status === RESPONSE_STATUS.FAILED) {
    return (
      <GenericCell blackText={groupActive}>
        <ErrorOutlineIcon style={{color: 'red', fontSize: 22, marginLeft: -2}} />
      </GenericCell>
    )
  }

  return (
    <GenericCell blackText={groupActive}>
      {`${group.activeUsers} / ${group.seats}`}
    </GenericCell>
  )
}

export { SeatTableCell }