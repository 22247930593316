const regexReplaceAll = ({regex, length, str}) => {
  var replacedStr = ''
  var idx = 0
  var tempStr = str
  while (idx !== -1) {
    idx = tempStr.search(regex)
    if (idx === -1) {
      replacedStr += tempStr
      continue
    }

    var numberStart = tempStr.slice(0, idx) + '   '
    const periodIdx = tempStr.slice(idx).search(/\./)
    numberStart += tempStr.slice(idx, idx+periodIdx+1)

    replacedStr += numberStart
    tempStr = tempStr.slice(idx+periodIdx+1)
  }

  return replacedStr
}

export default regexReplaceAll