// Coding Panel
import React from 'react'
import Button from '@material-ui/core/Button'
import { Dialog, Paper } from '@material-ui/core'

import Typography from '@material-ui/core/Typography'
import { useFileManagement } from './contexts/FileManagementContext'
import FiriaLabsBanner from './assets/firialabsbanner.png'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

const UserProgressFetchDialog = () => {
  const [fileManagementState] = useFileManagement()
  function refresh() {
    window.location.href = '/'
  }

  return (
    <Dialog  fullWidth maxWidth='md' open={fileManagementState.userProgressFetchFailed}
      style={{backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 5000, height: '80%'}}
    >
      <Paper
        style={{
          minWidth: '300px',
          padding: '50px',
          paddingTop: 0,
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          <img alt={''}  style={{display: 'flex', alignItems:'center'}} src={FiriaLabsBanner}/>
          <Typography
            align='center'
            variant='h4'
          >
            We apologize for the inconvenience.
          </Typography>
          <div>
            <Typography
              align='center'
              variant='body1'
              style={{ marginTop: '30px' }}
            >
              We're having difficulty fetching your progress. In order to protect your hard work, we've ended your session.
            </Typography>
            <Typography
              align='center'
              variant='subtitle1'
            >
              Please log back in. If the problem persists, contact support and we'll investigate the issue promptly.
            </Typography>
          </div>
          <div style={{ width: '100%', maxWidth: 550, textAlign: 'center', marginTop: '50px', display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'space-around' }}>
            <div style={{width: '49%'}}>
              <Button onClick={() => {
                window.open('mailto:support@firia.com')
              }} variant='contained' style={{minWidth: 225}} startIcon={<MailOutlineIcon />}>
              Contact Support
              </Button>
            </div>
            <div style={{width: '49%'}}>
              <Button variant='outlined' onClick={refresh} style={{minWidth: 225}}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </Dialog>
  )
}

export default UserProgressFetchDialog