import { validatorController } from './ValidatorControl'
import { FileOpStatus, FILE_DATA_BUFFER_SIZE, FileOperation } from './utils/file-operation-utils'
class SimFileSystemControl {
  constructor() {
    this.fsRequestCb = async () => {
      return [FileOpStatus.FILESYSTEM_NOT_READY, []]
    }
    this.fsActionCb = async () => {
      return [FileOpStatus.FILESYSTEM_NOT_READY]
    }
    this.updateTabCb = async () => {}
    this.deleteTabCb = async () => {}
  }

  setFsRequestCb = (cb) => {
    this.fsRequestCb = cb
  }

  setFsActionCb = (cb) => {
    this.fsActionCb = cb
  }

  setTabCbs = (updateTab, deleteTab) => {
    this.updateTabCb = updateTab
    this.deleteTabCb = deleteTab
  }

  processFileRequest = async (filename, operation) => {
    let [status, data] = await this.fsRequestCb(filename, operation)
    validatorController.handleFileOp(filename, operation, status, data)
    // the file management context will always return COMPLETE_FILE_READ unless there is an error
    if (data.length <= FILE_DATA_BUFFER_SIZE || status !== FileOpStatus.COMPLETE_FILE_READ) {
      return [status, data]
    }
    this.remainingFileData = data.slice(FILE_DATA_BUFFER_SIZE)
    return [FileOpStatus.PARTIAL_FILE_READ, data.slice(0, FILE_DATA_BUFFER_SIZE)]
  }

  readyNextFileData = async () => {
    if (this.remainingFileData.length <= FILE_DATA_BUFFER_SIZE) {
      return [FileOpStatus.COMPLETE_FILE_READ, this.remainingFileData]
    }
    let d = this.remainingFileData.slice(0, FILE_DATA_BUFFER_SIZE)
    this.remainingFileData = this.remainingFileData.slice(FILE_DATA_BUFFER_SIZE)
    return [FileOpStatus.PARTIAL_FILE_READ, d]
  }

  processFileAction = async (filename, operation, data) => {
    const status = await this.fsActionCb(filename, operation, data)
    validatorController.handleFileOp(filename, operation, status, data)
    return status
  }

  fileWriteByteArray = async (filename, data) => {
    // called from the mission context to skip the validator
    await this.fsActionCb(filename, FileOperation.WRITE_BINARY, data)
  }
}

const simFsController = new SimFileSystemControl()
export {
  simFsController,
}