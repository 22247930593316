// Mission Select Dialog
import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import LockIcon from '@material-ui/icons/Lock'
import DoneIcon from '@material-ui/icons/Done'
import RegionalDialog from './RegionalDialog'
import { CircularProgress, Typography } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'

import { useMission } from './contexts/MissionContext'
import { userProgressController } from './UserProgressControl'
import { changeActiveMission } from './content-manager/content-director/content-director-use-cases/changeActiveMission'
import { readMissionProgressFromUserProgressStore } from './content-manager/user-progress/user-progress-store'
import { isMissionManuallyLocked } from './content-manager/content-director/content-director-use-cases/isMissionManuallyLocked'
import { isMissionOrganizationallyUnlocked } from './content-manager/content-director/content-director-use-cases/isMissionOrganizationallyUnlocked'
import { isMissionGated } from './content-manager/groups/group-use-cases/isMissionGated'
import { missionWasStartedBeforeContestStarted } from './content-manager/contests/contest-use-cases/contest-erases-progress'

const useStyles = makeStyles(theme => ({
  listRoot: {
    padding: 0,
    width: '100%',
    maxWidth: 472,
    backgroundColor: theme.palette.background.paper,
    height:'100%',
  },
  selectRoot: {
    '&:after': {
      borderColor: 'white',
    },
    padding: 0,
    width: '100%',
    maxWidth: 494,
    backgroundColor: theme.palette.background.paper,
    height:'100%',
  },
  selectMenu: {
    width:494,
    whiteSpace: 'normal',
    padding: '8px 5px',
  },
  listTextPrimary: {
    display: 'flex',
  },
  menuItemDiv: {
    display: 'flex',
    flexDirection:'row',
    alignItems:'flex-start',
  },
  missionSelectContainer: {
    padding:'0px 9px',
    margin: '0px 24px 8px',
    border: '2px groove',
    borderRadius: '5px',
    overflowY: 'scroll',
    height:'100%',
  },
  dialogRoot: {
    maxHeight: '80%',
    overflow: 'visible',
  },
  selectListItem: {
    whiteSpace: 'normal',
    padding: '8px 16px',
  },
  missionPackContainer: {
    height:'100%',
    overflow:'visible',
    margin: '8px 23px 8px 22px',
    padding:0,
  },
  title: {
    paddingBottom: 0,
  },
  spinner: {
    paddingLeft: 1,
    position: 'absolute',
    color: theme.palette.type === 'dark' ? 'white' : 'black',
  },
}))

function MissionList(props) {
  const classes = useStyles()
  const [missionState] = useMission()
  const missions = props.activeMissionPack?.missions ? props.activeMissionPack.missions : []

  const isUnlocked = (id, index, missionCompletion) => {
    if (isMissionManuallyLocked(id)) {
      return false
    }

    if (missionState.testMode || index === 0) {
      return true
    }

    if (isMissionOrganizationallyUnlocked(id)) {
      return true
    }

    if (isMissionOrganizationallyUnlocked(missionState.activeMissionPack.missions[index - 1]?.id)) {
      return true
    }

    return (
      missionCompletion[id] === true ||
      missionCompletion[missionState.activeMissionPack.missions[index - 1]?.id] === true
    )
  }


  return (
    <List className={classes.listRoot}>
      {missions.map((mission, index) => {
        const missionProgress = readMissionProgressFromUserProgressStore(mission.id)
        return (
          <ListItem
            alignItems='flex-start'
            button
            onClick={() => props.onSelectionClick(mission.id)}
            selected={props.selectedMissionId === mission.id}
            divider
            disabled={props.loading || !isUnlocked(mission.id, index, props.missionCompletion) || isMissionGated(mission.id)}
            key={mission.id}
          >
            <ListItemAvatar>
              <Avatar variant='square' src={mission.avatarBlob ? URL.createObjectURL(mission.avatarBlob) : ''} />
            </ListItemAvatar>
            <ListItemText
              primary={(
                <>
                  <span style={{marginRight:'1em'}}>{`Mission ${index+1}: ${mission.title}`}</span>
                  <span style={{display: 'flex', alignItems:'center', gap: 5}}>
                    {!isUnlocked(mission.id, index, props.missionCompletion) && (<LockIcon />)}
                    {isMissionGated(mission.id) || (index !== missions.length-1 ? isMissionGated(missions[index+1].id):false) ? <BlockIcon /> : isMissionManuallyLocked(mission.id) && <Typography variant='body2' style={{fontStyle: 'italic', paddingLeft: 4, paddingTop: 3, width: 102}}>{'Coming Soon!'}</Typography>}
                    {missionProgress?.completed && !missionWasStartedBeforeContestStarted(mission.id) && (<DoneIcon style={{color:'green'}}/>)}
                  </span>
                </>
              )}
              secondary={mission.brief}
              classes={{
                primary: classes.listTextPrimary,
              }}
            />
          </ListItem>
        )
      })}
    </List>
  )
}

function MissionSelect(props) {
  const classes = useStyles()
  const [selectedMissionId, setSelectedMissionId] = React.useState('')
  const [missionState] = useMission()
  const [missionCompletion, setMissionCompletion] = React.useState({})
  const [selectedPack, setSelectedPack] = React.useState({id: 'Free'})
  const loading = !missionState.lessonContentReady

  React.useEffect(() => {
    if (props.open){
      // const missionCompletion = userProgressController.activateMissionPack(pack)
      // if (!pack.missions.find(msn => msn.id === selectedMissionId)){
      //   setSelectedMissionId(getNextMissionId(missionCompletion))
      // }
      setSelectedMissionId(missionState.ui.activeMissionId)
      setSelectedPack(missionState.activeMissionPack)
      setMissionCompletion(userProgressController.activateMissionPack(missionState.activeMissionPack))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])


  const handleSelect = () => {
    changeActiveMission(selectedMissionId)
  }

  React.useEffect(() => {
    if (!loading) {
      props.handleClose()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <RegionalDialog
      open={props.open}
      onClose={props.handleClose}
      keepMounted
      containerId={'3d'}
      PaperProps={{
        classes: { root: classes.dialogRoot },
      }}
    >
      <DialogTitle className={classes.title}>Select Mission</DialogTitle>
      <div className={classes.missionSelectContainer} style={{marginTop: 8}}>
        <MissionList
          onSelectionClick={id => setSelectedMissionId(id)}
          selectedMissionId={selectedMissionId}
          maxMissionId={props.nextMissionId}
          activeMissionPack={selectedPack}
          missionCompletion={missionCompletion}
          loading={loading}
        />
      </div>
      <DialogActions>
        <Button onClick={props.handleClose} disabled={loading} color='default'>
          Cancel
        </Button>
        <Button onClick={handleSelect} variant={loading ? 'contained' : 'outlined'} autoFocus  disabled={loading}>
          {loading ? <CircularProgress size={26} className={classes.spinner} /> : null}
          {'OK'}
        </Button>
      </DialogActions>
    </RegionalDialog>
  )
}

MissionSelect.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default MissionSelect
