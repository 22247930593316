import React from 'react'

export const OnlineStatusActions = Object.freeze({
  UPDATE_STATUS: Symbol('update status'),
})

const OnlineStatusStateContext = React.createContext()
const OnlineStatusDispatchContext = React.createContext()
var onlineStatusDispatch = () => {}
export const OnlineStatusProvider = ({ children }) => {
  const [isOnline, setIsOnline] = React.useState(navigator.onLine)

  const dispatch = async (action) => {
    switch (action.type) {
      case OnlineStatusActions.UPDATE_STATUS:
        setIsOnline(action.status)
        break
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }
  onlineStatusDispatch = dispatch

  return (
    <OnlineStatusStateContext.Provider value={{ isOnline }}>
      <OnlineStatusDispatchContext.Provider value={dispatch}>
        {children}
      </OnlineStatusDispatchContext.Provider>
    </OnlineStatusStateContext.Provider>
  )
}

export { onlineStatusDispatch }
export const useOnlineStatus = () => {
  const state = React.useContext(OnlineStatusStateContext)
  const dispatch = React.useContext(OnlineStatusDispatchContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error('useOnlineStatus must be used within a child of a OnlineStatusProvider')
  }
  return [state, dispatch]
}