// Generic template Entities for constructing environments
import { Entity } from '../SimScene'
import * as BABYLON from '@babylonjs/core'
import { hitCache, setPhysicsNoContactCollision, makeImpostor, assetPath, disposePromise,
  loadSoundCachedAsync,
} from '../utils/BabylonUtils'

const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7
const OBSTACLE_FRICTION = 5.9

export class TemplateLights extends Entity {
  constructor(options) {
    super()

    const defaultOptions = {
      envLightingName: undefined,
      envLightingSource: undefined,
      envIntensity: 1.0,
      fogMode: BABYLON.Scene.FOGMODE_NONE,  // BABYLON.Scene.FOGMODE_EXP2
      fogDensity: 0.003,
      fogColor: new BABYLON.Color3(0.9, 0.9, 0.85),
    }
    this.options = {...defaultOptions, ...options}
  }

  load = async (scene) => {
    this.scene = scene

    // Ambient lighting provided by HDR environment
    this.envTexture = await hitCache(this.options.envLightingName, () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(this.options.envLightingSource, scene)
    })

    scene.environmentTexture = this.envTexture
    // Adjust scene exposure level for PBR materials
    scene.environmentIntensity = this.options.envIntensity

    // Fog - Makes some of the crisp graphic details less "pretty" to show off. BUT it does add spooky feel...
    scene.fogMode = this.options.fogMode
    scene.fogColor = this.options.fogColor
    scene.fogDensity = this.options.fogDensity
  }

  unload = async () => {
    this.scene.environmentTexture = null
    this.scene.fogMode = BABYLON.Scene.FOGMODE_NONE
  }
}

export class TemplateGround extends Entity {
  constructor(options) {
    super()

    const defaultOptions = {
      envModelName: undefined,
      envModelSource: undefined,
      meshVisitor: undefined,     // If set, meshVisitor(mesh) is called during load for each mesh in envModel
    }
    this.options = {...defaultOptions, ...options}

    this.envModel = null  // After load will be set to envModel AssetContainer
    this.rootMesh = null  // After load will be set to root mesh of envModel
  }

  makeCheckpoint = (mesh) => {
    if (mesh.name.includes('Checkpoint')) {
      // mesh.visibility = false
      mesh.isVisible = false
      // Use physics engine to detect collisions but pass-through (a "trigger volume")
      mesh.setParent(null)
      mesh.physicsImpostor = new BABYLON.PhysicsImpostor(mesh, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: GROUND_FRICTION, group: 0, mask: 0 })
      setPhysicsNoContactCollision(mesh.physicsImpostor)
      BABYLON.Tags.AddTagsTo(mesh, 'BotCollider')
    }
  }

  load = async (scene) => {
    // Create the invisible ground parent mesh
    this.ground = BABYLON.Mesh.CreateGround('ground', 200, 200, 2, scene)
    this.ground.checkCollisions = true  // For camera collisions
    this.ground.isVisible = false
    this.ground.isPickable = false

    let path, name
    [path, name] = assetPath(this.options.envModelSource)

    // Import the environment
    this.envModel = await hitCache(this.options.envModelName, async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync (path, name, scene)
    })
    // Currently NOT cloning environment model, instead trying to keep makeImpostor() and other
    // operations that modify the mesh idempotent. That means we can't dispose this, and instead
    // must add/remove it from scene.
    this.envModel.addAllToScene()
    const meshes = this.envModel.meshes
    this.rootMesh = meshes[0]

    // Set the ground mesh as the root node's parent
    this.rootMesh.parent = this.ground

    // Recurse through all meshes in this scene
    await Promise.all(meshes.map(async (mesh) => {
      this.makeCheckpoint(mesh)

      // Visitor runs prior to makeImpostor.
      // NOTE: Any mods to mesh must be unconditional, OR unload() must revert any changes
      // made to the mesh, since it will be cached between loads (not cloned).
      if (this.options.meshVisitor) {
        this.options.meshVisitor(mesh)
      }
      makeImpostor(mesh, this.ground, { mass: 0, friction: OBSTACLE_FRICTION })
      mesh.freezeWorldMatrix()
    }))

    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
    this.ground.freezeWorldMatrix()
  }

  unload = async () => {
    // Don't dispose environment model, just remove from scene
    this.rootMesh.parent = null
    this.envModel.removeAllFromScene()

    await disposePromise(this.ground)
  }
}

export class TemplateSounds extends Entity {
  constructor(options) {
    super()

    // Notes:
    //  - For all SFx use cloneSfx(). Sounds clones will be disposed automatically.
    //  - Names are used for caching, so be sure they're unique and consistent
    const defaultOptions = {
      envMusicName: undefined,   // Theme music
      envMusicSource: undefined,
      envSoundFx: [],  // Array of SFx { name:'', source: SoundAsset, options: {} }
    }
    this.options = {...defaultOptions, ...options}

    this.musicVolume = 0.5
    this.sfxVolume = 0.5
    this.sfxMap = new Map()  // Loaded SFx assets (also in assetCache, but maintaining this for future flexibility)
    this.disposeList = []
  }

  setVolume = (volumeParams) => {
    // console.log('New Volume is: ', volumeParams)
    this.musicVolume = volumeParams[0]
    this.sfxVolume = volumeParams[1]
    this?.music && this.music.setVolume(this.musicVolume)
    this?.sfxTrack && this.sfxTrack.setVolume(this.sfxVolume)
  }

  cloneSfx = (name) => {
    // Clone one of the loaded sound effects
    const sfx = this.sfxMap.get(name)
    if (!sfx) {
      console.error(`SFx ${name} not found!`)
      return null
    }
    const newSfx = sfx.clone()
    this.sfxTrack.addSound(newSfx)   // Collective volume control for SFx
    this.disposeList.push(newSfx)    // Clones will be disposed (Six is having trouble...)
    return newSfx
  }

  load = async (scene) => {
    // Load sounds
    this.disposeList = []
    this.sfxMap.clear()

    if (this.options.envMusicSource) {
      // Ambient sound can start later when it's fully loaded... don't await it.
      this.music = await hitCache(this.options.envMusicName, () => {
        return new BABYLON.Sound(this.options.envMusicName, this.options.envMusicSource, scene, null, {
          loop: true,
          autoplay: true,
          volume: this.musicVolume,
        })
      })
      this.music.play()
    }

    // Create a Soundtrack to manage volume for all sound effects
    this.sfxTrack = new BABYLON.SoundTrack(scene, { volume: this.sfxVolume })
    this.disposeList.push(this.sfxTrack)

    // Settings for SFx sounds attached to mesh objects
    const spatialSoundOptions = {
      loop: false,
      spatialSound: true,
      distanceModel: 'exponential',
      rolloffFactor: 3,
      refDistance: 3,
      volume: 1.0,
    }

    // Note some SFx are spatial (cloned later and attached to meshes) and some are "global"
    // The SFx are cached, not disposed or added to SoundTrack. Objects will clone them for use as needed.
    const soundPromises = this.options.envSoundFx.map((s) => {
      return loadSoundCachedAsync(s.name, s.source, scene, { ...spatialSoundOptions, ...s.options })
    })
    this.sfxSounds = await Promise.all(soundPromises)

    // Index SFx for later lookup by name. Could alternatively use assetCache directly, but keeping here too for now.
    this.sfxSounds.forEach((sfx) => {
      this.sfxMap.set(sfx.name, sfx)
    })
    this.sfxTrack.setVolume(this.sfxVolume)
  }

  unload = async () => {
    // Stop the music... or rather, pause it. Nicer to continue music after each reset while coding.
    this.music.pause()

    const disposeList = [...this.disposeList]
    await Promise.all(disposeList.map(disposePromise))
  }
}
