import {
  ValidatorTypes,
  ModelEventValidator,
  ConsoleRegexValidator,
  CodeRegexValidator,
  ModelChangeValidator,
  RunTimerValidator,
  ModelCollisionValidator,
  ScenePickValidator,
  ScenePassThroughValidator,
  ContextEventValidator,
  ContextSingleValidator,
  CodeValidator,
  ConsoleValidator,
  CodeErrorValidator,
  FileOperationValidator,
  UARTCommandValidator,
  PollBackendValidator,
  RunStateValidator,
  CodeParseValidator,
} from './Validators'
import { TargetStates } from './TargetInterface'
import { simScene } from './SimScene'

export const ValidatorContextKeys = {
  SCENE: 'SCENE',
  USER_CONFIG: 'USER_CONFIG',
  MISSION: 'MISSION',
  CODE_PANEL: 'CODE_PANEL',
  DEBUGGER: 'DEBUGGER',
  FILE_MANAGEMENT: 'FILE_MANAGEMENT',
}

class ValidatorControl {
  constructor() {
    this.validators = []
    this.errorObservers = []
    this.modelController = null
    this.backend = null
    this.contextEvList = {}
    this.timerMonitorCb = () => {}
    this.setTimerValidatorCb = () => {}
    this.listenForRunStateChanges = false
    this.scoreInterface = {
      reset: () => {},
      add: () => {},
    }
  }

  setTimerCallbacks = (timerCb, setCb) => {
    this.timerMonitorCb = timerCb
    this.setTimerValidatorCb = setCb
  }

  subscribeToErrors = (cb) => {
    this.errorObservers.push(cb)
  }

  validatorError = (validator, err, isDestroy) => {
    let msg = 'Unknown Error in Validator'
    if (err) {
      msg = `Error in Validator: ${err.message}`
    }
    console.log(msg)

    if (!isDestroy && validator.success) {
      validator.success() // default to let people go on if there is an error
    }
    this.errorObservers.forEach((cb) => {
      cb(msg)
    })
  }

  addContextActions = (contextKey, actions) => {
    this.contextEvList[contextKey] = actions
  }

  handleContextEvent = async(action) => {
    // console.log('validator event', action)
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.CONTEXT_EV:
        case ValidatorTypes.CONTEXT_SINGLE:
          try {
            val.check(action)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  handleCodeError = async(err) => {
    // console.log(err)

    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.CODE_ERROR:
          if (!val.validated) {
            try {
              val.check(err)
            } catch (e) {
              this.validatorError(val, e)
            }
          }
          break
        default:
          break
      }
    })
  }

  handleUARTCmd = async(cmd, id, data) => {
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.UART_COMMAND:
          try {
            val.check(cmd, id, data)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  handleFileOp = async(filename, op, status, data) => {
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.FILE_OPERATION:
          try {
            val.check(filename, op, status, data)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  handleRunCode = async(code) => {
    this.scoreInterface.reset()
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.CODE_REGEX:
        case ValidatorTypes.CODE:
        case ValidatorTypes.CODE_PARSE:
          try {
            val.check(code)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  handleRunStateChange = async(runState, isCodeRunner) => {
    // console.log(runState, isCodeRunner)

    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.RUN_STATE:
          val.check(runState, isCodeRunner)
          break
        default:
          break
      }
    })

    if (this.listenForRunStateChanges) {
      switch (runState) {
        case TargetStates.RUNNING:
          this.validators.forEach((val) => {
            switch (val.type) {
              case ValidatorTypes.RUN_TIMER:
                val.start()
                this.timerMonitorCb(true)
                break
              default:
                break
            }
          })
          break
        case TargetStates.STOPPED:
          if (this.scoreInterface.save) {
            this.scoreInterface.save()
            this.scoreInterface.lock()
          }
          this.listenForRunStateChanges = false
          this.validators.forEach((val) => {
            switch (val.type) {
              case ValidatorTypes.RUN_TIMER:
                this.timerMonitorCb(false)
                break
              default:
                break
            }
          })
          break
        default:
          break
      }
    }

    if (!isCodeRunner && runState === TargetStates.RUNNING) {
      this.listenForRunStateChanges = true
    }
  }

  handleConsoleMsg = async(message) => {
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.CONSOLE_REGEX:
        case ValidatorTypes.CONSOLE:
          try {
            val.check(message)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  handleModelCollision = async(mesh) => {
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.MODEL_COLLISION:
          try {
            val.check(mesh)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  handleModelEvent = async(modelEvent) => {
    // console.log(modelEvent)
    this.validators.forEach((val) => {
      switch (val.type) {
        case ValidatorTypes.MODEL_EV:
        case ValidatorTypes.MODEL_CHANGE:
          try {
            val.check(modelEvent)
          } catch (err) {
            this.validatorError(val, err)
          }
          break
        default:
          break
      }
    })
  }

  setModelController = (modelController) => {
    if (this.modelController !== null) {
      this.modelController.modelEventObservable.delete(this.handleModelEvent)
      this.modelController.modelCollisionObservable.delete(this.handleModelCollision)
    }
    this.modelController = modelController
    if (modelController !== null) {
      this.modelController.modelEventObservable.add(this.handleModelEvent)
      this.modelController.modelCollisionObservable.add(this.handleModelCollision)
    }
  }

  setBackend = (backend) => {
    this.backend = backend
  }

  clear = async() => {
    this.validators.forEach((v) => {
      try {
        v.destroy()
      } catch (err) {
        this.validatorError(v, err, true)
      }
    })
    this.validators = []
    this.setTimerValidatorCb(null)
  }

  subscribe = async(validatorList, scoreInterface) => {
    let rt = null

    this.scoreInterface = scoreInterface
    validatorList.forEach((v) => {
      const valType = ValidatorTypes[v.validatorType]
      try {
        switch (valType) {
          case ValidatorTypes.MODEL_EV:
            this.validators.push(new ModelEventValidator(v.success, this.modelController, v.validator))
            break
          case ValidatorTypes.CODE_REGEX:
            this.validators.push(new CodeRegexValidator(v.success, v.fail, v.validator))
            break
          case ValidatorTypes.CODE_PARSE:
            this.validators.push(new CodeParseValidator(v.success, v.fail, v.validator))
            break
          case ValidatorTypes.CONSOLE_REGEX:
            this.validators.push(new ConsoleRegexValidator(v.success, v.validator))
            break
          case ValidatorTypes.MODEL_CHANGE:
            this.validators.push(new ModelChangeValidator(v.success, this.modelController, v.validator, v.other))
            break
          case ValidatorTypes.MODEL_COLLISION:
            this.validators.push(new ModelCollisionValidator(v.success, this.modelController, v.validator))
            break
          case ValidatorTypes.SCENE_PICK:
            this.validators.push(new ScenePickValidator(v.success, simScene, v.validator))
            break
          case ValidatorTypes.SCENE:
            this.validators.push(new ScenePassThroughValidator(v.success, simScene, v.validator))
            break
          case ValidatorTypes.CONTEXT_EV:
            this.validators.push(new ContextEventValidator(v.success, v.fail, v.other, this.contextEvList))
            break
          case ValidatorTypes.CONTEXT_SINGLE:
            this.validators.push(new ContextSingleValidator(v.success, v.fail, v.other, v.validator, this.contextEvList))
            break
          case ValidatorTypes.CODE:
            this.validators.push(new CodeValidator(v.success, v.fail, v.validator))
            break
          case ValidatorTypes.CONSOLE:
            this.validators.push(new ConsoleValidator(v.success, v.validator))
            break
          case ValidatorTypes.CODE_ERROR:
            this.validators.push(new CodeErrorValidator(v.success, v.validator))
            break
          case ValidatorTypes.FILE_OPERATION:
            this.validators.push(new FileOperationValidator(v.success, v.fail, v.validator))
            break
          case ValidatorTypes.UART_COMMAND:
            this.validators.push(new UARTCommandValidator(v.success, v.fail, v.validator))
            break
          case ValidatorTypes.POLL_BACKEND:
            this.validators.push(new PollBackendValidator(v.success, this.backend, v.other, v.validator))
            break
          case ValidatorTypes.RUN_STATE:
            this.validators.push(new RunStateValidator(v.success, v.validator))
            break
          case ValidatorTypes.RUN_TIMER:
            if (rt !== null) {
              throw Error('You can only have one timer validator in an objective')
            }
            rt = this.validators.push(new RunTimerValidator(v.success, v.fail, v.update, this.modelController, parseFloat(v.validator))) - 1
            break
          default:
            throw Error('Tried to create a validator that does not exist')
        }
      } catch (err) {
        this.validatorError(v, err)
      }
    })

    if (rt !== null) {
      const timedVals = []
      this.validators.forEach((v) => {
        if (v.type !== ValidatorTypes.RUN_TIMER && v.type !== ValidatorTypes.CODE_REGEX &&
            v.type !== ValidatorTypes.CODE && v.type !== ValidatorTypes.CODE_PARSE) {
          timedVals.push(v)
        }
      })
      this.validators[rt].addTimedValidators(timedVals)
      this.setTimerValidatorCb(this.validators[rt])
    } else {
      this.setTimerValidatorCb(null)
    }
  }
}

export const validatorController = new ValidatorControl()