import { hintCategory } from '../../../Toolbox/GeneralHelpHints'
import { toolsByCategory } from './ripToolboxContent'

const ripTableOfContents = ({ addTask, addSpacer, packs }) => {
  // addTask(`# <u>***${}***</u>`)
  addTask('# <u>***Toolbox***</u>')
  Object.values(hintCategory).forEach((category) => {
    const toolboxItems = toolsByCategory[category]
    addTask(`### ***${category}***`)
    toolboxItems
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .forEach((item) => {
        addTask(item, 'toc')
      })
    addSpacer(10)
  })

  // TODO: This shares a lot of code with the other content rippers, reuse
  packs.forEach((pack) => {
    addTask(`# <u>***${pack.title}***</u>`)
    pack.missions.forEach((mission, missionIdx) => {
      var objectiveIdx = 0
      var quizIdx = 0
      addTask(`### Mission ${missionIdx+1} - ***${mission.title}***`)
      mission.flow.forEach((flowItem, flowIdx) => {
        var flowTitle
        if (flowItem.type === 'quiz'){
          quizIdx += 1
          flowTitle = `Quiz ${quizIdx}`
        } else {
          objectiveIdx += 1
          flowTitle = `Objective ${objectiveIdx}`
        }

        addTask({name: flowTitle, desc: flowItem.obj.title }, 'toc')
      })
      addSpacer(10)
    })
  })
}

export default ripTableOfContents