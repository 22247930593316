const FileOpStatus = {
  IN_PROGRESS: 0,
  COMPLETE_FILE_READ: 1,
  PARTIAL_FILE_READ: 2,
  FILE_NOT_FOUND: 3,
  FILE_TOO_BIG: 4,
  NOT_SIGNED_IN: 5,
  WRITE_SUCCESS: 6,
  WRITE_FAIL: 7,
  FILESYSTEM_NOT_READY: 8,
  MORE_THAN_ONE_FILE: 9,
  INVALID_FILENAME: 10,
  OS_OP_SUCCESS: 11,
  INVALID_DST_FILENAME: 12,
  FILE_EXISTS: 13,
  NOT_A_DIRECTORY: 14,
  IS_A_DIRECTORY: 15,
  DST_FILE_EXISTS: 16,
  NOT_IMPLEMENTED: 253,
  NONE: 254,
  UNKNOWN_FAIL: 255,
}

// DO NOT MODIFY NUMBERS THAT ALREADY EXIST!!!
const FileOperation = {
  READ_BINARY: 0,
  WRITE_BINARY: 1,
  OPEN: 2,
  OS_STAT: 3,
  OS_RENAME: 4,
  OS_UNLINK: 5,
  OS_RMDIR: 6,
  OS_GETCWD: 7,
  OS_CHDIR: 8,
  OS_MKDIR: 9,
}

const MAX_FILE_SIZE = 1048576 // 1 MB

const FILE_DATA_BUFFER_SIZE = 128

// https://www.geeksforgeeks.org/how-to-find-unique-characters-of-a-string-in-javascript/
function findUnique(str){
  return [...str].reduce((acc, curr)=>{
    return acc.includes(curr) ?  acc  :  acc + curr
  }, '')
}

function isFileNameInvalid (fileName) {
  if (fileName.trim().length === 0){
    return {type: 'whitespace'}
  }

  const invalidChars = findUnique(fileName).match(/[^-_.A-Za-z0-9 ]/g)
  if (invalidChars){
    return {type: 'char', invalidChars: invalidChars}
  }

  return null
}

function encodeFileStat (uid, isFile, modified, created, size) {
  const mode = isFile ? 0 : 1
  const j = JSON.stringify({mode, uid, modified, created, size})
  return j.split('').map(char => char.charCodeAt(0))
}

function decodeFileStat (byteArray) {
  const jsonStr = byteArray.map(b => String.fromCharCode(b)).join('')
  return JSON.parse(jsonStr)
}

export {
  FileOpStatus,
  FileOperation,
  MAX_FILE_SIZE,
  FILE_DATA_BUFFER_SIZE,
  isFileNameInvalid,
  encodeFileStat,
  decodeFileStat,
}