import { getActiveGroup } from './getActiveGroup'
import {
  getMissionIdMissionsIndex,
} from '../../lesson-content/lesson-content-use-cases'

function isMissionGated(missionId) {
  const missionIdx = getMissionIdMissionsIndex(missionId)
  const activeGroup = getActiveGroup()
  if (!activeGroup) {
    return false
  }

  if (!activeGroup.progressionGates || activeGroup.progressionGates.length === 0) {
    return false
  }

  for (const gate of activeGroup.progressionGates) {
    const gateMissionIdx = getMissionIdMissionsIndex(gate.missionId)
    if (gateMissionIdx < missionIdx) {
      return true
    }
  }
}

export { isMissionGated }