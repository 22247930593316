// Default settings for the Botsim app

const jsCodeSnip = `// Write some code here!
// A little KITT to kick things off
let dir = 2
let val = 1

botcore.motors.run(0, 5)
botcore.motors.run(1, -5)

while(true) {
    botcore.leds.user(val)
    val *= dir
    if (val === 0x80 || val === 0x01) {
        dir = 1 / dir
    }
    sleep(100)
}
`
const pyCodeSnip = `# Write your code here!
`

export const appDefaults = {
  userConfig: {
    codeLanguage: 'python',
    theme: 'dark',
    soundVolume: 0.8,
    spatialSound: true,
  },

  codePanel: {
    tabs: {
      focused: null,
      opened: new Set(),
    },
  },

  initialCodeSnippet: {
    javascript: jsCodeSnip,
    python: pyCodeSnip,
  },

  sceneConfig: {
    envIndex: 0,
    camIndex: 0,
  },

  userProgress: {
    missions: {},
    ui: { // these must all be value types - no references
      missionSelOpen: false,
      curFlowIndex: -1,
      focusedContent: 'mission',
      windowMaximized: false,
      activeMissionId: 'Free_01Welcome',
      activeMissionPackId: 'Free',
      isSandboxMode: false,
      toolboxOpen: false,
      toolSelected: '',
      toolboxMaximized: false,
      consoleOpen: false,
      goalHUDOpen: false,
    },
    toolsFound: [],
    lastTool: 'nothing',
  },

  userLicenses: {
    lastActiveGroup: null,
    groupData: {},
  },
}
