import { putIndyUserContestRegistrationInCloud } from '../contest-gateways'
import {
  presentPutIndyContestRegistrationResponseStarted,
  presentPutIndyContestRegistrationResponseFailed,
  presentPutIndyContestRegistrationResponseSucceeded,
} from '../contest-presenter'
import { fetchContests } from './fetchContests'

async function registerIndyUser(contestId, firstName, lastName, registrationDetails) {
  presentPutIndyContestRegistrationResponseStarted()
  const error = await await putIndyUserContestRegistrationInCloud(contestId, 'indy', [firstName, lastName].join(' '), registrationDetails)
  if (error) {
    presentPutIndyContestRegistrationResponseFailed(error)
    return error
  }

  await fetchContests()
  presentPutIndyContestRegistrationResponseSucceeded()
  return error
}

export { registerIndyUser }