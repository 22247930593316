import React from 'react'
import { appDefaults } from '../BotSimDefaults'
import { editorController } from '../EditorControl'
import { useFileManagement, FileManagementActions } from './FileManagementContext'
import { useLogin } from './LoginContext'
import { validatorController, ValidatorContextKeys } from '../ValidatorControl'
import { codeFileController } from '../content-manager/code-files/code-file-controllers'

export const UserConfigActions = Object.freeze({
  LANGUAGE_SET: Symbol('set language'),
  THEME_SET: Symbol('set theme'),
  VOLUME_SET: Symbol('set volume'),
  SPATIAL_SOUND_SET: Symbol('set 3d sound'),
  DELETE_ALL: Symbol('delete all user settings'),
})

validatorController.addContextActions(ValidatorContextKeys.USER_CONFIG, UserConfigActions)

const UserConfigContext = React.createContext()
export const UserConfigProvider = ({ children }) => {
  const [fileManagementState, fileManagementDispatch] = useFileManagement()
  const [loginState] = useLogin()
  const [codeLanguage, setCodeLanguage] = React.useState(appDefaults.userConfig.codeLanguage)
  const [theme, setTheme] = React.useState(appDefaults.userConfig.theme)
  const [soundVolume, setSoundVolume] = React.useState(appDefaults.userConfig.soundVolume)
  const [spatialSound, setSpatialSound] = React.useState(appDefaults.userConfig.spatialSound)

  React.useEffect(() => {
    if (!loginState) {
      return
    }
    const readUserConfigFromFile = async () => {
      const userConfig = await fileManagementDispatch({ type: FileManagementActions.READ_USER_CONFIG })
      if (!!userConfig) {
        setCodeLanguage(userConfig.codeLanguage)
        setTheme(userConfig.theme)
        setSoundVolume(userConfig.soundVolume)
        setSpatialSound(userConfig.spatialSound)
      }
    }
    if (!loginState.user) {
      // Set back to defaults (this happens after a log out)
      setCodeLanguage(appDefaults.userConfig.codeLanguage)
      setTheme(appDefaults.userConfig.theme)
      setSoundVolume(appDefaults.userConfig.soundVolume)
      setSpatialSound(appDefaults.userConfig.spatialSound)
    } else {
      readUserConfigFromFile()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileManagementState.fileSystemReady])

  React.useEffect(() => {
    editorController.setTheme(theme)
  }, [theme])

  const dispatch = async (action) => {
    validatorController.handleContextEvent(action)

    switch (action.type) {
      // { type: ..., codeLanguage: String }
      case UserConfigActions.LANGUAGE_SET: {
        const { codeLanguage } = action
        fileManagementDispatch({
          type: FileManagementActions.SAVE_USER_CONFIG,
          userConfig: { codeLanguage },
        })
        setCodeLanguage(codeLanguage)
        break
      }

      // { type: ..., theme: String }
      case UserConfigActions.THEME_SET: {
        const { theme } = action
        fileManagementDispatch({
          type: FileManagementActions.SAVE_USER_CONFIG,
          userConfig: { theme },
        })
        setTheme(theme)
        break
      }

      // { type: ..., soundVolume: Number }
      case UserConfigActions.VOLUME_SET: {
        const { soundVolume } = action
        fileManagementDispatch({
          type: FileManagementActions.SAVE_USER_CONFIG,
          userConfig: { soundVolume },
        })
        setSoundVolume(soundVolume)
        break
      }

      // { type: ..., spatialSound: Boolean }
      case UserConfigActions.SPATIAL_SOUND_SET: {
        const { spatialSound } = action
        fileManagementDispatch({
          type: FileManagementActions.SAVE_USER_CONFIG,
          userConfig: { spatialSound },
        })
        setSpatialSound(spatialSound)
        break
      }

      case UserConfigActions.DELETE_ALL: {
        // TODO: Needs to delete EVERYTHING (code, config, everything)
        // So it should be done in the File Management Context anyway.

        // fileManagementDispatch({
        //   type: FileManagementActions.SAVE_USER_CONFIG,
        //   userConfig: appDefaults.userConfig,
        // })
        // setCodeLanguage(appDefaults.userConfig.codeLanguage)
        // setTheme(appDefaults.userConfig.theme)
        // setSoundVolume(appDefaults.userConfig.soundVolume)

        await codeFileController.resetFiles()
        break
      }

      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }

  return (
    <UserConfigContext.Provider
      value={{
        state: {
          codeLanguage,
          theme,
          lightTheme: theme === 'light',
          soundVolume,
          spatialSound,
        },
        dispatch,
      }}>
      {children}
    </UserConfigContext.Provider>
  )
}

export const useUserConfig = () => {
  const { state, dispatch } = React.useContext(UserConfigContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useUserConfig must be used within a child of a UserConfigProvider'
    )
  }
  return [state, dispatch]
}
