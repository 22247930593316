// Cafeteria
import { Entity, simScene } from '../SimScene'
import * as BABYLON from '@babylonjs/core'
import { assetPath, makeImpostor, disposePromise, loadGLBWithPhysics, checkMark, setPhysicsNoContactCollision } from '../utils/BabylonUtils'
import SignBoard1 from '../assets/SignBoard2.glb'
import Runway from '../assets/Runway.glb'
import Runway09 from '../assets/Runway09.glb'
import Runway27 from '../assets/Runway27.glb'
import Map1 from '../assets/Map1.glb'
import Cafeteria1 from '../assets/Cafeteria.glb'
import StudioSoftboxEnv from '../assets/StudioSoftbox.env'
import WaterBottle from './../assets/WaterBottle-label.glb'
import Sumo1 from './../assets/Sumo1.glb'
import TreatCoil from './../assets/coil.glb'
import TreatLED from './../assets/LED.glb'
import TreatDiode from './../assets/diode.glb'
import TreatButton from './../assets/button.glb'
import TreatTO220 from './../assets/TO220.glb'
import { shuffle } from 'lodash'
import { hitCache } from '../utils/BabylonUtils'

const GROUND_FRICTION = 0.8
const GROUND_RESTITUTION = 0.7
const OBSTACLE_FRICTION = 5.9

class SimLights extends Entity {
  setOptions = (params) => {
    this.params = params
  }

  load = async (scene) => {
    this.scene = scene

    // Ambient lighting provided by HDR environment
    // this.envTexture = BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    this.envTexture = await hitCache('Env_StudioSoftbox', () => {
      return BABYLON.CubeTexture.CreateFromPrefilteredData(StudioSoftboxEnv, scene)
    })

    scene.environmentTexture = this.envTexture

    // Directional shadow-casting light. Needs to be fairly close to bot in order to create crisp
    // (not blocky) shadows. Ceiling level looks terrible...
    this.light = new BABYLON.DirectionalLight('dir01', new BABYLON.Vector3(-1, -1, -1), scene)

    // Position the shadow-casting light near player-1
    this.light.position = new BABYLON.Vector3(...this.params.bot[0].shadowPosition)
    this.light.intensity = 1.0

    // Visualize directional light source (beautiful yellow sun!)
    // const lightSphere = BABYLON.Mesh.CreateSphere('sphere', 10, 2, scene)
    // lightSphere.position = this.light.position
    // lightSphere.material = new BABYLON.StandardMaterial('light', scene)
    // lightSphere.material.emissiveColor = new BABYLON.Color3(1, 1, 0)
    // localAxes(3, scene).parent = lightSphere

    this.shadowGenerator = new BABYLON.ShadowGenerator(1024, this.light)
    this.shadowGenerator.useExponentialShadowMap = true

    // Note: A potential performance improvement (if shadows are found to be too burdensome) is
    //       to switch to using imposters as the shadow casters. The following line allows transparent
    //       meshes to cast shadows. This will require changing imposters to use "visibility=0" rather
    //       than "isVisible=false", since the former sets 100% transparent while the latter removes
    //       from render altogether. Would need to test to see if this helps performance!!
    // this.shadowGenerator.setTransparencyShadow(true)
  }

  getShadowGenerators = () => {
    return [this.shadowGenerator]
  }

  unload = async () => {
    const disposeList = [this.shadowGenerator, this.light]
    await Promise.all(disposeList.map(disposePromise))
    this.scene.environmentTexture = null
  }
}

class SimGround extends Entity {
  setOptions = (params) => {
    this.params = params
  }

  load = async (scene) => {
    // Create the invisible ground parent mesh
    this.ground = BABYLON.MeshBuilder.CreateGround('ground',
      { width: 150, height: 150, subdivisionsX: 2, subdivisionsY: 2, updatable: false },
      scene
    )
    this.ground.checkCollisions = true  // For camera collisions
    this.ground.isVisible = false

    // Small objects fall through ground. Physics is happier with a thicker box as the floor.
    const floor = BABYLON.MeshBuilder.CreateBox('floor', { width: 150, height: 1, depth: 150 }, scene)
    floor.position.y -= 0.5  // Adjust to ground level
    floor.isVisible = false
    floor.parent = this.ground
    floor.physicsImpostor = new BABYLON.PhysicsImpostor(
      floor,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )

    let path, name
    [path, name] = assetPath(Cafeteria1)

    // Import the Cafeteria environment
    this.cafeteriaMesh = await hitCache('CafeteriaModels', async () => {
      return await BABYLON.SceneLoader.LoadAssetContainerAsync (path, name, scene)
    })
    // Currently NOT cloning environment model, instead trying to keep makeImpostor() and other
    // operations that modify the mesh idempotent. That means we can't dispose this, and instead
    // must add/remove it from scene.
    this.cafeteriaMesh.addAllToScene()
    const meshes = this.cafeteriaMesh.meshes
    this.rootMesh = meshes[0]

    // Set the ground mesh as the root node's parent
    this.rootMesh.parent = this.ground

    // Recurse through all meshes in this scene
    await Promise.all(meshes.map(mesh => new Promise((resolve) => {
      // Add the ceiling light meshes with a white glow to the glowMeshes map
      // if (mesh.name === 'Cafeteria_Ceiling 1.032_primitive1') {
      if (mesh.name.startsWith('Cafeteria_Ceiling') && mesh.name.endsWith('primitive1')) {
        simScene.glowMeshes.set(mesh.name, new BABYLON.Color4(1, 1, 1, 0.15))
        simScene.glLeds.addExcludedMesh(mesh) // Exclude this mesh from the LED glow layer
      }

      // Enable shadows where needed (removing floor until cascading shadows are sorted)
      if (/* mesh.name.startsWith('Floor') || */ mesh.name.startsWith('Cafeteria_table')) {
        if (mesh.getClassName() !== 'InstancedMesh') {
          mesh.receiveShadows = true
        }
      }

      makeImpostor(mesh, this.ground)
      mesh.freezeWorldMatrix()
      // TODO: I want to tag the tables so validators can detect collisions. The following caused a crash in Ammo.js, not sure why...
      // const imp = makeImpostor(mesh, this.ground)
      // if (imp && mesh.name.startsWith('ImpostorCylinder.059')) {
      //   // console.log(imp[0].name)
      //   BABYLON.Tags.AddTagsTo(imp[0], 'BotCollider')
      // }

      resolve()
    })))

    // Parent physics imposter must init last
    this.ground.physicsImpostor = new BABYLON.PhysicsImpostor(
      this.ground,
      BABYLON.PhysicsImpostor.BoxImpostor,
      { mass: 0, friction: GROUND_FRICTION, restitution: GROUND_RESTITUTION },
      scene
    )
    this.ground.freezeWorldMatrix()
  }

  unload = async () => {
    // Don't dispose environment model, just remove from scene
    this.rootMesh.parent = null
    this.cafeteriaMesh.removeAllFromScene()

    await disposePromise(this.ground)
  }
}

class Waypoints extends Entity {
  createPad = async (name, position, size, test, scene) => {
    const faceUVs = new Array(6)
    for (let i = 0; i < 6; i++) {
      faceUVs[i] = new BABYLON.Vector4(0, 0, 0, 0)
    }
    const boxOptions = { width: size[0], height: size[1], depth: size[2], faceUV: faceUVs }
    const pad = BABYLON.MeshBuilder.CreateBox(name, boxOptions, scene)
    if (!test) {
      const materialPad = new BABYLON.StandardMaterial('PadMat', scene)
      materialPad.alpha = 0
      pad.material = materialPad
    }

    pad.position.set(...position)
    // Use physics engine to detect collisions but pass-through (a "trigger volume")
    pad.physicsImpostor = new BABYLON.PhysicsImpostor(pad, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: GROUND_FRICTION, group: 0, mask: 0 })
    setPhysicsNoContactCollision(pad.physicsImpostor)
    BABYLON.Tags.AddTagsTo(pad, 'BotCollider')

    const mark = await checkMark(pad, [0, -0.6, 0], 0.3)
    pad.validated = () => {
      mark.setEnabled(true)
    }

    return pad
  }

  load = async (scene) => {
    this.scene = scene
    this.pads = []
    if (this.params?.waypoints) {
      for (const w of this.params.waypoints) {
        const pad = await this.createPad(w.name, w.position, w.size, w.test, scene)
        this.pads.push(pad)
      }
    }
  }

  unload = async () => {
    if (this.pads) {
      await Promise.all(this.pads.map(disposePromise))
      this.pads.length = 0
    }
  }
  setOptions = (params) => {
    this.params = params
  }
}

class RunwayMarkers extends Entity {
  createMarker = (idx, xPos, rwyPos, scene) => {
    // Rectangular pad (flag) for waypoint visualization
    const position = [xPos, rwyPos[1], rwyPos[2] + 1.5]

    const faceUVs = new Array(6)
    for (let i = 0; i < 6; i++) {
      faceUVs[i] = new BABYLON.Vector4(0, 0, 0, 0)
    }

    // Border color
    const faceColors = new Array(6)
    for (let i = 0; i < 6; i++) {
      faceColors[i] = new BABYLON.Color4(1, 0, 0, 1)
    }

    const boxOptions = { width: 0.1, height: 0.3, depth: 0.3, faceUV: faceUVs, faceColors: faceColors }

    // Create dynamic texture on standard material
    // const dtWidth = boxOptions.depth * 512
    // const dtHeight = boxOptions.width * 512
    // const texturePad = new BABYLON.DynamicTexture('dynamic texture', {width:dtWidth, height:dtHeight}, scene)
    const materialPad = new BABYLON.StandardMaterial('PadMat', scene)
    // materialPad.emissiveTexture = texturePad
    materialPad.alpha = 1

    const marker = BABYLON.MeshBuilder.CreateBox('rwyMarker' + idx, boxOptions, scene)
    marker.material = materialPad

    marker.position.set(...position)

    return marker
  }

  load = async (scene) => {
    this.scene = scene
    this.markers = []
    if (this.params?.runwayMarkers && this.params?.runway) {
      for (let i = 0; i < this.params.runwayMarkers.length; i++) {
        const marker = this.createMarker(i, this.params.runwayMarkers[i], this.params.runwayPosition, scene)
        this.markers.push(marker)
      }
    }
  }

  unload = async () => {
    if (this.markers) {
      await Promise.all(this.markers.map(disposePromise))
      this.markers.length = 0
    }
  }
  setOptions = (params) => {
    this.params = params
  }
}

class SimObstacles extends Entity {
  constructor(ground) {
    super()
    this.ground = ground
  }

  setOptions = (params) => {
    this.params = params
  }

  loadSurfaceProp = async (id, modelAsset, position, shadowMesh) => {
    const prop = await loadGLBWithPhysics(modelAsset, id, { mass: 0, friction: OBSTACLE_FRICTION }, 1.0, position)

    // Traverse and enable shadows
    prop.getChildren(m => m.name.endsWith(shadowMesh), false).forEach((m) => {
      m.receiveShadows = true
    })

    return prop
  }

  loadWaterBottle = async (position) => {
    const scaling = 1.0
    const bottle = await loadGLBWithPhysics(WaterBottle, 'bottle1', { mass: 0.3, friction: 0.2, restitution: 0.8 }, scaling, position)
    BABYLON.Tags.AddTagsTo(bottle, 'BotCollider')

    return bottle
  }

  placeWaterBottle = async (scene) => {
    const bottleStartPos = new BABYLON.Vector3(...this.params.bot[0].playerPosition)

    // Pick a random bottle start position on the map between 0 and 7 * PI/4
    const angle = Math.floor(Math.random() * 7) * (Math.PI / 4)
    const dx = -Math.cos(angle) * this.params.waterBottlePlacement.radiusX
    const dz = Math.sin(angle) * this.params.waterBottlePlacement.radiusZ
    const dy = 0.6  // Bottle a little higher than player center
    bottleStartPos.addInPlaceFromFloats(dx, dy, dz)

    this.bottle1 = await this.loadWaterBottle(bottleStartPos)
  }

  loadTreat = async (id, modelAsset, position, rotation) => {
    // Load treat model
    const startPosition = new BABYLON.Vector3(...position)
    const startRotation = new BABYLON.Vector3(...rotation.map(deg => deg * Math.PI / 180))
    const scaling = 2.0

    const treat = await loadGLBWithPhysics(modelAsset, id, { mass: 2.0, friction: 4.0 }, scaling, startPosition, startRotation)
    treat.physicsImpostor.physicsBody.setDamping(0.99, 0.99)  // Reduce idle motion

    const mark = await checkMark(treat, [0, 0.2, 0])
    treat.botCollision = () => {
      mark.setEnabled(true)
    }

    BABYLON.Tags.AddTagsTo(treat, 'BotCollider')

    return treat
  }

  placeTreats = async (scene) => {
    // Treats are placed according to this.params.treatPositions (list of specific pos/rot)
    const allTreatModels = [TreatCoil, TreatLED, TreatDiode, TreatButton, TreatTO220]
    const randTreatModels = shuffle(allTreatModels)

    const treatPromises = this.params.treatPositions.map((treat, i) => {
      const treatModel = randTreatModels[i % randTreatModels.length]
      return this.loadTreat('treat' + i, treatModel, treat.position, treat.rotation)
    })
    this.treats = await Promise.all(treatPromises)
  }

  placeRunway = async (scene) => {
    const tileSize = 12.2
    const rwyTiles = this.params.runwayTiles
    const rwyPos = [...this.params.runwayPosition]
    const runway27Pos = new BABYLON.Vector3(...rwyPos)

    const rwyTilePromises = []

    for (let i = 0; i < rwyTiles; i++) {
      rwyPos[0] -= tileSize
      const tilePos = new BABYLON.Vector3(...rwyPos)
      rwyTilePromises.push(this.loadSurfaceProp('runway', Runway, tilePos, 'primitive1'))
    }

    this.runwayTiles = await Promise.all(rwyTilePromises)

    rwyPos[0] -= tileSize
    const runway09Pos = new BABYLON.Vector3(...rwyPos)

    let [runway09Prop, runway27Prop] = await Promise.all([
      this.loadSurfaceProp('runway09', Runway09, runway09Pos, 'primitive1'),
      this.loadSurfaceProp('runway27', Runway27, runway27Pos, 'primitive1'),
    ])

    this.runway09 = runway09Prop
    this.runway27 = runway27Prop
  }

  load = async (scene) => {
    // Create obstacles
    const mapPos = new BABYLON.Vector3(35.34, 5.5, 1.27) // Cafeteria_table.004
    const sign1Pos = new BABYLON.Vector3(11.34, 5.4, 37.28) // Cafeteria_table.006
    const sumoPos = new BABYLON.Vector3(-12.67, 5.52, 1.27) // Cafeteria_table.002

    // Concurrently load surface props
    let [mapProp, signboardProp, sumoRingProp] = await Promise.all([
      this.loadSurfaceProp('map1', Map1, mapPos, 'primitive1'),
      this.loadSurfaceProp('sign1', SignBoard1, sign1Pos, 'primitive1'),
      this.loadSurfaceProp('sumo1', Sumo1, sumoPos, 'primitive0'),
    ])
    this.map1 = mapProp
    this.sign1 = signboardProp
    this.sumo = sumoRingProp

    if (this.params.runway) {
      await this.placeRunway(scene)
    }

    if (this.params.waterBottle) {
      await this.placeWaterBottle(scene)
    }

    if (this.params.placeTreats) {
      // Delay to watch the treats fall...
      // Note: Delay can't be used outside of testing, due to CX-414 issue
      // setTimeout(async () => {
      await this.placeTreats(scene)
      // }, 1000)
    }
  }

  unload = async () => {
    const disposeList = [this.map1, this.sign1, this.sumo]
    if (this.bottle1) {
      disposeList.push(this.bottle1)
    }
    if (this.treats) {
      disposeList.push(...this.treats)
    }
    if (this.runway09) {
      disposeList.push(...this.runwayTiles)
      disposeList.push(this.runway09)
      disposeList.push(this.runway27)
    }
    await Promise.all(disposeList.map(disposePromise))
  }
}

export class Cafeteria extends Entity {
  constructor() {
    super()
    this.name = 'Cafeteria'
    this.defaultParams = CafeteriaDefaultParams
    this.lights = new SimLights()
    this.ground = new SimGround()
    this.obstacles = new SimObstacles(this.ground.ground)
    this.waypoints = new Waypoints()
    this.runwayMarkers = new RunwayMarkers()
  }
  load = async (scene) => {
    scene.autoClear = false // Increase frame rate and performance
    scene.autoClearDepthAndStencil = false
    // Place ground first, so objects don't fall through.
    await this.ground.load(scene)
    await Promise.all([
      this.lights.load(scene),
      this.obstacles.load(scene),
      this.waypoints.load(scene),
      this.runwayMarkers.load(scene),
    ])
  }
  unload = () => Promise.all([
    this.lights.unload(),
    this.ground.unload(),
    this.obstacles.unload(),
    this.waypoints.unload(),
    this.runwayMarkers.unload(),
  ])
  setOptions = (params) => {
    this.params = params ? params : this.defaultParams
    this.objectiveParams = this.params
    this.lights.setOptions(this.params)
    this.ground.setOptions(this.params)
    this.obstacles.setOptions(this.params)
    this.waypoints.setOptions(this.params)
    this.runwayMarkers.setOptions(this.params)
  }
  getShadowGenerators = () => {
    return this.lights.getShadowGenerators()
  }
}

const CafeteriaDefaultParams = {
  'waterBottle': false,
  'waterBottlePlacement': {
    'radiusX': 5.5,
    'radiusZ': 5.5,
  },
  'runway': false,
  'runwayTiles': 8,
  'runwayPosition': [48.8, 0, -25],
  'waypoints': [
    // {
    //   'name': 'Checkpoint 1',
    //   'position': [0, 5, -25],
    //   'size': [5, 5, 5], // [width, height, depth],
    //   'test': false, // if set to true this will show the boxes on the screen
    // },
  ],
  'runwayMarkers': [
    // x position real-world => y and z are relative to runway
  ],
  'bot': [
    {
      'playerPosition': [0, 0.2, -25], // In front of main food counter
      'shadowPosition': [12, 6, -17],  // Near food counter
    },
  ],
  'placeTreats': false,
  'treatPositions': [
    // {
    //   'position': [0, 1.5, -26],
    //   'rotation': [90, 0, 0],
    // },
    // {
    //   'position': [2, 1.5, -25],
    //   'rotation': [0, 90, 0],
    // },
    // {
    //   'position': [3, 1.5, -25],
    //   'rotation': [0, 90, 0],
    // },
    // {
    //   'position': [4, 1.5, -25],
    //   'rotation': [0, 90, 0],
    // },
  ],
}

/* Position notes:
  Map:
    this.centerPlayerPosition = new BABYLON.Vector3(35.34, 5.6, 1.27)  // Cafeteria_table.004
    shadowLightPosition = new BABYLON.Vector3(55, 12, 25)  // near table.004
  Sign-board:
    this.centerPlayerPosition = new BABYLON.Vector3(11.34, 5.4, 37.28) // Cafeteria_table.006
    shadowLightPosition = new BABYLON.Vector3(35, 12, 62)  // window near table.006
  Sumo ring:
    this.centerPlayerPosition = new BABYLON.Vector3(-12.67, 5.52, 1.27) // Cafeteria_table.002
    shadowLightPosition = new BABYLON.Vector3(10, 12, 22)
  Floor in front of food counter:
    this.centerPlayerPosition = new BABYLON.Vector3(0, 0.2, -25)  // In front of main food counter
    shadowLightPosition = new BABYLON.Vector3(35, 12, 62)  // window near table.006
*/