// The point of this file is to define the interface that the various debug
// backends must conform to. I considered settling for just using duck-typing,
// here is what swayed me to go with the interfaces paradigm:
// 1. Jeff already went to the trouble of creating this file
// 2. Since we expect to be implementing multiple backends, making the interface
// explicit should help us catch implementation errors


// Enumeration of Target States
export const TargetStates = Object.freeze({
  STOPPED: 'STOPPED',
  LOADING: 'LOADING',
  RUNNING: 'RUNNING',
  STOPPING: 'STOPPING',
  AWAITING_INPUT: 'AWAITING_INPUT',
  INITIALIZING: 'INITIALIZING',
})

// Enumeration of Target Modes
export const TargetModes = Object.freeze({
  DEBUG: 'DEBUG',
  RUN: 'RUN',
  REPL: 'REPL',
  DISCONNECTED: 'DISCONNECTED',
})

// Enumeration of Debugger Commands
export const DebugCommands = Object.freeze({
  CONTINUE: 'CONTINUE',
  PAUSE: 'PAUSE',
  STEP_OVER: 'STEP_OVER',
  STEP_INTO: 'STEP_INTO',
  STEP_OUT: 'STEP_OUT',
  RESTART: 'RESTART',
  STOP: 'STOP',
})


export class TargetInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor() {
    this.modeCallbacks = new Set()
    this.stateCallbacks = new Set()
    this.debugCallbacks = new Set()
    this.userProgramErrorCallbacks = new Set()
    this.targetMode = TargetModes.RUN
    this.lastTargetState = TargetStates.STOPPED
    this.webTerminal = undefined
    this.deviceConnected = null
  }

  deviceConnCb = () => {}

  setDeviceConnCb = (cb) => {
    this.deviceConnCb = cb
  }

  upgradeDeviceCb = () => {}

  setUpgradeDeviceCb = (cb) => {
    this.upgradeDeviceCb = cb
  }

  addCallbacks = (stateCallback, modeCallback, debugCallback, userProgramErrorCallback) => {
    if (stateCallback) {
      this.stateCallbacks.add(stateCallback)
    }
    if (modeCallback) {
      this.modeCallbacks.add(modeCallback)
    }
    if (debugCallback) {
      this.debugCallbacks.add(debugCallback)
    }
    if (userProgramErrorCallback) {
      this.userProgramErrorCallbacks.add(userProgramErrorCallback)
    }
  }

  removeCallbacks = (stateCallback, modeCallback, debugCallback, userProgramErrorCallback) => {
    if (stateCallback) {
      this.stateCallbacks.delete(stateCallback)
    }
    if (modeCallback) {
      this.modeCallbacks.delete(modeCallback)
    }
    if (debugCallback) {
      this.debugCallbacks.delete(debugCallback)
    }
    if (userProgramErrorCallback) {
      this.userProgramErrorCallbacks.delete(userProgramErrorCallback)
    }
  }

  // Interesting bugs can appear if your set of callbacks changes WHILE you are
  // running through it. This routine avoids those bugs by making a copy of the
  // set of callbacks first, then processing THOSE only.
  invokeExistingCallbacksOnly = (setOfCallbacks, callbackParameter) => {
    let copyOfSet = new Set(setOfCallbacks)
    copyOfSet.forEach(callback => callback(callbackParameter))
  }

  connectWebTerminal = (webTerminal) => {
    // We will send our text output to the WebTerminal
    this.webTerminal = webTerminal
    // The WebTerminal will sent keyboard input to us
    this.webTerminal.setTextDestination(this.receiveTextFromTerminal)
  }

  disconnectWebTerminal = () => {
    if (this.webTerminal !== undefined) {
      this.webTerminal.setTextDestination(undefined)
      this.webTerminal = undefined
    }
  }

  breakpointChanged = () => {
    throw new Error('Implementation error: missing breakpointChanged()')
  }

  receiveTextFromTerminal = (text) => {
    throw new Error('Implementation error: missing receiveTextFromTerminal()')
  }

  onStateChange = (targetState) => {
    throw new Error('Implementation error: missing onStateChange()')
  }

  // { line: int, debugVariables: Any }
  onDebugChange = (debugInfo) => {
    throw new Error('Implementation error: missing onDebugChange()')
  }

  onModeChange = (mode) => {
    throw new Error('Implementation error: missing onModeChange()')
  }

  onUserProgramError = (err) => {
    throw new Error('Implementation error: missing onUserProgramError()')
  }

  run = (code, language) => {
    throw new Error('Implementation error: missing run()')
  }

  stopRun = () => {
    throw new Error('Implementation error: missing stopRun()')
  }

  debug = (filename, code, language, initialBreakpoints) => {
    throw new Error('Implementation error: missing debug()')
  }

  debugCommands = (debugCommand) => {
    throw new Error('Implementation error: missing debugCommands()')
  }

  stopDebug = () => {
    throw new Error('Implementation error: missing stopDebug()')
  }

  restartDebug = () => {
    throw new Error('Implementation error: missing restartDebug()')
  }

  repl = () => {
    // only required for the simulator
  }

  init = () => {
    // TODO: merge this with repl somehow?
  }

  hydrate = () => {
    // optional
    // triggers when the target is selected
  }
}
