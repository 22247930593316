import React from 'react'
import HTMLSubject from './HTMLSubject'

const HTMLRipper = (props) => {
  const css = React.useRef({})
  const total = React.useRef(0)
  const task = React.useRef(null)
  const [subject, setSubject] = React.useState(null)

  const iterateTasks = () => {
    task.current = props.tasks.current.pop()
    const completed = total.current - props.tasks.current.length
    props.setProgress(parseInt(completed / total.current * 100))
    setSubject(
      <HTMLSubject
        styleClasses={props.styleClasses}
        task={task.current}
        successfulRip={successfulRip}
        absImagePath={props.absImagePath}
        imageSizeList={props.imageSizeList}
      />
    )
  }

  const successfulRip = (newCss, content) => {
    css.current = {...css.current, ...newCss}
    task.current.taskCb(content)
    if (props.tasks.current.length === 0){
      task.current = null
      props.setProgress(100)
      props.onFinish(Object.values(css.current))
    } else {
      iterateTasks()
    }
  }


  React.useEffect(() => {
    if (!task.current && props.tasks.current.length > 0){
      total.current = props.tasks.current.length
      iterateTasks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>{subject}</div>
  )
}

export default HTMLRipper