
import { fetchMissionPackFromCloud } from './lesson-content-cloud-access'
import {
  setMissionPackStore,
  readMissionPackIdsFromMissionPackStore,
  readMissionPackStoreByPackId,
  setFetchMissionPackPromiseInPackStore,
  readFetchMissionPackPromiseFromPackStore,
  readMissionStoreByMissionId,
  readFlowStoreByFlowId,
} from './lesson-content-store'
import { ValidatorEditorNotes } from '../../Validators'

function formatAndSaveMissionPack(pack) {
  pack.missions.forEach((mission) => {
    mission.flow.forEach((flow) => {
      if (flow?.obj.goals) {
        const funcGoals = flow.obj.goals.filter(({validatorType}) => ValidatorEditorNotes.find(({id}) => id === validatorType))
        flow.scored = !!funcGoals.find(goal => goal.validator.includes('score.'))
      }
    })

    mission.scored = !!mission.flow.find(flow => flow.scored)
  })
  setMissionPackStore(pack)
}

function fetchLessonContent(missionPackIds) {
  return Promise.all(missionPackIds.map((missionPackId) => {
    try {
      const alreadyFetchedPromise = readFetchMissionPackPromiseFromPackStore(missionPackId)
      if (!!alreadyFetchedPromise) {
        return alreadyFetchedPromise
      }

      const promise = new Promise(async (res, rej) => {
        const missionPackContent = await fetchMissionPackFromCloud(missionPackId)
        formatAndSaveMissionPack(missionPackContent)
        res(missionPackContent)
      })

      setFetchMissionPackPromiseInPackStore(missionPackId, promise)
      return promise
    } catch (err) {
      console.log('Error: fetchLessonContent', err)
      return null
    }
  }))
}

function getMissionPackWithMissionId(missionId) {
  const packIds = readMissionPackIdsFromMissionPackStore()
  for (const i in packIds) {
    const packId = packIds[i]
    const pack = readMissionPackStoreByPackId(packId)
    if (!!pack.missions.find(mission => mission.id === missionId)) {
      return pack
    }
  }
}

function getMissionIdMissionsIndex(missionId) {
  const pack = getMissionPackWithMissionId(missionId)
  return pack?.missions.findIndex(mission => mission.id === missionId) ?? 0
}

function getFlowItemIdFlowItemsIndex(missionId, flowItemId) {
  const mission = readMissionStoreByMissionId(missionId)
  return mission?.flow.findIndex(flowItem => flowItem.id === flowItemId) ?? -1
}

function getPackAndMissionFromFlowId(flowId) {
  const packIds = readMissionPackIdsFromMissionPackStore()
  for (const packId of packIds) {
    const pack = readMissionPackStoreByPackId(packId)
    for (const mission of pack.missions) {
      const flowItem = mission.flow.find(flowItem => flowItem.id === flowId)
      if (!!flowItem) {
        return { pack, mission, flowItem }
      }
    }
  }
}

function objectiveIsScored(flowId) {
  const flow = readFlowStoreByFlowId(flowId)
  if (!flow?.obj?.goals) {
    return
  }
  return flow.scored
}

function missionContainsScoredObjective(missionId) {
  const mission = readMissionStoreByMissionId(missionId)
  if (!mission) {
    return
  }
  return mission.scored
}

function missionPackContainsScoredMission(missionPackId) {
  const { missions } = readMissionPackStoreByPackId(missionPackId)
  return !!missions.find(mission => missionContainsScoredObjective(mission.id))
}

export {
  formatAndSaveMissionPack,
  fetchLessonContent,
  getMissionPackWithMissionId,
  getMissionIdMissionsIndex,
  getFlowItemIdFlowItemsIndex,
  objectiveIsScored,
  missionContainsScoredObjective,
  missionPackContainsScoredMission,
  getPackAndMissionFromFlowId,
}