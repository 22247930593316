import { isFileShared } from './isFileShared'
import { readSharedFileFromRepo } from '../code-file-store'

function getSharedFileName(fileId) {
  if (!isFileShared(fileId)) {
    return
  }

  const sharedFile = readSharedFileFromRepo(fileId)
  if (!sharedFile) {
    return
  }

  return sharedFile.name
}

export { getSharedFileName }