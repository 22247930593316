export function isRemarkParser(parser) {
  return Boolean(parser && parser.prototype && parser.prototype.blockTokenizers)
}

export function isRemarkCompiler(compiler) {
  return Boolean(compiler && compiler.prototype && compiler.prototype.visitors)
}

function ToolboxPlugin(options) {
  const parser = this.Parser
  const compiler = this.Compiler

  if (isRemarkParser(parser)) {
    attachParser(parser, options)
  }

  if (isRemarkCompiler(compiler)) {
    attachCompiler(compiler, options)
  }
}

function attachParser(parser, options) {
  const proto = parser.prototype
  const inlineMethods = proto.inlineMethods

  toolboxTokenizer.locator = locator

  proto.inlineTokenizers.toolbox = toolboxTokenizer

  inlineMethods.splice(inlineMethods.indexOf('text'), 0, 'toolbox')

  function locator(value, fromIndex) {
    return value.indexOf('==', fromIndex)
  }

  function toolboxTokenizer(eat, value, silent) {
    let match = /^==(\w+[\s|.|\-|:\w+]+)==/.exec(value)

    if (match) {
      if (silent) {
        return true
      }

      return eat(match[0])({
        type: 'toolbox',
        value: match[1],
        data: {
          hName: 'mark',
          hChildren: [{type: 'text', value: match[1]}],
        },
      })
    }
  }
}

function attachCompiler(compiler) {
  const proto = compiler.prototype

  proto.visitors.toolboxoolbox = compileToolbox

  function compileToolbox(node) {
    return '==' + node.value + '=='
  }
}

export default ToolboxPlugin
