// Mission and Objective Panels
import React from 'react'
import { Typography, Dialog, DialogContent, IconButton, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import ErrorIcon from '@material-ui/icons/Error'
import { useGroups } from '../contexts/GroupsContext'

function GoogleSubmissionErrorDialog() {
  const { submitAssignmentResponse } = useGroups()
  const [open, setOpen] = React.useState(false)

  function handleClose() {
    setOpen(false)
  }

  React.useEffect(() => {
    if (submitAssignmentResponse?.reason?.source === 'google' && submitAssignmentResponse?.reason?.message === 'Precondition check failed.') {
      setOpen(true)
    }
  }, [submitAssignmentResponse])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth='sm'
    >
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px 20px'}}>
        <Typography variant='h6'>Google Classroom Submission Error</Typography>
        <IconButton onClick={handleClose} size='small'>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <ErrorIcon style={{fontSize: 25, color: '#ff3333'}}/>
          <Typography style={{fontWeight: 600}}>
            {'There was an error submitting your assignment!'}
          </Typography>
          <ErrorIcon style={{fontSize: 25, color: '#ff3333'}}/>
        </div>
        <div style={{height: 25}}></div>
        <Typography>
          {'Google responded with the error code "FAILED_PRECONDITION" which can be caused by a number of different issues.'}
        </Typography>
        <div style={{height: 10}}></div>
        <Typography>
          {'Please refer to Google\'s documentation below for further information.'}
        </Typography>
        <div style={{height: 30}}></div>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'center'}}>
          <Button
            style={{alignContent:'center'}}
            variant={'outlined'}
            onClick={() => window.open('https://developers.google.com/classroom/reference/Request.Errors#http-400:-failed_precondition', '_blank')}
          >
            {'Google "FAILED_PRECONDITION" Documentation'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export { GoogleSubmissionErrorDialog }
