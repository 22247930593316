import { fetchGroups } from './fetchGroups'
import { deleteGroupMemberFromCloud } from '../group-gateways'
import { presentLeaveGroupResponseStarted, presentLeaveGroupResponseFailed, presentLeaveGroupResponseSucceeded } from '../group-presenter'
import { userSessionStore } from '../../user-session/user-session-store'
import { readActiveGroupIdFromStore } from '../group-store'
import { DEFAULT_GROUP_ID } from './generateDefaultGroups'
import { takeSeat } from './takeSeat'

async function leaveGroup(groupId) {
  presentLeaveGroupResponseStarted()

  const { uid: userId } = userSessionStore.readUserSession()
  const error = await deleteGroupMemberFromCloud(groupId, userId)
  if (error) {
    presentLeaveGroupResponseFailed(error)
    return error
  }

  const activeGroupId = readActiveGroupIdFromStore()
  if (activeGroupId === groupId) {
    await takeSeat(DEFAULT_GROUP_ID, true)
  }

  await fetchGroups()
  presentLeaveGroupResponseSucceeded(groupId)
}

export { leaveGroup }