import { getActiveMissionPack } from '../../content-director/content-director-use-cases/getActiveMissionPack'
import { getActiveMission } from '../../content-director/content-director-use-cases/getActiveMission'
import { userFileUseCases } from '../../user-files/user-file-use-cases'
import { userProgressController } from '../../../UserProgressControl'
import { isMissionManuallyLocked } from '../../content-director/content-director-use-cases/isMissionManuallyLocked'
import { objectiveIsScored } from '../../lesson-content/lesson-content-use-cases'

function formatFlowProgress(flowItem) {
  const flowProgressItem = {
    id: flowItem.id,
    type: flowItem.type,
    started: new Date(),
    completed: new Date(),
    tries: 0,
    xp: flowItem?.obj.xp,
  }

  if (objectiveIsScored(flowItem.id)) {
    flowProgressItem.score = 1
    flowProgressItem.bestScore = 2
  }

  if (flowItem.type === 'quiz') {
    flowProgressItem.questions = flowItem.obj.questions.map((q) => {
      const {id, answers, xp} = q.obj
      return {
        id,
        answers: answers.map((_, idx) => idx),
        xp,
        selected: answers.map((_, idx) => idx),
      }
    })
  }

  return flowProgressItem
}

async function completeMissionProgress() {
  const activeMission = getActiveMission()
  if (!activeMission) {
    return
  }

  const missionProgress = {
    started: new Date(),
    completed: isMissionManuallyLocked(activeMission.id) ? '' : new Date(),
    flow: activeMission.flow.map(formatFlowProgress),
  }

  const userProgress = await userFileUseCases.getUserProgress()
  userProgress.missions[activeMission.id] = missionProgress

  manuallySaveUserProgress(userProgress)
}

async function completeMissionPackProgress() {
  const missionProgress = {}

  const activeMissionPack = getActiveMissionPack()
  if (!activeMissionPack) {
    return
  }

  const { missions } = activeMissionPack
  missions.forEach((mission) => {
    missionProgress[mission.id] = {
      started: new Date(),
      completed: isMissionManuallyLocked(mission.id) ? '' : new Date(),
      flow: mission.flow.map(formatFlowProgress),
    }
  })

  const userProgress = await userFileUseCases.getUserProgress()
  userProgress.missions = {...userProgress.missions, ...missionProgress}

  manuallySaveUserProgress(userProgress)
}

// Do not use this function in production code, this is for testing purposes only
async function manuallySaveUserProgress(userProgress) {
  try {
    userProgressController.userProgress = userProgress
    await userProgressController.saveProgress(userProgress)
    await userProgressController.hydrateUI()
  } catch (err) {
    //
  }
}

export { completeMissionPackProgress, completeMissionProgress }
