// Control Editor functions such as code messages and error handling
class EditorControl {
  constructor() {
    this.editorInstance = null
    this.markerList = []
    this.hoverBreakpoint = {lineNumber: -1, glyphId: []}
    this.selectedBreakpoint = {lineNumber: -1, glyphId: []}
    this.highlightedLine = {lineNumber: -1, glyphId: []}
    this.breakpointObservable = new Set()
    this.preventAddBreakpoint = false
  }

  setEditorInstance = (editorInstance) => {
    this.editorInstance = editorInstance
  }

  notifyBreakpointObservers = () => {
    this.breakpointObservable.forEach(observer => observer(this))
  }

  addError = (err) => {
    // Given 'err' with base class Javascript 'Error'
    let endColumn, startColumn, lineNumber=err.lineNumber

    // If a call stack is provided, we currently just show the lowest level (nearest the error) that's in
    // the __main__ module.
    // TODO: Open correct file (lowest we have source to) based on callStack module name.
    if (err.callStack) {
      let line, module
      // Start at bottom of stack and find lowest __main__ point of error, and to support
      // debugging where the main file is an "eval" string, look for lowest '$exec_' also.
      for (let i = err.callStack.length - 1; i >= 0; --i) {
        [line, module] = err.callStack[i]
        if (module.startsWith('__main__') || module.startsWith('$exec_')) {
          lineNumber = line
          break
        }
      }
    }

    const model = this.editorInstance.getModel()
    // the USB devices can return a line past the last line
    const max = model.getLineCount()
    const safeLineNum = lineNumber <= max ? lineNumber : max

    if (!err.codeText) {
      // Grab this line of editor content
      err.codeText = model.getLineContent(safeLineNum)
    }
    const codeSection = err.codeText.slice(err.columnNumber - 1)
    const codeWordMatch = codeSection.match(/\w+/)
    if (codeWordMatch && codeWordMatch[0]) {
      // Mark to end of current word
      startColumn = err.columnNumber + codeWordMatch.index
      endColumn = startColumn + codeWordMatch[0].length
    } else {
      const minMarkLen = 4
      if (err.columnNumber) {
        if (err.codeText.length > (err.columnNumber + minMarkLen)) {
          startColumn = err.columnNumber
        } else {
          startColumn = Math.max(1, err.codeText.length - minMarkLen + 1)
        }
      } else {
        // Mark entire current line
        startColumn = 1
      }
      endColumn = err.codeText.length + 1
    }

    let errName = err.module === 'builtins' || err.module === '' ? err.name : `${err.module}.${err.name}`

    const markerData = {
      startLineNumber: safeLineNum,
      endLineNumber: safeLineNum,
      startColumn: startColumn,
      endColumn: endColumn,
      message: `${errName}: ${err.message}`,
      severity: window.monaco.MarkerSeverity.Error,  // Hint(1), Info(2), Warning(4), Error(8)
    }
    this.addMarker(markerData)

    this.showMarkerDetail()
  }

  addMarker = (markerData) => {
    this.markerList.push(markerData)
    const model = this.editorInstance.getModel()
    window.monaco.editor.setModelMarkers(model, 'api', this.markerList)
  }

  clearMarkers = () => {
    this.markerList = [] // we always want to clear this list
    let model = null
    try { // if the editor instance isnt mounted don't try to clear anything
      model = this.editorInstance.getModel()
    } catch (err) {
      return
    }
    window.monaco.editor.setModelMarkers(model, 'api', this.markerList)
    // To clear markers, you need to trigger both next in files and marker next in that order.
    this.editorInstance.trigger('api', 'editor.action.marker.nextInFiles')
    this.showMarkerDetail()
  }

  showMarkerDetail = () => {
    setTimeout(() => {
      // Bring up Monaco's "peek problem" message block beneath error marker
      // https://github.com/microsoft/vscode/blob/12134ce9978b156d6521bea84bce28a70fe90352/src/vs/editor/contrib/gotoError/gotoError.ts#L370
      this.editorInstance.getAction('editor.action.marker.next').run()

      // Alternatively
      // this.editorInstance.trigger('api', 'editor.action.marker.next')

      if (this.markerList.length > 0) {
        this.editorInstance.revealLineInCenter(this.markerList[0].startLineNumber)
      }
    }, 0)
  }

  editorTrigger = (command) => {
    if (!!this.editorInstance) {
      this.editorInstance.focus()
      this.editorInstance.trigger('', command)
    }
  }

  focusEditor = () => {
    if (!!this.editorInstance) {
      this.editorInstance.focus()
    }
  }

  decorationIsBreakpoint = (decoration) => {
    return (decoration.options.glyphMarginClassName === 'Breakpoint' ||
      decoration.options.glyphMarginClassName === 'SelectedGlyphWithBreakpoint' ||
      decoration.options.glyphMarginClassName === 'LightSelectedGlyphWithBreakpoint')
  }

  getBreakpointGlyphIds = (decorations) => {
    var breakpointList = []
    for (let i=0; i<decorations.length;i++) {
      if (this.decorationIsBreakpoint(decorations[i])) {
        breakpointList.push(decorations[i].id)
      }
    }
    return breakpointList
  }

  addBreakpoint = (model, ids, lineNumber, className) => {
    return model.deltaDecorations(ids, [ {
      range: {
        endColumn: 1,
        endLineNumber: lineNumber,
        startColumn: 1,
        startLineNumber: lineNumber,
      },
      options: {
        stickiness:1,
        glyphMarginClassName: className,
      },
    }])
  }

  removeHoverBreakpoint = () => {
    this.hoverBreakpoint.lineNumber = -1
    this.hoverBreakpoint.glyphId = this.editorInstance.getModel().deltaDecorations([this.hoverBreakpoint.glyphId], [])
  }

  breakpointExists = (lineNumber) => {
    let decorations = this.getBreakpointGlyphIds(this.editorInstance.getModel().getLineDecorations(lineNumber))
    if (decorations.length > 0) {
      return decorations
    } else {
      return false
    }
  }

  deleteHighlightedLine = () => {
    this.debugModel.deltaDecorations(this.highlightedLine.glyphId, [])
  }

  drawDebugLine = (lineNumber, glyphClass) => {
    this.highlightedLine.glyphId = this.debugModel.deltaDecorations(this.highlightedLine.glyphId, [ {
      range: {
        endColumn: 1,
        endLineNumber: lineNumber,
        startColumn: 1,
        startLineNumber: lineNumber,
      },
      options: {
        isWholeLine: true,
        className: this.theme === 'dark' ? 'HighlightedLine' : 'LightHighlightedLine',
        stickiness:1,
        glyphMarginClassName:glyphClass,
      },
    }])
  }

  bpMouseDown = (ev) => {
    // If the code is not being run
    if (!this.preventAddBreakpoint) {
      // If the user has clicked in the glyph margin
      if (ev.target.type === 2 && !ev.target.detail.isAfterLines) {
        const breakpointId = this.breakpointExists(ev.target.position.lineNumber)
        // If a breakpoint exists on the clicked glyph
        if (breakpointId) {
          // If the clicked glyph is also the highlighted line, render a selected glyph without breakpoint,
          // otherwise remove the breakpoint
          if (this.highlightedLine.lineNumber === ev.target.position.lineNumber) {
            this.selectedBreakpoint = {lineNumber: -1, glyphId: []}
            this.drawDebugLine(this.highlightedLine.lineNumber, this.theme === 'dark'? 'SelectedGlyph':'LightSelectedGlyph')
          } else {
            this.editorInstance.getModel().deltaDecorations([breakpointId], [])
            this.hoverBreakpoint.lineNumber = ev.target.position.lineNumber
            this.hoverBreakpoint.glyphId = this.addBreakpoint(this.editorInstance.getModel(), [this.hoverBreakpoint.glyphId], ev.target.position.lineNumber, 'HoverBreakpoint')
          }
          // If no breakpoint exists, render one
        } else {
          this.addBreakpoint(this.editorInstance.getModel(), [], ev.target.position.lineNumber, 'Breakpoint')
          if (this.highlightedLine.lineNumber === ev.target.position.lineNumber) {
            this.handleLineNumberChange(ev.target.position.lineNumber)
          }
        }
        this.notifyBreakpointObservers()
      }
    }
  }

  bpMouseMove = (ev) => {
    // If the code is not being run
    if (!this.preventAddBreakpoint) {
      // If the user has hovered over the glyph margin, or the line number margin
      if ((ev.target.type === 2 || ev.target.type === 3) && !ev.target?.detail.isAfterLines) {
        // If the glyph margin doesn't already have a hover breakpoint rendered
        if (this.hoverBreakpoint.lineNumber !== ev.target.position.lineNumber) {
          const breakpoint = this.breakpointExists(ev.target.position.lineNumber)
          // If the glyph margin doesn't already have a breakpoint rendered, or the selected line glyph
          if (!breakpoint && this.highlightedLine.lineNumber !== ev.target.position.lineNumber) {
            this.hoverBreakpoint.lineNumber = ev.target.position.lineNumber
            this.hoverBreakpoint.glyphId = this.addBreakpoint(this.editorInstance.getModel(), [this.hoverBreakpoint.glyphId], ev.target.position.lineNumber, 'HoverBreakpoint')
          } else {
            this.removeHoverBreakpoint()
          }
        }
        // If the user has hovered away form the glyph margin or line number margin, remove hover breakpoint
      } else if (this.hoverBreakpoint.lineNumber !== -1) {
        this.removeHoverBreakpoint()
      }
    } else if (this.hoverBreakpoint.lineNumber !== -1) {
      this.removeHoverBreakpoint()
    }
  }

  bpMouseLeave = (ev) => {
    this.removeHoverBreakpoint()
  }

  bpChangeContent = (ev) => {
    // Change content monitors whether multiple lines with glyphs were collapsed, in which case
    // the duplicate glyphs need to be removed and replaced with a single glyph to prevent glyph stacking
    // If the user collapsed a line
    if (ev.changes[0].range.startLineNumber < ev.changes[0].range.endLineNumber) {
      let decorations = this.editorInstance.getModel().getLineDecorations(ev.changes[0].range.startLineNumber)
      // If glyphs exist on the collapsed line
      if (decorations.length > 1) {
        let overflowGlyphs = this.getBreakpointGlyphIds(decorations)
        // If the line contains breakpoint glyphs
        if (overflowGlyphs.length > 1) {
          overflowGlyphs.length = overflowGlyphs.length-1
          this.editorInstance.getModel().deltaDecorations(overflowGlyphs, [])
        }
      }
    }
  }

  removeHighlightWithBreakpoint = () => {
    const check = this.selectedBreakpoint.glyphId.length > 0
    if (check) {
      this.deleteHighlightedLine()
      this.addBreakpoint(this.debugModel, this.selectedBreakpoint.glyphId, this.selectedBreakpoint.lineNumber, 'Breakpoint')
      this.selectedBreakpoint.glyphId = []
      this.selectedBreakpoint.lineNumber = -1
    }
    return check
  }

  handleLineNumberChange = (lineNumber) => {
    // console.log('In EditorController::handleLineNumberChange, lineNumber=' + lineNumber)
    this.highlightedLine.lineNumber = lineNumber
    // If the selected line glyph is covering a breakpoint, move the selected glyph, and rending the breakpoint
    // before moving the highlighted line foreward
    this.removeHighlightWithBreakpoint()
    // If the highlighted line is being deleted
    if (lineNumber === -1) {
      this.deleteHighlightedLine()
      // If the highlighted line is being moved to a valid row
    } else {
      var glyphClass = this.theme === 'dark' ? 'SelectedGlyph' : 'LightSelectedGlyph'
      let decorations = this.getBreakpointGlyphIds(this.debugModel.getLineDecorations(lineNumber))
      // If the next line has a breakpoint rendered, remove it and save it's locations to re-render it when the
      // highlighted line is moved in the future
      if (decorations.length > 0) {
        this.debugModel.deltaDecorations(decorations, [])
        this.selectedBreakpoint.glyphId = decorations
        this.selectedBreakpoint.lineNumber = lineNumber
        glyphClass = this.theme === 'dark' ? 'SelectedGlyphWithBreakpoint' : 'LightSelectedGlyphWithBreakpoint'
      }
      this.drawDebugLine(lineNumber, glyphClass)
      // From least aggressive to most aggressive at keeping the highlighted line centered
      // this.editorInstance.revealLine(parseInt(lineNumber))
      this.editorInstance.revealLineInCenterIfOutsideViewport(parseInt(lineNumber))
      // this.editorInstance.revealLineInCenter(parseInt(lineNumber))
    }
  }

  setDebugModel = () => {
    this.debugModel = this.editorInstance.getModel()
  }

  setTheme = (theme) => {
    this.theme = theme
    // If theres currently a theme-dependent glyph rendered, re-render it with the correct css
    if (this.highlightedLine.lineNumber !== -1) {
      if (this.selectedBreakpoint.lineNumber !== -1) {
        this.drawDebugLine(this.highlightedLine.lineNumber, this.theme === 'dark' ? 'SelectedGlyphWithBreakpoint' : 'LightSelectedGlyphWithBreakpoint')
      } else {
        this.drawDebugLine(this.highlightedLine.lineNumber, this.theme === 'dark' ? 'SelectedGlyph' : 'LightSelectedGlyph')
      }
    }
  }

  getBreakpoints = () => {
    const decorations = this.debugModel.getAllDecorations()
    var breakpointList = []
    for (let i=0; i<decorations.length;i++) {
      if (this.decorationIsBreakpoint(decorations[i])) {
        breakpointList.push(decorations[i].range.startLineNumber)
      }
    }
    return breakpointList
  }
}

// Singleton controller instance
export const editorController = new EditorControl()
