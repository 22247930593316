import React from 'react'
import { useSnackbar } from 'notistack'
import { fetchGroups } from '../content-manager/groups/group-use-cases/fetchGroups'
import { RESPONSE_STATUS } from '../content-manager/fetch-responses'

let setGroupsState = () => {}
let setFloatingLicenseSeatsState = () => {}
let toggleGroupsDialogOpenState = () => {}
let setActiveGroupIdState = () => {
}
let setSelectedGroupIdState = () => {}
let setSelectedAssignmentIdState = () => {}
let setFetchGroupsResponseState = () => {}
var setFetchFloatingLicenseSeatsResponseState = () => {}
let setTakeSeatResponseState = () => {}
let setJoinGroupResponseState = () => {}
let setLeaveGroupResponseState = () => {}
let setSubmitAssignmentResponseState = () => {}
let setAuthorizationRequiredState = () => {}
let toggleAuthorizationDialogOpen = () => {}
let closeGroupsDialogOpenState = () => {}
let enqueueSnack = () => {}
let setGroupsDialogOpenState = () => {}

function clearSelectedGroupIdState() {
  setSelectedGroupIdState(null)
}

function clearSelectedAssignmentIdState() {
  setSelectedAssignmentIdState(null)
}


const GroupsStateContext = React.createContext()
function GroupsProvider({ children }) {
  const snacks = useSnackbar()
  const [groupsInitialized, setGroupsInitialized] = React.useState(false)
  const [groups, setGroups] = React.useState([])
  setGroupsState = (groups) => {
    setGroups(groups)
    setGroupsInitialized(true)
  }

  const [floatingLicenseSeats, setFloatingLicenseSeats] = React.useState({})
  setFloatingLicenseSeatsState = setFloatingLicenseSeats

  enqueueSnack = (message, variant='success', autoHideDuration = 3000) => {
    snacks.enqueueSnackbar(message, {
      variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration,
    })
  }

  const [authorizationDialogOpen, setAuthorizationDialogOpen] = React.useState(false)
  toggleAuthorizationDialogOpen = () => setAuthorizationDialogOpen(authDialogOpen => !authDialogOpen)

  const [authorizationRequired, setAuthorizationRequired] = React.useState(false)
  setAuthorizationRequiredState = setAuthorizationRequired

  const [activeGroupId, setActiveGroupId] = React.useState(null)
  setActiveGroupIdState = setActiveGroupId

  const [selectedGroupId, setSelectedGroupId] = React.useState(null)
  setSelectedGroupIdState = setSelectedGroupId

  const [selectedAssignmentId, setSelectedAssignmentId] = React.useState(null)
  setSelectedAssignmentIdState = setSelectedAssignmentId

  const [groupsDialogOpen, setGroupsDialogOpen] = React.useState(false)
  setGroupsDialogOpenState = setGroupsDialogOpen
  toggleGroupsDialogOpenState = ({ groupId = null, assignmentId = null }) => {
    setSelectedGroupId(groupId)
    setSelectedAssignmentId(assignmentId)
    if (!groupsDialogOpen) {
      fetchGroups()
    }
    setGroupsDialogOpen(groupsDialogOpen => !groupsDialogOpen)
  }

  closeGroupsDialogOpenState = () => {
    setGroupsDialogOpen(false)
  }

  const [fetchGroupsResponse, setFetchGroupsResponse] = React.useState({})
  setFetchGroupsResponseState = setFetchGroupsResponse

  const [fetchFloatingLicenseSeatsResponse, setFetchFloatingLicenseSeatsResponse] = React.useState({})
  setFetchFloatingLicenseSeatsResponseState = setFetchFloatingLicenseSeatsResponse


  const [takeSeatResponse, setTakeSeatResponse] = React.useState({})
  setTakeSeatResponseState = setTakeSeatResponse

  const [joinGroupResponse, setJoinGroupResponse] = React.useState({})
  setJoinGroupResponseState = setJoinGroupResponse

  const [leaveGroupResponse, setLeaveGroupResponse] = React.useState({})
  setLeaveGroupResponseState = setLeaveGroupResponse

  const [submitAssignmentResponse, setSubmitAssignmentResponse] = React.useState({})
  setSubmitAssignmentResponseState = setSubmitAssignmentResponse

  const formatActiveGroup = (activeGroupId, groups) => {
    return groups.find(group => activeGroupId === group.id) ?? {}
  }
  return (
    <GroupsStateContext.Provider
      value={{
        groupsInitialized,
        groups,
        floatingLicenseSeats,
        groupsDialogOpen,
        activeGroupId,
        activeGroup: formatActiveGroup(activeGroupId, groups),
        selectedGroupId,
        selectedGroup: selectedGroupId ? groups.find(group => group.id === selectedGroupId) : null,
        selectedAssignmentId,
        fetchGroupsResponse,
        fetchGroupsInProgress: fetchGroupsResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        fetchFloatingLicenseSeatsResponse,
        fetchFloatingLicenseSeatsResponseInProgress: fetchFloatingLicenseSeatsResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        takeSeatResponse,
        takeSeatInProgress: takeSeatResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        takeSeatSucceeded: takeSeatResponse?.status === RESPONSE_STATUS.SUCCEEDED,
        joinGroupResponse,
        joinGroupInProgress: joinGroupResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        leaveGroupResponse,
        leaveGroupInProgress: leaveGroupResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        submitAssignmentResponse,
        submitAssignmentSucceeded: submitAssignmentResponse?.status === RESPONSE_STATUS.SUCCEEDED,
        authorizationRequired: !!authorizationRequired,
        authorizingGroupId: authorizationRequired?.groupId,
        authorizingCb: authorizationRequired?.cb,
        authorizationDialogOpen,
      }}>
      {children}
    </GroupsStateContext.Provider>
  )
}

function useGroups() {
  return React.useContext(GroupsStateContext)
}

export {
  GroupsProvider,
  useGroups,
  setGroupsState,
  toggleGroupsDialogOpenState,
  setActiveGroupIdState,
  setSelectedGroupIdState,
  setSelectedAssignmentIdState,
  clearSelectedGroupIdState,
  clearSelectedAssignmentIdState,
  setFetchGroupsResponseState,
  setFetchFloatingLicenseSeatsResponseState,
  setFloatingLicenseSeatsState,
  setTakeSeatResponseState,
  setJoinGroupResponseState,
  setLeaveGroupResponseState,
  setSubmitAssignmentResponseState,
  setAuthorizationRequiredState,
  toggleAuthorizationDialogOpen,
  closeGroupsDialogOpenState,
  enqueueSnack,
  setGroupsDialogOpenState,
}
