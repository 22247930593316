import { getMissionPacks } from '../../lesson-content/lesson-content-cloud-access'
import { getAvailableDevelopmentPackIds } from '../../content-director/content-director-use-cases/isMissionManuallyLocked'

let defaultGroupId = 0

const genFauxLicense = (packId, groupName=null) => {
  const groupId = groupName ?? `DEV-${packId}`
  const license =  {
    activeUsers: 0,
    groupId: groupId,
    id: groupId,
    groupName: groupId,
    licenseId: `${defaultGroupId}`,
    missionPackId: packId,
    seats: 1,
    type: 'Assigned',
    default: true,
  }
  defaultGroupId += 1
  return license
}

const GRPID_FREE_LEVEL1 = 'Free Pack: Python Level-1'
const GRPID_FREE_DEMO = 'Free Pack: CodeSpace Demo Showcase'
const GRPID_FREE_HAUNT23 = 'Free Pack: Haunted Code Chronicles'
const DEFAULT_GROUP_ID = window.location.pathname.includes('haunted') ? GRPID_FREE_HAUNT23 : GRPID_FREE_LEVEL1
function generateFreeGroups() {
  const groups = []
  groups.push(genFauxLicense('Free', GRPID_FREE_LEVEL1))
  groups.push(genFauxLicense('Free_Demo', GRPID_FREE_DEMO))
  groups.push(genFauxLicense('HWN', GRPID_FREE_HAUNT23))
  return groups
}
const FREE_GROUPS = generateFreeGroups()

async function generateDefaultGroups() {
  const groups = []

  // If we're in development, create free licenses for all packs. Otherwise, create free licenses
  // for any designated development packs if the user is whitelisted.
  if (process.env.NODE_ENV === 'development') {
    const packs = await getMissionPacks() ?? []
    packs.filter(packId => !packId.startsWith('Free') && packId !== 'HWN').forEach(pack => groups.push(genFauxLicense(pack)))
  } else {
    const devPackIds = getAvailableDevelopmentPackIds()
    for (const devPackId of devPackIds) {
      groups.push(genFauxLicense(devPackId))
    }
  }

  return groups
}

export { generateFreeGroups, FREE_GROUPS, generateDefaultGroups, DEFAULT_GROUP_ID, genFauxLicense }