import { userSessionPresenter } from './user-session-presenter'
import { userSessionStore } from './user-session-store'
import { userSessionGateway } from './user-session-gateway'
import { pollerController } from '../utils/poller-controller'

function handleOnlineStatusChanged(isOnline) {
  userSessionStore.setIsOnline(isOnline)
  userSessionPresenter.displayOnlineStatus(isOnline)
}

let onlineStatusPoller
function stopPoller() {
  clearInterval(onlineStatusPoller)
  onlineStatusPoller = null
}

function startPoller() {
  stopPoller()

  const checkCloudFuncCommunication = async () => {
    if (await userSessionGateway.getPing()) {
      handleOnlineStatusChanged(true)
      stopPoller()
    }
  }

  onlineStatusPoller = setInterval(
    checkCloudFuncCommunication
    , 1000)
}

const onlineStatusPollerController = {
  start: startPoller,
  stop: stopPoller,
}
pollerController.addPoller('onlineStatus', onlineStatusPollerController.start)

class UserSessionController {
  handleSessionWentOffline() {
    if (!userSessionStore.readIsOnline()) {
      return
    }
    handleOnlineStatusChanged(false)
    onlineStatusPollerController.start()
  }
}

const userSessionController = new UserSessionController()
export { userSessionController }