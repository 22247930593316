// Mission and Objective Panels
import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { PositionedPanel } from './PositionedPanels'
import Markdown from './FiriaMarkdown'
import { Divider } from '@material-ui/core'
import hexToRGBA from './hexToRGBA'
import { Done, Close, LiveHelp } from '@material-ui/icons'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import Checkbox from '@material-ui/core/Checkbox'
import Paper from '@material-ui/core/Paper'
import { useUserConfig } from './contexts/UserConfigContext'
import { ConfettiContainer } from './CompletionPanel'
import { useMission, MissionActions } from './contexts/MissionContext'
import { LessonPanelSkipButton } from './ObjectivePanel'

const useStyles = makeStyles((theme) => {
  return ({
    missionPanel: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
    },
    panelHeader: {
      display: 'flex',
      marginBottom: '0.2em',
      justifyContent: 'space-between',
    },
    noMargin: {
      margin: 0,
    },
    panelName: {
      marginLeft: '0.5em',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
    },
    collapseButton: {
      marginLeft: 'auto',
      width: 30,
      height: 30,
    },
    body: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      overflow: 'auto',
      padding:'0px 7px 14px 7px',
      width:'100%',
    },
    panelFooter: {
      display: 'flex',
      marginTop: 5,
    },
    continueButton: {
      marginLeft: 'auto',
    },
    minimizeIcon: {
      fontSize: 17,
      transform: 'scale(-1)',
    },
    maximizeIcon: {
      fontSize: 18,
    },
    checkboxRoot:{
      '&:hover': {
        backgroundColor: 'transparent',
      },
      padding:0,
      paddingRight:10,
    },
    checkboxIcon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkboxSelectedIcon: {
      stroke: 'white',
      strokeWidth: '2',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
      },
    },
    correctIcon:{
      backgroundColor: '#5DB460',
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    wrongIcon:{
      backgroundColor: '#f51818',
      'input:hover ~ &': {
        backgroundColor: '#db1616',
      },
    },
    disabledWrongIcon:{
      backgroundColor: '#f51818',
      'input:hover ~ &': {
        backgroundColor: '#db1616',
      },
    },
    buttonBase:{
      borderRadius: 7,
      padding: '0px 10px',
    },
    buttonDarkHover:{
      '&:hover': {
        backgroundColor: '#686868',
      },
    },
    buttonLightHover:{
      '&:hover': {
        backgroundColor: '#68686833',
      },
    },
    wrongAnswerButton:{
      '&:disabled': {
        backgroundColor: '#f5181833',
      },
    },
    correctAnswerButton:{
      '&:disabled': {
        backgroundColor: '#5DB46033',
      },
    },
    flexRow: {
      display:'flex',
      flexDirection:'row',
    },
    quizContainer: {
      overflowY:'auto',
      overflowX:'hidden',
      paddingLeft:15,
      paddingRight:15,
      paddingTop:17,
    },
    multiChoiceContainer: {
      flexWrap: 'wrap',
      justifyContent:'flex-start',
      width:'100%',
    },
    answerMarkdown: {
      textAlign: 'left',
    },
  })
})

function PanelHeader(props) {
  return (
    <div className={props.classes.panelHeader}>
      <div>
        <LiveHelp />
        <div className={props.classes.panelName}>
          <p className={props.classes.noMargin}>
            <span >{props.title}</span>
          </p>
        </div>
      </div>
      <div style={{marginLeft: 'auto', display: 'flex' }}>
      </div>
    </div>
  )
}

export const MultiChoiceButtonContent = (props) => {
  const classes = useStyles()
  return (
    <>
      <Checkbox
        className={classes.checkboxRoot}
        checked={props.checked}
        disableRipple
        color='default'
        disabled={!props.checked && props.questionCompleted}
        checkedIcon={
          props.correct ?
            <Done className={`${classes.checkboxIcon} ${classes.checkboxSelectedIcon} ${classes.correctIcon}`} />:
            <Close className={`${classes.checkboxIcon} ${classes.checkboxSelectedIcon} ${classes.wrongIcon}`}/>
        }
        icon={
          !props.correct && !props.checked && props.questionCompleted ?
            <Close className={`${classes.checkboxIcon} ${classes.checkboxSelectedIcon} ${classes.disabledWrongIcon}`}/>:
            <span className={classes.checkboxIcon}
            />
        }
      />
      <Markdown className={classes.answerMarkdown}>
        { props.md }
      </Markdown>
    </>
  )
}

const MultipleChoice = ({ setQuestionComplete, theme, question, progress }) => {
  const [answersState, setAnswersState] = useState({})
  const [, setDisableQuestion] = useState(false)
  const [, missionDispatch] = useMission()
  const classes = useStyles()

  const answersNeeded = () => {
    return question.answers.filter(answer => answer.correct).length
  }

  const answersCorrect = () => {
    return question.answers.filter(answer => answer.correct && answersState[answer.key] === 'selected').length
  }

  const handleAnswerClick = (key) => {
    var tempAnswersState = {...answersState}
    tempAnswersState[key] = 'selected'
    var mcCompleted = true
    // If all correct answers have been selected, then set off confetti and disabled remaining answers
    question.answers.filter(answer => answer.correct && tempAnswersState[answer.key] !== 'selected'? mcCompleted = false : null)
    if (mcCompleted){
      setDisableQuestion(true)
      setQuestionComplete()
    }
    setAnswersState(tempAnswersState)
  }

  React.useEffect(() => {
    var onLoadAnswerState = {}
    for (const selectedIndex in progress.selected){
      onLoadAnswerState[progress.selected[selectedIndex]] = 'selected'
    }
    setAnswersState(onLoadAnswerState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div className={`${classes.flexRow} ${classes.multiChoiceContainer}`} style={{gap:7}}>
        {progress.answers.map((answerKey) => {
          const answerData = question.answers[answerKey]
          const questionCompleted = progress.completed !== undefined && progress.completed
          return (
            <ButtonBase
              onClick={()=>{
                handleAnswerClick(answerKey)
                missionDispatch({ type: MissionActions.SET_QUIZ_ANSWER, questionId:question.id, answerKey:answerKey })
              }}
              disableRipple
              disabled={answersState[answerKey] === 'selected' || questionCompleted}
              className={
                answerData.correct || !questionCompleted ?
                  `${classes.buttonBase} ${theme === 'dark' ? classes.buttonDarkHover : classes.buttonLightHover} ${answerData.correct?classes.correctAnswerButton:classes.wrongAnswerButton}`:
                  `${classes.buttonBase} ${theme === 'dark' ? classes.buttonDarkHover : classes.buttonLightHover}`
              }
              key={answerKey}
            >
              <MultiChoiceButtonContent
                checked={answersState[answerKey] === 'selected'}
                questionCompleted={questionCompleted}
                correct={answerData.correct}
                md={answerData.a}
              />
            </ButtonBase>
          )
        })}
      </div>
      {answersNeeded() > 1 ? (
        <div style={{float:'right', marginRight:'1em', fontStyle:'italic'}}>
          Answered {answersCorrect()} of {answersNeeded()}
        </div>
      ): null}
    </div>
  )
}

const QuestionPanel = ({ question, progress }) => {
  const [runConfetti, setRunConfetti] = useState(false)
  const [userConfigState] = useUserConfig()
  const classes = useStyles()

  return (
    <ConfettiContainer
      run={runConfetti}
    >
      <Paper elevation={3} className={classes.body} square style={{backgroundColor:userConfigState.theme ==='dark'?'#595959':'white'}} >
        <div className={classes.flexRow} style={{alignItems:'flex-start', justifyContent:'space-between'}}>
          <div style={{flex:1}}>
            <Markdown style={{margin:0}}>
              { question.q }
            </Markdown>
          </div>
          <b style={{paddingTop:14, color:'darkorange'}}>{`+${question.xp} XP`}</b>
        </div>
        {question.type === 'mc' ? <MultipleChoice question={question} progress={progress} setQuestionComplete={()=>setRunConfetti(true)} theme={userConfigState.theme} />:null}
      </Paper>
    </ConfettiContainer>
  )
}

export default function QuizPanel(props) {
  const classes = useStyles()
  const transparency = 0.95
  const theme = useTheme()
  const backgroundColor = hexToRGBA(theme.palette.background.paper, transparency)

  const id = props?.json?.id
  const activeId = React.useRef(id)
  const bodyRef = React.useRef(null)
  // Manually scroll to top in fullscreen mode when the id changes
  if (id !== activeId.current) {
    activeId.current = id
    if (props.displayFullscreen) {
      bodyRef.current.scrollTo(0, 0)
    }
  }


  let panelStyle = {left:'15%', right:'15%', top:'10%', maxHeight:'80%', display:'flex', backgroundColor: backgroundColor}
  if (props.maximizeWindow) {
    panelStyle = {left:'0%', right:'0%', top:'0%', maxHeight:'100%', minHeight: '100%', display:'flex', backgroundColor: backgroundColor }
  }
  if (props.displayFullscreen) {
    panelStyle.backgroundColor = theme.palette.background.paper
  }

  return (
    <>
      <PositionedPanel
        style={{...panelStyle}}
        direction={'left'}
        anchorEl={props.missionViewElt}
        open={props.open}
        timeout={{enter: 200, exit: 200}}
        onExited={props.onPanelExit}
        onEntering={props.onPanelEnter}
        noSlide={props.panelSlidingDisabled}
      >
        <div className={classes.missionPanel}>
          <div style={{display: 'flex', flexDirection: 'column', height: 'calc(100% - 42px)'}}>
            <div>
              <PanelHeader {...props} title={props.quiz.data.obj.title} classes={classes} />
              <Divider />
            </div>
            <div ref={bodyRef} className={classes.quizContainer}>
              {!!props.quiz.data.obj.overview ?
                <div style={{marginTop: -15}}>
                  <Markdown>
                    {props.quiz.data.obj.overview}
                  </Markdown>
                  <Divider />
                </div>:null}
              {props.quiz.data.obj.questions.length > 0 ?
                props.quiz.data.obj.questions.map((question, index) => {
                  const questionProgress = props.quiz.progress.questions.find(q => q.id === question.obj.id)
                  return (
                    <div key={index}>
                      {question?.obj && questionProgress?.id ?
                        <div style={{paddingBottom:17}}>
                          <QuestionPanel question={question.obj} progress={questionProgress}/>
                        </div>:
                        null
                      }
                    </div>
                  )
                }) :
                null
              }
            </div>
          </div>
          <div>
            <Divider/>
            <div className={classes.panelFooter} >
              <LessonPanelSkipButton
                onContinueClick={props.onContinueButton}
                onCollapseClick={props.onCollapseButton}
              />
            </div>
          </div>
        </div>
      </PositionedPanel>
    </>
  )
}
QuizPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  missionViewElt: PropTypes.any,
  isCompleted: PropTypes.bool.isRequired,
  onCollapseButton: PropTypes.func.isRequired,
  onContinueButton: PropTypes.func.isRequired,
  maximizeWindow: PropTypes.bool.isRequired,
  setMaximizeWindow: PropTypes.func.isRequired,
}
