import { userIsLoggedIn } from '../user-session/user-session-use-cases/userIsLoggedIn'
import { isFileShared } from './code-file-use-cases/isFileShared'
import { readSharedUserFile } from './code-file-use-cases/readSharedUserFile'
import {
  codeFileGateway,
} from './code-file-gateways'
import { codeFileLocalStorageController, loggedOutLocalStorageController } from '../../databaseworker/database.worker.controller'

export const LOGGED_OUT_FILE_ID = 'loggedOutFile'
class CodeFileUseCases {
  async getFile(fileId) {
    if (!userIsLoggedIn()) {
      return await loggedOutLocalStorageController.readFile(LOGGED_OUT_FILE_ID)
    }

    if (isFileShared(fileId)) {
      return readSharedUserFile(fileId)
    }

    return await codeFileLocalStorageController.readFile(fileId)
  }

  async saveFile(fileId, contents, metadata=null) {
    if (!userIsLoggedIn()) {
      return await loggedOutLocalStorageController.saveFile(LOGGED_OUT_FILE_ID, contents)
    }

    const saveToDatabase = codeFileLocalStorageController.saveFile(fileId, contents, metadata)
    const saveToCloud = codeFileGateway.saveToCloud(fileId, contents, metadata)

    return await Promise.all([saveToDatabase, saveToCloud])
  }

  async deleteFile(fileId, metadata=null) {
    const deleteFromDatabase = codeFileLocalStorageController.deleteFile(fileId, metadata)
    const deleteFromCloud = codeFileGateway.deleteFromCloud(fileId, metadata)
    return await Promise.allSettled([deleteFromDatabase, deleteFromCloud])
  }

  async saveMetadata(metadata) {
    const saveToDatabase = codeFileLocalStorageController.saveMetadata(metadata)
    const saveToCloud = codeFileGateway.saveMetadataToCloud(metadata)
    return await Promise.allSettled([saveToDatabase, saveToCloud])
  }

  async getMetadata() {
    return await codeFileLocalStorageController.readMetadata()
  }
}

const codeFileUseCases = new CodeFileUseCases()
export { codeFileUseCases }