import { userProgressController } from '../../../UserProgressControl'
import { validatorController } from '../../../ValidatorControl'
import { getActiveContentSequenceItem } from './getActiveContentSequenceItem'
import { presentScore } from '../content-director-presenter'

let scoreStore = {}
// Why do we need to 'lock' the score?
// The validators keep running a little bit after the program stops, which
// could lead to score accumulating when it shouldn't be. Fortunately, 'reset' is called
// every time the program starts. So it'll be unlocked every run.
let locked = false

function validatorsValidated() {
  return !validatorController.validators.find(v => !v.validated)
}

function get(key = null) {
  if (!key) {
    let score = 0
    Object.values(scoreStore).forEach(scoreVal => score += scoreVal)
    return score
  }

  if (!(key in scoreStore)) {
    scoreStore[key] = 0
    return 0
  }

  return scoreStore[key]
}

function set(key, newScore, overrideLock=false) {
  if (locked && !overrideLock) {
    return
  }
  scoreStore[key] = Math.trunc(newScore)
  presentScore(get())

  // If the validators have validated already, and the user continues to gain score, save it
  if (validatorsValidated()) {
    save()
  }
}

function add(key, scoreToAdd, overrideLock=false) {
  const score = get(key)
  set(key, score + scoreToAdd, overrideLock)
  return score
}

function subtract(key, scoreToSubtract, overrideLock=false) {
  const score = get(key)
  set(key, score - scoreToSubtract, overrideLock)
  return score
}

async function save() {
  const item = getActiveContentSequenceItem()
  // The user isn't on an objective.
  if (!item) {
    return
  }

  if (!validatorsValidated()) {
    return
  }

  const score = get()
  if (!!score && score > 1) {
    userProgressController.updateScore(item.flow.id, score)
  }
}

function reset() {
  locked = false
  scoreStore = {}
  presentScore(get())
}

function lock() {
  locked = true
}

const scoreInterface = Object.freeze({
  set,
  get,
  add,
  subtract,
  save,
  reset,
  lock,
})

const resetScore = reset
const saveScore = save
export {
  scoreInterface,
  resetScore,
  saveScore,
}