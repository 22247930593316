import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useScene } from './contexts/SceneContext'

const useStyles = makeStyles(() => ({
  cursorChanger: ready => ({
    cursor: ready ? 'inherit' : 'wait',
  }),
  cursorEater: ready => ({
    pointerEvents: ready ? 'inherit' : 'none',
  }),
  fullSize: {
    width: '100%',
    height: '100%',
  },
}))

const SimulatorOverlay = ({children, ...props}) => {
  const [sceneState] = useScene()
  const classes = useStyles(sceneState.loading)

  return (
    <div {...props} className={`${classes.fullSize} ${classes.cursorChanger}`} >
      <div className={`${classes.fullSize} ${classes.cursorEater}`} >
        {children}
      </div>
    </div>
  )
}
export default SimulatorOverlay