import React from 'react'
import { RESPONSE_STATUS } from '../content-manager/fetch-responses'
import { markNotificationRead } from '../content-manager/notifications/notification-use-cases'
import { useLogin } from './LoginContext'
import { useSnackbar } from 'notistack'

let notificationsStateInterface = {
  setFetchNotificationsResponseState: () => {},
  setNotificationsState: () => {},
  openDialog: () => {},
  closeDialog: () => {},
  selectNotificationIdState: () => {},
  displayNotificationReminder: () => {},
}

const NotificationsStateContext = React.createContext()
function NotificationsProvider({ children }) {
  const [notificationsDialogOpen, setNotificationsDialogOpen] = React.useState(false)
  const [fetchNotificationsResponse, setFetchNotificationsResponse] = React.useState({})
  const [notifications, setNotifications] = React.useState([])
  const [notificationsInitialized, setNotificationsInitialized] = React.useState(true)
  const [selectedNotificationId, setSelectedNotificationId] = React.useState(null)
  const [loginState] = useLogin()
  const snacks = useSnackbar()

  const unreadNotifications = notifications.filter(notification => !notification.read).length

  const notificationsReminderDisplayed = React.useRef(false)
  const fetchStarted = React.useRef(false)
  React.useEffect(() => {
    if (!loginState?.user) {
      fetchStarted.current = false
      setNotificationsInitialized(true)
      notificationsReminderDisplayed.current = false
    }
  }, [loginState])

  React.useEffect(() => {
    if (fetchNotificationsResponse?.status && !fetchStarted.current) {
      setNotificationsInitialized(false)
      fetchStarted.current = true
    }

    if (fetchNotificationsResponse?.status === RESPONSE_STATUS.SUCCEEDED) {
      setNotificationsInitialized(true)
    }
  }, [fetchNotificationsResponse])

  notificationsStateInterface.openDialog = () => setNotificationsDialogOpen(true)
  notificationsStateInterface.closeDialog = () => setNotificationsDialogOpen(false)
  notificationsStateInterface.setFetchNotificationsResponseState = setFetchNotificationsResponse
  notificationsStateInterface.setNotificationsState = setNotifications
  notificationsStateInterface.selectNotificationIdState = (id) => {
    if (!!id) {
      markNotificationRead(id)
    }
    setSelectedNotificationId(id)
  }
  notificationsStateInterface.displayNotificationReminder = () => {
    if (!!notificationsReminderDisplayed.current) {
      return
    }

    notificationsReminderDisplayed.current = true
    if (unreadNotifications === 0) {
      return
    }
    let message = 'New notifications!'
    if (unreadNotifications === 1) {
      message = 'New notification!'
    }
    snacks.enqueueSnackbar(message, {
      variant: 'info',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'left',
      },
    })
  }

  return (
    <NotificationsStateContext.Provider
      value={{
        notificationsStateInterface,
        selectedNotificationId,
        selectedNotification: notifications.find(notification => notification.id === selectedNotificationId),
        unreadNotifications,
        allNotificationsRead: unreadNotifications === 0,
        notificationsDialogOpen,
        notificationsInitialized,
        notifications,
        fetchNotificationsResponse,
        fetchNotificationsInProgress: fetchNotificationsResponse?.status === RESPONSE_STATUS.IN_PROGRESS,
        fetchNotificationsFailed: fetchNotificationsResponse?.status === RESPONSE_STATUS.FAILED,
        notificationsReminderDisplayed,
      }}>
      {children}
    </NotificationsStateContext.Provider>
  )
}

function useNotifications() {
  return React.useContext(NotificationsStateContext)
}

export {
  NotificationsProvider,
  useNotifications,
  notificationsStateInterface,
}