// Run tasks serially with time-pacing
export class SerialRunner {
  constructor() {
    this.functions = []
    this.currentTaskIndex = 0
    this.isRunning = false
    this.timerId = null
  }

  // Add a task to the list
  add = (fn) => {
    this.functions.push(fn)
  }

  wait = (ms) => {
    return new Promise((resolve) => {
      this.timerId = setTimeout(resolve, ms)
    })
  }

  // Start executing functions in series
  start = async (doLoop=true) => {
    this.isRunning = true

    while (this.isRunning) {
      if (this.currentTaskIndex < this.functions.length) {
        await this.functions[this.currentTaskIndex]()
        this.currentTaskIndex++
      } else {
        this.reset(doLoop)
      }
    }
  }

  // Stop executing functions
  stop = () => {
    this.isRunning = false
    clearTimeout(this.timerId)
  }

  // Reset to first task on list
  reset = (keepRunning) => {
    this.currentTaskIndex = 0
    this.isRunning = keepRunning
  }
}

/*
// Usage example:

const runner = new SerialRunner()

// Adding functions to the runner
runner.add(async () => {
  console.log('Function 1 started')
  runner.wait(1000)
  console.log('Function 1 ended')
})

runner.add(async () => {
  console.log('Function 2 started')
  runner.wait(1000)
  console.log('Function 2 ended')
})

runner.start()

// After 500ms, stop the runner
setTimeout(() => {
  runner.stop()
  console.log('Stopped')

  // After 1000ms, start the runner again
  setTimeout(() => {
    runner.start()
  }, 1000)
}, 500)
*/