import { appDefaults } from '../../BotSimDefaults'

const store = {}
function setContentDirectorStoreDefaults() {
  store.activeMissionPackId = 'Free'
  store.activeMissionId = 'Free_01Welcome'
  store.contentSequenceIndex = -1
  store.contentSequence=[]
  store.initialized = false
}
setContentDirectorStoreDefaults()

function setContentDirectorInitialized() {
  store.initialized = true
}

function readContentDirectorInitialized() {
  return store.initialized
}

function setActiveMissionPackId(activeMissionPackId){
  store.activeMissionPackId = activeMissionPackId ?? appDefaults.userProgress.ui.activeMissionPackId
}

function readActiveMissionPackId() {
  return store.activeMissionPackId
}

function setActiveMissionId(activeMissionId) {
  if (!activeMissionId || (typeof activeMissionId === 'string' && activeMissionId.length === 0)) {
    store.activeMissionId = appDefaults.userProgress.ui.activeMissionId
    return
  }

  store.activeMissionId = activeMissionId ?? appDefaults.userProgress.ui.activeMissionId
}

function readActiveMissionId() {
  return store.activeMissionId
}

function setContentSequence(contentSequence) {
  store.contentSequence = contentSequence
}

function readContentSequence() {
  return store.contentSequence
}

function readContentSequenceItem(contentSequenceItemIndex) {
  return store.contentSequence[contentSequenceItemIndex]
}

function setContentSequenceIndex(contentSequenceIndex){
  store.contentSequenceIndex = contentSequenceIndex
}

function readContentSequenceIndex(){
  return store.contentSequenceIndex
}

export {
  setContentDirectorInitialized,
  readContentDirectorInitialized,
  setContentDirectorStoreDefaults,
  setActiveMissionPackId,
  readActiveMissionPackId,
  setActiveMissionId,
  readActiveMissionId,
  setContentSequence,
  readContentSequence,
  readContentSequenceItem,
  setContentSequenceIndex,
  readContentSequenceIndex,
}