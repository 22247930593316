import React from 'react'
import { v4 as uuid } from 'uuid'
import { useLogin } from './LoginContext'
import { appDefaults } from '../BotSimDefaults'
import { FileOpStatus, isFileNameInvalid, FileOperation, encodeFileStat } from '../utils/file-operation-utils'
import { simFsController } from '../SimFileSystemControl'
import { ValidatorContextKeys, validatorController } from '../ValidatorControl'
import { isFileShared } from '../content-manager/code-files/code-file-use-cases/isFileShared'
import { handleContentManagerOnUserSessionStarted, initializeContentManagerUserSession } from '../content-manager/content-manager-use-cases'
import { codeFileController } from '../content-manager/code-files/code-file-controllers'
import { codeFileUseCases } from '../content-manager/code-files/code-file-use-cases'
import { userFileController } from '../content-manager/user-files/user-file-controller'
import { userFileUseCases } from '../content-manager/user-files/user-file-use-cases'

let pyFilesBuffer = new Map()


// Current file metadata structure:
/*
{
  name: String,
  parentFolder: UUID,
  createdAt: Date,
  modifiedAt: Date,
  language: String
}
*/
// Current folder metadata structure:
/*
{
  name: String,
  parentFolder: UUID || null,   // null if current folder is "root"
  tree: [UUID]                  // folder and/or file UIDs
}
*/

export const FileManagementActions = Object.freeze({
  NEW_FILE: Symbol('new file'),
  READ_FILE: Symbol('read file'),
  SAVE_FILE: Symbol('save file'),
  NEW_FOLDER: Symbol('new folder'),
  MOVE: Symbol('move'),
  RENAME: Symbol('rename'),
  DELETE: Symbol('delete'),

  // Actions for CodePanelContext
  SAVE_EDITOR_STATE: Symbol('save editor state'),
  READ_EDITOR_STATE: Symbol('read editor state'),

  // Actions for UserConfigContext
  SAVE_USER_CONFIG: Symbol('save user config'),
  READ_USER_CONFIG: Symbol('read user config'),

  // Actions for UserProgressContext
  SAVE_USER_PROGRESS: Symbol('save user progress'),
  READ_USER_PROGRESS: Symbol('read user progress'),

  GET_ABSOLUTE_FILE_PATH: Symbol('get absolute file path'),
  READ_FILE_TREE: Symbol('read file tree'),
  SET_FILE_ERROR: Symbol('set file error'),
})
export var fileManagementDispatch = () => {}

export const FileSystemStates = Object.freeze({
  FILE_SYSTEM_INIT: 0,
  FILE_SYSTEM_PARTIAL: Symbol('file system partially loaded'),
  FILE_SYSTEM_DONE: Symbol('file system fully loaded'),
})

validatorController.addContextActions(ValidatorContextKeys.FILE_MANAGEMENT, FileManagementActions)

export const FileManagementContext = React.createContext()
export const FileManagementProvider = ({ children }) => {
  const [loginState] = useLogin()
  const [fileSystemReady, setFileSystemReady] = React.useState(FileSystemStates.FILE_SYSTEM_INIT)
  const [userProgressFetchFailed, setUserProgressFetchFailed] = React.useState(false)
  const [state, setState] = React.useState({
    root: null,
    tree: new Map(),
  })

  // Handle user login/logout, by providing DatabaseWorker with our
  // user's UID & idToken (if applicable), so that it may hydrate the filesystem
  React.useEffect(() => {
    if (!loginState.loginReady) {
      return
    }
    const handleUserChanged = async (user) => {
      handleContentManagerOnUserSessionStarted(loginState?.user)
      setFileSystemReady(FileSystemStates.FILE_SYSTEM_INIT)

      try {
        if (loginState?.user) {
          const fileTreePromise = codeFileController.initializeUserFileTree()
          await userFileController.initializeUserFiles()
          const fileTree = await fileTreePromise
          setState(fileTree)
          initializeContentManagerUserSession(loginState?.user)
        }
      } catch (err) {
        // TODO: if this fails then logout and throw up a toast
        console.error(err)
      }
      pyFilesBuffer = new Map()
      await new Promise(resolve => setTimeout(() => {
        setFileSystemReady(FileSystemStates.FILE_SYSTEM_DONE)
        resolve()
      }, 1))
    }
    handleUserChanged(loginState?.user)
  }, [loginState])

  const nameConflict = React.useCallback((tree, name) => {
    return [...(tree ?? [])].filter(node => state.tree.get(node)?.name === name)?.length > 0
  }, [state.tree])

  const handleRename = async (fileTree, uid, name) => {
    const metadata = fileTree.get(uid)
    if (!metadata) {
      return
    }

    if (!name) {
      throw new Error('File name cannot be empty')
    }

    if (nameConflict(fileTree.get(metadata?.parentFolder)?.tree, name)) {
      throw new Error('File name already in use')
    }

    metadata.name = name
    metadata.modifiedAt = Date.now()
    fileTree.set(uid, metadata)

    try {
      await codeFileUseCases.saveMetadata({
        [uid]: metadata,
      })
    } catch (err) {
      console.error(err)
    }

    return fileTree
  }

  const handleDelete = async (fileTree, uid) => {
    // Delete entry from parent tree
    const parentFolderUid = fileTree.get(uid)?.parentFolder
    let parentFolderMetadata
    if (!!parentFolderUid) {
      parentFolderMetadata = fileTree.get(parentFolderUid)
      const index = parentFolderMetadata.tree.indexOf(uid)
      if (index > -1) {
        parentFolderMetadata.tree.splice(index, 1)
      }
      fileTree.set(parentFolderUid, parentFolderMetadata)
    }
    // Gather all uids under this one into an array to delete
    const uidsToDelete = []
    const rmRecursive = (uid) => {
      const subtree = fileTree.get(uid)?.tree
      if (!!subtree) {
        subtree.forEach(uid => rmRecursive(uid))
      }
      uidsToDelete.push(uid)
    }
    rmRecursive(uid)
    uidsToDelete.forEach(uid => fileTree.delete(uid))
    try {
      await codeFileUseCases.deleteFile(
        uidsToDelete,
        !!parentFolderUid ? {
          [parentFolderUid]: parentFolderMetadata,
        } : undefined)
    } catch (err) {
      console.error(err)
    }
    return fileTree
  }

  const dispatch = async (action) => {
    validatorController.handleContextEvent(action)

    switch (action.type) {
      // { type: ..., parentFolder: UUID, name: String }
      case FileManagementActions.NEW_FOLDER: {
        const { parentFolder, name } = action
        const fileTree = new Map(state.tree)

        // Folders are just, like, a man-made construct, dude.
        // They only exist as nodes in the tree, not actually within file system!
        if (!name) {
          throw new Error('Folder name cannot be empty')
        }

        // Create a new folder UID
        const folderUid = uuid()

        // Check for naming conflicts
        /*
        if (nameConflict(fileTree.get(parentFolder).tree, name)) {
          throw new Error('File already exists')
        }
        */

        // Add this folder to the parent folder's tree
        const parentMetadata = fileTree.get(parentFolder)
        if (!!parentMetadata.tree) {
          parentMetadata.tree.push(folderUid)
          fileTree.set(parentFolder, parentMetadata)
        }

        // Set this folder's metadata
        const metadata = {
          name,
          parentFolder,
          tree: new Set(),
        }
        fileTree.set(folderUid, metadata)

        try {
          await codeFileUseCases.saveMetadata({
            [folderUid]: metadata,
            [parentFolder]: parentMetadata,
          })
        } catch (err) {
          console.error(err)
        }

        setState(state => ({ ...state, tree: fileTree }))
        break
      }

      // { type: ..., parentFolder: UUID, name: String, code: Any }
      case FileManagementActions.NEW_FILE: {
        if (!loginState?.user) {
          return
        }
        const { parentFolder, name, code } = action
        const fileTree = new Map(state.tree)
        if (!name) {
          throw new Error('File name cannot be empty')
        }

        // Create a new file UID
        const fileUid = uuid()

        // Check for naming conflicts
        if (nameConflict(fileTree.get(parentFolder)?.tree, name)) {
          throw new Error('File already exists')
        }

        // Add this file to the parent folder's tree
        const parentMetadata = fileTree.get(parentFolder)
        if (!!parentMetadata?.tree) {
          parentMetadata.tree.push(fileUid)
          fileTree.set(parentFolder, parentMetadata)
        }

        const userConfig = await dispatch({ type: FileManagementActions.READ_USER_CONFIG })

        // Set file's metadata
        const metadata = {
          name,
          parentFolder,
          createdAt: Date.now(),
          modifiedAt: Date.now(),
          codeLanguage: userConfig?.codeLanguage ?? 'python',
        }
        fileTree.set(fileUid, metadata)

        const newState = {
          ...state,
          tree: fileTree,
        }
        try {
          await codeFileUseCases.saveFile(
            fileUid,
            code ?? '',
            {
              [fileUid]: metadata,
              [parentFolder]: parentMetadata,
            })
        } catch (err) {
          console.error(err)
        }
        setState(newState)

        return fileUid
      }

      // { type: ..., uid: UUID }
      case FileManagementActions.READ_FILE: {
        const { uid } = action

        try {
          const code = await codeFileUseCases.getFile(uid)
          return code
        } catch (err) {
          console.error(err)
          throw new Error('Error reading file')
        }
      }

      // { type: ..., uid: UUID, code: Any }
      case FileManagementActions.SAVE_FILE: {
        if (!loginState?.user) {
          return
        }

        const { uid, code } = action
        if (isFileShared(uid)) {
          return
        }

        // Update modifiedAt date
        const newState = {
          ...state,
          tree: new Map(state.tree),
        }
        const metadata = newState.tree.get(uid)
        if (!metadata) {
          return
        }
        metadata.modifiedAt = Date.now()
        newState.tree.set(uid, metadata)
        // Save code to file
        try {
          await codeFileUseCases.saveFile(uid, code, { [uid]: metadata })
        } catch (err) {
          console.error(err)
          throw new Error('Could not save file')
        }

        setState(newState)
        break
      }

      // { type: ..., uid: UUID, to: UUID }
      case FileManagementActions.MOVE: {
        const { uid, to } = action
        const fileTree = new Map(state.tree)

        let metadata = fileTree.get(uid)

        // Check if a file with the same name exists in the target directory
        /*
        if (nameConflict(state.get(to).tree, metadata.name)) {
          throw new Error('A file with the same name already exists')
        }
        */

        // Handle current parent folder association
        const oldParentUid = metadata.parentFolder
        const oldParentFolder = fileTree.get(oldParentUid)
        oldParentFolder.tree.delete(uid)
        fileTree.set(metadata.parentFolder, oldParentFolder)
        // Add to new parent folder's tree
        const newParentUid = to
        const newParentFolder = fileTree.get(newParentUid)
        newParentFolder.tree.push(uid)
        fileTree.set(to, newParentFolder)
        // Update the folder/file with the new parent
        metadata.parentFolder = to
        fileTree.set(uid, metadata)

        try {
          await codeFileUseCases.saveMetadata({
            [uid]: metadata,
            [oldParentUid]: oldParentFolder,
            [newParentUid]: newParentFolder,
          })
        } catch (err) {
          console.error(err)
        }

        setState(state => ({ ...state, tree: fileTree }))
        break
      }

      // { type: ..., uid: UUID, name: String }
      case FileManagementActions.RENAME: {
        const { uid, name } = action
        const fileTree = new Map(state.tree)

        const updatedFileTree = await handleRename(fileTree, uid, name)
        setState(state => ({ ...state, tree: updatedFileTree }))
        break
      }

      // { type: ..., uid: UUID }
      case FileManagementActions.DELETE: {
        const { uid } = action
        const fileTree = new Map(state.tree)

        const updatedFileTree = await handleDelete(fileTree, uid)
        setState(state => ({ ...state, tree: updatedFileTree }))
        break
      }

      // { type: ..., editorState: Object }
      case FileManagementActions.SAVE_EDITOR_STATE: {
        if (!loginState?.user) {
          return
        }

        try {
          await userFileUseCases.saveEditorState(action.editorState)
        } catch (err) {
          console.error(err)
        }
        break
      }

      // { type: ... }
      case FileManagementActions.READ_EDITOR_STATE: {
        if (!loginState?.user) {
          return
        }

        try {
          let editorState = await userFileUseCases.getEditorState()
          // Quick fix to not barf on all the developers since the type of `tabs.opened`
          // has changed from `Map` to `Set` (and therefore uses different instance functions).
          // This test can be removed after everyone has pulled this change
          // (which will stop saving `Map` types), and the Cloud Storage blown away...
          if (editorState?.tabs?.opened instanceof Map) {
            console.debug('Editor State file contained a Map instance. Ignorning.')
            return appDefaults.codePanel
          } else {
            return editorState
          }
        } catch (err) {
          console.error(err)
        }
        break
      }

      // { type: ..., userConfig: Object }
      case FileManagementActions.SAVE_USER_CONFIG: {
        if (!loginState?.user) {
          return
        }

        const existingUserConfig = await dispatch({ type: FileManagementActions.READ_USER_CONFIG })
        const userConfig = {
          ...appDefaults.userConfig, // fills in undefined fields
          ...existingUserConfig,
          ...action.userConfig,
        }

        try {
          await userFileUseCases.saveUserConfig(userConfig)
        } catch (err) {
          console.error(err)
        }
        break
      }

      // { type: ... }
      case FileManagementActions.READ_USER_CONFIG: {
        if (!loginState?.user) {
          return
        }

        try {
          const userConfig = await userFileUseCases.getUserConfig()
          return { ...appDefaults.userConfig, ...userConfig } // fills in undefined fields
        } catch (err) {
          console.error(err)
        }
        break
      }

      // { type: ..., userProgress: Object }
      case FileManagementActions.SAVE_USER_PROGRESS: {
        const userId = action.userId ?? loginState?.user?.uid
        if (!userId) {
          return
        }

        const userProgress = {
          ...action.userProgress,
        }

        try {
          await userFileUseCases.saveUserProgress(userProgress)
        } catch (err) {
          console.error(err)
        }
        break
      }

      // { type: ... }
      case FileManagementActions.READ_USER_PROGRESS: {
        const userId = action.userId ?? loginState?.user?.uid
        if (!userId) {
          return
        }

        try {
          return await userFileUseCases.getUserProgress()
        } catch (err) {
          console.error(err)
        }
        break
      }

      // { type: ... }, fileUid: string
      case FileManagementActions.GET_ABSOLUTE_FILE_PATH: {
        if (isFileShared(action.fileUid)) {
          return ''
        }

        let file = state.tree?.get(action.fileUid)
        if (!file || !loginState?.user) {
          return
        }
        let filePath = file?.name
        let parentFolder = file?.parentFolder
        while (parentFolder) {
          const metadata = state.tree?.get(parentFolder)
          filePath = metadata.name + '/' + filePath
          parentFolder = metadata.parentFolder
        }

        return filePath
      }
      case FileManagementActions.READ_FILE_TREE: {
        return state
      }
      case FileManagementActions.SET_FILE_ERROR: {
        setUserProgressFetchFailed(true)
        return
      }
      default:
        throw new Error(`Unhandled action type: ${action.type.toString()}`)
    }
  }

  // Handle file operations from the python code such as Reads and Writes
  // all files are written in binary
  React.useEffect(() => {
    const getFileStat = async (filename) => {
      if (!loginState?.user) {
        let pyFile = pyFilesBuffer.get(filename)
        if (pyFile === undefined) {
          return [FileOpStatus.FILE_NOT_FOUND, []]
        }
        // FAKE STAT - assume we can never make folders if not logged in
        const uid = uuid()
        const fileLen = pyFile.length
        const isFile = true
        const createdTm = Date.now()
        const modifiedTm = Date.now()
        const stat = encodeFileStat(uid, isFile, modifiedTm, createdTm, fileLen)
        return [FileOpStatus.COMPLETE_FILE_READ, stat]
      }
      const f = [...(state.tree ?? [])].filter(node => node[1].name === filename)
      if (f?.length === 1) {
        const [uid, metadata] = f[0]
        const isFile = metadata['tree'] === undefined

        let data = []
        if (isFile) {
          try {
            data = await codeFileUseCases.getFile(uid)
          } catch (err) {
            console.error(err)
            return [FileOpStatus.UNKNOWN_FAIL, []]
          }
        }

        try {
          const fileLen = data.length
          const createdTm = metadata.createdAt
          const modifiedTm = isFile ? metadata.modifiedAt : createdTm
          const statData = encodeFileStat(uid, isFile, modifiedTm, createdTm, fileLen)
          return [FileOpStatus.COMPLETE_FILE_READ, statData]
        } catch (err) {
          console.error(err)
        }
        return [FileOpStatus.UNKNOWN_FAIL, []]
      } else if (f?.length > 1) {
        return [FileOpStatus.MORE_THAN_ONE_FILE, []]
      } else {
        return [FileOpStatus.FILE_NOT_FOUND, []]
      }
    }

    const renameFile = async (filename, newFilename) => {
      if (!loginState?.user) {
        let pyFile = pyFilesBuffer.get(filename)
        if (pyFile === undefined) {
          return FileOpStatus.FILE_NOT_FOUND
        }
        pyFilesBuffer.set(newFilename, pyFile)
        pyFilesBuffer.delete(filename)
        return FileOpStatus.OS_OP_SUCCESS
      }

      var fileTree = new Map(state.tree)

      const f = [...fileTree].filter(node => node[1].name === filename)
      if (f?.length === 1) {
        // do nothing continue
      } else if (f?.length > 1) {
        return FileOpStatus.MORE_THAN_ONE_FILE
      } else {
        return FileOpStatus.FILE_NOT_FOUND
      }

      // this function will overwrite a file if it already exists so check and delete if exists
      const newF = [...fileTree].filter(node => node[1].name === newFilename)
      if (newF?.length === 1) {
        const [newFuid] = newF[0]
        try {
          simFsController.deleteTabCb(newFuid)
          fileTree = await handleDelete(fileTree, newFuid)
        } catch (err) {
          console.error(err)
          return FileOpStatus.UNKNOWN_FAIL
        }
      } else if (newF?.length > 1) {
        return FileOpStatus.MORE_THAN_ONE_FILE
      }

      const [uid] = f[0]
      try {
        fileTree = await handleRename(fileTree, uid, newFilename)
      } catch (err) {
        console.error(err)
        return FileOpStatus.UNKNOWN_FAIL
      }

      simFsController.updateTabCb(uid)
      setState(state => ({ ...state, tree: fileTree }))
      return FileOpStatus.OS_OP_SUCCESS
    }

    const deleteFile = async (filename) => {
      if (!loginState?.user) {
        let pyFile = pyFilesBuffer.get(filename)
        if (pyFile === undefined) {
          return FileOpStatus.FILE_NOT_FOUND
        }
        pyFilesBuffer.delete(filename)
        return FileOpStatus.OS_OP_SUCCESS
      }
      const f = [...(state.tree ?? [])].filter(node => node[1].name === filename)
      if (f?.length === 1) {
        const [uid] = f[0]
        try {
          simFsController.deleteTabCb(uid)
          await dispatch({ type: FileManagementActions.DELETE, uid: uid })
        } catch (err) {
          console.error(err)
          return FileOpStatus.UNKNOWN_FAIL
        }
        return FileOpStatus.OS_OP_SUCCESS
      } else if (f?.length > 1) {
        return FileOpStatus.MORE_THAN_ONE_FILE
      } else {
        return FileOpStatus.FILE_NOT_FOUND
      }
    }

    const readFile = async (filename) => {
      if (!loginState?.user) {
        let pyFile = pyFilesBuffer.get(filename)
        if (pyFile === undefined) {
          return [FileOpStatus.FILE_NOT_FOUND, []]
        }
        return [FileOpStatus.COMPLETE_FILE_READ, pyFile]
      }
      const f = [...(state.tree ?? [])].filter(node => node[1].name === filename)
      if (f?.length === 1) {
        const [uid] = f[0]
        let data = []
        try {
          data = await codeFileUseCases.getFile(uid)
        } catch (err) {
          console.error(err)
          return [FileOpStatus.UNKNOWN_FAIL, []]
        }
        const uint8Array = [...data].map(c => c.charCodeAt(0))
        return [FileOpStatus.COMPLETE_FILE_READ, uint8Array]
      } else if (f?.length > 1) {
        return [FileOpStatus.MORE_THAN_ONE_FILE, []]
      } else {
        return [FileOpStatus.FILE_NOT_FOUND, []]
      }
    }

    const writeFile = async (filename, contents) => {
      if (!loginState?.user) {
        if (isFileNameInvalid(filename)) {
          return FileOpStatus.INVALID_FILENAME
        }
        pyFilesBuffer.set(filename, contents)
        return FileOpStatus.WRITE_SUCCESS
      }
      const f = [...(state.tree ?? [])].filter(node => node[1].name === filename)
      if (f?.length === 1) {
        const [uid] = f[0]
        try {
          await dispatch({ type: FileManagementActions.SAVE_FILE, uid: uid, code: String.fromCharCode(...contents) })
        } catch (err) {
          console.error(err)
          return FileOpStatus.UNKNOWN_FAIL
        }

        simFsController.updateTabCb(uid)
        return FileOpStatus.WRITE_SUCCESS
      } else if (f?.length > 1) {
        return FileOpStatus.MORE_THAN_ONE_FILE
      } else {
        if (isFileNameInvalid(filename)) {
          return FileOpStatus.INVALID_FILENAME
        }
        try {
          await dispatch({
            type: FileManagementActions.NEW_FILE,
            parentFolder: state.root,
            name: filename,
            code: String.fromCharCode(...contents),
          })
        } catch (err) {
          console.error(err)
          return FileOpStatus.UNKNOWN_FAIL
        }
        return FileOpStatus.WRITE_SUCCESS
      }
    }

    const fileReturn = async (filename, operation) => {
      // console.log('return', filename, operation)
      if (!fileSystemReady) {
        return [FileOpStatus.FILESYSTEM_NOT_READY, []]
      }
      switch (operation) {
        case FileOperation.READ_BINARY:
          return await readFile(filename)
        case FileOperation.OS_STAT:
          return await getFileStat(filename)
        default:
          return [FileOpStatus.NOT_IMPLEMENTED, []]
      }
    }

    const fileAction = async (filename, operation, data) => {
      // console.log('action', filename, operation, data)
      if (!fileSystemReady) {
        return FileOpStatus.FILESYSTEM_NOT_READY
      }
      switch (operation) {
        case FileOperation.WRITE_BINARY:
          return await writeFile(filename, data)
        case FileOperation.OS_UNLINK:
          return await deleteFile(filename)
        case FileOperation.OS_RENAME:
          return await renameFile(filename, data)
        default:
          return FileOpStatus.NOT_IMPLEMENTED
      }
    }

    simFsController.setFsRequestCb(fileReturn)
    simFsController.setFsActionCb(fileAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileSystemReady, state, dispatch])

  fileManagementDispatch = dispatch
  return (
    <FileManagementContext.Provider value={{ state: { ...state, fileSystemReady, userProgressFetchFailed }, dispatch }}>
      {children}
    </FileManagementContext.Provider>
  )
}

export const useFileManagement = () => {
  const { state, dispatch } = React.useContext(FileManagementContext)
  if (state === undefined || dispatch === undefined) {
    throw new Error(
      'useFileManagement must be used within a child of FileManagementProvider'
    )
  }
  return [state, dispatch]
}