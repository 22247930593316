import React from 'react'
import { Button, Typography, IconButton } from '@material-ui/core'
import { useGroups, toggleGroupsDialogOpenState } from '../contexts/GroupsContext'
import { readGroupFromStore } from '../content-manager/groups/group-store'
import { useLogin } from '../contexts/LoginContext'
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons'
import { JoinClassDialog } from './JoinClassDialog'
import { FiriaGreenButton } from '../FiriaGreenButton'
import { loginHref } from '../LandingDialog'

function ClassesDialogHeader() {
  const [{ user }] = useLogin()
  const { selectedGroupId, takeSeatInProgress } = useGroups()
  const [joinClassDialogOpen, setJoinClassDialogOpen] = React.useState(false)
  const titleArr = ['My Classes']
  const selectedGroup = readGroupFromStore(selectedGroupId) ?? {}

  if (selectedGroupId) {
    titleArr.push(selectedGroup.groupName)
  }

  return (
    <>
      <div style={{
        padding: '10px 20px',
        display: 'flex',
        alignItems:'center',
        justifyContent: 'space-between',
        height: 60,
      }}>
        <div style={{display: 'flex', alignItems:'center', justifyContent: 'flex-start'}}>
          <Typography variant='subtitle1' color='textPrimary' style={{fontWeight: 500, fontSize: 24}}>
            {'My Classes'}
          </Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          {user ?

            <Button
              variant='outlined'
              disabled={!user || takeSeatInProgress}
              style={{height: 40}}
              startIcon={<AddIcon style={{fontSize: 24}}/>}
              onClick={() => setJoinClassDialogOpen(true)}>
              {'join class'}
            </Button>:
            <FiriaGreenButton
              href={loginHref}
            >
              {'Log In'}
            </FiriaGreenButton>}
          <div style={{width: 15}}></div>
          <IconButton onClick={toggleGroupsDialogOpenState} size='small' style={{width: 40, height: 40}}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <JoinClassDialog open={joinClassDialogOpen} handleClose={() => setJoinClassDialogOpen(false)}/>
    </>
  )
}

export { ClassesDialogHeader }