import React from 'react'
import Markdown from '../FiriaMarkdown'
import { makeStyles, useTheme, emphasize } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import RegionalDialog from '../RegionalDialog'
import Slide from '@material-ui/core/Slide'

import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import RemoveIcon from '@material-ui/icons/Close'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SearchIcon from '@material-ui/icons/Search'

import { useMission, MissionActions, LessonContentViews } from '../contexts/MissionContext'
import hexToRGBA from '../hexToRGBA'

import { userProgressController } from '../UserProgressControl'
import { hintCategory } from './GeneralHelpHints'
import { isTool, isToolMatched, getUnlockedTools, getAllTools, getTool } from './ToolboxUtil'
import { TextField } from '@material-ui/core'

const useStyles = makeStyles((theme) => {
  return ({
    toolboxPanel: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    panelHeader: {
      display: 'flex',
      marginBottom: '0.2em',
      justifyContent: 'space-between',
    },
    menuButton: {
      flex: 0,
    },
    noMargin: {
      margin: 0,
    },
    panelName: {
      marginLeft: '0.5em',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
    },
    collapseButton: {
      marginLeft: 'auto',
      width: 30,
      height: 30,
    },
    body: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      overflowY: 'auto',
      padding: '0px 7px 14px 7px',
      height: '100%',
      width: '100%',
    },
    panelFooter: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '.25em',
      justifyContent: 'space-between',
    },
    continueButton: {
      marginLeft: 'auto',
    },
    minimizeIcon: {
      fontSize: 17,
      transform: 'scale(-1)',
    },
    maximizeIcon: {
      fontSize: 18,
    },
    categoryTitle: {
      fontSize: '1.5em',
      textAlign: 'center',
      marginTop: '0.75em',
      marginBottom: '0.25em',
    },
    name: {
      flex: '0 0 14em',
      marginTop: 'auto',
      minWidth: '50px',
      width: '0',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: 'bold',
      transition: 'all 0.3s',
    },
    largeName: {
      flex: '1 0 100%',
      marginTop: 'auto',
      fontWeight: 'bold',
      fontSize: '2em',
      transition: 'all 0.3s',
    },
    desc: {
      flex: '1 1 0%',
      maxHeight: '32px',
      minWidth: '0',
      width: '0',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      opacity: '0.75',
      fontStyle: 'italic',
      transition: 'all 0.3s',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'stretch',
      width: '100%',
    },
    spacer: {
      display: 'flex',
      marginLeft: 'auto',
    },
    backdrop: {
      position: 'absolute',
      backdropFilter: 'blur(5px)',
      backgroundColor: 'rgba(0,0,30,0.01)',
    },
    maximizedPanel: {
      display: 'flex',
      zIndex: 100,
      left: '0%',
      right: '0%',
      top: '0%',
      height: '100%',
      maxWidth: '100%',
      minWidth: '100%',
      padding: theme.spacing(1),
      margin: 0,
    },
    minimizedPanel: {
      display: 'flex',
      zIndex: 100,
      top: '10%',
      left: '12%',
      right: '12%',
      minWidth: '76%',
      maxWidth: '76%',
      padding: theme.spacing(1),
      margin: '0px !important',
      overflow: 'hidden',
    },
    minimizedHeight: {
      maxHeight: '80%',
      minHeight: '10%',
    },
    maximizedHeight: {
      maxHeight: '100%',
      minHeight: '100%',
    },
    root: {
      height: '100%',
    },
    containerVisible: {
      position: 'fixed',
    },
    containerInvisible: {
      width: '100%',
    },
    paperScroll: {
      display: 'block !important',
      height: '100%',
      width: '100%',
    },
    breadcrumbContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      flex: '0 0 auto',
      transitionProperty: 'all',
      transitionDuration: '.33s',
    },
    breadcrumbs: {
      flexWrap: 'nowrap',
      fontSize: 'small',
    },
    breadcrumb: {
      padding: theme.spacing(.125),
    },
    selectedBreadcrumb: {
      backgroundColor: emphasize(theme.palette.background.paper),
      padding: theme.spacing(.125),
    },
    searchContainer: {
      display: 'flex',
      alignContent: 'flex-end',
      transitionProperty: 'all',
      transitionDuration: '.33s',
    },
  })
})

function ToolPanel(props) {
  const [missionState, missionDispatch] = useMission()
  const accordionElem = React.useRef(null)

  const handleToolboxAccordion = panel => async (_, expanded) => {
    // console.log('in handleToolboxAccordion with args', expanded, panel)
    props.setAccordionClick(true)
    await missionDispatch({ type: MissionActions.TOOL_SELECT, tool: expanded ? panel : '' })
  }

  const scrollToElement = (elem) => {
    if (!elem) {
      return
    }

    elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  }

  const getPanelId = (category, name) => {
    return 'toolbox-panel-' + category.replace(/\s/g, '-') + '-' + name.replace(/\s/g, '-')
  }

  const [unlockedTools, setUnlockedTools] = React.useState(getUnlockedTools())

  React.useEffect(() => {
    if (missionState.testMode) {
      setUnlockedTools(getAllTools())
    } else {
      setUnlockedTools(getUnlockedTools())
    }
  }, [missionState.testMode])

  const setIsSearching = props.setIsSearching

  React.useEffect(() => {
    if (missionState.ui.toolSelected !== '') {
      const obj = getTool(missionState.ui.toolSelected)
      if (!obj) { // in case of bad word stop it here
        console.log('That tool does not exist: ', missionState.ui.toolSelected)
        return
      }
      if (!unlockedTools.find(o => isToolMatched(o, missionState.ui.toolSelected))) {
        // Tool is newly owned. Add it to the list.
        setUnlockedTools(getUnlockedTools())
        // We want newly unlocked tools to not be filtered out by a search
        setIsSearching(false)
      }

      const panelId = getPanelId(obj.category, obj.name)
      const elem = document.getElementById(panelId)
      accordionElem.current = elem
    }
  }, [missionState.ui.toolSelected, setIsSearching, unlockedTools])

  React.useEffect(() => {
    if (accordionElem.current !== null && props.isVisible === 'entered') {
      scrollToElement(accordionElem.current)
      accordionElem.current = null
    }
  }, [props.isVisible])

  if (unlockedTools.length > 0) {
    const tools = unlockedTools.filter((tool) => {
      // Case insensitive, include plural form
      const target = new RegExp(props.searchText + 's?', 'i')
      // Include match strings, description, and name
      const corpus = tool.matches.join(' ') + ' ' + tool.desc + ' ' + tool.name
      return corpus.match(target) !== null
    })
    return (
      Object.entries(hintCategory).map(([, category]) => {
        // Return null if this category is empty
        if (Object.keys(tools).find(key => tools[key].category === category) === undefined) {
          return (null)
        }
        return (
          <div key={category}>
            <div
              id={`toolbox-title-${category.replace(/\s/g, '-')}`}
              className={props.classes.categoryTitle}
            >
              {category}
            </div>
            {tools.map((obj, i) => {
              if (category === obj.category) {
                let textDesc = null
                if (obj.desc) {
                  textDesc = obj.desc.replace(/\*/g, '')
                }
                const panelId = getPanelId(obj.category, obj.name)
                const isObjSelected = isToolMatched(obj, missionState.ui.toolSelected)
                return (
                  <Accordion
                    key={i}
                    id={panelId}
                    onChange={handleToolboxAccordion(obj.matches[0])}
                    expanded={isObjSelected}
                    TransitionProps={{
                      onEntered: () => {
                        if (accordionElem.current !== null) {
                          scrollToElement(accordionElem.current)
                          accordionElem.current = null
                        }
                      },
                      timeout: 150,
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      style={{ minHeight: 24 }}
                    >
                      <div className={props.classes.wrapper}>
                        {(!isObjSelected) ? (
                          <div className={props.classes.name}>{obj.name}</div>
                        ) : (
                          <div className={props.classes.largeName}>{obj.name}</div>
                        )}
                        {(textDesc && !isObjSelected) ? (
                          <div className={props.classes.desc} title={textDesc}>{textDesc}</div>
                        ) : (
                          <div title={textDesc}></div>
                        )}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {isObjSelected ? (
                          <div style={{ width: '100%' }} >
                            <Markdown>
                              {obj.desc ? `#### ${obj.desc}` : ''}
                            </Markdown>
                            {obj.hint}
                          </div>
                        ) : <></>}
                      </>
                    </AccordionDetails>
                  </Accordion>
                )
              } else {
                return null
              }
            })}
          </div>
        )
      }
      )
    )
  } else {
    return (
      <div>
        <Markdown>{`
## Your toolbox is empty!
___
Be on the lookout for ==new concepts== in the lessons. Click on one when you see it and it will be added here!
`}</Markdown>
      </div>
    )
  }
}

function PanelHeader(props) {
  const [missionState, missionDispatch] = useMission()

  function maximizeToolboxWindow() {
    missionDispatch({ type: MissionActions.TOOLBOX_MAX, isMax: !missionState.ui.toolboxMaximized })
  }

  return (
    <div className={props.classes.panelHeader}>
      <div>
        <BusinessCenterIcon />
        <div className={props.classes.panelName}>
          <p className={props.classes.noMargin}>
            Toolbox
          </p>
        </div>
      </div>
      <div className={props.classes.spacer}>
        <IconButton
          className={props.classes.collapseButton}
          onClick={() => (maximizeToolboxWindow(!props.maximizeWindow))}
        >
          {props.maximizeWindow ?
            <FilterNoneIcon className={props.classes.minimizeIcon} /> :
            <CheckBoxOutlineBlankIcon className={props.classes.maximizeIcon} />
          }
        </IconButton>
        <IconButton
          className={props.classes.collapseButton}
          onClick={props.onCollapseButton}
        >
          <RemoveIcon />
        </IconButton>
      </div>
    </div>
  )
}


function PanelFooter(props) {
  const [missionState] = useMission()
  const [recentTools, setRecentTools] = React.useState(userProgressController?.lastTool ? [missionState.userProgress.lastTool] : [])

  React.useEffect(() => {
    if (isTool(missionState.ui.toolSelected)) {
      if (!recentTools.includes(missionState.ui.toolSelected)) {
        if (!props.accordionClick) {
          setRecentTools([...recentTools, missionState.ui.toolSelected])
        } else {
          setRecentTools([missionState.ui.toolSelected])
          props.setAccordionClick(false)
        }
      }
    }
  }, [missionState.ui.toolSelected, props, recentTools])

  return (
    <div className={props.classes.panelFooter}>
      <div className={props.classes.breadcrumbContainer} style={props.isSearching ? { width: '0' } : { width: '90%' }}>
        {(recentTools.length > 1) &&
          <Breadcrumbs
            itemsBeforeCollapse={0} maxItems={5} itemsAfterCollapse={5}
            separator={<NavigateNextIcon fontSize='small' />}
            className={props.classes.breadcrumbs}
            aria-label='toolbox-breadcrumb'
          >
            {recentTools.map((tool) => {
              return (
                <div
                  key={tool}
                  className={tool === missionState.ui.toolSelected ? props.classes.selectedBreadcrumb : props.classes.breadcrumb}
                  sx={{ borderRadius: 12 }}
                >
                  <Markdown fontSize='small'>=={tool}==</Markdown>
                </div>
              )
            })}
          </Breadcrumbs>
        }
      </div>
      <div className={props.classes.searchContainer} style={props.isSearching ? { width: '100%' } : { marginLeft: 'auto' }}>
        {(userProgressController.userProgress.toolsFound.length > 0) &&
          <IconButton aria-label='toolbox search' onClick={() => props.setIsSearching(!props.isSearching)}>
            <SearchIcon />
          </IconButton>
        }
        {(props.isSearching) &&
          <TextField
            type='search'
            id='toolbox-search'
            size='small'
            onChange={event => props.setSearchText(event.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Escape') {
                props.setIsSearching(false)
              }
            }}
            style={{ width: '80%', paddingTop: '.75em' }}
            autoFocus />
        }
      </div>
    </div>
  )
}


function ToolboxPanel(props) {
  const classes = useStyles()
  const transparency = 0.95
  const theme = useTheme()
  const [missionState, missionDispatch] = useMission()
  const backgroundColor = hexToRGBA(theme.palette.background.paper, transparency)
  // This seems arbitrary, but it fixes the clipping issue that occurs when you click on an inline tool in lesson content,
  // and the mission bar momentarily drags into the sim panel
  const [isVisible, setIsVisible] = React.useState(false)
  const dialogOver3dPanel = missionState.ui.focusedContent === LessonContentViews.COMPLETION_MESSAGE || missionState.hintDialogOpen
  const [accordionClick, setAccordionClick] = React.useState(false)
  const [isSearching, setIsSearching] = React.useState(false)
  const [searchText, setSearchText] = React.useState('')

  function onToolboxCollapse() {
    missionDispatch({ type: MissionActions.TOOLBOX_SHOW, isOpen: false })
  }

  React.useEffect(() => {
    if (!isSearching) {
      setSearchText('')
    }
  }, [isSearching])

  return (
    <RegionalDialog
      containerId={dialogOver3dPanel ? '3d' : 'sim'}
      open={props.open}
      BackdropProps={{
        classes: { root: classes.backdrop, invisible: classes.backdrop },
        transitionDuration: 225,
      }}
      PaperProps={{
        square: true,
        classes: { root: props.maximizeWindow ? `${classes.maximizedPanel} ${classes.maximizedHeight}` : `${classes.minimizedPanel} ${classes.minimizedHeight}` },
        style: { backgroundColor: backgroundColor },
      }}
      TransitionComponent={Slide}
      TransitionProps={{
        direction: 'left',
        timeout: 225,
        mountOnEnter: true,
        unmountOnExit: true,
        onEntering: () => setIsVisible('entering'),
        onExited: () => {
          setIsVisible(false)
          setIsSearching(false)
        },
        onEntered: () => setIsVisible('entered'),
      }}
      maxWidth='lg'
      classes={{
        container: isVisible ? classes.containerInvisible : classes.containerVisible,
        scrollPaper: classes.paperScroll,
      }}
      onBackdropClick={onToolboxCollapse}
    >
      <div className={`${classes.toolboxPanel} ${props.maximizeWindow ? classes.maximizedHeight : classes.minimizedHeight}`}>
        <PanelHeader {...props} classes={classes} onCollapseButton={onToolboxCollapse} className={classes.panelHeader} />
        <Divider />
        <div className={classes.body}>
          <ToolPanel {...props} setAccordionClick={setAccordionClick} isSearching={isSearching} setIsSearching={setIsSearching} searchText={searchText} classes={classes} isVisible={isVisible} />
        </div>
        <Divider />
        <PanelFooter {...props}
          accordionClick={accordionClick} setAccordionClick={setAccordionClick} isSearching={isSearching} setIsSearching={setIsSearching} setSearchText={setSearchText}
          classes={classes} className={classes.panelFooter} />
      </div>
    </RegionalDialog>
  )
}

ToolboxPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  onCollapseButton: PropTypes.func.isRequired,
  onMenuButton: PropTypes.func,
  maximizeWindow: PropTypes.bool.isRequired,
  setMaximizeWindow: PropTypes.func.isRequired,
}

export default ToolboxPanel
