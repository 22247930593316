/* CodeBot Models
    3D models and physics for CodeBot CB2
    * Physics uses box and cylinder primitives, and motorized hinge joints
    * Scale 1:10

    TODO:
    * When rotation speed is high, change wheel model to one with blurred tread texture, to avoid
      stroboscopic effect due to fixed frame rates.

    * Sleep physics bodies when we're motionless (avoid jitter in closeup view)
      * Babylon wrapper for Ammo.js doesn't yet support physicsImposter.sleep()
      * Tried native: cbBody.physicsImpostor.physicsBody.setSleepingThresholds() to no avail...
      * See thread here: https://forum.babylonjs.com/t/setsleepingthresholds-not-working/12181
        * We could follow suit, and save/restore body positions manually in the 'registerBeforePhysicsStep' handler.
          * https://playground.babylonjs.com/#IMD13Q#5

    * Switch to async/await for loading assets
      * Display Firia Labs branded loading screen while loading!

*/

import * as BABYLON from '@babylonjs/core'
// import { localAxes } from '../utils/BabylonUtils'
import { Entity } from '../SimScene'
import '@babylonjs/loaders'
import CB2_body from '../assets/BotBody.glb'
import CB2_wheelAssy from '../assets/WheelAssy.glb'
import CB2_engineSound from '../assets/Engine1.wav'
import CB2_spkr440 from '../assets/Spkr440.wav'
import { disposePromise } from '../utils/BabylonUtils'
import stone_base from '../assets/materials/Stone_Tiles_004_basecolor_reduced.jpg'
import createPeripheral from './peripherals/PeripheralFactory'

// LED dimensions
const LED_WIDTH = 2
const LED_LENGTH = 1.4
const LED_HEIGHT = 1

// Proximity LED Specs
const PROX_Y_DELTA = 5      // Distance of sensor center to top of PCB (mm)
const PROX_Z_DELTA = 2      // Distance of sensor center to top of PCB (mm)
const PROX_ELEV_ANGLE = 20  // Elevation angle to parallel ground plane (degrees)
const PROX_RANGE_MAX = 5.0  // Maximum proximity sensor range (decimeters)

// LED position/color defs. Referenced to center of body in mm.
const userLedColor = new BABYLON.Color3(0.5, 0, 0)
const userLeds = [
  [new BABYLON.Vector2(18.9, -2.3), userLedColor],  // 0
  [new BABYLON.Vector2(13.8, -2.3), userLedColor],  // 1
  [new BABYLON.Vector2(8.7, -2.3), userLedColor],   // 2
  [new BABYLON.Vector2(3.6, -2.3), userLedColor],   // 3
  [new BABYLON.Vector2(-3.6, -2.3), userLedColor],  // 4
  [new BABYLON.Vector2(-8.7, -2.3), userLedColor],  // 5
  [new BABYLON.Vector2(-13.8, -2.3), userLedColor], // 6
  [new BABYLON.Vector2(-18.9, -2.3), userLedColor], // 7
]
const lsLedColor = new BABYLON.Color3(0, 0.5, 0)
const lsLeds = [
  [new BABYLON.Vector2(19.1, -61.0), lsLedColor],   // 0
  [new BABYLON.Vector2(9.7, -61.0), lsLedColor],     // 1
  [new BABYLON.Vector2(0, -61.0), lsLedColor],       // 2
  [new BABYLON.Vector2(-9.7, -61.0), lsLedColor],    // 3
  [new BABYLON.Vector2(-19.1, -61.0), lsLedColor],  // 4
]
const proxLedColor = new BABYLON.Color3(0.659, 0.498, 0.196)
const proxLeds = [
  [new BABYLON.Vector2(35.8, -59.6), proxLedColor],   // 0
  [new BABYLON.Vector2(-35.8, -59.6), proxLedColor],  // 1
]
const pwrLed = [new BABYLON.Vector3(33.3, 38.6), userLedColor]
const usbLed = [new BABYLON.Vector3(37.4, -38.5), lsLedColor]

class CodeBotCB2Model extends Entity {
  constructor(player_num) {
    super()

    // Debug controls
    this.impostersVisible = false
    this.doPhysics = true

    // Identification
    this.deviceType = 'CodeBot'
    this.player_num = player_num
    this.uniqueId = `${this.deviceType}_${this.player_num}`

    this.loadedObservable = new Set()   // add/delete callbacks here: observer(isLoaded)

    // CodeBot dimensions. Divide by 100 to get proper scaling (1:10 in meters)
    this.CB_WIDTH_MM = 80
    this.CB_LENGTH_MM = 127
    this.CB_HEIGHT_MM = 1.6
    this.WHEEL_DIA_MM = 65
    this.WHEEL_WIDTH_MM = 25
    this.WHEEL_X_OFS_MM = 28   // distance from body-rear to axle-center
    this.WHEEL_Y_OFS_MM = 3    // distance from body outer edge to wheel cylinder inner edge
    this.WHEEL_Z_OFS_MM = 12   // distance below body center to axle-center
    this.BATT_WIDTH_MM = 58
    this.BATT_LENGTH_MM = 63
    this.BATT_HEIGHT_MM = 16
    this.BATT_X_OFS_MM = 18    // distance from body-front to batt-front
    this.MOTOR_WIDTH_MM = 20
    this.MOTOR_LENGTH_MM = 65
    this.MOTOR_HEIGHT_MM = 20
    this.MOTOR_X_OFS_MM = 31   // distance from body-center to motor-center
    this.MOTOR_Y_OFS_MM = 12   // distance from body-center to motor-center
    this.MOTOR_Z_OFS_MM = 55   // distance from body-center to motor-center

    this.BODY_CENTER_Y = (this.WHEEL_DIA_MM / 2 + this.WHEEL_Z_OFS_MM) / 100

    // Peripheral mounting points, keyed by 'name' of peripheral (defaults to class name)
    this.PERIPH_MOUNT = {
      Breadboard: {
        loc: new BABYLON.Vector3(0, this.BODY_CENTER_Y - 0.34, -0.4754),
        rot: new BABYLON.Vector3(0, Math.PI, 0),
      },
      SevenSegment: {
        loc: new BABYLON.Vector3(0, this.BODY_CENTER_Y - 0.23, -0.40),
        rot: new BABYLON.Vector3(0, 0, 0),
      },
      JumperWire: {
        loc: new BABYLON.Vector3(0, this.BODY_CENTER_Y - 0.34, -0.4754),
        rot: new BABYLON.Vector3(0, Math.PI, 0),
      },
      JumperWireSet4: {
        loc: new BABYLON.Vector3(0, this.BODY_CENTER_Y - 0.34, -0.4754),
        rot: new BABYLON.Vector3(0, Math.PI, 0),
      },
      Neopixel16: {
        loc: new BABYLON.Vector3(0, 0.27, -0.280),
        rot: new BABYLON.Vector3(0, -Math.PI / 2, -20 * Math.PI / 180),
      },
    }

    // Physics params
    this.BODY_MASS = 40
    this.WHEEL_MASS = 5
    this.SKID_FRICTION = 0.01
    this.WHEEL_FRICTION = 50.0
    this.MOTOR_NOM_TORQUE = 1.1
    this.MOTOR_MAX_RPM = 300     // Measure this using encoder test!

    // Center of Gravity
    // Relative offset of all rigid bodies from the center of cbBody, which we historically defined as the center of the PCB.
    this.cog = new BABYLON.Vector3(0, 0, 0.15)  // Move COG a little forward from center of PCB. Batteries are heavy!

    // Convert to angular velocity (rad/s)
    // this.motorMaxAngularVelocity = this.MOTOR_MAX_RPM * 60 * 2 * Math.PI
    this.motorMaxAngularVelocity = 15  // Experimental value. Physics engine doesn't appear to handle real units...

    this.leftAxleJoint = null
    this.rightAxleJoint = null

    this.cbBody = null
    this.leftWheel = null
    this.rightWheel = null
    this.leftMotor = null
    this.rightMotor = null

    this.initialPosition = new BABYLON.Vector3(0, 0, 0)
    this.initialRotation = Math.PI / 2

    // LED meshes
    this.userLeds = []
    this.lsLeds = []
    this.proxLeds = []
    this.pwrLed = null
    this.usbLed = null

    // Peripherals
    this.peripherals = []

    // Sounds
    this.useSpatialSound = true  // May need ability to turn 3D sound off for low-performance environments
    this.engineSound = null
    this.speakerOscillator = null
    this.speakerIsPlaying = false
    this.ENGINE_VOLUME = 8

    this.shadowCasters = []
    this.shadowModelChanged = false

    this.isLoaded = false
  }

  notifyLoadedObservers = (scene) => {
    // Notify Observers when model is loaded / unloaded
    this.loadedObservable.forEach(observer => observer(scene))
  }

  getRootMesh = () => {
    return this.cbBody
  }

  getShadowCasters = () => {
    return this.shadowCasters

    // Lo-fi imposter shadows only
    // return [this.cbBody, this.leftWheel, this.rightWheel]
  }

  hasShadowModelChanged = () => {
    const hasChanged = this.shadowModelChanged
    this.shadowModelChanged = false
    return hasChanged
  }

  unload = async () => {
    const disposeList = []

    // Remove from scene and cleanup memory
    if (this.cbBody) {
      disposeList.push(this.cbBody)
    }
    if (this.leftWheel) {
      disposeList.push(this.leftWheel)
    }
    if (this.rightWheel) {
      disposeList.push(this.rightWheel)
    }
    if (this.caster) {
      disposeList.push(this.caster)
    }
    this.cbBody = null
    this.leftWheel = null
    this.rightWheel = null
    this.caster = null
    this.leftAxleJoint = null
    this.rightAxleJoint = null
    this.initialPosition = new BABYLON.Vector3(0, 0, 0)

    // Dispose of sounds
    if (this.speakerOscillator) {
      this.speakerOscillator = null
    }
    if (this.speakerSound) {
      this.speakerSound.stop()
      disposeList.push(this.speakerSound)
      // this.speakerSound.dispose()
      this.speakerSound = null
    }
    if (this.engineSound) {
      this.engineSound.stop()
      disposeList.push(this.engineSound)
      // this.engineSound.dispose()
      this.engineSound = null
    }
    this.speakerIsPlaying = false

    const periphUnloads = this.peripherals.map(p => p.unload())

    this.shadowCasters = []
    this.shadowModelChanged = false

    await Promise.all([...disposeList.map(disposePromise), ...periphUnloads])

    this.isLoaded = false
    this.notifyLoadedObservers(false)
  }

  load = async (scene) => {
    // Create a new mesh to be our root for the physics-enabled "compound" object
    this.cbBody = new BABYLON.Mesh('CodeBot', scene)
    this.cbBody.position.y = this.BODY_CENTER_Y     // Historical position is center of PCB
    this.cbBody.position.addInPlace(this.initialPosition)  // Offset by environment-specified position
    this.cbBody.rotation.x = -Math.PI / 8  // Preset to normal PCB slope when resting on skid.
    this.cbBody.rotation.y = this.initialRotation

    // Note:
    //   We are constructing a compound physics object with the root (empty) mesh being cbBody.
    //   All the other rigid bodies (shaped impostors) are parented to this mesh, and the root is the only
    //   impostor (type "NoImpostor") whose mass and friction have effect.
    //   By consequence, the center of gravity (COG) of the compound CodeBot is determined solely by the position
    //   of this invisible root mesh (cbBody) _relative_ to the parented rigid bodies.
    //   To clarify, the cbBody.position does not matter. We are only adjusting it above to allow environment params to place
    //   the compound CodeBot object independent of COG location, referencing the historical position at center of PCB.

    // Create Physics impostors
    const promises = []
    let cbPcb
    let cbBatt
    promises.push(new Promise((resolve) => {
      cbPcb = BABYLON.MeshBuilder.CreateBox('cbPcb', { width: this.CB_WIDTH_MM / 100, height: this.CB_HEIGHT_MM / 100, depth: this.CB_LENGTH_MM / 100 }, scene)
      cbPcb.position.addInPlace(this.cog)
      cbPcb.parent = this.cbBody
      cbPcb.isVisible = this.impostersVisible

      cbBatt = BABYLON.MeshBuilder.CreateBox('cbBattPack', { width: this.BATT_WIDTH_MM / 100, height: this.BATT_HEIGHT_MM / 100, depth: this.BATT_LENGTH_MM / 100 }, scene)
      cbBatt.parent = this.cbBody
      cbBatt.position.y = (-this.BATT_HEIGHT_MM / 2 - this.CB_HEIGHT_MM / 2) / 100
      cbBatt.position.z = -(this.CB_LENGTH_MM / 2 - this.BATT_LENGTH_MM / 2 - this.BATT_X_OFS_MM) / 100
      cbBatt.position.addInPlace(this.cog)
      cbBatt.isVisible = this.impostersVisible
      resolve()
    }))

    const motor_x_offset = this.MOTOR_X_OFS_MM / 100
    promises.push(new Promise((resolve) => {
      this.leftMotor = this.createMotor(-motor_x_offset, scene)
      resolve()
    }))
    promises.push(new Promise((resolve) => {
      this.rightMotor = this.createMotor(+motor_x_offset, scene)
      resolve()
    }))

    const wheel_x_offset = (this.CB_WIDTH_MM / 2 + this.WHEEL_WIDTH_MM / 2 + this.WHEEL_Y_OFS_MM) / 100
    promises.push(new Promise((resolve) => {
      this.leftWheel = this.createWheel(+wheel_x_offset, scene)
      resolve()
    }))
    promises.push(new Promise((resolve) => {
      this.rightWheel = this.createWheel(-wheel_x_offset, scene)
      resolve()
    }))

    await Promise.all(promises)

    // Enable collision checks for Camera
    this.cbBody.checkCollisions = true
    this.leftWheel.checkCollisions = true
    this.rightWheel.checkCollisions = true
    this.leftMotor.checkCollisions = true
    this.rightMotor.checkCollisions = true

    // Next set of promises - load peripherals (some have physics, so must do this before root compound impostor is created)
    promises.length = 0
    this.peripherals = this.peripheralLoad ? this.peripheralLoad.reduce((pArray, p) => {
      const newPeriph = createPeripheral(p.type, p.params)
      // Gracefully ignore unknown peripherals
      if (newPeriph) {
        pArray.push(newPeriph)
      }
      return pArray
    }, []) : []

    for (let i = 0; i < this.peripherals.length; i++) {
      const periph = this.peripherals[i]
      if (periph) {
        let loc = null, rot = null
        const mountPoint = this.PERIPH_MOUNT[periph.name()]
        if (mountPoint) {
          // console.log(`mount for "${this.peripherals[i].name()} is loc=${mountPoint.loc.toString()}, rot=${mountPoint.rot.toString()}`)
          loc = mountPoint.loc.clone()
          rot = mountPoint.rot
          loc.addInPlace(this.cog)
        }
        promises.push(periph.load(scene, this.cbBody, loc, rot))
      }
    }

    // Load GLB visual model components
    promises.push(this.loadModel(scene))

    await Promise.all(promises)

    if (this.doPhysics) {
      cbBatt.physicsImpostor = new BABYLON.PhysicsImpostor(cbBatt, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: this.SKID_FRICTION })
      cbPcb.physicsImpostor = new BABYLON.PhysicsImpostor(cbPcb, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: this.SKID_FRICTION })

      // Must create root "compound" Impostor object LAST. (Note: it has the only friction and mass that are actually used by the Ammo.js physics engine)
      this.cbBody.physicsImpostor = new BABYLON.PhysicsImpostor(this.cbBody, BABYLON.PhysicsImpostor.NoImpostor, { mass: this.BODY_MASS, friction: this.SKID_FRICTION }, scene)

      this.leftAxleJoint = this.createAxle(wheel_x_offset, this.cbBody, this.leftWheel)
      this.rightAxleJoint = this.createAxle(-wheel_x_offset, this.cbBody, this.rightWheel)

      // Set sleeping thresholds so we don't jitter at low speeds (not yet working!)
      // this.leftWheel.physicsImpostor.physicsBody.setSleepingThresholds(0.1,0.1)
      // this.rightWheel.physicsImpostor.physicsBody.setSleepingThresholds(0.1,0.1)
      // cbBody.physicsImpostor.physicsBody.setSleepingThresholds(0.1,0.1)

      // Enable motors!
      this.leftAxleJoint.setMotor(0, this.NOM_TORQUE)
      this.rightAxleJoint.setMotor(0, this.NOM_TORQUE)

      // this.caster = this.createCaster(this.cbBody)

      this.targetVelocityL = this.targetVelocityR = 0
    }

    this.loadSounds(scene)

    this.isLoaded = true
    this.notifyLoadedObservers(scene)
  }

  buildProceduralMeshes = async () => {
    // LEDs and such
    // Create LEDs
    this.userLeds = await Promise.all(userLeds.map(led => new Promise((resolve) => {
      resolve(this.createLed(led[0], led[1]))
    })))
    this.lsLeds = await Promise.all(lsLeds.map(led => new Promise((resolve) => {
      resolve(this.createLed(led[0], led[1]))
    })))
    this.proxLeds = await Promise.all(proxLeds.map(led => new Promise((resolve) => {
      resolve(this.createLed(led[0], led[1]))
    })))
    this.pwrLed = this.createLed(pwrLed[0], pwrLed[1])
    this.usbLed = this.createLed(usbLed[0], usbLed[1])
  }

  assetPath = asset => (
    // Extract file path/name from Webpack static media location
    [asset.substring(0, asset.lastIndexOf('/') + 1), asset.substring(asset.lastIndexOf('/') + 1)]
  )

  loadModel = async (scene) => {
    const loadBody = async () => {
      const [path, name] = this.assetPath(CB2_body)
      const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
      const bodyMesh = meshes[0]  // root transform
      // localAxes(1, scene).parent = bodyMesh
      bodyMesh.rotation = new BABYLON.Vector3(0, 0, 0)
      bodyMesh.parent = this.cbBody
      bodyMesh.position.addInPlace(this.cog)
      this.shadowCasters.push(bodyMesh)
      this.shadowModelChanged = true

      // Traverse all meshes in the CodeBot body
      const childMeshes = bodyMesh.getChildMeshes()
      await Promise.all(childMeshes.map(mesh => new Promise((resolve) => {
        // Add tags identifiying the meshes, useful for raycast tests, collisions...
        BABYLON.Tags.AddTagsTo(mesh, this.deviceType + ' ' + this.uniqueId)

        // When pointer hovers over buttons change cursor to a hand and highlight with green glow
        if (mesh.name === 'Cylinder.004' || mesh.name === 'Cylinder.006' || mesh.name === 'Cylinder.002') {
          mesh.isPickable = true
          mesh.actionManager = new BABYLON.ActionManager(scene)

          // Create an inner glow highlight layer
          const hl = new BABYLON.HighlightLayer('hl1', scene, {
            innerGlow: true,
          })

          // On pointer over mesh (mouse enter) and add green glow
          mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
            scene.hoverCursor = 'pointer'
            hl.addMesh(mesh, BABYLON.Color3.Green())
          }))

          // On pointer not over mesh (mouse exit) and remove green glow
          mesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
            hl.removeMesh(mesh, BABYLON.Color3.Green())
          }))
        }
        resolve()
      })))
    }
    const loadWheels = async () => {
      const [path, name] = this.assetPath(CB2_wheelAssy)

      const { meshes } = await BABYLON.SceneLoader.ImportMeshAsync('', path, name, scene)
      const leftWheelMesh = meshes[0]  // root transform
      const rightWheelMesh = leftWheelMesh.instantiateHierarchy(null)  // New instance, cloned!
      leftWheelMesh.rotation = new BABYLON.Vector3(0, 0, Math.PI / 2)
      leftWheelMesh.parent = this.leftWheel
      rightWheelMesh.rotation = new BABYLON.Vector3(0, 0, -Math.PI / 2)
      rightWheelMesh.parent = this.rightWheel

      this.shadowCasters.push(leftWheelMesh)
      this.shadowCasters.push(rightWheelMesh)
      this.shadowModelChanged = true
    }
    await Promise.all([loadBody(), loadWheels()])
    await this.buildProceduralMeshes()
  }

  createWheel = (x_offset, scene) => {
    let wheel = BABYLON.MeshBuilder.CreateCylinder('cbWheel', { height: this.WHEEL_WIDTH_MM / 100, diameter: this.WHEEL_DIA_MM / 100, tessellation: 16 }, scene)
    // localAxes(1, scene).parent = wheel
    wheel.isVisible = this.impostersVisible
    wheel.material = new BABYLON.StandardMaterial('', scene)
    wheel.material.diffuseColor = new BABYLON.Color3(0, 1, 0)

    // Ensure that wheels are at correct starting location for physics joints to connect without "whiplash" effect.
    // Wheels will have independent (not parented) physics impostors, but they need to start right with the cbBody.
    wheel.rotation.x = -Math.PI / 2
    wheel.rotation.z = -Math.PI / 2
    const wheelPosition = new BABYLON.Vector3(
      x_offset,
      -this.WHEEL_Z_OFS_MM / 100,
      (this.CB_LENGTH_MM / 2 - this.WHEEL_X_OFS_MM) / 100
    )
    wheel.position = wheelPosition  // Local offset.
    wheel.position.addInPlace(this.cog)
    wheel.parent = this.cbBody      // Temporarily set parent to get transform.
    wheel.setParent(null)           // Merge parent transform into wheel, since Physics doesn't do parents.

    if (this.doPhysics) {
      wheel.physicsImpostor = new BABYLON.PhysicsImpostor(wheel, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: this.WHEEL_MASS, friction: this.WHEEL_FRICTION })
    }

    return wheel
  }

  createMotor = (x_offset, scene) => {
    let motor = BABYLON.MeshBuilder.CreateBox('cbMotor', { width: this.MOTOR_WIDTH_MM / 100, height: this.MOTOR_HEIGHT_MM / 100, depth: this.MOTOR_LENGTH_MM / 100 }, scene)
    motor.isVisible = this.impostersVisible
    motor.material = new BABYLON.StandardMaterial('', scene)
    motor.material.diffuseColor = new BABYLON.Color3(0, 1, 0)

    if (this.doPhysics) {
      motor.physicsImpostor = new BABYLON.PhysicsImpostor(motor, BABYLON.PhysicsImpostor.BoxImpostor, { mass: 0, friction: this.SKID_FRICTION })
    }

    const motorPosition = new BABYLON.Vector3(
      x_offset, // this.MOTOR_X_OFS_MM / 100
      -this.MOTOR_Y_OFS_MM / 100,
      this.MOTOR_Z_OFS_MM / 100
    )
    motorPosition.addInPlace(this.cog)
    motor.position = motorPosition
    motor.parent = this.cbBody

    return motor
  }

  createCaster = (body) => {
    // Attach a caster to the front of the bot, for truer skidding
    // Note: This was an experimental attempt to get the bot to drive straighter.
    //       Tried a ball rotating in one direction, a cylinder (wheel), and a ball freely rotating (ball-and-socket).
    //       None of these provided straighter rolling than the original skid approach.

    // Ball caster
    // const caster = BABYLON.MeshBuilder.CreateSphere('caster', {diameter:0.3})

    // Wheel caster
    const caster = BABYLON.MeshBuilder.CreateCylinder('caster', { diameter: 0.3, height: 0.1 })

    caster.rotation.x = Math.PI / 2
    caster.rotation.z = Math.PI / 2
    caster.position = body.position.clone()
    caster.position.z -= 0.68

    // Apply material to visualize rotation of caster
    const mat = new BABYLON.StandardMaterial('casterMat')
    mat.diffuseTexture = new BABYLON.Texture(stone_base)
    mat.diffuseTexture.uScale = 2
    mat.diffuseTexture.vScale = 2
    caster.material = mat

    caster.physicsImpostor = new BABYLON.PhysicsImpostor(caster, BABYLON.PhysicsImpostor.CylinderImpostor, { mass: this.WHEEL_MASS / 2, friction: this.WHEEL_FRICTION })
    // caster.physicsImpostor = new BABYLON.PhysicsImpostor(caster, BABYLON.PhysicsImpostor.SphereImpostor, { mass: this.WHEEL_MASS / 2, friction: this.SKID_FRICTION})

    // const casterJoint = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.BallAndSocketJoint, {
    const casterJoint = new BABYLON.PhysicsJoint(BABYLON.PhysicsJoint.HingeJoint, {
      mainPivot: new BABYLON.Vector3(0, 0, -0.68),   // Front and center
      connectedPivot: new BABYLON.Vector3(0, 0, 0), // Center of caster
      mainAxis: new BABYLON.Vector3(1, 0, 0),       // Axle of CodeBot body is X-axis
      connectedAxis: new BABYLON.Vector3(0, 1, 0),  // Cylinder
      // connectedAxis: new BABYLON.Vector3(1, 0, 0),  // Sphere
    })
    body.physicsImpostor.addJoint(caster.physicsImpostor, casterJoint)
    return caster
  }

  createAxle = (x_offset, body, wheel) => {
    const botAxlePos = new BABYLON.Vector3(
      x_offset,
      -this.WHEEL_Z_OFS_MM / 100,
      (this.CB_LENGTH_MM / 2 - this.WHEEL_X_OFS_MM) / 100
    )
    botAxlePos.addInPlace(this.cog)

    const axleJoint = new BABYLON.MotorEnabledJoint(BABYLON.PhysicsJoint.HingeJoint, {
      mainPivot: botAxlePos,
      connectedPivot: new BABYLON.Vector3(0, 0, 0), // Center of wheel
      mainAxis: new BABYLON.Vector3(1, 0, 0),       // Axle of CodeBot body is X-axis
      connectedAxis: new BABYLON.Vector3(0, 1, 0),  // wheel cylinder rotates about its Y-axis (locally it sits like a tuna can)
      nativeParams: {
      },
    })
    body.physicsImpostor.addJoint(wheel.physicsImpostor, axleJoint)
    return axleJoint
  }

  createLed = (positionVec2, colorVec3, scene) => {
    const led = BABYLON.MeshBuilder.CreateBox('LED', { width: LED_WIDTH / 100, height: LED_HEIGHT / 100, depth: LED_LENGTH / 100 }, scene)
    led.material = new BABYLON.StandardMaterial('', scene)
    led.material.diffuseColor = new BABYLON.Color3(0.956, 0.921, 0.721)
    led.material.ambientColor = led.material.diffuseColor
    led.material.alpha = 0.8
    led.FL_onColor = colorVec3
    led.FL_offColor = new BABYLON.Color3(0, 0, 0)
    led.material.emissiveColor = led.FL_offColor
    led.material.specularColor = new BABYLON.Color3(0, 0, 0)
    led.parent = this.cbBody

    led.position.x = positionVec2.x / 100
    led.position.y = (LED_HEIGHT / 2) / 100
    led.position.z = positionVec2.y / 100
    led.position.addInPlace(this.cog)

    return led
  }

  ledPower = (led, isOn) => {
    // led.material.EmissiveTextureEnabled = isOn
    led.material.emissiveColor = isOn ? led.FL_onColor : led.FL_offColor
  }

  getLineSensorRay = (num) => {
    // Create ray from sensor downward
    let origin = this.lsLeds[num].position.clone()   // LEDs are just above sensor

    // Move origin just beneath PCB
    origin.y -= (LED_HEIGHT + this.CB_HEIGHT_MM + 2) / 100

    // Transform LED origin to match cbBody orientation
    origin = BABYLON.Vector3.TransformCoordinates(origin, this.cbBody.getWorldMatrix())
    const down = new BABYLON.Vector3(0, -1, 0)

    // Test: Always use "world" down vector, regardless of cbBody orientation
    // const direction = down

    // Rotate down vector based on cbBody orientation
    const rotationmatrix = new BABYLON.Matrix()
    BABYLON.Matrix.FromQuaternionToRef(this.cbBody.rotationQuaternion, rotationmatrix)
    const direction = BABYLON.Vector3.TransformNormal(down, rotationmatrix)

    const length = 0.35  // LS range is about 3.5 centimeters
    return new BABYLON.Ray(origin, direction, length)
  }

  getProximitySensorRay = (num) => {
    // Create ray from sensor forward
    let origin = this.proxLeds[num].position.clone()

    // Move origin above PCB by Y delta to center of proximity center
    // and move forward by Z delta to clear sensor housing
    origin.y += PROX_Y_DELTA / 100
    origin.z -= PROX_Z_DELTA / 100

    // Transform LED origin to match cbBody orientation
    origin = BABYLON.Vector3.TransformCoordinates(origin, this.cbBody.getWorldMatrix())

    // Angle to raise sensor ray to point straight forward (~20 degrees)
    const elevationAngle = Math.cos(PROX_ELEV_ANGLE)
    const forward = new BABYLON.Vector3(0, elevationAngle, -1)

    // Rotate forward vector based on cbBody orientation
    let rotationmatrix = new BABYLON.Matrix()
    BABYLON.Matrix.FromQuaternionToRef(this.cbBody.rotationQuaternion, rotationmatrix)
    const direction = BABYLON.Vector3.TransformNormal(forward, rotationmatrix)

    // Return calculated proximity sensor ray
    return new BABYLON.Ray(origin, direction, PROX_RANGE_MAX)
  }

  getBodyRotation = () => {
    return this.cbBody.rotationQuaternion.toEulerAngles()
  }

  getWheel = (num) => {
    return num === 0 ? this.leftWheel : this.rightWheel
  }

  getWheelRotationAngle = (num) => {
    // Return rotation around x-axis in radians
    // Note: This is not accurate to get the amount the wheel has rotated on its axle.
    //       That information is not preserved in the quaternion. Only a rotation axis and angle to achieve
    //       the current rotation is held; the way we actually got there is not.
    const eu = this.getWheel(num)?.rotationQuaternion.toEulerAngles()
    let rot = eu.x
    if (eu.y > 0) {
      rot = Math.PI - rot
    } else if (rot < 0) {
      rot += 2 * Math.PI
    }
    return rot
  }

  getWheelAngularVelocity = (num) => {
    // Return scalar magnitude of angular velocity in radians/sec

    // TODO: Derive direction (sign) of angular velocity from quaternion rather than motor direction.
    let sign = num ? Math.sign(this.targetVelocityR) : Math.sign(this.targetVelocityL)
    if (sign === 0) {
      sign = +1
    }

    // Use magnitude of velocity vector
    return sign * this.getWheel(num)?.physicsImpostor.getAngularVelocity().length()
  }

  set3dSounds = (value) => {
    this.useSpatialSound = value
    if (this.useSpatialSound) {
      this.ENGINE_VOLUME = 8
    } else {
      this.ENGINE_VOLUME = 2
    }
  }

  loadSounds = (scene) => {
    // Load model's sounds now if Web Audio has been "unlocked" by user gesture. Otherwise defer until then.

    // See following for distance model details:
    // https://developer.mozilla.org/en-US/docs/Web/API/PannerNode/distanceModel

    const doLoadSounds = () => {
      this.engineSound = new BABYLON.Sound('engine', CB2_engineSound, scene,
        () => {
          if (this.engineSoundIsPlaying) {
            this.engineSound.stop()
            this.engineSound.play()
          }
        },
        {
          loop: true,
          spatialSound: this.useSpatialSound,
          distanceModel: 'exponential',  // volume: pow((Math.max(distance, refDistance) / refDistance, -rolloffFactor)
          rolloffFactor: 0.7,
          refDistance: 1,
          // maxDistance: 15,   // Used by linear distanceModel only (see below)
          // distanceModel: "linear",    // volume: 1 - rolloffFactor * (distance - refDistance) / (maxDistance - refDistance)
        })
      this.engineSound.setVolume(this.ENGINE_VOLUME)  // Overridden when motor speeds are set!
      if (this.useSpatialSound) {
        this.engineSound.attachToMesh(this.cbBody)
      }

      // Note: Implemented 2 options for speaker. Both seem to work equivalently well.
      //   1) Use Web API oscillator: See https://developer.mozilla.org/en-US/docs/Web/API/OscillatorNode
      //   2) Use a WAV file sample sound.
      // The second approach is pretty much required as a baseline anyway since we want to use Babylon's attachToMesh()
      // capability for positional sound. Below we provide the option to connect a pure oscillator and connect it
      // to the speakerSound's _inputAudioNode so it can drive the sound rather than the WAV file, but still take advantage
      // of the postional sound capabilities.
      //
      // TODO: Can we get a more faithful speaker sample by WAV or by adding harmonic oscillators? Probably have spent WAY too much
      //       time on this already, so another day...
      const USE_OSCILLATOR = false
      if (USE_OSCILLATOR) {
        const audioCtx = BABYLON.Engine.audioEngine.audioContext
        this.speakerOscillator = audioCtx.createOscillator()
        this.speakerOscillator.type = 'square'
        this.speakerOscillator.frequency.value = 440
        this.speakerOscillator.start()
      }

      this.speakerSound = new BABYLON.Sound('speaker', CB2_spkr440, scene, null,
        {
          loop: true,
          spatialSound: this.useSpatialSound,
          distanceModel: 'exponential',
          rolloffFactor: 0.7,
          refDistance: 1,
        })
      this.speakerSound = new BABYLON.Sound('speaker', CB2_spkr440, scene,
        async () => {
          if (this.speakerIsPlaying) {
            this.speakerSound.stop()
            this.speakerSound.play()
          }
        },
        {
          loop: true,
          spatialSound: this.useSpatialSound,
          distanceModel: 'exponential',
          rolloffFactor: 0.7,
          refDistance: 1,
        })
      if (this.useSpatialSound) {
        this.speakerSound.setVolume(0.04)
        this.speakerSound.attachToMesh(this.cbBody)
      } else {
        this.speakerSound.setVolume(0.01)
      }
    }

    doLoadSounds()
    // Check whether to load now or later
    // if (BABYLON.Engine.audioEngine.unlocked) {
    //   doLoadSounds()
    // } else {
    //   BABYLON.Engine.audioEngine.onAudioUnlockedObservable.add(doLoadSounds)
    // }
  }

  calcPercentRange = (metric, min, max) => {
    return ((max - min) * metric / 100 + min) / 100
  }

  setMotors = (powerL, powerR) => {
    // Control motor/sound given +-100% power range
    this.targetVelocityL = (powerL / 100) * this.motorMaxAngularVelocity
    this.targetVelocityR = (powerR / 100) * this.motorMaxAngularVelocity

    // Increase torque linearly with power applied to motor
    const torqueL = this.calcPercentRange(Math.abs(powerL), 100, 180) * this.MOTOR_NOM_TORQUE
    const torqueR = this.calcPercentRange(Math.abs(powerR), 100, 180) * this.MOTOR_NOM_TORQUE

    this.leftAxleJoint.setMotor(this.targetVelocityL, torqueL)
    this.rightAxleJoint.setMotor(this.targetVelocityR, torqueR)

    // Adjust sound
    // TODO: Should be adjusting gear-motor sound based on physics update (actual angular velocity) rather than here.
    if (this.engineSound) {
      const speed = Math.max(Math.abs(powerL), Math.abs(powerR))
      if (speed) {
        if (!this.engineSound.isPlaying) {
          this.engineSound.spatialSound = this.useSpatialSound  // Address panner.setPosition() performance issue
          this.engineSound.play()
          this.engineSoundIsPlaying = true
        }
        // const volumeFactor = 0.009 * speed + 0.1  // 10% to 100% volume
        const volumeFactor = this.calcPercentRange(speed, 70, 130)
        this.engineSound.setVolume(volumeFactor * this.ENGINE_VOLUME)

        // const pitchFactor = 0.0025 * speed + 0.5  // 50% to 100% pitch
        const pitchFactor = this.calcPercentRange(speed, 40, 80)
        this.engineSound.setPlaybackRate(pitchFactor)
      // } else if (this.engineSound.isPlaying) {
      } else {  // May not be playing for other reasons. Still administratively want sound stopped
        this.engineSound.stop()
        this.engineSoundIsPlaying = false
        // this.engineSound.spatialSound = false  // Address panner.setPosition() performance issue
        // ^-- removed 9/2023, was causing issues with BJS v6.21
        //     motors sound stuck on in non-spatial mode.
      }
    }
  }

  setSpeaker = (freq, duty) => {
    if (this.speakerSound) {
      if (freq > 0 && duty > 0) {
        if (this.speakerOscillator) {
          this.speakerOscillator.frequency.value = freq
        } else {
          this.speakerSound.setPlaybackRate(freq / 440)
        }

        if (!this.speakerIsPlaying) {
          this.speakerIsPlaying = true
          this.speakerSound.spatialSound = this.useSpatialSound  // Address panner.setPosition() performance issue

          if (this.speakerOscillator) {
            this.speakerOscillator.connect(this.speakerSound._inputAudioNode)
          } else {
            this.speakerSound.play()
          }
        }
      } else {
        if (this.speakerIsPlaying) {
          this.speakerIsPlaying = false
          this.speakerSound.spatialSound = false  // Address panner.setPosition() performance issue
          if (this.speakerOscillator) {
            this.speakerOscillator.disconnect(this.speakerSound._inputAudioNode)
          } else {
            this.speakerSound.stop()
          }
        }
      }
    }
  }
}

export { CodeBotCB2Model }