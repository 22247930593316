// Camera Entities to load into Scene

import * as BABYLON from '@babylonjs/core'
import { Entity } from '../SimScene'
import { disposePromise } from '../utils/BabylonUtils'

class CameraBase extends Entity {
  constructor() {
    super()
    this.name = 'CameraBase'
    this.camera = null
    this.targetEntity = null
    this.engine = null
    this.isBlurred = false
    this.blurEffect = {
      kernel: 256.0,
      hBlur: null,
      vBlur: null,
    }
    this.unattachedTarget = null
  }
  setTargetMesh = (mesh) => {
  }
  reset() {
    // Derived class must save with storeState() upon load.
    this.camera.restoreState()
    this.setTargetEntity(this.targetEntity, true)
  }
  load(scene) {
    this.engine = scene.getEngine()
    this.unattachedTarget = new BABYLON.TransformNode('Unattached', scene)
  }
  unload = async () => {
    await disposePromise(this.camera)
  }

  blur = (shouldBlur) => {
    if (!this.camera || !this.engine) {
      return
    }

    this.isBlurred = shouldBlur

    if (shouldBlur) {
      // Create the blurs if they do not yet exist
      if (!this.blurEffect.hBlur) {
        this.blurEffect.hBlur = new BABYLON.BlurPostProcess('hBlur', new BABYLON.Vector2(1.0, 0), this.blurEffect.kernel, 1.0, null, null, this.engine)
      }
      if (!this.blurEffect.vBlur) {
        this.blurEffect.vBlur = new BABYLON.BlurPostProcess('vBlur', new BABYLON.Vector2(0, 1.0), this.blurEffect.kernel, 1.0, null, null, this.engine)
      }
      // Attach the blurs to the camera
      this.camera.attachPostProcess(this.blurEffect.hBlur)
      this.camera.attachPostProcess(this.blurEffect.vBlur)
    } else {
      // Detach the blurs from the camera
      if (!!this.blurEffect.hBlur) {
        this.camera.detachPostProcess(this.blurEffect.hBlur)
        delete this.blurEffect.hBlur
      }
      if (!!this.blurEffect.vBlur) {
        this.camera.detachPostProcess(this.blurEffect.vBlur)
        delete this.blurEffect.vBlur
      }
    }
  }

  setTargetEntity = (entity, isReset, envCamParams) => {
    // Set camera target mesh entity and associated params. Some cameras derive position and orientation
    // based on target mesh position.

    // The isReset param allows user to retain camera position through scene reset.
    // NOTE: currently isReset is not used!

    // Environment scene params can include "camera" prop to override defaults specific to camera-type, in envCamParams.
    // Scene params must use derived class name (e.g. "Rotate") for specific overrides per camera type.
    const envParams = envCamParams?.[this.name]

    if (entity === null) {
      this.setTargetMesh(this.unattachedTarget, isReset)
      return
    }
    if (!entity.getRootMesh) {
      console.error('Camera can\'t target non-mesh entity!')
      return
    }
    this.targetEntity = entity
    let targetMesh = entity.getRootMesh()
    if (targetMesh) {
      this.setTargetMesh(targetMesh, isReset, envParams)
    } else {
      entity.loadedObservable.add(() => {
        targetMesh = entity.getRootMesh()
        if (targetMesh) {
          this.setTargetMesh(targetMesh, isReset, envParams)
        }
      })
    }
  }
}

class RotateCam extends CameraBase {
  constructor() {
    super()
    this.name = 'Rotate'
    this.camera = null
    this.target = BABYLON.Vector3.Zero()
  }

  setTargetMesh = (mesh, isReset, envCamParams) => {
    this.targetMesh = mesh

    const defaults = {
      target: [mesh.position.x, mesh.position.y, mesh.position.z],
      alpha: Math.PI * 0.8,
      beta: Math.PI / 2.5,
      radius: 4,
    }
    const params = {...defaults, ...envCamParams}

    // console.log(`Rotate Cam: target=${params.target}, reset=${isReset}, envCamParams=${JSON.stringify(envCamParams)}`)

    this.target = new BABYLON.Vector3(...params.target)
    if (this.camera) {
      this.camera.setTarget(this.target)
      this.camera.alpha = params.alpha
      this.camera.beta = params.beta
      this.camera.radius = params.radius
    }

    // Disable collisions until settled at new position
    this.camera.checkCollisions = false
    this.enableCollisionsOnArrival(params)
  }

  enableCollisionsOnArrival = (params) => {
    const obs = this.camera.onViewMatrixChangedObservable.add(() => {
      if (Math.abs(this.camera.alpha - params.alpha) < 0.1 &&
          Math.abs(this.camera.beta - params.beta) < 0.1 &&
          Math.abs(this.camera.radius - params.radius) < 0.1
      ) {
        this.camera.checkCollisions = true
        this.camera.onViewMatrixChangedObservable.remove(obs)
      }
    })
  }

  load = (scene) => {
    super.load(scene)
    const canvas = scene.getEngine().getRenderingCanvas()

    this.camera = new BABYLON.ArcRotateCamera('RotateCam', Math.PI * 0.8, Math.PI / 2.5, 4, this.target, scene)
    this.camera.wheelDeltaPercentage = 0.01
    this.camera.upperBetaLimit = 0.95 * Math.PI / 2   // Stay above ground
    this.camera.lowerBetaLimit = 0.01 // -0.95 * Math.PI / 2   // Stay above ground and right side up
    this.camera.useInputToRestoreState = false // Prevent double clicks from resetting to origin

    // Using camera.collisionRadius instead of ArcRotateCamera.lowerRadiusLimit
    // this.camera.lowerRadiusLimit = 2.2
    this.camera.collisionRadius = new BABYLON.Vector3(1.3, 0.3, 1.3)  // x/z = Radius for CodeBot model, y = min height
    // this.camera.onCollide = ((m) => console.log(`cam collided with ${m.name}`))
    this.camera.checkCollisions = true

    // this.camera.upperRadiusLimit = 30
    this.camera.upperRadiusLimit = 60

    // Minimum view distance (with default value the near clipping allowed see-through ground, etc.)
    this.camera.minZ = 0.1

    // this.camera.setTarget(BABYLON.Vector3.Zero())
    this.camera.attachControl(canvas, true)

    // Save initial state of camera, so we can reset it
    this.camera.storeState()

    scene.activeCamera = this.camera
  }
}

class ChaseCam extends CameraBase {
  constructor() {
    super()
    this.name = 'Chase'
    this.camera = null
    this.targetMesh = null
    this.homePosition = new BABYLON.Vector3(0, 10, -10)
  }

  setTargetMesh = (mesh, isReset, envCamParams) => {
    const defaults = {
      position: [mesh.position.x - 4, mesh.position.y + 8, mesh.position.z - 8],
    }
    const params = {...defaults, ...envCamParams}
    // See below for additional params overrides

    this.targetMesh = mesh
    if (this.camera) {
      this.homePosition = new BABYLON.Vector3(...params.position)
      this.camera.position = this.homePosition

      this.camera.lockedTarget = mesh
      this.setFollowParams()

      // Allow environment overrides of FollowParams
      this.camera.rotationOffset = params.rotationOffset ?? this.camera.rotationOffset
      this.camera.heightOffset = params.heightOffset ?? this.camera.heightOffset
      this.camera.radius = params.radius ?? this.camera.radius
    }
  }

  reset = () => {
    // Derived class must save with storeState() upon load so super.reset() works properly
    super.reset()
    this.setFollowParams()
  }

  setFollowParams = () => {
    // The goal distance of camera from target
    this.camera.radius = 3.0

    this.camera.lowerRadiusLimit = 2.0
    this.camera.upperRadiusLimit = 15.0
    this.camera.minZ = 0.1

    // The goal height of camera above local origin (centre) of target
    this.camera.heightOffset = 2.0
    this.camera.lowerHeightOffsetLimit = 0.2
    this.camera.upperHeightOffsetLimit = 8.0

    // The goal rotation of camera around local origin (centre) of target in x y plane
    this.camera.rotationOffset = 0

    // Acceleration of camera in moving from current to goal position
    this.camera.cameraAcceleration = 0.03

    // The speed at which acceleration is halted
    this.camera.maxCameraSpeed = 10
  }

  load = (scene) => {
    super.load(scene)
    const canvas = scene.getEngine().getRenderingCanvas()

    // Parameters: name, position, scene
    this.camera = new BABYLON.FollowCamera('ChaseCam', this.homePosition, scene)

    this.setFollowParams()

    // This attaches the camera to the canvas
    this.camera.attachControl(canvas, true)

    // Lock onto target
    if (!this.targetMesh) {
      console.log('Warning: No target mesh set for ChaseCam!')
    }
    this.camera.lockedTarget = this.targetMesh

    // Save initial state of camera, so we can reset it
    this.camera.storeState()

    scene.activeCamera = this.camera
  }
}

class UniversalCam extends CameraBase {
  constructor() {
    super()
    this.name = 'Universal'
    this.camera = null
    this.target = BABYLON.Vector3.Zero()
    this.homePosition = new BABYLON.Vector3(5.0, 20.0, 5.0)
  }

  setTargetMesh = (mesh, isReset, envCamParams) => {
    const defaults = {
      position: [mesh.position.x + 5, mesh.position.y + 15, mesh.position.z + 5],
      target: [mesh.position.x, mesh.position.y, mesh.position.z],
    }
    const params = {...defaults, ...envCamParams}

    this.targetMesh = mesh
    this.target = new BABYLON.Vector3(...params.target)
    // console.log(`Universal Cam: position=${params.position} target=${params.target}, reset=${isReset}, envCamParams=${JSON.stringify(envCamParams)}`)
    if (this.camera) {
      this.homePosition = new BABYLON.Vector3(...params.position)
      this.camera.position = this.homePosition
      this.camera.setTarget(this.target)
    }
  }

  load = (scene) => {
    super.load(scene)
    const canvas = scene.getEngine().getRenderingCanvas()

    this.camera = new BABYLON.UniversalCamera('UniversalCam', this.homePosition, scene)

    // Using camera.collisionRadius
    this.camera.collisionRadius = new BABYLON.Vector3(1.3, 0.3, 1.3)  // x/z = Radius for CodeBot model, y = min height
    this.camera.checkCollisions = true
    this.camera.ellipsoid = new BABYLON.Vector3(0.3, 0.3, 0.3)

    this.camera.upperRadiusLimit = 60
    this.camera.upperBetaLimit = 0.95 * Math.PI / 2   // Stay above ground
    this.camera.lowerBetaLimit = 0.01 // -0.95 * Math.PI / 2   // Stay above ground and right side up

    // Minimum view distance (with default value the near clipping allowed see-through ground, etc.)
    this.camera.minZ = 0.1

    this.camera.setTarget(BABYLON.Vector3.Zero())
    this.camera.attachControl(canvas, true)

    this.camera.cameraAcceleration = 0.008 // how fast to move
    this.camera.maxCameraSpeed = 1.0 // speed limit
    this.camera.angularSensibility = 5000
    this.camera.speed = 0.2

    // WASD key mapping
    this.camera.keysUp.push(87)         // W
    this.camera.keysLeft.push(65)       // A
    this.camera.keysRight.push(68)      // S
    this.camera.keysDown.push(83)       // D
    this.camera.keysDownward.push(81)   // Q
    this.camera.keysUpward.push(69)     // E

    // Enable mouse wheel inputs
    this.camera.inputs.addMouseWheel()
    this.camera.inputs.attached.mousewheel.wheelPrecisionY = 0.05

    // Save initial state of camera, so we can reset it
    this.camera.storeState()

    // Point this camera to the Codebot target on load
    this.reset()

    scene.activeCamera = this.camera
  }
}

class AttachedCam extends CameraBase {
  // The AttachedCam is parented to a TargetMesh, so it moves exactly with the mesh - like a mounted camera.
  constructor() {
    super()
    this.name = 'Attached'
    this.camera = null
  }

  setTargetMesh = (mesh) => {
    this.targetMesh = mesh
    if (this.camera) {
      this.camera.parent = mesh
    }
  }

  load = (scene) => {
    super.load(scene)

    // Attach position: +Z moves further back (behind) bot, +Y moves above
    const attachPosition = new BABYLON.Vector3(0, 0.5, 1.3)  // Sitting on speaker

    if (this.camera) {
      this.camera.dispose()
    }

    this.camera = new BABYLON.FlyCamera('AttachedCam', attachPosition, scene, true)
    this.camera.rotation.y += Math.PI  // Face forward

    if (!this.targetMesh) {
      console.log('Warning: No target mesh set for AttachedCam!')
    }
    this.setTargetMesh(this.targetMesh)

    // Save initial state of camera, so we can reset it
    this.camera.storeState()

    scene.activeCamera = this.camera
  }
}

export const CameraIds = Object.freeze({
  ROTATE_CAM: 0,
  CHASE_CAM: 1,
  UNIVERSAL_CAM: 2,
  ATTACHED_CAM: 3,
})

export const cameraList = [
  new RotateCam(),
  new ChaseCam(),
  new UniversalCam(),
  new AttachedCam(),
]

